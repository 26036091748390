import enumTypes from '@/app/library/constants';

export const MIME_TYPES = [
  'video/mp4',
  'video/x-msvideo',
  'video/x-matroska',
  'application/pdf',
  'application/zip',
  'application/x-rar-compressed',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/octet-stream',
  'application/x-tar',
  'application/x-7z-compressed',
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/svg+xml',
  'text/plain',
  'text/html',
  'application/json',
  'application/xml',
  'text/csv',
];

export const POPULAR_FILE_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.txt',
  '.rtf',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.tiff',
  '.tif',
  '.zip',
  '.rar',
  '.7z',
  '.tar',
  '.mp3',
  '.wav',
  '.aac',
  '.mp4',
  '.mov',
  '.avi',
  '.mkv',
  '.html',
  '.htm',
  '.csv',
  '.xml',
];

export const DISCARD_DIALOG = {
  group: 'global',
  header: 'Discard Changes?',
  message: 'If you leave now, any unsaved changes will be lost.',
  acceptLabel: 'Discard',
  acceptSeverity: 'danger',
};

export const chartScaleLabels = {
  [enumTypes.HOURLY]: 'Hour',
  [enumTypes.DAILY]: 'Day',
  [enumTypes.MONTHLY]: 'Month',
};
