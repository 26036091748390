<script setup>
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import { useMagicKeys } from '@vueuse/core';
import { useFilterSidePanel } from '@dashhudson/dashing-ui';

const keys = useMagicKeys();
const metaSlash = keys['meta+/'];

const { expanded, setMounted, toggleNavigationPanel } = useSecondaryNavigationPanel();

const { visible: filterSidePanelVisible } = useFilterSidePanel();

const isExpanded = computed(() => {
  return expanded.value;
});

onMounted(() => {
  setMounted(true);
});

onUnmounted(() => {
  setMounted(false);
});

watch(metaSlash, (to) => {
  if (to && !filterSidePanelVisible.value) toggleNavigationPanel();
});
</script>

<template>
  <div
    class="dash-transition-md fixed left-[var(--primary-navigation-width)] top-0 h-full w-[var(--secondary-navigation-width)] rounded-tl-[40px] bg-[#f9fbff]"
    :class="{ 'invisible opacity-0': !isExpanded }"
    data-secondary-nav
  >
    <div class="dash-elevation-2xl relative h-full pb-5 pt-8 xl:shadow-none">
      <div id="sideNavigationPanel" class="flex flex-col items-start justify-center" />
    </div>
  </div>
</template>

<style>
.hs-web-interactives-top-banner-open {
  [data-secondary-nav] {
    height: calc(100% - var(--hubspot-banner-height)) !important;
    top: var(--hubspot-banner-height) !important;
  }
}
</style>
