<template>
  <SchedulePostPopupWrapper
    :close="close"
    :close-confirmation="confirmation"
    :post="post"
    :show-post-editor-side-panel="showPostEditorSidePanel"
    :linked-comment-id="linkedCommentId"
    :avatar="connection && connection.avatar_url"
    :display-name="displayName"
    :show-media-placeholder-message="mediaList.length === 0"
    :has-unsaved-changes="hasUnsavedChanges"
    platform="tiktok"
    class="tiktok-scheduling"
  >
    <template #media>
      <MediaManager
        :disable-editing="disableEditing"
        :display-error-message="onMediaUploadError"
        :download-media-clicked="downloadMediaClicked"
        :media-list="mediaList"
        :media-count-limit="1"
        :media-selected="mediaSelected"
        media-type-limit="VIDEO"
        :viewer-component="viewerComponent"
        :viewer-component-props="{
          aspectRatio: 'portrait',
          platform: 'tiktok',
          validationError: validationErrorOrWarning,
          thumbOffset: thumbOffset,
        }"
        :on-upload-status-change="uploadStatusChanged"
        :custom-cropper-presets="customCropperPresets"
        :enable-free-crop="false"
        :can-be-published-at="scheduledTime"
        disallow-past-publish-dates
        disallow-incompatible-publish-dates
        :publish-dates-must-overlap-with="canPublishWithin"
        :vision-usage-info="visionUsageInfo"
        @reset-thumb-details="resetThumbnail"
      />
      <VideoPredictionThumbnails
        v-if="displayKeyframes"
        v-model="thumbOffset"
        :media="mediaList[0]"
        :is-processing="mediaList[0].isProcessing"
        :video-predictions="mediaList[0].videoPredictions"
        :suggested-thumbnails="mediaList[0].videoSuggestedThumbnailList"
        :prediction-interval="mediaList[0].videoPredictionInterval"
        @offset-update="handleOffset"
      />
      <Banner
        v-if="validationErrorOrWarning && mediaList.length >= 1"
        data-cy="validationBanner"
        :alert-type="validationErrorOrWarning.level"
        :custom-icon="validationErrorOrWarning.icon"
        class="validation-banner"
        hide-default-icon
      >
        {{ validationErrorOrWarning.message }}
      </Banner>
      <Banner
        v-if="publishDateRangeWarning"
        :alert-type="publishDateRangeWarning.level"
        class="validation-banner"
      >
        {{ publishDateRangeWarning.message }}
      </Banner>
    </template>
    <template #details>
      <template v-if="currentScreen === 'index'">
        <PostStatusBox :post="{ ...post, platform: 'tiktok' }" />
        <section>
          <PublishOptionHeader
            :disabled="isDraftToggleDisabled"
            :is-on="isDraft"
            :post="post"
            @drafts-toggled="(draft) => onToggleDraft(draft)"
          />
          <AutoPublishOptions
            v-model="autoPublish"
            :disabled="disableEditing"
            :hide-auto-publish="!canAutoPublish"
            :disable-auto-publish="disableAutoPublish"
            platform="tiktok"
            :post="post"
          />
          <PostSettingItem
            :disabled="disableEditing || approvedPublishingDatesExpired"
            :title="scheduledTimeLabel"
            :clear-action="resetTime"
            :on-click="() => switchSubScreen('schedule')"
            :error-message="scheduledTimeError"
            empty="Schedule Date & Time"
            left-icon="calendar"
          />
          <QuickSelectCalendar
            v-if="!disableEditing && isActiveTab && !approvedPublishingDatesExpired"
            ref="quickSelectCalendar"
            :class="{ 'pt-4': publishDateError }"
            :model-value="scheduledTime"
            :restrict-selection-to-interval="canPublishWithin"
            :platform="platformLabels.tiktok"
            @update:model-value="timeSelected"
          />
        </section>
        <PostSettingItem
          v-if="hasApprovalRequestsAccess"
          :title="getApprovalRequestTitle(approvalRequests)"
          :on-click="
            () =>
              schedulerStore.toggleSidePanel({
                router: $router,
                platform: 'tiktok',
                post,
              })
          "
          data-cy="approval-request-setting-item"
          empty="Add Reviewers"
          left-icon="chatBubbleSquareCheck"
          label="Approval Request"
          :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
        />
        <section v-if="canAutoPublish">
          <p class="section-title">Allow users to:</p>
          <div v-tooltip="toggleOptionTooltip" class="allow-toggle-options">
            <CheckBox
              v-model="allowComment"
              :disabled="!autoPublish || disableEditing"
              label="Comment"
              data-cy="allow-comment-toggle"
              class="allow-toggle"
            />
            <CheckBox
              v-model="allowDuet"
              :disabled="!autoPublish || disableEditing"
              label="Duet"
              class="allow-toggle"
            />
            <CheckBox
              v-model="allowStitch"
              :disabled="!autoPublish || disableEditing"
              label="Stitch"
              data-cy="allow-stitch-toggle"
              class="allow-toggle"
            />
          </div>
        </section>
        <section>
          <div class="caption-header">
            <p class="section-title">
              Caption
              <InfoTooltip v-if="!autoPublish" :tooltip="captionTooltip" xsmall />
            </p>
            <div v-if="excessCharacterCount" class="red">{{ -excessCharacterCount }}</div>
          </div>
          <Textarea
            v-model="text"
            data-cy="tiktok-caption-area"
            :class="{ 'invalid-field': !isCaptionValid }"
            :disabled="disableEditing"
            placeholder="Enter caption"
            resizable
          />
        </section>
        <PostSettingItem
          :input-tooltip="linksDisabledToolTip"
          :title="linksLabel"
          :disabled="linksDisabled || disableEditing"
          :clear-action="resetLinks"
          :on-click="openLinks"
          :show-tooltip="mediaUpdated"
          empty="Add Links"
          left-icon="link"
          label="Links"
          :label-info-tooltip="toolTips.tiktokLink"
        />
        <PostSettingItem
          :disabled="disableEditing"
          :title="addToLabel"
          :clear-action="resetAddTo"
          :on-click="() => switchSubScreen('addTo')"
          empty="After Published, Add to"
          left-icon="folderAdd"
          :label="addToAfterLabel"
        />
        <Controls class="controls">
          <Button
            v-if="!disableEditing && post && post.id"
            v-tooltip="'Remove from Scheduler'"
            remove
            class="remove"
            data-cy="tiktok-video-remove-button"
            @click="onRemoveClick"
          >
            Remove
          </Button>
          <SplitButton
            v-if="!disableEditing"
            :tooltip="saveTooltip"
            :disabled="saveDisabled"
            :loading="isSaving"
            class="save-button"
            primary
            data-cy="tiktok-video-save-button"
            :options="saveOptions"
            @click="save"
          >
            Save
          </SplitButton>
          <DropdownButton
            v-else
            :dropdown-list="duplicatePostOptions"
            button-classes="primary"
            data-cy="duplicate-post-button"
            items-center
          >
            <template #buttonContent>Duplicate Post</template>
          </DropdownButton>
        </Controls>
      </template>

      <!-- Select board to add to subscreen -->
      <AddToGallery
        v-if="currentScreen === 'addTo'"
        :selected-galleries="selectedGalleries"
        :on-galleries-selected="onGalleriesSelected"
        :on-back="() => switchSubScreen('index')"
        type="TIKTOK"
      />

      <!-- Select post time subscreen -->
      <SubScreen
        v-if="currentScreen === 'schedule'"
        title="Choose Date & Time"
        @on-back="switchSubScreen('index')"
      >
        <template #content>
          <section>
            <Calendar
              :posts="schedulerStore.getPosts(brandTikTokPosts)"
              :time-selected="timeSelected"
              :on-fetch-posts="onCalendarFetch"
              :restrict-selection-to-interval="canPublishWithin"
              :ideal-post-times="bestTimesToPost"
              platform="tiktok"
              type="compact"
            />
          </section>
        </template>
      </SubScreen>
    </template>
    <MediaLinks
      v-if="mediaLinksStore.showLinkPopup"
      :thumb-offset="thumbOffset"
      context="schedulerPopup"
      :prefetch-links="false"
    />
  </SchedulePostPopupWrapper>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import dayjs from 'dayjs';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useIdentityStore } from '@/stores/identity';
import {
  discardCommentConfirmMessage,
  discardConfirmMessage,
  maxTiktokAutoPublishCaptionLength,
  maxTiktokSendNotificationCaptionLength,
  tikTokCropperPresets,
  toolTips,
  UPLOAD_STATUS,
} from '@/config';
import {
  mediaCropLinkMessage,
  mixpanelActions,
  platformLabels,
  PLATFORMS,
  popUpMessages,
  postPublishInfoTooltip,
  postStatus,
} from '@/app/scheduler/constants';
import MediaLinks from '@/app/library/components/MediaLinks.vue';
import {
  allMediaUploaded,
  checkUnsavedChanges,
  shouldDisplayKeyFrames,
  ThumbnailUrlGenerator,
  getUnscheduledRouteName,
} from '@/app/scheduler/utils';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import PostSettingItemLabelMixin from '@/app/scheduler/mixins/PostSettingItemLabelMixin';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import Button from '@/components/foundation/Button.vue';
import Calendar from '@/app/scheduler/components/Calendar.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import SchedulePostPopupWrapper from '@/app/scheduler/components/EditPost/Layout/SchedulePostPopupWrapper.vue';
import VideoPredictionThumbnails from '@/app/scheduler/components/EditPost/MediaViewer/VideoPredictionThumbnails.vue';
import QuickSelectCalendar from '@/app/scheduler/components/QuickSelectCalendar.vue';
import Textarea from '@/components/Textarea.vue';
import CarouselViewer from '@/app/scheduler/components/EditPost/MediaViewer/CarouselViewer.vue';
import SplitButton from '@/components/foundation/SplitButton.vue';
import { mediaTypes } from '@/app/library/constants';
import AddToGallery from '@/app/scheduler/components/EditPost/AddToGallery.vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import { useCommentingStore } from '@/stores/commenting';
import { useSchedulerStore } from '@/stores/scheduler';
import { useMediaStore } from '@/stores/media';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { usePlatformStore } from '@/stores/platform';
import { useCropperStore } from '@/stores/cropper';
import { useMediaLinksStore } from '@/stores/media-links';
import { useFlagStore } from '@/stores/flag';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import {
  mixpanelPublishTypes,
  postTypeLabelMap,
  SchedulerUserEventTracker,
} from '@/app/scheduler/mixpanel';
import { utmChannel } from '@/app/settings/components/Utm/const';
import { USER } from '@/models/auth/permissions.enum';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import { getFirstMediaError, validatePostMedia } from '@/app/scheduler/utils/mediaValidation';
import { refreshBestTimesToPost } from '@/app/scheduler/utils/bestTimesToPost';
import AutoPublishOptions from '@/app/scheduler/components/AutoPublishOptions.vue';
import Controls from '@/app/scheduler/components/EditPost/Layout/Controls.vue';
import PostSettingItem from '@/app/scheduler/components/PostSettingItem.vue';
import PostStatusBox from '@/app/scheduler/components/PostStatusBox.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import MediaManager from '@/app/scheduler/components/EditPost/MediaViewer/MediaManager.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EditTiktokPost',
  components: {
    PublishOptionHeader,
    Banner,
    SplitButton,
    MediaLinks,
    MediaManager,
    SchedulePostPopupWrapper,
    AddToGallery,
    AutoPublishOptions,
    Button,
    Calendar,
    Controls,
    DropdownButton,
    PostSettingItem,
    PostStatusBox,
    QuickSelectCalendar,
    SubScreen,
    Textarea,
    CheckBox,
    InfoTooltip,
    VideoPredictionThumbnails,
  },
  mixins: [PostDuplicationMixin, PostSettingItemLabelMixin, ApprovedPublishingDatesMixin],
  props: {
    close: { type: Function, required: true },
    post: { type: Object, default: null },
    media: { type: Array, default: null },
    mediaCaption: { type: String, default: null },
    context: { type: String, default: null },
    disableEditing: { type: Boolean, default: false },
    showPostEditorSidePanel: { type: Boolean, default: false },
    linkedCommentId: { type: Number, default: null },
    isActiveTab: { type: Boolean, default: true },
  },
  data() {
    return {
      autoPublish: this.post?.autoPublish ?? false,
      mediaList: this.media ?? this.post?.media ?? [],
      text: this.post?.text ?? this.mediaCaption ?? '',
      selectedGalleries: [
        ...(this.post?.campaigns?.map((campaignObj) => ({ ...campaignObj, campaign: true })) ?? []),
        ...(this.post?.boards ?? []),
      ],
      scheduledTime: this.post?.timestamp && dayjs(this.post.timestamp),
      currentScreen: 'index',
      isCaptionValid: true,
      isSaving: false,
      isValidating: false,
      mediaUpdated: false,
      mediaValidationError: null,
      showRemoveConfirm: false,
      uploadPending: false,
      userClearedMediaLinks: false,
      allowComment: !this.post?.disableComment,
      allowDuet: !this.post?.disableDuet,
      allowStitch: !this.post?.disableStitch,
      toolTips,
      hasUnsavedChanges: false,
      mediaUploadError: null,
      thumbnailOffset: this.post?.thumbnailOffset ?? null,
      thumbnailUrl: this.post?.thumbnailUrl ?? null,
      thumbnailUrlGenerator: new ThumbnailUrlGenerator(),
      approvalRequests: (this.post && this.post.approvalRequests) || [],
      isDraft: (!this.post?.id && !this.post?.timestamp) || this.post?.status === postStatus.DRAFT,
      thumbnailSource: null,
    };
  },
  computed: {
    ...mapStores(
      useCustomerJourneyStore,
      useSchedulerStore,
      useCommentingStore,
      useAuthStore,
      useNotificationStore,
      usePlatformStore,
      useCropperStore,
      useMediaLinksStore,
      useFlagStore,
      useTrackingStore,
      useMediaStore,
      useIdentityStore,
    ),
    brandTikTokPosts() {
      return allPosts.forBrand(this.authStore.currentBrand.id).forPlatform(PLATFORMS.TIKTOK);
    },
    customCropperPresets() {
      return tikTokCropperPresets;
    },
    toggleOptionTooltip() {
      if (this.authStore.canAccessAutoPublish) {
        return !this.autoPublish ? toolTips.tikTokAllowTogglesOnlyAllowedInAutoPublish : null;
      }

      return toolTips.insufficientPermission;
    },
    disableAutoPublish() {
      return {
        isDisabled: !allMediaUploaded(this.mediaList),
        tip: toolTips.noVideoSelectedAutoPublish,
      };
    },
    hasApprovalRequestsAccess() {
      return !this.customerJourneyStore.hasPlanGrow;
    },
    addToLabel() {
      if (this.selectedGalleries.length) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return null;
    },
    canAutoPublish() {
      return this.authStore.canAccessAutoPublish;
    },
    maxCaptionLength() {
      return this.autoPublish
        ? maxTiktokAutoPublishCaptionLength
        : maxTiktokSendNotificationCaptionLength;
    },
    displayName() {
      return this.connection?.email_or_handle ?? this.authStore.currentBrand.name ?? '';
    },
    connection() {
      return this.platformStore.platformConnectionsMap.tiktok?.[this.authStore.currentBrand?.id];
    },
    isAppConnected() {
      return this.connection?.status === 'connected';
    },
    confirmation() {
      if (this.hasUnsavedChanges) {
        return discardConfirmMessage;
      }
      if (this.commentingStore?.hasUnsavedComments) {
        return discardCommentConfirmMessage;
      }
      return null;
    },
    viewerComponent() {
      return CarouselViewer;
    },
    validationErrorOrWarning() {
      if ([postStatus.POSTED, postStatus.IMPORTED].includes(this.post?.status)) {
        return null;
      }
      return this.publishDateError ?? this.mediaValidationError ?? this.mediaUploadError;
    },
    popUpMessages() {
      return popUpMessages;
    },
    postData() {
      return {
        platform: PLATFORMS.TIKTOK,
        id: this.post?.id,
        autoPublish: this.autoPublish,
        boardIds: this.selectedBoardIds,
        campaignIds: this.selectedCampaignIds,
        brandId: this.authStore.currentBrand.id,
        text: this.text,
        media: this.mediaList,
        mediaIds: this.mediaList.map((m) => m.id),
        timestamp: this.scheduledTime?.toISOString?.() ?? null,
        disableComment: !this.allowComment,
        disableDuet: !this.allowDuet,
        disableStitch: !this.allowStitch,
        thumbnailOffset: this.thumbnailOffset,
        approvalRequests: this.approvalRequests,
        ...(this.isDraft && this.flagStore.ready && this.flagStore.flags.schedulerDrafts
          ? { status: postStatus.DRAFT }
          : {}),
        ...(this.post?.approvalPolicy ? { approval_policy: this.post.approvalPolicy } : {}),
      };
    },
    selectedBoardIds() {
      return this.selectedGalleries.filter((g) => !g.campaign).map((g) => g.id);
    },
    selectedCampaignIds() {
      return this.selectedGalleries.filter((g) => g.campaign).map((g) => g.id);
    },
    duplicatePostData() {
      return {
        ...this.postData,
        boards: this.selectedGalleries.filter((g) => !g.campaign),
        campaigns: this.selectedGalleries.filter((g) => g.campaign),
      };
    },
    isAScheduledPost() {
      return !!this?.scheduledTime;
    },
    timestampAddedOrRemoved() {
      return (
        (!this.scheduledTime && this.post && this.post.timestamp) ||
        (!this.post.timestamp && this.scheduledTime)
      );
    },
    scheduledTimeLabel() {
      return this.scheduledTime ? this.scheduledTime.format('LT, ll') : null;
    },
    hasInvalidMediaSelection() {
      return (
        this.mediaList.length > 1 || this.mediaList.some((media) => media.type === mediaTypes.IMAGE)
      );
    },
    saveDisabled() {
      return (
        this.uploadPending ||
        (this.autoPublish && !this.canAutoPublish) ||
        this.hasInvalidMediaSelection ||
        (this.mediaValidationError && this.mediaValidationError.level === 'error') ||
        this.isValidating ||
        this.isSaving ||
        (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) ||
        (this.autoPublish && this.text.length > this.maxCaptionLength) ||
        (this.autoPublish && !this.isAppConnected) ||
        !!this.publishDateError
      );
    },
    saveTooltip() {
      if (this.uploadPending) {
        return { content: toolTips.mediaUploading };
      }
      if (this.mediaValidationError && this.mediaValidationError.level === 'error') {
        return { content: this.mediaValidationError.message };
      }
      if (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) {
        return { content: toolTips.illegalTimeStamp };
      }
      if (this.hasInvalidMediaSelection) {
        return { content: toolTips.invalidTikTokMediaSelection };
      }
      if (this.autoPublish && !this.canAutoPublish) {
        return { content: toolTips.autoPublishNotSupported };
      }
      if (this.autoPublish && this.text.length > this.maxCaptionLength) {
        return { content: toolTips.maxTikTokCharactersReached };
      }
      if (this.autoPublish && !this.isAppConnected) {
        return { content: toolTips.invalidTikTokConnection };
      }
      return null;
    },
    isPublishNowDisabled() {
      if (!this.authStore.canAccessAutoPublish) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (this.mediaList.length === 0) {
        return { isDisabled: true, tip: toolTips.noMediaSelected };
      }
      if (!this.autoPublish) {
        return { isDisabled: true, tip: toolTips.notAutoPublish };
      }
      if (!this.canAssetPublishToday) {
        return { isDisabled: true, tip: this.publishNowWarning };
      }
      if (this.requiresApproval(this.post)) {
        return { isDisabled: true, tip: toolTips.requiresApproval };
      }
      return { isDisabled: false };
    },
    duplicatePostOptions() {
      return this.pdDuplicationOptions(this.duplicate);
    },
    saveOptions() {
      return [
        {
          text: 'Save & Duplicate',
          subList: this.pdDuplicationOptions(this.saveAndDuplicate),
        },
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.isPublishNowDisabled.isDisabled,
          tooltip: this.isPublishNowDisabled.tip,
        },
        {
          text: 'Publish Now & Duplicate',
          disabled: this.isPublishNowDisabled.isDisabled,
          tooltip: this.isPublishNowDisabled.tip,
          subList: this.pdDuplicationOptions(this.publishNowAndDuplicate),
        },
      ];
    },
    excessCharacterCount() {
      return Math.max(0, this.text.length - this.maxCaptionLength);
    },
    linksLabel() {
      if (!this.userClearedMediaLinks && this.mediaLinksStore.mediaLinks.length > 0) {
        if (this.mediaLinksStore.mediaLinks.length === 1) {
          return this.mediaLinksStore.mediaLinks[0].url;
        }
        return `${this.mediaLinksStore.mediaLinks.length} links`;
      }
      return null;
    },
    linksPromptMessage() {
      return mediaCropLinkMessage;
    },
    linksDisabledToolTip() {
      if (this.linksDisabled && !this.disableEditing) {
        return 'You must add media to your post before adding product links';
      }
      return '';
    },
    linksDisabled() {
      return (
        this.mediaList.length === 0 ||
        (this.mediaList[0].uploadStatus && this.mediaList[0].uploadStatus !== UPLOAD_STATUS.SUCCESS)
      );
    },
    captionTooltip() {
      return postPublishInfoTooltip.TIKTOK_CAPTION;
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
    platformLabels() {
      return platformLabels;
    },
    displayKeyframes() {
      return (
        this.flagStore.flags.tiktokThumbnail &&
        shouldDisplayKeyFrames(this.mediaList, this.autoPublish, this.post)
      );
    },
    visionUsageInfo() {
      const usageInfo = this.identityStore.guard(USER.VISION.CAN_ACCESS_VISION)
        ? toolTips.enableVision
        : toolTips.promoteVision;
      return this.mediaList?.length === 1 &&
        !this.autoPublish &&
        this.flagStore.flags.tiktokThumbnail
        ? usageInfo
        : null;
    },
    isDraftToggleDisabled() {
      const isPublished = [
        postStatus.POSTED,
        postStatus.AUTOPUBLISHING,
        postStatus.IMPORTED,
      ].includes(this.post?.status);
      return isPublished || !this.postData?.timestamp;
    },
    bestTimesToPost() {
      return this.schedulerStore.recommendedPublishTimes?.[this.authStore.currentBrand.id]?.[
        PLATFORMS.TIKTOK
      ];
    },
    postApprovalRequests() {
      return (
        this.schedulerStore.approvalRequests?.[this.authStore.currentBrand.id]?.[
          PLATFORMS.TIKTOK
        ] || []
      );
    },
    thumbOffset() {
      return this.thumbnailOffset / 1000;
    },
  },
  watch: {
    autoPublish() {
      if (this.mediaList.length > 0) {
        this.validateMedia();
        this.handleOffset(null);
      }
    },
    postApprovalRequests: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) this.approvalRequests = newVal;
      },
    },
    postData: debounce(function handler(to, from) {
      let fieldChanged = checkUnsavedChanges(this.postData, this.post, this.scheduledTime);
      if (this.post === null) {
        fieldChanged = checkUnsavedChanges(to, from, this.scheduledTime);
      }
      this.hasUnsavedChanges = !this.disableEditing && (fieldChanged || this.uploadPending);
      if (!to?.timestamp) this.isDraft = true;
    }, 500),
    mediaList: {
      deep: true,
      immediate: true,
      handler(to) {
        if (to?.length === 0) {
          this.userClearedMediaLinks = false;
          this.clearMediaValidation();
          this.handleOffset(null);
        } else {
          this.validateMedia();
        }
      },
    },
  },
  async created() {
    this.autoPublish = this.canAutoPublish && this.autoPublish;

    // Fetch best times to post if they're not already in the store
    refreshBestTimesToPost(this.authStore.currentBrand.id, PLATFORMS.TIKTOK);
    // Handle media brought in from library/query params
    if (this.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand.id,
        mediaArrList: this.media,
      });
    }

    if (this.mediaList && this.mediaList.length > 0) {
      this.mediaLinksStore.listMediaLinks({
        brandId: this.authStore.currentBrand.id,
        mediaId: this.mediaList[0].id,
      });
    }
    // Set utm channel and fetch utm settings
    this.mediaLinksStore.utmChannel = utmChannel.TIKTOK_LIKESHOP;
    this.mediaLinksStore.fetchUtmSettings();
  },
  beforeUnmount() {
    this.thumbnailUrlGenerator.destroy();
    this.schedulerStore.resetCarouselPage();
  },
  unmounted() {
    this.mediaLinksStore.clearMediaLinks();
    this.cropperStore.removeAdjustLinksPrompt();
  },
  methods: {
    getApprovalRequestTitle,
    getApprovalChipText,
    onGalleriesSelected(galleries) {
      this.selectedGalleries = galleries;
    },
    resetAddTo() {
      this.selectedGalleries = [];
    },
    resetLinks() {
      this.userClearedMediaLinks = true;
      this.mediaLinksStore.clearMediaLinks();
    },
    openLinks() {
      this.mediaLinksStore.utmChannel = utmChannel.TIKTOK_LIKESHOP;
      this.mediaLinksStore.utmTrackingData = {
        postId: this.post?.id,
        postPlatform: 'TikTok',
        postType: 'Post',
      };
      this.userClearedMediaLinks = false;
      const media = this.mediaList[0]?.fullMediaObject ?? this.mediaList[0];
      this.mediaLinksStore.openMediaLinkEditor(media, false);
      const updateLinkData = {
        editFrom: 'Scheduler Popup',
        firstLink: this.mediaLinksStore.mediaLinks.length === 0,
      };
      this.trackingStore.track(mixpanelActions.LINK_UPDATE_CLICKED, updateLinkData);
    },
    onMediaUploadError(errorMessage) {
      this.mediaUploadError = errorMessage;
    },
    switchSubScreen(screen) {
      if (!this.disableEditing) {
        // hide tooltip if change sub-screen.
        this.mediaUpdated = false;
        this.currentScreen = screen;
        this.schedulerStore.setActiveSubScreen(this.currentScreen);
        if (screen === 'schedule') {
          this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
            postPlatform: 'TikTok',
            quickSelectClicked: false,
          });
        }
      }
    },
    uploadStatusChanged(status) {
      this.uploadPending = status;
    },
    timeSelected(time) {
      if (!this.scheduledTime) this.isDraft = false;
      this.scheduledTime = dayjs(time);
      this.switchSubScreen('index');
    },
    resetTime() {
      this.scheduledTime = null;
    },
    async onRemoveClick() {
      const { title, message } = popUpMessages.removeFromScheduler;
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.remove,
      });
    },
    async remove() {
      try {
        await this.schedulerStore.deletePost(this.post);
        if (this.scheduledTime) {
          this.notificationStore.setToast({ message: 'Post removed from your timeline.' });
        } else {
          this.notificationStore.setToast({
            message: 'Post removed from your unscheduled posts.',
          });
        }
        this.close();
      } catch (e) {
        this.showRemoveConfirm = false;
        this.notificationStore.setToast({
          type: 'error',
          message: 'Something went wrong removing your post. Try again later.',
        });
      }
    },
    async save(closeAfter = true) {
      if (this.saveDisabled || this.schedulerStore.postActionPending) {
        return;
      }
      if (this.publishDateError) {
        return;
      }
      this.isSaving = true;
      await this.savePost(closeAfter);
    },
    async savePost(closeAfter = true) {
      if (this.userClearedMediaLinks && this.mediaList.length > 0) {
        this.mediaLinksStore.updateMediaLinks({
          brandId: this.authStore.currentBrand.id,
          mediaId: this.mediaList[0].id,
          data: [],
        });
      }
      try {
        const postData = {
          ...this.postData,
          ...(this.thumbnailOffset !== (this.post?.thumbnailOffset ?? null)
            ? {
                thumbnailUrl: await this.thumbnailUrlGenerator.createThumbnailUrl(
                  this.mediaList,
                  this.thumbnailOffset / 1000, // ms -> s
                ),
              }
            : {}),
        };
        if (this.post?.id) {
          // Take value before updating post (as it then updates this.post)
          const timestampAddedOrRemoved = this.timestampAddedOrRemoved;
          await this.schedulerStore.updatePost(postData);
          if (timestampAddedOrRemoved) {
            if (this.scheduledTime) {
              this.notificationStore.setToast({
                message: 'Post updated & added to your timeline.',
              });
            } else {
              this.notificationStore.setToast({
                message: 'Post updated & added to your ',
                actionText: 'unscheduled posts.',
                actionTo: {
                  name: getUnscheduledRouteName(PLATFORMS.TIKTOK),
                },
                sameLine: true,
              });
            }
          } else {
            this.notificationStore.setToast({ message: 'Post updated.' });
          }
        } else {
          await this.schedulerStore.createPost(postData);
          if (this.scheduledTime) {
            this.notificationStore.setToast({ message: 'Post added to your timeline.' });
          } else {
            this.notificationStore.setToast({
              message: 'Post added to your ',
              actionText: 'unscheduled posts.',
              actionTo: {
                name: getUnscheduledRouteName(PLATFORMS.TIKTOK),
              },
              sameLine: true,
            });
          }
        }

        if (closeAfter) {
          this.close();
        }
      } catch (e) {
        this.notificationStore.setToast({
          type: 'error',
          message: 'Something went wrong saving your post. Try again later.',
        });
        throw e;
      } finally {
        this.isSaving = false;
      }
    },
    duplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.TIKTOK_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.TIKTOK_POST,
        post: duplicateData,
      });
    },
    async publishNowAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.TIKTOK_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.publishNow(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.TIKTOK_POST,
        post: duplicateData,
      });
    },
    async saveAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.TIKTOK_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.save(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.TIKTOK_POST,
        post: duplicateData,
      });
    },
    async onCalendarFetch(start, end) {
      this.schedulerStore.fetchPosts(this.brandTikTokPosts.betweenDates(start, end));
    },
    mediaSelected(media) {
      this.mediaList = media;
    },
    validateMedia() {
      const { mediaList } = this;
      if (mediaList[0]) {
        this.isValidating = true;
        const errorList = validatePostMedia(mediaList, PLATFORMS.TIKTOK, this.autoPublish);

        this.mediaValidationError = getFirstMediaError(errorList);
        this.isValidating = false;
      }
    },
    clearMediaValidation() {
      this.mediaValidationError = null;
    },
    async publishNow(closeAfter = true) {
      this.isSaving = true;

      const data = {
        ...this.postData,
        status: postStatus.AUTOPUBLISHING,
        ...(this.thumbnailOffset !== (this.post?.thumbnailOffset ?? null)
          ? {
              thumbnailUrl: await this.thumbnailUrlGenerator.createThumbnailUrl(
                this.mediaList,
                this.thumbnailOffset / 1000, // ms -> s
              ),
            }
          : {}),
      };

      if (this.post?.id) {
        await this.schedulerStore.updatePost(data);
      } else {
        await this.schedulerStore.createPost(data);
      }

      this.notificationStore.setToast({
        message: 'Publishing in progress.',
        actionText: 'See it on your Timeline',
        actionTo: { name: 'scheduler.tiktok.timeline' },
        type: 'primary',
      });

      this.isSaving = false;
      if (closeAfter) {
        this.close();
      }
    },
    downloadMediaClicked(media) {
      const postTrackingData = {
        postId: this.post?.id ?? null,
        postPlatform: postTypeLabelMap.tiktok,
        postPlatformType: null,
        postStatus: this.post?.status ?? null,
        publishType: this.postData.autoPublish
          ? mixpanelPublishTypes.AUTO_PUBLISH
          : mixpanelPublishTypes.SEND_NOTIFICATION,
        timestamp: this.postData.timestamp,
      };
      this.trackMixpanel.downloadMediaFromScheduler(postTrackingData, media);
    },
    handleOffset: debounce(function handler(offset, source) {
      // TikTok API docs says the offset passed in will be rounded up to find the closest frame,
      // which caused an issue where the published frame is different from what the user selected.
      // We are doing an additional step for TikTok here to round down the offset first to avoid
      // this issue. https://business-api.tiktok.com/portal/docs?id=1762228496095234
      const fps = this.mediaList[0]?.frameRate;
      const roundedDownOffset = Math.floor(fps * offset) / fps;
      this.thumbnailOffset = offset
        ? roundedDownOffset && Math.round(roundedDownOffset * 1000)
        : null;
      this.thumbnailSource = source;
    }, 10),
    requiresApproval(post) {
      return postRequiresApproval({ post });
    },
    onToggleDraft(draft) {
      this.isDraft = draft;
      this.trackMixpanel.draftToggleClicked(draft, postTypeLabelMap.tiktok);
    },
    resetThumbnail() {
      this.thumbnailOffset = null;
      this.thumbnailSource = null;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.section-title {
  display: flex;
  align-items: center;
}

.validation-banner {
  margin: var(--space-16) 0 0 0;

  &.carousel-visible {
    margin-top: var(--space-56);
  }
}

.controls {
  position: sticky;
  bottom: 0;
}

.caption-header,
.first-comment-header {
  display: flex;
  justify-content: space-between;

  .red {
    color: var(--error-500);
  }
}

.first-comment-header {
  margin-top: var(--space-16);
}

.first-comment-textarea.disabled {
  opacity: 0.4;
  cursor: auto;
}

.link-tabs {
  display: flex;
  align-items: baseline;

  &:hover {
    transform: none;
  }
}

.link-editors {
  margin-top: var(--space-24);

  .empty-multi-link {
    height: 15rem;
    font-size: var(--x18);
    font-weight: var(--font-medium);
    color: var(--text-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.add-top-margin {
  margin-top: var(--space-32);
}

.empty-control {
  display: flex;
  justify-content: flex-start;
}

.add-to-content {
  height: 14.5rem;
}

input.popup-input {
  margin: var(--space-16) 0 13.5rem;
  width: 100%;
  height: var(--space-40);
  border: 1px solid var(--border);
  border-radius: var(--round-corner-small);
  padding: 0 var(--space-8);
  color: var(--text-primary);
  font-size: var(--x14);
}

.save-button {
  min-width: 10rem;
}

.information-box {
  .links-prompt {
    padding-bottom: var(--space-16);
    text-align: left;
  }

  .links-prompt-buttons {
    display: flex;

    :deep(.xsmall) {
      min-height: var(--space-32);
      min-width: 148px;
      padding: 0 var(--space-12);
      margin-right: var(--space-8);
    }
  }
}

.invalid-field :deep(textarea),
.invalid-field :deep(.editor) {
  border: 1px solid var(--error-500);
  border-radius: var(--round-corner-small);
}

.allow-toggle-options {
  display: flex;
  width: 100%;
  margin-top: var(--space-8);

  .allow-toggle {
    padding-right: var(--space-16);
  }
}
</style>
