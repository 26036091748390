import { logger } from '@/utils/logger';

export function getAsset(path) {
  const imageUrlGlob = import.meta.glob('./**/*', {
    eager: true,
    query: 'url',
  });
  const asset = imageUrlGlob[`./${path}`];
  if (!asset) {
    logger.error(`Could not find image for path: ${path}`);
    return '';
  }
  return asset.default;
}
