import { Buffer } from 'buffer';
import { useIntegrationStore } from '@/stores/integration';
import { backoffRetry } from '@/utils/paragon';

// This is the maximum number of requests which can be batched together according to docs:
// https://learn.microsoft.com/en-us/graph/json-batching
const MAX_BATCH_SIZE = 20;

let pendingRequests = [];

export const useParagonOutlookAPI = () => {
  const integrationStore = useIntegrationStore();

  /**
   * Send a request to the Outlook API via Paragon, batching any requests
   * received within "threshold" milliseconds using the batch API.
   * https://learn.microsoft.com/en-us/graph/json-batching
   *
   * @param path The Outlook API path.
   * @param params Parameters sent to Paragon SDK, such as the method or body.
   * @param threshold Any requests made within this number of milliseconds will be batched.
   * @returns {Promise<Object>}
   */
  async function batchConcurrentRequests(path, params, threshold = 50) {
    async function processRequests() {
      // Immediately copy reference to and empty pending requests list so any
      // requests made after this point in time will be added to a future batch.
      const requestsToSendNow = pendingRequests.slice(0, MAX_BATCH_SIZE);
      pendingRequests = pendingRequests.slice(MAX_BATCH_SIZE);

      if (pendingRequests.length > 0) {
        setTimeout(processRequests, 0);
      }

      try {
        const response = await integrationStore.request('outlook', '$batch', {
          method: 'POST',
          body: {
            requests: requestsToSendNow.map((pendingRequest, i) => ({
              id: `${i}`,
              url: pendingRequest.path,
              headers: {
                'Content-Type': 'application/json',
              },
              ...pendingRequest.params,
            })),
          },
        });
        const responsesByIndex = Object.fromEntries(
          response.responses.map((innerResponse) => [innerResponse.id, innerResponse]),
        );
        requestsToSendNow.forEach((request, i) => {
          const currentResponse = responsesByIndex[i];
          if (currentResponse.status < 200 || currentResponse.status >= 300) {
            const currentError = new Error(
              `Received ${currentResponse.status} response to ${request.path} request! ` +
                'This request was part of a batch of one or more other requests.',
            );
            currentError.request = { path, params };
            currentError.response = currentResponse;
            request.reject(currentError);
          } else {
            request.resolve(currentResponse.body);
          }
        });
      } catch (e) {
        // If an error occurs with the batch request, reject all requests in the batch.
        requestsToSendNow.forEach(({ reject }) => reject(e));
      }
    }

    return new Promise((resolve, reject) => {
      pendingRequests.push({ path, params, resolve, reject });

      if (pendingRequests.length === 1) {
        setTimeout(processRequests, threshold);
      }
    });
  }

  return {
    /**
     * List messages
     * https://learn.microsoft.com/en-us/graph/api/user-list-messages
     *
     * @param query A string search query
     * @param conversationId The ID of the conversation the messages are associated with
     * @param limit The maximum number of messages to return per page
     * @returns {Promise<Object>}
     */
    async getMessages({ query, conversationId, limit }) {
      const params = new URLSearchParams({
        $expand: 'attachments',
      });

      if (limit) {
        params.append('$top', limit);
      }

      if (query) {
        params.append('$search', `"${query}"`);
      }

      if (conversationId) {
        params.append('$filter', `conversationId eq '${conversationId}'`);
      }

      return backoffRetry(() =>
        batchConcurrentRequests(`me/messages?${params.toString()}`, {
          method: 'GET',
        }),
      );
    },

    /**
     * Create a message draft
     * https://learn.microsoft.com/en-us/graph/api/user-post-messages
     *
     * @param subject The subject of the email
     * @param body The content of the email
     * @param to The primary recipients
     * @param cc Carbon copy recipients
     * @param bcc Blind carbon copy recipients
     * @returns {Promise<Object>}
     */
    async createDraft({ subject, body, to, cc, bcc, attachments }) {
      const messagePayload = {
        subject,
        body: {
          contentType: 'HTML',
          content: body,
        },
        toRecipients: to.map((email) => ({
          emailAddress: {
            address: email,
          },
        })),
        ccRecipients: cc.map((email) => ({
          emailAddress: {
            address: email,
          },
        })),
        bccRecipients: bcc.map((email) => ({
          emailAddress: {
            address: email,
          },
        })),
        attachments: attachments?.map(({ filename, mimeType, content }) => ({
          '@odata.type': '#microsoft.graph.fileAttachment',
          name: filename,
          contentType: mimeType,
          contentBytes: Buffer.from(content, 'base64').toString('base64'),
        })),
      };

      return backoffRetry(() =>
        batchConcurrentRequests('me/messages', {
          method: 'POST',
          body: messagePayload,
        }),
      );
    },

    /**
     * Send the draft message
     * https://learn.microsoft.com/en-us/graph/api/message-send
     *
     * @param messageId The ID of the draft that will be sent
     * @returns {Promise<Object>}
     */
    async sendMessage({ messageId }) {
      return backoffRetry(() =>
        batchConcurrentRequests(`me/messages/${messageId}/send`, {
          method: 'POST',
          body: {},
        }),
      );
    },

    /**
     * Forward a message
     * https://learn.microsoft.com/en-us/graph/api/message-forward
     *
     * @param messageId The ID of the draft that will be sent
     * @param comment The comment to add to the forwarded message
     * @param to The recipients
     * @returns {Promise<Object>}
     */
    async forwardMessage({ messageId, comment, to }) {
      return batchConcurrentRequests(`me/messages/${messageId}/forward`, {
        method: 'POST',
        body: {
          comment,
          toRecipients: to.map((email) => ({
            emailAddress: {
              address: email,
            },
          })),
        },
      });
    },

    /**
     * Fetch attachments
     * https://learn.microsoft.com/en-us/graph/api/attachment-get
     *
     * @param messageId The ID of the message of the attachment
     * @param attachmentId The ID of the attachment
     * @returns {Promise<Object>}
     */
    async getAttachment({ messageId, attachmentId }) {
      return backoffRetry(() =>
        batchConcurrentRequests(`me/messages/${messageId}/attachments/${attachmentId}`, {
          method: 'GET',
        }),
      );
    },

    /**
     * Get user information
     * https://learn.microsoft.com/en-us/graph/api/user-get
     */
    async getUser() {
      return backoffRetry(() =>
        batchConcurrentRequests('me', {
          method: 'GET',
        }),
      );
    },

    /**
     * Send reply
     * https://learn.microsoft.com/en-us/graph/api/message-reply
     *
     * @param id The ID of the message
     * @param body The content of the email
     * @param to The primary recipients
     * @param cc Carbon copy recipients
     * @param bcc Blind carbon copy recipients
     * @returns {Promise<Object>}
     */
    async sendReply({ id, body, to, cc, bcc }) {
      const messagePayload = {
        message: {
          toRecipients: to.map((email) => ({
            emailAddress: {
              address: email,
            },
          })),
          ccRecipients: cc.map((email) => ({
            emailAddress: {
              address: email,
            },
          })),
          bccRecipients: bcc.map((email) => ({
            emailAddress: {
              address: email,
            },
          })),
        },
        comment: body,
      };

      return backoffRetry(() =>
        batchConcurrentRequests(`me/messages/${id}/reply`, {
          method: 'POST',
          body: messagePayload,
        }),
      );
    },

    /**
     * Mark a message as read
     * https://learn.microsoft.com/en-us/graph/api/message-update
     *
     * @param {string} messageId The id of the message to mark as read
     * @returns {Promise<Object>}
     */
    async markMessageAsRead({ messageId }) {
      const payload = {
        isRead: true,
      };

      return backoffRetry(() =>
        batchConcurrentRequests(`me/messages/${messageId}`, {
          method: 'PATCH',
          body: payload,
        }),
      );
    },

    /**
     * Get unread message count
     * https://learn.microsoft.com/en-us/graph/api/user-list-messages
     *
     * @returns {Promise<Object>}
     */
    async getUnreadMessageCount({ creatorEmail }) {
      const params = new URLSearchParams({
        $filter: `isRead eq false and from/emailAddress/address eq '${creatorEmail}'`,
        $select: 'conversationId',
        // This is assuming that the unread message count can't be greater than 1000 to avoid dealing with paging
        $top: 1000,
      });
      return backoffRetry(() =>
        batchConcurrentRequests(`/me/mailFolders/inbox/messages?${params.toString()}`, {
          method: 'GET',
        }),
      );
    },
  };
};
