<template>
  <div :class="['media-container', aspectRatio, { thumbnail, draggable }]">
    <div
      class="aspect-ratio-container"
      @mouseover="mediaHovered = true"
      @mouseleave="mediaHovered = false"
    >
      <div v-if="validationError && validationError.icon" class="warning-icon">
        <Icon :name="validationError.icon" xsmall color="var(--error-500)" />
      </div>

      <!-- Video icon -->
      <div v-if="isVideo" class="video-duration">
        <Icon name="video-2" xsmall color="white" />
        <span class="video-duration-text">{{ formattedVideoDuration }}</span>
      </div>

      <!-- Upload progress bar -->
      <RadialProgressBar
        v-if="mediaUploading"
        :completed-steps="mediaCompletedSteps"
        :total-steps="100"
        :diameter="progressDiameter"
        :stroke-width="progressWidth"
        :start-color="iconColor"
        :stop-color="iconColor"
        class="center"
        inner-stroke-color="lightGrey"
      />

      <InfiniteLoader v-if="showSpinner" :use-white-overlay="creatingBrandMedia" />

      <!-- Play/pause controls -->
      <transition name="fade">
        <div v-if="showControls" class="controls center dash-z-20">
          <Icon
            v-if="paused && !videoEnd"
            v-bind="controlsIconSize"
            :color="iconColor"
            class="control-button"
            name="play"
            @click="play"
          />
          <div v-else>
            <Icon
              v-if="!videoEnd"
              v-bind="controlsIconSize"
              :color="iconColor"
              class="control-button"
              name="pause"
              @click="pause"
            />
          </div>
          <Icon
            v-if="showReplayButton"
            v-bind="controlsIconSize"
            :color="iconColor"
            class="control-button"
            name="replay"
            @click="replay"
          />
        </div>
      </transition>

      <!-- Image or video player -->
      <div
        v-if="isImage || showVideoThumbnail"
        :style="{ 'background-image': `url(${mediaUrl})` }"
        :class="{ cover }"
        class="media"
        @playing="updatePaused"
        @pause="updatePaused"
      ></div>
      <video
        v-else
        id="video"
        ref="video"
        :src="mediaUrl"
        :class="{ cover }"
        :poster="videoPoster"
        class="media"
        loop
        @canplay="updateCanPlay"
        @ended="updateVideoEnded"
        @playing="updatePaused"
        @pause="updatePaused"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import RadialProgressBar from '@/vendor/vue-radial-progress/RadialProgressBar.vue';
import Icon from '@/components/foundation/Icon.vue';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import { UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import { mediaIsUploading } from '@/utils/media';
import { formatTime } from '@/utils/formatters';
import { useSchedulerStore } from '@/stores/scheduler';
import { useFlagStore } from '@/stores/flag';
import { tabContexts, fbPostTypes } from '@/app/scheduler/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Media',
  components: {
    Icon,
    InfiniteLoader,
    RadialProgressBar,
  },
  props: {
    media: { type: Object, required: true },
    thumbnail: { type: Boolean, default: false },
    aspectRatio: { type: String, default: 'square' },
    compact: { type: Boolean, default: false },
    showMediaOnly: { type: Boolean, default: false },
    onVideoEnded: { type: Function, default: () => {} },
    cover: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    playing: { type: Boolean, default: null },
    firstVideoPlay: { type: Boolean, default: false },
    useOriginalThumb: { type: Boolean, default: false },
    coverImage: { type: Object, default: null },
    validationError: { type: Object, default: null },
    creatingBrandMedia: { type: Boolean, default: false },
    postType: { type: String, default: null },
    thumbOffset: { type: Number, default: null },
  },
  emits: ['update:playing', 'uploaded-duration'],
  data() {
    return {
      mediaHovered: false,
      canPlay: false,
      paused: true,
      iconColor: colours.BASIC.WHITE,
      playFrom: 0,
      videoDuration: null,
      videoEnd: false,
      videoPlayed: false,
    };
  },
  computed: {
    ...mapStores(useSchedulerStore, useFlagStore),
    hasCrossChannelPublishingFlag() {
      return this.flagStore.ready && this.flagStore.flags.crossChannelPublishing;
    },
    formattedVideoDuration() {
      if (this.isVideo && this.videoDuration) {
        return formatTime(this.videoDuration);
      }
      return null;
    },
    controlsIconSize() {
      return { [this.thumbnail ? 'large' : 'xxlarge']: true };
    },
    progressDiameter() {
      return this.thumbnail ? 50 : 100;
    },
    progressWidth() {
      return this.thumbnail ? 7 : 10;
    },
    thumbnailUrl() {
      const formattedMedia = this.media.fullMediaObject || this.media;
      const thumbnail = this.media?.urls
        ? this.media.urls.thumbs
        : formattedMedia.sizes.smallSquare?.url;
      return this.useOriginalThumb ? thumbnail.replace(/\?\w=.*/g, '') : thumbnail;
    },
    showVideoThumbnail() {
      return this.firstVideoPlay && this.thumbnailUrl;
    },
    mediaUrl() {
      if (this.isUpload) {
        return this.media.previewUrl;
      }
      const formattedMedia = this.media.fullMediaObject || this.media;
      // If image thumbnail is requested, return that
      // (Shared component, must handle library and scheduler media shapes)
      // Old media object will have urls in `this.media.urls`, the rest are MediaV2
      if ((this.isImage || this.isCarousel) && (this.thumbnail || this.small)) {
        return this.thumbnailUrl;
      }
      if (this.isVideo && this.showVideoThumbnail) {
        // match thumbnail on media card, but keep original ratio
        return this.thumbnailUrl.replace('&fit=cover', '');
      }
      // otherwise fallback to original converted/original
      if (this.media.urls) {
        return this.media.urls.full || this.media.urls.original;
      }
      return formattedMedia?.sizes?.originalConverted
        ? formattedMedia?.sizes?.originalConverted?.url
        : formattedMedia?.sizes?.original?.url;
    },
    isUpload() {
      return Boolean(this.media.uploadStatus || this.coverImage?.uploadStatus);
    },
    isImage() {
      return (
        this.media.media_type === 'IMAGE' ||
        this.media.mediaType === 'IMAGE' ||
        this.media.type === 'IMAGE'
      );
    },
    isVideo() {
      return (
        this.media.media_type === 'VIDEO' ||
        this.media.mediaType === 'VIDEO' ||
        this.media.type === 'VIDEO'
      );
    },
    isCarousel() {
      return (
        this.media.media_type === 'CAROUSEL' ||
        this.media.mediaType === 'CAROUSEL' ||
        this.media.type === 'CAROUSEL'
      );
    },
    mediaUploading() {
      return mediaIsUploading(this.media) || mediaIsUploading(this.coverImage);
    },
    mediaCompletedSteps() {
      if (this.media.uploadStatus === UPLOAD_STATUS.UPLOADING) {
        return this.media.uploadProgress;
      }
      return this.coverImage?.uploadProgress;
    },
    mediaProcessing() {
      return (
        this.isUpload &&
        (this.media.uploadStatus === UPLOAD_STATUS.PROCESSING ||
          this.coverImage?.uploadStatus === UPLOAD_STATUS.PROCESSING)
      );
    },
    showSpinner() {
      return this.creatingBrandMedia || this.mediaProcessing;
    },
    mediaReady() {
      return (
        !this.isUpload ||
        this.media.uploadStatus === UPLOAD_STATUS.SUCCESS ||
        this.coverImage?.uploadStatus === UPLOAD_STATUS.SUCCESS
      );
    },
    showControls() {
      return (
        this.isVideo &&
        this.mediaReady &&
        this.canPlay &&
        (this.mediaHovered || this.videoEnd) &&
        !this.showMediaOnly &&
        this.schedulerStore.activeSubScreen !== 'tagProducts'
      );
    },
    videoPoster() {
      return (
        this.coverImage?.sizes?.originalConverted?.url ||
        this.coverImage?.previewUrl ||
        this.media.thumbnailUrl
      );
    },
    isReel() {
      return (
        this.schedulerStore.instagramTabContext === tabContexts.REEL ||
        this.postType === fbPostTypes.REEL
      );
    },
    showReplayButton() {
      // Show replay button if the reel video is played or ended.
      return this.isReel && (this.videoPlayed || this.videoEnd);
    },
  },
  watch: {
    mediaUrl(newVal) {
      if (newVal) {
        this.paused = true;
        this.applyThumbOffset();
      }
    },
    thumbOffset: {
      handler() {
        this.applyThumbOffset();
      },
    },
    'schedulerStore.instagramTabContext': {
      handler(newVal) {
        if (!this.hasCrossChannelPublishingFlag) {
          if (this.$refs.video) {
            this.$refs.video.loop = !(newVal === tabContexts.REEL && this.isReel);
          }
          this.replay();
        }
      },
    },
    coverImage: {
      handler(to) {
        // Video poster only works before the video is played.
        // Reset the video here to show the selected cover photo.
        if (to) {
          this.videoPlayed = false;
          this.$refs.video.load();
          this.$refs.video.currentTime = 0;
          this.playFrom = this.$refs.video.currentTime;
          this.paused = true;
        } else {
          this.applyThumbOffset();
        }
      },
    },
    paused(newVal) {
      this.$emit('update:playing', !newVal);
    },
    firstVideoPlay(to) {
      if (!to) {
        this.play();
      }
    },
    playing: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.play();
        } else if (newVal === false) {
          this.pause();
        }
      },
    },
  },
  mounted() {
    this.applyThumbOffset();
    // For Reels, we do not loop videos so users are able to see their selected cover image
    if (this.isReel && this.$refs.video) {
      this.$refs.video.loop = false;
    }
    if (!this.paused) {
      this.play();
    }
  },
  updated() {
    if (!this.paused) {
      this.play();
    }
  },
  beforeUnmount() {
    if (this.isVideo && this.$refs?.video) {
      const { video } = this.$refs;

      video.pause();
      video.removeAttribute('src');
      video.load();
    }
  },
  methods: {
    play() {
      if (this.$refs.video?.paused) {
        this.$refs.video.currentTime = this.playFrom;
        this.$refs.video.play();
      } else {
        this.paused = false;
      }
    },
    pause() {
      if (this.$refs.video) {
        this.$refs.video.pause();
        this.playFrom = this.$refs.video.currentTime;
      } else {
        this.paused = true;
      }
    },
    replay() {
      this.playFrom = 0;
      this.$refs?.video?.load?.();
      this.paused = true;
      this.videoEnd = false;
      this.videoPlayed = false;
    },
    updateVideoEnded() {
      if (this.isReel) {
        this.videoEnd = true;
      }
      this.onVideoEnded();
    },
    updateCanPlay() {
      this.canPlay = true;
      this.videoDuration = this.getVideoDuration();
    },
    updatePaused(event) {
      this.paused = event.target.paused;
      this.videoPlayed = true;
    },
    getVideoDuration() {
      if (this.isVideo) {
        // Videos that have just been uploaded will not have a duration
        if (this.media.duration) {
          return this.media.duration.toFixed(1);
        }
        if (this.$refs.video && this.$refs.video.duration) {
          const uploadedDuration = this.$refs.video.duration.toFixed(1);
          this.$emit('uploaded-duration', uploadedDuration);
          return uploadedDuration;
        }
        return null;
      }
      return null;
    },
    applyThumbOffset() {
      if (this.$refs.video && parseInt(this.thumbOffset, 10) >= 0 && !this.coverImage) {
        // Under some conditions (e.g. a video is cropped and a new media is downloaded), this
        // callback will be executed too early and changing thumbOffset won't have any effect.
        // Waiting until next tick fixes this.
        nextTick(() => {
          this.$refs.video.currentTime = this.thumbOffset;
        });

        // Play from should be reset to the start of video when cover image is changed
        this.playFrom = 0;
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.aspect-ratio-container {
  width: 100%;
  height: 100%;
}

.square {
  aspect-ratio: 1 / 1;
}

.portrait {
  aspect-ratio: 9 / 16;
}

.unset {
  max-width: 100%;
  width: fit-content;
  margin: auto;

  #video {
    display: block;
    max-height: 22rem;
    max-width: 100%;
    height: auto;
    width: auto;
  }
}

.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--background-500);
  border-radius: var(--round-corner-small);
  overflow: hidden;
  height: 100%;

  &.draggable {
    cursor: pointer;
  }

  .media {
    height: 100%;
    width: 100%;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 0;

    &.cover {
      object-fit: cover;
      background-size: cover;
    }
  }
}

.thumbnail {
  width: var(--space-80);
  height: var(--space-80);
  margin-bottom: 0;
}

.video-icon {
  position: absolute;
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 15%));

  .compact-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.controls {
  background: rgba(0 0 0 / 30%);
}

.control-button {
  cursor: pointer;
  margin-left: var(--space-20);
  margin-right: var(--space-20);
}

.center {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-duration {
  position: absolute;
  bottom: 10px;
  right: 12px;
  z-index: 4;

  .icon {
    position: relative;
    top: 3px;
  }

  .video-duration-text {
    padding-left: var(--space-4);
    color: var(--white);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    line-height: var(--space-18);
  }
}

.warning-icon {
  position: absolute;
  bottom: 10px;
  left: 12px;
  z-index: 4;
  background-color: var(--white);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
