import kebabCase from 'lodash/kebabCase';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export const BASIC = Object.freeze({
  BLACK: '#000',
  WHITE: '#fff',
  ALMOST_BLACK: '#282828',
});

export const BRAND = Object.freeze({
  BRAND_PRIMARY: '#2a2a2a',
  BRAND_ACCENT: '#2a2a2a',
});

export const ACTION = Object.freeze({
  ACTION_900: '#00234c',
  ACTION_700: '#004799',
  ACTION_500: '#4990e2',
  ACTION_400: '#57aof6',
  ACTION_200: '#abcffb',
  ACTION_150: '#d5e8ff',
  ACTION_100: '#f3f9fe',
  ACTION_50: '#f9fcff',
});

export const ERROR = Object.freeze({
  ERROR_900: '#4d0e00',
  ERROR_700: '#b63619',
  ERROR_500: '#e36042',
  ERROR_400: '#fb7050',
  ERROR_200: '#fdb7a7',
  ERROR_100: '#fff1ee',
  ERROR_50: '#fff8f7',
});

export const SUCCESS = Object.freeze({
  SUCCESS_900: '#0c4017',
  SUCCESS_700: '#257837',
  SUCCESS_500: '#24c449',
  SUCCESS_400: '#3fd65f',
  SUCCESS_200: '#9feaaf',
  SUCCESS_100: '#e0f8e5',
  SUCCESS_50: '#effbf2',
});

export const ALERT = Object.freeze({
  ALERT_900: '#563c10',
  ALERT_700: '#8e5d0b',
  ALERT_500: '#f5a623',
  ALERT_400: '#feb43a',
  ALERT_200: '#ffda9d',
  ALERT_100: '#fff6e7',
  ALERT_50: '#fffbf3',
});

export const TEXT = Object.freeze({
  TEXT_PRIMARY: BASIC.BLACK,
  TEXT_SECONDARY: '#686a7a',
  TEXT_LINK: ACTION.ACTION_500,
});

export const ICON = Object.freeze({
  ICON_PRIMARY: TEXT.TEXT_PRIMARY,
  ICON_SECONDARY: TEXT.TEXT_SECONDARY,
  ICON_LINK: TEXT.TEXT_LINK,
});

export const BACKGROUND = Object.freeze({
  BACKGROUND_900: BASIC.BLACK,
  BACKGROUND_700: TEXT.TEXT_SECONDARY,
  BACKGROUND_500: '#e8ecf4',
  BACKGROUND_400: '#f2f5fb',
  BACKGROUND_300: '#f9fbff',
  BACKGROUND_50: '#f9fbff',
  BACKGROUND_0: BASIC.WHITE,
});

export const BORDER = Object.freeze({
  BORDER: '#ddd',
});

export const SOCIAL_CHANNEL = Object.freeze({
  SC_FACEBOOK: '#0068eb',
  SC_INSTAGRAM: 'linear-gradient(225deg, #1400C8 0%, #B900B4 50%, #F50000 100%)',
  SC_LINKEDIN: '#0077b5',
  SC_PINTEREST: '#bd081b',
  SC_TIKTOK: BASIC.BLACK,
  SC_TWITTER: BASIC.BLACK,
  SC_YOUTUBE: '#ff0000',
  SC_THREADS: '#1e1e1e',
  SC_BLUESKY: '#0B7BFF',
});

export const CHART = Object.freeze({
  SET: {
    CHART_SET_01: '#4990e2',
    CHART_SET_02: '#27c449',
    CHART_SET_03: '#f6a623',
    CHART_SET_04: '#e36042',
    CHART_SET_05: '#f6d423',
    CHART_SET_06: '#39bce6',
    CHART_SET_07: '#8943ff',
    CHART_SET_08: '#51cc92',
    CHART_SET_09: '#7597f0',
    CHART_SET_10: '#dd82e5',
    CHART_SET_11: '#c26c47',
    CHART_SET_12: '#97cb28',
    CHART_SET_13: '#ed8071',
    CHART_SET_14: '#87b2f2',
    CHART_SET_15: '#c9e321',
    CHART_SET_16: '#f0649a',
    CHART_SET_17: '#63e759',
    CHART_SET_18: '#69dcdd',
    CHART_SET_19: '#c5994c',
    CHART_SET_20: '#bd7C9b',
    CHART_SET_21: '#84ae63',
    CHART_SET_22: '#0B23FF',
    CHART_SET_23: '#156552',
    CHART_SET_24: '#2C0DA1',
  },
  GRADIENT: {
    CHART_GRADIENT_01: 'rgba(73, 144, 226, 0.3)',
    CHART_GRADIENT_02: 'rgba(39, 196, 73, 0.3)',
    CHART_GRADIENT_FADE: 'rgba(255, 255, 255, 0)',
  },
  WILDCARD: '#666666',
  BACKGROUND: BASIC.WHITE,
  BACKGROUND_BAR: 'rgb(0 0 0 / 2%)',
  HOVER_LINE: 'rgb(74 144 226 / 39%)',
});

export const CHART_SOCIAL_CHANNEL = Object.freeze({
  CHART_SC_FACEBOOK: CHART.SET.CHART_SET_07,
  CHART_SC_INSTAGRAM: CHART.SET.CHART_SET_08,
  CHART_SC_PINTEREST: CHART.SET.CHART_SET_06,
  CHART_SC_TWITTER: CHART.SET.CHART_SET_03,
  CHART_SC_IG_STORIES: CHART.SET.CHART_SET_15,
  CHART_SC_IG_UGC: CHART.SET.CHART_SET_04,
  CHART_SC_TIKTOK: CHART.SET.CHART_SET_01,
  CHART_SC_YOUTUBE: CHART.SET.CHART_SET_10,
  CHART_SC_META_ADS: CHART.SET.CHART_SET_18,
  CHART_SC_LINKEDIN: CHART.SET.CHART_SET_22,
  CHART_SC_THREADS: CHART.SET.CHART_SET_09,
});

export const TRANSPARENCY = Object.freeze({
  BLACK_ALPHA_70: 'rgb(0 0 0 / 70%)',
  BLACK_ALPHA_40: 'rgb(0 0 0 / 40%)',
  MEDIA_CARD_EDGE_GRADIENT:
    'linear-gradient(0deg, rgb(255  255 255 / 0%) 0%, rgb(0 0 0 / 20%) 100%)',
});

export const MARKETING = Object.freeze({
  MARKETING_LIGHT_BLUE: '#edf4fc',
});

export const VISION_AI = Object.freeze({
  VISION_AI_01: '#8CA5FF',
  VISION_AI_02: '#30A0C4',
  VISION_AI_03: '#4990E2',
  VISION_AI_BUTTON_GRADIENT:
    'linear-gradient(45deg, rgb(140 165 255 / 100%) 0%, rgb(48 160 196 / 100%) 100%)',
});

export function generateVarNames(names) {
  if (isObject(names)) {
    return Object.keys(names).map((key) => `var(--${kebabCase(key)})`);
  }
  if (Array.isArray(names)) {
    return names.map((key) => `var(--${kebabCase(key)})`);
  }
  if (isString(names)) {
    return `var(--${kebabCase(names)})`;
  }
  return [];
}
export function getColourLevel(name) {
  if (!isString(name)) {
    return null;
  }

  const endValue = name.split('_').pop();

  return Number(endValue);
}

export function getChartColour(index) {
  const chartColours = Object.values(CHART.SET);
  return chartColours[index % chartColours.length];
}

export const colours = {
  BASIC,
  BRAND,
  ACTION,
  ERROR,
  SUCCESS,
  ALERT,
  TEXT,
  ICON,
  BACKGROUND,
  BORDER,
  SOCIAL_CHANNEL,
  CHART,
  CHART_SOCIAL_CHANNEL,
  TRANSPARENCY,
  MARKETING,
};
