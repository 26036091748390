import { computed } from 'vue';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { BRAND, ORGANIZATION_USER } from '@/models/auth/permissions.enum';

export function useContentAutomationPermissions() {
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const brandsWithAccessToContentAutomations = computed(() => {
    if (!identityStore.identity?.brands) return {};
    return Object.fromEntries(
      Object.entries(identityStore.identity.brands).filter(([, brandValue]) => {
        return brandValue.permissions?.settings?.can_access_content_automations;
      }),
    );
  });

  const canAccessContentAutomations = computed(() => {
    return (
      identityStore.checkIfBrandPermissionsExist(
        [BRAND.SETTINGS.CAN_ACCESS_CONTENT_AUTOMATION],
        [identityStore.currentBrand],
      ) &&
      flagStore.ready &&
      (flagStore.flags?.contentAutomationGalleries ||
        flagStore.flags?.contentAutomationContentTags ||
        flagStore.flags?.contentAutomationCampaigns ||
        flagStore.flags?.contentAutomationBoards)
    );
  });

  const canAccessContentAutomationsWithCampaigns = computed(() => {
    return (
      identityStore.checkIfBrandPermissionsExist(
        [BRAND.SETTINGS.CAN_ACCESS_CONTENT_AUTOMATION],
        [identityStore.currentBrand],
      ) &&
      flagStore.ready &&
      flagStore.flags?.contentAutomationCampaigns
    );
  });

  const canManageContentAutomations = computed(() => {
    return (
      identityStore.guard(
        ORGANIZATION_USER.SETTINGS.CAN_MANAGE_CONTENT_AUTOMATIONS,
        identityStore.currentBrand,
      ) || identityStore.isCurrentBrandRoleAdmin
    );
  });

  return {
    brandsWithAccessToContentAutomations,
    canAccessContentAutomations,
    canAccessContentAutomationsWithCampaigns,
    canManageContentAutomations,
  };
}
