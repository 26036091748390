import { tiktokBoardsDetailSortOptions } from '@/app/tiktok/constants';
import { facebookGalleriesDetailSortOptions } from '@/app/facebook/constants';
import { twitterGalleriesDetailSortOptions } from '@/app/twitter/constants';
import enumTypes, { likeshopBoardsDetailSortOptions } from '@/app/library/constants';
import {
  instagramBoardsDetailSortOptions,
  instagramBoardsDetailSortOptionsDeprecated,
} from '@/app/instagram/constants';
import { youtubeGalleriesDetailSortOptions } from '@/app/youtube/constants';
import { pinterestGalleriesDetailSortOptions } from '@/app/pinterest/constants';

export const GALLERY_DETAIL_SORT_OPTIONS = {
  TIKTOK: tiktokBoardsDetailSortOptions,
  INSTAGRAM: instagramBoardsDetailSortOptionsDeprecated,
  INSTAGRAM_UPDATED: instagramBoardsDetailSortOptions,
  INSTAGRAM_LIKESHOP: likeshopBoardsDetailSortOptions,
  FACEBOOK: facebookGalleriesDetailSortOptions,
  PINTEREST: pinterestGalleriesDetailSortOptions,
  TWITTER: twitterGalleriesDetailSortOptions,
  YOUTUBE: youtubeGalleriesDetailSortOptions,
  DEFAULT: [
    {
      label: 'Recently Published',
      value: '-POSTED',
    },
    {
      label: 'Recently Added',
      value: '-ADDED',
    },
  ],
};

export const GALLERY_DETAIL_SORT_OPTIONS_STYLE = {
  TIKTOK: ['cols-2'],
  FACEBOOK: ['cols-2-reduced-width'],
  TWITTER: ['cols-2-reduced-width'],
  INSTAGRAM: ['cols', 'sorted-vertically'],
  YOUTUBE: ['cols', 'sorted-vertically'],
};

export const GALLERY_DETAIL_SORT_COLUMNS = {
  TIKTOK: 2,
  FACEBOOK: 2,
  TWITTER: 2,
  YOUTUBE: 2,
  INSTAGRAM: 2,
};

export const GALLERY_TITLE = {
  INSTAGRAM: 'IG Board',
  INSTAGRAM_STORY: 'Story Board',
  INSTAGRAM_LIKESHOP: 'LikeShop',
  LIBRARY: 'Gallery',
};

export const GALLERY_MEDIA_NAMES = {
  TIKTOK: 'Videos',
  INSTAGRAM_STORY: 'Story Frames',
  PINTEREST: 'Pins',
  TWITTER: 'Posts',
  YOUTUBE: 'Videos',
};

export const GALLERY_ADD_TYPES = {
  TIKTOK: 'Videos',
  INSTAGRAM_STORY: 'Frames',
  YOUTUBE: 'Videos',
};

export const GALLERY_NAMES = {
  TWITTER: 'X Board',
  TIKTOK: 'TikTok Board',
  INSTAGRAM: 'Board',
  INSTAGRAM_STORY: 'Story Board',
  LIBRARY: 'Gallery',
  YOUTUBE: 'YouTube Board',
};

export const GALLERY_SOURCES = {
  TIKTOK: enumTypes.TIKTOK,
  INSTAGRAM: enumTypes.INSTAGRAM,
  INSTAGRAM_STORY: enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES,
  FACEBOOK: enumTypes.FACEBOOK,
  PINTEREST: enumTypes.PINTEREST,
  TWITTER: enumTypes.TWITTER,
  YOUTUBE: enumTypes.YOUTUBE,
};

export const GALLERY_STATS = {
  TWITTER: [
    {
      label: 'Total Engagements',
      valueName: 'twitterTotalEngagements',
      format: null,
      localize: true,
    },
    {
      label: 'Avg. Engagement Rate',
      valueName: 'twitterAvgEngagementRate',
      format: 'percent',
      localize: false,
      unavailableInCompetitive: true,
    },
    {
      label: 'Reposts',
      valueName: 'twitterRetweets',
      format: null,
      localize: true,
    },
    {
      label: 'Likes',
      valueName: 'twitterLikes',
      format: null,
      localize: false,
    },
    {
      label: 'Impressions',
      valueName: 'twitterImpressions',
      format: null,
      localize: false,
      unavailableInCompetitive: true,
    },
  ],
  FACEBOOK: [
    {
      label: 'Total Engagements',
      valueName: 'fbTotalEngagements',
      format: null,
      localize: false,
    },
    {
      label: 'Avg. Engagement Rate',
      valueName: 'fbAvgEngagementRate',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Avg. Effectiveness',
      valueName: 'fbAvgEffectiveness',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Avg. Reach',
      valueName: 'fbAvgReach',
      format: null,
    },
    {
      label: 'Impressions',
      valueName: 'fbTotalImpressions',
      format: null,
      localize: false,
    },
  ],
  PINTEREST: [
    {
      label: 'Avg. Engagement Rate',
      valueName: 'pinterestAvgEngagementRate',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Outbound Clicks',
      valueName: 'pinterestTotalClicks',
      format: null,
    },
    {
      label: 'Saves',
      valueName: 'pinterestTotalSaves',
      format: null,
    },
    {
      label: 'Impressions',
      valueName: 'pinterestTotalImpressions',
      format: null,
    },
    {
      label: 'Pin Clicks',
      valueName: 'pinterestTotalCloseups',
      format: null,
    },
  ],
  INSTAGRAM: [
    {
      label: 'Total Reach',
      valueName: 'instagramTotalReach',
      format: null,
      localize: false,
    },
    {
      label: 'Total Likes',
      valueName: 'instagramTotalLikes',
      format: null,
      localize: false,
    },
    {
      label: 'Total Comments',
      valueName: 'instagramTotalComments',
      format: null,
    },
    {
      label: 'Avg. Engagement Rate',
      valueName: 'instagramAvgEngagement',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Avg. Effectiveness Rate',
      valueName: 'instagramAvgEffectiveness',
      format: 'percent',
      localize: false,
    },
  ],
  INSTAGRAM_STORY: [
    {
      label: 'Avg. Reach',
      valueName: 'igStoryAvgReach',
      format: null,
      localize: false,
    },
    {
      label: 'Total Impressions',
      valueName: 'igStoryTotalImpressions',
      format: null,
      localize: false,
    },
    {
      label: 'Avg. Completion Rate',
      valueName: 'igStoryAvgCompletionRate',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Avg. Exit Rate',
      valueName: 'igStoryAvgExitRate',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Total Link Clicks',
      valueName: 'igStorySwipeUps',
      format: null,
      localize: false,
    },
  ],
  TIKTOK: [
    {
      label: 'Total Video Views',
      valueName: 'tiktokTotalVideoViews',
      format: null,
      localize: true,
    },
    {
      label: 'Avg. Video Views',
      valueName: 'tiktokAvgVideoViews',
      format: null,
      localize: true,
    },
    {
      label: 'Avg. Retention Rate',
      valueName: 'tiktokAvgRetentionRate',
      format: 'percent',
      localize: false,
    },
    {
      label: 'Avg. Engagement Rate',
      valueName: 'tiktokAvgEngagementRate',
      format: 'percent',
      localize: false,
    },
  ],
  YOUTUBE: [
    {
      label: 'Video Views',
      valueName: 'youtubeTotalVideoViews',
      format: null,
      localize: true,
    },
    {
      label: 'Watch Time',
      valueName: 'youtubeTotalWatchTime',
      format: 'duration',
      localize: false,
      unavailableInCompetitive: true,
    },
    {
      label: 'Avg. View Duration',
      valueName: 'youtubeAvgViewDuration',
      format: 'duration',
      localize: false,
      unavailableInCompetitive: true,
    },
    {
      label: 'Total Engagements',
      valueName: 'youtubeTotalEngagements',
      format: null,
      localize: true,
      unavailableInCompetitive: true,
    },
    {
      label: 'Subscribers',
      valueName: 'youtubeSubscribers',
      format: null,
      localize: true,
      unavailableInCompetitive: true,
    },
  ],
};

export const GALLERY_TYPE_NAMES = {
  CAMPAIGNS: 'Campaigns',
  LIBRARY: 'Library',
  INSTAGRAM: 'Instagram',
  INSTAGRAM_STORY: 'Instagram_Story',
  TIKTOK: 'TikTok',
  PINTEREST: 'Pinterest',
  FACEBOOK: 'Facebook',
  TWITTER: 'X',
  YOUTUBE: 'YouTube',
};

// parent component name: source
export const GALLERY_ADDITION_SOURCES = {
  CampaignDetails: 'campaign detail page',
  Galleries: 'board list page',
  GalleriesControls: 'board list page',
  MultiSelectTopBar: 'multi select',
  MediaActionButtons: 'media popup',
};
