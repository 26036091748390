import { defineAsyncComponent } from 'vue';
import { constants } from '@/config';
import instagramConstants from './constants';

const Overview = () => import('@/app/instagram/pages/Overview.vue');
const OverviewReport = () => import('@/app/instagram/pages/OverviewReport.vue');
const App = () => import('@/app/instagram/App.vue');
const AccountOverview = () => import('@/app/instagram/pages/Insights/AccountOverview.vue');
const CompetitiveClusterDetail = () => import('@/app/instagram/pages/CompetitiveClusterDetail.vue');
const ContentRights = () => import('@/app/instagram/pages/UGC/ContentRights.vue');
const ContentRightsSettings = () => import('@/app/instagram/pages/UGC/ContentRightsSettings.vue');
const InstagramGallery = () => import('@/app/instagram/pages/InstagramGallery.vue');
const StoriesGallery = () => import('@/app/instagram/pages/Stories/StoryGallery.vue');
const GalleryEdit = () => import('@/components/Galleries/GalleryEdit.vue');
const BoardsWrapper = () => import('@/app/boards/pages/BoardsWrapper.vue');
const HashtagMonitor = () => import('@/app/instagram/pages/UGC/HashtagMonitor.vue');
const InfluentialFans = () => import('@/app/instagram/pages/Insights/InfluentialFans.vue');
const LikeShop = () => import('@/app/ecommerce/pages/LikeShopWrapper.vue');
const OrganicReach = () => import('@/app/instagram/pages/UGC/OrganicReach.vue');
const TrendEdit = () => import('@/app/instagram/components/TrendEdit.vue');
const ViqCompetitive = () => import('@/app/instagram/pages/ViqCompetitive.vue');
const ViqOwned = () => import('@/app/instagram/pages/ViqOwned.vue');
const ViqTrend = () => import('@/app/instagram/pages/ViqTrend.vue');
const ViqTrends = () => import('@/app/instagram/pages/ViqTrends.vue');
const ViqUgc = () => import('@/app/instagram/pages/ViqUgc.vue');
const YourFollowers = () => import('@/app/instagram/pages/Insights/YourFollowers.vue');
const YourPosts = () => import('@/app/instagram/pages/Insights/YourPosts.vue');
const StoryFrames = () => import('@/app/instagram/pages/Stories/StoryFrames.vue');
const StoryNarrative = () => import('@/app/instagram/pages/Stories/Narrative.vue');
const StoryOverview = () => import('@/app/instagram/pages/Stories/Overview.vue');
const StoryMetricsReport = () => import('@/app/instagram/pages/Stories/StoryMetricsReport.vue');
const StoryOverviewReport = () => import('@/app/instagram/pages/Stories/StoryOverviewReport.vue');
const StoryFramesReport = () => import('@/app/instagram/pages/Stories/StoryFramesReport.vue');
const InstagramUgcCommentPopup = defineAsyncComponent(
  () => import('@/app/instagram/components/UGC/InstagramUgcCommentPopup.vue'),
);
const StoryBoardPopup = defineAsyncComponent(
  () => import('@/app/dashboards/components/StoryBoardPopup.vue'),
);

const PAGE_LEVEL_TOP = `Instagram`;
const PAGE_LEVEL_VIQ = `Visual IQ`;
const PAGE_LEVEL_TRENDS = 'Trends';
const PAGE_LEVEL_UGC = `UGC`;
const PAGE_LEVEL_BOARDS = `Boards`;
const PAGE_LEVEL_YOUR_STORIES = `Your Stories`;
const PAGE_LEVEL_STORIES = `Stories`;
const PAGE_LEVEL_INSIGHTS = `Insights`;

export default {
  path: 'instagram',
  component: App,
  meta: {
    analytics: `${PAGE_LEVEL_TOP}`,
    requiresAuth: true,
  },
  children: [
    {
      name: 'instagram',
      path: '',
      redirect: { name: 'instagram.overview' },
    },
    {
      name: 'instagram.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: 'Instagram - Overview',
      },
    },
    {
      name: 'instagram.overview.report',
      path: 'overview/report',
      component: OverviewReport,
      meta: {
        report: true,
        analytics: `${PAGE_LEVEL_TOP} - Overview - Report`,
      },
    },
    {
      name: 'instagram.viq.owned',
      path: 'viq/owned',
      component: ViqOwned,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - VIQ Owned`,
      },
    },
    {
      name: 'instagram.media',
      path: 'media/:id',
      redirect: { name: 'library.media', query: { source: 'INSTAGRAM' } },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - View Media`,
      },
    },
    {
      name: 'instagram.viq.competitive',
      path: 'viq/competitive',
      component: ViqCompetitive,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - List VIQ Competitive`,
      },
    },
    {
      name: 'instagram.viq.competitive.detail',
      path: 'viq/competitive/:id',
      component: CompetitiveClusterDetail,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - View VIQ Competitive`,
      },
    },
    {
      path: 'viq/trends',
      children: [
        {
          name: 'instagram.viq.trends',
          path: '',
          component: ViqTrends,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - View VIQ Trends`,
          },
          children: [
            {
              name: 'instagram.viq.trend.new',
              path: 'new',
              component: TrendEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_VIQ} - ${PAGE_LEVEL_TRENDS} - New`,
              },
            },
          ],
        },
        {
          name: 'instagram.viq.trend',
          path: ':id',
          component: ViqTrend,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - View VIQ Trend Detail`,
          },
          children: [
            {
              name: 'instagram.viq.trend.edit',
              path: 'edit',
              component: TrendEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_VIQ} - ${PAGE_LEVEL_TRENDS} - Edit`,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'instagram.viq.trend.report',
      path: 'viq/trends/:id/report',
      component: ViqTrend,
      meta: {
        report: true,
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_VIQ} - ${PAGE_LEVEL_TRENDS} - Detail Report`,
      },
    },
    {
      name: 'instagram.viq.ugc',
      path: 'viq/ugc',
      component: ViqUgc,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - VIQ UGC`,
      },
    },
    {
      name: 'instagram.ugc.hashtag',
      path: 'ugc/hashtag',
      component: HashtagMonitor,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_UGC} - Hashtag`,
      },
    },
    {
      name: 'instagram.ugc.reach',
      path: 'ugc/reach',
      component: OrganicReach,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_UGC} - Organic Reach`,
      },
    },
    {
      name: 'instagram.ugc.content-rights',
      path: 'ugc/content-rights',
      component: ContentRights,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_UGC} - Content Rights`,
      },
    },
    {
      name: 'instagram.ugc.content-rights-settings',
      path: 'ugc/content-rights-settings',
      component: ContentRightsSettings,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_UGC} - Content Rights Settings`,
      },
    },
    {
      name: 'instagram.ugc.comments',
      path: 'ugc/comments/:postId',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Instagram UGC comment`,
        modal: {
          component: InstagramUgcCommentPopup,
        },
      },
    },
    {
      name: 'instagram.likeshop',
      path: 'likeshop',
      component: LikeShop,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - LikeShop`,
      },
    },
    {
      path: 'boards',
      children: [
        {
          name: 'instagram.galleries',
          path: '',
          component: BoardsWrapper,
          props: {
            galleryType: constants.INSTAGRAM,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_BOARDS}`,
          },
          children: [
            {
              name: 'instagram.galleries.new',
              path: 'new',
              component: GalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_BOARDS} - New`,
              },
            },
          ],
        },
        {
          name: 'instagram.galleries.board',
          path: ':id',
          component: InstagramGallery,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_BOARDS} - Detail`,
          },
          children: [
            {
              name: 'instagram.galleries.edit',
              path: 'edit',
              component: GalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_BOARDS} - Edit`,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'instagram.galleries.boards.report',
      path: 'boards/:id/report',
      component: InstagramGallery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_BOARDS} - Detail Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: 'instagram.stories.galleries',
      path: 'stories/boards',
      redirect: { name: instagramConstants.routeNames.STORY_BOARDS },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_STORIES} - Boards`,
      },
    },
    {
      name: 'instagram.stories.galleries.gallery',
      path: 'stories/boards/:id',
      component: StoriesGallery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_STORIES} - Boards - Detail`,
      },
      children: [
        {
          name: 'instagram.stories.galleries.edit',
          path: 'edit',
          component: GalleryEdit,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_STORIES} - Boards - Edit`,
          },
        },
      ],
    },
    {
      name: 'instagram.stories.galleries.report',
      path: 'stories/boards/:id/report',
      component: StoriesGallery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_STORIES} - Boards - Detail Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: 'instagram.insights.accountOverview',
      path: 'insights/account-overview',
      component: AccountOverview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Account Overview`,
      },
    },
    {
      name: 'instagram.insights.accountOverview.report',
      path: 'insights/account-overview/report',
      component: AccountOverview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Account Overview Report`,
        report: true,
      },
    },
    {
      name: 'instagram.insights.yourFollowers',
      path: 'insights/your-followers',
      component: YourFollowers,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Followers`,
      },
    },
    {
      name: 'instagram.insights.influentialFans',
      path: 'insights/influential-fans',
      component: InfluentialFans,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Influential Fans`,
      },
    },
    {
      name: 'instagram.insights.yourPosts',
      path: 'insights/your-posts',
      component: YourPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Posts`,
      },
    },
    {
      name: 'instagram.insights.yourPosts.report',
      path: 'insights/your-posts/report',
      component: YourPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Posts Report`,
        report: true,
      },
    },
    {
      path: 'your-stories',
      children: [
        {
          path: 'story-overview',
          children: [
            {
              name: instagramConstants.routeNames.STORY_NARRATIVE,
              path: ':id',
              component: StoryNarrative,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Overview - Detail`,
                narrativeRoute: true,
              },
            },
            {
              name: instagramConstants.routeNames.STORY_NARRATIVE_POPUP,
              path: ':id',
              component: StoryNarrative,
              meta: {
                modal: {
                  component: StoryBoardPopup,
                },
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Overview - Detail`,
                narrativeRoute: true,
              },
            },
            {
              name: instagramConstants.routeNames.STORY_OVERVIEW,
              path: '',
              component: StoryOverview,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Overview`,
              },
            },
          ],
        },
        {
          name: instagramConstants.routeNames.STORY_METRICS_REPORT,
          path: 'story-metrics-report',
          component: StoryMetricsReport,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Metrics Report`,
            report: true,
          },
        },
        {
          name: instagramConstants.routeNames.STORY_OVERVIEW_REPORT,
          path: 'story-overview-report',
          component: StoryOverviewReport,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Overview Report`,
            report: true,
            galleryType: constants.INSTAGRAM_STORY_NARRATIVE,
          },
        },
        {
          name: instagramConstants.routeNames.STORY_FRAMES,
          path: 'story-frames',
          component: StoryFrames,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Frames`,
          },
        },
        {
          name: 'instagram.yourStories.storyFrames.report',
          path: 'story-frames/report',
          component: StoryFrames,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Frames Report`,
            report: true,
            hideNav: true,
            hideVisionAi: true,
          },
        },
        {
          path: 'story-boards',
          children: [
            {
              name: instagramConstants.routeNames.STORY_BOARDS,
              path: '',
              component: BoardsWrapper,
              props: {
                galleryType: constants.INSTAGRAM_STORY,
              },
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Boards`,
              },
              children: [
                {
                  name: 'instagram.yourStories.storyBoards.new',
                  path: 'new',
                  component: GalleryEdit,
                  meta: {
                    analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Boards - New`,
                  },
                },
              ],
            },
            {
              name: instagramConstants.routeNames.STORY_BOARDS_DETAILS,
              path: ':id',
              component: StoriesGallery,
              props: {
                galleryType: constants.INSTAGRAM_STORY,
              },
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Boards - Detail`,
              },
              children: [
                {
                  name: instagramConstants.routeNames.STORY_BOARDS_DETAILS_EDIT,
                  path: 'edit',
                  component: GalleryEdit,
                  meta: {
                    analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Boards - Edit`,
                  },
                },
              ],
            },
          ],
        },
        {
          name: instagramConstants.routeNames.STORY_BOARDS_REPORT,
          path: 'story-boards/report',
          component: StoryOverviewReport,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story Boards Report`,
            report: true,
            galleryType: constants.INSTAGRAM_STORY,
          },
        },
        {
          name: instagramConstants.routeNames.STORY_NARRATIVE_REPORT,
          path: 'story/:id/report',
          component: StoryFramesReport,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Story - Detail Report`,
            report: true,
          },
        },
        {
          name: instagramConstants.routeNames.STORY_BOARD_REPORT,
          path: 'board/:id/report',
          component: StoryFramesReport,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_YOUR_STORIES} - Board - Detail Report`,
            report: true,
          },
        },
      ],
    },
  ],
};
