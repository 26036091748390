<template>
  <Popup
    :close="close"
    :close-confirmation="closeConfirmation"
    :platform="platform"
    type="medium"
    full-height
    class="schedule-post-popup-wrapper"
    :inline-styles="{
      display: 'flex',
      width: 'unset',
      padding: '0',
    }"
  >
    <div class="left-container">
      <div class="flex flex-col">
        <div class="header-area">
          <Header
            :avatar="avatar"
            :handle="displayName"
            :platform="platform?.toLowerCase()"
            :title="platform?.toLowerCase()"
            :icon="platform?.toLowerCase()"
            :post="post"
            :show-post-editor-side-panel="showPostEditorSidePanel"
            :tabs="tabs"
            :active-tab="activeTab"
            :tab-changed="tabChanged"
          />
          <slot name="header" />
        </div>
        <div class="h-full overflow-y-auto rounded-bl-md">
          <div class="flex min-h-full">
            <MediaContainer
              :show-media-placeholder-message="showMediaPlaceholderMessage"
              :error-message="mediaErrorMessage"
            >
              <slot name="media" />
            </MediaContainer>
            <DetailsContainer>
              <slot name="details" />
            </DetailsContainer>
          </div>
        </div>
      </div>
      <slot />
    </div>
    <!-- The ID is used to teleport components to the side panel -->
    <div id="SchedulerRightSidePanel" class="right-container">
      <PostEditorSidePanel
        v-if="resourceId"
        v-show="showPostEditorSidePanel"
        :resource-id="resourceId"
        :post-resource-type="postResourceType"
        :on-close="closePostEditorSidePanel"
        :linked-comment-id="linkedCommentId"
        :post="post"
        :post-editor-type="postEditorType"
        :platform="platform"
        :has-unsaved-changes="hasUnsavedChanges"
      />
    </div>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { PLATFORMS } from '@/app/scheduler/constants';
import { useAuthStore } from '@/stores/auth';
import Popup from '@/components/Popup.vue';
import PostEditorSidePanel from '@/app/scheduler/components/EditPost/PostEditorSidePanel.vue';
import Header from '@/app/scheduler/components/EditPost/Layout/Header.vue';
import MediaContainer from '@/app/scheduler/components/EditPost/Layout/MediaContainer.vue';
import DetailsContainer from '@/app/scheduler/components/EditPost/Layout/DetailsContainer.vue';
import { useSchedulerStore } from '@/stores/scheduler';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SchedulePostPopupWrapper',
  components: {
    PostEditorSidePanel,
    Popup,
    Header,
    MediaContainer,
    DetailsContainer,
  },
  props: {
    close: { type: Function, required: true },
    closeConfirmation: { type: String, default: null },
    platform: { type: String, required: true },
    post: { type: Object, default: null },
    showPostEditorSidePanel: { type: Boolean, default: false },
    linkedCommentId: { type: Number, default: null },
    showMediaPlaceholderMessage: { type: Boolean, default: false },
    mediaErrorMessage: { type: String, default: null },
    displayName: { type: String, default: null },
    avatar: { type: String, default: null },
    tabChanged: { type: Function, default: null },
    tabs: { type: Array, default: null },
    activeTab: { type: String, default: null },
    hasUnsavedChanges: { type: Boolean, default: false },
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    ...mapStores(useSchedulerStore),
    resourceId() {
      return this.post ? this.post.id : 'new';
    },
    postResourceType() {
      return this.post?.id && `SCHEDULER_${this.platform.toUpperCase()}_POST`;
    },
    postEditorType() {
      if (this.platform === PLATFORMS.INSTAGRAM) {
        const postType = this.activeTab === 'Feed' ? 'Post' : this.activeTab;
        return `instagram${postType}`;
      }
      return this.platform.toLowerCase();
    },
  },
  beforeUnmount() {
    this.schedulerStore.approvalRequests = {};
  },
  methods: {
    closePostEditorSidePanel() {
      this.schedulerStore.toggleSidePanel({
        router: this.$router,
        platform: this.platform,
        post: this.post,
      });
    },
  },
});
export default comp;
</script>

<style scoped>
.schedule-post-popup-wrapper {
  :deep(.confirm-container) {
    padding-top: var(--space-64);
  }

  .left-container,
  .right-container {
    height: 100%;
  }

  .left-container {
    display: flex;
  }

  .right-container {
    background-color: var(--background-300);
    display: flex;
    height: 100%;
    border-radius: 0 var(--round-corner) var(--round-corner) 0;
  }

  .loading-container {
    width: 60.25rem;
    height: var(--height-641);
  }
}

.header-area {
  position: relative;
  display: flex;

  .header-button {
    display: flex;
    position: absolute;
    align-items: center;
    top: 1.5rem;
    right: var(--space-48);
    padding-right: var(--space-32);

    a {
      font-weight: 400;
      padding-right: var(--space-8);
      font-size: var(--x14);
    }

    a:hover {
      color: var(--action-500);
    }

    span {
      font-weight: 600;
    }

    .svg-icon {
      box-sizing: content-box;
      padding-top: 0.25rem;
      cursor: help;
    }
  }
}
</style>
