import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const libraryAxios = createAxiosInstance(env.libraryApiUrl);

const instagramAxios = createAxiosInstance(env.instagramApiUrl, {
  camelizeKeys: true,
});

export async function createCampaign({ brandId, name, hashtagIds, startDate, endDate }) {
  return libraryAxios.post(`/brands/${brandId}/campaigns`, {
    name,
    hashtagIds,
    startDate,
    endDate,
  });
}

export async function getCampaign({ brandId, campaignId }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}`);
}

export async function updateCampaign({
  brandId,
  campaignId,
  name,
  hashtagIds,
  googleAnalyticsSelectedCampaigns,
  creatorIds,
  creatorFilters,
  ttcmSparkAdsAuthorizationDays,
}) {
  return libraryAxios.put(`/brands/${brandId}/campaigns/${campaignId}`, {
    name,
    hashtagIds,
    googleAnalyticsSelectedCampaigns,
    creatorIds,
    creatorFilters,
    ttcmSparkAdsAuthorizationDays,
  });
}

export async function deleteCampaign({ brandId, campaignId }) {
  return libraryAxios.delete(`/brands/${brandId}/campaigns/${campaignId}`);
}

export async function getVideos({
  brandId,
  campaignId,
  limit,
  sort,
  sourceCreatedOnOrAfter,
  sourceCreatedOnOrBefore,
}) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/media`, {
    params: {
      postType: 'VIDEO',
      sort,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      limit,
    },
  });
}

export async function getPosts({
  brandId,
  campaignId,
  limit,
  sort,
  sourceCreatedOnOrAfter,
  sourceCreatedOnOrBefore,
}) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/media`, {
    params: {
      postType: 'POST',
      sort,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      limit,
    },
  });
}

export async function getTopKeywords({
  brandId,
  campaignId,
  sourceCreatedOnOrAfter,
  sourceCreatedOnOrBefore,
  cloudTypes,
}) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/top_keywords`, {
    params: {
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      cloudType: cloudTypes,
    },
  });
}

export async function addOwnedMediaToCampaign({ brandId, campaignId, mediaIds, galleryIds }) {
  return libraryAxios.post(`/brands/${brandId}/campaigns/${campaignId}/media`, {
    mediaIds,
    galleryIds,
  });
}

export async function addUgcMediaToCampaign({ brandId, campaignId, mediaIds, galleryIds }) {
  return libraryAxios.post(`/brands/${brandId}/campaigns/${campaignId}/ugc`, {
    mediaIds,
    galleryIds,
  });
}

export async function addMediaToCampaignRelationship({
  brandId,
  campaignId,
  relationshipId,
  mediaIds,
}) {
  return libraryAxios.post(
    `/brands/${brandId}/campaigns/${campaignId}/relationships/${relationshipId}/media`,
    { mediaIds },
  );
}

export async function getSummaryCampaigns({ brandId, creatorId }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns`, {
    params: { summary: true, creator_id: creatorId },
  });
}

export async function getAllCampaigns({ brandId }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns`);
}

export async function getCampaigns({
  url,
  brandId,
  limit,
  summary,
  search,
  sort,
  ids,
  creatorId,
  creatorCampaignStatus,
  hasCreators,
}) {
  let constructedUrl = `/brands/${brandId}/campaigns?limit=${limit}`;
  if (summary) constructedUrl = `${constructedUrl}&summary=${summary}`;
  if (search) constructedUrl = `${constructedUrl}&search=${search}`;
  if (sort) constructedUrl = `${constructedUrl}&sort=${sort}`;
  if (ids) constructedUrl = `${constructedUrl}&ids=${ids}`;
  if (creatorId) constructedUrl = `${constructedUrl}&creator_id=${creatorId}`;
  if (creatorCampaignStatus)
    constructedUrl = `${constructedUrl}&creator_campaign_status=${creatorCampaignStatus}`;
  if (hasCreators) constructedUrl = `${constructedUrl}&has_creators=${hasCreators}`;
  return libraryAxios.get(url || constructedUrl);
}

export async function getCampaignsForBrands({ brandIds, search }) {
  return libraryAxios.post('/brands/campaigns/search', { brandIds, search });
}

export async function getCampaignRelationships({ brandId, campaignId, startDate, endDate }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/relationships`, {
    params: {
      startDate,
      endDate,
    },
  });
}

export async function getCampaignUgcMedia(
  {
    brandId,
    campaignId,
    url,
    sort,
    source,
    sourceCreatedOnOrAfter,
    sourceCreatedOnOrBefore,
    limit,
  },
  axiosConfig = {},
) {
  return libraryAxios.get(url || `/brands/${brandId}/campaigns/${campaignId}/ugc`, {
    params: {
      sort,
      source: !url ? source : undefined,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      limit: !url ? limit : undefined,
    },
    ...axiosConfig,
  });
}

export async function getCampaignChannelMedia(
  {
    brandId,
    campaignId,
    url,
    sort,
    source,
    sourceCreatedOnOrAfter,
    sourceCreatedOnOrBefore,
    limit,
  },
  axiosConfig = {},
) {
  return libraryAxios.get(url || `/brands/${brandId}/campaigns/${campaignId}/media`, {
    params: {
      sort,
      source: !url ? source : undefined,
      sourceCreatedOnOrAfter,
      sourceCreatedOnOrBefore,
      limit: !url ? limit : undefined,
    },
    ...axiosConfig,
  });
}

export async function getCampaignStats({ brandId, campaignId, startDate, endDate, scale }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/stats`, {
    params: {
      startDate,
      endDate,
      scale,
    },
  });
}

export async function getCampaignStatsForOverview({ brandId, campaignId, startDate, endDate }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/stats/overview`, {
    params: {
      startDate,
      endDate,
    },
  });
}

export async function removeOwnedMediaFromCampaign({ brandId, campaignId, mediaIds }) {
  return libraryAxios.delete(`/brands/${brandId}/campaigns/${campaignId}/media`, {
    params: {
      mediaIds: mediaIds.toString(),
    },
  });
}

export async function removeRelationshipMediaFromCampaign({
  brandId,
  campaignId,
  relationshipId,
  mediaIds,
}) {
  return libraryAxios.delete(
    `/brands/${brandId}/campaigns/${campaignId}/relationships/${relationshipId}/media`,
    {
      params: {
        mediaIds: mediaIds.toString(),
      },
    },
  );
}

export async function removeUgcMediaFromCampaign({ brandId, campaignId, mediaIds }) {
  return libraryAxios.delete(`/brands/${brandId}/campaigns/${campaignId}/ugc`, {
    params: {
      mediaIds: mediaIds.toString(),
    },
  });
}

export async function getRelationships({ brandId, campaignId, relationshipIds }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/relationships`, {
    params: {
      relationshipIds,
    },
  });
}

export async function addRelationship({ brandId, campaignId, relationshipId, mediaIds }) {
  return libraryAxios.post(
    `/brands/${brandId}/campaigns/${campaignId}/relationships/${relationshipId}/media`,
    {
      mediaIds,
    },
  );
}

export async function deleteRelationship({ brandId, campaignId, relationshipId, mediaIds }) {
  return libraryAxios.delete(
    `/brands/${brandId}/campaigns/${campaignId}/relationships/${relationshipId}/media`,
    {
      params: {
        media_ids: mediaIds,
      },
    },
  );
}

export async function getMonitoredHashtags({ brandId }) {
  return instagramAxios.get(`/brands/${brandId}/instagram/hashtags`);
}

export async function getRelationshipPosts({ brandId, relationshipId, mediaIds }) {
  return instagramAxios.get(
    `/brands/${brandId}/instagram/relationships/${relationshipId}/relationship_posts?library_media_ids=${mediaIds}`,
    {
      params: {
        libraryMediaIds: mediaIds,
        sort: '-TIMESTAMP',
      },
    },
  );
}

export async function downloadSpreadsheet({ brandId, campaignId, socketId, startDate, endDate }) {
  return libraryAxios.post(`/brands/${brandId}/campaigns/${campaignId}/stats/report`, {
    socketId,
    startDate,
    endDate,
  });
}

export async function getGoogleAnalyticsReport({
  brandId,
  startDate,
  endDate,
  metrics,
  dimensions,
  dimensionFilter,
}) {
  return libraryAxios.post(`/brands/${brandId}/google_analytics/account/report`, {
    dateRange: { startDate, endDate },
    metrics,
    dimensions,
    dimensionFilter,
  });
}

export async function getCampaignSentiment({ brandId, campaignId, startDate, endDate }) {
  return libraryAxios.get(`/brands/${brandId}/campaigns/${campaignId}/stats/sentiment`, {
    params: { startDate, endDate },
  });
}
