import { useDrawer } from '@dashhudson/dashing-ui';
import { defineAsyncComponent } from 'vue';
import { BOARDS_DRAWERS } from '@/app/boards/constants';
import { CREATOR_DRAWERS } from '@/app/creators/constants';
import { DASHBOARD_DRAWERS } from '@/app/dashboards/constants';
import { ECOMMERCE_DRAWERS, LIKESHOP_DRAWERS } from '@/app/ecommerce/constants';
import { SCHEDULER_DRAWERS } from '@/app/scheduler/constants';
import { SOCIAL_LISTENING_DRAWERS } from '@/app/socialListening/constants';

/*
 *   Drawer Registry
 *
 *   Register your drawers here in the section that your drawer belongs, or create a section if
 *   needed and add to the drawerRegistry array below.
 *
 *   Each entry object has the following keys available:
 *   {
 *     name: *Required, unique drawer name
 *     component: *Required, defineAsyncComponent call to dynamically import the component
 *     propsDef: Optional
 *     meta: Optional
 *   }
 *   See dashing-ui useDrawer for details.
 * */
const auth = [
  {
    name: 'startTrial',
    component: defineAsyncComponent(() => import('@/components/InAppTrials/StartTrialPopup.vue')),
  },
];

const boards = [
  {
    name: BOARDS_DRAWERS.DETAIL,
    component: defineAsyncComponent(
      () => import('@/app/boards/components/drawers/BoardDetailDrawer.vue'),
    ),
  },
  {
    name: BOARDS_DRAWERS.CREATE_BOARD,
    component: defineAsyncComponent(
      () => import('@/app/boards/components/drawers/CreateBoardWizard.vue'),
    ),
    meta: { excludeFromRouter: true },
  },
  {
    name: BOARDS_DRAWERS.GALLERY_REORDER,
    component: defineAsyncComponent(
      () => import('@/app/boards/components/drawers/GalleryReorderDrawer.vue'),
    ),
    meta: { excludeFromRouter: true },
  },
];

const campaigns = [
  {
    name: 'assignDeliverables',
    component: defineAsyncComponent(
      () => import('@/app/campaigns/drawers/Deliverables/AssignDeliverables.vue'),
    ),
  },
  {
    name: 'topPerformingCreators',
    component: defineAsyncComponent(
      () => import('@/app/campaigns/drawers/TopPerformingCreators.vue'),
    ),
  },
  {
    name: 'addCreator',
    component: defineAsyncComponent(
      () => import('@/app/campaigns/drawers/Creators/AddCreatorsWizard.vue'),
    ),
  },
  {
    name: 'creatorDeliverablesDateRangeEditor',
    component: defineAsyncComponent(
      () => import('@/app/campaigns/drawers/Deliverables/DateRangeEditor.vue'),
    ),
  },
  {
    name: 'campaignCreation',
    component: defineAsyncComponent(
      () => import('@/app/campaigns/pages/CampaignCreationDrawer.vue'),
    ),
  },
];

const creators = [
  {
    name: 'creatorManualPayment',
    component: defineAsyncComponent(
      () => import('@/app/creators/drawers/Profile/ManualPayment.vue'),
    ),
  },
  {
    name: 'creatorDeliverablesPosts',
    component: defineAsyncComponent(
      () => import('@/app/creators/drawers/Profile/CreatorDeliverablesPosts.vue'),
    ),
  },
  {
    name: CREATOR_DRAWERS.CREATOR_PROFILE,
    component: defineAsyncComponent(
      () => import('@/app/creators/drawers/Profile/CreatorProfile.vue'),
    ),
  },
  {
    name: CREATOR_DRAWERS.ACCOUNT_PROFILE,
    component: defineAsyncComponent(
      () => import('@/app/creators/drawers/Profile/AccountProfile.vue'),
    ),
  },
  {
    name: CREATOR_DRAWERS.BULK_UPLOAD,
    component: defineAsyncComponent(() => import('@/app/creators/drawers/Profile/BulkUpload.vue')),
  },
  {
    name: 'creatorEmailThread',
    component: defineAsyncComponent(
      () => import('@/app/creators/drawers/Profile/CreatorEmailThread.vue'),
    ),
  },
  {
    name: 'emailInvitation',
    component: defineAsyncComponent(
      () => import('@/app/creators/drawers/Profile/EmailInvitation.vue'),
    ),
  },
];

const dashboards = [
  {
    name: DASHBOARD_DRAWERS.TEMPLATES,
    component: defineAsyncComponent(
      () => import('@/app/dashboards/components/drawers/DashboardTemplates.vue'),
    ),
  },
  {
    name: DASHBOARD_DRAWERS.CREATE_REPORT,
    component: defineAsyncComponent(
      () => import('@/app/dashboards/components/drawers/ReportTypeSelection.vue'),
    ),
  },
  {
    name: DASHBOARD_DRAWERS.NO_DASHBOARDS,
    component: defineAsyncComponent(
      () => import('@/app/dashboards/components/drawers/NoDashboards.vue'),
    ),
  },
  {
    name: DASHBOARD_DRAWERS.REPORT_FORM_DRAWER,
    component: defineAsyncComponent(
      () => import('@/app/dashboards/components/drawers/ReportFormDrawer.vue'),
    ),
  },
  {
    name: DASHBOARD_DRAWERS.SHARE_DASHBOARD,
    component: defineAsyncComponent(
      () => import('@/app/dashboards/components/drawers/ShareDashboard.vue'),
    ),
  },
];

const ecommerce = [
  {
    name: LIKESHOP_DRAWERS.SETTINGS,
    component: defineAsyncComponent(
      () => import('@/app/ecommerce/components/drawers/LikeShopSettingsDrawer.vue'),
    ),
  },
  {
    name: ECOMMERCE_DRAWERS.MEDIA_LINKS,
    component: defineAsyncComponent(
      () => import('@/app/ecommerce/components/drawers/MediaLinksDrawer.vue'),
    ),
  },
  {
    name: ECOMMERCE_DRAWERS.PRODUCT_PAGE_GALLERY_SETTINGS,
    component: defineAsyncComponent(
      () => import('@/app/ecommerce/components/drawers/ProductPageGallerySettingsDrawer.vue'),
    ),
    meta: { excludeFromRouter: true },
  },
];

const library = [
  {
    name: 'media',
    component: defineAsyncComponent(() => import('@/app/library/components/MediaPopup.vue')),
    meta: { pushHistoryOnPropChange: true },
  },
  {
    name: 'selectMedia',
    component: defineAsyncComponent(() => import('@/components/core/SelectMediaDrawer.vue')),
    meta: { excludeFromRouter: true },
  },
];

const scheduler = [
  {
    name: 'SchedulerEventDrawer',
    component: defineAsyncComponent(() => import('@/app/scheduler/components/EventDrawer.vue')),
  },
  {
    name: SCHEDULER_DRAWERS.EDIT_POST_DRAWER,
    component: defineAsyncComponent(
      () => import('@/app/scheduler/components/EditPost/Layout/EditPostDrawerWrapper.vue'),
    ),
  },
  {
    name: SCHEDULER_DRAWERS.BULK_PINTEREST_DRAWER,
    component: defineAsyncComponent(
      () =>
        import('@/app/scheduler/components/EditPost/DrawerEditors/PinterestBulkDrawerContent.vue'),
    ),
  },
  {
    name: 'schedulerMediaCropper',
    component: defineAsyncComponent(
      () => import('@/components/MediaCropping/ImageCropperDrawer.vue'),
    ),
  },
  {
    name: 'schedulerVideoCropper',
    component: defineAsyncComponent(
      () => import('@/components/MediaCropping/VideoCropperDrawer.vue'),
    ),
  },
];

const settings = [
  {
    name: 'rssConnectionDrawer',
    component: defineAsyncComponent(
      () => import('@/app/settings/components/RSSConnectionDrawer.vue'),
    ),
  },
  {
    name: 'rssConnectionListDrawer',
    component: defineAsyncComponent(
      () => import('@/app/settings/components/RSSConnectionListDrawer.vue'),
    ),
  },
];

const socialListening = [
  {
    name: 'publicMedia',
    component: defineAsyncComponent(
      () => import('@/components/common/MediaPopupV2/PublicMediaPopup.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.TOPIC_CREATOR,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningTopicCreator.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.TRENDING_KEYWORD,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningTrendingKeywords.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.TOP_POST,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningTopPerformingPosts.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.VISUAL_TRENDS,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningVisualTrends.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.VISUAL_TREND,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningVisualTrend.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.MANAGE_TOPIC_LIMITS,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningManageTopicLimits.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.WEB_RESULTS,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningTopWebResults.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.TOPIC_ALERT,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningTopicAlert.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.VISUAL_FILTERS,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningVisualFilters.vue'),
    ),
  },
  {
    name: SOCIAL_LISTENING_DRAWERS.MEDIA_POST,
    component: defineAsyncComponent(
      () => import('@/app/socialListening/drawers/SocialListeningMediaPost.vue'),
    ),
  },
];

export const drawerRegistry = [
  auth,
  boards,
  campaigns,
  creators,
  dashboards,
  ecommerce,
  library,
  scheduler,
  settings,
  socialListening,
];

export const registerDrawers = () => {
  const { registerDrawer } = useDrawer();
  const registeredDrawerNames = [];
  drawerRegistry.forEach((register) => {
    register.forEach((args) => {
      const { name, component, propDefs = undefined, meta = undefined } = args;
      if (registeredDrawerNames.includes(name)) {
        throw Error(`Drawer name ${name} already registered, name must be unique.`);
      }
      registerDrawer(name, component, propDefs, meta);
      registeredDrawerNames.push(name);
    });
  });
};
