import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import {
  ROLLING_DATE_RANGE_OFFSET_MAP,
  dateTimeFormat,
  SENTIMENT_FILTER_OPTIONS,
  TOPIC_DATE_FILTER_OPTIONS,
  LISTENING_SCOPED_FILTERS,
  CUSTOM_DATE_FILTER_OPTION,
} from '@/app/socialListening/constants/listeningFilters';
import invert from 'lodash/invert';
import { lastDateRange } from '@/utils/dateUtils';
import omitBy from 'lodash/omitBy';
import { SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS } from '@/app/socialListening/constants';
import union from 'lodash/union';
import difference from 'lodash/difference';

export function getDateRangeByOffset(offset) {
  const filterOffsetMap = invert(ROLLING_DATE_RANGE_OFFSET_MAP);
  const preset = filterOffsetMap[offset] ?? TOPIC_DATE_FILTER_OPTIONS.LAST_4_WEEKS.value;
  const presetObject = TOPIC_DATE_FILTER_OPTIONS[preset];
  const { start, end } = lastDateRange(presetObject.subtractValue, presetObject.subtractUnit);
  return {
    start: dayjs(start).format(dateTimeFormat),
    end: dayjs(end).format(dateTimeFormat),
  };
}

export function formatDateRange(start, end, offset) {
  const dateRange = {
    onOrAfter: dayjs(start).format(dateTimeFormat),
    onOrBefore: dayjs(end).format(dateTimeFormat),
  };
  if (offset) {
    dateRange.rollingDateRangeOffset = offset;
  }
  return dateRange;
}

export function sortFilterOptionsByLabel(options, additionalFields = []) {
  const fields = [(o) => o?.label.toLowerCase(), ...additionalFields];
  return sortBy(options, fields);
}

export function convertToScopedTopicFilters({
  sources,
  sourceCreated,
  sentiment,
  mediaTypes,
  visualData,
  industryIds,
  sourceAndCreatorIds,
}) {
  const filters = {
    sources: sources?.length ? sources : undefined,
    industryIds: industryIds?.length ? industryIds : undefined,
    sourceAndCreatorIds: sourceAndCreatorIds?.length ? sourceAndCreatorIds : undefined,
    visualData: !isEmpty(visualData) ? visualData : undefined,
  };

  /**
   * sentiment: { includeNegative: Boolean, includeNeutral: Boolean, includePositive: Boolean }
   *
   * Expected value example
   * sentiment: ['NEGATIVE', 'NEUTRAL', 'POSITIVE']
   */
  if (!isEmpty(sentiment)) {
    const value = [];
    Object.values(SENTIMENT_FILTER_OPTIONS).forEach((v) => {
      if (sentiment[v.field]) {
        value.push(v.value);
      }
    });
    filters.sentiment = value;
  } else {
    filters.sentiment = undefined;
  }

  /**
   * sourceCreated: { onOrAfter: Datetime, onOrBefore: Datetime, rollingDateRangeOffset: Integer }
   *
   * Expected value example
   * sourceCreated: {
   *    preset: 'CUSTOM',
   *    presetObject: { label: 'CUSTOM', label: 'Custom' },
   *    range: { start: Datetime, end: Datetime }
   * }
   */
  if (!isEmpty(sourceCreated)) {
    let value;
    const filterOffsetMap = invert(ROLLING_DATE_RANGE_OFFSET_MAP);
    const offset = sourceCreated?.rollingDateRangeOffset;

    if (offset && filterOffsetMap[offset]) {
      const preset = filterOffsetMap[offset];
      value = { preset };
    } else {
      value = {
        preset: CUSTOM_DATE_FILTER_OPTION.value,
        range: {
          start: dayjs(sourceCreated.onOrAfter).startOf('day').toDate(),
          end: dayjs(sourceCreated.onOrBefore).endOf('day').toDate(),
        },
      };
    }
    filters.sourceCreated = value;
  } else {
    filters.sourceCreated = undefined;
  }

  /**
   * mediaTypes: { includes: Array, doesNotInclude: Array }
   *
   * Expected value example
   * mediaTypes: ['IMAGE', 'VIDEO', 'LINK', 'TEXT']
   */
  if (!isEmpty(mediaTypes) && mediaTypes?.includes?.length) {
    filters.mediaTypes = [...mediaTypes.includes];
  } else {
    filters.mediaTypes = undefined;
  }

  return { ...LISTENING_SCOPED_FILTERS.TOPIC.default, ...omitBy(filters, isEmpty) };
}

export function convertLoadedQueryParamsToScopedFilters(payload) {
  // wrap media types in includes for convertToScopedTopicFilters
  const { mediaTypes } = payload;
  if (Array.isArray(mediaTypes) && mediaTypes.length) {
    payload.mediaTypes = { includes: mediaTypes };
  }
  return convertToScopedTopicFilters(payload);
}

export function convertToSearchBodyFilters({
  sources,
  sourceCreated,
  sentiment,
  mediaTypes,
  visualData,
  industryIds,
  sourceAndCreatorIds,
  topicId,
}) {
  const searchBodyFilters = {
    topicId,
    sources: sources?.length ? sources : undefined,
    industryIds: industryIds?.length ? industryIds : undefined,
    sourceAndCreatorIds: sourceAndCreatorIds?.length ? sourceAndCreatorIds : undefined,
    mediaTypes: mediaTypes?.length ? { includes: mediaTypes } : undefined,
  };

  if (sentiment?.length) {
    const value = {};
    Object.values(SENTIMENT_FILTER_OPTIONS).forEach((v) => {
      value[v.field] = sentiment.includes(v.value);
    });
    searchBodyFilters.sentiment = value;
  } else {
    searchBodyFilters.sentiment = undefined;
  }

  if (sourceCreated?.preset || sourceCreated?.presetObject || sourceCreated?.range) {
    let value;
    const presetObject =
      sourceCreated?.presetObject ?? TOPIC_DATE_FILTER_OPTIONS[sourceCreated?.preset];
    const offset = ROLLING_DATE_RANGE_OFFSET_MAP[sourceCreated.preset];

    const { range } = sourceCreated;
    if (range) {
      value = {
        onOrAfter: dayjs(range.start).format(dateTimeFormat),
        onOrBefore: dayjs(range.end).format(dateTimeFormat),
      };
    } else {
      const { start, end } = lastDateRange(presetObject.subtractValue, presetObject.subtractUnit);
      value = {
        onOrAfter: dayjs(start).format(dateTimeFormat),
        onOrBefore: dayjs(end).format(dateTimeFormat),
      };
    }
    if (offset) {
      value.rollingDateRangeOffset = offset;
    }
    searchBodyFilters.sourceCreated = value;
  } else {
    searchBodyFilters.sourceCreated = undefined;
  }

  if (visualData?.includes?.length || visualData?.doesNotInclude?.length) {
    searchBodyFilters.visualData = visualData;
  } else {
    searchBodyFilters.visualData = undefined;
  }

  return searchBodyFilters;
}

export function formatVisualFilters(_filters) {
  // Formats visual filters for refined results queries
  // Exclude TEXT, LINK from refined results, since they cannot produce visual filter results
  const mediaFilterExcludes = [
    SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.LINK.value,
    SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.TEXT.value,
  ];
  const excludes = union(_filters.mediaTypes?.doesNotInclude || [], mediaFilterExcludes);
  if (!_filters.mediaTypes) {
    _filters.mediaTypes = {};
  }
  _filters.mediaTypes.doesNotInclude = excludes;
  if (_filters?.mediaTypes?.includes) {
    // Simplify includes since we don't want to include & exclude the same things
    _filters.mediaTypes.includes = difference(_filters.mediaTypes.includes, excludes);
  }
}

export function sortVisualFilterArrays(obj) {
  return {
    ...obj,
    doesNotInclude: sortBy(obj.doesNotInclude ?? [], JSON.stringify),
    includes: sortBy(obj.includes ?? [], JSON.stringify),
  };
}

export function convertToTrendsFilters(searchBodyFilters) {
  const { sources, mediaTypes, ...filterData } = searchBodyFilters ?? {};
  return {
    ...omitBy(
      {
        channels: sources,
        mediaTypes: Array.isArray(mediaTypes?.includes) ? mediaTypes.includes : mediaTypes,
        ...filterData,
      },
      isEmpty,
    ),
  };
}
