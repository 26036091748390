import { BRAND, USER } from '@/models/auth/permissions.enum';
import { getSrcSet, IMG } from '@/assets';

export const sourceTypeEnum = {
  OWNED: 'OWNED',
  UGC: 'UGC',
  COMPETITIVE: 'OTHER',
};

export const connectionPlatforms = {
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_INFLUENCER: 'INSTAGRAM_INFLUENCER',
  PINTEREST: 'PINTEREST',
  FACEBOOK: 'FACEBOOK',
  FACEBOOK_ADS: 'FACEBOOK_ADS',
  FACEBOOK_ANALYTICS: 'FACEBOOK_ANALYTICS',
  FACEBOOK_SANDBOX: 'FACEBOOK_SANDBOX',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
  LINKEDIN: 'LINKEDIN',
  TWITTER: 'TWITTER',
  TIKTOK: 'TIKTOK',
  TIKTOK_CREATOR: 'TIKTOK_CREATOR',
  TIKTOK_ADS: 'TIKTOK_ADS',
  TIKTOK_CREATOR_MARKETPLACE: 'TIKTOK_CREATOR_MARKETPLACE',
  YOUTUBE: 'YOUTUBE',
  THREADS: 'THREADS',
  BLUESKY: 'BLUESKY',
};

export const platforms = {
  ...connectionPlatforms,
  UPLOAD: 'UPLOAD',
  EDITED: 'EDITED',
  UGC: 'UGC',
};

export const searchBrandMediaTypes = {
  INSTAGRAM_OWNED: 'INSTAGRAM_OWNED',
  INSTAGRAM_OWNED_BOOSTED: 'INSTAGRAM_OWNED_BOOSTED',
  INSTAGRAM_OWNED_IGTV: 'INSTAGRAM_OWNED_IGTV',
  INSTAGRAM_UGC: 'INSTAGRAM_UGC',
  INSTAGRAM_UGC_IGTV: 'INSTAGRAM_UGC_IGTV',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  INSTAGRAM_STORY_UGC: 'INSTAGRAM_STORY_UGC',
  LINKEDIN_OWNED: 'LINKEDIN_OWNED',
  PINTEREST_OWNED: 'PINTEREST_OWNED',
  PINTEREST_UGC: 'PINTEREST_UGC',
  FACEBOOK_OWNED: 'FACEBOOK_OWNED',
  TWITTER_OWNED: 'TWITTER_OWNED',
  TIKTOK_OWNED: 'TIKTOK_OWNED',
  TIKTOK_UGC: 'TIKTOK_UGC',
  UPLOADED: 'UPLOADED',
  YOUTUBE_OWNED: 'YOUTUBE_OWNED',
  THREADS_OWNED: 'THREADS_OWNED',
};

export const brandMediaTypes = {
  ...searchBrandMediaTypes,
  FACEBOOK_TEXT: 'FACEBOOK_TEXT',
  FACEBOOK_LINK: 'FACEBOOK_LINK',
  FACEBOOK_TEXT_LINK: 'FACEBOOK_TEXT_LINK',
  TWITTER_OWNED_LINK: 'TWITTER_OWNED_LINK',
  TWITTER_OWNED_TEXT: 'TWITTER_OWNED_TEXT',
};

export const adsSources = {
  FACEBOOK_ADS: 'FACEBOOK_ADS',
};

export const textOnlySources = {
  FACEBOOK_LINK: 'FACEBOOK_LINK',
  FACEBOOK_TEXT_LINK: 'FACEBOOK_TEXT_LINK',
  FACEBOOK_TEXT: 'FACEBOOK_TEXT',
  FACEBOOK_OTHER_TEXT: 'FACEBOOK_OTHER_TEXT',
  FACEBOOK_OTHER_TL: 'FACEBOOK_OTHER_TL',
  TWITTER_OWNED_LINK: 'TWITTER_OWNED_LINK',
  TWITTER_OWNED_TEXT: 'TWITTER_OWNED_TEXT',
  TWITTER_OTHER_LINK: 'TWITTER_OTHER_LINK',
  TWITTER_OTHER_TEXT: 'TWITTER_OTHER_TEXT',
};

export const visualSources = {
  EDITOR: 'EDITOR',
  FACEBOOK_OWNED: 'FACEBOOK_OWNED',
  FACEBOOK_OTHER: 'FACEBOOK_OTHER',
  INSTAGRAM_OTHER: 'INSTAGRAM_OTHER',
  INSTAGRAM_OTHER_IGTV: 'INSTAGRAM_OTHER_IGTV',
  INSTAGRAM_OWNED_BOOSTED: 'INSTAGRAM_OWNED_BOOSTED',
  INSTAGRAM_OWNED: 'INSTAGRAM_OWNED',
  INSTAGRAM_OWNED_IGTV: 'INSTAGRAM_OWNED_IGTV',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  INSTAGRAM_STORY_UGC: 'INSTAGRAM_STORY_UGC',
  INSTAGRAM_UGC: 'INSTAGRAM_UGC',
  INSTAGRAM_UGC_IGTV: 'INSTAGRAM_UGC_IGTV',
  LINKEDIN_OWNED: 'LINKEDIN_OWNED',
  PINTEREST_OWNED: 'PINTEREST_OWNED',
  PINTEREST_UGC: 'PINTEREST_UGC',
  TWITTER_OWNED: 'TWITTER_OWNED',
  TWITTER_OTHER: 'TWITTER_OTHER',
  TIKTOK_OWNED: 'TIKTOK_OWNED',
  TIKTOK_UGC: 'TIKTOK_UGC',
  TIKTOK_OTHER: 'TIKTOK_OTHER',
  UPLOADED: 'UPLOADED',
  UPLOAD: 'UPLOAD',
  YOUTUBE_OWNED: 'YOUTUBE_OWNED',
  YOUTUBE_OTHER: 'YOUTUBE_OTHER',
  THREADS_OWNED: 'THREADS_OWNED',
};

export const sources = {
  ...adsSources,
  ...textOnlySources,
  ...visualSources,
};

// Name of source filters are used in many places as platform types.
// And it doesn't work well for Instagram story type.
// Declare one constant here to make it a bit more clear what values it takes for stories.
// Hopefully will help testing easier when we do a bigger refactor in SourcesFilter.vue.
export const sourcesFilterNames = {
  SOURCE_FILTER_INSTAGRAM: 'instagram',
  SOURCE_FILTER_INSTAGRAM_STORIES: 'instagram stories',
  SOURCE_FILTER_TIKTOK: 'tikTok',
  SOURCE_FILTER_YOUTUBE: 'youTube',
  SOURCE_FILTER_LINKEDIN: 'linkedIn',
  SOURCE_FILTER_TWITTER: 'twitter',
  SOURCE_FILTER_PINTEREST: 'pinterest',
  SOURCE_FILTER_FACEBOOK: 'facebook',
  SOURCE_FILTER_THREADS: 'threads',
};

export const sourceLists = {
  FACEBOOK_LINK_LIST: ['FACEBOOK_LINK', 'FACEBOOK_TEXT_LINK'],
  FACEBOOK_GALLERY_LIST: ['FACEBOOK_OWNED', 'FACEBOOK_LINK'],
  FACEBOOK_SOURCE_LIST: [
    'FACEBOOK',
    'FACEBOOK_OWNED',
    'FACEBOOK_TEXT',
    'FACEBOOK_LINK',
    'FACEBOOK_TEXT_LINK',
    'FACEBOOK_OTHER',
    'FACEBOOK_OTHER_TEXT',
    'FACEBOOK_OTHER_LINK',
    'FACEBOOK_OTHER_TL',
  ],
  FACEBOOK_COMPETITIVE_SOURCE_LIST: [
    'FACEBOOK_OTHER',
    'FACEBOOK_OTHER_TEXT',
    'FACEBOOK_OTHER_LINK',
    'FACEBOOK_OTHER_TL',
  ],
  FACEBOOK_SOURCE_LIST_V1: [
    'FACEBOOK_OWNED',
    'FACEBOOK_TEXT',
    'FACEBOOK_LINK',
    'FACEBOOK_TEXT_LINK',
  ],
  FACEBOOK_OWNED_SOURCE_LIST: [
    'FACEBOOK_OWNED',
    'FACEBOOK_TEXT',
    'FACEBOOK_LINK',
    'FACEBOOK_TEXT_LINK',
  ],
  INSTAGRAM_SOURCE_LIST: [
    'INSTAGRAM_OWNED',
    'INSTAGRAM_OWNED_IGTV',
    'INSTAGRAM_UGC',
    'INSTAGRAM_OTHER',
  ],
  INSTAGRAM_STORY_SOURCE_LIST: ['INSTAGRAM_STORY', 'INSTAGRAM_STORY_UGC'],
  INSTAGRAM_OWNED_SOURCE_LIST: ['INSTAGRAM_OWNED', 'INSTAGRAM_OWNED_IGTV', 'INSTAGRAM_STORY'],
  PINTEREST_SOURCE_LIST: ['PINTEREST_OWNED'],
  PINTEREST_OWNED_SOURCE_LIST: ['PINTEREST_OWNED'],
  THREADS_OWNED_SOURCE_LIST: ['THREADS_OWNED'],
  TWITTER_SOURCE_LIST: [
    'TWITTER_OWNED',
    'TWITTER_OWNED_LINK',
    'TWITTER_OWNED_TEXT',
    'TWITTER',
    'TWITTER_LINK',
    'TWITTER_TEXT',
    'TWITTER_OTHER',
    'TWITTER_OTHER_LINK',
    'TWITTER_OTHER_TEXT',
  ],
  TWITTER_COMPETITIVE_SOURCE_LIST: ['TWITTER_OTHER', 'TWITTER_OTHER_LINK', 'TWITTER_OTHER_TEXT'],
  TWITTER_SOURCE_LIST_V1: ['TWITTER_OWNED', 'TWITTER_OWNED_LINK', 'TWITTER_OWNED_TEXT'],
  TWITTER_OWNED_SOURCE_LIST: ['TWITTER_OWNED', 'TWITTER_OWNED_LINK', 'TWITTER_OWNED_TEXT'],
  TWITTER_LINK_LIST: ['TWITTER_LINK', 'TWITTER_OWNED_LINK'],
  TIKTOK_SOURCE_LIST: ['TIKTOK_OWNED'],
  TIKTOK_OWNED_SOURCE_LIST: ['TIKTOK_OWNED'],
  YOUTUBE_SOURCE_LIST: ['YOUTUBE_OWNED'],
  YOUTUBE_OWNED_SOURCE_LIST: ['YOUTUBE_OWNED'],
  YOUTUBE_COMPETITIVE_SOURCE_LIST: ['YOUTUBE_OTHER'],
  NO_MEDIA_SOURCE_LIST: [
    'TWITTER_OWNED_LINK',
    'TWITTER_OWNED_TEXT',
    'FACEBOOK_TEXT',
    'FACEBOOK_TEXT_LINK',
  ],
  get OWNED_LIST() {
    return [
      ...this.FACEBOOK_OWNED_SOURCE_LIST,
      ...this.INSTAGRAM_OWNED_SOURCE_LIST,
      ...this.PINTEREST_OWNED_SOURCE_LIST,
      ...this.THREADS_OWNED_SOURCE_LIST,
      ...this.TIKTOK_OWNED_SOURCE_LIST,
      ...this.TWITTER_OWNED_SOURCE_LIST,
      ...this.YOUTUBE_OWNED_SOURCE_LIST,
    ];
  },
  UGC_LIST: ['INSTAGRAM_UGC', 'PINTEREST_UGC', 'UGC', 'INSTAGRAM_STORY_UGC', 'TIKTOK_UGC'],
  UGC_SOURCE_LIST: ['INSTAGRAM_UGC', 'PINTEREST_UGC', 'INSTAGRAM_STORY_UGC'],
  COMPETITIVE_LIST: [
    'INSTAGRAM_OTHER',
    'INSTAGRAM_OTHER_IGTV',
    'OTHER',
    'TWITTER_OTHER',
    'TWITTER_OTHER_LINK',
    'TWITTER_OTHER_TEXT',
    'YOUTUBE_OTHER',
    'FACEBOOK_OTHER',
    'FACEBOOK_OTHER_TEXT',
    'FACEBOOK_OTHER_LINK',
    'FACEBOOK_OTHER_TL',
  ],
};

export const visionPredictedSources = [
  visualSources.EDITOR,
  visualSources.UPLOAD,
  visualSources.UPLOADED,
  sourceTypeEnum.COMPETITIVE,
  ...sourceLists.UGC_SOURCE_LIST,
];

export const invalidVisionUgcSources = ['TIKTOK'];

export const postsCsvTypes = {
  POSTS_CSV_LINK: 'links',
  POSTS_CSV_IMAGE: 'images',
  POSTS_CSV_POST: 'posts',
};

export const mediaTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const linkedinMediaTypes = {
  DOCUMENT: 'DOCUMENT',
  IMAGE: 'IMAGE',
  LINK: 'LINK',
  MULTI_IMAGE: 'MULTI_IMAGE',
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
};

export const threadsMediaTypes = {
  TEXT_POST: 'TEXT_POST',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  CAROUSEL_ALBUM: 'CAROUSEL_ALBUM',
  AUDIO: 'AUDIO',
};

export const instagramPostTypes = {
  IMAGE: 'image',
  VIDEO: 'video',
  REEL: 'reel',
  CAROUSEL: 'carousel',
};

export const aspectRatios = {
  SQUARE: 'SQUARE',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
};

export const visualSearchImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

export const mediaBadgeEnumsByPlatform = {
  [searchBrandMediaTypes.LINKEDIN_OWNED]: [
    {
      mediaType: linkedinMediaTypes.VIDEO,
      tooltip: 'This video post is published on your LinkedIn Page',
      iconName: 'video',
      showIcon: false,
    },
    {
      mediaType: linkedinMediaTypes.DOCUMENT,
      tooltip: 'This document post is published on your LinkedIn Page',
      iconName: 'document',
      showIcon: false,
    },
    {
      mediaType: linkedinMediaTypes.IMAGE,
      tooltip: 'This image post is published on your LinkedIn Page',
      iconName: 'image',
      showIcon: false,
    },
    {
      mediaType: linkedinMediaTypes.MULTI_IMAGE,
      tooltip: 'This carousel post is published on your LinkedIn Page',
      iconName: 'multipleMedia',
      showIcon: false,
    },
    {
      mediaType: linkedinMediaTypes.TEXT,
      tooltip: 'This text post is published on your LinkedIn Page',
      iconName: 'text',
      showIcon: false,
    },
    {
      mediaType: linkedinMediaTypes.LINK,
      tooltip: 'This link post is published on your LinkedIn Page',
      iconName: 'link',
      showIcon: false,
    },
  ],
  [searchBrandMediaTypes.THREADS_OWNED]: [
    {
      mediaType: threadsMediaTypes.TEXT_POST,
      tooltip: 'This text post is published on your Threads Profile',
      iconName: 'text',
      showIcon: false,
    },
    {
      mediaType: threadsMediaTypes.IMAGE,
      tooltip: 'This image post is published on your Threads Profile',
      iconName: 'image',
      showIcon: false,
    },
    {
      mediaType: threadsMediaTypes.VIDEO,
      tooltip: 'This video post is published on your Threads Profile',
      iconName: 'video',
      showIcon: false,
    },
    {
      mediaType: threadsMediaTypes.CAROUSEL_ALBUM,
      tooltip: 'This carousel post is published on your Threads Profile',
      iconName: 'multipleMedia',
      showIcon: false,
    },
    {
      mediaType: threadsMediaTypes.AUDIO,
      tooltip: 'This audio post is published on your Threads Profile',
      iconName: 'music-note',
      showIcon: false,
    },
  ],
};

// deprecated constant, use availabilityStockTypes instead
export const productStockTypes = {
  IN_STOCK: 'in stock',
  OUT_OF_STOCK: 'out of stock',
  PRODUCT_TYPE: {
    IN_STOCK: 'in-stock',
    ALL: 'all',
  },
  PREORDER: 'preorder',
  AVAILABLE_FOR_ORDER: 'available for order',
  DISCONTINUED: 'discontinued',
};

export const productAvailabilityTypes = {
  IN_STOCK: 'in stock',
  OUT_OF_STOCK: 'out of stock',
  PREORDER: 'preorder',
  AVAILABLE_FOR_ORDER: 'available for order',
  DISCONTINUED: 'discontinued',
};

export const productAvailabilityLabels = {
  IN_STOCK: 'In Stock',
  OUT_OF_STOCK: 'Out of Stock',
  PREORDER: 'Pre-order',
  AVAILABLE_FOR_ORDER: 'Available for Order',
  DISCONTINUED: 'Discontinued',
};

export const productAvailabilityMap = Object.keys(productAvailabilityTypes).reduce((acc, key) => {
  acc[productAvailabilityTypes[key]] = productAvailabilityLabels[key];
  return acc;
}, {});

export const productCardAvailabilityLabels = {
  [productAvailabilityTypes.IN_STOCK]: {
    label: productAvailabilityLabels.IN_STOCK,
    style: 'availability-green',
  },
  [productAvailabilityTypes.OUT_OF_STOCK]: {
    label: productAvailabilityLabels.OUT_OF_STOCK,
    style: 'availability-red',
  },
  [productAvailabilityTypes.AVAILABLE_FOR_ORDER]: {
    label: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
    style: 'availability-blue',
  },
  [productAvailabilityTypes.PREORDER]: {
    label: productAvailabilityLabels.PREORDER,
    style: 'availability-blue',
  },
  [productAvailabilityTypes.DISCONTINUED]: {
    label: productAvailabilityLabels.DISCONTINUED,
    style: 'availability-grey',
  },
};

export const productCardAvailabilityChips = {
  [productAvailabilityTypes.IN_STOCK]: {
    label: productAvailabilityLabels.IN_STOCK,
    severity: 'success',
  },
  [productAvailabilityTypes.AVAILABLE_FOR_ORDER]: {
    label: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
    severity: 'success',
  },
  [productAvailabilityTypes.PREORDER]: {
    label: productAvailabilityLabels.PREORDER,
    severity: 'success',
  },
  [productAvailabilityTypes.DISCONTINUED]: {
    label: productAvailabilityLabels.DISCONTINUED,
    severity: 'warning',
  },
  [productAvailabilityTypes.OUT_OF_STOCK]: {
    label: productAvailabilityLabels.OUT_OF_STOCK,
    severity: 'danger',
  },
};

export const productReviewStatusTypes = {
  APPROVED: 'approved',
  OUTDATED: 'outdated',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

export const productReviewStatusLabels = {
  APPROVED: 'Approved',
  OUTDATED: 'Outdated',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

export const scales = {
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};

export const sortingOptions = {
  MOST_RECENT: '-CREATED',
  MOST_ENGAGEMENT: '-INSTAGRAM_ENGAGEMENT',
  MOST_EFFECTIVE: '-INSTAGRAM_EFFECTIVENESS',
  INSTAGRAM_REACH: '-INSTAGRAM_REACH',
  LIBRARY_TRAFFIC: '-LIBRARY_TRAFFIC',
  STORY_REACH: '-IG_STORY_AVG_REACH',
  STORY_IMPRESSIONS: '-IG_STORY_AVG_IMPRESSIONS',
  STORY_EXIT_RATE: '-IG_STORY_AVG_EXIT_RATE',
};

export const contentRightsPostTypes = {
  ALL: 'ALL',
  APPROVED: 'APPROVED',
  LAPSED: 'LAPSED',
  RESET: 'RESET',
  ORIGINAL_TYPE: 'REQUEST',
  REQUESTED: 'REQUESTED',
  REQUEST_TIME_FORMAT: 'MMM D, YYYY h:mmA',
};

export const durationUnits = {
  SECONDS: 'SECONDS',
  MILLISECONDS: 'MILLISECONDS',
};

export const creatorSources = {
  CREATOR_INSTAGRAM: `CREATORS_INSTAGRAM`,
  CREATOR_INSTAGRAM_STORIES: 'CREATORS_INSTAGRAM_STORIES',
};

export default {
  ...platforms,
  ...sources,
  ...sourceLists,
  ...sourcesFilterNames,
  // source type
  OWNED: 'OWNED',
  ...mediaTypes,
  ...postsCsvTypes,
  ...productStockTypes,
  ...scales,
  ...sortingOptions,
  ...contentRightsPostTypes,
  ...aspectRatios,
  ...durationUnits,
  ...creatorSources,
};

export const defaultShoppableSettings = {
  viewType: 'gallery',
  column: '4',
  gap: '4',
  row: 'auto',
  scroll: 'show',
  autoplay: 'on',
  mobileColumn: '4',
  mobileGap: '2',
  customLinks: true,
  callToAction: '-',
  galleryTitle: 'Dash Social',
};

export const productFeedSortEnum = {
  ARCHIVED: 'ARCHIVED',
};

export const productImportSourceEnum = {
  FACEBOOK_PRODUCT_CATALOG: 'FACEBOOK_PRODUCT_CATALOG',
  OTHER_PRODUCT_FEED: 'OTHER_PRODUCT_FEED',
  SINGLE_PRODUCT_LINK: 'SINGLE_PRODUCT_LINK',
};

export const productCatalogSourceEnum = {
  FACEBOOK: 'FACEBOOK',
};

export const importYourProductsDropdownList = [
  {
    text: 'Facebook Product Feed',
    action: 'openProductFeedPopup',
  },
  {
    text: 'Single Product Link',
    action: 'openAddProductPopup',
  },
];

export const productFeedStatus = {
  INIT: 'INIT',
  CONNECTED: 'CONNECTED',
  UPDATING: 'UPDATING',
  ERROR: 'ERROR',
};

export const productFeedTypeEnum = {
  PRODUCT_CATALOG: 'PRODUCT_CATALOG',
};

export const productFilterOptions = [
  { label: 'Import Source', value: 'import_source' },
  { label: 'Availability', value: 'availability' },
];

export const productImportSourceMap = {
  'facebook-product-catalog': 'Facebook Product Catalog',
  'single-product-link': 'Single Product Link',
  'other-product-feed': 'Other Product Feeds',
};

export const productFilterWidgets = {
  import_source: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Import Source',
    noDuplicate: true,
    options: {
      filterOptions: [
        {
          formattedLabel: 'Facebook Product Catalog',
          label: 'Facebook Product Catalog',
          value: 'facebook-product-catalog',
        },
        {
          formattedLabel: 'Single Product Link',
          label: 'Single Product Link',
          value: 'single-product-link',
        },
        {
          formattedLabel: 'Other Product Feeds',
          label: 'Other Product Feeds',
          value: 'other-product-feed',
        },
      ],
    },
  },
  product_catalog: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Product Catalog',
    noDuplicate: true,
    options: {
      filterOptions: [],
    },
  },
  availability: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Availability',
    noDuplicate: true,
    options: {
      filterOptions: [
        {
          label: productAvailabilityLabels.IN_STOCK,
          formattedLabel: productAvailabilityLabels.IN_STOCK,
          value: productAvailabilityTypes.IN_STOCK,
        },
        {
          label: productAvailabilityLabels.OUT_OF_STOCK,
          formattedLabel: productAvailabilityLabels.OUT_OF_STOCK,
          value: productAvailabilityTypes.OUT_OF_STOCK,
        },
        {
          label: productAvailabilityLabels.PREORDER,
          formattedLabel: productAvailabilityLabels.PREORDER,
          value: productAvailabilityTypes.PREORDER,
        },
        {
          label: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
          formattedLabel: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
          value: productAvailabilityTypes.AVAILABLE_FOR_ORDER,
        },
        {
          label: productAvailabilityLabels.DISCONTINUED,
          formattedLabel: productAvailabilityLabels.DISCONTINUED,
          value: productAvailabilityTypes.DISCONTINUED,
        },
      ],
    },
  },
};

export const productFeedInfoMessage =
  'You can now import products by adding a Facebook Product Catalog. If you would like to import products with a custom Product Feed link, please contact your Account Representative.';

export const archiveFeedConfirmationTitle = 'Archive Product Feed?';

export const archiveFeedConfirmationMessage =
  'Archiving this Product Feed will archive all associated products. Archived products cannot be used to add links to media and will be hidden from your products. You can still see the analytics by toggling "Show archived". To access these products please un-archive the Product Feed.';

export const unarchiveFeedConfirmationTitle = 'Unarchive Product Feed?';

export const unarchiveFeedConfirmationMessage =
  'Unarchiving your Product Feed will re-import your products. Products will be available for tagging immediately, however, it may take up to 24 hours for all properties to update.';

export const analyticsDisabledTooltip = 'Remove filters to toggle analytics.';

export const productCatalogAlreadyImportedTooltip =
  "You've already imported this\nFacebook Product Catalog";

export const productFeedCreatedNotification =
  "We've started importing your Facebook Product Catalog. This may take up to 24 hours.";

export const connectFacebookPlatformPopupMessage =
  'To import your Facebook Product Catalog and access Facebook and Instagram insights you must connect your Facebook account to Dash Social';

export const connectFacebookPlatformPopupTitle = 'Connect Your Facebook Account';

export const authorizeProductCatalogsPopupMessage =
  'To import your Facebook Product Catalog you must authorize access with Dash Social';

export const authorizeProductCatalogsPopupTitle = 'Connect Your Facebook Product Catalog';

export const productFeedArchived = 'ARCHIVED';

export const productFeedStatusTooltips = {
  [productFeedStatus.CONNECTED]:
    'Your Product Feed is connected and will refresh daily. New products added may take up to 24 hours to appear.',
  [productFeedStatus.ERROR]:
    'Error importing your Facebook Product Feed. Please reach out to your Customer Success Representative.',
  [productFeedStatus.UPDATING]:
    "We're updating your Product Feed. When the update is complete the status will change to Connected.",
  [productFeedArchived]: 'This Product Feed is archived.',
};

export const productSortOptions = [
  { label: 'Recently Added', value: '-DATE' },
  { label: 'Total Clicks', value: '-CLICKS' },
];

export const productSearchPlaceholder = 'Search name or ID';

export const PRODUCT_ACTION_BANNERS = Object.freeze([
  {
    type: 'addFacebookProductCatalog',
    title: 'Import Your Product Feed',
    message:
      'Connect your Facebook Product Catalog or contact us to import a custom product feed, making your products easily shoppable.',
    bannerSrc: IMG.addFacebookProductCatalog,
    bannerSrcset: getSrcSet('products/add-facebook-product-catalog'),
    ctaButton: { label: 'Add Facebook Product Catalog', 'icon-right': 'plus' },
  },
  {
    type: 'addProductLinks',
    title: 'Add Product Links',
    message:
      'Add links for use with Dash’s EComm features, including product pages, blog posts and website pages.',
    bannerSrc: IMG.addProductLinks,
    bannerSrcset: getSrcSet('products/add-product-links'),
    ctaButton: { label: 'Add Product Links', 'icon-right': 'plus' },
  },
]);

export const multiSelectTopBarArchiveConfirmationCopy = {
  message:
    'Archiving products will remove them from your products and you will no longer be able to access them when scheduling media. View archived products by toggling "Show archived".',
  title: 'Archive product',
};

export const fbGraphApiErrors = {
  PERMISSIONS_ERROR: 'PERMISSIONS_ERROR',
  API_PERMISSION_ERROR: 'API_PERMISSION_ERROR',
  INVALID_PARAMETER: 'INVALID_PARAMETER',
};

export const noProductsFoundEmptyState = {
  title: 'No Products Found',
  image: 'empty-beach.png',
};

export const noProductsImportedEmptyState = {
  title: "You haven't imported any products",
  image: 'empty-seashore.png',
};

export const productsNoResultFoundState = {
  variant: 'sectionEmpty',
  icon: 'search',
  title: 'Couldn’t Find a Match',
  message: 'Review or adjust your filters or search to uncover more results',
  ctaButton: { label: 'Reset All', severity: 'tertiary' },
};

export const galleryDefaultSortOptions = [
  { label: 'Recently Added', value: sortingOptions.MOST_RECENT },
  { label: 'Total Traffic', value: sortingOptions.LIBRARY_TRAFFIC },
];

export const likeshopBoardsDetailSortOptions = [
  {
    label: 'Recently Published',
    value: '-ADDED',
  },
  {
    label: 'Clicks',
    value: '-CLICKS',
  },
];
export const libraryBoardsDetailSortOptions = [
  {
    label: 'Customized Order',
    value: 'ORDER',
  },
  {
    label: 'Recently Published',
    value: '-POSTED',
  },
  {
    label: 'Recently Added',
    value: '-ADDED',
  },
];

export const visionBoardsDetailSortOptions = [
  {
    label: 'Predicted Performance',
    value: '-PERFORMANCE',
  },
];

export const audienceSectionTabs = {
  TRAFFIC_SOURCE: 'TRAFFIC_SOURCE',
  LOCATION: 'LOCATION',
  DEMOGRAPHICS: 'DEMOGRAPHICS',
};

export const audienceSectionTabLabels = {
  [audienceSectionTabs.TRAFFIC_SOURCE]: 'Traffic Source',
  [audienceSectionTabs.LOCATION]: 'Location',
  [audienceSectionTabs.DEMOGRAPHICS]: 'Demographics',
};

export const PERMISSIONS_COPY = {
  user: {
    message: "Looks like you don't have permission to use this feature!",
    callToAction:
      'Dash Social brings you all of your essential social media solutions, including community management! Ask your Dash Social administrator to provide you access.',
  },
  brand: {
    message: 'Looks Like This Feature Isn’t Included In Your Plan!',
    callToAction:
      'Dash Social brings you all of the insights you love, including community management! If this feature isn’t in your package, contact your Customer Success Representative to try it out!',
  },
};

export const MAX_MEDIA_OFFSET = 10000;

export const noLibraryAccessPlaceholderCopy = {
  title: 'Library isn’t included in your plan',
  message:
    'A centralized place to store all of your content. Upgrade to our Engage package to access Library and easily manage all of your owned, earned and paid content.',
};

export const noBoardsAccessCopy = {
  title: 'Upgrade Your Plan',
  message: 'Easily see which content pillars are driving results with Boards.',
};

export const MEDIA_TYPE_OPTIONS = {
  IMAGE: {
    value: 'IMAGE',
    title: 'Photos',
    tooltip: 'Image',
  },
  VIDEO: {
    value: 'VIDEO',
    title: 'Videos',
    tooltip: 'Video',
  },
  LINK: {
    value: 'LINK',
    title: 'Links',
    tooltip: 'Link',
  },
  TEXT: {
    value: 'TEXT',
    title: 'Text',
    tooltip: 'Text',
  },
};

export const ugcMediaDeleteMessage =
  'Deleting a UGC post only removes it from Organic Reach and Library within Dash Social. Are you sure you want to delete this ';
export const nonUGCMediaDeleteMessage = 'Are you sure you want to delete this ';

export const bulkUploadNoBrandTagsMessage =
  'Your brand(s) do not have any Brand Tags yet. Go to Settings to create new Brand Tags.';

export const boostUrl = 'https://business.facebook.com/latest/';

export const allowedMediaTypes = Object.freeze([
  connectionPlatforms.FACEBOOK,
  connectionPlatforms.FACEBOOK_ANALYTICS,
  connectionPlatforms.FACEBOOK_SANDBOX,
  connectionPlatforms.INSTAGRAM,
  connectionPlatforms.INSTAGRAM_INFLUENCER,
  connectionPlatforms.LINKEDIN,
  connectionPlatforms.PINTEREST,
  connectionPlatforms.TIKTOK,
  connectionPlatforms.TIKTOK_ADS,
  connectionPlatforms.TWITTER,
  connectionPlatforms.YOUTUBE,
  sources.EDITOR,
  sources.FACEBOOK_ADS,
  sources.FACEBOOK_OTHER,
  sources.FACEBOOK_OTHER_TEXT,
  sources.FACEBOOK_OTHER_TL,
  sources.FACEBOOK_OWNED,
  sources.INSTAGRAM_OTHER,
  sources.INSTAGRAM_OTHER_IGTV,
  sources.INSTAGRAM_OWNED,
  sources.INSTAGRAM_OWNED_BOOSTED,
  sources.INSTAGRAM_OWNED_IGTV,
  sources.INSTAGRAM_STORY,
  sources.INSTAGRAM_STORY_UGC,
  sources.INSTAGRAM_UGC,
  sources.INSTAGRAM_UGC_IGTV,
  sources.LINKEDIN_OWNED,
  sources.PINTEREST_OWNED,
  sources.PINTEREST_UGC,
  sources.TIKTOK_UGC,
  sources.TIKTOK_OTHER,
  sources.TWITTER_OTHER,
  sources.TWITTER_OWNED,
  sources.UPLOAD,
  sources.UPLOADED,
  sources.YOUTUBE_OTHER,
  sources.YOUTUBE_OWNED,
  sourceTypeEnum.UGC,
]);

export const mediaSortOptions = Object.freeze({
  date: { label: 'Recently Added', value: 'date' },
  reach: { label: 'Reach', value: 'reach' },
  relevance: { label: 'Most Relevant', value: 'relevance' },
  performance: {
    label: 'Predicted Performance',
    value: 'performance',
    permissions: USER.VISION.CAN_ACCESS_VISION,
  },
});

export const MEDIA_SOURCES_OWNED_FILTERS = Object.freeze({
  TIKTOK_OWNED: {
    channel: 'tiktok',
    chipInputLabel: 'TikTok Owned',
    label: 'TikTok',
    permissions: BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS,
    value: 'TIKTOK_OWNED',
    sources: [sources.TIKTOK_OWNED],
  },
  INSTAGRAM_OWNED: {
    channel: 'instagram',
    chipInputLabel: 'Instagram Owned',
    label: 'Instagram',
    value: 'INSTAGRAM_OWNED',
    sources: [sources.INSTAGRAM_OWNED, sources.INSTAGRAM_OWNED_IGTV],
  },
  INSTAGRAM_STORY_OWNED: {
    channel: 'instagram story',
    chipInputLabel: 'Instagram Stories Owned',
    label: 'Instagram Stories',
    value: 'INSTAGRAM_STORY_OWNED',
    sources: [sources.INSTAGRAM_STORY],
  },
  FACEBOOK_OWNED: {
    channel: 'facebook',
    chipInputLabel: 'Facebook Owned',
    label: 'Facebook',
    permissions: BRAND.FACEBOOK.CAN_ACCESS_FB_INSIGHTS,
    value: 'FACEBOOK_OWNED',
    sources: sourceLists.FACEBOOK_GALLERY_LIST,
  },
  PINTEREST_OWNED: {
    channel: 'pinterest',
    chipInputLabel: 'Pinterest Owned',
    label: 'Pinterest',
    value: 'PINTEREST_OWNED',
    sources: [sources.PINTEREST_OWNED],
  },
  TWITTER_OWNED: {
    channel: 'x',
    chipInputLabel: 'X Owned',
    label: 'X',
    permissions: BRAND.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS,
    value: 'TWITTER_OWNED',
    sources: [sources.TWITTER_OWNED],
  },
  YOUTUBE_OWNED: {
    channel: 'youtube',
    chipInputLabel: 'YouTube Owned',
    label: 'YouTube',
    permissions: BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS,
    value: 'YOUTUBE_OWNED',
    sources: [sources.YOUTUBE_OWNED],
  },
  LINKEDIN_OWNED: {
    channel: 'linkedin',
    label: 'LinkedIn',
    chipInputLabel: 'LinkedIn Owned',
    permissions: BRAND.LINKEDIN.CAN_ACCESS_LINKEDIN_INSIGHTS,
    value: 'LINKEDIN_OWNED',
    sources: [sources.LINKEDIN_OWNED],
  },
  THREADS_OWNED: {
    channel: 'threads',
    label: 'Threads',
    chipInputLabel: 'Threads Owned',
    permissions: BRAND.THREADS.CAN_ACCESS_THREADS_INSIGHTS,
    value: 'THREADS_OWNED',
    sources: [sources.THREADS_OWNED],
  },
});

export const MEDIA_SOURCES_UGC_FILTERS = Object.freeze({
  TIKTOK_UGC: {
    channel: 'tiktok',
    chipInputLabel: 'TikTok UGC',
    label: 'TikTok',
    permissions: [
      USER.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS,
      BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS,
      BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE,
      USER.INSTAGRAM.CAN_ACCESS_UGC_MORE,
    ],
    featureFlag: 'tiktokUgc',
    value: 'TIKTOK_UGC',
    sources: [sources.TIKTOK_UGC],
  },
  INSTAGRAM_UGC: {
    channel: 'instagram',
    chipInputLabel: 'Instagram UGC',
    label: 'Instagram',
    permissions: [BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE, USER.INSTAGRAM.CAN_ACCESS_UGC_MORE],
    value: 'INSTAGRAM_UGC',
    sources: [sources.INSTAGRAM_UGC],
  },
  INSTAGRAM_STORY_UGC: {
    channel: 'instagram story',
    label: 'Instagram Stories',
    chipInputLabel: 'Instagram Stories UGC',
    permissions: [BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE, USER.INSTAGRAM.CAN_ACCESS_UGC_MORE],
    value: 'INSTAGRAM_STORY_UGC',
    sources: [sources.INSTAGRAM_STORY_UGC],
  },
});

export const MEDIA_SOURCES_FILTER_OPTIONS = Object.freeze({
  ...MEDIA_SOURCES_OWNED_FILTERS,
  ...MEDIA_SOURCES_UGC_FILTERS,
  UPLOADED: {
    label: 'Uploads',
    chipInputLabel: 'Uploads',
    value: 'UPLOADED',
    sources: [sources.UPLOADED],
  },
});

export const LIBRARY_SCOPED_FILTERS = Object.freeze({
  GALLERIES: {
    scope: 'libraryGalleries',
    default: {
      tags: [],
      showAnalytics: 'off',
    },
  },
  PRODUCTS: {
    scope: 'libraryProducts',
    options: {
      sort: productSortOptions,
      import_source: [
        {
          formattedLabel: 'Facebook Product Catalog',
          label: 'Facebook Product Catalog',
          value: 'facebook-product-catalog',
        },
        {
          formattedLabel: 'Single Product Link',
          label: 'Single Product Link',
          value: 'single-product-link',
        },
        {
          formattedLabel: 'Other Product Feeds',
          label: 'Other Product Feeds',
          value: 'other-product-feed',
        },
      ],
      availability: [
        {
          label: productAvailabilityLabels.IN_STOCK,
          formattedLabel: productAvailabilityLabels.IN_STOCK,
          value: productAvailabilityTypes.IN_STOCK,
        },
        {
          label: productAvailabilityLabels.OUT_OF_STOCK,
          formattedLabel: productAvailabilityLabels.OUT_OF_STOCK,
          value: productAvailabilityTypes.OUT_OF_STOCK,
        },
        {
          label: productAvailabilityLabels.PREORDER,
          formattedLabel: productAvailabilityLabels.PREORDER,
          value: productAvailabilityTypes.PREORDER,
        },
        {
          label: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
          formattedLabel: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
          value: productAvailabilityTypes.AVAILABLE_FOR_ORDER,
        },
        {
          label: productAvailabilityLabels.DISCONTINUED,
          formattedLabel: productAvailabilityLabels.DISCONTINUED,
          value: productAvailabilityTypes.DISCONTINUED,
        },
      ],
    },
    default: {
      sort: {
        sortOrder: 'DESC',
        selected: productSortOptions[0],
      },
      import_source: [],
      availability: [],
      product_catalog_ids: [],
      show_archived: false,
      show_analytics: false,
    },
  },
  MEDIA: {
    scope: 'libraryMedia',
    options: {
      mediaType: [
        { label: 'Photo', value: mediaTypes.IMAGE },
        { label: 'Video', value: mediaTypes.VIDEO },
      ],
      performance: [
        { label: 'Top or Above Average', value: 'top' },
        { label: 'Lower Than Average', value: 'low' },
      ],
      color: [
        { label: 'Red', value: 'red' },
        { label: 'Orange', value: 'orange' },
        { label: 'Yellow', value: 'yellow' },
        { label: 'Green', value: 'green' },
        { label: 'Teal', value: 'cyan' },
        { label: 'Blue', value: 'blue' },
        { label: 'Purple', value: 'purple' },
        { label: 'Pink', value: 'pink' },
        { label: 'Brown', value: 'brown' },
        { label: 'Black', value: 'black' },
        { label: 'Grey', value: 'gray' },
        { label: 'White', value: 'white' },
      ],
      size: [
        { label: '> 800 x 600', value: '800x600' },
        { label: '> 1024 x 768', value: '1024x768' },
        { label: '> 1600 x 1200', value: '1600x1200' },
      ],
    },
    default: {
      sources: [],
      contentTags: [],
      dateRange: {
        preset: 'ALL_TIME',
      },
      contentRights: 'off',
      showDuplicateMedia: 'off',
    },
  },
});

export const LIBRARY_MEDIA_FILTER_TOOLTIPS = Object.freeze({
  DUPLICATE_MEDIA: 'Duplicate media refers only to uploads',
  PREDICTED_PERFORMANCE:
    'Vision AI predicts the performance of UGC and uploads based on the past performance of your channel',
  REPORTING_PERIOD: 'Includes posts published or uploaded within the reporting period',
  CONTENT_TAGS: 'Filter by media that match any applied Content Tag',
});

export const MEDIA_CARD_SENTIMENT = Object.freeze([
  { key: 'isPositive', value: 'POSITIVE' },
  { key: 'isNeutral', value: 'NEUTRAL' },
  { key: 'isNegative', value: 'NEGATIVE' },
]);

export const SENTIMENT_DISALLOWED_CHANNELS = Object.freeze([
  'instagram_story_frame',
  'linkedin',
  'pinterest',
  'threads',
]);

export const SENTIMENT_SOURCE_TYPES = Object.freeze(['OWNED', 'UGC']);

export const ADD_TO_GALLERY_DISABLED_TOOLTIPS = Object.freeze({
  CONTAINS_TIKTOK_UGC: 'Adding TikTok UGC to Boards is not available',
  INSTAGRAM_STORY: 'You can only add Instagram stories to your Instagram Story Boards.',
  INSTAGRAM: 'You can only add feed content and UGC to your Instagram Boards.',
  PINTEREST: 'You can add to Pinterest Boards only when this media is published on Pinterest.',
  TIKTOK: 'You can only add TikTok videos to your TikTok Boards.',
  YOUTUBE: 'You can only add YouTube videos to your YouTube Boards.',
  CONTAINS_TEXT_POSTS: 'Posts without media cannot be added to a Gallery.',
  CONTAINS_NON_ORIGINAL_POST: 'Reposts, Quote Posts, and Replies cannot be added to a Board.',
  X: 'You can add to X Boards only when this media is published on X.',
  FACEBOOK: 'You can add to Facebook Boards only when this media is published on Facebook.',
});

export const PLATFORM_MEDIA_TYPES = Object.freeze({
  IMAGE: 'IMAGE',
  CAROUSEL: 'CAROUSEL',
  VIDEO: 'VIDEO',
  LIVE: 'LIVE',
  ON_DEMAND: 'ON_DEMAND',
  SHORT: 'SHORT',
  STORY: 'STORY',
  STORY_FRAME: 'STORY_FRAME',
  REEL: 'REEL',
  AUDIO: 'AUDIO',
  LINK: 'LINK',
  TEXT: 'TEXT',
  DOCUMENT: 'DOCUMENT',
});

export const PLATFORM_MEDIA_TYPES_MAP = {
  SHORTS: PLATFORM_MEDIA_TYPES.SHORT,
  PHOTO: PLATFORM_MEDIA_TYPES.IMAGE,
  REELS: PLATFORM_MEDIA_TYPES.REEL,
};

export const CONVERTED_VIDEO_DURATION_WARNING =
  'There may be an issue with the length of this video. It was processed successfully but please review to ensure it plays as expected.';
export const CONVERTED_VIDEO_DURATION_WARNING_SOME_MEDIA =
  'There may be an issue with the length of one of your videos. They were processed successfully but please review to ensure they play as expected.';
