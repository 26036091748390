import { getFormattedMetrics } from '@/app/dashboards/utils/report.utils';
import { COMPETITIVE_AVERAGE_KEY, COMPETITIVE_AVERAGE_NAME } from '@/app/dashboards/constants';
import { getMetricMediaType } from '@/app/dashboards/utils/media-types.utils';
import { CHANNELS } from '@/models/dashboards/channels.enum';

export function getReportMediaTypeMetrics(
  metrics,
  reportData,
  reportBrands,
  reportBrandId,
  reportChannel,
  metricTypeReportKey,
) {
  return metrics?.map((mediaTypeMetric) => {
    const reportDataBrand = reportData?.[reportBrandId];
    if (!reportDataBrand) {
      return {
        ...reportBrands[0],
        mediaType: getMetricMediaType(reportChannel, mediaTypeMetric, metricTypeReportKey),
      };
    }
    const brandMediaTypeMetrics = Object.keys(reportDataBrand?.metrics || {})
      .filter((mediaMetric) => mediaMetric === mediaTypeMetric)
      .reduce((obj, key) => {
        return Object.assign(obj, { [key]: reportDataBrand?.metrics[key] });
      }, {});
    const { formattedMetrics, metric } = getFormattedMetrics(
      brandMediaTypeMetrics,
      mediaTypeMetric,
      reportChannel,
    );
    return {
      ...reportBrands[0],
      isBrand: true,
      mediaType: getMetricMediaType(reportChannel, mediaTypeMetric, metricTypeReportKey),
      metric,
      formattedMetrics,
    };
  });
}

export function getReportBrandsMetrics(
  metrics,
  reportData,
  reportBrands,
  campaignIds,
  reportChannel,
  isAdsTotalMetric,
) {
  if (isAdsTotalMetric) {
    return campaignIds.map((campaignId) => {
      const reportDataCampaign = reportData?.[campaignId];
      if (!reportDataCampaign) {
        return {
          isBrand: false,
        };
      }
      const aggregatedTotalMetrics = reportData?.metrics;
      const brandMetrics = aggregatedTotalMetrics || reportDataCampaign?.metrics || {};
      const { formattedMetrics, metric } = getFormattedMetrics(brandMetrics, metrics[0]);
      return {
        isBrand: false,
        metric,
        formattedMetrics,
      };
    });
  }
  return reportBrands?.map((brand) => {
    const reportDataBrand = reportData?.[brand.id];
    const aggregatedTotalMetrics = reportData?.metrics;

    if (!reportDataBrand) {
      return {
        ...brand,
      };
    }

    const brandMetrics = aggregatedTotalMetrics || reportDataBrand?.metrics || {};
    const { formattedMetrics, metric } = getFormattedMetrics(
      brandMetrics,
      metrics[0],
      reportChannel,
    );

    return {
      ...brand,
      isBrand: true,
      data_type: reportDataBrand.data_type,
      metric,
      formattedMetrics,
    };
  });
}

export function getReportCompetitorsMetrics(metrics, reportData, reportCompetitors, reportChannel) {
  return reportCompetitors?.map((competitor) => {
    const reportCompetitorData = reportData?.[competitor.id];

    if (!reportCompetitorData) {
      return {
        ...competitor,
      };
    }
    const competitorMetrics = reportCompetitorData?.metrics || {};
    const { formattedMetrics, metric } = getFormattedMetrics(
      competitorMetrics,
      metrics[0],
      reportChannel,
    );

    return {
      ...competitor,
      isCompetitor: true,
      metric,
      formattedMetrics,
    };
  });
}

export function getReportCompetitorAverageMetrics(metrics, reportData, reportChannel) {
  const reportCompetitorAverageData = reportData?.[COMPETITIVE_AVERAGE_KEY];
  if (!reportCompetitorAverageData) return undefined;

  const competitorAverageMetrics = reportCompetitorAverageData?.metrics || {};
  const { formattedMetrics, metric } = getFormattedMetrics(
    competitorAverageMetrics,
    metrics[0],
    reportChannel,
  );

  return {
    ...reportCompetitorAverageData,
    id: COMPETITIVE_AVERAGE_KEY,
    name: COMPETITIVE_AVERAGE_NAME,
    isCompetitor: true,
    avatar: null,
    metric,
    formattedMetrics,
  };
}

export function getReportChannelMetrics(metrics, reportData, reportChannels) {
  return reportChannels?.map((channel) => {
    const reportChannelData = reportData?.[channel];

    if (!reportChannelData) {
      return {
        channel,
        id: channel,
        name: CHANNELS[channel].text,
        isChannel: true,
      };
    }

    const channelMetrics = reportChannelData?.metrics || {};
    const { formattedMetrics, metric } = getFormattedMetrics(channelMetrics, metrics[0]);
    return {
      channel,
      id: channel,
      name: CHANNELS[channel].text,
      isChannel: true,
      metric,
      formattedMetrics,
    };
  });
}
