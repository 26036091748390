import cloneDeep from 'lodash/cloneDeep';
import { env } from '@/env';
import html2canvas from 'html2canvas';
import {
  getMetricMediaType,
  removeMediaTypeFromMetric,
} from '@/app/dashboards/utils/media-types.utils';
import { METRICS } from '@/models/dashboards/metrics';
import {
  BAR_CHART_REPORTS,
  COMPETITIVE_REPORTS,
  GRAPH_REPORTS,
  LINE_GRAPH_REPORT_TYPES,
  METRIC_TABLE_REPORTS,
  SINGLE_METRIC_REPORT_TYPE,
  REPORTS,
  LINE_CHART_REPORTS,
  ADS_REPORT_TYPES,
  METRIC_TYPE_REPORT_KEYS,
} from '@/app/dashboards/utils/reports.enum';
import {
  AGGREGATE_REPORT_TYPES,
  ADS_CHANNEL_TO_AGGREGATE_BY_TYPES,
  ADS_REPORT_AGGREGATE_BY_TYPES,
} from '@/models/dashboards/aggregate-type.enum';
import { IMAGE_DOWNLOAD_FILE_FORMATS } from '@/app/dashboards/constants';
import { useMetricsStore } from '@/stores/metrics';
import { CHANNELS } from '@/models/dashboards/channels.enum';

export function sortBrandsByName(brandArray) {
  return brandArray.sort((brandA, brandB) => {
    return brandA.name.localeCompare(brandB.name);
  });
}

export function sortBrandsByHandle(brandArray) {
  return brandArray.sort((brandA, brandB) => {
    return brandA.handle.localeCompare(brandB.handle);
  });
}

export function getBrandOccurrenceInfo(brandOccurrences, report, allBrands) {
  const brandIds = report?.meta?.brand_ids || [];
  brandIds.forEach((brandId) => {
    if (!brandOccurrences?.[brandId]) {
      const targetBrand = allBrands.find((brand) => brand.id === Number(brandId));
      let brandName = '';
      if (targetBrand) {
        brandName = targetBrand?.name || targetBrand?.label || '';
      }
      brandOccurrences[brandId] = {
        id: brandId,
        name: brandName,
        count: 1,
      };
    } else {
      brandOccurrences[brandId].count += 1;
    }
  });
}

export function getCompetitorOccurrenceInfo(competitorOccurrences, report, allCompetitors) {
  const competitiveSourceMap = {
    INSTAGRAM_COMPETITIVE: 'INSTAGRAM',
  };
  const competitorIds = report?.meta?.competitor_source_account_ids || [];
  const channel = report?.meta?.channel || report?.meta?.channels?.[0] || '';
  const channelCompetitors = allCompetitors?.[competitiveSourceMap[channel]] || [];
  let competitorHandle = '';

  competitorIds.forEach((competitorId) => {
    if (!competitorOccurrences?.[competitorId]) {
      const targetCompetitor = channelCompetitors.find(
        (competitor) => competitor.sourceAccountId === competitorId,
      );
      if (targetCompetitor) {
        competitorHandle = targetCompetitor?.handle || '';
      }
      competitorOccurrences[competitorId] = {
        id: competitorId,
        handle: competitorHandle,
        count: 1,
      };
    } else {
      competitorOccurrences[competitorId].count += 1;
    }
  });
}

export const extractReportType = (reportType, brands, metrics, channels) => {
  if (!brands.length || !metrics.length || !channels.length) {
    return reportType;
  }
  const firstMetric = metrics[0];
  const channel = channels[0];
  const baseMetric = removeMediaTypeFromMetric(firstMetric);
  const mediaSubtypes = METRICS?.[channel]?.[baseMetric]?.mediaSubtypes;
  const mediaSubtypeLocked = METRICS?.[channel]?.[baseMetric]?.mediaSubtypeLocked;
  const mediaTypes = metrics.reduce((result, metric) => {
    if (mediaSubtypes && !mediaSubtypeLocked) {
      const mediaTypeObj = getMetricMediaType(channel, metric, reportType);
      result.push(mediaTypeObj.text);
    }
    return result;
  }, []);

  if (!reportType) return reportType;
  switch (true) {
    case reportType === 'GRAPH' && brands.length >= 2 && (!mediaSubtypes || mediaSubtypeLocked):
      return LINE_GRAPH_REPORT_TYPES.GRAPH_MULTI_BRAND;
    case reportType === 'GRAPH' && brands.length >= 2 && mediaTypes.length === 1:
      return LINE_GRAPH_REPORT_TYPES.GRAPH_MULTI_BRAND_SINGLE_MEDIA_TYPE;
    case reportType === 'GRAPH' && brands.length === 1 && mediaTypes.length === 1:
      return LINE_GRAPH_REPORT_TYPES.GRAPH_SINGLE_BRAND_SINGLE_MEDIA_TYPE;
    case reportType === 'GRAPH' && brands.length === 1 && mediaTypes.length > 1:
      return LINE_GRAPH_REPORT_TYPES.GRAPH_SINGLE_BRAND_MULTI_MEDIA_TYPE;
    case reportType === 'SINGLE_METRIC' && mediaSubtypes && !mediaSubtypeLocked:
      return SINGLE_METRIC_REPORT_TYPE.SINGLE_METRIC_MEDIA_TYPE;
    default:
      return reportType;
  }
};

export function getMediaTypeOccurrenceInfo(
  mediaTypeOccurrence,
  mediaTypeMetricOccurrence,
  baseMetricsOccurrence,
  metrics,
  channels,
  reportType,
) {
  if (!metrics.length || !channels.length) {
    return;
  }

  const firstMetric = metrics[0];
  const channel = channels[0];
  const baseMetric = removeMediaTypeFromMetric(firstMetric);
  if (baseMetric) {
    baseMetricsOccurrence[baseMetric] = (baseMetricsOccurrence?.[baseMetric] || 0) + 1;
  }

  metrics.forEach((metric) => {
    if (metric && channels.length === 1) {
      const mediaSubtype = METRICS?.[channel]?.[baseMetric]?.mediaSubtypes;
      const mediaSubtypeLocked = METRICS?.[channel]?.[baseMetric]?.mediaSubtypeLocked;
      if (mediaSubtype && !mediaSubtypeLocked) {
        const mediaTypeObj = getMetricMediaType(channel, metric, reportType);
        mediaTypeOccurrence[mediaTypeObj.text] =
          (mediaTypeOccurrence?.[mediaTypeObj.text] || 0) + 1;
        mediaTypeMetricOccurrence[metric] = (mediaTypeMetricOccurrence?.[metric] || 0) + 1;
      }
    }
  });
}

export function getSimplifiedOccurrenceDict(occurrenceDict, fieldName) {
  return Object.values(occurrenceDict).reduce((results, item) => {
    const keyName = item[fieldName];
    results[keyName] = item.count;
    return results;
  }, {});
}

export function dashboardReportLoading(reportId, reportType, dashboardsStore, searchStore) {
  const isCompetitiveReport = COMPETITIVE_REPORTS.includes(reportType);

  if (isCompetitiveReport) {
    return (
      dashboardsStore.reportLoading[reportId] || searchStore.competitorsRequestStatus === 'pending'
    );
  }
  return dashboardsStore.reportLoading[reportId];
}

export function removeAccountInfoWhenAccountTagsExist(reportMeta) {
  if (reportMeta.brand_tags) {
    delete reportMeta.brand_ids;
  }
  if (reportMeta.competitor_tags) {
    delete reportMeta.competitor_source_account_ids;
  }
}

export function getGraphReportAggregateTypeOptions(
  aggregateTypeOptions,
  isLineChart = false,
  brandTagsSelected = false,
) {
  return aggregateTypeOptions.map((value) => {
    const valueCopy = cloneDeep(value);
    const aggregateLabel = isLineChart ? 'Lines' : 'Columns';
    valueCopy.text = `${aggregateLabel} ${value.text}`;
    if (!brandTagsSelected && value.value === AGGREGATE_REPORT_TYPES.TAG.value) {
      valueCopy.disabled = true;
    }
    return valueCopy;
  });
}

export function getMetricReportAggregateTypeOptions(aggregateTypeOptions) {
  return aggregateTypeOptions.map((value) => {
    const valueCopy = cloneDeep(value);
    valueCopy.text = `Rows ${value.text}`;
    return valueCopy;
  });
}

function getAdsReportAggregationOptions(report) {
  const reportType = report?.type ?? '';
  if ([REPORTS.ADS_GRAPH.value, REPORTS.ADS_TOTAL_METRIC.value].includes(reportType)) {
    return [];
  }

  // Ads reports only support 1 channel
  const channel = report.meta?.channels?.[0];
  // We need the targetMetricDetails from the backend to check if any aggregation options should be excluded
  const metricReportTypeKey =
    REPORTS[reportType]?.metricTypeReportKey ?? METRIC_TYPE_REPORT_KEYS.METRICS_REPORT;
  const metricsStore = useMetricsStore();
  const metricDetails = metricsStore.getTargetMetricDetails(
    channel,
    metricReportTypeKey,
    report.meta?.metrics?.[0],
  );

  const options = ADS_CHANNEL_TO_AGGREGATE_BY_TYPES[channel];
  const excludedAggregations = cloneDeep(metricDetails?.excluded_aggregations ?? []);
  // TikTok ads doesn't have a sub breakdown when aggregating by campaign, therefore we exclude this option
  if (
    channel === CHANNELS.TIKTOK_ADS.value &&
    reportType === REPORTS.ADS_STACKED_BAR_METRIC.value
  ) {
    excludedAggregations.push(ADS_REPORT_AGGREGATE_BY_TYPES.SOURCE_CAMPAIGN.value);
  }
  if (!excludedAggregations) return options;

  // Filter out any aggregation options that are in the exclude list from the backend
  return options.filter((option) => {
    return !excludedAggregations.includes(option.value);
  });
}

export function getReportAggregationOptionsByReportType(report) {
  const reportType = report?.type ?? '';
  if (ADS_REPORT_TYPES.includes(reportType)) {
    return getAdsReportAggregationOptions(report);
  }

  return Object.values(AGGREGATE_REPORT_TYPES);
}

export function getReportAggregateTypeOptions(report) {
  const reportType = report?.type ?? '';
  let values = getReportAggregationOptionsByReportType(report);

  const combinedGraphAndBarReportTypes = BAR_CHART_REPORTS.concat(GRAPH_REPORTS);
  const isGraphReport = combinedGraphAndBarReportTypes.includes(reportType);
  const isMetricReport = METRIC_TABLE_REPORTS.includes(reportType);
  const isLineChart = LINE_CHART_REPORTS.includes(reportType);
  const brandTagsSelected = Boolean(report?.meta?.brand_tags?.length);
  const isCompetitiveReport = reportType.startsWith('COMPETITIVE');

  if (isLineChart || isMetricReport) {
    values = values.filter((value) => value.value !== AGGREGATE_REPORT_TYPES.TAG.value);
  }

  if (isCompetitiveReport) {
    values = values.filter((value) => value.value !== AGGREGATE_REPORT_TYPES.CHANNEL.value);
  }

  if (isGraphReport) {
    return getGraphReportAggregateTypeOptions(
      values,
      isLineChart,
      brandTagsSelected,
      isCompetitiveReport,
    );
  }

  if (isMetricReport) {
    return getMetricReportAggregateTypeOptions(values);
  }

  return [];
}

export function downloadImage(element, format) {
  html2canvas(element, {
    backgroundColor: 'white',
    proxy: `${env.dashboardApiUrl}export_proxy`,
    onclone: (clonedDoc) => {
      // Sanitizing the CSS
      clonedDoc.querySelectorAll('.dashboard-report-item-content').forEach((item) => {
        item.style.boxShadow = 'none';
      });
      clonedDoc.querySelectorAll('.media-card, .content-media-card').forEach((item) => {
        item.style.boxShadow = 'none';
        item.style.border = '1px solid #ddd';
      });
      clonedDoc.querySelectorAll('.media-card .media-badges svg').forEach((item) => {
        item.style.width = '20px';
        item.style.margin = '0';
      });
      clonedDoc.querySelectorAll('.card .stats-container dl dd .metric-stat').forEach((item) => {
        item.style.paddingLeft = '5px';
      });
      clonedDoc.querySelectorAll('.media-card .media img').forEach((item) => {
        item.style.position = 'absolute';
        item.style.top = '50%';
        item.style.left = '50%';
        item.style.height = 'auto';
        item.style.width = '100%';
        item.style.transform = 'translate(-50%, -50%)';
      });
      clonedDoc.querySelectorAll('.sort-order-text').forEach((item) => {
        item.style.paddingBottom = '2px';
      });
      clonedDoc
        .querySelectorAll(
          '.context-change-content.decrease svg, .context-change-content.decrease-in-green svg',
        )
        .forEach((item) => {
          item.style.transform = 'rotate(0deg)';
          item.style.width = '17px';
        });
      clonedDoc.querySelectorAll('.picker').forEach((item) => {
        item.style.background = 'none';
      });
      // Hide the download button. use class 'image-download'
      clonedDoc.querySelectorAll('.image-download, .see-more-button').forEach((item) => {
        item.style.display = 'none';
      });
      clonedDoc.querySelectorAll('.multi-brand-metric-report-panel').forEach((item) => {
        item.closest('.vue-grid-item').style.height = '';
      });
    },
  }).then((canvas) => {
    const formatKey = format.toUpperCase();
    const imageFormatKey = IMAGE_DOWNLOAD_FILE_FORMATS[formatKey];
    const dataURL = canvas.toDataURL(imageFormatKey.dataUrl);
    const url = dataURL.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    const link = document.createElement('a');
    const title = element.querySelectorAll('.metric-title')[0]?.textContent.trim();

    if (title) {
      link.download = `${title}.${imageFormatKey.extension}`;
    } else {
      link.download = `DH Report.${imageFormatKey.extension}`;
    }
    link.href = url;
    link.click();
  });
}

export function channelsInDashboard(dashboardReports) {
  const channelsWithDuplicates = dashboardReports.reduce((acc, report) => {
    const reportChannels = report?.meta?.channels || [].concat(report?.meta?.channel || []);
    return acc.concat(reportChannels);
  }, []);
  return [...new Set(channelsWithDuplicates)];
}
