<script setup>
import { useFilterSidePanel } from '@dashhudson/dashing-ui';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import { env } from '@/env';
import { computed } from 'vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import {
  REGENERATE_TOOLTIPS,
  SUMMARY_INTENT_TYPES,
  VISION_AI_SUMMARY_LAUNCH_TYPE,
} from '@/components/VisionAi/constants';
import sphereAnimation from '@/assets/animations/lottie/vision-ai-sphere.json';
import { useIntentMap } from '@/components/VisionAi/composables/useIntentMap';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import { aiSummaryLaunchEvent } from '@/components/VisionAi/utils/mixpanel-events';
import { useVisionAiMessagesStore } from '@/stores/vision-ai-messages';
import { useHubspotStore } from '@/stores/hubspot';

const visionAiStore = useVisionAiStore();
const visionAiConversationsStore = useVisionAiConversationsStore();
const visionAiMessagesStore = useVisionAiMessagesStore();
const props = defineProps({
  isGlobalPopup: {
    type: Boolean,
    default: null,
  },
  large: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['openChatWindow']);

const { checkIfInvalidThreshold, getTooltip } = useIntentMap();

const { visible: filterVisible } = useFilterSidePanel();
const hubspotStore = useHubspotStore();

const tooltip = computed(() => {
  return visionAiMessagesStore.summaryLoading
    ? REGENERATE_TOOLTIPS.LOADING
    : getTooltip(visionAiStore.currentIntentType);
});

const sphereAnimationProps = computed(() => {
  const size = props.isGlobalPopup ? 36 : 20;
  return {
    animationData: sphereAnimation,
    width: size,
    height: size,
    pauseOnHover: true,
    renderer: 'canvas',
  };
});

const showLottie = computed(() => {
  return !visionAiMessagesStore.summaryLoading;
});

const showButtonText = computed(() => {
  return !visionAiMessagesStore.summaryLoading && !props.isGlobalPopup;
});

const iconName = computed(() => {
  return !showLottie.value ? 'vision-ai' : null;
});

const globalRight = computed(() => {
  if (filterVisible.value) {
    if (hubspotStore.ready) {
      if (hubspotStore.isGdsVersion) {
        return 'right-[24.75rem]';
      }
      return 'right-[26.50rem]';
    }
    return 'right-[22rem]';
  }

  if (hubspotStore.ready) {
    if (hubspotStore.isGdsVersion) {
      return 'right-[2.75rem]';
    }
    return 'right-[4.50rem]';
  }
  return 'right-0';
});

const buttonPositionClasses = computed(() => {
  return props.isGlobalPopup ? `${globalRight.value} fixed bottom-4 z-popup mr-4` : 'left-5';
});

const showLegacyButton = computed(() => {
  return env.isProduction;
});

async function handleGlobalButtonClicked(event) {
  visionAiStore.openChatWindow();

  emit('openChatWindow', event);
}

async function handleSummaryButtonClicked(event) {
  visionAiStore.openChatWindow();

  if (!visionAiConversationsStore.summariesSelected) {
    visionAiConversationsStore.selectSummaries();
    visionAiStore.clearLocalChatHistory();
    await visionAiMessagesStore.getMessages({ intentTypes: SUMMARY_INTENT_TYPES });
  }

  if (checkIfInvalidThreshold(visionAiStore.currentIntentType)) {
    await visionAiStore.createMockMessages({});
  } else {
    await visionAiStore.startConversation({
      message: visionAiStore.defaultPrompts[0],
    });
  }

  emit('openChatWindow', event);
  aiSummaryLaunchEvent({
    launchedFrom: VISION_AI_SUMMARY_LAUNCH_TYPE.ICON,
    intentType: visionAiStore.currentIntentType,
  });
}

async function handleButtonClicked(event) {
  if (props.isGlobalPopup) {
    await handleGlobalButtonClicked(event);
  } else {
    await handleSummaryButtonClicked(event);
  }
}
</script>

<template>
  <div
    v-if="showLegacyButton"
    :data-cy="isGlobalPopup ? 'vision-ai-button-global' : 'vision-ai-button'"
    :class="['vision-ai-button ', 'dash-transition-md', buttonPositionClasses]"
  >
    <Button
      v-tooltip="isGlobalPopup ? null : { content: tooltip, html: true }"
      no-border
      :class="['summon-ai-vision-button', { action: !isGlobalPopup, 'h-[42px] pl-3': large }]"
      :loading="visionAiMessagesStore.summaryLoading && !isGlobalPopup"
      :icon-color="colours.BASIC.WHITE"
      :icon-hover-color="colours.BASIC.WHITE"
      :icon-name="iconName"
      :round="isGlobalPopup"
      :small="!isGlobalPopup"
      @click="handleButtonClicked($event)"
    >
      <Vue3Lottie v-if="showLottie" v-bind="sphereAnimationProps" />
      <span v-if="showButtonText" class="prompt-button-font-style"> Summarize </span>
    </Button>
  </div>
  <div
    v-else
    :data-cy="isGlobalPopup ? 'vision-ai-button-global' : 'vision-ai-button'"
    :class="['vision-ai-button', 'dash-transition-md', buttonPositionClasses]"
  >
    <Button
      v-tooltip="isGlobalPopup ? null : { content: tooltip, html: true }"
      no-border
      :class="[
        'summon-ai-vision-button-gdi w-[148]',
        { action: !isGlobalPopup, 'h-[42px]': large },
      ]"
      :loading="visionAiMessagesStore.summaryLoading && !isGlobalPopup"
      :round="isGlobalPopup"
      :small="!isGlobalPopup"
      @click="handleButtonClicked($event)"
    >
      <span v-if="showButtonText" class="prompt-button-font-style mr-2">Summarize</span>
      <Icon
        v-if="!Boolean(visionAiMessagesStore.summaryLoading && !isGlobalPopup)"
        data-test="vision-ai-icon"
        name="vision-ai"
        :large="isGlobalPopup"
        :xsmall="!isGlobalPopup"
        color="white"
      />
    </Button>
  </div>
</template>
<style lang="postcss" scoped>
.summon-ai-vision-button,
.summon-ai-vision-button-gdi {
  background: var(--vision-button-gradient);

  &.action {
    @apply gap-2 py-4 pl-2 pr-4;

    min-width: var(--width-130);
    color: var(--text-primary);
    border-radius: var(--space-24) var(--space-24) var(--space-24) var(--round-corner-small);
  }

  &:not(.action) {
    width: 40px;
    height: 40px;
  }
}

.summon-ai-vision-button-gdi {
  background: var(--color-background-vision-ai);

  &.action {
    border-radius: var(--button-border-radius);
    gap: 0;
    padding: 0;
  }

  span {
    margin-right: var(--space-8);
  }
}
</style>
