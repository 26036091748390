import { computed, unref } from 'vue';
import isNil from 'lodash/isNil';
import { logger } from '@/utils/logger';
import { useMetricsStore } from '@/stores/metrics';
import {
  AGGREGATION_CURRENT_GRAPH_STATS,
  AGGREGATION_NO_GRAPH_STATS,
  AGGREGATION_ONLY_AVG_GRAPH_STATS,
  AGGREGATION_TYPES,
  DATA_TYPE_FORMATS,
  DATA_TYPES,
} from '@/models/dashboards/metrics.constants';
import { METRIC_TYPE_REPORT_KEYS } from '@/app/dashboards/utils/reports.enum';
import { GRAPH_STATS } from '@/models/dashboards/graph-stats.enum';
import { formatValueByFormatType } from '@/utils/formatters';
import { MEDIA_TYPES } from '@/models/dashboards/media-types.enum';
import { DATA_TYPE_TIME_UNITS } from '@/app/dashboards/constants';
import { CHANNELS, compareChannels } from '@/models/dashboards/channels.enum';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';
import { AVAILABLE_CUSTOM_METRIC_CHANNELS } from '@/app/settings/components/CustomMetrics/constants';
import isNumber from 'lodash/isNumber';

export function useMetricDetails({ metric, channels, metricReportTypeKey } = {}) {
  const metricsStore = useMetricsStore();
  const dashboardReportsStore = useDashboardReportsStore();

  // unref the metric details arguments before use
  const reportChannels = unref(channels);
  const channel = reportChannels?.[0];
  const reportMetric = unref(metric);
  const unrefMetricReportTypeKey = unref(metricReportTypeKey);

  // Internal set up. Do not return these properties and functions
  const isContentReportMetric = computed(() => {
    return unrefMetricReportTypeKey === METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT;
  });

  const isGraphReportMetric = computed(() => {
    return unrefMetricReportTypeKey === METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT;
  });

  const isMultiChannelMetric = computed(() => {
    return reportChannels?.length > 1;
  });

  const targetMetricDetails = computed(() => {
    if (isMultiChannelMetric.value)
      return metricsStore.getTargetMetricDetailsMultiChannel(reportMetric);
    return metricsStore.getTargetMetricDetails(channel, unrefMetricReportTypeKey, reportMetric);
  });

  // Properties and functions for ALL report types
  const allowContentTags = computed(() => {
    return targetMetricDetails.value?.allow_content_tags;
  });

  const displayName = computed(() => {
    return targetMetricDetails.value?.display_name;
  });

  const formats = computed(() => {
    const dataType = targetMetricDetails.value?.data_type;
    return DATA_TYPE_FORMATS?.[dataType];
  });

  function convertMetricToRequiredUnit(dataType, value) {
    // seconds is the preferred unit to be displayed
    if (dataType === DATA_TYPE_TIME_UNITS.MILLISECONDS) {
      return Math.floor(value / 1000);
    }
    return value;
  }

  function formatMetricValue(value, formatType, unit = null) {
    const emptyValue = '-';
    if (isNil(value)) return emptyValue;
    const format = formats.value?.[formatType];
    const convertedValue = convertMetricToRequiredUnit(targetMetricDetails.value?.data_type, value);

    let formattedValue = isNumber(value) ? value.toString() : value;
    if (format) {
      formattedValue = formatValueByFormatType(convertedValue, format, emptyValue);
    } else {
      logger.error(`formatMetricValue: missing format for '${formatType}'`);
    }
    if (unit) formattedValue = `${formattedValue} ${unit}`;
    return formattedValue;
  }

  const isNegativeMetric = computed(() => {
    return targetMetricDetails.value?.is_negative_metric;
  });

  const isMonetaryMetric = computed(() => {
    return targetMetricDetails.value?.data_type === DATA_TYPES.MONETARY;
  });

  const lockedMediaBreakdown = computed(() => {
    return targetMetricDetails.value?.locked_media_breakdown;
  });

  const supportsMediaBreakdown = computed(() => {
    return targetMetricDetails.value?.supports_media_breakdown;
  });

  const mediaTypeLabel = computed(() => {
    if (!supportsMediaBreakdown.value) return null;
    if (!targetMetricDetails.value?.media_type_label) return MEDIA_TYPES.ALL_MEDIA.text;
    return targetMetricDetails.value?.media_type_label;
  });

  const parentMetric = computed(() => {
    return targetMetricDetails.value?.parent_metric ?? reportMetric;
  });

  const permissions = computed(() => {
    return targetMetricDetails.value?.permissions;
  });

  const requiredFeatureFlag = computed(() => {
    if (isMultiChannelMetric.value && reportChannels.includes(CHANNELS.YOUTUBE.value)) {
      const ytKey =
        dashboardReportsStore.comparableMetrics?.[reportMetric]?.[CHANNELS.YOUTUBE.value];
      const ytDetails = metricsStore.getTargetMetricDetails(
        CHANNELS.YOUTUBE.value,
        unrefMetricReportTypeKey,
        ytKey,
      );
      return ytDetails?.required_feature_flag;
    }
    return targetMetricDetails.value?.required_feature_flag;
  });

  const tooltip = computed(() => {
    return targetMetricDetails.value?.description;
  });

  // Properties and functions for CONTENT report types
  const sourceDataName = computed(() => {
    if (!isContentReportMetric.value) return null;
    return targetMetricDetails.value?.source_data_name;
  });

  // Properties and functions for GRAPH report types
  const aggregationType = computed(() => {
    let aggregation = targetMetricDetails.value?.aggregation;
    if (aggregation === AGGREGATION_TYPES.CUSTOM)
      aggregation = targetMetricDetails.value?.aggregation_type;
    return aggregation;
  });

  const graphBeginAtZero = computed(() => {
    if (!isGraphReportMetric.value) return null;
    return (
      aggregationType.value !== AGGREGATION_TYPES.LATEST &&
      aggregationType.value !== AGGREGATION_TYPES.LATEST_VALID
    );
  });

  const graphPrecision = computed(() => {
    if (!isGraphReportMetric.value) return null;
    const dataType = targetMetricDetails.value?.data_type;
    if (dataType === DATA_TYPES.PERCENTAGE) return 4;
    return 0;
  });

  const graphStats = computed(() => {
    if (!isGraphReportMetric.value) return null;
    if (
      AGGREGATION_NO_GRAPH_STATS.includes(aggregationType.value) ||
      (AGGREGATION_ONLY_AVG_GRAPH_STATS.includes(aggregationType.value) &&
        isMultiChannelMetric.value)
    ) {
      return [];
    }
    if (AGGREGATION_ONLY_AVG_GRAPH_STATS.includes(aggregationType.value)) {
      return [GRAPH_STATS.MEAN_BY_DAY.value];
    }
    if (AGGREGATION_CURRENT_GRAPH_STATS.includes(aggregationType.value)) {
      return [GRAPH_STATS.CURRENT.value, GRAPH_STATS.MEAN_BY_DAY.value];
    }
    return [GRAPH_STATS.SUM.value, GRAPH_STATS.MEAN_BY_DAY.value];
  });

  function sortMetricsByChannelPriority(availableMetrics, availableChannels) {
    availableChannels.sort(compareChannels);
    return availableChannels.reduce((acc, channelName) => {
      if (availableMetrics?.[channelName]) {
        acc[channelName] = availableMetrics[channelName].sort((a, b) =>
          a.displayName.localeCompare(b.displayName),
        );
      }
      return acc;
    }, {});
  }

  function getAvailableChannelsAndMetricsForCustomMetrics() {
    const availableChannels = Object.keys(metricsStore.metricDetails).filter((availableChannel) =>
      AVAILABLE_CUSTOM_METRIC_CHANNELS.includes(availableChannel),
    );

    let availableMetrics = availableChannels.reduce((acc, availableChannel) => {
      const metricsReport = metricsStore.metricDetails[availableChannel].metrics_report || {};

      acc[availableChannel] = Object.entries(metricsReport)
        .filter(([, metricValue]) => metricValue.allow_in_custom_metric_formulas)
        .map(([metricKey, metricValue]) => ({
          metricKey,
          displayName: metricValue.display_name ?? metricKey,
          channel: availableChannel,
          description: metricValue.description,
        }));

      return acc;
    }, {});

    availableMetrics = sortMetricsByChannelPriority(availableMetrics, availableChannels);

    return { availableChannels, availableMetrics };
  }

  return {
    allowContentTags,
    displayName,
    formatMetricValue,
    graphBeginAtZero,
    graphPrecision,
    graphStats,
    isNegativeMetric,
    isMonetaryMetric,
    lockedMediaBreakdown,
    mediaTypeLabel,
    parentMetric,
    permissions,
    requiredFeatureFlag,
    sourceDataName,
    supportsMediaBreakdown,
    tooltip,
    targetMetricDetails,
    sortMetricsByChannelPriority,
    getAvailableChannelsAndMetricsForCustomMetrics,
  };
}
