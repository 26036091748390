import sortBy from 'lodash/sortBy';
import { MAX_RECENT_BRAND_USERS, MAX_RECENT_USERS_FILTER_LIST } from '@/app/community/constants';
import { camelizeKeys } from 'humps';

export function formatUser(user = {}) {
  const _user = camelizeKeys(user);
  return {
    label: `${_user?.firstName} ${_user?.lastName}`,
    value: _user?.id,
    firstName: _user?.firstName,
    lastName: _user?.lastName,
    avatarUrl: _user?.avatarUrl,
    type: 'item',
  };
}

export function getMostRecentUserIds({
  comments,
  identity,
  limit = MAX_RECENT_USERS_FILTER_LIST,
} = {}) {
  // collaboration db comments
  return [
    ...new Set(
      sortBy(comments || [], (m) => m.createdAt)
        .reverse()
        .map(({ userId, text }) => {
          return userId ?? parseInt(text.replaceAll(/[@[\]]/gi, ''), 10);
        })
        .filter((n) => !Number.isNaN(n) && n !== identity?.id),
    ),
  ].slice(0, limit);
}

export function getCurrentAndUnassignedUser(identity) {
  const userItems = [];
  if (identity) {
    // push the current user first
    userItems.push({
      ...formatUser(identity),
      note: '(you)',
    });
  }
  // push unassigned filter option and a divider line
  userItems.push({
    label: 'Unassigned',
    firstName: '',
    lastName: '',
    value: -1,
    avatarUrl: null,
    type: 'item',
  });
  userItems.push({
    type: 'line',
  });
  return userItems;
}

export function getMostRecentUsers({ identity, mostRecentUserIds = [], accessibleUsers = [] }) {
  const userItems = getCurrentAndUnassignedUser(identity);
  if (accessibleUsers) {
    if (mostRecentUserIds?.length) {
      // push the most recent users
      const recentUsers = accessibleUsers
        .filter((user) => mostRecentUserIds.includes(user.id))
        .sort((a, b) => mostRecentUserIds.indexOf(a.id) - mostRecentUserIds.indexOf(b.id))
        .map((user) => formatUser(user));
      if (recentUsers?.length) {
        const topMostRecentUsers = recentUsers.slice(0, MAX_RECENT_BRAND_USERS);
        userItems.push(...topMostRecentUsers);
        if (recentUsers.length > topMostRecentUsers.length) {
          const remainingMostRecentUsers = sortBy(recentUsers.slice(MAX_RECENT_BRAND_USERS), [
            'firstName',
            'lastName',
          ]);
          userItems.push({ type: 'line' });
          userItems.push(...remainingMostRecentUsers);
        }
      }
    }
  }
  return userItems;
}
