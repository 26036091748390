<script setup>
import { computed, onBeforeMount, ref, unref, toRefs, watch } from 'vue';
import dayjs from 'dayjs';
import SchedulePostPopupWrapper from '@/app/scheduler/components/EditPost/Layout/SchedulePostPopupWrapper.vue';
import { discardConfirmMessage, toolTips, maxLinkedInCaptionLength } from '@/config';
import { useAuthStore } from '@/stores/auth';
import { usePlatformStore } from '@/stores/platform';
import { useMediaStore } from '@/stores/media';
import { useTrackingStore } from '@/stores/tracking';
import MediaManager from '@/app/scheduler/components/EditPost/MediaViewer/MediaManager.vue';
import RichTextarea from '@/app/scheduler/components/EditPost/RichTextarea/RichTextarea.vue';
import {
  decodeFromTiptapForLinkedinPost,
  encodeForTiptapFromLinkedinPost,
} from '@/app/scheduler/utils/tiptap';
import ListViewer from '@/app/scheduler/components/EditPost/MediaViewer/ListViewer.vue';
import AutoPublishOptions from '@/app/scheduler/components/AutoPublishOptions.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import PostSettingItem from '@/app/scheduler/components/PostSettingItem.vue';
import PostStatusBox from '@/app/scheduler/components/PostStatusBox.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import { allPosts } from '@/app/scheduler/utils/post-query';
import {
  PLATFORMS,
  mixpanelActions,
  popUpMessages,
  postStatus,
  CROSS_BRAND_DUPLICATE_ERROR,
  platformLabels,
  linkedinNotConnectedText,
} from '@/app/scheduler/constants';
import Calendar from '@/app/scheduler/components/Calendar.vue';
import QuickSelectCalendar from '@/app/scheduler/components/QuickSelectCalendar.vue';
import SplitButton from '@/components/foundation/SplitButton.vue';
import Controls from '@/app/scheduler/components/EditPost/Layout/Controls.vue';
import Button from '@/components/foundation/Button.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { usePostDuplication } from '@/composables/usePostDuplication';
import { useNotificationStore } from '@/stores/notification';
import {
  checkUnsavedChanges,
  getUnscheduledRouteName,
  validateLinkedInMedia,
} from '@/app/scheduler/utils';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { getConflictingMediaIds } from '@/app/scheduler/utils/publish-dates';
import { useApprovedPublishingDates } from '@/app/scheduler/composables/useApprovedPublishingDates';
import {
  CROSS_BRAND_DUPLICATE_PLATFORMS,
  DUPLICATION_POST_TYPES,
  DUPLICATION_TYPES,
} from '@/utils/postDuplication';
import { useFlagStore } from '@/stores/flag';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import isEqual from 'lodash/isEqual';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import { postTypeLabelMap, SchedulerUserEventTracker } from '@/app/scheduler/mixpanel';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import { refreshBestTimesToPost } from '@/app/scheduler/utils/bestTimesToPost';

const props = defineProps({
  close: {
    type: Function,
    required: true,
  },
  post: {
    type: Object,
    default: null,
  },
  media: {
    type: Array,
    default: null,
  },
  mediaCaption: {
    type: String,
    default: null,
  },
  disableEditing: {
    type: Boolean,
    default: false,
  },
  onValidateMedia: {
    type: Function,
    default: () => {},
  },
  showPostEditorSidePanel: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const platformStore = usePlatformStore();
const schedulerStore = useSchedulerStore();
const notificationStore = useNotificationStore();
const mediaStore = useMediaStore();
const trackingStore = useTrackingStore();
const flagStore = useFlagStore();
const customerJourneyStore = useCustomerJourneyStore();

const {
  pdCrossBrandDuplicationDisabled,
  pdDuplicatePostToChannel,
  pdDuplicationOptions,
  duplicateToBrandSubList,
} = usePostDuplication();

const isSaving = ref(false);
const showRemoveConfirm = ref(false);

const hasUnsavedChanges = ref(false);
const uploadPending = ref(false);
// eslint-disable-next-line vue/no-setup-props-destructure
const postText = ref(props.post?.text || '');
const mediaUploadError = ref(null);
const scheduledTime = ref(null);
const platform = ref(PLATFORMS.LINKEDIN);
const mediaValidationError = ref(null);
const mediaWarningIds = ref([]);
const isValidating = ref(false);
const mediaValidation = ref(Promise.resolve());
// eslint-disable-next-line vue/no-setup-props-destructure
const mediaList = ref(props.media || props.post?.media || []);
const approvalRequests = ref((props.post && props.post.approvalRequests) || []);
const isDraft = ref(
  (!props.post?.id && !props.post?.timestamp) || props.post?.status === postStatus.DRAFT,
);

// ############# MIXIN ##################
const { post } = toRefs(props);
const {
  approvedPublishingDatesExpired,
  canPublishWithin,
  publishDateRangeWarning,
  publishDateError,
  scheduledTimeError,
  canAssetPublishToday,
  publishNowWarning,
} = useApprovedPublishingDates({
  scheduledTime,
  post,
  mediaList,
});

// ############### COMPUTED VARIABLES ###############
const confirmation = computed(() => {
  if (hasUnsavedChanges.value) {
    return discardConfirmMessage;
  }
  return null;
});
const timestampAddedOrRemoved = computed(
  () =>
    (!scheduledTime.value && props.post && props.post.timestamp) ||
    (!props.post.timestamp && scheduledTime.value),
);

const postData = computed(() => {
  return {
    platform: PLATFORMS.LINKEDIN,
    id: props.post?.id,
    autoPublish: true,
    brandId: authStore.currentBrand.id,
    text: postText.value,
    media: mediaList.value,
    mediaIds: mediaList.value?.map((m) => m.id),
    timestamp: scheduledTime?.value?.toISOString?.() ?? null,
    approvalRequests: approvalRequests.value,
    ...(isDraft.value && flagStore.ready && flagStore.flags.schedulerDrafts
      ? { status: postStatus.DRAFT }
      : {}),
    ...(props.post?.approvalPolicy ? { approval_policy: props.post.approvalPolicy } : {}),
  };
});

const displayName = computed(
  () =>
    platformStore.platformConnectionsMap.linkedin?.[authStore.currentBrand?.id]?.email_or_handle ||
    authStore.currentBrand?.name,
);
const connection = computed(
  () => platformStore.platformConnectionsMap.linkedin?.[authStore.currentBrand?.id],
);
const connected = computed(() => connection.value && connection.value?.status === 'connected');

const messageHTML = computed({
  get() {
    return encodeForTiptapFromLinkedinPost(postText.value || props.mediaCaption || '');
  },
  set(text) {
    postText.value = decodeFromTiptapForLinkedinPost(text);
  },
});
const scheduledTimeLabel = computed(() => {
  return scheduledTime.value ? scheduledTime.value.format('LT, ll') : null;
});
const brandLinkedinPosts = computed(() =>
  allPosts.forBrand(authStore.currentBrand.id).forPlatform(platform.value),
);
const maxTextCharsExceededBy = computed(
  () => postText.value && postText.value.length - maxLinkedInCaptionLength,
);
const maxTextCharErrorWarning = computed(() => {
  return maxTextCharsExceededBy.value > 0
    ? { message: toolTips.maxLinkedInCharactersReached, level: 'error' }
    : null;
});

const multipleMediaUploadError = computed(() => {
  return mediaList.value.length > 1
    ? { message: toolTips.maxLinkedInMediaLimit, level: 'error' }
    : null;
});
const validationErrorOrWarning = computed(() => {
  if (
    publishDateError?.value &&
    ![postStatus.POSTED, postStatus.IMPORTED].includes(props.post?.status)
  ) {
    return publishDateError.value;
  }

  return (
    mediaValidationError.value ??
    mediaUploadError.value ??
    maxTextCharErrorWarning.value ??
    multipleMediaUploadError.value
  );
});

const hasCrossBrandDuplicateError = computed(() => {
  return mediaUploadError.value === CROSS_BRAND_DUPLICATE_ERROR;
});

watch(publishDateError, () => {
  const outsideDatesErr = publishDateError?.value?.code === 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES';
  mediaWarningIds.value = outsideDatesErr
    ? getConflictingMediaIds(mediaList.value, scheduledTime.value)
    : [];
});

const listViewerComponentProps = computed(() => {
  const outsideDatesErr = publishDateError?.value?.code === 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES';
  let mediaValidationErrorList = outsideDatesErr
    ? mediaList.value.map((media) =>
        mediaWarningIds.value.includes(media.id) ? validationErrorOrWarning : null,
      )
    : mediaValidationError;

  mediaValidationErrorList = hasCrossBrandDuplicateError.value
    ? mediaList.value.map((media) =>
        media.brandId !== authStore.currentBrand.id && !mediaStore.pending.createMediaV2
          ? validationErrorOrWarning
          : null,
      )
    : mediaValidationErrorList;
  return {
    platform: 'linkedin',
    mediaValidationErrorList: unref(mediaValidationErrorList) ?? [],
    mediaWarningIds: unref(mediaWarningIds),
  };
});

const postEmpty = computed(() => {
  return !postText?.value?.trim() && !mediaList.value.length;
});

const saveDisabled = computed(
  () =>
    postEmpty.value ||
    !!validationErrorOrWarning.value ||
    (scheduledTime.value && scheduledTime.value < dayjs.utc(dayjs()).local()) ||
    uploadPending.value ||
    !connected.value ||
    postText?.value?.length > maxLinkedInCaptionLength,
);
const saveTooltip = computed(() => {
  const appNotConnected = !connected.value ? linkedinNotConnectedText : null;
  const uploadPendingTip = uploadPending.value ? toolTips.mediaUploading : null;
  const illegalTimeStampTip =
    scheduledTime.value && scheduledTime.value < dayjs.utc(dayjs()).local()
      ? toolTips.illegalTimeStamp
      : null;
  const postEmptyTip = postEmpty.value ? toolTips.unableToSaveEmptyPost : null;
  return (
    postEmptyTip ||
    validationErrorOrWarning.value?.message ||
    uploadPendingTip ||
    illegalTimeStampTip ||
    appNotConnected
  );
});

const scheduleTimeTooltip = computed(() => {
  return !connected.value ? toolTips.noLinkedinConnection : '';
});

const requiresApproval = computed(() => {
  return postRequiresApproval({ post: props.post });
});

const publishNowDisabledData = computed(() => {
  if (!authStore.canAccessAutoPublish) {
    return { isDisabled: true, tip: toolTips.insufficientPermission };
  }
  if (postEmpty.value) {
    return { isDisabled: true, tip: toolTips.noMediaSelected };
  }
  if (!unref(canAssetPublishToday)) {
    return { isDisabled: true, tip: unref(publishNowWarning) };
  }
  if (requiresApproval.value) {
    return { isDisabled: true, tip: toolTips.requiresApproval };
  }
  return { isDisabled: false };
});

const crossBrandDuplicatePostsFlagSet = computed(() => {
  return flagStore.ready && flagStore.flags.crossBrandDuplicatePosts;
});

const canCrossBrandDuplicatePost = computed(() => {
  return (
    CROSS_BRAND_DUPLICATE_PLATFORMS.includes(props.post.platform) && crossBrandDuplicatePostsFlagSet
  );
});
const hasApprovalRequestsAccess = computed(() => {
  return !customerJourneyStore.hasPlanGrow;
});

const isDraftToggleDisabled = computed(() => {
  const isPublished = [postStatus.POSTED, postStatus.AUTOPUBLISHING, postStatus.IMPORTED].includes(
    props.post?.status,
  );
  return isPublished || !postData.value?.timestamp;
});

const bestTimesToPost = computed(() => {
  return schedulerStore.recommendedPublishTimes?.[authStore.currentBrand.id]?.[PLATFORMS.LINKEDIN];
});
const postApprovalRequests = computed(() => {
  return schedulerStore.approvalRequests?.[authStore.currentBrand.id]?.[PLATFORMS.LINKEDIN] || [];
});
// ############### FUNCTIONS ###############
function mediaSelected(media) {
  mediaList.value = media;
}
function onMediaUploadError(errorMessage) {
  mediaUploadError.value = errorMessage ?? null;
}
function switchSubScreen(screen) {
  if (!props.disableEditing) {
    schedulerStore.setActiveSubScreen(screen);
    if (screen === 'schedule') {
      trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
        postPlatform: 'LinkedIn',
        quickSelectClicked: false,
      });
    }
  }
}
function timeSelected(time) {
  if (!scheduledTime.value) isDraft.value = false;
  scheduledTime.value = dayjs(time);
  switchSubScreen('index');
}

async function remove() {
  try {
    await schedulerStore.deletePost(props.post);
    if (scheduledTime.value) {
      notificationStore.setToast({ message: 'Post removed from your timeline.' });
    } else {
      notificationStore.setToast({
        message: 'Post removed from your unscheduled posts.',
      });
    }
    props.close();
  } catch (e) {
    showRemoveConfirm.value = false;
    notificationStore.setToast({
      type: 'error',
      message: 'Something went wrong removing your post. Try again later.',
    });
  }
}

async function onRemoveClick() {
  const { title, message } = popUpMessages.removeFromScheduler;
  await notificationStore.confirm(title, message, {
    confirmAlias: 'Remove',
    onConfirm: remove,
  });
}

function setNotification(text) {
  if (scheduledTime.value) {
    notificationStore.setToast({ message: `${text} timeline.` });
  } else {
    notificationStore.setToast({
      message: text,
      actionText: 'unscheduled posts.',
      actionTo: {
        name: getUnscheduledRouteName(PLATFORMS.LINKEDIN),
      },
      sameLine: true,
    });
  }
}
async function savePost(closeAfter = true) {
  try {
    if (props.post?.id) {
      // Take value before updating post (as it then updates this.post)
      const timeStampAddedOrRemoved = timestampAddedOrRemoved.value;
      await schedulerStore.updatePost(postData.value);
      if (timeStampAddedOrRemoved) {
        setNotification('Post updated & added to your ');
      } else {
        notificationStore.setToast({ message: 'Post updated.' });
      }
    } else {
      await schedulerStore.createPost(postData.value);
      setNotification('Post added to your ');
    }
    if (closeAfter) {
      props.close();
    }
  } catch (e) {
    notificationStore.setToast({
      type: 'error',
      message: 'Something went wrong saving your post. Try again later.',
    });
    throw e;
  } finally {
    isSaving.value = false;
  }
}

async function save(closeAfter = true) {
  if (saveDisabled.value || schedulerStore.postActionPending) {
    return;
  }
  isSaving.value = true;
  await savePost(closeAfter);
}

async function publishNow(closeAfter = true) {
  isSaving.value = true;

  const data = {
    ...postData.value,
    status: postStatus.AUTOPUBLISHING,
  };

  if (props.post?.id) {
    await schedulerStore.updatePost(data);
  } else {
    await schedulerStore.createPost(data);
  }

  notificationStore.setToast({
    message: 'Publishing in progress.',
    actionText: 'See it on your Timeline',
    actionTo: { name: 'scheduler.linkedin.timeline' },
    type: 'primary',
  });

  isSaving.value = false;
  if (closeAfter) {
    props.close();
  }
}

async function publishNowAndDuplicate({ toType }) {
  const duplicateData = postData.value;
  const postDuplicationData = {
    duplicatedFrom: DUPLICATION_POST_TYPES.LINKEDIN_POST,
    duplicatedTo: toType,
    duplicationType: DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
  };
  trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
  await publishNow(false);
  if (!duplicateData?.id) {
    duplicateData.id = this.schedulerStore.duplicationPostId;
  }
  pdDuplicatePostToChannel({
    toType,
    fromType: DUPLICATION_POST_TYPES.LINKEDIN_POST,
    post: duplicateData,
  });
}

async function saveAndDuplicate({ toType }) {
  const duplicateData = postData.value;
  const postDuplicationData = {
    duplicatedFrom: DUPLICATION_POST_TYPES.LINKEDIN_POST,
    duplicatedTo: toType,
    duplicationType: DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
  };
  trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
  await save(false);
  if (!duplicateData?.id) {
    duplicateData.id = schedulerStore.duplicationPostId;
  }
  pdDuplicatePostToChannel({
    toType,
    fromType: DUPLICATION_POST_TYPES.LINKEDIN_POST,
    post: duplicateData,
  });
}

function duplicate({ toType }) {
  const postDuplicationData = {
    duplicatedFrom: DUPLICATION_POST_TYPES.LINKEDIN_POST,
    duplicatedTo: toType,
    duplicationType: DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
  };
  trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
  pdDuplicatePostToChannel({
    fromType: DUPLICATION_POST_TYPES.LINKEDIN_POST,
    toType,
    post: props.post,
  });
}

async function onCalendarFetch(start, end) {
  return schedulerStore.fetchPosts(brandLinkedinPosts.value.betweenDates(start, end));
}

async function validateMedia() {
  const MediaList = mediaList.value;
  if (MediaList[0]) {
    isValidating.value = true;
    mediaValidation.value = mediaValidation.value
      .then(() => validateLinkedInMedia(MediaList[0]))
      .then((result) => {
        mediaValidationError.value = result;
        props.onValidateMedia(result);
      })
      .finally(() => {
        isValidating.value = false;
      });
  }
}

function uploadStatusChanged(status) {
  uploadPending.value = status;
}

function trackMixpanel() {
  return new SchedulerUserEventTracker();
}

function onToggleDraft(draft) {
  isDraft.value = draft;
  trackMixpanel().draftToggleClicked(draft, postTypeLabelMap.linkedin);
}

watch(
  () => mediaList.value,
  function watchMediaChange(to) {
    if (to?.length === 0) {
      mediaWarningIds.value = [];
      mediaValidationError.value = null;
    } else {
      validateMedia();
    }
  },
  { immediate: true },
);

watch(postApprovalRequests, (newVal, oldVal) => {
  if (!isEqual(newVal, oldVal)) approvalRequests.value = newVal;
});

watch(postData, (to, from) => {
  const fieldChanged =
    props.post === null
      ? checkUnsavedChanges(to, from, scheduledTime.value)
      : checkUnsavedChanges(postData.value, props.post, scheduledTime.value);
  if (!to?.timestamp) isDraft.value = true;
  hasUnsavedChanges.value = !props.disableEditing && (fieldChanged || uploadPending.value);
});

onBeforeMount(() => {
  refreshBestTimesToPost(authStore.currentBrand.id, PLATFORMS.LINKEDIN);
  schedulerStore.setAutoPublish(true);
  schedulerStore.setActiveSubScreen('index');
  scheduledTime.value = props.post && props.post?.timestamp && dayjs(props.post.timestamp);
});

const saveOptions = computed(() => [
  {
    text: 'Save & Duplicate',
    subList: pdDuplicationOptions(saveAndDuplicate),
  },
  {
    text: 'Publish Now',
    action: publishNow,
    disabled: publishNowDisabledData.value.isDisabled,
    tooltip: publishNowDisabledData.value.tip,
  },
  {
    text: 'Publish Now & Duplicate',
    disabled: publishNowDisabledData.value.isDisabled,
    tooltip: publishNowDisabledData.value.tip,
    subList: pdDuplicationOptions(publishNowAndDuplicate),
  },
]);

const duplicatePostOptions = computed(() => {
  const crossBrandDisabled = pdCrossBrandDuplicationDisabled(props.post, mediaList.value);
  return crossBrandDuplicatePostsFlagSet.value
    ? [
        {
          text: 'To Channel',
          subList: pdDuplicationOptions(duplicate),
        },
        ...(canCrossBrandDuplicatePost.value
          ? [
              {
                text: 'To Brand',
                subList: duplicateToBrandSubList(props.post),
                disabled: crossBrandDisabled.isDisabled,
                tooltip: crossBrandDisabled.tip,
              },
            ]
          : []),
      ]
    : pdDuplicationOptions(duplicate);
});

defineExpose({
  mediaSelected,
  saveAndDuplicate,
  publishNowAndDuplicate,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <SchedulePostPopupWrapper
    :platform="platform"
    :close="close"
    :close-confirmation="confirmation"
    :post="post"
    :show-post-editor-side-panel="showPostEditorSidePanel"
    :avatar="connection && (connection.avatar_url || connection.avatarUrl)"
    :display-name="displayName"
    :show-media-placeholder-message="unref(mediaList).length === 0"
    :has-unsaved-changes="hasUnsavedChanges"
  >
    <template #media>
      <div class="caption-header">
        <p class="pb-1.5">LinkedIn Post</p>
        <span v-if="maxTextCharErrorWarning" class="character-counter">
          -{{ maxTextCharsExceededBy }}
        </span>
      </div>
      <RichTextarea
        ref="richTextArea"
        v-model="messageHTML"
        :disabled="disableEditing"
        autofocus="end"
        placeholder="Compose LinkedIn Post"
        textarea-id="linkedin-post-edit"
        data-cy="scheduled-post-text-area"
        enable-hash-tag-highlighting
        resizable
        @text-pasted="(e) => e.clipboardData.getData('text')"
      />
      <MediaManager
        class="mt-4"
        :disable-editing="disableEditing"
        :display-error-message="onMediaUploadError"
        :media-list="mediaList"
        :media-selected="mediaSelected"
        :viewer-component="ListViewer"
        :viewer-component-props="listViewerComponentProps"
        :on-upload-status-change="uploadStatusChanged"
        disallow-past-publish-dates
        disallow-incompatible-publish-dates
        media-type-limit="IMAGE"
        :media-count-limit="1"
        :can-be-published-at="unref(scheduledTime)"
        :publish-dates-must-overlap-with="canPublishWithin"
      />
      <Banner
        v-if="validationErrorOrWarning"
        data-cy="validationBanner"
        :custom-icon="validationErrorOrWarning?.icon"
        :alert-type="validationErrorOrWarning?.level ?? 'error'"
        class="mb-8 mt-4"
        hide-default-icon
      >
        {{ validationErrorOrWarning.message }}
      </Banner>

      <Banner
        v-if="unref(publishDateRangeWarning)"
        :alert-type="publishDateRangeWarning.level"
        class="validation-banner"
      >
        {{ publishDateRangeWarning.message }}
      </Banner>
    </template>
    <template #details>
      <template v-if="schedulerStore.activeSubScreen === 'index'">
        <section>
          <PostStatusBox :post="{ ...post, platform: 'linkedin' }" />
          <PublishOptionHeader
            :disabled="isDraftToggleDisabled"
            :is-on="isDraft"
            :post="post"
            @drafts-toggled="(draft) => onToggleDraft(draft)"
          />
          <AutoPublishOptions
            :disabled="disableEditing"
            hide-send-notification
            :platform="platform"
            :post="post"
          />
          <PostSettingItem
            :input-tooltip="scheduleTimeTooltip"
            :title="scheduledTimeLabel"
            :clear-action="() => (scheduledTime = null)"
            :disabled="disableEditing || !connected || approvedPublishingDatesExpired"
            :error-message="unref(scheduledTimeError)"
            :on-click="() => switchSubScreen('schedule')"
            empty="Schedule Date & Time"
            left-icon="calendar"
          />
          <QuickSelectCalendar
            v-if="!disableEditing && connection && !approvedPublishingDatesExpired"
            ref="quickSelectCalendar"
            :class="{ 'pt-4': publishDateError }"
            :model-value="scheduledTime"
            :restrict-selection-to-interval="unref(canPublishWithin)"
            :platform="platformLabels.linkedin"
            @update:model-value="timeSelected"
          />
        </section>
        <PostSettingItem
          v-if="hasApprovalRequestsAccess"
          :title="getApprovalRequestTitle(approvalRequests)"
          :on-click="
            () =>
              schedulerStore.toggleSidePanel({
                router: $router,
                platform: 'linkedin',
                post,
              })
          "
          data-cy="approval-request-setting-item"
          empty="Add Reviewers"
          left-icon="chatBubbleSquareCheck"
          label="Approval Request"
          :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
        />
        <Controls class="controls">
          <Button
            v-if="!disableEditing && post && post.id"
            v-tooltip="'Remove from Scheduler'"
            remove
            class="remove"
            @click="onRemoveClick"
          >
            Remove
          </Button>
          <SplitButton
            v-if="!disableEditing"
            :tooltip="saveTooltip"
            :disabled="saveDisabled"
            :loading="schedulerStore.postActionPending"
            :options="saveOptions"
            class="keepDropdownUp min-w-[10rem]"
            :dropdown-list-styles="{ 'margin-top': 'var(--space-4)', 'min-width': '150px' }"
            primary
            @click="save"
          >
            Save
          </SplitButton>
          <DropdownButton
            v-else
            :dropdown-list="duplicatePostOptions"
            :dropdown-list-styles="{ 'margin-top': 'var(--space-4)' }"
            scrollable-list
            button-classes="primary"
            items-center
            data-cy="duplicate-post-button"
          >
            <template #buttonContent>Duplicate Post</template>
          </DropdownButton>
        </Controls>
      </template>
      <SubScreen
        v-if="schedulerStore.activeSubScreen === 'schedule'"
        title="Choose Date & Time"
        @on-back="switchSubScreen('index')"
      >
        <template #content>
          <div>
            <Calendar
              :posts="schedulerStore.getPosts(brandLinkedinPosts)"
              :time-selected="timeSelected"
              :on-fetch-posts="onCalendarFetch"
              :restrict-selection-to-interval="unref(canPublishWithin)"
              :ideal-post-times="bestTimesToPost"
              platform="linkedin"
              type="compact"
            />
          </div>
        </template>
      </SubScreen>
    </template>
  </SchedulePostPopupWrapper>
</template>
<style scoped>
.caption-header {
  display: flex;
  justify-content: space-between;
}

.character-counter {
  color: var(--error-500);
  font-size: var(--x12);
}

.controls {
  position: sticky;
  bottom: 0;
}

.validation-banner {
  margin: var(--space-16) 0 0 0;
}
</style>
