import cloneDeep from 'lodash/cloneDeep';
import { CHANNELS, COMPETITIVE_CHANNELS, compareChannels } from '@/models/dashboards/channels.enum';
import { sortLayout } from '@/app/dashboards/utils/grid.utils';
import constants from '@/app/dashboards/constants';
import {
  MULTI_CHANNEL_REPORT_TYPES,
  SINGLE_BRAND_REPORT_TYPES,
  SINGLE_COMPETITOR_REPORT_TYPES,
} from '@/app/dashboards/utils/reports.enum';

const MAP_CHANNELS = { INSTAGRAM_UGC: 'INSTAGRAM', INSTAGRAM_STORIES: 'INSTAGRAM' };

export function getTemplateLayout(template, brands = [], competitors = []) {
  const singleBrandLayout = template?.layouts?.singleBrand;
  const multiBrandLayout = template?.layouts?.multiBrand;

  if (brands.length === 1 && competitors.length === 0) {
    return singleBrandLayout || multiBrandLayout || [];
  }
  return multiBrandLayout || singleBrandLayout || [];
}

function hasChannel(layoutItem) {
  return !!layoutItem?.meta?.channel;
}

function getMappedChannel(channel) {
  return MAP_CHANNELS?.[channel] || channel;
}

export function getChannels(template) {
  const layout = getTemplateLayout(template);

  return layout
    .filter((layoutItem) => hasChannel(layoutItem))
    .reduce((results, layoutItem) => {
      const channel = getMappedChannel(layoutItem.meta.channel);
      if (!results.includes(channel)) {
        results.push(channel);
      }
      return results;
    }, [])
    .sort(compareChannels);
}

export function getChannelIcons(channels) {
  const channelIconsObject = channels.reduce((results, channel) => {
    const channelIcon = CHANNELS[channel]?.channelIcon;
    results[channelIcon] = {
      value: channelIcon,
      competitive: COMPETITIVE_CHANNELS.includes(channel),
    };
    return results;
  }, {});
  return Object.values(channelIconsObject);
}

export function getGroupedChannelIcons(template, maxColumns) {
  const channels = getChannels(template);
  const ungroupedIconList = [...(template?.iconOverrideList || getChannelIcons(channels))];
  const numIcons = ungroupedIconList.length;
  if (numIcons === 0) {
    return [];
  }

  let startingGroup = [];
  const remainder = numIcons % maxColumns;
  if (remainder !== 0) {
    startingGroup = [ungroupedIconList.splice(0, remainder)];
  }

  let currGroup = [];
  return ungroupedIconList.reduce((results, channelIcon) => {
    currGroup.push(channelIcon);

    if (currGroup.length === maxColumns) {
      results.push(currGroup);
      currGroup = [];
    }

    return results;
  }, startingGroup);
}

function isSingleBrandItem(layoutItem) {
  if (layoutItem?.meta?.channels?.length > 1) {
    return MULTI_CHANNEL_REPORT_TYPES.includes(layoutItem?.type);
  }
  return SINGLE_BRAND_REPORT_TYPES.includes(layoutItem?.type);
}

function isSingleCompetitorItem(layoutItem) {
  return SINGLE_COMPETITOR_REPORT_TYPES.includes(layoutItem?.type);
}

function itemIsGrouped(layoutItem, nextLayoutItem, isLastLayoutItem) {
  const isGroupHeader =
    layoutItem.type === 'HEADER' &&
    !isLastLayoutItem &&
    (isSingleBrandItem(nextLayoutItem) || isSingleCompetitorItem(nextLayoutItem));
  return isGroupHeader || isSingleBrandItem(layoutItem) || isSingleCompetitorItem(layoutItem);
}

function getLayoutGroupType(layoutItem) {
  if (isSingleBrandItem(layoutItem)) {
    return 'brandGroup';
  }
  if (isSingleCompetitorItem(layoutItem)) {
    return 'competitorGroup';
  }
  return null;
}

function getGroupPayload(currentGroup) {
  const payload = {};
  payload[currentGroup.type] = currentGroup.group;
  return payload;
}

function formatTemplateLayout(templateLayout) {
  const sortedTemplateLayout = templateLayout.sort(sortLayout);

  const currentGroup = {
    group: [],
    type: null,
  };

  return sortedTemplateLayout.reduce((results, layoutItem, index) => {
    const isLastLayoutItem = index === sortedTemplateLayout.length - 1;
    const nextLayoutItem = sortedTemplateLayout[index + 1];
    const isGroupMember = itemIsGrouped(layoutItem, nextLayoutItem, isLastLayoutItem);

    if (isGroupMember) {
      const itemGroupType = getLayoutGroupType(layoutItem);

      if (currentGroup.type == null || itemGroupType === currentGroup.type) {
        currentGroup.group.push(layoutItem);
      } else {
        results.push(getGroupPayload(currentGroup));
        currentGroup.group = [layoutItem];
      }
      currentGroup.type = itemGroupType;
    }

    if ((!isGroupMember || isLastLayoutItem) && currentGroup.group.length > 0) {
      results.push(getGroupPayload(currentGroup));
      currentGroup.group = [];
    }

    if (!isGroupMember) {
      results.push(layoutItem);
    }

    return results;
  }, []);
}

function setPositionData(layoutItem, positionData) {
  const extendsPastLastCol = positionData.currX + layoutItem.w > constants.NUMBER_OF_DASHBOARD_COLS;
  if (extendsPastLastCol) {
    positionData.currX = 0;
    positionData.currY += positionData.yIncrease;
    positionData.yIncrease = 0;
  }

  layoutItem.y = positionData.currY;

  positionData.currX += layoutItem.w;
  const largerYInc = layoutItem.h > positionData.yIncrease;
  if (largerYInc) {
    positionData.yIncrease = layoutItem.h;
  }
}

function getBrandName(brandArray, targetBrandId) {
  const targetBrand = brandArray.filter((brand) => brand.id === targetBrandId);
  return targetBrand[0].name || targetBrand[0].label || '';
}

export function customizeLayout(templateLayout, brands, competitors) {
  const positionData = {
    currX: 0,
    currY: 0,
    yIncrease: 0,
  };
  const brandIds = brands.map((brand) => brand.id);
  const competitorIds = competitors.map((competitor) => competitor.sourceAccountId);

  return formatTemplateLayout(templateLayout).reduce((results, layoutItemTemplate) => {
    const layoutItem = cloneDeep(layoutItemTemplate);

    if (layoutItem?.brandGroup) {
      brands.forEach((brand) => {
        layoutItem.brandGroup.forEach((groupLayoutItem) => {
          const repeatLayoutItem = cloneDeep(groupLayoutItem);
          if (repeatLayoutItem.type === 'HEADER' && brands.length > 1) {
            repeatLayoutItem.meta.name += ` - ${getBrandName(brands, brand.id)}`;
          } else if (repeatLayoutItem.meta.brand_ids) {
            repeatLayoutItem.meta.brand_ids = [brand.id];
          }
          setPositionData(repeatLayoutItem, positionData);
          results.push(repeatLayoutItem);
        });
      });
    } else if (layoutItem?.competitorGroup) {
      competitors.forEach((competitor) => {
        layoutItem.competitorGroup.forEach((groupLayoutItem) => {
          const repeatLayoutItem = cloneDeep(groupLayoutItem);
          if (repeatLayoutItem.type === 'HEADER' && competitors.length > 1) {
            repeatLayoutItem.meta.name += ` - ${competitor.handle}`;
          } else if (repeatLayoutItem?.meta?.competitor_source_account_ids) {
            repeatLayoutItem.meta.competitor_source_account_ids = [competitor.sourceAccountId];
          }
          setPositionData(repeatLayoutItem, positionData);
          results.push(repeatLayoutItem);
        });
      });
    } else {
      if (layoutItem?.meta?.brand_ids) {
        if (brandIds.length > 0) {
          layoutItem.meta.brand_ids = brandIds;
        } else {
          delete layoutItem.meta.brand_ids;
        }
      }
      if (layoutItem?.meta?.competitor_source_account_ids) {
        if (competitorIds.length > 0) {
          layoutItem.meta.competitor_source_account_ids = competitorIds;
        } else {
          delete layoutItem.meta.competitor_source_account_ids;
        }
      }
      setPositionData(layoutItem, positionData);
      results.push(layoutItem);
    }

    return results;
  }, []);
}

export function sortBrands(brandArray) {
  return brandArray.sort((brandA, brandB) => {
    return brandA.label.localeCompare(brandB.label);
  });
}
