import { USER } from '@/models/auth/permissions.enum';
import { constants } from '@/config';

const App = () => import('@/app/pinterest/App.vue');
const PinterestGallery = () => import('@/app/pinterest/pages/PinterestGallery.vue');
const PinterestGalleryEdit = () => import('@/app/pinterest/pages/PinterestGalleryEdit.vue');
const BoardsWrapper = () => import('@/app/boards/pages/BoardsWrapper.vue');
const Galleries = () => import('@/app/pinterest/pages/Galleries.vue');
const Overview = () => import('@/app/pinterest/pages/Overview.vue');
const Followers = () => import('@/app/pinterest/pages/Followers.vue');
const Engagement = () => import('@/app/pinterest/pages/Engagement.vue');
const PinIndex = () => import('@/app/pinterest/pages/PinIndex.vue');

const PAGE_LEVEL_TOP = `Pinterest`;
const PAGE_LEVEL_INSIGHTS = `Insights`;
const PAGE_LEVEL_DH_BOARDS = `DH Boards`;

export default {
  path: 'pinterest',
  component: App,
  meta: {
    requiresAuth: true,
    permissions: [USER.PINTEREST.CAN_ACCESS_PINT],
  },
  children: [
    {
      name: 'pinterest',
      path: '',
      meta: {
        analytics: `${PAGE_LEVEL_TOP}`,
      },
      redirect: { name: 'pinterest.index.overview' },
    },
    {
      name: 'pinterest.index',
      path: 'dashboard',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
        pinterestRoute: true,
      },
      redirect: { name: 'pinterest.index.overview' },
    },
    {
      name: 'pinterest.index.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
        pinterestRoute: true,
      },
    },
    {
      name: 'pinterest.insights.engagement',
      path: 'engagement',
      component: Engagement,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Engagement`,
        pinterestRoute: true,
      },
    },
    {
      name: 'pinterest.insights.engagement.report',
      path: 'engagement/report',
      component: Engagement,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Engagement Report`,
        report: true,
      },
    },
    {
      name: 'pinterest.insights.followers',
      path: 'followers',
      component: Followers,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Followers`,
        pinterestRoute: true,
      },
    },
    {
      name: 'pinterest.insights.followers.report',
      path: 'followers/report',
      component: Followers,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Followers Report`,
        report: true,
        layout: 'horizontal',
      },
    },
    {
      name: 'pinterest.insights.pins',
      path: 'pins',
      component: PinIndex,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Pins`,
        pinterestRoute: true,
      },
    },
    {
      name: 'pinterest.insights.pins.report',
      path: 'pins/report',
      component: PinIndex,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSIGHTS} - Your Pins Report`,
        report: true,
      },
    },
    {
      path: 'board',
      children: [
        {
          name: 'pinterest.galleries',
          path: '',
          component: BoardsWrapper,
          props: {
            galleryType: constants.PINTEREST,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_DH_BOARDS}`,
            pinterestRoute: true,
            permissions: [USER.PINTEREST.CAN_ACCESS_PINT_BOARDS],
          },
          children: [
            {
              name: 'pinterest.galleries.new',
              path: 'new',
              component: PinterestGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_DH_BOARDS} - New Board`,
                pinterestRoute: true,
              },
            },
          ],
        },
        {
          name: 'pinterest.galleries.detail',
          path: ':id',
          component: PinterestGallery,
          children: [
            {
              name: 'pinterest.galleries.edit',
              path: 'edit',
              component: PinterestGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_DH_BOARDS} - Edit Board`,
                pinterestRoute: true,
              },
            },
          ],
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_DH_BOARDS} - View Board`,
            pinterestRoute: true,
            permissions: [USER.PINTEREST.CAN_ACCESS_PINT_BOARDS],
          },
        },
      ],
    },
    {
      name: 'pinterest.galleries.report',
      path: 'board/report',
      component: Galleries,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_DH_BOARDS} - Report`,
        report: true,
        layout: 'horizontal',
      },
    },
    {
      name: 'pinterest.galleries.boards.report',
      path: 'board/:id/report',
      component: PinterestGallery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_DH_BOARDS} - View Board Report`,
        report: true,
        layout: 'horizontal',
        hideNav: true,
        hideVisionAi: true,
        permissions: [USER.PINTEREST.CAN_ACCESS_PINT_BOARDS],
      },
    },
  ],
};
