import { metaPromotedFieldsToVerbose } from '@/app/instagram/constants';
import startCase from 'lodash/startCase';
import isString from 'lodash/isString';
import { capitalize } from '@/utils/formatters';

export function formatMetaPromoted(
  field,
  { parentField, verboseFields = metaPromotedFieldsToVerbose } = {},
) {
  if (parentField && verboseFields[parentField]?.nested?.[field]) {
    return verboseFields[parentField].nested[field];
  }
  const verbose = verboseFields[field];
  if (verbose) {
    return isString(verbose) ? verbose : verbose.label;
  }
  if (field === 'videoViewsPaid') {
    return 'Plays - Promoted';
  }
  if (field.startsWith('promoted_scoped')) {
    const [metricName, adAccountName] = field.split('_').slice(2);
    return `Promoted ${capitalize(metricName)} - ${adAccountName}`;
  }
  if (!field.endsWith('Paid')) {
    return startCase(field.replaceAll(/([A-Z])/g, ' $1').replaceAll(/([_])/g, ' '));
  }
  return startCase(field).replace(
    ' Paid',
    field.startsWith('videoPlays') ? '% - Promoted' : ' - Promoted',
  );
}

export function getFormattedNestings(nestings, values) {
  if (nestings.length === 2) {
    const [organicField, promotedField] = nestings;
    return {
      organic: values[organicField] ?? '',
      promoted: values[promotedField] ?? '',
    };
  }
  const formattedNestings = {};
  nestings.forEach((subField) => {
    formattedNestings[subField] = values[subField] ?? '';
  });
  return formattedNestings;
}

export function getAdAccountScopedNestings(
  adAccountScopedInsights,
  metaAdsAccounts,
  field,
  defaultValues = {},
) {
  return adAccountScopedInsights.reduce((acc, insights) => {
    const adAccountName = metaAdsAccounts?.[insights.adAccountId]?.account_name || '';
    return { ...acc, [`promoted_scoped_${field}_${adAccountName}`]: insights?.[field] };
  }, defaultValues);
}

export function sortMetaPromotedFields(a, b, formatter = formatMetaPromoted) {
  if (a === 'likeshopClicks') return 1;
  if (b === 'likeshopClicks') return -1;
  const aFormatted = formatter(a);
  const bFormatted = formatter(b);
  return aFormatted.localeCompare(bFormatted, undefined, { numeric: true });
}
