import { computed } from 'vue';
import { useIdentityStore } from '@/stores/identity';
import * as socialListeningApi from '@/apis/social-listening';
import { useRoute } from 'vue-router';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';

export function useSocialListeningGDI() {
  const route = useRoute();
  const identityStore = useIdentityStore();

  const organizationId = computed(() => {
    return identityStore.currentBrand?.organizationId;
  });

  const isTopicsPage = computed(() => {
    return route.name === SOCIAL_LISTENING_ROUTE_NAMES.TOPICS;
  });

  const isTrendsPage = computed(() => {
    return route.name === SOCIAL_LISTENING_ROUTE_NAMES.TRENDS;
  });

  const isOverviewPage = computed(() => {
    return route.name === SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW;
  });

  async function getTimeSeriesPostVolume(
    payload,
    cancelToken,
    useEstimates = { useEstimates: false },
    teaserEndpoint = false,
  ) {
    const getFunction = teaserEndpoint
      ? socialListeningApi.getTeaserTimeSeriesPostVolume
      : socialListeningApi.getTimeSeriesPostVolume;

    const res = await getFunction(
      { organizationId: organizationId.value, payload, useEstimates },
      { cancelToken },
    );
    return res.data.data;
  }

  async function getTopKeywords({
    payload,
    cancelToken,
    useEstimates = { useEstimates: false },
    teaserEndpoint = false,
  }) {
    const getFunction = teaserEndpoint
      ? socialListeningApi.getTeaserTopKeywords
      : socialListeningApi.getTopKeywords;

    const res = await getFunction(
      {
        organizationId: organizationId.value,
        payload,
        useEstimates,
      },
      { cancelToken },
    );
    return res.data.data;
  }

  return {
    organizationId,
    getTimeSeriesPostVolume,
    getTopKeywords,
    isTopicsPage,
    isTrendsPage,
    isOverviewPage,
  };
}
