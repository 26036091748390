import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import * as socialListeningApi from '@/apis/social-listening';
import * as InstagramAPI from '@/apis/instagram';
import * as TwitterAPI from '@/apis/twitter';
import * as YouTubeAPI from '@/apis/youtube';
import axios from 'axios';
import { LibraryAPI } from '@/apis';
import {
  ALERT_SENSITIVITY_THRESHOLDS,
  POST_SORT_OPTIONS,
  POST_VOLUME_GRAPH_OPTIONS,
  SHARE_OF_VOICE_BREAKDOWN_BY,
  SOCIAL_LISTENING_AUDIENCE_TYPES,
  SOV_GRAPH_METRIC_OPTIONS,
  TOP_POSTS_FETCH_LIMIT,
  TOP_POSTS_INITIAL_MAX_POSTS,
  VISUAL_DATA_TYPES,
  VISUAL_FILTER_SEARCH_MAX_MEDIA,
  WEB_RESULTS_LIMIT,
  TOPIC_BUILDER_SOURCE_OPTIONS,
  POST_VOLUME_API_DATE_FORMAT,
  SOCIAL_LISTENING_DRAWERS,
} from '@/app/socialListening/constants';
import { getFormattedSentimentChartData } from '@/app/socialListening/utils/formatters';
import {
  CHANNEL_FILTER_OPTIONS,
  defaultFilterValues,
  ROLLING_DATE_RANGE_OFFSET_MAP,
  SENTIMENT_FILTER_OPTIONS,
} from '@/app/socialListening/constants/listeningFilters';
import { useFlagStore } from '@/stores/flag';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { useIdentityStore } from '@/stores/identity';
import { refreshCancelToken } from '@/apis/axios.utils';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useSocialListeningGDI } from '@/app/socialListening/composables/useSocialListeningGDI';
import dayjs from 'dayjs';
import mapValues from 'lodash/mapValues';
import {
  convertToScopedTopicFilters,
  formatVisualFilters,
  getDateRangeByOffset,
  sortVisualFilterArrays,
} from '@/app/socialListening/utils/filter-utils';
import { getElementPositionMap } from '@/app/socialListening/utils/sorting';
import humps from 'humps';
import { UPLOAD_STATUS } from '@/config';
import { logger } from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { useSocketStore } from '@/stores/socket';
import {
  getAnomalyDateRange,
  getKeywordTypePayload,
} from '@/app/socialListening/utils/api-request-utils';
import { getLineChartGraphScale } from '@/app/socialListening/utils/graph-utils';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import isUndefined from 'lodash/isUndefined';
import { useListeningPermissions } from '@/app/socialListening/composables/useListeningPermissions';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import { useDrawer } from '@dashhudson/dashing-ui';
import { useSocialListeningStore } from './social-listening';

export const useSocialListeningTopicsStore = defineStore('socialListeningTopics', () => {
  const { organizationId } = useSocialListeningGDI();

  const socketStore = useSocketStore();
  const identityStore = useIdentityStore();
  const listeningStore = useSocialListeningStore();
  const { launchDrawer } = useDrawer();

  const topicList = ref([]);
  const topicsUsage = ref(null);
  const topicMonitors = ref([]);
  const topicMonitorEvents = ref({});
  const selectedTopic = ref();

  const filters = ref({});
  const temporaryFilters = ref({});
  const createTopicPreviewPayload = ref({});
  const brandAccountIds = ref({});
  const brandAccountDetails = ref({});
  const brandCompetitors = ref({});

  const context = reactive({});
  const webResults = ref([]);
  const sentimentTrendTimeSeries = ref(null);
  const visualFilters = ref({ includes: [], doesNotInclude: [] });
  const visualFilterResults = ref([]);
  const visualFilterResultsCount = ref(null);
  const trendGroups = ref([]);
  const mentionsTrendTimeSeriesPreview = ref(null);
  const topPostsTopicPreview = ref([]);

  const sovSelectedGraphMetric = ref(SOV_GRAPH_METRIC_OPTIONS.POST_VOLUME.value);
  const timeSeriesSelectedGraphMetric = ref(SOV_GRAPH_METRIC_OPTIONS.POST_VOLUME.value);
  const postData = reactive({
    postVolume: {},
    postEngagement: {},
  });

  const postTimeSeriesData = reactive({
    postVolume: {},
    postEngagement: {},
  });

  const topLineStats = ref({
    totalPosts: null,
    uniqueCreators: null,
    totalEngagements: null,
    averageEngagementsPerPost: null,
    averageMentionsPerDay: null,
  });

  const topLineStatsPreview = ref({
    totalPosts: null,
    uniqueCreators: null,
    averageEngagementsPerPost: null,
    averageMentionsPerDay: null,
  });

  const mostRecentToast = ref(null);

  const pending = ref({
    topicMonitors: true,
    topicMonitorCreateOrUpdate: false,
    updateSavedTopic: false,
    webResultsPreview: true,
    webResults: false,
    sentimentTrendTimeSeries: true,
    postVolumeCompetitors: true,
    postVolumeTimeSeriesCompetitors: true,
    postEngagementCompetitors: false,
    postEngagementTimeSeriesCompetitors: false,
    topLineStats: true,
    topLineStatsPreview: true,
    loadingVisualData: false,
    visualDataRefinedResults: false,
    visualDataTotalResultsCount: false,
    trendGroups: false,
    mentionsTrendTimeSeriesPreview: true,
    topPostsTopicPreview: true,
    getTopicData: true,
  });

  const visualTrendGroupsErrors = ref(false);
  const tempVisualFiltersChanged = ref(false);

  const setPendingState = (key, value) => {
    if (key in pending.value) {
      if (value === undefined) {
        pending.value[key] = !pending.value[key];
      } else {
        pending.value[key] = value;
      }
    }
  };

  const selectedTopicId = computed(() => selectedTopic.value?.id);
  const searchFilters = computed(() => {
    const combined = omitBy(
      mapValues({ ...(filters.value || {}), ...(temporaryFilters.value || {}) }, (v) => {
        // Convert arrays of numeric strings to numbers
        if (Array.isArray(v)) {
          return v.map((item) =>
            typeof item === 'string' && !Number.isNaN(item) && !Number.isNaN(parseFloat(item))
              ? Number(item)
              : item,
          );
        }
        return v;
      }),
      (v) => (!isNumber(v) && !isBoolean(v) && isEmpty(v)) || isNil(v),
    );
    if (combined?.sourceCreated) {
      const offset = combined?.sourceCreated?.rollingDateRangeOffset;
      let sourceCreated = combined?.sourceCreated;
      if (offset) {
        // Get date range by offset without updating topic filters
        const { start, end } = getDateRangeByOffset(offset);
        sourceCreated = {
          onOrAfter: start,
          onOrBefore: end,
          rollingDateRangeOffset: offset,
        };
      }
      return { ...combined, sourceCreated };
    }
    return combined;
  });
  const scale = computed(() => {
    return listeningStore.calculateScale(searchFilters.value);
  });
  const postVolumePayload = computed(() => {
    return {
      scale: scale.value,
      topicId: selectedTopicId.value,
      searchFilters: searchFilters.value,
    };
  });
  const hasTopics = computed(() => topicList.value.length > 0);
  const hasExceededMentionLimit = computed(
    () => topicsUsage.value?.mentionsAdded >= topicsUsage.value?.mentionsRollingLimit,
  );
  const hasExceededTopicLimit = computed(
    () => topicsUsage.value?.topicsCreated >= topicsUsage.value?.topicsLimit,
  );
  const showWebResultsPreview = computed(() => filters.value?.websearch?.enabled);
  const resetDuration = computed(() =>
    Math.abs(dayjs(topicsUsage.value?.cycleExpiresAt).diff(dayjs(), 'day')),
  );
  const topicLimitsDescription = computed(
    () =>
      `Your plan is shared across all brands within your organization. Your monthly mention usage will reset in ${resetDuration.value} days.`,
  );

  const formattedVisualData = computed(() => {
    if (isEmpty(visualFilters.value.includes) && isEmpty(visualFilters.value.doesNotInclude)) {
      return undefined;
    }
    const callback = (item) => ({ [item.filterType]: item.id });
    const includes = visualFilters.value.includes.map(callback);
    const doesNotInclude = visualFilters.value.doesNotInclude.map(callback);
    return {
      includes,
      doesNotInclude,
    };
  });

  const shouldUseEstimates = computed(() => !searchFilters.value.sourceAndCreatorIds?.length);

  const userAccessibleBrandsWithCompetitorsAccess = computed(() => {
    const permissions = identityStore.identity?.permissions;
    const identityBrands = identityStore.identity?.brands || {};
    if (!isEmpty(identityBrands)) {
      return Object.values(identityBrands).filter(
        (brand) => permissions.competitive[brand.label]?.can_access_competitors,
      );
    }
    return [];
  });

  const selectedBrandId = computed(() => {
    let brandId = selectedTopic.value?.selectedBrandId;
    if (!brandId) {
      const brandIds = userAccessibleBrandsWithCompetitorsAccess.value.map((b) => b.id);
      const currentBrandId = identityStore?.currentBrand?.id;
      if (brandIds.includes(currentBrandId)) {
        brandId = currentBrandId;
      }
    }
    return brandId;
  });

  const hasSelectedBrandAccessToCompetitors = computed(() => {
    return userAccessibleBrandsWithCompetitorsAccess.value.some(
      (brand) => brand.id === selectedBrandId.value,
    );
  });

  function getChartConfig(targetFilters) {
    const filterData = targetFilters ?? filters.value;
    const { onOrAfter, onOrBefore } = filterData?.sourceCreated ?? {};
    if (onOrAfter && onOrBefore) {
      return {
        scales: {
          x: {
            suggestedMin: dayjs(onOrAfter, POST_VOLUME_API_DATE_FORMAT).valueOf(),
            suggestedMax: dayjs(onOrBefore, POST_VOLUME_API_DATE_FORMAT).valueOf(),
            time: {
              round: false,
              unit: scale.value ? scale.value.toLowerCase() : 'day',
            },
          },
        },
      };
    }
    return {};
  }

  function clearTempFilters() {
    temporaryFilters.value = {};
  }

  function setTempFilters(filtersObj) {
    temporaryFilters.value = { ...filtersObj };
  }

  function clearSOVPostData() {
    postData.postVolume = {};
    postData.postEngagement = {};
    postTimeSeriesData.postVolume = {};
    postTimeSeriesData.postEngagement = {};
  }

  function clearSelectedTopic() {
    selectedTopic.value = null;
    filters.value = {};
    temporaryFilters.value = {};
  }

  function clearTopicMonitorData() {
    topicMonitors.value = [];
    topicMonitorEvents.value = {};
  }

  async function getSavedTopics() {
    try {
      const res = await socialListeningApi.getTopics(organizationId.value, true);
      topicList.value = res.data.data;
    } catch {
      topicList.value = [];
    }
  }

  async function getSelectedTopicData(topicId) {
    pending.value.getTopicData = true;
    filters.value = {};
    temporaryFilters.value = {};
    clearTopicMonitorData();

    try {
      const res = await socialListeningApi.getTopic({
        organizationId: organizationId.value,
        topicId: topicId ?? selectedTopicId.value,
      });
      const { searchBody } = res.data.data;
      const searchBodyFilters = searchBody.filters;
      if (searchBodyFilters?.sourceCreated) {
        const offset = searchBodyFilters?.sourceCreated?.rollingDateRangeOffset;
        if (offset) {
          // Get date range by offset without updating topic filters
          const { start, end } = getDateRangeByOffset(offset);
          searchBodyFilters.sourceCreated = {
            onOrAfter: start,
            onOrBefore: end,
            rollingDateRangeOffset: offset,
          };
        }
      }
      filters.value = searchBodyFilters;
      selectedTopic.value = res.data.data;
      pending.value.getTopicData = false;
    } catch (error) {
      pending.value.getTopicData = false;
      selectedTopic.value = null;
      const topicExists = topicList.value.some((t) => t.id === topicId);
      if (error?.response?.status === 404 && topicExists) {
        await getSavedTopics();
      }
      throw error;
    }
  }

  async function getTopicsUsage() {
    const flagStore = useFlagStore();
    const customerJourneyStore = useCustomerJourneyStore();
    const socialListeningTier = await customerJourneyStore.getSocialListeningTier()?.value;
    const cancelToken = refreshCancelToken(context, 'topicsUsageCancelToken');
    try {
      const res = await socialListeningApi.getTopicsUsage(organizationId.value, { cancelToken });
      topicsUsage.value = res.data;
      if (flagStore.flags.inAppTrials && socialListeningTier === 'trial') {
        topicsUsage.value.topicsLimit = 5;
        topicsUsage.value.mentionsRollingLimit = 1000000;
      }
    } catch (error) {
      topicsUsage.value = null;
    }
  }

  async function getTopicMonitors() {
    setPendingState('topicMonitors', true);
    try {
      const res = await socialListeningApi.getTopicMonitors({
        organizationId: organizationId.value,
        topicId: selectedTopicId.value,
      });
      topicMonitors.value = res.data.data;
    } catch {
      topicMonitors.value = [];
    } finally {
      setPendingState('topicMonitors', false);
    }
  }

  async function getTopicMonitorEvents(monitorId) {
    const { startDate, endDate } = getAnomalyDateRange(selectedTopic.value);
    if (isEqual(startDate, endDate)) {
      topicMonitorEvents.value = {};
      return;
    }
    try {
      const res = await socialListeningApi.getTopicMonitorEvents({
        organizationId: organizationId.value,
        topicId: selectedTopicId.value,
        monitorId,
        startDate,
        endDate,
      });
      topicMonitorEvents.value = res.data.data;
    } catch {
      topicMonitorEvents.value = {};
    }
  }

  async function getTopicMonitorAndEvents() {
    await getTopicMonitors();
    if (topicMonitors.value?.length) {
      const monitorId = topicMonitors.value[0]?.id;
      await getTopicMonitorEvents(monitorId);
    }
  }

  async function createOrUpdateTopicMonitor({
    alertRecipients,
    muted,
    sensitivity,
    topicId = null,
  }) {
    setPendingState('topicMonitorCreateOrUpdate', true);
    const payload = {
      topicId: topicId ?? selectedTopicId.value,
      organizationId: organizationId.value,
      alertRecipients,
      muted,
      sensitivity: ALERT_SENSITIVITY_THRESHOLDS[sensitivity],
    };
    let previousAlert;
    try {
      this.pending.topicMonitorCreateOrUpdate = true;
      if (topicMonitors.value.length > 0) {
        const res = await socialListeningApi.updateTopicMonitor({
          ...payload,
          topicMonitorId: topicMonitors.value[0].id,
        });
        previousAlert = topicMonitors.value.splice(0, 1, res.data.data);
      } else {
        const res = await socialListeningApi.createTopicMonitor(payload);
        topicMonitors.value.splice(topicMonitors.value.length, 0, res.data.data);
      }
    } finally {
      setPendingState('topicMonitorCreateOrUpdate', false);
    }
    return { updatedAlert: topicMonitors.value[0], previousAlert: previousAlert?.[0] ?? {} };
  }

  async function deleteTopic(topicId) {
    let result;
    try {
      result = await socialListeningApi.deleteTopic({
        organizationId: organizationId.value,
        topicId,
      });
      topicList.value = topicList.value.filter((topic) => topic.id !== topicId);
      if (selectedTopicId.value === topicId) {
        clearSelectedTopic();
      }
    } catch {
      return result;
    }
    return result;
  }

  async function updateSavedTopic(payload, topicId) {
    try {
      setPendingState('updateSavedTopic', true);
      await socialListeningApi.updateTopic(
        {
          organizationId: organizationId.value,
          topicId: topicId ?? selectedTopicId.value,
        },
        payload,
      );
      setPendingState('updateSavedTopic', false);
      return true;
    } catch {
      setPendingState('updateSavedTopic', false);
      return false;
    }
  }

  async function updateTopicFilters(overrideFilters = {}, saveTopic = true, topicId = null) {
    const newFilters = omitBy({ ...filters.value, ...overrideFilters }, isEmpty);
    if (!isEqual(omitBy(filters.value, isEmpty), newFilters)) {
      const updated = saveTopic
        ? await updateSavedTopic({ searchBody: { filters: newFilters } }, topicId)
        : true;
      if (updated) {
        filters.value = newFilters;
      }
      return updated;
    }
    return false;
  }

  async function updateTopicAndFilters({ filterOverrides, name, meta, brandSelected }) {
    let updated = false;
    const newFilters = omitBy({ ...filters.value, ...filterOverrides }, isEmpty);
    if (
      !isEqual(omitBy(filters.value, isEmpty), newFilters) ||
      name !== selectedTopic?.value.name ||
      !isEqual(meta, selectedTopic?.value?.meta) ||
      brandSelected !== selectedTopic?.value?.selectedBrandId
    ) {
      const payload = omitBy(
        {
          name,
          meta,
          selectedBrandId: brandSelected,
          searchBody: { filters: newFilters },
        },
        isUndefined,
      );
      updated = await updateSavedTopic(payload);
      if (updated) {
        await updateTopicFilters(filterOverrides, false);
      }
    }
    return updated;
  }

  const drawerSerializedFilters = computed(() => {
    const { keywordsAndHashtags, sourceCreated, ...filterData } = searchFilters.value;
    const scopedFilters = convertToScopedTopicFilters(filterData);
    const drawerFilters = mapValues(
      { ...scopedFilters, sourceCreated: null, ...sourceCreated },
      (v) => {
        return Array.isArray(v) ? v?.join(',') : v;
      },
    );
    return omitBy(drawerFilters, (v) => (isEmpty(v) && !isNumber(v)) || isNil(v));
  });

  async function getSentimentTrend() {
    try {
      sentimentTrendTimeSeries.value = null;
      setPendingState('sentimentTrendTimeSeries', true);

      const result = await listeningStore.getSentimentTrendTimeSeries({
        ...postVolumePayload.value,
        useEstimates: shouldUseEstimates.value,
      });
      const topicSentiment = [];
      Object.values(SENTIMENT_FILTER_OPTIONS).forEach((v) => {
        if (searchFilters.value.sentiment && searchFilters.value.sentiment[v.field]) {
          topicSentiment.push(v.value);
        }
      });

      if (topicSentiment.length === 0) {
        topicSentiment.push(...defaultFilterValues(SENTIMENT_FILTER_OPTIONS));
      }

      sentimentTrendTimeSeries.value = getFormattedSentimentChartData(result, {
        ...filters.value,
        sentiments: topicSentiment,
      });
    } finally {
      setPendingState('sentimentTrendTimeSeries', false);
    }
  }

  async function fetchWebResults(preview = false, shouldRetry = false) {
    const pendingState = preview ? 'webResultsPreview' : 'webResults';
    setPendingState(pendingState, true);
    if (preview) {
      webResults.value = [];
    }
    try {
      const res = await socialListeningApi.getWebResults({
        organizationId: organizationId.value,
        topicId: selectedTopicId.value,
        limit: WEB_RESULTS_LIMIT,
        offset: webResults.value?.length || null,
      });

      webResults.value = webResults.value.concat(res.data.data);
    } catch (error) {
      if (axios.isCancel(error)) return;
    }

    if (!shouldRetry || webResults.value?.length) {
      setPendingState(pendingState, false);
    }
  }

  function calculateAveragePostsPerDay(sourceCreated, statsData) {
    const { onOrAfter, onOrBefore } = sourceCreated;
    // DayJS does not consider partial days - must be >= 24h, and we consider it an inclusive
    // day when we have 23:59:59. So add 1 day to the calculation to get the correct number
    // of days for the scale
    const days = dayjs(onOrBefore).diff(dayjs(onOrAfter), 'day') + 1;
    statsData.averageMentionsPerDay = Math.round(statsData.totalPosts / days);
    return statsData;
  }

  async function fetchTopLineStats({
    refresh = true,
    overrideFilters = {},
    teaserEndpoint = false,
    statsScale = null,
  } = {}) {
    setPendingState('topLineStats', true);

    if (refresh) {
      topLineStats.value = {
        totalPosts: null,
        uniqueCreators: null,
        totalEngagements: null,
        averageEngagementsPerPost: null,
        averagePostsPerDay: null,
      };
    }

    const cancelToken = refreshCancelToken(context, 'statsCancelToken');
    try {
      const promises = [];

      let getFunction;
      if (teaserEndpoint) {
        getFunction = socialListeningApi.getTeaserStats;
      } else {
        getFunction = socialListeningApi.getTopicStats;
      }

      const defaultScaleParam = { scale: scale.value };
      const topLineStatsFilters = {
        organizationId: organizationId.value,
        topicId: selectedTopicId.value,
        searchFilters: searchFilters.value,
        useEstimates: shouldUseEstimates.value,
        ...overrideFilters,
        ...(statsScale ? { statsScale } : defaultScaleParam),
      };

      const topLineStatsCall = await getFunction(topLineStatsFilters, { cancelToken });
      promises.push(topLineStatsCall);

      const results = await Promise.allSettled(promises);
      let statsData = results[0].value.data.data;

      const sourceCreated = topLineStatsFilters.searchFilters.sourceCreated;
      if (statsData.totalPosts && sourceCreated.onOrAfter && sourceCreated.onOrBefore) {
        statsData = calculateAveragePostsPerDay(sourceCreated, statsData);
      }
      topLineStats.value = statsData;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      Object.keys(topLineStats.value).forEach((statKey) => {
        topLineStats.value[statKey] = '-';
      });
    } finally {
      setPendingState('topLineStats', false);
    }
  }

  async function getBrandPlatformAccounts() {
    if (!selectedBrandId.value || brandAccountIds.value[selectedBrandId.value]) {
      return;
    }

    brandAccountIds.value[selectedBrandId.value] = [];
    brandAccountDetails.value = {};

    const platformStore = usePlatformStore();
    const platforms = [
      PLATFORM_CONNECTION.FACEBOOK,
      PLATFORM_CONNECTION.TWITTER,
      PLATFORM_CONNECTION.YOUTUBE,
    ];

    const accountPromises = platforms.map(async (platform) => {
      let account = platformStore.accounts?.[platform.value]?.[selectedBrandId.value] || null;

      if (!account) {
        switch (platform.value) {
          case PLATFORM_CONNECTION.FACEBOOK.value:
            account = await platformStore.getInstagramAccount(selectedBrandId.value);
            break;
          case PLATFORM_CONNECTION.TWITTER.value:
            account = await platformStore.getTwitterAccount(selectedBrandId.value);
            break;
          case PLATFORM_CONNECTION.YOUTUBE.value:
            account = await platformStore.getYouTubeChannel(selectedBrandId.value);
            break;
          default:
            break;
        }
      }

      if (account) {
        let sourceAndId;
        let displayName;
        let avatarObjKey;
        const avatarObj = {};
        if (platform.value === PLATFORM_CONNECTION.FACEBOOK.value && account.instagramId) {
          sourceAndId = `INSTAGRAM:${account.instagramId}`;
          displayName = account.handle;
          avatarObjKey = `INSTAGRAM:${displayName}`;
          avatarObj.avatar = account.avatarUrl;
          avatarObj.channel = 'instagram';
        } else if (
          platform.value === PLATFORM_CONNECTION.TWITTER.value &&
          account.twitter_user_id
        ) {
          sourceAndId = `TWITTER:${account.twitter_user_id}`;
          displayName = account.handle;
          avatarObjKey = `TWITTER:${displayName}`;
          avatarObj.avatar = account.twitter_avatar;
          avatarObj.channel = 'twitter';
        } else if (
          platform.value === PLATFORM_CONNECTION.YOUTUBE.value &&
          account.sourceChannelId
        ) {
          sourceAndId = `YOUTUBE:${account.sourceChannelId}`;
          displayName = account.title;
          avatarObjKey = `YOUTUBE:${displayName}`;
          avatarObj.avatar = account.thumbnailUrl;
          avatarObj.channel = 'youtube';
        }

        if (sourceAndId) {
          brandAccountIds.value[selectedBrandId.value].push(sourceAndId);
          brandAccountDetails.value[avatarObjKey] = {
            displayName,
            ...avatarObj,
          };
        }
      }
    });

    await Promise.all(accountPromises);
  }

  async function getPublicAccountsByIds(channel, ids) {
    let response;
    switch (channel) {
      case CHANNEL_FILTER_OPTIONS.INSTAGRAM.value:
        response = await InstagramAPI.getPublicAccountsByIds({ instagramIds: ids });
        return response.data.map((r) => ({
          value: `${channel}:${r.instagramId}`,
          label: r.handle,
          avatar: r.avatar,
          icon: CHANNEL_FILTER_OPTIONS.INSTAGRAM.icon,
        }));
      case CHANNEL_FILTER_OPTIONS.TWITTER.value:
        response = await TwitterAPI.getPublicAccountsByIds({ twitterUserIds: ids });
        return response.data.data.map((r) => ({
          value: `${channel}:${r.twitterUserId}`,
          label: r.handle,
          avatar: r.twitterAvatar,
          icon: CHANNEL_FILTER_OPTIONS.TWITTER.icon,
        }));
      case CHANNEL_FILTER_OPTIONS.YOUTUBE.value:
        response = await YouTubeAPI.getPublicAccountsByIds({ sourceChannelIds: ids });
        return response.data.map((r) => ({
          value: `${channel}:${r.sourceChannelId}`,
          label: r.title,
          avatar: r.thumbnailUrl,
          icon: CHANNEL_FILTER_OPTIONS.YOUTUBE.icon,
        }));
      default:
        return [];
    }
  }

  async function addPublicCompetitors(savedCompetitors) {
    const availableIds = savedCompetitors.map((c) => c.value);
    const unavailableIds = {
      [CHANNEL_FILTER_OPTIONS.INSTAGRAM.value]: [],
      [CHANNEL_FILTER_OPTIONS.TWITTER.value]: [],
      [CHANNEL_FILTER_OPTIONS.YOUTUBE.value]: [],
    };
    // check if library competitor list includes all saved competitor ids
    difference(filters.value.sourceAndCreatorIds, availableIds).forEach((sourceAndCreatorId) => {
      const [channel, accountId] = sourceAndCreatorId.split(':');
      unavailableIds[channel].push(accountId);
    });
    // call api to get public account of unavailable ids
    await Promise.all(
      Object.entries(unavailableIds)
        .filter(([, ids]) => !isEmpty(ids))
        .map(async ([channel, ids]) => {
          const accounts = await getPublicAccountsByIds(channel, ids);
          savedCompetitors.push(...accounts);
        }),
    );
    return savedCompetitors;
  }

  async function fetchCompetitorsForBrands(brandIds) {
    const response = await LibraryAPI.apiGetCompetitors({
      payload: {
        brandIds,
        sourceList: defaultFilterValues(CHANNEL_FILTER_OPTIONS),
      },
    });
    const filteredCompetitors = response.data.filter(
      (c) => c.handle && c.avatarUrl && c.sourceAccountId,
    );
    return filteredCompetitors;
  }

  async function fetchBrandCompetitors() {
    // fetch competitors of selected brand that have competitor access
    if (selectedBrandId.value && brandCompetitors.value[selectedBrandId.value]?.length) {
      return;
    }
    brandCompetitors.value[selectedBrandId.value] = [];
    let fetchedCompetitors = [];
    try {
      if (selectedBrandId.value) {
        const validCompetitors = await fetchCompetitorsForBrands([selectedBrandId.value]);
        if (validCompetitors) {
          fetchedCompetitors = validCompetitors.map((c) => ({
            value: `${c.source}:${c.sourceAccountId}`,
            label: c.handle,
            avatar: c.avatarUrl,
            icon: CHANNEL_FILTER_OPTIONS[c.source.toUpperCase()].icon,
            channel: c.source.toUpperCase(),
          }));
        }
      }
      // limit large number of competitors for testing brands
      const savedCompetitorsIds = filters.value?.sourceAndCreatorIds || [];
      if ([1, 144, 4688, 1999].includes(selectedBrandId.value)) {
        const savedCompetitors = fetchedCompetitors.filter((c) =>
          savedCompetitorsIds.includes(c.value),
        );
        fetchedCompetitors = uniqBy(
          [...fetchedCompetitors.slice(0, 100), ...savedCompetitors],
          'value',
        );
      }
      // fetch public competitors if brand competitors does not have saved competitors
      const brandCompetitorsIds = (fetchedCompetitors || []).map((c) => c.value);
      const missingIds = savedCompetitorsIds.filter((id) => !brandCompetitorsIds.includes(id));
      if (missingIds.length) {
        await addPublicCompetitors(fetchedCompetitors);
      }
      brandCompetitors.value[selectedBrandId.value] = fetchedCompetitors;
    } catch {
      brandCompetitors.value[selectedBrandId.value] = [];
    }
  }

  function addDetailsToPVCompetitors(competitors, sourceAndCreatorIds) {
    const filteredCompetitorAccountDetails = competitors.filter((competitor) =>
      (sourceAndCreatorIds ?? []).includes(competitor.value),
    );

    const processedCompetitorAccounts = {};
    filteredCompetitorAccountDetails.forEach((competitor) => {
      const source = competitor.value.split(':')[0];
      const competitorKey = `${source}:${competitor.label}`;
      processedCompetitorAccounts[competitorKey] = {
        avatar: competitor.avatar,
        channel: source.toLowerCase(),
        displayName: competitor.label,
      };
    });

    return processedCompetitorAccounts;
  }

  function setMappedSovGraphMetric(value) {
    sovSelectedGraphMetric.value = SOV_GRAPH_METRIC_OPTIONS[value].value;
  }

  function setMappedTimeSeriesGraphMetric(value) {
    timeSeriesSelectedGraphMetric.value = SOV_GRAPH_METRIC_OPTIONS[value].value;
  }

  async function fetchCompetitorsData() {
    const { aggregationMetric, property } = SOV_GRAPH_METRIC_OPTIONS[sovSelectedGraphMetric.value];
    setPendingState(`${property}Competitors`, hasSelectedBrandAccessToCompetitors.value);
    postData[property] = {};
    if (!hasSelectedBrandAccessToCompetitors.value) {
      return;
    }

    const sovSearchFilters = cloneDeep(searchFilters.value);
    if (!sovSearchFilters?.sourceAndCreatorIds?.length) {
      const sourceAndCreatorIds = brandCompetitors.value[selectedBrandId.value].map((c) => c.value);
      sovSearchFilters.sourceAndCreatorIds = sourceAndCreatorIds;
    }
    const payload = {
      breakdownBy: SHARE_OF_VOICE_BREAKDOWN_BY[SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR],
      topicId: selectedTopicId.value,
      searchFilters: sovSearchFilters,
      ...(aggregationMetric ? { aggregationMetric } : {}),
    };
    const cancelToken = refreshCancelToken(context, `${property}CancelToken`);

    try {
      const res = await socialListeningApi.getPostVolume(
        { organizationId: organizationId.value, payload },
        { cancelToken },
      );
      const competitorAccounts = addDetailsToPVCompetitors(
        brandCompetitors.value[selectedBrandId.value],
        payload.searchFilters.sourceAndCreatorIds,
      );
      res.data.data.breakdown.data.forEach((item) => {
        const formattedAccount = competitorAccounts[item.group];
        if (formattedAccount) item.groupDetails = competitorAccounts[item.group];
        else {
          const [channel, displayName] = item.group.split(':');
          item.groupDetails = {
            channel,
            displayName,
          };
        }
      });
      postData[property] = res.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      postData[property] = {};
    } finally {
      setPendingState(`${property}Competitors`, false);
    }
  }

  async function fetchCompetitorsTimeSeries() {
    const { aggregationMetric, property } =
      SOV_GRAPH_METRIC_OPTIONS[timeSeriesSelectedGraphMetric.value];
    setPendingState(`${property}TimeSeriesCompetitors`, hasSelectedBrandAccessToCompetitors.value);
    postTimeSeriesData[property] = {};
    if (!hasSelectedBrandAccessToCompetitors.value) {
      return;
    }

    const sovSearchFilters = cloneDeep(searchFilters.value);
    if (!sovSearchFilters?.sourceAndCreatorIds?.length) {
      const sourceAndCreatorIds = brandCompetitors.value[selectedBrandId.value].map((c) => c.value);
      sovSearchFilters.sourceAndCreatorIds = sourceAndCreatorIds;
    }

    const payload = {
      breakdownBy: SHARE_OF_VOICE_BREAKDOWN_BY[SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR],
      ...postVolumePayload.value,
      searchFilters: sovSearchFilters,
      ...(aggregationMetric ? { aggregationMetric } : {}),
    };

    const cancelToken = refreshCancelToken(context, `${property}TSCancelToken`);

    try {
      const res = await socialListeningApi.getTimeSeriesPostVolume(
        { organizationId: organizationId.value, payload },
        { cancelToken },
      );

      const competitorAccounts = addDetailsToPVCompetitors(
        brandCompetitors.value[selectedBrandId.value],
        payload.searchFilters.sourceAndCreatorIds,
      );

      const processed = {};
      Object.entries(res.data.data).forEach(([date, entry]) => {
        entry.breakdown.data.forEach((dataPoint) => {
          dataPoint.groupDetails = competitorAccounts[dataPoint.group];
        });
        processed[date] = entry;
      });
      postTimeSeriesData[property] = processed;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      postTimeSeriesData[property] = {};
    } finally {
      setPendingState(`${property}TimeSeriesCompetitors`, false);
    }
  }

  async function getMentionsBreakdown(graphOption = POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL) {
    const payload = {
      ...postVolumePayload.value,
      breakdownBy: graphOption.primaryBreakdown,
    };
    await listeningStore.getTimeSeriesPostVolume(payload, graphOption.value, {
      useEstimates: shouldUseEstimates.value,
    });
  }

  async function fetchVisualTrends() {
    const requestId = uuidv4();
    const socketId = socketStore.id;
    if (socketId) {
      await listeningStore.getVisualTrends({
        requestId,
        socketId,
        searchBody: { filters: { ...searchFilters.value } },
        topicId: selectedTopicId.value,
      });
    }
  }

  async function getVisualTrendById(trendId) {
    const res = await socialListeningApi.getTrendGroup({
      organizationId: organizationId.value,
      trendId,
    });
    return res.data.data;
  }

  async function getTrendGroups(trendIds) {
    if (isEmpty(trendIds)) {
      return;
    }

    setPendingState('trendGroups', true);
    visualTrendGroupsErrors.value = false;

    const groups = [];
    const errors = [];

    const results = await Promise.allSettled(trendIds.map((id) => getVisualTrendById(id)));

    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        groups.push(result.value);
      } else if (result.status === 'rejected') {
        errors.push(true);
      }
    });

    trendGroups.value = groups;
    setPendingState('trendGroups', false);
    visualTrendGroupsErrors.value = errors.length === trendIds.length;
  }

  async function getTopPostsPreview() {
    listeningStore.pending.topPostsPreview = true;
    listeningStore.clearTopPostsPreview();
    await listeningStore.getTopPostsPreview({
      filters: searchFilters.value,
      sorts: [`-${POST_SORT_OPTIONS.engagements.value}`, `-${POST_SORT_OPTIONS.date.value}`],
      paging: {
        limit: TOP_POSTS_INITIAL_MAX_POSTS,
        offset: 0,
      },
      topicId: selectedTopicId.value,
    });
  }

  function setComponentsPendingState() {
    listeningStore.pending = {
      ...listeningStore.pending,
      totalPostsTimeseries: true,
      postsByChannelTimeseries: true,
      postsByMediaTypeTimeseries: true,
      topPostsPreview: true,
    };
    pending.value = {
      ...pending.value,
      topicMonitors: true,
      webResultsPreview: true,
      sentimentTrendTimeSeries: true,
      postVolumeCompetitors: true,
      postVolumeTimeSeriesCompetitors: true,
      topLineStats: true,
    };
  }

  const fetchComponentData = async () => {
    if (!isEmpty(searchFilters.value)) {
      setComponentsPendingState();
      listeningStore.getOrganizationUsers();
      listeningStore.clearVisualTrends();
      listeningStore.clearTopPostsData();
      clearSOVPostData();

      // Fetch brand competitors before fetching SOV data
      if (hasSelectedBrandAccessToCompetitors.value) {
        await fetchBrandCompetitors();
      }

      const topKeywordsPayload = {
        searchFilters: searchFilters.value,
        topicId: selectedTopicId.value,
        ...getKeywordTypePayload(searchFilters.value.keywordTypes),
      };

      const promises = [];
      promises.push(
        getTopicsUsage(),
        getTopicMonitorAndEvents(),
        // Topic Stats
        fetchTopLineStats(),
        // Mentions Trend
        getMentionsBreakdown(POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS),
        // Top Performing Posts
        getTopPostsPreview(),
        // Sentiment Analysis
        getSentimentTrend(),
        // Top Keywords
        listeningStore.getTopKeywords(topKeywordsPayload),
        listeningStore.getParseTree(filters.value.keywordsAndHashtags),
        // Mentions Breakdown - Post By Channel
        getMentionsBreakdown(POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL),
        // Mentions Breakdown - Post By Media Type
        getMentionsBreakdown(POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_MEDIA_TYPE),
        // Visual Trends
        fetchVisualTrends(),
        // Share Of Voice
        fetchCompetitorsData(),
        fetchCompetitorsTimeSeries(),
      );

      if (showWebResultsPreview.value) {
        // Top Web Results
        promises.push(fetchWebResults(true, true));
      }

      await Promise.allSettled(promises);

      // retry loading web results if the previous request return no results
      if (
        showWebResultsPreview.value &&
        pending.value.webResultsPreview &&
        !webResults.value.length
      ) {
        setTimeout(() => {
          fetchWebResults(true);
        }, 3000);
      } else {
        setPendingState('webResultsPreview', false);
      }
    }
  };

  const canSaveVisualFilters = computed(() => {
    const areDifferent = !isEqual(
      sortVisualFilterArrays(formattedVisualData.value ?? {}),
      sortVisualFilterArrays(filters.value?.visualData ?? {}),
    );
    return (
      areDifferent &&
      !pending.value.loadingVisualData &&
      !pending.value.visualDataRefinedResults &&
      !pending.value.updateSavedTopic
    );
  });

  async function fetchRefinedResultsMedia(offset = 0) {
    if (!formattedVisualData.value) {
      visualFilterResults.value = [];
      return;
    }
    pending.value.visualDataRefinedResults = true;
    try {
      if (!offset) {
        // clear so that the DataGrid shows skeletons for the entire grid
        visualFilterResults.value = [];
      }
      const _filters = searchFilters.value;
      formatVisualFilters(_filters);
      const payload = {
        organizationId: organizationId.value,
        payload: {
          topicId: selectedTopicId.value,
          filters: _filters,
          paging: { limit: 100, offset: offset ?? 0 },
        },
      };
      const res = await socialListeningApi.getSearchResults(payload);
      const { data = [] } = res.data;
      if (offset) {
        visualFilterResults.value = visualFilterResults.value.concat(data);
      } else {
        visualFilterResults.value = data;
      }
    } finally {
      pending.value.visualDataRefinedResults = false;
    }
  }

  async function fetchRefinedResultsStats(clearOnEmptyVisualData = false) {
    if (clearOnEmptyVisualData) {
      if (!formattedVisualData.value) {
        visualFilterResultsCount.value = 0;
        return;
      }
    }
    setPendingState('visualDataTotalResultsCount');
    try {
      const _filters = searchFilters.value;
      formatVisualFilters(_filters);
      const res = await socialListeningApi.getTopicStats({
        organizationId: organizationId.value,
        topicId: selectedTopicId.value,
        searchFilters: _filters,
      });
      const { data = null } = res.data;
      visualFilterResultsCount.value = data?.totalPosts;
    } finally {
      setPendingState('visualDataTotalResultsCount');
    }
  }

  function updateVisualFilters(
    include,
    mediaItem,
    index = undefined,
    deleteCount = 0,
    tempUsage = true,
  ) {
    const [key, verb] = include ? ['includes', 'include'] : ['doesNotInclude', 'exclude'];
    const idx = index ?? visualFilters.value[key].length;
    const totalVisualFilters = Object.values(visualFilters.value).reduce(
      (prev, next) => prev + next.length,
      0,
    );
    const isDuplicate =
      deleteCount === 0 && visualFilters.value[key].some((item) => item.id === mediaItem?.id);
    if (isDuplicate) {
      mostRecentToast.value = {
        severity: 'error',
        message: `You have already ${verb}d this media`,
      };
    } else if (
      mediaItem &&
      totalVisualFilters >= VISUAL_FILTER_SEARCH_MAX_MEDIA &&
      deleteCount === 0
    ) {
      mostRecentToast.value = {
        severity: 'error',
        message: `You may only ${verb} up to ${VISUAL_FILTER_SEARCH_MAX_MEDIA} media`,
      };
    } else if (mediaItem && include) {
      visualFilters.value.includes.splice(idx, deleteCount, mediaItem);
      if (tempUsage) {
        setTempFilters({ visualData: formattedVisualData.value });
      }
    } else if (mediaItem && !include) {
      visualFilters.value.doesNotInclude.splice(idx, deleteCount, mediaItem);
      // remove excluded media from includes if it was included before
      visualFilters.value.includes = visualFilters.value.includes.filter(
        (item) => item.id !== mediaItem.id,
      );
      if (tempUsage) {
        setTempFilters({ visualData: formattedVisualData.value });
      }
    }
  }

  function removeVisualFilter(index, include) {
    const key = include ? 'includes' : 'doesNotInclude';
    visualFilters.value[key].splice(index, 1);
    tempVisualFiltersChanged.value = true;
    setTempFilters({ visualData: formattedVisualData.value });
  }

  function clearVisualFilters() {
    visualFilters.value.includes = [];
    visualFilters.value.doesNotInclude = [];
    visualFilterResults.value = [];
    visualFilterResultsCount.value = 0;
  }

  async function fetchSavedMedia(isIncludes, data, sourceIds) {
    const brandSourceToSourceIdMap = data.reduce((acc, { libraryMediaDetails }) => {
      if (libraryMediaDetails) {
        const [brandId, source, sourceId] = libraryMediaDetails.split(':');
        return {
          ...acc,
          [brandId]: {
            ...(acc[brandId] ?? {}),
            [source]: [...(acc?.[brandId]?.[source] ?? []), sourceId],
          },
        };
      }
      return acc;
    }, {});
    Object.keys(brandSourceToSourceIdMap).map(async (brandId) => {
      const brandIdMap = brandSourceToSourceIdMap[brandId];
      const brandMediaListPromises = [];
      // build promise list of lib requests for each source under this brand
      Object.keys(brandIdMap).forEach((source) => {
        brandMediaListPromises.push({
          source,
          promise: LibraryAPI.getBrandMediaListBySource({
            brandId,
            source,
            sourceIds: brandIdMap[source],
          }),
        });
      });
      // collect and iterate promise results
      const results = await Promise.allSettled(brandMediaListPromises.map((p) => p.promise));
      results.forEach((promise, index) => {
        const { source } = brandMediaListPromises[index];
        if (promise.status === 'fulfilled') {
          const promiseValue = promise.value?.data ?? [];
          promiseValue.forEach((mediaItem) => {
            const media = humps.camelizeKeys(mediaItem);
            const item = {
              id: `${brandId ?? ''}:${source}:${media.sourceId}`,
              filterType: VISUAL_DATA_TYPES.LIBRARY,
              sizes: media.imageSizes,
              mediaType: media.type,
            };
            updateVisualFilters(isIncludes, { ...media, ...item });
          });
        }
        if (promise.status === 'rejected') {
          logger.error(promise?.reason);
          const key = isIncludes ? 'includes' : 'doesNotInclude';
          // remove all visual filter items from the store that failed to fetch
          visualFilters.value[key] = visualFilters.value[key].filter((filter) =>
            brandIdMap[source].includes(filter.id.split(':').slice(-1)),
          );
        }
      });
    });
    data.forEach((mediaItem) => {
      if (mediaItem.data) {
        // Uploaded media
        updateVisualFilters(isIncludes, {
          id: mediaItem.data,
          previewUrl: mediaItem.data,
          uploadStatus: UPLOAD_STATUS.SUCCESS,
          filterType: VISUAL_DATA_TYPES.UPLOAD,
          mediaType: 'IMAGE',
        });
      } else if (mediaItem.sourceAndId) {
        // Trends posts
        sourceIds.push(mediaItem.sourceAndId);
        updateVisualFilters(isIncludes, {
          id: mediaItem.sourceAndId,
          filterType: VISUAL_DATA_TYPES.TRENDS,
        });
      }
    });
  }

  async function loadSavedVisualFilters() {
    setPendingState('loadingVisualData', true);
    visualFilters.value = { includes: [], doesNotInclude: [] };
    const visualData = searchFilters.value?.visualData;
    if (isEmpty(visualData)) {
      setPendingState('loadingVisualData', false);
      return;
    }
    // keep track of the original saved order of visual filter items
    // we use this sort order later since library media are fetched and loaded separately
    const includesDataPositionMap = getElementPositionMap(visualData.includes);
    const excludesDataPositionMap = getElementPositionMap(visualData.doesNotInclude);
    try {
      const sourceIds = [];
      const fetchSavedMediaPromises = [];
      if (visualData.includes?.length) {
        fetchSavedMediaPromises.push(fetchSavedMedia(true, visualData.includes, sourceIds));
      }
      if (visualData.doesNotInclude?.length) {
        fetchSavedMediaPromises.push(fetchSavedMedia(false, visualData.doesNotInclude, sourceIds));
      }
      await Promise.allSettled(fetchSavedMediaPromises);
      if (isEmpty(sourceIds)) {
        setPendingState('loadingVisualData', false);
        return;
      }
      const _filters = { sourceAndIds: sourceIds };

      try {
        const res = await socialListeningApi.getSearchResults({
          organizationId: organizationId.value,
          payload: {
            filters: _filters,
          },
        });
        if (!res.data) {
          return;
        }
        // adds mediaType and sizes to all media returned from /search
        const mediaList = humps.camelizeKeys(res.data.data);
        mediaList.forEach((media) => {
          const id = `${media.source}:${media.sourceId}`;
          const cb = (item) => item.id === id;
          const includesIndex = visualFilters.value.includes.findIndex(cb);
          const excludesIndex = visualFilters.value.doesNotInclude.findIndex(cb);
          if (includesIndex >= 0) {
            updateVisualFilters(
              true,
              {
                ...visualFilters.value.includes[includesIndex],
                mediaType: media.mediaType,
                sizes: media.urls.imageSizes || media.urls.videoSizes,
              },
              includesIndex,
              1,
            );
          }
          if (excludesIndex >= 0) {
            updateVisualFilters(
              false,
              {
                ...visualFilters.value.doesNotInclude[excludesIndex],
                mediaType: media.mediaType,
                sizes: media.urls.imageSizes || media.urls.videoSizes,
              },
              excludesIndex,
              1,
            );
          }
        });
      } catch (error) {
        sourceIds.forEach((id) => {
          const cb = (item) => item.id === id;
          const includesIndex = visualFilters.value.includes.findIndex(cb);
          const excludesIndex = visualFilters.value.doesNotInclude.findIndex(cb);
          if (includesIndex >= 0) {
            updateVisualFilters(
              true,
              {
                ...visualFilters.value.includes[includesIndex],
                error: true,
              },
              includesIndex,
              1,
            );
          }
          if (excludesIndex >= 0) {
            updateVisualFilters(
              false,
              { ...visualFilters.value.doesNotInclude[excludesIndex], error: true },
              excludesIndex,
              1,
            );
          }
        });
      }
    } finally {
      // apply original sort order
      visualFilters.value.includes.sort(function sort(left, right) {
        return includesDataPositionMap[left.id] - includesDataPositionMap[right.id];
      });
      visualFilters.value.doesNotInclude.sort(function sort(left, right) {
        return excludesDataPositionMap[left.id] - excludesDataPositionMap[right.id];
      });
      setPendingState('loadingVisualData', false);
    }
  }

  async function updateTopicVisualData() {
    const updated = await updateTopicFilters({ visualData: formattedVisualData.value });
    return updated;
  }

  async function launchCreateTopicDrawer(props) {
    const drawerName =
      hasExceededTopicLimit.value || hasExceededMentionLimit.value
        ? SOCIAL_LISTENING_DRAWERS.MANAGE_TOPIC_LIMITS
        : SOCIAL_LISTENING_DRAWERS.TOPIC_CREATOR;
    await launchDrawer({ name: drawerName, ...(props ? { props } : {}) });
  }

  async function createTopic({
    name,
    keywordBooleanExpression,
    websearch,
    brandSelected = null,
    suggestedTopicId = null,
  }) {
    const sourceCreated = { rollingDateRangeOffset: ROLLING_DATE_RANGE_OFFSET_MAP.LAST_4_WEEKS };
    const payload = {
      organizationId: organizationId.value,
      name,
      audienceType: TOPIC_BUILDER_SOURCE_OPTIONS.PUBLIC.value,
      searchBody: {
        filters: {
          keywordsAndHashtags: keywordBooleanExpression,
          sourceCreated,
          websearch,
        },
      },
      selectedBrandId: brandSelected,
      meta: { meta: { keywordsAndHashtagsEditorText: keywordBooleanExpression } },
    };
    if (suggestedTopicId) {
      payload.suggestionId = suggestedTopicId;
    }
    const res = await socialListeningApi.createTopic(payload);
    return res.data.data;
  }

  async function fetchTopLineStatsPreview() {
    setPendingState('topLineStatsPreview', true);
    const { canAccessYoutubeCompliance } = useListeningPermissions();

    const cancelToken = refreshCancelToken(context, 'statsPreviewCancelToken');
    try {
      const topLineStatsPreviewPayload = {
        organizationId: organizationId.value,
        searchFilters: createTopicPreviewPayload.value,
        useEstimates: true,
      };

      const { onOrAfter, onOrBefore } = topLineStatsPreviewPayload.searchFilters.sourceCreated;
      const countsPayload = {
        topicQuery: topLineStatsPreviewPayload.searchFilters.keywordsAndHashtags,
        bucket: 'day',
        fromDate: onOrAfter,
        toDate: onOrBefore,
      };
      const promises = [
        socialListeningApi.getInstagramStats(topLineStatsPreviewPayload, {
          cancelToken,
        }),
        TwitterAPI.getTwitterSearchCounts(countsPayload),
      ];

      if (canAccessYoutubeCompliance.value) {
        promises.push(YouTubeAPI.getYouTubeSearchCounts(countsPayload));
      }

      const results = await Promise.allSettled(promises);
      let statsData = results[0].value.data.data;
      if (results.length > 1 && results[1].value) {
        statsData.totalPosts += results[1].value.data.total_count;
      }
      if (results.length > 2 && results[2].value) {
        statsData.totalPosts += results[2].value.data.data.total_count;
      }

      if (statsData.totalPosts && onOrAfter && onOrBefore) {
        statsData = calculateAveragePostsPerDay({ onOrAfter, onOrBefore }, statsData);
      }
      topLineStatsPreview.value = statsData;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      Object.keys(topLineStatsPreview.value).forEach((statKey) => {
        topLineStatsPreview.value[statKey] = '-';
      });
    } finally {
      setPendingState('topLineStatsPreview', false);
    }
  }

  async function fetchMentionsTimeSeriesPreview() {
    setPendingState('mentionsTrendTimeSeriesPreview', true);
    const cancelToken = refreshCancelToken(context, 'mentionsTrendPreviewCancelToken');

    try {
      const graphScaleData = getLineChartGraphScale(
        createTopicPreviewPayload.value.sourceCreated.onOrAfter,
        createTopicPreviewPayload.value.sourceCreated.onOrBefore,
      );
      const mentionsTimeSeriesPreviewPayload = {
        breakdownBy: POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS.primaryBreakdown,
        scale: graphScaleData.scale,
        searchFilters: createTopicPreviewPayload.value,
      };

      const res = await socialListeningApi.getTimeSeriesPostVolume(
        {
          organizationId: organizationId.value,
          payload: mentionsTimeSeriesPreviewPayload,
          useEstimates: { useEstimates: false },
        },
        { cancelToken },
      );

      mentionsTrendTimeSeriesPreview.value = res.data.data;
    } catch {
      mentionsTrendTimeSeriesPreview.value = null;
    } finally {
      setPendingState('mentionsTrendTimeSeriesPreview', false);
    }
  }

  async function fetchTopPostsTopicPreview(payload = {}, offsetValue = 0, sortChanged = false) {
    setPendingState('topPostsTopicPreview', true);
    const cancelToken = refreshCancelToken(context, 'topPostsTopicPreviewCancelToken');
    let initialFetchLimit = TOP_POSTS_INITIAL_MAX_POSTS;
    if (sortChanged) {
      initialFetchLimit = TOP_POSTS_FETCH_LIMIT;
    }
    try {
      const topPostsPreviewPayload = {
        filters: createTopicPreviewPayload.value,
        sorts: [`-${POST_SORT_OPTIONS.engagements.value}`, `-${POST_SORT_OPTIONS.date.value}`],
        paging: {
          limit: offsetValue === 0 ? initialFetchLimit : TOP_POSTS_FETCH_LIMIT,
          offset: offsetValue,
        },
        ...payload,
      };
      const res = await socialListeningApi.getSearchResults({
        organizationId: organizationId.value,
        payload: topPostsPreviewPayload,
        cancelToken,
      });
      if (topPostsPreviewPayload.paging.offset) {
        topPostsTopicPreview.value = topPostsTopicPreview.value.concat(res.data.data);
      } else {
        topPostsTopicPreview.value = res.data.data;
      }
    } catch (error) {
      if (axios.isCancel(error)) return;
      topPostsTopicPreview.value = [];
    } finally {
      setPendingState('topPostsTopicPreview', false);
    }
  }

  function clearTopicPreviewData() {
    topLineStatsPreview.value = null;
    mentionsTrendTimeSeriesPreview.value = null;
    topPostsTopicPreview.value = null;
  }

  function setTopicCreatorPreviewPendingState() {
    pending.value = {
      ...pending.value,
      topLineStatsPreview: true,
      mentionsTrendTimeSeriesPreview: true,
      topPostsTopicPreview: true,
    };
  }

  async function fetchPreviewData() {
    setTopicCreatorPreviewPendingState();
    clearTopicPreviewData();
    const promises = [];
    promises.push(
      // Topic Stats Preview
      fetchTopLineStatsPreview(),
      // Mentions Trend Preview
      fetchMentionsTimeSeriesPreview(),
      // Top Performing Posts Preview
      fetchTopPostsTopicPreview(),
    );

    await Promise.allSettled(promises);
  }

  return {
    selectedTopicId,
    topicList,
    topicsUsage,
    topicMonitors,
    topicMonitorEvents,
    drawerSerializedFilters,
    filters,
    selectedBrandId,
    brandAccountIds,
    brandAccountDetails,
    brandCompetitors,
    scale,
    temporaryFilters,
    visualFilters,
    visualFilterResults,
    visualFilterResultsCount,
    getChartConfig,
    clearTempFilters,
    setTempFilters,
    topLineStats,
    fetchTopLineStats,
    fetchComponentData,
    searchFilters,
    selectedTopic,
    sovSelectedGraphMetric,
    timeSeriesSelectedGraphMetric,
    postData,
    postTimeSeriesData,
    sentimentTrendTimeSeries,
    getSentimentTrend,
    webResults,
    pending,
    hasTopics,
    hasExceededTopicLimit,
    hasExceededMentionLimit,
    showWebResultsPreview,
    topicLimitsDescription,
    mostRecentToast,
    getSavedTopics,
    getSelectedTopicData,
    clearSelectedTopic,
    getTopicMonitors,
    createOrUpdateTopicMonitor,
    getTopicsUsage,
    deleteTopic,
    updateSavedTopic,
    updateTopicFilters,
    fetchWebResults,
    getBrandPlatformAccounts,
    fetchBrandCompetitors,
    setMappedSovGraphMetric,
    setMappedTimeSeriesGraphMetric,
    fetchCompetitorsData,
    fetchCompetitorsTimeSeries,
    fetchRefinedResultsMedia,
    fetchRefinedResultsStats,
    getTopicMonitorEvents,
    updateVisualFilters,
    removeVisualFilter,
    clearVisualFilters,
    loadSavedVisualFilters,
    updateTopicAndFilters,
    updateTopicVisualData,
    canSaveVisualFilters,
    getTrendGroups,
    trendGroups,
    visualTrendGroupsErrors,
    fetchPreviewData,
    topLineStatsPreview,
    mentionsTrendTimeSeriesPreview,
    topPostsTopicPreview,
    createTopicPreviewPayload,
    fetchTopPostsTopicPreview,
    launchCreateTopicDrawer,
    createTopic,
    hasSelectedBrandAccessToCompetitors,
    userAccessibleBrandsWithCompetitorsAccess,
    formattedVisualData,
    tempVisualFiltersChanged,
    setComponentsPendingState,
    fetchTopLineStatsPreview,
  };
});
