/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vision-ai': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g clip-path="url(#clip0_10141_214918)"><mask id="svgicon_vision-ai_a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14 26.833c7.088 0 12.833-5.745 12.833-12.833S21.088 1.167 14 1.167 1.167 6.912 1.167 14 6.912 26.833 14 26.833zm0-3.118c5.366 0 9.715-4.35 9.715-9.715 0-5.366-4.35-9.715-9.715-9.715-5.366 0-9.715 4.35-9.715 9.715 0 5.366 4.35 9.715 9.715 9.715z" _fill="#000"/><path pid="1" d="M20.467 11.684l-3.183 1.171-1.18 3.175-1.174-3.177-3.181-1.178 3.183-1.172 1.18-3.174 1.174 3.176 3.18 1.179z" _fill="#000"/></mask><g mask="url(#svgicon_vision-ai_a)"><path pid="2" _fill="#F9FBFF" d="M1.167 1.167h25.667v25.667H1.167z"/></g></g><defs><clipPath id="clip0_10141_214918"><path pid="3" _fill="#fff" d="M0 0h28v28H0z"/></clipPath></defs>'
  }
})
