<script setup>
import { computed, onMounted } from 'vue';
import { Button, Chip, Panel, Skeleton, useConfirmationDialog } from '@dashhudson/dashing-ui';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import {
  metaPaidPartnerAdsUrl,
  metaPaidPartnersStatuses,
  tooltips,
} from '@/app/creators/constants';
import { useMetaPaidPartnersStatus } from '@/app/library/queries/meta-paid-partners';
import {
  useRevokeMetaPaidPartners,
  useRequestMetaPaidPartners,
} from '@/app/library/mutations/meta-paid-partners';
import { MediaPopupUserEventTracker } from '@/app/library/mixpanel';

const props = defineProps({
  isBusinessAccount: {
    type: Number,
    required: true,
  },
  handle: {
    type: String,
    required: true,
  },
});

const mediaPopupUserEventTracker = new MediaPopupUserEventTracker();

// Stores
const platformStore = usePlatformStore();
const { isPlatformConnected, canAccessScope, connect } = platformStore;

// Queries
const {
  metaPaidPartnersStatus,
  handle: creatorHandle,
  error: statusError,
  isLoading: statusIsLoading,
  refetch,
} = useMetaPaidPartnersStatus();

// Mutations
const { revokeMetaPaidPartners, isLoading: revokeIsLoading } = useRevokeMetaPaidPartners();
const { requestMetaPaidPartners, isLoading: requestIsLoading } = useRequestMetaPaidPartners();

// Functions
function handleRevoke() {
  mediaPopupUserEventTracker.mpaInteraction({ buttonClicked: 'Revoke' });
  revokeMetaPaidPartners({
    handle: creatorHandle.value,
    creatorInstagramId: metaPaidPartnersStatus.value.creatorInstagramId,
  });
}

const { showConfirmationDialog: showRevokeConfirmationDialog } = useConfirmationDialog({
  group: 'global',
  header: 'Revoke Meta Partnership?',
  message:
    "Your brand will no longer be authorized to promote this creator's content as an ad. You'll need to request authorization again.",
  acceptLabel: 'Revoke',
  acceptSeverity: 'primary',
  onAccept: handleRevoke,
});

function handleRequest() {
  mediaPopupUserEventTracker.mpaInteraction({ buttonClicked: 'Request' });
  requestMetaPaidPartners({
    handle: creatorHandle.value,
  });
}

function connectInstagram() {
  mediaPopupUserEventTracker.mpaInteraction({ buttonClicked: 'Create Connection' });
  connect(PLATFORM_CONNECTION.FACEBOOK, {
    showWhenDrawerOpen: true,
  });
}

// Computed Properties
const isFacebookConnected = computed(() => {
  return isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK);
});

const hasInstagramBrandedContentAdsScope = computed(() => {
  return canAccessScope(PLATFORM_CONNECTION.FACEBOOK, [
    PLATFORM_CONNECTION.FACEBOOK.OPTIONAL_SCOPES.INSTAGRAM_BRANDED_CONTENT_ADS_BRAND,
  ]);
});

const statusNotFound = computed(() => statusError.value?.response?.status === 404);
const statusForbidden = computed(() => statusError.value?.response?.status === 403);

const errorState = computed(() => {
  if (!props.isBusinessAccount) {
    return {
      message:
        'Paid partnership requests are only available for creators with professional accounts',
    };
  }
  if (statusError.value && !statusNotFound.value && !statusForbidden.value) {
    return {
      message: 'Unable to retrieve permission status. Please try again.',
      ctaButton: {
        label: 'Retry',
        onClick: refetch,
      },
    };
  }

  return null;
});

const emptyState = computed(() => {
  if (!isFacebookConnected.value) {
    return {
      message: 'Connect your Instagram account to run partnership ads with this creator',
      ctaButton: {
        label: 'Connect Instagram',
        onClick: connectInstagram,
      },
    };
  }
  if (isFacebookConnected.value && !hasInstagramBrandedContentAdsScope.value) {
    return {
      message: 'Update your Instagram connection to enable permission to request partnership ads',
      ctaButton: {
        label: 'Update Connection',
        onClick: connectInstagram,
      },
    };
  }

  return null;
});

const status = computed(() => metaPaidPartnersStatuses[metaPaidPartnersStatus.value?.status]);

const showRequestButton = computed(
  () =>
    statusNotFound.value ||
    status.value === metaPaidPartnersStatuses.REVOKED ||
    status.value === metaPaidPartnersStatuses.CANCELED,
);

// Lifecycle Hooks
onMounted(() => {
  creatorHandle.value = props.handle;
});
</script>

<template>
  <div class="dash-w-full">
    <Panel
      title="Meta Partnership Ads"
      channel="META"
      :tooltip="tooltips.metaPaidPartnerAds"
      :error="Boolean(errorState)"
      :no-data="Boolean(emptyState)"
      :content-banner="emptyState || errorState"
    >
      <template v-if="status && !statusNotFound && !errorState && !emptyState" #titleAppendRight>
        <Chip
          v-dash-tooltip="status.tooltip"
          :badge="{ severity: status.severity }"
          :label="status.label"
        />
      </template>
      <Skeleton v-if="statusIsLoading" height="12rem" />
      <template v-else>
        <div class="dash-flex dash-flex-col dash-items-start">
          <span class="text-left">
            {{
              status === metaPaidPartnersStatuses.APPROVED
                ? 'This creator has authorized your brand to run their content that has tagged you as paid ads. To run their content as an ad, please go to your Meta Ads Manager.'
                : 'This permission enables a brand to promote creator content they’re tagged in via paid partnership label or non-branded content (@mentions, tags, product tags, or Instagram Collab posts) as paid ads.'
            }}
          </span>
          <a
            v-if="status !== metaPaidPartnersStatuses.APPROVED"
            class="dash-text-action no-underline"
            :href="metaPaidPartnerAdsUrl"
            target="_blank"
            rel="noopener"
          >
            Learn More
          </a>
        </div>
      </template>
      <template #footer>
        <div v-if="!statusIsLoading && showRequestButton" class="dash-flex dash-justify-end">
          <Button
            severity="primary"
            size="lg"
            label="Request"
            :loading="requestIsLoading"
            @click="handleRequest"
          />
        </div>
        <div v-if="!statusIsLoading && !showRequestButton" class="dash-flex dash-justify-end">
          <Button
            severity="secondary"
            size="lg"
            label="Revoke"
            :loading="revokeIsLoading"
            @click="showRevokeConfirmationDialog"
          />
        </div>
      </template>
    </Panel>
  </div>
</template>
