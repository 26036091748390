import { computed, ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import {
  getRuleItemLabel,
  MESSAGE_RULE_FILTER_OPTIONS,
  MESSAGE_RULE_TYPES,
} from '@/app/community/constants/inboxAutomation';
import { getRuleFilterMatchCount } from '@/apis/community';
import isNil from 'lodash/isNil';
import union from 'lodash/union';

export function useCommunityMessageRuleBuilder() {
  const INITIAL_RULE_ACTIONS = [];
  const GROUP_STRUCTURE = {
    items: [], // the filters inside a group will be added here
    operator: 'OR',
  };
  const INITIAL_RULE_CONDITION_GROUPS = [
    {
      previousGroupJoiningOperator: null,
      groupDetails: cloneDeep(GROUP_STRUCTURE),
    },
  ];

  const conditionGroups = ref(cloneDeep(INITIAL_RULE_CONDITION_GROUPS));
  const actions = ref(cloneDeep(INITIAL_RULE_ACTIONS));
  const filterMatchedMessagesCount = ref(null);

  const ruleBuilderErrors = ref({ filterMatchedMessagesCount: null });

  const isFiltersComplete = computed(() => {
    let isComplete = true;
    conditionGroups.value.forEach((group) => {
      const items = group.groupDetails.items;
      if (!items.every((item) => !isNil(item.key) && !isNil(item.value) && item.value !== '')) {
        isComplete = false;
      }
    });
    return isComplete;
  });

  const emptyGroupCount = computed(() => {
    return conditionGroups.value.reduce((count, group) => {
      return group.groupDetails.items.length === 0 ? count + 1 : count;
    }, 0);
  });

  const isActionsComplete = computed(() => {
    const action = actions.value?.[0];
    return !!action && !!action.key && !!action.value;
  });
  const isRuleComplete = computed(() => {
    return isFiltersComplete.value && isActionsComplete.value && emptyGroupCount.value === 0;
  });
  const hasRepliedFilter = computed(() => {
    let exist = false;
    conditionGroups.value.forEach((group) => {
      const items = group.groupDetails.items;
      if (
        items.length &&
        items.some((item) => item.key === MESSAGE_RULE_FILTER_OPTIONS.REPLIED.key)
      ) {
        exist = true;
      }
    });
    return exist;
  });

  function getFilterUsed() {
    const filterKeys = conditionGroups.value.map((g) =>
      g.groupDetails.items.map((item) => item.key),
    );
    return union(...filterKeys).map((k) => getRuleItemLabel(k));
  }

  function getFilterGroups() {
    const filterGroups = conditionGroups.value.map((g) => {
      const items = g.groupDetails.items;
      return {
        filter: items.map((item) => getRuleItemLabel(item.key)),
        value: items.map((item) => item.value),
        operator: g.previousGroupJoiningOperator,
      };
    });
    return filterGroups;
  }

  function clearFilterMessageCountAndError() {
    filterMatchedMessagesCount.value = null;
    ruleBuilderErrors.value.filterMatchedMessagesCount = false;
  }

  function clearRuleBuilder() {
    conditionGroups.value = cloneDeep(INITIAL_RULE_CONDITION_GROUPS);
    actions.value = cloneDeep(INITIAL_RULE_ACTIONS);
    clearFilterMessageCountAndError();
  }

  function updateGroup(groupIndex, groupItems, ruleType) {
    if (ruleType === MESSAGE_RULE_TYPES.ACTION) {
      actions.value = groupItems;
    } else {
      conditionGroups.value[groupIndex].groupDetails.items = groupItems;
    }
  }

  function removeFilterGroup(groupIndex) {
    if (conditionGroups.value[groupIndex]) {
      conditionGroups.value.splice(groupIndex, 1);
    }
  }

  function setConditionGroupOperator(groupIndex, joiningOperator) {
    const nextGroup = conditionGroups.value[groupIndex + 1];
    const nextGroupOperator = nextGroup?.previousGroupJoiningOperator;

    if (!nextGroupOperator) {
      // If a new group is being added
      conditionGroups.value.push({
        previousGroupJoiningOperator: joiningOperator,
        groupDetails: cloneDeep(GROUP_STRUCTURE),
      });
    } else if (conditionGroups.value[groupIndex + 1]) {
      // if existing group's operator is being updated
      conditionGroups.value[groupIndex + 1].previousGroupJoiningOperator = joiningOperator;
    }
  }

  function buildJSONRuleFilters() {
    if (conditionGroups.value.length === 0) return null;

    const nonEmptyGroups = conditionGroups.value.filter(
      (group) => group.groupDetails.items.length > 0,
    );

    let finalFilters = {
      items: [],
      operator: 'OR',
    };

    let currentAndGroup = null;
    let hasOrOperator = false;

    nonEmptyGroups.forEach((group, index) => {
      if (group.previousGroupJoiningOperator === 'AND') {
        if (!currentAndGroup) {
          // Create a new AND group and include the previous group inside it
          currentAndGroup = {
            items: [],
            operator: 'AND',
          };
          if (index > 0) {
            // Add the previous group to the new AND group
            currentAndGroup.items.push(finalFilters.items.pop());
          }
        }
        currentAndGroup.items.push(group.groupDetails);
      } else {
        // Check for OR operator explicitly, ignore the first group with null
        if (group.previousGroupJoiningOperator === 'OR') {
          hasOrOperator = true;
        }
        if (currentAndGroup) {
          finalFilters.items.push(currentAndGroup);
          currentAndGroup = null;
        }
        finalFilters.items.push(group.groupDetails);
      }
    });

    if (currentAndGroup) {
      finalFilters.items.push(currentAndGroup);
    }

    // If there were no OR operators and all conditionGroups are combined with AND, change the outermost operator to AND
    if (!hasOrOperator) {
      finalFilters = finalFilters.items[0];
    }

    return finalFilters || {};
  }

  function buildJSONRuleActions() {
    let finalActions = {};
    if (isActionsComplete.value) {
      finalActions = { [actions.value[0].key]: actions.value[0].value };
    }
    return finalActions;
  }

  function buildJSONRule() {
    const finalFilters = buildJSONRuleFilters();
    const finalActions = buildJSONRuleActions();
    return { actions: finalActions, filters: finalFilters };
  }

  function checkGroupHasCompleteItem(index) {
    const items = conditionGroups.value[index]?.groupDetails.items;
    return items?.length > 0 && items.some((item) => !isNil(item.key) && !isNil(item.value));
  }

  async function updateFilterMatchCount(selectedBrandId) {
    if (emptyGroupCount.value === conditionGroups.value.length) {
      clearFilterMessageCountAndError();
      return;
    }
    if (isFiltersComplete.value) {
      try {
        const JSONFilters = buildJSONRuleFilters();
        const res = await getRuleFilterMatchCount(selectedBrandId, JSONFilters);
        filterMatchedMessagesCount.value = res.data.count;
        ruleBuilderErrors.value.filterMatchedMessagesCount = null;
      } catch (error) {
        ruleBuilderErrors.value.filterMatchedMessagesCount = true;
      }
    }
  }

  const ruleDetail = computed(() => buildJSONRule());

  function processRuleFilterGroupItem(filter, groupOperator = null) {
    if (filter.items[0].operator) {
      filter.items.forEach((nestedFilter, index) => {
        const operator = index === 0 ? groupOperator : filter.operator;
        processRuleFilterGroupItem(nestedFilter, operator);
      });
    } else {
      conditionGroups.value.push({
        groupDetails: filter,
        previousGroupJoiningOperator: conditionGroups.value.length ? groupOperator : null,
      });
    }
  }

  function setRuleFiltersFromJSON(rawFilters) {
    conditionGroups.value = [];
    processRuleFilterGroupItem(rawFilters);
  }

  function setRuleActionsFromJSON(rawActions) {
    const key = Object.keys(rawActions)[0];
    actions.value = [{ key, value: rawActions[key] }];
  }

  function isRuleDetailChanged(original) {
    const current = buildJSONRule();
    return JSON.stringify(original) !== JSON.stringify(current);
  }

  return {
    conditionGroups,
    actions,
    isFiltersComplete,
    isActionsComplete,
    isRuleComplete,
    hasRepliedFilter,
    filterMatchedMessagesCount,
    ruleBuilderErrors,
    ruleDetail,
    updateGroup,
    removeFilterGroup,
    setConditionGroupOperator,
    buildJSONRule,
    buildJSONRuleFilters,
    buildJSONRuleActions,
    clearRuleBuilder,
    checkGroupHasCompleteItem,
    updateFilterMatchCount,
    setRuleActionsFromJSON,
    setRuleFiltersFromJSON,
    isRuleDetailChanged,
    getFilterUsed,
    getFilterGroups,
  };
}
