<template>
  <div v-if="flagStore.ready">
    <Popup
      :close="close"
      :close-confirmation="closeConfirmation"
      type="large"
      class="media-cropper-popup"
      :style="{
        padding: 'var(--space-48)',
      }"
      :show-when-drawer-open="showWhenDrawerOpen"
      :inline-styles="{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '40rem',
        height: '100%',
        margin: 'auto',
      }"
    >
      <header class="dh-media-cropper">
        <Icon :color="iconColor" medium name="crop" />
        <span>Edit Media</span>
      </header>
      <section class="cropping-tools">
        <div class="media-section">
          <MediaCropper
            :media="media"
            :aspect-ratio="aspectRatio"
            :on-crop-change="setCropData"
            :original-media="originalMedia"
            :zoom-value="cropperStore.cropperZoom"
          />
        </div>
        <AspectRatioSelector
          :media="media"
          :enable-free-crop="enableFreeCrop"
          :custom-cropper-presets="customCropperPresets"
        />
        <ValidationError v-if="validationErrorMessage" class="cropper-validation-error">
          {{ validationErrorMessage }}
        </ValidationError>
      </section>
      <footer class="dh-media-cropper">
        <div class="footer-tools">
          <Slider
            :default-value="cropperStore.cropperZoom"
            :min="0"
            :max="500"
            left-icon="fileLandscapeImage"
            right-icon="fileLandscapeImage"
            slider-type="zoom"
            @changed-slider-value="onZoomChangedValue"
          />
        </div>
        <div class="controls">
          <Button small :disabled="creatingCroppedImage" data-cy="cancel-crop" @click="close">
            Cancel
          </Button>
          <Button
            :loading="creatingCroppedImage || !croppedMediaData"
            :disabled="creatingCroppedImage || validationErrorMessage !== null || !croppedMediaData"
            small
            class="save"
            primary
            data-cy="save-crop"
            @click="saveImage"
          >
            Save
          </Button>
        </div>
        <div v-if="showPredictionWarning" class="crop-prediction-warning">
          <p>* Cropping or trimming may result in a change of</p>
          <p>Vision predicted performance</p>
        </div>
      </footer>
    </Popup>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import camelCase from 'lodash/camelCase';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useMediaStore } from '@/stores/media';
import { useNotificationStore } from '@/stores/notification';
import { externalAxios } from '@/apis/external-apis';
import { canvasToBlob } from '@/utils';
import { axios as libraryAxios } from '@/apis/library';
import { imageCropperPresets, discardConfirmMessage } from '@/config';
import { colours } from '@/ux/colours';
import {
  mediaValidationMap,
  mediaCropLinkTagsMessage,
  mediaCropLinkTagsTitle,
  facebookTagsCropConfirmTitle,
  facebookTagsCropConfirmMessage,
  PLATFORMS,
  PLATFORM_POST_TYPE_MAP,
  postTypes,
} from '@/app/scheduler/constants';
import enumTypes, { mediaTypes } from '@/app/library/constants';
import AspectRatioSelector from '@/components/MediaCropping/AspectRatioSelector.vue';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import MediaCropper from '@/components/MediaCropping/ImageCropper.vue';
import MediaModel from '@/models/media';
import Popup from '@/components/Popup.vue';
import Slider from '@/components/Slider.vue';
import ValidationError from '@/components/ValidationError.vue';
import { useCropperStore } from '@/stores/cropper';
import { useMediaLinksStore } from '@/stores/media-links';
import { useFlagStore } from '@/stores/flag';
import { useSocketStore } from '@/stores/socket';
import { showPredictions } from '@/utils/vision';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaCropperPopup',
  components: {
    AspectRatioSelector,
    Button,
    Icon,
    MediaCropper,
    Popup,
    Slider,
    ValidationError,
  },
  props: {
    close: { type: Function, required: true },
    media: { type: [Object, String], required: true },
    onSave: { type: Function, required: true },
    originalMedia: { type: Object, default: null },
    platform: { type: String, default: null },
    customCropperPresets: { type: Object, default: null },
    keepMediaLinks: { type: Boolean, default: false },
    enableFreeCrop: { type: Boolean, default: true },
    showWhenDrawerOpen: { type: Boolean, default: false },
    postType: { type: String, default: null },
  },
  data() {
    return {
      aspectRatio: null,
      croppedMediaData: null,
      creatingCroppedImage: false,
      iconColor: colours.ICON.ICON_SECONDARY,
      updatedCanvas: null,
      validationErrorMessage: null,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useCropperStore,
      useAuthStore,
      useMediaLinksStore,
      useMediaStore,
      useFlagStore,
      useSocketStore,
      useSchedulerStore,
      useInstagramShoppingTaggerStore,
      useInstagramUserTaggerStore,
      useFacebookProductTaggerStore,
      useTrackingStore,
    ),
    validationRequired() {
      return (
        this.platform &&
        ((this.platform === PLATFORMS.INSTAGRAM &&
          this.postType === postTypes.FEED &&
          this.schedulerStore.autoPublish) ||
          (this.platform === PLATFORMS.PINTEREST && this.schedulerStore.autoPublish) ||
          this.platform === PLATFORMS.TWITTER)
      );
    },
    closeConfirmation() {
      return discardConfirmMessage;
    },
    showPredictionWarning() {
      return showPredictions(this.media, this.$router) || !this.media.id;
    },
    canvas() {
      return this.updatedCanvas ?? this.croppedMediaData.canvas;
    },
    platformPostType() {
      return this.postType
        ? PLATFORM_POST_TYPE_MAP[this.platform]?.[this.postType]
        : PLATFORM_POST_TYPE_MAP[this.platform];
    },
  },
  created() {
    if (this.media?.id) {
      this.facebookProductTaggerStore.setActiveMediaId({ mediaId: this.media?.id });
    }

    if (this.originalMedia) {
      const zoom = this.media.editorData && this.media.editorData.zoomValue;
      if (zoom) this.cropperStore.setCropperZoom(zoom);
      const ratioType = this.media.editorData.aspectRatio;
      if (ratioType !== 'freeCrop') {
        // Default to full preset list if no custom option
        const ratioTypeData =
          this.customCropperPresets?.[ratioType] || imageCropperPresets[ratioType];
        const res = ratioTypeData.ratio.split(':');
        const widthRatio = Number(res[0]);
        const heightRatio = Number(res[1]);
        this.aspectRatio = widthRatio / heightRatio;

        this.cropperStore.setCropperRatio({
          ratioType,
          name: ratioTypeData.name,
          ratio: ratioTypeData.ratio,
        });
      }
    }
  },
  beforeUnmount() {
    this.croppedMediaData = null;
    this.cropperStore.resetCropperZoom();
  },
  methods: {
    isOdd(number) {
      return number % 2 !== 0;
    },
    /**
     For images with odd dimensions, cropperJS doesn't handle rounding properly
     which leads to additional black borders being displayed in the exported image.
     For more info see here: https://github.com/fengyuanchen/cropperjs/issues/551
     The workaround done here maps the original canvas generated by cropperJS
     onto a new canvas and removes the 1px black borders in the new canvas.
     */
    resizeImage() {
      // Create and initialize a new canvas
      this.updatedCanvas = document.createElement('canvas');

      // Copy and resize second canvas to cropperjs canvas and shave off a pixel from width and height
      this.updatedCanvas.width = this.croppedMediaData.canvas.width - 1;
      this.updatedCanvas.height = this.croppedMediaData.canvas.height - 1;

      const ctx = this.updatedCanvas.getContext('2d');
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(
        this.croppedMediaData.canvas,
        0,
        0,
        this.croppedMediaData.canvas.width - 1,
        this.croppedMediaData.canvas.height - 1,
        0,
        0,
        this.updatedCanvas.width,
        this.updatedCanvas.height,
      );
    },
    saveImage() {
      if (
        this.isOdd(this.croppedMediaData.canvas.width) ||
        this.isOdd(this.croppedMediaData.canvas.height)
      ) {
        this.resizeImage();
      }
      this.creatingCroppedImage = true;
      this.checkIfTagsExist();
    },
    async createCroppedImage() {
      // On save, create a new image with the right canvas and set the scheduled post to use that media id
      const blob = await canvasToBlob(this.canvas);

      // Request a signed S3 upload URL
      const params = { filename: 'image.jpeg', content_type: blob.type };
      const res = await libraryAxios.post(`/media_upload_url`, params);
      const croppedMediaUrl = res.data.split('?')[0];
      const options = { headers: { 'Content-Type': blob.type } };

      // Upload cropped image to S3
      await externalAxios.put(res.data, blob, options);

      // If the media item has been cropped before, save the original parent media id
      const parentMediaId = this.originalMedia ? this.originalMedia.id : this.media?.id;

      // There's a chance that croppedMediaData is null if the user closes the cropper popup while
      // saving the ratio change, so adding the check for it here to avoid error.
      if (this.croppedMediaData) {
        const { canvasData } = this.croppedMediaData;
        const { x, y, width, height } = this.croppedMediaData.cropData;

        try {
          const data = {
            callback_socket_id: this.socketStore.id,
            image: {
              editor_data: {
                aspect_ratio: this.cropperStore.cropperRatio.ratioType,
                canvas_data: {
                  height: canvasData.height,
                  left: canvasData.left,
                  natural_height: canvasData.naturalHeight,
                  natural_width: canvasData.naturalWidth,
                  top: canvasData.top,
                  width: canvasData.width,
                },
                zoom_value: this.cropperStore.cropperZoom,
              },
              sizes: {
                original: {
                  url: croppedMediaUrl,
                },
              },
              transforms: {
                crop: {
                  x,
                  y,
                  width,
                  height,
                },
                parent_media_id: parentMediaId || null,
              },
            },
            source: enumTypes.EDITOR,
            type: 'IMAGE',
          };

          await this.mediaStore.createMediaV2({ brandId: this.authStore.currentBrand.id }, data);

          const croppedMediaItem = this.mediaStore.newMediaV2;

          this.onSave(new MediaModel(croppedMediaItem));
          // Only save links if not a carousel or is first page of carousel (index 0)
          if (
            !this.schedulerStore.carouselPage?.[this.platformPostType] &&
            this.mediaLinksStore.mediaLinks?.length > 0
          ) {
            this.saveLinks(croppedMediaItem.id);
          }
          if (this.instagramUserTaggerStore.activeMediaTaggedUsers.length > 0) {
            this.saveUserTags(croppedMediaItem.id);
          }
          if (this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length > 0) {
            this.saveShoppingTags(croppedMediaItem.id);
          }
          if (this.facebookProductTaggerStore.activeMediaTaggedProducts.length > 0) {
            this.saveFacebookProductTags(croppedMediaItem.id);
          }
          this.trackingStore.track('Cropped media saved', {
            platformType: this.platform,
            mediaType: mediaTypes.IMAGE,
          });
        } catch (error) {
          this.notificationStore.setToast({
            message: 'Something went wrong, please try again.',
            type: 'error',
          });
          this.updatedCanvas = null;
        }
        // Format new media item to match media object passed to Media Manager
        this.creatingCroppedImage = false;
      }
    },
    setCropData(data) {
      this.croppedMediaData = data;
      // Validate media when aspect ratio changes
      if (this.validationRequired) {
        if (this.platform === PLATFORMS.INSTAGRAM && this.postType === postTypes.FEED) {
          this.validateAspectRatio(this.croppedMediaData.cropData);
        } else if (this.platform === 'twitter' || this.platform === 'facebook') {
          this.validateDimensions(this.croppedMediaData.cropData);
        }
      }
    },
    validateAspectRatio(cropData) {
      let platformPostType = camelCase(this.platform);
      if (platformPostType === PLATFORMS.INSTAGRAM) {
        platformPostType = camelCase(this.platform + this.postType);
      }
      const mediaType = this.media.type.toLowerCase();
      const validations = mediaValidationMap[platformPostType].aspectRatio[mediaType];
      /**
       For images with odd dimensions, we have to subtract 1 pixel from width and height to account
       for an issue in cropperJS that produces black borders. Validation also needs to account for
       this difference in dimensions.
       For more info see here: https://github.com/fengyuanchen/cropperjs/issues/551
       */
      let { width, height } = cropData;
      if (this.isOdd(cropData.width) || this.isOdd(cropData.height)) {
        width -= 1;
        height -= 1;
      }
      const currentAspectRatio = (width / height).toFixed(4);

      if (
        currentAspectRatio < validations.minNumeric ||
        currentAspectRatio > validations.maxNumeric
      ) {
        this.validationErrorMessage =
          `This ${mediaType} aspect ratio doesn't meet ${validations.platformName}'s ` +
          `requirements. Please crop your image to be within a ${validations.min} to ` +
          `${validations.max} range and try again!`;
      } else {
        this.validationErrorMessage = null;
      }
    },
    validateDimensions(cropData) {
      const platformName = camelCase(this.platform);
      const mediaType = this.media.type.toLowerCase();
      const validations = mediaValidationMap[platformName].dimensions[mediaType];
      /**
       For images with odd dimensions, we have to subtract 1 pixel from width and height to account
       for an issue in cropperJS that produces black borders. Validation also needs to account for
       this difference in dimensions.
       For more info see here: https://github.com/fengyuanchen/cropperjs/issues/551
       */
      let { width, height } = cropData;
      if (this.isOdd(cropData.width) || this.isOdd(cropData.height)) {
        width -= 1;
        height -= 1;
      }

      if (width < validations.minNumeric || width > validations.maxNumeric) {
        this.validationErrorMessage =
          `This ${mediaType} width doesn't meet ${validations.platformName}'s ` +
          `requirements. Please crop your image to be within ${validations.min} to ` +
          `${validations.max} wide and try again!`;
      } else if (height < validations.minNumeric || height > validations.maxNumeric) {
        this.validationErrorMessage =
          `This ${mediaType} height doesn't meet ${validations.platformName}'s ` +
          `requirements. Please crop your image to be within ${validations.min} to ` +
          `${validations.max} high and try again!`;
      } else {
        this.validationErrorMessage = null;
      }
    },
    onZoomChangedValue(newZoomValue) {
      this.cropperStore.setCropperZoom(newZoomValue / 100);
    },
    async checkIfTagsExist() {
      const confirmAlias = 'Crop';
      if (
        this.platform === PLATFORMS.INSTAGRAM &&
        this.postType === postTypes.FEED &&
        ((this.schedulerStore.carouselPage?.[this.platformPostType] === 0 &&
          this.mediaLinksStore.mediaLinks?.length > 0) ||
          this.instagramUserTaggerStore.activeMediaTaggedUsers.length > 0 ||
          this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length > 0)
      ) {
        await this.notificationStore.confirm(mediaCropLinkTagsTitle, mediaCropLinkTagsMessage, {
          confirmAlias,
          onConfirm: this.confirmCrop,
          onCancel: this.closeTagsConfirmationPopup,
        });
      } else if (
        this.platform === 'facebook' &&
        this.facebookProductTaggerStore.activeMediaTaggedProducts.length > 0
      ) {
        await this.notificationStore.confirm(
          facebookTagsCropConfirmTitle,
          facebookTagsCropConfirmMessage,
          {
            confirmAlias,
            onConfirm: this.confirmCrop,
            onCancel: this.closeTagsConfirmationPopup,
          },
        );
      } else {
        this.createCroppedImage();
      }
    },
    closeTagsConfirmationPopup() {
      this.creatingCroppedImage = false;
    },
    async confirmCrop() {
      await this.createCroppedImage();
    },
    saveLinks(croppedMediaId) {
      this.mediaLinksStore.updateMediaLinks({
        brandId: this.authStore.currentBrand.id,
        mediaId: croppedMediaId,
        data: this.mediaLinksStore.mediaLinks,
      });
      this.cropperStore.setAdjustLinksPrompt();
    },
    saveUserTags(croppedMediaId) {
      const userTags = this.instagramUserTaggerStore.taggedUsers.map((tag) => {
        if (tag.mediaId === this.media.id.toString()) {
          return { ...tag, mediaId: croppedMediaId };
        }
        return tag;
      });
      this.instagramUserTaggerStore.setUserTags({ tags: userTags });
      this.cropperStore.setAdjustTagsPrompt();
    },
    async saveShoppingTags(croppedMediaId) {
      await this.instagramShoppingTaggerStore.replaceCroppedMediaShoppingTags(
        this.media.id.toString(),
        croppedMediaId.toString(),
      );
      this.cropperStore.setAdjustShoppingTagsPrompt();
    },
    saveFacebookProductTags(croppedMediaId) {
      const productTags = this.facebookProductTaggerStore.taggedProducts.map((tag) => {
        if (tag.mediaId === this.media.id) {
          return { ...tag, mediaId: croppedMediaId };
        }
        return tag;
      });
      this.facebookProductTaggerStore.setFacebookProductTags({ productTags });
      this.cropperStore.setAdjustFacebookProductTagsPrompt();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
#popup-page-container.media-cropper-popup {
  header.dh-media-cropper {
    display: flex;
    padding: var(--space-20) var(--space-24);
    border-bottom: 1px solid var(--border);

    span {
      color: var(--text-primary);
      font-size: var(--x18);
      font-weight: var(--font-medium);
      margin-left: var(--space-12);
    }
  }

  section.cropping-tools {
    position: relative;
    display: flex;
    flex: auto;
    overflow: auto;
    height: fit-content;
    padding: var(--space-32) var(--space-32) 0 var(--space-32);

    .media-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 300px);
      margin-bottom: var(--space-32);
    }

    .media-cropper {
      display: flex;
      justify-content: center;
      max-height: 100%;
      flex-grow: 1;
    }
  }

  .cropper-validation-error {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  footer.dh-media-cropper {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--border);

    .footer-tools {
      width: calc(100% - 300px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .controls {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 22px 32px 16px;

      .save {
        margin-left: var(--space-16);
      }
    }

    .crop-prediction-warning {
      width: 100%;
      font-size: 0.7rem;
      line-height: 16px;
      text-align: right;
      padding: 0 32px 16px 0;
      margin-top: -8px;
    }
  }
}

#popup-page-container.tags-confirmation-popup {
  :deep(p) {
    margin: 0 var(--space-16);
  }
}

.link-tags-confirmation-popup :deep(h3) {
  text-transform: none;
  font-size: var(--x22);
}
</style>
