<script setup>
import CommentPanel from '@/components/CommentingModule/CommentPanel.vue';
import PostEditorSidePanelHeader from '@/app/scheduler/components/EditPost/PostEditorSidePanelHeader.vue';
import ApprovalRequestPanel from '@/app/scheduler/components/ApprovalRequestPanel.vue';
import { computed } from 'vue';
import { useCustomerJourneyStore } from '@/stores/customer-journey';

const props = defineProps({
  resourceId: {
    type: [String, Number],
    required: true,
  },
  postResourceType: {
    type: String,
    default: null,
  },
  onClose: {
    type: Function,
    required: true,
  },
  linkedCommentId: {
    type: Number,
    default: null,
  },
  post: {
    type: Object,
    default: null,
  },
  postEditorType: {
    type: String,
    required: true,
  },
  hasUnsavedChanges: {
    type: Boolean,
    default: false,
  },
});

const customerJourneyStore = useCustomerJourneyStore();

const hasApprovalRequestsAccess = computed(() => {
  return !customerJourneyStore.hasPlanGrow;
});
</script>

<template>
  <div v-if="props.resourceId">
    <div
      class="flex h-full w-96 flex-col overflow-scroll rounded-br-md rounded-tr-md bg-[--background-300]"
    >
      <PostEditorSidePanelHeader :on-close="onClose" />
      <ApprovalRequestPanel
        v-if="hasApprovalRequestsAccess"
        :approval-post-type="postEditorType"
        :post="post"
        :has-unsaved-changes="hasUnsavedChanges"
      />
      <div v-if="props.resourceId > 0">
        <hr class="horizontal-line" />
        <CommentPanel
          class="w-80"
          show-header
          :panel-open="Boolean(props.resourceId)"
          :resource-id="props.resourceId"
          :resource-type="postResourceType"
          :linked-comment-id="linkedCommentId"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.horizontal-line {
  width: 90%;
  border-top: 1.5px solid var(--border);
  text-align: center;
  margin-left: 5%;
}
</style>
