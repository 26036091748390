import isNil from 'lodash/isNil';
import merge from 'lodash/merge';

import { axios } from '@/apis/social-listening';

const generateFilters = ({ hashtags, startDate, endDate, ...rest }) => ({
  hashtags,
  source_created: { on_or_after: startDate, on_or_before: endDate },
  ...rest,
});

export async function getIgHashtagsSearchResults(
  { hashtags, startDate, endDate, sorts, paging },
  axiosConfig = {},
) {
  return axios.post(
    '/hashtags/instagram/posts/search',
    { filters: generateFilters({ hashtags, startDate, endDate }), sorts, paging },
    axiosConfig,
  );
}

export async function getIgHashtagsRelatedTagsResults(
  { hashtags, startDate, endDate, limit },
  axiosConfig = {},
) {
  const requestConfig = isNil(limit) ? axiosConfig : merge({ params: { limit } }, axiosConfig);
  return axios.post(
    '/hashtags/instagram/related_tags',
    { filters: generateFilters({ hashtags, startDate, endDate }) },
    requestConfig,
  );
}

export async function postIgHashtagsPostsCSV(
  { csvType, socketId, hashtags, startDate, endDate, sorts },
  axiosConfig = {},
) {
  return axios.post(
    '/hashtags/instagram/posts/csv',
    { csvType, socketId, filters: generateFilters({ hashtags, startDate, endDate }), sorts },
    axiosConfig,
  );
}

export async function getIgHashtagsPostVolume(
  { hashtags, startDate, endDate, rule },
  axiosConfig = {},
) {
  return axios.post(
    '/hashtags/instagram/post_volume',
    { filters: generateFilters({ hashtags, startDate, endDate, rule }) },
    axiosConfig,
  );
}

export async function getIgHashtagsTimeSeriesPostVolume(
  { hashtags, startDate, endDate, scale },
  axiosConfig = {},
) {
  return axios.post(
    '/hashtags/instagram/time_series/post_volume',
    { filters: generateFilters({ hashtags, startDate, endDate, scale }) },
    axiosConfig,
  );
}
