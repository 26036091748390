<template>
  <Popup :close="onClose" enable-drag-select type="large" :style="{ overflow: 'hidden' }">
    <SelectMediaPanel v-bind="$attrs" @close="onClose" />
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import Popup from '@/components/Popup.vue';
import SelectMediaPanel from '@/components/core/SelectMediaPanel.vue';

const comp = defineComponent({
  name: 'SelectMediaPopup',
  components: {
    Popup,
    SelectMediaPanel,
  },
  props: {
    close: { type: Function, required: true },
  },
  methods: {
    onClose() {
      this.close();
    },
  },
});
export default comp;
</script>
