<script setup>
import { useRouter, useRoute } from 'vue-router';
import constants from '@/app/settings/components/CustomMetrics/constants';
import { useMediaDetailStore } from '@/stores/media-detail';
import { onMounted, onUnmounted, computed } from 'vue';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import { FORMAT_TYPES } from '@/models/dashboards/metrics.constants';
import { SEND_TO_SETTINGS, DASHBOARD_EVENT_NAMES } from '@/app/dashboards/constants';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useCustomMetricsStore } from '@/stores/custom-metrics';

const {
  formatCustomMetricTooltip,
  extractChannelsFromFormula,
  formatCustomMetricValue,
  getCustomReportDetailsFromId,
  getChannelConnectionStatuses,
} = useCustomMetrics();

const router = useRouter();
const route = useRoute();

const props = defineProps({
  mediaItem: { type: Object, default: null },
});

const customMetricsStore = useCustomMetricsStore();
const mediaDetailStore = useMediaDetailStore();
const trackingStore = useTrackingStore();
const authStore = useAuthStore();

onMounted(async () => {
  await customMetricsStore.getCustomMetrics({ resetPagination: true, useLimit: false });
  await mediaDetailStore.getCustomMetricsForMedia(props.mediaItem?.id, props.mediaItem?.brandId);
});

onUnmounted(() => {
  mediaDetailStore.clearCustomMetricsDetails();
});

const filteredCustomMetrics = computed(() => {
  const customMetrics = mediaDetailStore.customMetricsDetail || [];

  const relevantCustomMetrics = customMetrics.filter((customMetric) => {
    const channelsInFormula = extractChannelsFromFormula(customMetric.formula);

    const hasOneChannel = channelsInFormula.length === 1;
    const customMetricChannelMatchesMediaChannel =
      constants.CHANNEL_FROM_FORMULA_TO_MEDIA_ITEM_MAP[channelsInFormula[0]] ===
      props.mediaItem.postType;

    return hasOneChannel && customMetricChannelMatchesMediaChannel;
  });
  return relevantCustomMetrics;
});

function customMetricHasConnectionError(customMetric) {
  const channelsInFormula = extractChannelsFromFormula(customMetric.formula);
  const channelConnectionStatus = getChannelConnectionStatuses(channelsInFormula, [
    props.mediaItem.brandId,
  ]);
  return channelConnectionStatus[props.mediaItem.postType.toLowerCase()];
}

const customMetricsListExistOrPending = computed(
  () =>
    mediaDetailStore.customMetricsDetailRequestStatus === 'pending' ||
    mediaDetailStore.customMetricsDetail?.length ||
    mediaDetailStore.customMetricsNotFound,
);

function redirectToCustomMetricsSettings() {
  trackingStore.track(DASHBOARD_EVENT_NAMES.MANGE_CUSTOM_METRIC_BUTTON_CLICKED, {
    page: SEND_TO_SETTINGS.CUSTOM_METRICS_NAME,
    previousPage: route.name,
  });

  router.push({
    name: SEND_TO_SETTINGS.CUSTOM_METRICS_PAGE,
    params: { brandLabel: authStore.currentBrand?.label },
  });
}

function getCustomMetricFormat(customMetricId) {
  const prefixedCustomMetricId = `CM_${customMetricId}`;
  const metricDetails = getCustomReportDetailsFromId(prefixedCustomMetricId);
  return metricDetails?.metricFormat;
}
</script>

<template>
  <header
    v-if="customMetricsListExistOrPending"
    class="custom-metric-header mt-6 flex items-center justify-between text-[length:var(--x16)] capitalize text-[color:--text-primary]"
  >
    <div class="flex items-center">
      <Icon class="mr-3" height="24" width="24" name="user" />
      <span>{{ constants.CUSTOM_METRIC_MEDIA_POPUP_HEADER_TEXT }}</span>
    </div>
    <a
      class="text-link text-small-medium"
      data-test-id="redirectToSettingsLink"
      @click="redirectToCustomMetricsSettings"
    >
      {{ constants.MANAGE_CUSTOM_METRIC_MEDIA_POPUP_TEXT }}
    </a>
  </header>
  <SkeletonLoader
    :loading="mediaDetailStore.customMetricsDetailRequestStatus === 'pending'"
    :rows="3"
    type="chart"
  >
  </SkeletonLoader>

  <div v-if="filteredCustomMetrics.length" class="custom-metrics-insights-list">
    <ul class="ml-8 pl-1 pt-4">
      <template v-for="(customMetric, key) in filteredCustomMetrics" :key="key">
        <li>
          <span class="pair-row mx-0 my-2 flex w-full justify-between overflow-hidden">
            <span
              class="stat-name flex items-center whitespace-nowrap text-[color:--text-primary]"
              data-cy="data-stat-name"
            >
              <Icon
                v-if="customMetricHasConnectionError(customMetric)"
                v-tooltip="{
                  content: constants.TOOLTIPS.CUSTOM_METRIC_CONTAINS_ERROR,
                  theme: 'dh-tooltip-small',
                }"
                data-test-id="customMeticConnectionErrorIcon"
                class="mb-1 mr-1"
                name="alertTriangle"
                small
                color="var(--error-500)"
              />
              <div class="max-w-32 overflow-x-clip overflow-ellipsis">
                {{ customMetric.name }}
              </div>
              <InfoTooltip
                :tooltip="{ html: true, content: formatCustomMetricTooltip(customMetric) }"
                xsmall
              />
            </span>
            <span class="text-[color:--action-500]">
              {{
                formatCustomMetricValue(
                  customMetric.value,
                  getCustomMetricFormat(customMetric.id),
                  FORMAT_TYPES.LONG,
                )
              }}
            </span>
          </span>
        </li>
      </template>
    </ul>
  </div>
  <span v-else-if="mediaDetailStore.customMetricsNotFound">
    <Icon
      v-tooltip="constants.CUSTOM_METRIC_ERROR_MESSAGES.CUSTOM_METRICS_ERROR_MEDIA_POPUP"
      color="var(--error-500)"
      height="16"
      width="16"
      class="mt-6"
      name="alert-triangle"
    />
  </span>
  <span
    v-else-if="
      !filteredCustomMetrics.length &&
      mediaDetailStore.customMetricsDetailRequestStatus !== 'pending'
    "
  >
    <a class="mt-6 flex text-[color:--text-link]" @click="redirectToCustomMetricsSettings">{{
      constants.CREATE_CUSTOM_METRIC_BUILDER_HEADER_TEXT
    }}</a>
  </span>
</template>

<style scoped lang="postcss">
:deep(.chart-skeleton-container) {
  div.chart-skeleton-chart {
    padding-top: var(--space-16);
    display: block;
    height: auto;
    margin-left: var(--space-32);

    div.chart-skeleton-chart-bar {
      margin: var(--space-16) 0;
      min-height: 22px;
      border-radius: var(--round-corner);
    }
  }
}

.custom-metrics-insights-list {
  ul {
    li {
      .pair-row::before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: '--------------------------------------------------------------------------------';
        color: var(--border);
      }

      .pair-row span + span {
        float: right;
        padding-left: var(--space-8);
        background: var(--background-300);
      }

      .pair-row span:first-child {
        position: absolute;
        background: var(--background-300);
      }
    }
  }
}
</style>
