import { CHANNELS } from '@/models/dashboards/channels.enum';

export const CHANNEL_SELECTOR_ROUTE_MAP = {
  [CHANNELS.INSTAGRAM.value]: {
    value: CHANNELS.INSTAGRAM.value,
    channel: CHANNELS.INSTAGRAM.value,
    to: { name: 'instagram' },
  },
  [CHANNELS.TIKTOK.value]: {
    value: CHANNELS.TIKTOK.value,
    channel: CHANNELS.TIKTOK.value,
    to: { name: 'tiktok' },
  },
  [CHANNELS.FACEBOOK.value]: {
    value: CHANNELS.FACEBOOK.value,
    channel: CHANNELS.FACEBOOK.value,
    to: { name: 'facebook' },
  },
  [CHANNELS.PINTEREST.value]: {
    value: CHANNELS.PINTEREST.value,
    channel: CHANNELS.PINTEREST.value,
    to: { name: 'pinterest' },
  },
  [CHANNELS.TWITTER.value]: {
    value: CHANNELS.TWITTER.value,
    channel: CHANNELS.TWITTER.value,
    to: { name: 'twitter' },
  },
  [CHANNELS.YOUTUBE.value]: {
    value: CHANNELS.YOUTUBE.value,
    channel: CHANNELS.YOUTUBE.value,
    to: { name: 'youtube' },
  },
  [CHANNELS.LINKEDIN.value]: {
    value: CHANNELS.LINKEDIN.value,
    channel: CHANNELS.LINKEDIN.value,
    to: { name: 'linkedin' },
  },
  [CHANNELS.THREADS.value]: {
    value: CHANNELS.THREADS.value,
    channel: CHANNELS.THREADS.value,
    to: { name: 'threads' },
  },
};

export const MIXPANEL_VALUES = {
  UTILITY_MENU: {
    ROOT: 'utility menu',
    SETTINGS: 'Settings',
    BILLING: 'Billing and Plan',
    RESOURCE_CENTER: 'Resource Center',
    LOGOUT: 'Log Out',
    SWITCH_BRAND: 'Brand Switched',
    SEARCH_BRAND: 'Typed in Search Bar',
  },
  NOTIFICATIONS: {
    ROOT: 'notifications panel',
  },
};
