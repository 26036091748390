<template>
  <SchedulePostPopupWrapper
    :close="close"
    :close-confirmation="confirmation"
    :post="post"
    :show-post-editor-side-panel="showPostEditorSidePanel"
    :linked-comment-id="linkedCommentId"
    :avatar="avatarUrl"
    :display-name="handle"
    :show-media-placeholder-message="mediaList.length === 0"
    :media-error-message="mediaUploadError"
    :has-unsaved-changes="hasUnsavedChanges"
    platform="pinterest"
    class="pinterest-scheduling"
    data-cy="EditPinterestPost"
  >
    <template v-if="!successfulPublished" #header>
      <div :class="'header-button'">
        <a @click="switchToBulk">
          Switch to
          <span>Bulk Scheduling</span>
        </a>
        <Icon
          v-tooltip="switchBulkToolTip"
          :hover-color="colours.ACTION.ACTION_500"
          name="info"
          xsmall
        />
      </div>
    </template>
    <template #media>
      <MediaManager
        :disable-editing="disableEditing"
        :display-error-message="onMediaUploadError"
        :media-list="mediaList"
        :media-selected="mediaSelected"
        :download-media-clicked="downloadMediaClicked"
        :viewer-component="viewerComponent"
        :viewer-component-props="{
          aspectRatio,
          platform: 'pinterest',
          validationError,
          mediaWarningIds,
          thumbOffset: thumbOffset,
        }"
        :on-upload-status-change="uploadStatusChanged"
        :single-select-media="true"
        :can-be-published-at="scheduledTime"
        disallow-past-publish-dates
        disallow-incompatible-publish-dates
        :publish-dates-must-overlap-with="canPublishWithin"
        :vision-usage-info="visionUsageInfo"
        @reset-thumb-details="resetThumbnail"
      />
      <VideoPredictionThumbnails
        v-if="displayKeyframes"
        v-model="thumbOffset"
        :media="mediaList[0]"
        :is-processing="mediaList[0].isProcessing"
        :video-predictions="mediaList[0].videoPredictions"
        :suggested-thumbnails="mediaList[0].videoSuggestedThumbnailList"
        :prediction-interval="mediaList[0].videoPredictionInterval"
        @offset-update="handleOffset"
      />
      <Banner
        v-if="validationError"
        :alert-type="validationError.level"
        :custom-icon="validationError.icon"
        class="validation-banner"
        hide-default-icon
      >
        {{ validationError.message }}
      </Banner>
      <Banner
        v-if="publishDateRangeWarning"
        :alert-type="publishDateRangeWarning.level"
        class="validation-banner"
      >
        {{ publishDateRangeWarning.message }}
      </Banner>
    </template>

    <template #details>
      <template v-if="currentScreen === 'index'">
        <PostStatusBox :post="{ ...currentPost, platform: 'pinterest' }" />
        <section>
          <PublishOptionHeader
            :disabled="isDraftToggleDisabled"
            :is-on="isDraft"
            :post="post"
            @drafts-toggled="(draft) => onToggleDraft(draft)"
          />
          <AutoPublishOptions
            v-model="autoPublish"
            :disabled="disableEditing"
            :disable-auto-publish="disableAutoPublish"
            platform="pinterest"
            :post="post"
          />
          <PostSettingItem
            :input-tooltip="scheduleTimeTooltip"
            data-cy="ScheduleTimePostSettingItem"
            :disabled="disableEditing || !isAppConnected || approvedPublishingDatesExpired"
            :title="scheduledTimeLabel"
            :clear-action="resetTime"
            :on-click="() => switchSubScreen('schedule')"
            :error-message="scheduledTimeError"
            empty="Schedule Date & Time"
            left-icon="calendar"
          />
          <QuickSelectCalendar
            v-if="isAppConnected && !approvedPublishingDatesExpired"
            ref="quickSelectCalendar"
            :class="{ 'pt-4': publishDateError }"
            :model-value="scheduledTime"
            :restrict-selection-to-interval="canPublishWithin"
            :platform="platformLabels.pinterest"
            @update:model-value="timeSelected"
          />
        </section>
        <PostSettingItem
          v-if="hasApprovalRequestsAccess"
          :title="getApprovalRequestTitle(approvalRequests)"
          :on-click="
            () =>
              schedulerStore.toggleSidePanel({
                router: $router,
                platform: 'pinterest',
                post,
              })
          "
          data-cy="approval-request-setting-item"
          empty="Add Reviewers"
          left-icon="chatBubbleSquareCheck"
          label="Approval Request"
          :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
        />
        <section class="board-selector">
          <p class="section-title">
            Pinterest Board
            <span class="required-asterisk">*</span>
          </p>
          <PinterestBoardSelector
            v-model="selectedBoard"
            v-tooltip="boardTooltip"
            :disabled="boardDisabled"
          />
        </section>
        <section class="input-row">
          <p class="section-title">
            Pin Destination Link
            <Icon
              v-tooltip="toolTips.pinterestDestinationLink"
              :hover-color="colours.ACTION.ACTION_500"
              class="section-title-tooltip"
              name="info"
              xsmall
            />
          </p>
          <div
            v-tooltip="linkDisabledToolTip"
            data-test="websiteInput"
            class="website-input input"
            :class="{
              disabled: disableLink || disableEditing,
              'invalid-highlight': websiteHasErrors,
            }"
          >
            <input
              v-model="website"
              :disabled="disableLink || disableEditing"
              placeholder="Add URL to this Pin"
            />
            <button :class="hideClearButton" class="clear-link" @click="clearLink">
              <Icon name="closeCircleSolid" small />
            </button>
            <button
              v-tooltip="'Choose from products'"
              :disabled="disableLink || disableEditing"
              class="link-option"
              @click="openProductPopup"
            >
              <Icon name="windowLink" />
            </button>
          </div>
          <p v-if="websiteHasErrors" class="link-error">
            {{ firstWebsiteError }}
          </p>
          <Button :disabled="!website" class="w-fit" link @click="toggleUtmPanel">{{
            utmScheduleOpenEditTrackingButton
          }}</Button>
        </section>
        <section class="title-input">
          <p class="section-title flex-layout">
            <span>
              Title
              <span class="required-asterisk">*</span>
            </span>
            <span class="caption-indicator">{{ titleLimitIndicator }}</span>
          </p>
          <input
            v-model="postTitle"
            data-test="titleInput"
            :disabled="disableEditing"
            :class="{ warning: postTitle.length > pinterestCaptionLimit.title }"
            class="compact-input"
            data-cy="pin-input-title"
            placeholder="Enter title"
          />
        </section>
        <section id="description">
          <p class="section-title flex-layout">
            Description
            <span class="caption-indicator">{{ descriptionLimitIndicator }}</span>
          </p>
          <Textarea
            v-model="description"
            :disabled="disableEditing"
            :class="{ warning: description.length > pinterestCaptionLimit.description }"
            placeholder="Enter description"
          />
        </section>
        <hr />
        <PostSettingItem
          :disabled="disableEditing"
          :title="addToLabel"
          :clear-action="resetAddTo"
          empty="After Published, Add to"
          left-icon="folderAdd"
          @click="switchSubScreen('addTo')"
        />
        <Controls class="controls">
          <Button
            v-if="!disableEditing && post && post.id"
            v-tooltip="'Remove from Scheduler'"
            remove
            class="remove"
            data-cy="remove-pin-button"
            @click="onRemoveClick"
          >
            Remove
          </Button>
          <SplitButton
            v-if="!disableEditing"
            :tooltip="saveTooltip"
            :disabled="saveDisabled"
            :loading="creatingThumbnailUrl || isSaving"
            :options="saveOptions"
            class="save-button"
            primary
            data-cy="save-pin-dropdown-button"
            @click="save"
          >
            Save
          </SplitButton>
          <DropdownButton
            v-else
            :dropdown-list="duplicatePostOptions"
            button-classes="primary"
            items-center
            data-cy="duplicate-post-button"
          >
            <template #buttonContent>Duplicate Post</template>
          </DropdownButton>
        </Controls>
      </template>

      <!-- Select post time subscreen -->
      <SubScreen
        v-if="currentScreen === 'schedule'"
        title="Choose Date & Time"
        @on-back="switchSubScreen('index')"
      >
        <template #content>
          <div>
            <Calendar
              :posts="schedulerStore.getPosts(brandPinterestPosts)"
              :time-selected="timeSelected"
              :on-fetch-posts="onCalendarFetch"
              :restrict-selection-to-interval="canPublishWithin"
              :ideal-post-times="bestTimesToPost"
              platform="pinterest"
              type="compact"
            />
          </div>
        </template>
      </SubScreen>

      <!-- Select board to add to subscreen -->
      <AddToGallery
        v-if="currentScreen === 'addTo'"
        :selected-galleries="selectedGalleries"
        :on-galleries-selected="galleriesSelected"
        :on-back="() => switchSubScreen('index')"
        type="PINTEREST"
      />
    </template>
    <ProductPopup v-if="showProductPopup" @on-change="updateLink" @close="closeProductPopup" />
    <teleport v-if="utmSettings !== null && showUtmPanel" to="#SchedulerRightSidePanel">
      <UtmEditorPanel
        class="w-[20rem]"
        :url="website"
        :channel="utmChannel"
        :brand-channel-utm-settings="utmSettings"
        :tracking-data="trackingData"
        @close="toggleUtmPanel"
        @tracking-parameters-updated="setUtmLink"
      />
    </teleport>
  </SchedulePostPopupWrapper>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useProductStore } from '@/stores/product';
import { useIdentityStore } from '@/stores/identity';
import {
  discardCommentConfirmMessage,
  discardConfirmMessage,
  richPinValidatorUrl,
  toolTips,
  debounceInputDelayShort,
} from '@/config';
import { env } from '@/env';
import { colours } from '@/ux/colours';
import validateRules from '@/utils/ruleValidation';
import {
  mixpanelActions,
  postStatus,
  popUpMessages,
  pinterestCaptionLimit,
  pinterestCarouselPublishing,
  somethingWentWrongMessage,
  somethingWentWrongTitle,
  thumbnailSourceType,
  PLATFORMS,
  platformLabels,
} from '@/app/scheduler/constants';
import {
  pinBoardAndTitleCheck,
  shouldDisplayKeyFrames,
  validatePinterestMedia,
  ThumbnailUrlGenerator,
  validateRecommendedMediaSize,
  videoPredictionMixpanelEvent,
  shouldClearVideoThumbnail,
  checkUnsavedChanges,
  hasOriginalConverted,
  allMediaUploaded,
  getUnscheduledRouteName,
} from '@/app/scheduler/utils';
import { getConflictingMediaIds } from '@/app/scheduler/utils/publish-dates';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import Textarea from '@/components/Textarea.vue';
import SplitButton from '@/components/foundation/SplitButton.vue';
import SchedulePostPopupWrapper from '@/app/scheduler/components/EditPost/Layout/SchedulePostPopupWrapper.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import VideoPredictionThumbnails from '@/app/scheduler/components/EditPost/MediaViewer/VideoPredictionThumbnails.vue';
import { useCommentingStore } from '@/stores/commenting';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { useSchedulerStore } from '@/stores/scheduler';
import { usePlatformStore } from '@/stores/platform';
import { useMediaLinksStore } from '@/stores/media-links';
import { useMediaStore } from '@/stores/media';
import { useFlagStore } from '@/stores/flag';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import {
  mixpanelPublishTypes,
  postTypeLabelMap,
  SchedulerUserEventTracker,
  trackSchedulerUtmPopout,
} from '@/app/scheduler/mixpanel';
import UtmEditorPanel from '@/app/settings/components/Utm/UtmEditorPanel.vue';
import { LibraryAPI } from '@/apis';
import { utmChannel, utmScheduleOpenEditTrackingButton } from '@/app/settings/components/Utm/const';
import { fetchUtmSettings } from '@/app/settings/components/Utm/utils';
import { logger } from '@/utils/logger';
import { useImpersonatorStore } from '@/stores/impersonator';
import { USER } from '@/models/auth/permissions.enum';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import isEqual from 'lodash/isEqual';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import { refreshBestTimesToPost } from '@/app/scheduler/utils/bestTimesToPost';
import PinterestBoardSelector from '@/app/scheduler/components/EditPost/PinterestBoardSelector.vue';
import PostSettingItem from '@/app/scheduler/components/PostSettingItem.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import CarouselViewer from '@/app/scheduler/components/EditPost/MediaViewer/CarouselViewer.vue';
import MediaManager from '@/app/scheduler/components/EditPost/MediaViewer/MediaManager.vue';
import Controls from '@/app/scheduler/components/EditPost/Layout/Controls.vue';
import AddToGallery from '@/app/scheduler/components/EditPost/AddToGallery.vue';
import PostStatusBox from '@/app/scheduler/components/PostStatusBox.vue';
import AutoPublishOptions from '@/app/scheduler/components/AutoPublishOptions.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EditPinterestPost',
  components: {
    PublishOptionHeader,
    Banner,
    AddToGallery,
    AutoPublishOptions,
    Button,
    Calendar: defineAsyncComponent(() => import('@/app/scheduler/components/Calendar.vue')),
    Controls,
    DropdownButton,
    Icon,
    MediaManager,
    PinterestBoardSelector,
    PostSettingItem,
    PostStatusBox,
    ProductPopup: defineAsyncComponent(
      () => import('@/app/library/components/MediaPopup/ProductPopup.vue'),
    ),
    QuickSelectCalendar: defineAsyncComponent(
      () => import('@/app/scheduler/components/QuickSelectCalendar.vue'),
    ),
    SchedulePostPopupWrapper,
    SplitButton,
    VideoPredictionThumbnails,
    SubScreen,
    Textarea,
    UtmEditorPanel,
  },
  mixins: [PostDuplicationMixin, ApprovedPublishingDatesMixin],
  props: {
    close: { type: Function, required: true },
    context: { type: String, default: null },
    disableEditing: { type: Boolean, default: false },
    changeOnPopup: { type: Boolean, default: false },
    media: { type: Array, default: null },
    mediaCaption: { type: String, default: null },
    post: { type: Object, default: null },
    showPostEditorSidePanel: { type: Boolean, default: false },
    linkedCommentId: { type: Number, default: null },
  },
  emits: ['onEditProduct'],
  data() {
    return {
      autoPublish: this.post?.auto_publish ?? false,
      creatingThumbnailUrl: false,
      currentScreen: 'index',
      dataCheckEnabled: false,
      description: this.mediaCaption || this.post?.note || '',
      greyIcon: colours.ICON.ICON_PRIMARY,
      isSaving: false,
      isValidating: false,
      mediaList: [],
      mediaUploadError: null,
      mediaValidationError: null,
      openItem: null,
      pinterestCaptionLimit,
      postTitle: this.post?.meta?.title ?? '',
      scheduledTime: this.post?.timestamp && dayjs(this.post.timestamp),
      selectedBoard: this.post?.meta?.pinterestBoard,
      selectedGalleries: [
        ...(this.post?.campaigns?.map((campaignObj) => ({ ...campaignObj, campaign: true })) ?? []),
        ...(this.post?.boards ?? []),
      ],
      showProductPopup: false,
      showRemoveConfirm: false,
      successfulPublished: this.post?.status === postStatus.IMPORTED,
      switchBulkToolTip:
        'Bulk scheduling allows you to schedule & publish up to 10 pins at a time.',
      thumbnailChanged: false,
      thumbnailError: false,
      thumbnailUrl: this.post?.thumbnail_url ?? null,
      thumbnailUrlGenerator: new ThumbnailUrlGenerator(),
      toolTips,
      uploadPending: false,
      viewerComponent: CarouselViewer,
      website: this.post?.link ?? '',
      websiteErrors: [],
      hasUnsavedChanges: false,
      utmChannel: utmChannel.PINTEREST,
      showUtmPanel: false,
      utmSettings: null,
      utmScheduleOpenEditTrackingButton,
      selectedProductId: null,
      urlLinkCustomized: false,
      approvalRequests: (this.post && this.post.approvalRequests) || [],
      isDraft: (!this.post?.id && !this.post?.timestamp) || this.post?.status === postStatus.DRAFT,
      thumbOffset: this.post?.thumbOffset || null,
      thumbnailSource: null,
    };
  },
  computed: {
    ...mapStores(
      useCustomerJourneyStore,
      usePlatformStore,
      useMediaLinksStore,
      useMediaStore,
      useFlagStore,
      useProductStore,
      useSchedulerStore,
      useCommentingStore,
      useNotificationStore,
      useSchedulerPinterestStore,
      useAuthStore,
      useTrackingStore,
      useImpersonatorStore,
      useIdentityStore,
    ),
    hasApprovalRequestsAccess() {
      return !this.customerJourneyStore.hasPlanGrow;
    },
    validationError() {
      if ([postStatus.POSTED, postStatus.IMPORTED].includes(this.post?.status)) {
        return null;
      }

      return this.publishDateError || this.mediaValidationError;
    },
    mediaWarningIds() {
      if (this.publishDateError?.code === 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES') {
        return getConflictingMediaIds(this.mediaList, this.scheduledTime);
      }
      return [];
    },
    colours() {
      return colours;
    },
    brandPinterestPosts() {
      return allPosts.forBrand(this.authStore.currentBrand.id).forPlatform(PLATFORMS.PINTEREST);
    },
    currentPost() {
      return this.post
        ? this.post
        : {
            // NOTE: temporary post, add fields you need to check onPostStatusBox.
            media: this.mediaList,
          };
    },
    connection() {
      const flagStore = useFlagStore();
      if (flagStore.ready && flagStore.flags['pinterest-migration']) {
        return this.platformStore.platformConnectionsMap.pinterest_v5?.[
          this.authStore.currentBrand?.id
        ];
      }
      return this.platformStore.platformConnectionsMap.pinterest?.[this.authStore.currentBrand?.id];
    },
    isAppConnected() {
      return this.connection?.status === 'connected';
    },
    scheduleTimeTooltip() {
      if (!this.isAppConnected) {
        return toolTips.noPinterestConnection;
      }
      return '';
    },
    descriptionLimitIndicator() {
      const { description } = this;
      if (description && description.length > pinterestCaptionLimit.description) {
        return pinterestCaptionLimit.description - description.length;
      }
      return '';
    },
    titleLimitIndicator() {
      const { postTitle } = this;
      if (postTitle && postTitle.length > pinterestCaptionLimit.title) {
        return pinterestCaptionLimit.title - postTitle.length;
      }
      return '';
    },
    duplicatePostOptions() {
      return this.pdDuplicationOptions(this.duplicate);
    },
    saveOptions() {
      return [
        {
          text: 'Save & Duplicate',
          subList: this.pdDuplicationOptions(this.saveAndDuplicate),
        },
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.saveDropdownDisabled.isDisabled,
          tooltip: this.saveDropdownDisabled.tip,
        },
        {
          text: 'Publish Now & Duplicate',
          disabled: this.saveDropdownDisabled.isDisabled,
          tooltip: this.saveDropdownDisabled.tip,
          subList: this.pdDuplicationOptions(this.publishNowAndDuplicate),
        },
      ];
    },
    saveDropdownDisabled() {
      const userPermission = this.authStore.user_can('scheduler', 'can_auto_publish_pinterest');
      if (!userPermission || this.impersonatorStore.isImpersonating) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (this.mediaList.length === 0) {
        return { isDisabled: true, tip: toolTips.noMediaSelectedAutoPublish };
      }
      if (this.mediaList.length > 0 && !this.selectedBoard) {
        return { isDisabled: true, tip: 'Please select a Pinterest Board to publish this pin.' };
      }
      if (!this.autoPublish) {
        return { isDisabled: true, tip: toolTips.notAutoPublish };
      }
      if (!this.postTitle) {
        return { isDisabled: true, tip: 'Please enter a title to publish this pin.' };
      }
      if (!this.canAssetPublishToday) {
        return { isDisabled: true, tip: this.publishNowWarning };
      }
      if (this.requiresApproval(this.post)) {
        return { isDisabled: true, tip: toolTips.requiresApproval };
      }
      return { isDisabled: false, tip: '' };
    },
    selectedTimeInvalid() {
      return this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local();
    },
    avatarUrl() {
      if (this.platformStore.pinterestAccount) {
        return this.platformStore.pinterestAccount.avatarUrl;
      }
      if (this.authStore.currentBrand) {
        return this.authStore.currentBrand.avatarUrl;
      }
      return '';
    },
    handle() {
      if (this.platformStore.pinterestAccount) {
        return this.platformStore.pinterestAccount.pinterestUsername;
      }
      if (this.authStore.currentBrand) {
        return this.authStore.currentBrand.name;
      }
      return '';
    },
    linkDisabledToolTip() {
      if (this.disableLink && !this.disableEditing) {
        return 'You must add media to your post before adding product links';
      }
      return '';
    },
    disableLink() {
      return this.mediaList.length === 0;
    },
    aspectRatio() {
      return 'portrait';
    },
    hideClearButton() {
      return { hide: !this.website };
    },
    timestampAddedOrRemoved() {
      return (
        (!this.scheduledTime && this.post?.timestamp) ||
        (!this.post.timestamp && this.scheduledTime)
      );
    },
    timestampUpdated() {
      if (this.post) {
        if (this.scheduledTime === null) {
          // if doesn't have scheduledTime, check if timestamp in saved post is also null
          return this.post.timestamp !== null;
        }
        // if there is a scheduledTime (dayjs object), use isSame to compare
        return !this.scheduledTime.isSame(this.post.timestamp);
      }
      // if no saved timestamp, scheduledTime should be null,
      // otherwise timestamp has been updated.
      return this.scheduledTime !== null;
    },
    confirmation() {
      if (this.hasUnsavedChanges) {
        return discardConfirmMessage;
      }
      if (this.commentingStore?.hasUnsavedComments) {
        return discardCommentConfirmMessage;
      }
      return null;
    },
    title() {
      if (this.disableEditing) {
        return 'Published Pinterest Pin';
      }
      if (this.post?.id) {
        return 'Edit Pinterest Pin';
      }
      return 'Add Pinterest Pin';
    },
    addToLabel() {
      if (this.selectedGalleries.length) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return null;
    },
    scheduledTimeLabel() {
      return this.scheduledTime ? this.scheduledTime.format('LT, ll') : null;
    },
    richPinTip() {
      return toolTips.richPin;
    },
    boardDisabled() {
      return this.disableEditing || !this.isAppConnected;
    },
    saveDisabled() {
      return !!(
        this.websiteHasErrors ||
        this.isSaving ||
        this.titleLimitIndicator ||
        this.descriptionLimitIndicator ||
        this.uploadPending ||
        this.creatingThumbnailUrl ||
        this.selectedTimeInvalid ||
        (this.autoPublish && this.scheduledTimeLabel && (!this.selectedBoard || !this.postTitle)) ||
        ((this.mediaList.length !== 1 || !this.selectedBoard) &&
          this.autoPublish &&
          this.scheduledTime) ||
        this.isValidating ||
        this.mediaValidationError?.level === 'error' ||
        (this.autoPublish && !this.isAppConnected) ||
        !!this.publishDateError
      );
    },
    boardTooltip() {
      if (!this.isAppConnected) {
        return toolTips.boardNeedsAccount;
      }
      return '';
    },
    saveTooltip() {
      if (this.websiteHasErrors) {
        return this.firstWebsiteError;
      }
      if (this.selectedTimeInvalid) {
        return toolTips.illegalTimeStamp;
      }
      if (this.titleLimitIndicator) {
        return toolTips.maxPinTitleCharactersReached;
      }
      if (this.descriptionLimitIndicator) {
        return toolTips.maxPinDescriptionCharactersReached;
      }
      if (this.autoPublish && !this.isAppConnected) {
        return toolTips.invalidConnection;
      }
      if (this.uploadPending) {
        return { content: 'Your media is uploading...' };
      }
      if (this.autoPublish && this.scheduledTimeLabel) {
        if (this.mediaList.length !== 1) {
          return {
            content: 'Please select one image to auto publish this pin.',
          };
        }

        const errMsg = pinBoardAndTitleCheck(!this.selectedBoard, !this.postTitle);
        if (errMsg) {
          return {
            content: errMsg,
          };
        }
      }
      if (this.mediaList.some((m) => !m.isProcessing && !hasOriginalConverted(m))) {
        return { content: toolTips.mediaConversionError };
      }
      return '';
    },
    displayKeyframes() {
      return shouldDisplayKeyFrames(this.mediaList, this.autoPublish, this.post);
    },
    postData() {
      return {
        platform: PLATFORMS.PINTEREST,
        id: this.post?.id,
        auto_publish: this.autoPublish,
        brand_id: this.authStore.currentBrand?.id,
        board_ids: this.selectedBoardIds,
        campaign_ids: this.selectedCampaignIds,
        link: this.website,
        media: this.mediaList,
        media_ids: this.mediaList.map((m) => m.id),
        meta: {
          pinterestBoard: this.selectedBoard,
          title: this.postTitle,
          thumb_offset: parseInt(this.thumbOffset, 10) >= 0 ? this.thumbOffset * 1000 : null,
          thumbnail_url: this.thumbnailUrl,
        },
        note: this.description,
        timestamp: this.scheduledTime && this.scheduledTime.toISOString(),
        custom_utms: this.urlLinkCustomized,
        approvalRequests: this.approvalRequests,
        ...(this.isDraft && this.flagStore.ready && this.flagStore.flags.schedulerDrafts
          ? { status: postStatus.DRAFT }
          : {}),
        ...(this.post?.approvalPolicy ? { approval_policy: this.post.approvalPolicy } : {}),
      };
    },
    selectedBoardIds() {
      return this.selectedGalleries.filter((g) => !g.campaign).map((g) => g.id);
    },
    selectedCampaignIds() {
      return this.selectedGalleries.filter((g) => g.campaign).map((g) => g.id);
    },
    duplicatePostData() {
      return {
        ...this.postData,
        boards: this.selectedGalleries.filter((g) => !g.campaign),
        campaigns: this.selectedGalleries.filter((g) => g.campaign),
      };
    },
    websiteRules() {
      return [
        (value) => !value || value.length <= 2000 || toolTips.invalidLinkLength,
        async (value) => {
          if (value) {
            try {
              const response = await this.productStore.getUrlMetadata({ url: value });
              if (response.data) {
                if (this.postTitle.trim().length === 0) {
                  this.postTitle = response.data.title ?? '';
                }
                if (this.description.trim().length === 0) {
                  this.description = response.data.description || '';
                }
              }
            } catch (error) {
              return toolTips.invalidLinkURL;
            }
          }
          return true;
        },
        (value) => {
          return (
            !value ||
            !env.pinterestDestLinkDenyList.some((denyHost) => {
              try {
                const urlObj = new URL(value);
                return urlObj.host.toLowerCase() === denyHost;
              } catch (e) {
                return false;
              }
            }) ||
            toolTips.invalidLinkURLShortener
          );
        },
      ];
    },
    websiteHasErrors() {
      return this.websiteErrors?.length > 0;
    },
    firstWebsiteError() {
      return this.websiteErrors?.[0] || '';
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
    trackingData() {
      return {
        postId: this.post?.id,
        postPlatform: 'Pinterest',
        postType: 'Single Pin',
      };
    },
    platformLabels() {
      return platformLabels;
    },
    visionUsageInfo() {
      const usageInfo = this.identityStore.guard(USER.VISION.CAN_ACCESS_VISION)
        ? toolTips.enableVision
        : toolTips.promoteVision;
      return !this.autoPublish && this.mediaList[0]?.type === 'VIDEO' ? usageInfo : null;
    },
    disableAutoPublish() {
      return {
        isDisabled: !allMediaUploaded(this.mediaList),
        tip: toolTips.noVideoSelectedAutoPublish,
      };
    },
    isDraftToggleDisabled() {
      const isPublished = [
        postStatus.POSTED,
        postStatus.AUTOPUBLISHING,
        postStatus.IMPORTED,
      ].includes(this.post?.status);
      return isPublished || !this.postData?.timestamp;
    },
    bestTimesToPost() {
      return this.schedulerStore.recommendedPublishTimes?.[this.authStore.currentBrand.id]?.[
        PLATFORMS.PINTEREST
      ];
    },
    postApprovalRequests() {
      return (
        this.schedulerStore.approvalRequests?.[this.authStore.currentBrand.id]?.[
          PLATFORMS.PINTEREST
        ] || []
      );
    },
  },
  watch: {
    async showUtmPanel(value) {
      if (!value) return;
      if (this.showPostEditorSidePanel) this.closePostEditorSidePanel();
      if (this.utmSettings === null) {
        this.utmSettings = await this.getUtmSettings();
      }
    },
    showPostEditorSidePanel(value) {
      if (value && this.showUtmPanel) {
        this.showUtmPanel = false;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: 'close',
        });
      }
    },
    website: debounce(function debouncedUrlValidation(link) {
      let validateWebsite = true;
      if (link) {
        const hasHeader = link.indexOf('http://') > -1 || link.indexOf('https://') > -1;
        if (link.length > 7 && !hasHeader) {
          this.website = `https://${link}`;
          validateWebsite = false;
        }
      }
      const showPanel = Boolean(link);
      if (this.showUtmPanel !== showPanel) {
        this.showUtmPanel = showPanel;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: showPanel ? 'auto open' : 'auto close',
        });
      }
      if (validateWebsite) this.validateWebsite();
    }, debounceInputDelayShort),
    autoPublish(newVal) {
      // Clear thumbnail selection if auto publish is disabled
      if (!newVal) {
        this.thumbOffset = 0;
        this.thumbnailSource = null;
      }
    },
    thumbOffset: {
      handler(newVal) {
        // sets thumbnail changed to true, every time the pop up is opened
        // prevent this if value is same as post
        // eslint-disable-next-line camelcase
        if (
          this.mediaList[0]?.type === 'VIDEO' &&
          this.post?.meta?.thumb_offset !== newVal * 1000
        ) {
          this.thumbnailChanged = true;
          this.thumbnailUrl = null;
        }
      },
    },
    postApprovalRequests: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) this.approvalRequests = newVal;
      },
    },
    mediaList: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.validateMedia();
        } else {
          this.website = '';
          this.clearMediaValidation();
        }
      },
    },
    'mediaLinksStore.mediaLinks': function mediaLinksWatcher(to) {
      if (to && to.length > 0) {
        if (!this.website) {
          this.website = to[0].url;
        }
        if (!this.postTitle) {
          this.postTitle = to[0].title || '';
        }
        if (!this.description) {
          this.description = to[0].description || '';
        }
      }
    },
    postData(to, from) {
      let fieldChanged = checkUnsavedChanges(this.postData, this.post, this.scheduledTime);
      if (this.post === null) {
        fieldChanged = checkUnsavedChanges(to, from, this.scheduledTime);
      }
      if (!to?.timestamp) this.isDraft = true;
      this.hasUnsavedChanges = !this.disableEditing && (fieldChanged || this.uploadPending);
    },
  },
  async created() {
    // Fetch best times to post if they're not already in the store
    refreshBestTimesToPost(this.authStore.currentBrand.id, PLATFORMS.PINTEREST);

    // Media can either come from the media prop (e.g. scheduling a post from the library)
    if (this.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand?.id,
        mediaArrList: this.media,
      });
      this.mediaLinksStore.listMediaLinks({
        brandId: this.authStore.currentBrand?.id,
        mediaId: this.mediaList[0].id,
      });
    }
    // ... or an existing scheduled post object (e.g. editing a scheduled post).
    else if (this.post?.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand?.id,
        mediaArrList: this.post.media,
      });
    }

    this.validateMedia();
    this.showUtmPanel = !!this.post?.link;
    if (this.showUtmPanel) {
      trackSchedulerUtmPopout({
        ...this.trackingData,
        action: 'auto open',
      });
    }
  },
  mounted() {
    // set thumbOffset if it exists
    // need to do this before any awaits so it's set for children
    if (Number.isInteger(this.post?.meta?.thumb_offset)) {
      this.thumbOffset = this.post.meta.thumb_offset / 1000;
      if (this.post?.media?.length > 0) {
        this.duration = this.post.media[0].duration;
      }
    }
  },
  beforeUnmount() {
    this.thumbnailUrlGenerator.destroy();
    this.schedulerStore.resetCarouselPage();
  },
  methods: {
    getApprovalRequestTitle,
    getApprovalChipText,
    async getUtmSettings() {
      return fetchUtmSettings({
        brandId: this.authStore.currentBrand.id,
        channel: this.utmChannel,
      });
    },
    closePostEditorSidePanel() {
      this.$router.push({
        name: 'scheduler.pinterest.posts',
        params: { id: this.post?.id, post: this.post },
        query: { replaceModal: true },
      });
    },
    setUtmLink(newUrl, trackingParameters, urlLinkCustomized) {
      this.website = newUrl;
      this.urlLinkCustomized = urlLinkCustomized;
    },
    async publishNow(closeAfter = true) {
      await this.save(true, closeAfter);
    },
    async publishNowAndDuplicate({ toType }) {
      // Data must be set before post is saved to avoid some computed fields from being cleared when popup is closed
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.PINTEREST_PIN,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.publishNow(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.PINTEREST_PIN,
        post: duplicateData,
      });
    },
    async saveAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.PINTEREST_PIN,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.save(false, false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.PINTEREST_PIN,
        post: duplicateData,
      });
    },
    async duplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.PINTEREST_PIN,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.PINTEREST_PIN,
        post: duplicateData,
      });
    },
    switchToBulk() {
      let pin = null;
      if (this.mediaList.length > 0) {
        pin = {
          linkUrl: this.website,
          title: this.postTitle,
          description: this.description,
          pinterestBoard: this.selectedBoard,
          selectedGalleries: this.selectedGalleries,
          scheduledTime: this.scheduledTime,
          publishType: this.autoPublish ? 'autoPublish' : 'notification',
          thumbOffset: this.thumbOffset,
          ...this.mediaList[0],
        };
      }
      this.schedulerPinterestStore.setSelectedPin({ pin });
      this.$router.push({
        name: 'scheduler.pinterest.posts.new.bulk',
        params: {
          replaceModal: true,
        },
      });
    },
    updateLink(link) {
      const { product } = link;
      this.selectedProductId = product.id;
      this.website = product.url;
      if (!this.postTitle && product.title) {
        this.postTitle = product.title;
      }
      if (!this.description && product.description) {
        this.description = product.description;
      }
    },
    closeProductPopup() {
      this.showProductPopup = false;
    },
    openProductPopup() {
      this.showProductPopup = true;
    },
    editProduct() {
      this.$emit('onEditProduct', this.$.vnode.key);
    },
    clearLink() {
      this.website = '';
    },
    resetAddTo() {
      this.selectedGalleries = [];
    },
    switchSubScreen(screen) {
      if (screen === 'schedule' && !this.isAppConnected) {
        return;
      }
      if (!this.disableEditing) {
        this.currentScreen = screen;
        if (screen === 'schedule') {
          this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
            postPlatform: 'Pinterest',
            quickSelectClicked: false,
          });
        }
      }
    },
    mediaSelected(media) {
      this.mediaList = media;
      if (this.mediaList.length !== 0) {
        this.mediaLinksStore.listMediaLinks({
          brandId: this.authStore.currentBrand?.id,
          mediaId: this.mediaList[0].id,
        });
      }
    },
    uploadStatusChanged(status) {
      this.uploadPending = status;
    },
    timeSelected(time) {
      if (!this.scheduledTime) this.isDraft = false;
      this.scheduledTime = dayjs(time);
      this.switchSubScreen('index');
    },
    resetTime() {
      this.scheduledTime = null;
    },
    galleriesSelected(galleries) {
      this.selectedGalleries = galleries;
    },
    validateRichPin() {
      if (this.website) {
        window.open(richPinValidatorUrl, '_blank');
      }
    },
    onThumbnailError() {
      this.thumbnailError = true;
      const message = somethingWentWrongMessage;
      this.notificationStore.setToast({ message, type: 'error' });
    },
    async onRemoveClick() {
      const { title, message } = popUpMessages.removeFromScheduler;
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.remove,
      });
    },
    async remove() {
      if (this.post) {
        await this.schedulerStore.deletePost(this.post);
        if (this.scheduledTime) {
          this.notificationStore.setToast({ message: 'Pin removed from your timeline.' });
        } else {
          this.notificationStore.setToast({
            message: 'Pin removed from your unscheduled pins.',
          });
        }
        this.close();
      }
    },
    async encodeUtm() {
      if (!this.website) return;

      const requestData = {
        brandId: this.authStore.currentBrand.id,
        content: this.website,
        channel: this.utmChannel,
        mediaId: this.mediaList[0].id,
        shortenUrl: false,
        urls: [this.website],
      };
      if (this.selectedProductId) {
        requestData.productId = this.selectedProductId;
      }
      const utmResponse = await LibraryAPI.encodeUtmSettings(requestData);
      this.website = utmResponse.data.content;
    },
    async save(publishNow, closeAfter = true) {
      try {
        this.dataCheckEnabled = true;
        if (this.saveDisabled || this.schedulerStore.postActionPending) {
          return;
        }
        if (this.publishDateError) {
          return;
        }
        this.isSaving = true;
        try {
          await this.encodeUtm();
        } catch (error) {
          this.notificationStore.setToast({
            message: somethingWentWrongTitle,
            subtext: somethingWentWrongMessage,
            type: 'error',
          });
          logger.error(error);
          return;
        }
        if (this.thumbnailChanged) {
          this.creatingThumbnailUrl = true;
          this.thumbnailUrl = await this.thumbnailUrlGenerator.createThumbnailUrl(
            this.mediaList,
            this.thumbOffset,
            this.onThumbnailError,
          );
          this.creatingThumbnailUrl = false;
          if (this.thumbnailError) {
            this.thumbnailError = false;
            this.isSaving = false;
            return;
          }
        }

        const data = { ...this.postData };

        if (publishNow === true) {
          data.auto_publish = true;
          data.timestamp = dayjs().toISOString();
          data.status = postStatus.AUTOPUBLISHING;
        }

        if (this.post?.id) {
          // update an existing post
          if (shouldClearVideoThumbnail(this.post, data)) {
            data.meta.thumbnail_url = null;
          }
          // Take value before updating post (as it then updates this.post)
          const timestampAddedOrRemoved = this.timestampAddedOrRemoved;
          await this.schedulerStore.updatePost(data);
          if (publishNow === true) {
            this.notificationStore.setToast({
              message: 'Publishing in progress.',
              actionText: 'See it on your Timeline',
              actionTo: { name: 'scheduler.pinterest.timeline' },
              type: 'primary',
            });
          } else if (timestampAddedOrRemoved) {
            // add time to an old post
            if (this.scheduledTime) {
              this.notificationStore.setToast({
                message: 'Pin updated & added to your ',
                actionText: 'timeline.',
                actionTo: { name: 'scheduler.pinterest.timeline' },
                sameLine: true,
              });
            } else {
              // remove time from old post,
              this.notificationStore.setToast({
                message: 'Pin updated & added to your ',
                actionText: 'unscheduled Pins.',
                actionTo: {
                  name: getUnscheduledRouteName(PLATFORMS.PINTEREST),
                },
                sameLine: true,
              });
            }
          } else {
            this.notificationStore.setToast({ message: 'Pin updated.' });
          }
        } else {
          await this.schedulerStore.createPost(data);
          if (publishNow === true) {
            this.notificationStore.setToast({
              message: 'Publishing in progress.',
              actionText: 'See it on your Timeline',
              actionTo: { name: 'scheduler.pinterest.timeline' },
              type: 'primary',
            });
          } else if (this.scheduledTime) {
            this.notificationStore.setToast({
              message: 'Pin added to your',
              actionText: 'timeline.',
              actionTo: { name: 'scheduler.pinterest.timeline' },
              sameLine: true,
              type: 'primary',
            });
          } else {
            this.notificationStore.setToast({
              message: 'Pin added to your ',
              actionText: 'unscheduled Pins.',
              actionTo: {
                name: getUnscheduledRouteName(PLATFORMS.PINTEREST),
              },
              sameLine: true,
            });
          }
        }
        this.handleVideoThumbnailMixpanelEvent();
        if (closeAfter) {
          this.close();
        }
      } catch (error) {
        let message = 'An error occurred while attempting to save your pin. ';
        const data = error?.response?.data;
        if (data?.errors?.json?.link?.length > 0 || data?.errors?.link?.length > 0) {
          if (data.errors.json?.link?.length > 0) {
            data.errors.json.link.forEach((linkError) => {
              this.websiteErrors.push(linkError);
            });
          }

          // To remove when scheduler-backend is updated
          // https://github.com/dashhudson/scheduler-backend/pull/726
          if (data.errors.link?.length > 0) {
            data.errors.link.forEach((linkError) => {
              this.websiteErrors.push(linkError);
            });
          }
        } else {
          if (data?.description) {
            message += `${data.description} `;
          }
          this.notificationStore.setToast({
            message,
            type: 'error',
          });
        }
      } finally {
        this.isSaving = false;
      }
    },
    handleVideoThumbnailMixpanelEvent() {
      if (this.thumbnailSource === thumbnailSourceType.SLIDER) {
        this.trackingStore.track('Pinterest Video Thumbnail Changed');
      } else if (this.thumbnailSource === thumbnailSourceType.VISION) {
        const suggestionEvent = videoPredictionMixpanelEvent(
          this.mediaList[0].videoSuggestedThumbnailList,
          this.thumbOffset,
          this.authStore.currentBrand?.id,
        );
        this.trackingStore.track(suggestionEvent.action, suggestionEvent.properties);
      }
    },
    async onCalendarFetch(start, end) {
      this.schedulerStore.fetchPosts(this.brandPinterestPosts.betweenDates(start, end));
    },
    async validateMedia() {
      if (this.post?.status === postStatus.IMPORTED) {
        return;
      }
      const media = this.mediaList[0];
      let error = null;
      this.isValidating = true;
      if (media) {
        error = await validatePinterestMedia(media, this.authStore.currentBrand?.id);
      }
      if (this.mediaList.length > 1) {
        error = pinterestCarouselPublishing;
      }
      if (error) {
        this.mediaValidationError = { level: 'error', message: error };
      } else {
        this.mediaValidationError = await validateRecommendedMediaSize(media);
      }
      this.isValidating = false;
    },
    handleOffset(offset, source) {
      this.thumbnailSource = source;
      this.thumbOffset = offset;
    },
    onMediaUploadError(errorMessage) {
      this.mediaUploadError = errorMessage?.message ?? errorMessage;
    },
    clearMediaValidation() {
      this.mediaValidationError = null;
    },
    async validateWebsite() {
      this.websiteErrors = await validateRules(this.websiteRules, this.website, true);
    },
    downloadMediaClicked(media) {
      const postTrackingData = {
        postId: this.post.id ?? null,
        postPlatform: postTypeLabelMap.pinterest,
        postPlatformType: null,
        postStatus: this.post?.status ?? null,
        publishType: this.postData.auto_publish
          ? mixpanelPublishTypes.AUTO_PUBLISH
          : mixpanelPublishTypes.SEND_NOTIFICATION,
        timestamp: this.postData.timestamp,
      };

      this.trackMixpanel.downloadMediaFromScheduler(postTrackingData, media);
    },
    toggleUtmPanel() {
      const action = this.showUtmPanel ? 'close' : 'open';
      this.showUtmPanel = !this.showUtmPanel;
      trackSchedulerUtmPopout({
        ...this.trackingData,
        action,
      });
    },
    requiresApproval(post) {
      return postRequiresApproval({ post });
    },
    onToggleDraft(draft) {
      this.isDraft = draft;
      this.trackMixpanel.draftToggleClicked(draft, postTypeLabelMap.pinterest);
    },
    resetThumbnail() {
      this.thumbOffset = null;
      this.thumbnailSource = null;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.controls {
  position: sticky;
  bottom: 0;
}

.save-button {
  min-width: 10rem;
}

.error-message {
  margin-top: 0.5rem;
  text-align: left;
  width: 100%;
  padding: var(--space-8) var(--space-16);
  background-color: var(--error-100);
  color: var(--error-500);
  font-size: var(--x13);
  border-radius: var(--round-corner-small);
}

.disabled {
  input,
  svg,
  button {
    cursor: not-allowed !important;
  }
}

.header-area {
  display: flex;

  .header-button {
    display: flex;
    position: absolute;
    align-items: center;
    top: 1.5rem;
    right: var(--space-48);
    padding-right: var(--space-32);

    a {
      font-weight: 400;
      padding-right: var(--space-8);
      font-size: var(--x14);
    }

    a:hover {
      color: var(--action-500);
    }

    span {
      font-weight: 600;
    }

    .svg-icon {
      box-sizing: content-box;
      padding-top: 0.25rem;
      cursor: help;
      margin-top: -4px;
    }
  }
}

.input {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  border: 1px solid var(--border);
  border-radius: var(--round-corner-small);

  input {
    width: 100%;
    height: var(--space-40);
    line-height: var(--space-32);
    border: 0;
    border-radius: var(--round-corner-small) 0 0 var(--round-corner-small);
    background: var(--background-0);
    color: var(--text-primary);
    font-size: var(--x14);
    padding: 0 var(--space-8);
    text-overflow: ellipsis;
  }

  .link-option {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 var(--round-corner-small) var(--round-corner-small) 0;
    margin: 0;
    border-left: 1px solid var(--border);
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
  }

  .clear-link {
    border: 0;
    margin: 0;
  }
}

.edit-container {
  overflow-y: auto;
  border-bottom-left-radius: var(--round-corner);
  height: 100%;

  .container-wrapper {
    display: flex;
    min-height: 100%;
  }

  input:focus {
    border-color: var(--action-500);
  }

  .select {
    margin: 0;
  }

  .validation-banner {
    margin: var(--space-16) 0 0 0;
  }
}

.input-row {
  margin: 0;
  position: relative;

  .link-error {
    color: var(--error-500);
    font-size: var(--x12);
  }

  button {
    justify-content: center;
    align-items: center;
    width: var(--space-40);
    height: var(--space-40);
    margin-left: var(--space-8);
    background: var(--background-0);
    cursor: pointer;

    &:hover {
      color: var(--action-500);
    }
  }

  &:focus {
    border-color: var(--action-500);
  }
}

.hide {
  display: none;
}

.website-input {
  &:focus-within {
    border-color: var(--action-500);
  }

  &.invalid-highlight {
    border-color: var(--error-500);
  }
}

.post-type {
  width: 100%;
}

.section-title.flex-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title-tooltip {
  position: relative;
  top: 3px;
}

.required-asterisk {
  color: var(--error-500);
}

.caption-indicator {
  color: var(--error-500);
  font-size: var(--x12);
}

.warning {
  &:focus,
  :deep(textarea:focus) {
    border-color: var(--error-500) !important;
  }
}
</style>
