<template>
  <SchedulePostPopupWrapper
    :close="close"
    :close-confirmation="confirmation"
    :post="post"
    :show-post-editor-side-panel="showPostEditorSidePanel"
    :linked-comment-id="linkedCommentId"
    :platform="platformLabels.facebook"
    :avatar="connection && (connection.avatar_url || connection.avatarUrl)"
    :display-name="displayName"
    :show-media-placeholder-message="showPlaceholderMessage && mediaList.length === 0"
    :has-unsaved-changes="hasUnsavedChanges"
    class="facebook-scheduling"
  >
    <template #media>
      <template v-if="currentScreen === 'tagProducts'">
        <MediaManager
          :media-list="mediaList"
          :media-selected="mediaSelected"
          :viewer-component="viewerComponent"
          :viewer-component-props="viewerComponentProps"
          disable-editing
          @reset-thumb-details="resetThumbnail"
        />
      </template>
      <template v-else>
        <section>
          <p class="section-title">Facebook Post</p>
          <RichTextarea
            ref="richTextArea"
            v-model="messageHTML"
            :disabled="disableEditing || isUtmEncodeDecodePending"
            :on-mention-search="searchFacebookPages"
            :on-mention-lookup="(mentionId) => mentions[mentionId]"
            :on-mention-add="(mention) => (mentions[mention.id] = mention)"
            :mention-details-component="mentionDetailsComponent"
            autofocus="end"
            placeholder="Compose Facebook Post"
            textarea-id="facebook-post-edit"
            data-cy="scheduled-post-text-area"
            :error-message="postLinkError"
            enable-mentions
            enable-hash-tag-highlighting
            resizable
            @text-pasted="onPaste"
          />
          <CheckBox
            v-model="shortenLinkCheckbox"
            data-cy="shortenLinksCheckbox"
            :disabled="!Boolean(postLink)"
            label="Shorten Links"
            class="mt-2"
          />
          <CircularLoader v-if="linkPreviewLoading" />
          <template v-else-if="linkPreview">
            <LinkPreview
              :disabled="disableEditing"
              :url="linkPreview.url"
              :image="linkPreview.image"
              :title="linkPreview.title"
              :description="linkPreview.description"
              :can-edit="linkPreview.can_edit && !disableEditing"
              :can-be-published-at="scheduledTime"
              @remove-clicked="removeLink"
              @text-updated="saveLinkPreviewText"
              @media-updated="saveLinkPreviewMedia"
            />
            <Banner
              v-if="!linkPreview.can_edit"
              alert-type="warning"
              class="validation-banner"
              hide-default-icon
            >
              Link preview customization is not available for this URL. To update the link preview,
              please
              <a
                href="https://developers.facebook.com/docs/sharing/domain-verification/verifying-your-domain"
                target="_blank"
              >
                verify this domain in Facebook Business Manager
              </a>
            </Banner>
          </template>
          <Banner v-if="linkPreviewFailure" alert-type="warning" class="validation-banner">
            The preview for this URL cannot be generated. The default link preview will be displayed
            when published.
          </Banner>

          <FacebookAccountPopup
            v-if="showAccountPopup"
            :account-list="displayAccounts"
            @on-select="selectAccount"
            @on-close="closeAccountPopup"
          />
          <Button
            :disabled="!Boolean(postLink)"
            data-cy="EditUtmParametersButton"
            link
            class="mt-2 text-left"
            @click="toggleUtmEditorPanel"
            >{{ utmScheduleOpenEditTrackingButton }}</Button
          >
        </section>
        <MediaManager
          :disable-editing="disableEditing"
          :display-error-message="onMediaUploadError"
          :download-media-clicked="downloadMediaClicked"
          :media-list="mediaList"
          :media-selected="mediaSelected"
          :viewer-component="viewerComponent"
          :viewer-component-props="viewerComponentProps"
          :on-upload-status-change="uploadStatusChanged"
          :save-alt-text="saveAltText"
          :media-count-limit="isReelSelected ? 1 : 10"
          :video-count-limit="isReelSelected ? null : 1"
          :allow-mixed-media="isReelSelected"
          :can-be-published-at="scheduledTime"
          :publish-dates-must-overlap-with="canPublishWithin"
          :post-type="postType"
          :media-type-limit="isReelSelected ? 'VIDEO' : null"
          :on-cropping-cover-image="onCroppingCoverImage"
          :custom-cropper-presets="customCropperPresets"
          :enable-free-crop="!isReelSelected"
          disallow-past-publish-dates
          disallow-incompatible-publish-dates
          @select-cover-image="coverImageAdded"
          @remove-cover-photo="removeCoverPhoto"
          @reset-thumb-details="resetThumbnail"
        />
        <input
          v-if="isSingleVideoSelected && !isReelSelected"
          v-model="videoTitle"
          :disabled="disableEditing"
          maxlength="255"
          placeholder="Enter video title"
          class="video-title-field"
        />
        <Banner
          v-if="visionUsageInfo"
          alert-type="white"
          custom-icon="magic-wand-1-filled"
          class="usage-info-banner"
          data-cy="usage-info-banner"
        >
          {{ visionUsageInfo }}
        </Banner>
        <VideoPredictionThumbnails
          v-if="displayKeyframes"
          v-model="thumbOffset"
          :media="mediaList[0]"
          :is-processing="mediaList[0].isProcessing"
          :video-predictions="mediaList[0].videoPredictions"
          :suggested-thumbnails="mediaList[0].videoSuggestedThumbnailList"
          :prediction-interval="mediaList[0].videoPredictionInterval"
          @offset-update="handleOffset"
        />
        <Banner
          v-if="validationBannerData"
          :alert-type="validationBannerData.level"
          :custom-icon="validationBannerData.icon"
          class="validation-banner"
          hide-default-icon
        >
          {{ validationBannerData.message }}
        </Banner>
        <Banner
          v-if="publishDateRangeWarning"
          :alert-type="publishDateRangeWarning.level"
          class="validation-banner"
        >
          {{ publishDateRangeWarning.message }}
        </Banner>
      </template>
    </template>

    <template #details>
      <template v-if="currentScreen === 'index'">
        <PostStatusBox :post="{ ...post, platform: 'facebook' }" />
        <Banner
          v-if="cropperStore.adjustFacebookProductTagsPrompt"
          class="crop-warning"
          alert-type="warning"
        >
          <div>
            <p>{{ facebookTagsCropWarningMessage }}</p>
            <Button small @click="cropperStore.removeAdjustFacebookProductTagsPrompt"> Ok </Button>
          </div>
        </Banner>
        <section>
          <PublishOptionHeader
            :disabled="isDraftToggleDisabled"
            :is-on="isDraft"
            :post="post"
            @drafts-toggled="(draft) => onToggleDraft(draft)"
          />
          <AutoPublishOptions
            v-model="autoPublish"
            :disabled="disableEditing"
            :disable-auto-publish="disableAutoPublish"
            platform="facebook"
            :post="post"
          />
          <PostSettingItem
            :input-tooltip="scheduleTimeTooltip"
            :title="scheduledTimeLabel"
            :clear-action="resetTime"
            :disabled="disableEditing || !isAppConnected || approvedPublishingDatesExpired"
            :error-message="scheduledTimeError"
            :on-click="() => switchSubScreen('schedule')"
            empty="Schedule Date & Time"
            left-icon="calendar"
          />
          <QuickSelectCalendar
            v-if="!disableEditing && !approvedPublishingDatesExpired"
            ref="quickSelectCalendar"
            :class="{ 'pt-4': publishDateError }"
            :model-value="scheduledTime"
            :restrict-selection-to-interval="canPublishWithin"
            platform="Facebook"
            @update:model-value="timeSelected"
          />
          <PublishAsOptions
            v-model="postType"
            :value="postType"
            :disabled="disableEditing"
            @input="(type) => (postType = type)"
          />
        </section>
        <PostSettingItem
          v-if="hasApprovalRequestsAccess"
          :title="getApprovalRequestTitle(approvalRequests)"
          :on-click="
            () =>
              schedulerStore.toggleSidePanel({
                router: $router,
                platform: 'facebook',
                post,
              })
          "
          data-cy="approval-request-setting-item"
          empty="Add Reviewers"
          left-icon="chatBubbleSquareCheck"
          label="Approval Request"
          :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
        />
        <PostSettingItem
          v-if="brandAllowedToPublishToFacebook"
          v-tooltip="addLocationTooltip"
          :input-tooltip="addLocationTooltip"
          :disabled="disableEditing || !canAutoPublishToFacebook"
          :title="locationLabel"
          :clear-action="resetLocation"
          :on-click="() => switchSubScreen('location')"
          empty="Add Location"
          left-icon="location"
          label="Location"
        />
        <HoverListDropdown :item-list="audienceHoverList" :dropdown-disabled="!audienceHoverList">
          <template #hoverableElement>
            <PostSettingItem
              :input-tooltip="audienceTooltip"
              :label-info-tooltip="audienceInfoTooltip"
              :disabled="audienceDisabled"
              :title="audienceTitle"
              :clear-action="resetAudience"
              :on-click="() => switchSubScreen('audience')"
              :empty="audienceEmptyLabel"
              left-icon="locationGlobe"
              label="Audience"
            />
          </template>
        </HoverListDropdown>

        <PostSettingItem
          v-if="hasAccessToProductTagging"
          v-tooltip="tagProductsTooltip"
          :input-tooltip="tagProductsTooltip"
          :disabled="tagProductsDisabled"
          :title="tagProductsLabel"
          :on-click="() => switchSubScreen('tagProducts')"
          empty="Tag Products"
          left-icon="tag"
          label="Facebook Shopping"
        />
        <PostSettingItem
          :disabled="disableEditing"
          :title="addToLabel"
          :clear-action="resetAddTo"
          :on-click="() => switchSubScreen('addTo')"
          empty="After Published, Add to"
          left-icon="folderAdd"
          :label="addToAfterLabel"
        />

        <Controls class="controls">
          <Button
            v-if="!disableEditing && post && post.id"
            v-tooltip="'Remove from Scheduler'"
            remove
            class="remove"
            @click="onRemoveClick"
          >
            Remove
          </Button>
          <SplitButton
            v-if="!disableEditing"
            :tooltip="saveTooltip"
            :disabled="saveDisabled"
            :loading="schedulerStore.postActionPending || publishing || isSaving"
            :options="saveOptions"
            class="save-button"
            data-cy="save-facebook-dropdown-button"
            primary
            @click="save"
          >
            Save
          </SplitButton>
          <DropdownButton
            v-else
            :dropdown-list="duplicatePostOptions"
            :dropdown-list-styles="{ 'margin-top': 'var(--space-4)' }"
            scrollable-list
            button-classes="primary"
            items-center
            data-cy="duplicate-post-button"
          >
            <template #buttonContent>Duplicate Post</template>
          </DropdownButton>
        </Controls>
      </template>

      <!-- Select add location subscreen -->
      <AddLocation
        v-if="currentScreen === 'location'"
        :loading="!!schedulerStore.pending.facebookLocationSearchList"
        :location="location"
        :on-back="() => switchSubScreen('index')"
        :on-location-selected="locationSelected"
        title-key="name"
        sub-title-key=""
        platform="facebook"
      />

      <SelectAudience
        v-show="currentScreen === 'audience'"
        v-model:targeting="targeting"
        :tooltip="audienceInfoTooltip"
        :locations="schedulerStore.facebookAdGeolocations"
        :recently-used-targeting="recentAudienceTargeting"
        @back="switchSubScreen('index')"
      />

      <!-- Select product tags subscreen -->
      <FacebookProductTags
        v-if="currentScreen === 'tagProducts'"
        :on-back="
          () => {
            cropperStore.removeAdjustFacebookProductTagsPrompt();
            switchSubScreen('index');
          }
        "
      />

      <!-- Select post time subscreen -->
      <SubScreen
        v-if="currentScreen === 'schedule'"
        title="Choose Date & Time"
        @on-back="switchSubScreen('index')"
      >
        <template #content>
          <div>
            <Calendar
              :posts="schedulerStore.getPosts(brandFacebookPosts)"
              :time-selected="timeSelected"
              :on-fetch-posts="onCalendarFetch"
              :restrict-selection-to-interval="canPublishWithin"
              :ideal-post-times="bestTimesToPost"
              platform="facebook"
              type="compact"
            />
          </div>
        </template>
      </SubScreen>

      <!-- Select board to add to subscreen -->
      <AddToGallery
        v-if="currentScreen === 'addTo'"
        :selected-galleries="selectedGalleries"
        :on-galleries-selected="onGalleriesSelected"
        :on-back="() => switchSubScreen('index')"
        type="FACEBOOK"
      />
    </template>
    <teleport v-if="showUtmEditorPanel && postLink && !postLinkError" to="#SchedulerRightSidePanel">
      <UtmEditorPanel
        class="w-80"
        :url="postLink"
        channel="facebook"
        :brand-channel-utm-settings="brandChannelUtmSettings"
        :tracking-data="trackingData"
        @close="toggleUtmEditorPanel"
        @tracking-parameters-updated="updateLinksWithUtms"
      />
    </teleport>
  </SchedulePostPopupWrapper>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { CancelToken } from 'axios';
import { mapStores } from 'pinia';
import humps, { camelizeKeys } from 'humps';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useTrackingStore } from '@/stores/tracking';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
import { useIdentityStore } from '@/stores/identity';
import {
  mixpanelActions,
  postStatus,
  popUpMessages,
  thumbnailSourceType,
  facebookTagsCropWarningMessage,
  unknownApiErrorMessage,
  PLATFORMS,
  somethingWentWrongMessage,
  somethingWentWrongTitle,
  MAX_ALT_TEXT_LENGTH,
  errorAltTextTooLong,
  altTextRemovedNotification,
  altTextSavedNotification,
  CROSS_BRAND_DUPLICATE_ERROR,
  platformLabels,
  fbPostTypes,
  carouselViewerAspectRatios,
} from '@/app/scheduler/constants';
import {
  shouldDisplayKeyFrames,
  ThumbnailUrlGenerator,
  videoPredictionMixpanelEvent,
  shouldClearVideoThumbnail,
  checkUnsavedChanges,
  getUnscheduledRouteName,
  allMediaUploaded,
  platformToProvider,
} from '@/app/scheduler/utils';
import { getConflictingMediaIds } from '@/app/scheduler/utils/publish-dates';
import {
  discardCommentConfirmMessage,
  discardConfirmMessage,
  toolTips,
  debounceInputDelay,
  facebookReelCropperPresets,
  facebookReelCoverImageCropperPresets,
} from '@/config';
import { KEYDOWN_EVENT_KEYS } from '@/utils/keyEvents';
import { oldAxios } from '@/apis/facebook';
import { FacebookAPI, LibraryAPI } from '@/apis';
import AddLocation from '@/app/scheduler/components/EditPost/AddLocation.vue';
import AddToGallery from '@/app/scheduler/components/EditPost/AddToGallery.vue';
import AutoPublishOptions from '@/app/scheduler/components/AutoPublishOptions.vue';
import Button from '@/components/foundation/Button.vue';
import Calendar from '@/app/scheduler/components/Calendar.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import Controls from '@/app/scheduler/components/EditPost/Layout/Controls.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import FacebookAccountPopup from '@/app/scheduler/components/EditPost/FacebookAccountPopup.vue';
import FacebookMentionDetails from '@/app/scheduler/components/EditPost/RichTextarea/FacebookMentionDetails.vue';
import FacebookProductTags from '@/app/scheduler/components/EditPost/FacebookProductTags.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import VideoPredictionThumbnails from '@/app/scheduler/components/EditPost/MediaViewer/VideoPredictionThumbnails.vue';
import LinkPreview from '@/app/scheduler/components/LinkPreview.vue';
import ListViewer from '@/app/scheduler/components/EditPost/MediaViewer/ListViewer.vue';
import MediaManager from '@/app/scheduler/components/EditPost/MediaViewer/MediaManager.vue';
import PostSettingItem from '@/app/scheduler/components/PostSettingItem.vue';
import PostStatusBox from '@/app/scheduler/components/PostStatusBox.vue';
import PublishAsOptions from '@/app/scheduler/components/PublishAsOptions.vue';
import QuickSelectCalendar from '@/app/scheduler/components/QuickSelectCalendar.vue';
import RichTextarea from '@/app/scheduler/components/EditPost/RichTextarea/RichTextarea.vue';
import SchedulePostPopupWrapper from '@/app/scheduler/components/EditPost/Layout/SchedulePostPopupWrapper.vue';
import SplitButton from '@/components/foundation/SplitButton.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import {
  encodedMentionRegex,
  encodeForTiptapFromFacebook,
  decodeFromTiptapForFacebook,
} from '@/app/scheduler/utils/tiptap';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import PostSettingItemLabelMixin from '@/app/scheduler/mixins/PostSettingItemLabelMixin';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import { useCommentingStore } from '@/stores/commenting';
import { useSchedulerStore } from '@/stores/scheduler';
import { usePlatformStore } from '@/stores/platform';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { useMediaStore } from '@/stores/media';
import { useCropperStore } from '@/stores/cropper';
import { useFlagStore } from '@/stores/flag';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';
import {
  mixpanelPublishTypes,
  postTypeLabelMap,
  SchedulerUserEventTracker,
  trackSchedulerUtmPopout,
  trackCustomCoverImageEvent,
} from '@/app/scheduler/mixpanel';
import CheckBox from '@/components/foundation/CheckBox.vue';
import { utmChannel, utmScheduleOpenEditTrackingButton } from '@/app/settings/components/Utm/const';
import { logger } from '@/utils/logger';
import UtmEditorPanel from '@/app/settings/components/Utm/UtmEditorPanel.vue';
import { fetchUtmSettings, extractAllUrlsFromString } from '@/app/settings/components/Utm/utils';
import { CROSS_BRAND_DUPLICATE_PLATFORMS } from '@/utils/postDuplication';
import SelectAudience from '@/app/scheduler/components/EditPost/SelectAudience.vue';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import HoverListDropdown from '@/components/HoverListDropdown.vue';
import { joinWithTruncation } from '@/utils/lists';
import { USER } from '@/models/auth/permissions.enum';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import { validateCustomCoverImage, validatePostMedia } from '@/app/scheduler/utils/mediaValidation';
import { ERROR_LEVEL } from '@/app/scheduler/constants/mediaValidationConsts';
import { refreshBestTimesToPost } from '@/app/scheduler/utils/bestTimesToPost';
import CarouselViewer from '@/app/scheduler/components/EditPost/MediaViewer/CarouselViewer.vue';

const TRUNCATE_AUDIENCE_TITLE_TO_CHARACTERS = 50;
const MAX_RECENT_AUDIENCE_TARGETING = 3;

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EditFacebookPost',
  components: {
    PublishOptionHeader,
    HoverListDropdown,
    SelectAudience,
    UtmEditorPanel,
    AddLocation,
    AddToGallery,
    AutoPublishOptions,
    Button,
    Calendar,
    CircularLoader,
    Controls,
    DropdownButton,
    FacebookAccountPopup,
    FacebookProductTags,
    Banner,
    LinkPreview,
    MediaManager,
    PostSettingItem,
    PostStatusBox,
    PublishAsOptions,
    QuickSelectCalendar,
    RichTextarea,
    SchedulePostPopupWrapper,
    SplitButton,
    SubScreen,
    VideoPredictionThumbnails,
    CheckBox,
  },
  mixins: [PostDuplicationMixin, PostSettingItemLabelMixin, ApprovedPublishingDatesMixin],
  props: {
    close: { type: Function, required: true },
    context: { type: String, default: null },
    disableEditing: { type: Boolean, default: false },
    media: { type: Array, default: null },
    mediaCaption: { type: String, default: null },
    post: { type: Object, default: null },
    showPostEditorSidePanel: { type: Boolean, default: false },
    linkedCommentId: { type: Number, default: null },
    duplicatedFrom: { type: String, default: null },
  },
  data() {
    return {
      autoPublish: this.post?.auto_publish ?? false,
      autoPublishInitialValue: false,
      currentScreen: 'index',
      displayAccounts: [],
      facebookAccounts: [],
      isPublishNow: false,
      isSaving: false,
      isValidating: false,
      link: (this.post && this.post.link) || '',
      linkPreview: this.post && this.post.link_preview,
      linkPreviewLoading: false,
      linkPreviewChanges: {},
      linkPreviewFailure: false,
      location: this.post && this.post.location,
      mediaList: this.media || (this.post && this.post.media) || [],
      mediaUploadError: null,
      mediaWarningIds: [],
      mediaValidationErrorList: [],
      coverImageValidationErrorMessage: null,
      mentionDetailsComponent: FacebookMentionDetails,
      mentions: {},
      postText: this.mediaCaption || (this.post && this.post.message) || '',
      publishing: false,
      scheduledTime: this.post && this.post.timestamp && dayjs(this.post.timestamp),
      targeting: this.hasTargetingData()
        ? { geoLocations: this.post.targeting.geoLocations }
        : null,
      selectedGalleries: [
        ...(this.post?.campaigns?.map((campaignObj) => ({ ...campaignObj, campaign: true })) ?? []),
        ...(this.post?.boards ?? []),
      ],
      showAccountPopup: false,
      thumbnailChanged: false,
      thumbnailUrl: (this.post && this.post.thumbnail_url) || null,
      thumbnailUrlGenerator: new ThumbnailUrlGenerator(),
      uploadPending: false,
      videoTitle: this.post?.video_title ?? '',
      source: null,
      facebookTagsCropWarningMessage,
      hasUnsavedChanges: false,
      altTextMediaMap: this.post?.altTextMediaMap || {},
      shortenLinks: false,
      shortenLinkCheckbox: false,
      showUtmEditorPanel: false,
      brandChannelUtmSettings: {},
      customUtms: {},
      postLink: '',
      utmScheduleOpenEditTrackingButton,
      postLinkError: null,
      altTextError: false,
      urlLinkCustomized: false,
      isUtmEncodeDecodePending: false,
      extraMedia: [],
      shouldKeepDuplicatedLink: this.duplicatedFrom === PLATFORMS.TWITTER || false,
      approvalRequests: (this.post && this.post.approvalRequests) || [],
      isDraft: (!this.post?.id && !this.post?.timestamp) || this.post?.status === postStatus.DRAFT,
      postType: (this.post && this.post.postType) || fbPostTypes.POST,
      coverImageMedia: this.post?.coverImageMedia,
      isCroppingCoverImage: false,
      tempTargeting: null,
      thumbOffset: this.post?.thumbOffset || null,
      thumbnailSource: null,
    };
  },
  computed: {
    ...mapStores(
      useCustomerJourneyStore,
      useAuthStore,
      useSchedulerStore,
      useCommentingStore,
      useNotificationStore,
      usePlatformStore,
      useMediaStore,
      useCropperStore,
      useFlagStore,
      useFacebookProductTaggerStore,
      useTrackingStore,
      useIdentityStore,
    ),
    bestTimesToPost() {
      return this.schedulerStore.recommendedPublishTimes?.[this.authStore.currentBrand.id]?.[
        PLATFORMS.FACEBOOK
      ];
    },
    isReelSelected() {
      return this.postType === fbPostTypes.REEL;
    },
    aspectRatio() {
      return this.isReelSelected
        ? carouselViewerAspectRatios.PORTRAIT
        : carouselViewerAspectRatios.SQUARE;
    },
    hasApprovalRequestsAccess() {
      return !this.customerJourneyStore.hasPlanGrow;
    },
    recentAudienceTargetingKey() {
      return `recent-audience-targeting-${this.authStore.currentBrand.id}`;
    },
    recentAudienceTargeting: {
      get() {
        const json = browserStorageGetItem(this.recentAudienceTargetingKey);
        return (json && JSON.parse(json)) ?? [];
      },
      set(to) {
        browserStorageSetItem(this.recentAudienceTargetingKey, JSON.stringify(to));
      },
    },
    brandFacebookPosts() {
      return allPosts.forBrand(this.authStore.currentBrand.id).forPlatform(PLATFORMS.FACEBOOK);
    },
    postData() {
      const data = {
        platform: PLATFORMS.FACEBOOK,
        id: this.post?.id,
        auto_publish: this.autoPublish,
        board_ids: this.selectedBoardIds,
        galleryTypes: this.selectedGalleryTypes,
        campaign_ids: this.selectedCampaignIds,
        brand_id: this.authStore.currentBrand.id,
        media: this.mediaList,
        media_ids: this.mediaList.map((m) => m.id),
        message: this.postText,
        location: this.location,
        targeting: !this.isReelSelected ? this.targeting : null,
        location_id: (this.location && this.location.id) || null,
        link: this.normalizedLink,
        timestamp: this.scheduledTime && this.scheduledTime.toISOString(),
        video_title: this.videoTitle,
        thumb_offset: parseInt(this.thumbOffset, 10) >= 0 ? this.thumbOffset * 1000 : null, // Multiply by 1000 to convert to milliseconds
        thumbnail_url: this.thumbnailUrl,
        altTextMediaMap: this.altTextMediaMap,
        shorten_link: this.shortenLinkCheckbox,
        custom_utms: this.urlLinkCustomized,
        approvalRequests: this.approvalRequests,
        postType: this.postType,
        ...(this.isDraft && this.flagStore.ready && this.flagStore.flags.schedulerDrafts
          ? { status: postStatus.DRAFT }
          : {}),
        ...(this.post?.approvalPolicy ? { approval_policy: this.post.approvalPolicy } : {}),
        cover_image_media: this.coverImageMedia,
        cover_image_media_id: this.coverImageMedia?.id ?? null,
      };

      if (this.flagStore.flags.facebookProductTagging) {
        data.product_tags = this.facebookProductTaggerStore.taggedProducts.map((tag) => {
          return {
            fb_product_id: tag.fbProductId,
            media_id: tag.mediaId,
            x: tag.x,
            y: tag.y,
            meta: {
              variant_attributes: tag.variantAttributes,
            },
          };
        });
      }

      return data;
    },
    duplicatePostData() {
      return {
        ...this.postData,
        boards: this.selectedGalleries.filter((g) => !g.campaign),
        campaigns: this.selectedGalleries.filter((g) => g.campaign),
      };
    },
    selectedBoardIds() {
      return this.selectedGalleries.filter((g) => !g.campaign).map((g) => g.id);
    },

    selectedGalleryTypes() {
      return this.selectedGalleries.filter((g) => !g.campaign).map((g) => g.galleryType);
    },

    selectedCampaignIds() {
      return this.selectedGalleries.filter((g) => g.campaign).map((g) => g.id);
    },
    messageHTML: {
      get() {
        return encodeForTiptapFromFacebook(this.postText);
      },
      set(val) {
        this.postText = decodeFromTiptapForFacebook(val);
      },
    },
    viewerComponent() {
      return this.currentScreen === 'tagProducts' || this.isReelSelected
        ? CarouselViewer
        : ListViewer;
    },
    viewerComponentProps() {
      const outsideDatesErr =
        this.publishDateError?.code === 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES';
      const mediaWarningIds = outsideDatesErr
        ? getConflictingMediaIds(this.mediaList, this.scheduledTime)
        : this.mediaWarningIds;
      let mediaValidationErrorList = outsideDatesErr
        ? this.mediaList.map((media) =>
            mediaWarningIds.includes(media.id) ? this.validationBannerData : null,
          )
        : this.mediaValidationErrorList;
      mediaValidationErrorList = this.hasCrossBrandDuplicateError
        ? this.mediaList.map((media) =>
            media.brandId !== this.authStore.currentBrand.id &&
            !this.mediaStore.pending.createMediaV2
              ? this.validationBannerData
              : null,
          )
        : mediaValidationErrorList;
      const props = {
        aspectRatio: this.aspectRatio,
        isCover: this.isCover,
        platform: 'facebook',
        mediaValidationErrorList,
        mediaWarningIds,
        altTextMediaMap: this.altTextMediaMap,
        coverImage: this.isReelSelected ? this.coverImageMedia : null,
        onCroppingCoverImage: this.onCroppingCoverImage,
        postType: this.postType,
        thumbOffset: this.thumbOffset,
      };
      if (this.currentScreen === 'tagProducts') {
        props.navigationEnabled = true;
      }
      return props;
    },
    brandAllowedToPublishToFacebook() {
      return this.authStore.brand_can('scheduler', 'can_auto_publish_facebook');
    },
    mediaValidationError() {
      return this.mediaValidationErrorList.find((result) => result) || null;
    },
    hasMedia() {
      return this.mediaList.length > 0;
    },
    showPlaceholderMessage() {
      return !this.linkPreview || !this.linkPreview.image;
    },
    addLocationTooltip() {
      return !this.canAutoPublishToFacebook ? toolTips.locationOnlyAllowedInFBAutoPublish : null;
    },
    tagProductsTooltip() {
      if (!this.autoPublish) {
        return toolTips.tagProductsOnlyAllowedInInsAutoPublish;
      }
      if (this.mediaList?.length === 0) {
        return toolTips.noMediaSelectedTagProducts;
      }
      return '';
    },
    tagProductsLabel() {
      if (this.facebookProductTaggerStore.taggedProducts.length > 0) {
        const numTags = this.facebookProductTaggerStore.taggedProducts.length;
        const plural = numTags > 1 ? 's' : '';
        return `${numTags} Product${plural}`;
      }
      return null;
    },
    tagProductsDisabled() {
      return this.disableEditing || !this.autoPublish || this.mediaList?.length === 0;
    },
    hasAccessToProductTagging() {
      // This feature is no longer being supported by Meta, so we are removing Feature Flag access
      // for all brands. The Feature Flag and code will remain in place for the time being, should
      // Meta decide to pick this feature back up.
      // More info in slack thread: https://dashhudson.slack.com/archives/C03CWHE088G/p1683047999925839
      return this.flagStore.ready && this.flagStore.flags.facebookProductTagging;
    },
    timestampAddedOrRemoved() {
      return (
        (!this.scheduledTime && this.post?.timestamp) ||
        (!this.post?.timestamp && this.scheduledTime)
      );
    },
    locationLabel() {
      return this.location ? this.location.name : null;
    },
    audienceHoverList() {
      if (this.targeting) {
        const allCountries = camelizeKeys(this.schedulerStore.facebookAdGeolocations).country ?? [];
        const selectedCountries = allCountries
          .filter((c) => this.targeting.geoLocations.countries.includes(c.key))
          .map((c) => {
            return { name: c.name };
          });
        selectedCountries.unshift({ name: 'Audience', header: true });
        return selectedCountries;
      }
      return null;
    },
    audienceTooltip() {
      if (!this.canAutoPublishToFacebook) {
        return toolTips.audienceOnlyAllowedInFBAutoPublish;
      }
      if (this.schedulerStore.errors.facebookAdGeolocations) {
        return toolTips.errorLoadingAudienceCountries;
      }
      if (this.isReelSelected) {
        return toolTips.limitLocationForFacebookReels;
      }
      return null;
    },
    audienceInfoTooltip() {
      return toolTips.facebookGeogatingTooltip;
    },
    audienceDisabled() {
      return (
        this.disableEditing ||
        !this.canAutoPublishToFacebook ||
        this.isReelSelected ||
        !!this.audienceLoadingOrErrorMessage
      );
    },
    audienceCountries() {
      return this.schedulerStore.facebookAdGeolocations.country ?? [];
    },
    audienceEmptyLabel() {
      return this.audienceLoadingOrErrorMessage ?? 'Limit by Location';
    },
    audienceLoadingOrErrorMessage() {
      if (this.targeting || (this.canAutoPublishToFacebook && !this.disableEditing)) {
        if (this.schedulerStore.errors.facebookAdGeolocations) {
          return 'Error loading locations';
        }
        if (this.audienceCountries.length === 0) {
          return 'Loading country names';
        }
      }
      return null;
    },
    audienceTitle() {
      if (this.audienceLoadingOrErrorMessage) {
        return null;
      }

      const selectedCountryKeys = this.targeting?.geoLocations?.countries ?? [];
      const selectedCountryNames = this.audienceCountries
        .filter((c) => selectedCountryKeys.includes(c.key))
        .map((c) => c.name);

      if (selectedCountryNames.length === 0) {
        return null;
      }

      return joinWithTruncation(selectedCountryNames, TRUNCATE_AUDIENCE_TITLE_TO_CHARACTERS);
    },
    confirmation() {
      if (this.hasUnsavedChanges) {
        return discardConfirmMessage;
      }
      if (this.commentingStore?.hasUnsavedComments) {
        return discardCommentConfirmMessage;
      }
      return null;
    },
    title() {
      if (this.disableEditing) {
        return 'Published Facebook Post';
      }
      return `${this.post && this.post.id ? 'Edit' : 'Add'} Facebook Post`;
    },
    addToLabel() {
      if (this.selectedGalleries.length) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return null;
    },
    scheduledTimeLabel() {
      return this.scheduledTime ? this.scheduledTime.format('LT, ll') : null;
    },
    saveDisabled() {
      return (
        this.publishing ||
        this.uploadPending ||
        this.isSaving ||
        this.isValidating ||
        this.mediaValidationError?.level === ERROR_LEVEL.ERROR ||
        this.postIsEmpty ||
        !this.isAppConnected ||
        !!this.publishDateError ||
        !!this.altTextError ||
        this.schedulerStore.postActionPending ||
        !!this.hasCrossBrandDuplicateError ||
        (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) ||
        this.reelMissingMedia
      );
    },
    saveTooltip() {
      if (this.saveDisabled && this.uploadPending) {
        return { content: toolTips.mediaUploading };
      }
      if (this.mediaValidationError?.level === ERROR_LEVEL.ERROR) {
        return { content: this.mediaValidationError.message };
      }
      if (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) {
        return { content: toolTips.illegalTimeStamp };
      }
      if (this.postIsEmpty) {
        return { content: toolTips.unableToSaveEmptyPost };
      }
      if (!this.isAppConnected) {
        return { content: toolTips.invalidConnection };
      }
      if (this.altTextError) {
        return { content: this.altTextError };
      }
      if (this.hasCrossBrandDuplicateError) {
        return { content: this.mediaUploadError };
      }
      if (this.reelMissingMedia) {
        return { content: toolTips.noVideoSelectedSave };
      }
      return null;
    },
    disableAutoPublish() {
      if (!this.authStore.canAccessAutoPublish('facebook')) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (this.reelMissingMedia) {
        return { isDisabled: true, tip: toolTips.noVideoSelectedAutoPublish };
      }
      return { isDisabled: false, tip: '' };
    },
    isSaveDropdownDisabled() {
      const isDisabledDueToNotAutoPublish = !this.autoPublish;
      if (!this.authStore.canAccessAutoPublish('facebook')) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (this.uploadPending) {
        return { isDisabled: true, tip: toolTips.mediaUploading };
      }
      if (isDisabledDueToNotAutoPublish) {
        return { isDisabled: true, tip: toolTips.notAutoPublish };
      }
      if (this.mediaValidationError?.level === ERROR_LEVEL.ERROR) {
        return { isDisabled: true, tip: this.mediaValidationError.message };
      }
      if (this.uploadPending) {
        return { isDisabled: true, tip: toolTips.mediaUploading };
      }
      if (this.postIsEmpty) {
        return { isDisabled: true, tip: toolTips.unableToSaveEmptyPost };
      }
      if (!this.canAssetPublishToday) {
        return { isDisabled: true, tip: this.publishNowWarning };
      }
      if (this.requiresApproval(this.post)) {
        return { isDisabled: true, tip: toolTips.requiresApproval };
      }
      return { isDisabled: false, tip: '' };
    },
    postIsEmpty() {
      return (
        !this.postText &&
        (!this.mediaList || this.mediaList.length === 0) &&
        !this.location &&
        !this.link
      );
    },
    reelMissingMedia() {
      return !allMediaUploaded(this.mediaList) && this.isReelSelected;
    },
    scheduleTimeTooltip() {
      if (!this.isAppConnected) {
        return toolTips.noFacebookConnection;
      }
      return '';
    },
    duplicatePostOptions() {
      const crossBrandDisabled = this.pdCrossBrandDuplicationDisabled(this.post, this.mediaList);
      return this.crossBrandDuplicatePostsFlagSet
        ? [
            {
              text: 'To Channel',
              subList: this.pdDuplicationOptions(this.duplicate),
            },
            ...(this.canCrossBrandDuplicatePost
              ? [
                  {
                    text: 'To Brand',
                    subList: this.duplicateToBrandSubList(this.post),
                    disabled: crossBrandDisabled.isDisabled,
                    tooltip: crossBrandDisabled.tip,
                  },
                ]
              : []),
          ]
        : this.pdDuplicationOptions(this.duplicate);
    },
    saveOptions() {
      return [
        {
          text: 'Save & Duplicate',
          subList: this.pdDuplicationOptions(this.saveAndDuplicate),
        },
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.isSaveDropdownDisabled.isDisabled,
          tooltip: this.isSaveDropdownDisabled.tip,
        },
        {
          text: 'Publish Now & Duplicate',
          disabled: this.isSaveDropdownDisabled.isDisabled,
          tooltip: this.isSaveDropdownDisabled.tip,
          subList: this.pdDuplicationOptions(this.publishNowAndDuplicate),
        },
      ];
    },
    canAutoPublishToFacebook() {
      return this.isAppConnected;
    },
    connectionPopupMessage() {
      const verb =
        this.schedulerStore.currentFacebookSchedulingToken?.status === 'invalid'
          ? 'reconnect'
          : 'connect';
      return `To enable auto publishing for Facebook, please ${verb} your Facebook account.`;
    },
    connection() {
      const token = this.schedulerStore.currentFacebookSchedulingToken;
      return token?.status === 'missing' ? null : token;
    },
    isAppConnected() {
      return this.platformStore.hasCompletePlatformConnection(
        this.authStore.currentBrand?.id,
        PLATFORMS.FACEBOOK.toUpperCase(),
      );
    },
    displayName() {
      return this.connection
        ? this.connection.email_or_handle ||
            this.connection.handle ||
            this.authStore.currentBrand.name
        : '';
    },
    normalizedLink() {
      // Our link extractor returns anything that might look like a URL, including foo.com. Our
      // backend services require well-formed URLs though, so we prefix http:// here if it's missing
      if (!this.link) {
        return null;
      }
      return /^https?:\/\//.test(this.link) ? this.link : `http://${this.link}`;
    },
    isSingleVideoSelected() {
      return this.mediaList.length === 1 && this.mediaList[0].type === 'VIDEO';
    },
    displayKeyframes() {
      if (this.isSingleVideoSelected && !this.coverImageMedia) {
        return shouldDisplayKeyFrames(this.mediaList, this.canAutoPublishToFacebook, this.post);
      }
      return false;
    },
    visionUsageInfo() {
      // Note that the banner is duplicated in this component rather than
      // passed into MediaManager, in order to have the video title above.
      const usageInfo = this.identityStore.guard(USER.VISION.CAN_ACCESS_VISION)
        ? null
        : toolTips.promoteVision;
      return this.isSingleVideoSelected ? usageInfo : null;
    },
    validationBannerData() {
      if (
        this.publishDateError &&
        ![postStatus.POSTED, postStatus.IMPORTED].includes(this.post?.status)
      ) {
        return this.publishDateError;
      }
      if (this.coverImageValidationErrorMessage) {
        return this.coverImageValidationErrorMessage;
      }
      const error = this.mediaUploadError || this.mediaValidationError || this.altTextError;
      if (error) {
        return {
          level: error.level || 'error',
          message: error.message || error,
          ...(this.hasCrossBrandDuplicateError && { icon: 'alertTriangle' }),
        };
      }
      return null;
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
    trackingData() {
      return {
        postId: this.post?.id,
        postPlatform: 'Facebook',
        postType: 'Post',
      };
    },
    crossBrandDuplicatePostsFlagSet() {
      return this.flagStore.ready && this.flagStore.flags.crossBrandDuplicatePosts;
    },
    canCrossBrandDuplicatePost() {
      return (
        CROSS_BRAND_DUPLICATE_PLATFORMS.includes(this.post?.platform) &&
        this.crossBrandDuplicatePostsFlagSet
      );
    },
    hasCrossBrandDuplicateError() {
      return this.mediaUploadError === CROSS_BRAND_DUPLICATE_ERROR;
    },
    allMedia() {
      const allMedia = [...this.mediaList, ...this.extraMedia];
      if (this.coverImageMedia) {
        allMedia.push(this.coverImageMedia);
      }
      return allMedia;
    },
    platformLabels() {
      return platformLabels;
    },
    isDraftToggleDisabled() {
      const isPublished = [
        postStatus.POSTED,
        postStatus.AUTOPUBLISHING,
        postStatus.IMPORTED,
      ].includes(this.post?.status);
      return isPublished || !this.postData?.timestamp;
    },
    customCropperPresets() {
      if (this.isReelSelected) {
        if (this.isCroppingCoverImage) {
          return facebookReelCoverImageCropperPresets;
        }
        return facebookReelCropperPresets;
      }
      return null;
    },
    shouldGenerateThumbnailUrl() {
      return !this.coverImageMedia && this.thumbnailChanged;
    },
    isCover() {
      return this.isReelSelected && this.coverImageMedia;
    },
    postApprovalRequests() {
      return (
        this.schedulerStore.approvalRequests?.[this.authStore.currentBrand.id]?.[
          PLATFORMS.FACEBOOK
        ] || []
      );
    },
  },
  watch: {
    mediaList: {
      deep: true,
      handler(newVal) {
        this.validateMedia();
        if (!newVal.length) {
          // Reset thumbnail when video is removed
          this.videoTitle = '';
          this.thumbOffset = null;
          this.thumbnailUrl = null;
          this.mediaWarningIds = [];
          this.mediaValidationErrorList = [];
        }
      },
    },
    coverImageMedia: {
      handler(to, from) {
        this.thumbnailUrl = to ? to.sizes?.originalConverted?.url : null;
        this.thumbnailSource = thumbnailSourceType.CUSTOM;
        let action;
        if (from === null) {
          action = 'Added';
        } else if (to === null) {
          action = 'Removed';
        } else if (to !== null && from !== null) {
          action = 'Updated';
        }
        trackCustomCoverImageEvent({
          action,
          postPlatform: 'Facebook',
          postPlatformType: 'Facebook reel',
          mediaSource: to?.sourceType,
          coverImageOrigin: thumbnailSourceType.CUSTOM,
          autoPublish: this.autoPublish,
          post: this.post,
        });
        this.validateCustomCoverImage();
      },
    },
    showAccountPopup(to) {
      if (to) {
        window.addEventListener('keydown', this.specialKeyHandler);
      } else {
        window.removeEventListener('keydown', this.specialKeyHandler);
      }
    },
    autoPublish(newVal) {
      this.schedulerStore.setAutoPublish(this.autoPublish);
      // If auto publish is selected, confirm that brand is connected and has the right permission.
      // If not, show prompt to reconnect Facebook.
      const provider = platformToProvider(PLATFORMS.FACEBOOK);
      if (newVal && !this.canAutoPublishToFacebook) {
        this.platformStore.connect(provider, {
          message: this.connectionPopupMessage,
          onCancel: () => this.cancelConnectionPopup(),
          title: 'Enable Auto Publishing',
        });
      }

      if (this.mediaList.length > 0) {
        this.validateMedia();
      }
    },
    hasMedia() {
      this.updateLinkPreview();
    },
    normalizedLink(newVal, oldVal) {
      // Remove utm settings.
      // Sometimes after appending utm parameters, the order of the original parameters changed.
      // Therefore, we need to sort both the old one and the new one.
      const [newPath, newSearchParams] = newVal ? newVal.split('?') : '';
      const newSearchParamsQuery = newSearchParams
        ?.split('&')
        .filter((p) => !p.startsWith('utm_'))
        .sort()
        .join('&');

      const [oldPath, oldSearchParams] = oldVal ? oldVal.split('?') : '';
      const oldSearchParamsQuery = oldSearchParams?.split('&').sort().join('&');

      const newUrl = newSearchParamsQuery ? `${newPath}?${newSearchParamsQuery}` : newPath;
      const oldUrl = oldSearchParamsQuery ? `${oldPath}?${oldSearchParamsQuery}` : oldPath;
      // We don't need to update the link preview when it's just encoded with utm settings.
      // Sometimes URL.toString returns an additional / at the end.
      if (newUrl === oldUrl) {
        this.linkPreview.url = newVal;
      } else {
        this.updateLinkPreview();
      }
    },
    postText(newVal) {
      const { cursorPosition } = this.$refs.richTextArea;
      this.debouncedExtractPostLink(newVal);
      const val = (newVal || '').replace(encodedMentionRegex, ' ');
      const testString = val.substring(0, cursorPosition);
      const link = extractAllUrlsFromString(testString)[0];

      if (newVal && !this.link && !this.isReelSelected) {
        // Only update the link if it is followed by a space
        if (
          link &&
          testString.includes(`${link} `) &&
          cursorPosition === testString.lastIndexOf(link) + link.length + 2
        ) {
          this.link = link;
        }
      }
    },
    thumbOffset: {
      handler(newVal) {
        // sets thumbnail changed to true, every time the pop up is opened
        // prevent this if value is same as post
        if (
          !this.coverImageMedia &&
          (!this.post || !this.post.thumb_offset || this.post.thumb_offset !== newVal * 1000)
        ) {
          this.thumbnailChanged = true;
          this.thumbnailUrl = null;
        }
      },
    },
    postApprovalRequests: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) this.approvalRequests = newVal;
      },
    },
    postData(to, from) {
      let fieldChanged = checkUnsavedChanges(this.postData, this.post, this.scheduledTime);
      if (this.post === null) {
        fieldChanged = checkUnsavedChanges(to, from, this.scheduledTime);
      }
      if (!to?.timestamp) this.isDraft = true;
      this.hasUnsavedChanges = !this.disableEditing && (fieldChanged || this.uploadPending);
    },
    async shortenLinkCheckbox(newVal) {
      if (!this.postLink) return;
      if (newVal) {
        // If the post is from Twitter by the "publish and duplicate" option and has a shorten
        // link, we should not parse utm settings yet, because it will set the shorten link in the
        // original tweet with the facebook utm parameters incorrectly.
        if (!this.shouldKeepDuplicatedLink) {
          await this.parsePostWithUtmSettings();
        } else {
          this.shouldKeepDuplicatedLink = false;
        }
      } else {
        await this.decodeUtmFromPost();
      }
    },
    async postLink(newVal) {
      this.showUtmEditorPanel = Boolean(newVal);
      if (newVal && newVal.includes('&') && !newVal.includes('?')) {
        this.postLinkError = 'URL contains invalid UTM parameters';
      } else {
        this.postLinkError = '';
      }
      if (newVal && this.shortenLinks) {
        await this.parsePostWithUtmSettings(false);
      }
    },
    showPostEditorSidePanel(newVal) {
      if (newVal && this.showUtmEditorPanel) {
        this.showUtmEditorPanel = false;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: 'close',
        });
      }
    },
    showUtmEditorPanel(to) {
      if (to && this.showPostEditorSidePanel) {
        // showPostEditorSidePanel parameter controlled at the route level
        this.$router.push({
          name: 'scheduler.facebook.posts',
          params: { id: this.post?.id, post: this.post },
          query: { replaceModal: true },
        });
      }
    },
    postType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.validateMedia();
        if (newVal === fbPostTypes.REEL) {
          this.validateCustomCoverImage();
        } else {
          this.coverImageValidationErrorMessage = null;
        }
      }
      // If switching from 'Post' to 'Reel', store the current targeting in tempTargeting
      if (newVal === fbPostTypes.REEL) {
        this.tempTargeting = this.targeting;
        this.targeting = null;
        this.clearLinkPreview();
      }
      // If switching from 'Reel' back to 'Post', restore the stored targeting
      else if (oldVal === fbPostTypes.REEL && newVal !== fbPostTypes.REEL) {
        this.targeting = this.tempTargeting;
        this.tempTargeting = null;
      }
    },
  },
  async created() {
    // prefetch mention details
    if (this.postText) {
      await this.fetchMentions();

      // find link in post and assign it for UTM editor
      this.extractPostLink(this.postText);
    }

    // Fetch best times to post if they're not already in the store
    refreshBestTimesToPost(this.authStore.currentBrand.id, PLATFORMS.FACEBOOK);

    // Media can either come from the media prop (e.g. scheduling a post from the library)
    if (this.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand.id,
        mediaArrList: this.media,
      });
    }
    // ... or an existing scheduled post object (e.g. editing a scheduled post).
    else if (this.post?.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand.id,
        mediaArrList: this.post.media,
      });
    }

    if (this.post?.product_tags?.length > 0) {
      const productIds = this.post.product_tags.map((tag) => tag.fb_product_id);
      const response = await FacebookAPI.facebookProductTags.searchProductsByIds({
        brandId: this.authStore.currentBrand.id,
        params: { productIds },
      });
      const products = Object.fromEntries(
        response.data.map((product) => [product.fbProductId, product]),
      );

      const productTags = this.post.product_tags.map((tag) => {
        const product = products[tag.fb_product_id];
        return {
          ...humps.camelizeKeys(tag.meta),
          ...product,
          fbProductId: tag.fb_product_id,
          mediaId: tag.media_id,
          x: tag.x,
          y: tag.y,
        };
      });
      this.facebookProductTaggerStore.setFacebookProductTags({ productTags });
    }

    this.schedulerStore.setAutoPublish(this.autoPublish);
    this.autoPublishInitialValue = this.autoPublish;

    if (this.media) {
      this.validateMedia();
    }

    if (this.coverImageMedia) {
      this.validateCustomCoverImage();
    }

    if (this.linkPreview?.image_media_id) {
      const linkMedia = await LibraryAPI.getMediaV2({
        brandId: this.authStore.currentBrand.id,
        mediaId: this.linkPreview.image_media_id,
      });
      this.extraMedia.push(linkMedia.data);
    }

    this.fetchBrandUtmSettings();

    if (this.postText) await this.decodeUtmFromPost(false);

    if (this.link) {
      // UTMEditorPanel accepts only one link, for Facebook
      // preview and post link can be different, so we need to emulate
      // decoding step to un-shorten link if needed
      await this.decodePreviewLinkUtms();
    }
  },
  mounted() {
    // set thumbOffset if it exists, and convert it from milliseconds to seconds
    // need to do this before any awaits so it's set for children
    if (Number.isInteger(this.post?.thumb_offset)) {
      this.thumbOffset = this.post.thumb_offset / 1000;
    }
  },
  beforeUnmount() {
    this.thumbnailUrlGenerator.destroy();
    this.schedulerStore.resetCarouselPage();
  },
  unmounted() {
    this.cropperStore.removeAdjustFacebookProductTagsPrompt();
    this.facebookProductTaggerStore.clearFacebookProductTags();
  },
  methods: {
    getApprovalRequestTitle,
    getApprovalChipText,

    clearLinkPreview() {
      // Should remove link preview when media presents
      if (this.hasMedia || this.isReelSelected) {
        this.link = null;
        this.linkPreviewChanges = {
          title: null,
          description: null,
          image_media_id: null,
        };
      }
    },
    updateRecentAudienceTargeting() {
      if (this.targeting) {
        this.recentAudienceTargeting = [
          this.targeting,
          ...this.recentAudienceTargeting.filter(
            (targeting) =>
              !isEqual(
                new Set(targeting.geoLocations.countries),
                new Set(this.targeting.geoLocations.countries),
              ),
          ),
        ].slice(0, MAX_RECENT_AUDIENCE_TARGETING);
      }
    },
    updateLinkPreview() {
      // Link preview requests can be slow (4+ sec), so we use axios directly
      // to cancel any outstanding requests
      if (this.source) {
        this.source.cancel();
        this.source = null;
      }
      this.linkPreview = null;
      this.linkPreviewFailure = false;

      if (!this.hasMedia && this.normalizedLink && !this.isReelSelected) {
        this.linkPreviewLoading = true;
        this.loadLinkPreview();
      }
    },
    async fetchMentions() {
      // matchAll function was causing compatibility issues with some browsers
      // can be used here in the future when there is wider support
      const mentionIds = [];
      let match;
      // eslint-disable-next-line no-cond-assign
      while ((match = encodedMentionRegex.exec(this.postText))) {
        mentionIds.push(match[2]);
      }
      const ids = mentionIds.join();
      if (ids) {
        const payload = await this.schedulerStore.getFacebookPageSearchList({ ids });
        this.mentions = payload.data.reduce((items, item) => {
          return { ...items, [item.id]: item };
        }, {});
      }
    },
    loadLinkPreview: debounce(async function loadLinkPreview() {
      // Link preview requests can be slow (4+ sec), so we use axios directly
      // to cancel any outstanding requests
      this.linkPreviewLoading = true;
      this.source = CancelToken.source();
      try {
        const res = await oldAxios.post(
          '/previews',
          {
            url: this.normalizedLink,
          },
          {
            params: {
              brand_id: this.authStore.currentBrand.id,
            },
            cancelToken: this.source.token,
          },
        );
        this.linkPreview = res.data;
        this.linkPreviewChanges = {
          title: null,
          description: null,
          image_media_id: null,
        };
        this.extraMedia.pop();
      } catch (e) {
        // Link preview request cancelled with CancelToken
        this.linkPreviewFailure = true;
      }
      this.linkPreviewLoading = false;
    }, 750),
    removeLink() {
      this.link = null;
      this.linkPreviewChanges = {
        title: null,
        description: null,
        image_media_id: null,
      };
      this.extraMedia.pop();
    },
    removeItem() {
      this.mediaList = [];
    },
    onGalleriesSelected(galleries) {
      this.selectedGalleries = galleries;
    },
    resetAddTo() {
      this.selectedGalleries = [];
    },
    switchSubScreen(screen) {
      if (!this.disableEditing) {
        this.currentScreen = screen;
        this.schedulerStore.setActiveSubScreen(this.currentScreen);
        this.$refs.editContainer?.scroll?.(0, 0);
        if (screen === 'schedule') {
          this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
            postPlatform: 'Facebook',
            quickSelectClicked: false,
          });
        }
      }
    },
    resetLocation() {
      this.location = null;
    },
    resetAudience() {
      this.targeting = null;
    },
    locationSelected(location) {
      this.location = location;
      this.switchSubScreen('index');
      this.trackingStore.track('Facebook Location Tagged', { location });
    },
    mediaSelected(media) {
      const mediaRemoved = media.length === 0;
      if (mediaRemoved) {
        this.removeCoverPhoto();
      }
      this.mediaList = media;
    },
    uploadStatusChanged(status) {
      this.uploadPending = status;
    },
    timeSelected(time) {
      if (!this.scheduledTime) this.isDraft = false;
      this.scheduledTime = dayjs(time);
      this.switchSubScreen('index');
    },
    resetTime() {
      this.scheduledTime = null;
    },
    closeAccountPopup() {
      this.showAccountPopup = false;
    },
    selectAccount(account) {
      const symbolPosition = this.postText.indexOf('@');
      const searchTerm = this.postText.substr(symbolPosition).replace('@', '');
      this.postText = this.postText.replace(`@${searchTerm}`, account.name);
      this.showAccountPopup = false;
      this.$refs.postTextArea.focus();
      this.displayAccounts = this.dummyAccounts;
    },
    onToggleDraft(draft) {
      this.isDraft = draft;
      this.trackMixpanel.draftToggleClicked(draft, postTypeLabelMap.facebook);
    },
    coverImageAdded(media) {
      this.coverImageMedia = media;
    },
    removeCoverPhoto() {
      this.coverImageMedia = null;
      this.thumbnailUrl = null;
      if (!this.thumbOffset) {
        this.thumbOffset = 0;
      }
    },
    onCroppingCoverImage(val) {
      this.isCroppingCoverImage = val;
    },
    specialKeyHandler(e) {
      // by default, press arrow up will bring the cursor to the front, and press enter will change line,
      // but when we have a popup with keyboard support, we want to disable these default operations.
      if ([KEYDOWN_EVENT_KEYS.ARROW_UP, KEYDOWN_EVENT_KEYS.ENTER].includes(e.key)) {
        e.preventDefault();
      }
    },
    async onRemoveClick() {
      const { title, message } = popUpMessages.removeFromScheduler;
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.remove,
      });
    },
    async remove() {
      await this.schedulerStore.deletePost(this.post);
      if (this.scheduledTime) {
        this.notificationStore.setToast({ message: 'Post removed from your timeline.' });
      } else {
        this.notificationStore.setToast({
          message: 'Post removed from your unscheduled posts.',
        });
      }
      this.close();
    },
    async save(closeAfter = true) {
      if (this.saveDisabled) {
        return;
      }
      if (this.publishDateError) {
        return;
      }
      this.isSaving = true;

      if (this.shouldGenerateThumbnailUrl) {
        await this.generateThumbnailUrl();
      }

      await this.parsePostWithUtmSettings();

      this.clearLinkPreview();
      if (this.linkPreviewChanges) {
        this.postData.link_preview = this.linkPreviewChanges;
      }

      try {
        if (this.post?.id) {
          if (shouldClearVideoThumbnail(this.post, this.postData)) {
            this.postData.thumbnail_url = null;
          }
          // Take value before updating post (as it then updates this.post)
          const timestampAddedOrRemoved = this.timestampAddedOrRemoved;
          await this.schedulerStore.updatePost(this.postData);
          if (timestampAddedOrRemoved) {
            this.notificationStore.setToast(this.setSaveNotification('updated & added'));
          } else {
            this.notificationStore.setToast({ message: 'Post updated.' });
          }
        } else {
          await this.schedulerStore.createPost(this.postData);
          this.notificationStore.setToast(this.setSaveNotification('added'));
        }

        this.updateRecentAudienceTargeting();
      } catch {
        this.notificationStore.setToast({ message: unknownApiErrorMessage, type: 'error' });
      }

      this.handleVideoThumbnailMixpanelEvent();
      this.isSaving = false;
      if (closeAfter) {
        this.close();
      }
      if (!this.coverImageMedia && this.media?.length > 0 && this.thumbnailChanged) {
        trackCustomCoverImageEvent({
          action: 'Added',
          postPlatform: 'Facebook',
          postPlatformType: 'Facebook reel',
          mediaSource: thumbnailSourceType.SLIDER,
          coverImageOrigin: this.thumbnailSource,
          autoPublish: this.autoPublish,
          post: this.post,
        });
      }
    },
    setSaveNotification(verb) {
      if (this.scheduledTime) {
        return { message: `Post ${verb} to your timeline.` };
      }
      return {
        message: `Post ${verb} to your `,
        actionText: 'unscheduled posts.',
        actionTo: {
          name: getUnscheduledRouteName(PLATFORMS.FACEBOOK),
        },
        sameLine: true,
      };
    },
    duplicate({ toType }) {
      // Have to define duplicateData within these functions so computed values
      // are not lost when current post is closed.
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.FACEBOOK_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      this.handleVideoThumbnailMixpanelEvent();
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.FACEBOOK_POST,
        post: duplicateData,
      });
    },
    async publishNowAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.FACEBOOK_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.publishNow(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.FACEBOOK_POST,
        post: duplicateData,
      });
    },
    async saveAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.FACEBOOK_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.save(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.FACEBOOK_POST,
        post: duplicateData,
      });
    },
    async publishNow(closeAfter = true) {
      this.publishing = true;
      this.isPublishNow = true;

      if (this.shouldGenerateThumbnailUrl) {
        await this.generateThumbnailUrl();
      }

      await this.parsePostWithUtmSettings();
      this.clearLinkPreview();
      if (this.linkPreviewChanges) {
        this.postData.link_preview = this.linkPreviewChanges;
      }

      const data = {
        ...this.postData,
        status: postStatus.AUTOPUBLISHING,
      };

      if (this.post && this.post.id) {
        await this.schedulerStore.updatePost(data);
      } else {
        await this.schedulerStore.createPost(data);
      }

      this.updateRecentAudienceTargeting();

      this.notificationStore.setToast({
        message: 'Publishing in progress.',
        actionText: 'See it on your Timeline.',
        actionTo: { name: 'scheduler.facebook.timeline' },
        type: 'primary',
      });

      this.publishing = false;
      this.handleVideoThumbnailMixpanelEvent();
      if (closeAfter) {
        this.close();
      }
    },
    handleVideoThumbnailMixpanelEvent() {
      if (this.thumbnailSource === thumbnailSourceType.SLIDER) {
        this.trackingStore.track('Facebook Video Thumbnail Changed');
      } else if (this.thumbnailSource === thumbnailSourceType.VISION) {
        const suggestionEvent = videoPredictionMixpanelEvent(
          this.mediaList[0].videoSuggestedThumbnailList,
          this.thumbOffset,
          this.authStore.currentBrand.id,
        );
        this.trackingStore.track(suggestionEvent.action, suggestionEvent.properties);
      }
    },
    onMediaUploadError(errorMessage) {
      this.mediaUploadError = errorMessage?.message ?? null;
    },
    async onCalendarFetch(start, end) {
      this.schedulerStore.fetchPosts(this.brandFacebookPosts.betweenDates(start, end));
    },
    cancelConnectionPopup() {
      this.autoPublish = false;
      this.schedulerStore.setAutoPublish(this.autoPublish);
    },
    validateMedia() {
      this.mediaWarningIds = [];
      this.isValidating = true;
      const results = validatePostMedia(
        this.mediaList,
        PLATFORMS.FACEBOOK,
        this.autoPublish,
        this.postType,
      );

      this.mediaWarningIds = results.map((error) => error.mediaId);
      this.mediaValidationErrorList = results;

      this.isValidating = false;
    },
    handleOffset(offset, source) {
      this.thumbnailSource = source;
      this.thumbOffset = offset;
    },
    async generateThumbnailUrl() {
      this.thumbnailUrl = await this.thumbnailUrlGenerator.createThumbnailUrl(
        this.mediaList,
        this.thumbOffset,
      );
    },
    onPaste(e) {
      // If text is pasted, use the first link found
      const pastedText = e.clipboardData.getData('text');
      if (!this.linkPreview) {
        const link = extractAllUrlsFromString(pastedText)[0];
        if (link) this.link = link;
      }
    },
    async searchFacebookPages(query) {
      const q = query.trim();
      if (q.length) {
        const payload = await this.schedulerStore.getFacebookPageSearchList({ q, limit: 10 });
        return payload.data.map((page) => ({ ...page, text: page.name }));
      }
      return [];
    },
    saveLinkPreviewText(title, description) {
      this.linkPreview = { ...this.linkPreview, title, description };
      this.linkPreviewChanges.title = title;
      this.linkPreviewChanges.description = description;
    },
    saveLinkPreviewMedia(media, imageUrl) {
      this.linkPreview.image = imageUrl;
      this.linkPreviewChanges.image_media_id = media?.id;
      this.extraMedia.splice(0, 1, media);
    },
    downloadMediaClicked(media) {
      const postTrackingData = {
        postId: this.post?.id ?? null,
        postPlatform: postTypeLabelMap.facebook,
        postPlatformType: null,
        postStatus: this.post?.status ?? null,
        publishType: this.postData.auto_publish
          ? mixpanelPublishTypes.AUTO_PUBLISH
          : mixpanelPublishTypes.SEND_NOTIFICATION,
        timestamp: this.postData.timestamp,
      };
      this.trackMixpanel.downloadMediaFromScheduler(postTrackingData, media);
    },
    saveAltText(mediaId, altText, originalMediaId = null) {
      // Handle alt text for cropped media
      if (this.altTextMediaMap[originalMediaId] && !this.altTextMediaMap[mediaId]) {
        this.altTextMediaMap[mediaId] = this.altTextMediaMap[originalMediaId];
        delete this.altTextMediaMap[originalMediaId];
        return;
      }
      if (altText === this.altTextMediaMap[mediaId]) return;
      if (altText === '' && this.altTextMediaMap[mediaId]) {
        delete this.altTextMediaMap[mediaId];
        this.notificationStore.setToast({ message: altTextRemovedNotification });
      } else {
        if (altText === '') return;
        this.altTextMediaMap[mediaId] = altText;
        if (altText.length <= MAX_ALT_TEXT_LENGTH) {
          this.notificationStore.setToast({ message: altTextSavedNotification });
        }
      }
      this.altTextError = Object.values(this.altTextMediaMap).some(
        (altTextStr) => altTextStr.length > MAX_ALT_TEXT_LENGTH,
      )
        ? errorAltTextTooLong
        : false;
    },
    async fetchBrandUtmSettings() {
      this.brandChannelUtmSettings = await fetchUtmSettings({
        brandId: this.authStore.currentBrand.id,
        channel: utmChannel.FACEBOOK,
      });
    },
    async decodePreviewLinkUtms() {
      try {
        const extractedUrls = extractAllUrlsFromString(this.link).filter((url) => url);
        const requestBody = {
          brandId: this.authStore.currentBrand.id,
          channel: utmChannel.FACEBOOK,
          content: this.link,
          urls: extractedUrls,
        };

        const result = await LibraryAPI.decodeUtmSettings(requestBody);
        this.link = result.data.original_content;
      } catch (error) {
        this.notificationStore.setToast({
          message: somethingWentWrongTitle,
          subtext: somethingWentWrongMessage,
          type: 'error',
        });
        logger.error(`Failed to decode utm settings for Facebook preview link: ${error}`);
      }
    },
    updateLinksWithUtms(newPostLink, trackingParameters, urlLinkCustomized) {
      this.customUtms = trackingParameters;
      this.urlLinkCustomized = urlLinkCustomized;
    },
    async parsePostWithUtmSettings(disableEditor = true) {
      const currentPostLinkInText = extractAllUrlsFromString(this.postText)[0];
      const previewAndPostLinksMatch = this.link === currentPostLinkInText;
      if (disableEditor) this.isUtmEncodeDecodePending = true;
      try {
        let extractedUrls = extractAllUrlsFromString(this.postText);
        let requestBody = {
          brandId: this.authStore.currentBrand.id,
          channel: utmChannel.FACEBOOK,
          content: this.postText,
          customUtms: this.customUtms,
          mediaId: this.mediaList[0]?.id,
          shortenUrl: this.shortenLinkCheckbox,
          urls: extractedUrls,
        };

        const postResponse = await LibraryAPI.encodeUtmSettings(requestBody);
        this.messageHTML = postResponse.data.content;
        // Allow messageHTML to update fully
        await nextTick();

        if (previewAndPostLinksMatch) {
          // links in post text and preview match each other
          this.link = postResponse.data.updatedLink;
        } else if (this.link) {
          extractedUrls = extractAllUrlsFromString(this.postText);
          if (isEmpty(extractedUrls)) {
            extractedUrls = [this.link];
          }
          requestBody = {
            brandId: this.authStore.currentBrand.id,
            channel: utmChannel.FACEBOOK,
            content: this.link,
            customUtms: this.customUtms,
            mediaId: this.mediaList[0]?.id,
            shortenUrl: this.shortenLinks,
            urls: extractedUrls,
          };

          // preview link is different from post link
          const linkResponse = await LibraryAPI.encodeUtmSettings(requestBody);
          this.link = linkResponse.data.content;
        }
      } catch (error) {
        this.notificationStore.setToast({
          message: somethingWentWrongTitle,
          subtext: somethingWentWrongMessage,
          type: 'error',
        });
        logger.error(`Failed to encode utm settings for Facebook post: ${error}`);
      }
      if (disableEditor) this.isUtmEncodeDecodePending = false;
    },
    async decodeUtmFromPost(modifyContent = true) {
      this.isUtmEncodeDecodePending = true;
      try {
        const extractedUrls = extractAllUrlsFromString(this.postText).filter((url) => url);
        const requestBody = {
          brandId: this.authStore.currentBrand.id,
          channel: utmChannel.FACEBOOK,
          content: this.postText,
          urls: extractedUrls,
        };

        const { data } = await LibraryAPI.decodeUtmSettings(requestBody);
        if (modifyContent) {
          this.messageHTML = data.unshortened_content ?? data.original_content;
          this.extractPostLink(this.postText);
        } else {
          this.shortenLinkCheckbox = data.has_shortened_link;
        }
        this.shortenLinks = data.has_shortened_link;
      } catch (e) {
        logger.error(`Failed to decode utm settings: ${e}`);
      }
      this.isUtmEncodeDecodePending = false;
    },
    debouncedExtractPostLink: debounce(function debouncedExtractPostLink(text) {
      this.extractPostLink(text);
    }, debounceInputDelay),
    extractPostLink(text) {
      this.postLink = extractAllUrlsFromString(text)[0];
      const showPanel = Boolean(this.postLink);
      if (this.showUtmEditorPanel !== showPanel) {
        this.showUtmEditorPanel = showPanel;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: showPanel ? 'auto open' : 'auto close',
        });
      }
    },
    toggleUtmEditorPanel() {
      this.showUtmEditorPanel = !this.showUtmEditorPanel;
      trackSchedulerUtmPopout({
        ...this.trackingData,
        action: this.showUtmEditorPanel ? 'open' : 'close',
      });
    },
    requiresApproval(post) {
      return postRequiresApproval({ post });
    },
    validateCustomCoverImage() {
      this.isValidating = true;
      this.coverImageValidationErrorMessage = validateCustomCoverImage(
        this.coverImageMedia,
        this.autoPublish,
        PLATFORMS.FACEBOOK,
      );
      this.isValidating = false;
    },
    hasTargetingData() {
      return (
        [this.postType, this.post?.postType].includes(fbPostTypes.POST) && this.post?.targeting
      );
    },
    resetThumbnail() {
      this.thumbOffset = null;
      this.thumbnailSource = null;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.controls {
  position: sticky;
  bottom: 0;
}

.container-wrapper {
  display: flex;
  min-height: 100%;
}

.video-title-field {
  font-size: var(--x14);
  border: none;
  border-bottom: 1px solid var(--border);
  margin: var(--space-12) 0;
  padding-bottom: var(--space-4);
  color: var(--text-primary);
  background: transparent;
  width: 100%;
}

.validation-banner {
  margin: var(--space-16) 0 0 0;
}

.crop-warning p {
  margin-bottom: var(--space-16);
}

.save-button {
  min-width: 10rem;
}

:deep(.auto-publish-options) {
  margin-top: 0;
}

.usage-info-banner {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--space-24);
  border-radius: var(--round-corner-small);
  font-size: var(--x14);
  margin-top: var(--space-14);
  margin-bottom: 0;
  min-height: fit-content;
  align-items: center;
}

.not-allowed-cursor {
  cursor: not-allowed;
}
</style>
