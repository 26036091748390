<script setup>
import Icon from '@/components/foundation/Icon.vue';
import { computed, toRefs } from 'vue';
import { toolTips } from '@/config';
import { colours, VISION_AI } from '@/ux/colours';
import VisionToolbar from '@/components/VisionAi/VisionToolbar.vue';
import { getPropsForIntentType, MESSAGE_WIDGET_MAP } from '@/components/VisionAi/utils/message';
import CircularLoader from '@/components/CircularLoader.vue';
import { aiMessageInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';
import {
  INTENT_TYPES,
  VISION_AI_SUMMARY_INTERACTIONS,
  REGENERATE_TOOLTIPS,
  SUMMARY_INTENT_TYPES,
} from '@/components/VisionAi/constants';
import { useIdentityStore } from '@/stores/identity';
import Button from '@/components/foundation/Button.vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import AssistantStyleWrapper from '@/components/VisionAi/AssistantStyleWrapper.vue';
import { useVisionAiMessagesStore } from '@/stores/vision-ai-messages';
import MarkdownIt from 'markdown-it';
import markdownItTaskLists from 'markdown-it-task-lists';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  messageId: {
    type: Number,
    default: null,
  },
  time: {
    type: String,
    default: '',
  },
  date: {
    type: String,
    default: '',
  },
  sourceUrl: {
    type: [String, Object],
    default: null,
  },
  regeneratingMessageLoading: {
    type: Boolean,
    default: false,
  },
  disableRegenerateButton: {
    type: Boolean,
    default: null,
  },
  intentType: {
    type: String,
    default: '',
  },
  additionalContent: {
    type: Object,
    default: null,
  },
  intentQuery: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'regenerateMessage',
  'copyResponse',
  'sourceButtonClicked',
  'expandMedia',
]);
const { sourceUrl, intentType } = toRefs(props);

// stores
const identityStore = useIdentityStore();
const visionAiStore = useVisionAiStore();
const visionAiMessagesStore = useVisionAiMessagesStore();

// consts
const disclaimerToolTipMessage = toolTips.visionAi.disclaimerMessage;

// computed
const dateTooltipMessage = computed(() => `${props.date} at ${props.time}`);

const regenerateResponseTooltip = computed(() => {
  return props.regeneratingMessageLoading || props.disableRegenerateButton
    ? REGENERATE_TOOLTIPS.LOADING
    : REGENERATE_TOOLTIPS.READY;
});

const isSamePageSource = computed(() => {
  return visionAiStore.checkIfSamePageSource(sourceUrl.value);
});

const isMetricsAnalytics = computed(() => {
  return props.intentType === INTENT_TYPES.METRIC_ANALYTICS;
});

const isSummaryIntent = computed(() => {
  return SUMMARY_INTENT_TYPES.includes(props.intentType);
});

const sourceTooltipMessage = computed(() => {
  return isSamePageSource.value
    ? toolTips.visionAi.disabledSourceButton
    : toolTips.visionAi.activeSourceButton;
});

const showRegenerateButton = computed(() => {
  if (isSummaryIntent.value) return true;

  const mostRecentMessage =
    visionAiMessagesStore.messages?.[visionAiMessagesStore.messages.length - 1];
  return props.messageId === mostRecentMessage?.id;
});

const assistantToolbarIcons = computed(() => {
  const icons = [
    {
      iconName: 'copy',
      iconColor: colours.TEXT.TEXT_LINK,
      tooltip: 'Copy response',
      showBorderOnHover: true,
      buttonClass: 'hover:bg-opacity-80',
      clickEvent: () => {
        aiMessageInteractionEvent({
          interaction: VISION_AI_SUMMARY_INTERACTIONS.COPY,
          intentType: props.intentType,
          messageId: props.messageId,
        });
        emit('copyResponse', props.text);
      },
      iconWidth: '18.67px',
      iconHeight: '18.67px',
    },
  ];
  if (showRegenerateButton.value) {
    icons.push({
      iconName: 'replay-2',
      iconColor: colours.TEXT.TEXT_LINK,
      iconHoverColor: colours.TEXT.TEXT_LINK,
      tooltip: regenerateResponseTooltip.value,
      showBorderOnHover: true,
      buttonClass: 'hover:bg-opacity-80 m-0',
      clickEvent: () => {
        aiMessageInteractionEvent({
          interaction: VISION_AI_SUMMARY_INTERACTIONS.REGENERATE,
          intentType: props.intentType,
          messageId: props.messageId,
        });
        emit('regenerateMessage', props.messageId);
      },
      iconWidth: '19.42px',
      iconHeight: '16.67px',
      iconLoading: props.regeneratingMessageLoading,
      disabled: props.disableRegenerateButton,
    });
  }
  return icons;
});

const widgetComponent = computed(() => {
  return MESSAGE_WIDGET_MAP[props.intentType];
});

const widgetProps = computed(() => {
  const content = { ...props.additionalContent };

  if (props.intentType === INTENT_TYPES.COMMENTS) {
    const brandLabel = props.intentQuery?.sourceLinkParams?.brandLabel;
    const brandAvatarUrl = identityStore.identity.brands[brandLabel]?.avatarUrl;

    if (content?.media?.source && content.media.source.split('_')[0] === 'TWITTER') {
      content.brandAvatarUrl = content.media.userAvatar || brandAvatarUrl;
    } else {
      content.brandAvatarUrl = brandAvatarUrl;
    }
  }
  const additionalProps = {
    isExpanded: visionAiStore.popupIsExpanded,
    hasConversationSidebarExpanded: visionAiStore.showConversationHistorySidebar,
  };

  return getPropsForIntentType(props.intentType, content, props.intentQuery, additionalProps);
});

const showExpandViewButton = computed(
  () => !visionAiStore.popupIsExpanded && visionAiStore.canAccessConversational,
);

const useLegacyHTML = computed(() => {
  // NOTE: Legacy responses always start with HTML tags, temp workaround
  return props.text.startsWith('<p') || props.text.endsWith('/ul>');
});

const markdownResponse = computed(() => {
  const markdown = new MarkdownIt({
    linkify: true,
    typographer: true,
  }).use(markdownItTaskLists, { enabled: true });

  return markdown.render(props.text);
});

// methods
function handleSourceButtonClicked() {
  emit('sourceButtonClicked', intentType.value, sourceUrl.value);
  aiMessageInteractionEvent({
    interaction: VISION_AI_SUMMARY_INTERACTIONS.SOURCE_LINK,
    intentType: props.intentType,
    messageId: props.messageId,
  });
}

function handleDownloadButtonClicked() {
  aiMessageInteractionEvent({
    interaction: VISION_AI_SUMMARY_INTERACTIONS.DOWNLOAD_MEDIA,
    intentType: props.intentType,
    messageId: props.messageId,
  });
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        disclaimerToolTipMessage,
        isSamePageSource,
        sourceTooltipMessage,
        isMetricsAnalytics,
      }
    : {},
);
</script>

<template>
  <AssistantStyleWrapper class="w-full" data-cy="assistant-message">
    <span v-if="useLegacyHTML" v-sanitize-html="props.text" class="sanitize-html-string" />
    <!-- eslint-disable vue/no-v-html -->
    <div v-else class="response-markdown" v-html="markdownResponse" />
    <VisionToolbar :clickable-icons="assistantToolbarIcons">
      <template
        #clickableIcons="{
          iconName,
          iconColor,
          iconHeight,
          iconWidth,
          iconHoverColor,
          iconLoading,
        }"
      >
        <CircularLoader v-if="iconLoading" />
        <Icon
          v-else
          :name="iconName"
          :color="iconColor"
          :hover-color="iconHoverColor"
          :height="iconHeight"
          :width="iconWidth"
        />
      </template>
    </VisionToolbar>

    <template v-if="props.additionalContent">
      <div class="h-[1px] w-full bg-black bg-opacity-[12%]"></div>
      <component
        :is="widgetComponent"
        v-bind="widgetProps"
        @download-button-clicked="handleDownloadButtonClicked"
      >
      </component>
    </template>

    <Button
      v-if="showExpandViewButton"
      link
      class="cursor-pointer text-[--text-link] no-underline"
      data-cy="expand-view-button"
      @click="emit('expandMedia')"
      >Expand to view report</Button
    >

    <VisionToolbar class="toolbar w-full">
      <template #leftIcons>
        <div class="flex w-60 items-center gap-3">
          <div v-tooltip="dateTooltipMessage" class="text-extra-small cursor-default">
            {{ props.time }}
          </div>

          <div
            v-if="props.sourceUrl && !isMetricsAnalytics"
            v-tooltip="sourceTooltipMessage"
            class="flex items-center"
          >
            <Button
              id="source-url"
              :to="props.sourceUrl"
              class="flex items-center no-underline"
              aria-label="View source for this summary"
              :style="{ color: VISION_AI.VISION_AI_03 }"
              :disabled="isSamePageSource"
              link
              :class="{ disabled: isSamePageSource }"
              @click="handleSourceButtonClicked"
            >
              <Icon name="link" xsmall :color="VISION_AI.VISION_AI_03" />
              <span class="text-extra-small-medium ml-1">Source</span>
            </Button>
          </div>
          <Icon
            v-tooltip="{
              content: disclaimerToolTipMessage,
              theme: 'dh-tooltip-small',
            }"
            name="info"
            xsmall
          />
        </div>
      </template>
    </VisionToolbar>
  </AssistantStyleWrapper>
</template>

<style lang="postcss" scoped>
.sanitize-html-string,
.response-markdown {
  :deep() {
    b,
    strong {
      font-weight: bold;
      font-size: inherit;
    }

    p:not(:last-child) {
      margin-bottom: var(--space-16);
    }

    i,
    em {
      font-style: italic;
      font-size: inherit;
    }

    ul {
      list-style-type: disc;
      padding-left: var(--space-20);
    }
  }
}

.response-markdown {
  :deep() {
    ol {
      list-style-type: decimal;
      padding-left: var(--space-20);

      & > li > ol {
        list-style-type: lower-alpha;

        & > li > ol {
          list-style-type: lower-roman;
        }
      }
    }

    .task-list-item {
      list-style-type: none;
    }

    .task-list-item input {
      padding-left: var(--space-20);
    }

    table {
      background-color: var(--white);
      border-collapse: separate;
      border-radius: var(--round-corner);
      box-shadow: var(--shadow-1);
      margin-bottom: var(--space-16);
      padding: var(--space-16);
      width: 100%;

      thead tr th {
        color: var(--text-secondary);
        font-weight: var(--font-medium);
        padding: var(--space-8) 0;
      }

      thead tr th,
      tbody tr td {
        border-bottom: 1px solid var(--border);
        font-size: var(--x14);
      }

      tbody tr td {
        padding: var(--space-16) 0;
      }

      tbody tr:last-child > td {
        border-bottom: none;
      }
    }

    blockquote {
      border-left: var(--space-4) solid var(--border);
      padding-left: var(--space-8);
      color: var(--text-secondary);
      font-style: italic;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: var(--space-24);
    }

    blockquote,
    h1:has(+ p, + ul, + ol),
    h2:has(+ p, + ul, + ol),
    h3:has(+ p, + ul, + ol),
    h4:has(+ p, + ul, + ol),
    h5:has(+ p, + ul, + ol),
    h6:has(+ p, + ul, + ol),
    ul:has(+ p, + ul, + ol),
    ol:has(+ p, + ul, + ol),
    p:last-child {
      margin-bottom: var(--space-16);
    }

    p:has(+ h1, + h2, + h3, + h4, + h5, + h6),
    ul:has(+ h1, + h2, + h3, + h4, + h5, + h6),
    ol:has(+ h1, + h2, + h3, + h4, + h5, + h6) {
      margin-bottom: var(--space-24);
    }

    h1 {
      font-size: var(--x32);
    }

    h2 {
      font-size: var(--x28);
    }

    h3 {
      font-size: var(--x24);
    }

    h4 {
      font-size: var(--x22);
    }

    h5 {
      font-size: var(--x18);
      font-weight: var(--font-bold);
    }

    h6 {
      font-size: var(--x16);
      font-weight: var(--font-bold);
    }

    a {
      font-weight: var(--font-normal);
    }

    hr {
      width: 33%;
      margin: 0 auto var(--space-24) auto;
    }
  }
}

.toolbar {
  .disabled {
    pointer-events: none;
    cursor: default;
  }
}
</style>
