import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import { SENTIMENT_LABEL_BY_TYPE } from '@/app/community/constants';
import { toolTips } from '@/config';

const promotedInsightsTooltips = {
  amountSpent:
    "The total amount of money you've spent on promoted posts published during the selected date range",
  cpm:
    'The average cost per 1,000 impressions on your promoted posts published during the selected date range\n' +
    '\n' +
    'CPM = Amount Spent / Promoted Impressions x 1,000',
  cpc:
    'The average cost per click of your promoted posts published during the selected date range\n' +
    '\n' +
    'CPC = Amount Spent / Promoted Clicks',
  ctr:
    'The percentage of times people clicked your promoted posts published during the selected date range\n' +
    '\n' +
    'CTR = Clicks / Promoted Impressions',
  comments:
    'The total number of times people commented on your promoted posts published during the selected date range',
  engagements:
    'The total number of engagements on your organic and promoted posts published during the selected date range\n' +
    '\n' +
    'Engagements = (Likes + Saves + Comments + Shares) - (Unlikes + Unsaves + Deleted Comments)\n' +
    '\n' +
    'Note: Promoted unsaves are unavailable',
  impressions:
    'The total number of impressions your promoted posts published during the selected date range received',
  likes:
    'The total number of times people liked your promoted posts published during the selected date range',
  reach:
    'The total number of unique accounts that saw your promoted posts published during the selected date range',
  shares:
    'The total number of times people shared your promoted posts published during the selected date range',
  videoPlays:
    'The total number times your promoted videos published during the reporting period were played for at least 1ms. This excludes replays.',
};
const promotedTopLineStatsTooltips = {
  plays:
    'The total number times your organic and promoted videos published during the reporting period were played for at least 1ms. This excludes replays. This may include views from IGTV.',
  netNewFollowers:
    'The net total of followers your account has accumulated during the selected date range',
  engagements:
    'The total number of engagements on your organic and promoted posts published during the selected date range\n' +
    '\n' +
    'Engagements = (Likes + Saves + Comments + Shares) - (Unlikes + Unsaves + Deleted Comments)\n' +
    '\n' +
    'Note: Promoted unsaves are unavailable',
  avgEngagementRate:
    'The average engagement rate for your organic posts published during the selected date range\n' +
    '\n' +
    'Engagement Rate = Engagements / Followers',
  avgEffectiveness:
    'The average effectiveness rate for your organic posts published during the reporting period\n' +
    '\n' +
    'Average Effectiveness = (Likes + Comments + Saves + Shares) / Reach\n' +
    '\n' +
    'Reels Effectiveness Rate = (Likes + Comments + Saves + Shares + Video Views) / Estimated Reach',
  avgReach:
    'The average number of unique accounts that saw your organic and promoted posts published during the selected date range',
  impressions:
    'The total number of impressions for your organic and promoted posts published during the selected date range',
  views:
    "The number of times your posts were displayed on a person's screen. This includes repeat views",
  avgEffectivenessEngagements:
    'The average percentage of users that saw your posts and engaged with them\n' +
    '\n' +
    'Avg. Effectiveness = Engagements / Reach',
  shares: 'The number of times your posts were shared',
};

const routeNames = {
  STORY_OVERVIEW: 'instagram.yourStories.storyOverview',
  STORY_FRAMES: 'instagram.yourStories.storyFrames',
  STORY_BOARDS: 'instagram.yourStories.storyBoards',
  STORY_BOARDS_DETAILS: 'instagram.yourStories.storyBoardsDetails',
  STORY_BOARDS_DETAILS_EDIT: 'instagram.yourStories.storyBoardsDetails.edit',
  STORY_NARRATIVE: 'instagram.yourStories.narrative',
  STORY_NARRATIVE_POPUP: 'instagram.yourStories.narrativePopup',
  STORY_OVERVIEW_REPORT: 'instagram.yourStories.storyOverviewReport',
  STORY_METRICS_REPORT: 'instagram.yourStories.storyMetricsReport',
  STORY_NARRATIVE_REPORT: 'instagram.yourStories.storyNarrativeReport',
  STORY_BOARD_REPORT: 'instagram.yourStories.storyBoardReport',
  INSIGHTS_YOUR_POSTS: 'instagram.insights.yourPosts',
  INSIGHTS_UGC: 'instagram.ugc.reach',
  LIKESHOP: 'instagram.likeshop',
};

const pdfReportTypes = {
  STORY_OVERVIEW_REPORT: 'instagramStoryOverview',
  STORY_METRICS_REPORT: 'instagramStoryMetrics',
  STORY_BOARDS_REPORT: 'instagramStoryBoards',
};

const filters = {
  POST_TYPE: 'is_boosted',
  MEDIA_TYPE: 'media_types',
  CAPTION: 'caption_keywords',
  SWIPE_UP_LINK: 'swipe_up_link',
  UGC_TYPE: 'ugc_media_type',
  UGC_POST_TYPE: 'post_types',
  IS_RIQ: 'is_riq',
};

const instagramStoryFrameMetricKeys = {
  REACH: 'INSTAGRAM_STORY_FRAME_REACH',
  IMPRESSIONS: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
  COMPLETION_RATE: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
  EXIT_RATE: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
  EXITS: 'INSTAGRAM_STORY_FRAME_EXITS',
  SWIPE_UPS: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
  REPLIES: 'INSTAGRAM_STORY_FRAME_REPLIES',
};

const instagramPostTypes = {
  REEL: 'REEL',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  CAROUSEL: 'CAROUSEL',
};

const instagramStoryFrameMetrics = {};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.REACH] = {
  field: 'reach',
  label: 'Reach',
};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.IMPRESSIONS] = {
  field: 'views',
  label: 'Impressions',
};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.COMPLETION_RATE] = {
  field: 'completionRate',
  label: 'Completion Rate',
  format: 'percent',
};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.EXIT_RATE] = {
  field: 'exitRate',
  label: 'Exit Rate',
  format: 'percent',
};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.EXITS] = {
  field: 'exits',
  label: 'Exits',
};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.SWIPE_UPS] = {
  field: 'swipeUps',
  label: 'Link Clicks',
};
instagramStoryFrameMetrics[instagramStoryFrameMetricKeys.REPLIES] = {
  field: 'replies',
  label: 'Replies',
  tooltip:
    'Due to API restrictions, replies from users in the European Economic Area (EEA) or Japan will no longer be included. For stories created in the EEA or Japan, Replies will now return a value of 0.',
};

const instagramStoryBoardFilterWidgets = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

const instagramStoryBoardFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

const instagramPostMetricLabelsDeprecated = {
  AVG_REACH: 'Avg. Reach',
  REACH: 'Reach',
  ESTIMATED_REACH: 'Estimated Reach',
  AVG_EFFECTIVENESS: 'Avg. Effectiveness',
  EFFECTIVENESS: 'Effectiveness',
  AVG_ENGAGEMENT_RATE: 'Avg. Engagement Rate',
  ENGAGEMENT_RATE: 'Engagement Rate',
  IMPRESSIONS: 'Impressions',
  ORGANIC_IMPRESSIONS: 'Impressions - Organic',
  PAID_IMPRESSIONS: 'Impressions - Promoted',
  TOTAL_IMPRESSIONS: 'Impressions - Total',
  ENGAGEMENTS: 'Engagements',
  TOTAL_ENGAGEMENTS: 'Total Engagements - Organic',
  SUM_TOTAL_ENGAGEMENTS: 'Total Engagements',
  PAID_TOTAL_ENGAGEMENTS: 'Total Engagements - Promoted ',
  LIKES: 'Likes',
  ORGANIC_LIKES: 'Likes - Organic',
  PAID_LIKES: 'Likes - Promoted',
  TOTAL_LIKES: 'Likes - Total',
  COMMENTS: 'Comments',
  ORGANIC_COMMENTS: 'Comments - Organic',
  PAID_COMMENTS: 'Comments - Promoted',
  TOTAL_COMMENTS: 'Comments - Total ',
  SAVED: 'Saved', // deprecated
  SAVES: 'Saves',
  LIKESHOP_CLICKS: 'LikeShop Clicks',
  VIDEO_VIEWS: 'Video Views',
  ORGANIC_VIDEO_VIEWS: 'Video Views - Organic',
  PAID_VIDEO_VIEWS: 'Video Views - Promoted',
  TOTAL_VIDEO_VIEWS: 'Video Views - Total',
  ENTERTAINMENT_SCORE: 'Entertainment Score',
};

const instagramPostMetricLabels = {
  LIKESHOP_CLICKS: 'LikeShop Clicks',
  AVG_REACH: 'Avg. Reach',
  ESTIMATED_REACH: 'Estimated Reach',
  AVG_EFFECTIVENESS: 'Avg. Effectiveness',
  AVG_ENGAGEMENT_RATE: 'Avg. Engagement Rate',
  ORGANIC_AVG_WATCH_TIME: 'Avg. Watch Time',
  ORGANIC_COMMENTS: 'Comments - Organic',
  ORGANIC_EFFECTIVENESS: 'Effectiveness - Organic',
  ORGANIC_ENGAGEMENT_RATE_FOLLOWERS: 'Engagement Rate - Organic (Followers)',
  ORGANIC_ENGAGEMENT_RATE_VIEWS: 'Engagement Rate - Organic (Views)',
  ORGANIC_ENGAGEMENTS: 'Engagements - Organic',
  ENTERTAINMENT_SCORE: 'Entertainment Score',
  ORGANIC_LIKES: 'Likes - Organic',
  ORGANIC_REACH: 'Reach - Organic',
  ORGANIC_SAVES: 'Saves - Organic',
  ORGANIC_SHARES: 'Shares - Organic',
  ORGANIC_VIEWS: 'Views - Organic',
  PAID_COMMENTS: 'Comments - Promoted',
  CPC: 'CPC',
  CPM: 'CPM',
  CTR: 'CTR',
  PAID_ENGAGEMENT_RATE: 'Engagement Rate - Promoted (Impressions)',
  PAID_ENGAGEMENTS: 'Engagements - Promoted',
  PAID_IMPRESSIONS: 'Impressions - Promoted',
  PAID_LIKES: 'Likes - Promoted',
  PAID_PLAYS: 'Plays - Promoted',
  PAID_REACH: 'Reach - Promoted',
  PAID_SAVES: 'Saves - Promoted',
  PAID_SHARES: 'Shares - Promoted',
  TOTAL_COMMENTS: 'Comments - Total',
  TOTAL_ENGAGEMENTS: 'Engagements - Total',
  TOTAL_LIKES: 'Likes - Total',
  TOTAL_REACH: 'Reach - Total',
  TOTAL_SAVES: 'Saves - Total',
  TOTAL_SHARES: 'Shares - Total',
  VIEWS: 'Views',
  DISCONTINUED_ORGANIC_IMPRESSIONS: 'Impressions - Organic (Discontinued)',
  DISCONTINUED_ORGANIC_EFFECTIVENESS: 'Effectiveness - Organic (Discontinued)',
  DISCONTINUED_ENGAGEMENT_RATE_IMPRESSIONS:
    'Engagement Rate - Organic (Impressions) (Discontinued)',
  DISCONTINUED_ORGANIC_PLAYS: 'Plays - Organic (Discontinued)',
  DISCONTINUED_ORGANIC_ALL_PLAYS: 'All Plays - Organic (Discontinued)',
  DISCONTINUED_TOTAL_IMPRESSIONS: 'Impressions - Total (Discontinued)',
  DISCONTINUED_TOTAL_PLAYS: 'Plays - Total (Discontinued)',
};

const instagramPostMetricLabelsShortForms = {
  ...cloneDeep(instagramPostMetricLabels),
};
instagramPostMetricLabelsShortForms.ORGANIC_ENGAGEMENT_RATE_FOLLOWERS =
  'Engagement Rate - Organic (F)';
instagramPostMetricLabelsShortForms.ORGANIC_ENGAGEMENT_RATE_VIEWS = 'Engagement Rate - Organic (V)';
instagramPostMetricLabelsShortForms.PAID_ENGAGEMENT_RATE = 'Engagement Rate - Promoted (I)';

const postMetricFields = {
  ORGANIC_AVG_WATCH_TIME: 'avgTimeViewedSec',
  ORGANIC_COMMENTS: 'commentsCount',
  ORGANIC_EFFECTIVENESS: 'effectivenessEngagements',
  ORGANIC_ENGAGEMENT_RATE_FOLLOWERS: 'engagement',
  ORGANIC_ENGAGEMENT_RATE_VIEWS: 'engagementRateViews',
  ORGANIC_ENGAGEMENTS: 'totalEngagement',
  ENTERTAINMENT_SCORE: 'entertainmentScore',
  ORGANIC_LIKES: 'likeCount',
  ORGANIC_REACH: 'reach',
  ORGANIC_SAVES: 'saved',
  ORGANIC_SHARES: 'shares',
  ORGANIC_VIEWS: 'views',
  PAID_COMMENTS: 'paidComments',
  CPC: 'cpc',
  CPM: 'cpm',
  CTR: 'ctr',
  PAID_ENGAGEMENT_RATE: 'paidEngagementRate',
  PAID_ENGAGEMENTS: 'paidTotalEngagements',
  PAID_IMPRESSIONS: 'paidImpressions',
  PAID_LIKES: 'paidLikes',
  PAID_PLAYS: 'paidVideoViews',
  PAID_REACH: 'paidReach',
  PAID_SAVES: 'paidSaved',
  PAID_SHARES: 'paidShares',
  TOTAL_COMMENTS: 'totalComments',
  TOTAL_ENGAGEMENTS: 'sumTotalEngagements',
  TOTAL_LIKES: 'totalLikes',
  TOTAL_REACH: 'paidAndOrganicReach',
  TOTAL_SAVES: 'paidAndOrganicSaved',
  TOTAL_SHARES: 'paidAndOrganicShares',
  DISCONTINUED_ORGANIC_IMPRESSIONS: 'impressions',
  DISCONTINUED_ORGANIC_EFFECTIVENESS: 'effectiveness',
  DISCONTINUED_ENGAGEMENT_RATE_IMPRESSIONS: 'engagementRateImpressions',
  DISCONTINUED_ORGANIC_PLAYS: 'videoViews',
  DISCONTINUED_ORGANIC_ALL_PLAYS: 'totalPlays',
  DISCONTINUED_TOTAL_IMPRESSIONS: 'totalImpressions',
  DISCONTINUED_TOTAL_PLAYS: 'totalVideoViews',
};

const storyOverviewMetricKeys = {
  STORY_FRAMES_COUNT: 'IG_STORY_FRAMES_COUNT',
  AVG_REACH: 'IG_STORY_AVG_REACH',
  AVG_IMPRESSIONS: 'IG_STORY_AVG_IMPRESSIONS',
  TOTAL_IMPRESSIONS: 'IG_STORY_TOTAL_IMPRESSIONS',
  AVG_COMPLETION_RATE: 'IG_STORY_AVG_COMPLETION_RATE',
  AVG_EXIT_RATE: 'IG_STORY_AVG_EXIT_RATE',
  SWIPE_UPS: 'IG_STORY_SWIPE_UPS',
  TOTAL_REPLIES: 'TOTAL_REPLIES',
};

const dashboardMetricTypes = {
  AMOUNT_SPENT: 'TOTAL_SPENT',
  COST_PER_CLICK: 'COST_PER_CLICK',
  COST_PER_MILE: 'COST_PER_MILE',
  CLICK_THROUGH_RATE: 'CLICK_THROUGH_RATE',
  // engagements
  TOTAL_ENGAGEMENTS: 'TOTAL_ENGAGEMENTS', // organic
  ORGANIC_ENGAGEMENTS_POSTS_PAID: 'ORGANIC_ENGAGEMENTS_POSTS_PAID',
  PAID_TOTAL_ENGAGEMENTS: 'PAID_TOTAL_ENGAGEMENTS',
  SUM_TOTAL_ENGAGEMENTS: 'SUM_TOTAL_ENGAGEMENTS',
  TOTAL_ENGAGEMENTS_POSTS_PAID: 'TOTAL_ENGAGEMENTS_POSTS_PAID',
  // impressions
  IMPRESSIONS: 'IMPRESSIONS',
  ORGANIC_IMPRESSIONS_POSTS_PAID: 'ORGANIC_IMPRESSIONS_POSTS_PAID',
  PAID_IMPRESSIONS: 'PAID_IMPRESSIONS',
  TOTAL_IMPRESSIONS: 'TOTAL_IMPRESSIONS',
  TOTAL_IMPRESSIONS_POSTS_PAID: 'TOTAL_IMPRESSIONS_POSTS_PAID',
  // likes
  ORGANIC_LIKES: 'ORGANIC_LIKES',
  ORGANIC_LIKES_POSTS_PAID: 'ORGANIC_LIKES_POSTS_PAID',
  PAID_LIKES: 'PAID_LIKES',
  TOTAL_LIKES: 'TOTAL_LIKES',
  TOTAL_LIKES_POSTS_PAID: 'TOTAL_LIKES_POSTS_PAID',
  // comments
  ORGANIC_COMMENTS: 'ORGANIC_COMMENTS',
  ORGANIC_COMMENTS_POSTS_PAID: 'ORGANIC_COMMENTS_POSTS_PAID',
  PAID_COMMENTS: 'PAID_COMMENTS',
  TOTAL_COMMENTS: 'TOTAL_COMMENTS',
  TOTAL_COMMENTS_POSTS_PAID: 'TOTAL_COMMENTS_POSTS_PAID',
  // video views
  ORGANIC_VIDEO_VIEWS: 'ORGANIC_VIDEO_VIEWS',
  ORGANIC_VIDEO_VIEWS_POSTS_PAID: 'ORGANIC_VIDEO_VIEWS_POSTS_PAID',
  PAID_VIDEO_VIEWS: 'PAID_VIDEO_VIEWS',
  TOTAL_VIDEO_VIEWS: 'TOTAL_VIDEO_VIEWS',
  TOTAL_VIDEO_VIEWS_POSTS_PAID: 'TOTAL_VIDEO_VIEWS_POSTS_PAID',
  // followers
  TOTAL_FOLLOWERS: 'TOTAL_FOLLOWERS',
  NET_NEW_FOLLOWERS: 'NET_NEW_FOLLOWERS',
  // reach
  ORGANIC_REACH: 'TOTAL_REACH',
  ORGANIC_REACH_POSTS_PAID: 'ORGANIC_REACH_POSTS_PAID',
  PAID_REACH: 'TOTAL_PAID_REACH',
  TOTAL_REACH: 'TOTAL_PAID_AND_ORGANIC_REACH',
  TOTAL_REACH_POSTS_PAID: 'TOTAL_REACH_POSTS_PAID',
  // shares
  ORGANIC_SHARES: 'SHARES',
  ORGANIC_SHARES_POSTS_PAID: 'ORGANIC_SHARES_POSTS_PAID',
  PAID_SHARES: 'PAID_SHARES',
  TOTAL_SHARES: 'TOTAL_SHARES',
  TOTAL_SHARES_POSTS_PAID: 'TOTAL_SHARES_POSTS_PAID',
  // avg. reach
  AVG_REACH: 'AVG_REACH',
  AVG_PAID_REACH: 'AVG_PAID_REACH',
  AVG_PAID_AND_ORGANIC_REACH: 'AVG_PAID_AND_ORGANIC_REACH',
  // other
  AVG_ENGAGEMENT_RATE: 'AVG_ENGAGEMENT_RATE',
  AVG_EFFECTIVENESS: 'AVG_EFFECTIVENESS',
  SAVES: 'SAVES',
  NUMBER_OF_POSTS: 'NUMBER_OF_POSTS',
  NUMBER_OF_POSTS_PAID: 'NUMBER_OF_POSTS_PAID',
  AVG_ENTERTAINMENT_SCORE: 'AVG_ENTERTAINMENT_SCORE_REEL',
  // Meta API V22 metrics update
  VIEWS: 'VIEWS',
  AVG_EFFECTIVENESS_ENGAGEMENTS: 'AVG_EFFECTIVENESS_ENGAGEMENTS',
  AVG_AVG_WATCH_TIME: 'AVG_AVG_WATCH_TIME',
};

const dashboardFollowersReportMetrics = [
  dashboardMetricTypes.TOTAL_FOLLOWERS,
  dashboardMetricTypes.NET_NEW_FOLLOWERS,
];

const dashboardMetricReportMetrics = {
  TOTAL_ENGAGEMENTS: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
  PAID_TOTAL_ENGAGEMENTS: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
  SUM_TOTAL_ENGAGEMENTS: dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
  AVG_ENGAGEMENT_RATE: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
  AVG_EFFECTIVENESS: dashboardMetricTypes.AVG_EFFECTIVENESS,
  AVG_REACH: dashboardMetricTypes.AVG_REACH,
  IMPRESSIONS: dashboardMetricTypes.IMPRESSIONS,
  PAID_IMPRESSIONS: dashboardMetricTypes.PAID_IMPRESSIONS,
  TOTAL_IMPRESSIONS: dashboardMetricTypes.TOTAL_IMPRESSIONS,
  ORGANIC_LIKES: dashboardMetricTypes.ORGANIC_LIKES,
  TOTAL_LIKES: dashboardMetricTypes.TOTAL_LIKES,
  PAID_LIKES: dashboardMetricTypes.PAID_LIKES,
  ORGANIC_COMMENTS: dashboardMetricTypes.ORGANIC_COMMENTS,
  PAID_COMMENTS: dashboardMetricTypes.PAID_COMMENTS,
  TOTAL_COMMENTS: dashboardMetricTypes.TOTAL_COMMENTS,
  SAVES: dashboardMetricTypes.SAVES,
  ORGANIC_VIDEO_VIEWS: dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
  PAID_VIDEO_VIEWS: dashboardMetricTypes.PAID_VIDEO_VIEWS,
  TOTAL_VIDEO_VIEWS: dashboardMetricTypes.TOTAL_VIDEO_VIEWS,
  NUMBER_OF_POSTS: dashboardMetricTypes.NUMBER_OF_POSTS,
  VIEWS: dashboardMetricTypes.VIEWS,
  AVG_EFFECTIVENESS_ENGAGEMENTS: dashboardMetricTypes.AVG_EFFECTIVENESS_ENGAGEMENTS,
  AVG_AVG_WATCH_TIME: dashboardMetricTypes.AVG_AVG_WATCH_TIME,
};

const dashboardContentReportMetrics = {
  ENGAGEMENT_RATE: 'ENGAGEMENT_RATE',
  TOTAL_ENGAGEMENTS: 'TOTAL_ENGAGEMENTS',
  EFFECTIVENESS: 'EFFECTIVENESS',
  UGC_ESTIMATED_REACH: 'UGC_ESTIMATED_REACH',
};

const boostedInsightsKeysLabels = {
  effectiveness: 'Effectiveness - Organic',
  engagementRate: 'Engagement Rate - Organic',
  reach: 'Reach - Organic',
  saved: 'Saves - Organic',
  shares: 'Shares - Organic',
  follows: 'Follows - Organic',
  profileVisits: 'Profile Visits - Organic',
  profileClicks: 'Profile Clicks - Organic',
  bioLinkClicks: 'Bio Link Clicks - Organic',
  calls: 'Calls - Organic',
  directions: 'Directions - Organic',
  emails: 'Emails - Organic',
  other: 'Other - Organic',
  text: 'Text - Organic',
  totalEngagements: 'Engagements',
  organicImpressions: 'Impressions - Organic',
  paidImpressions: 'Impressions - Promoted',
  organicTotalEngagements: 'Engagements - Organic',
  paidTotalEngagements: 'Engagements - Promoted',
  organicLikes: 'Likes - Organic',
  paidLikes: 'Likes - Promoted',
  organicComments: 'Comments - Organic',
  paidComments: 'Comments - Promoted',
  organicVideoViews: 'Video Views - Organic',
  paidVideoViews: 'Video Views - Promoted',
};

const organicInsightsKeysLabelsDeprecated = {
  saved: 'Saves - Organic',
  totalEngagements: 'Engagements - Organic',
  engagementRate: 'Engagement Rate - Organic (F)',
  engagementRateImpressions: 'Engagement Rate - Organic (I)',
};

const organicInsightsKeysLabels = {
  ...organicInsightsKeysLabelsDeprecated,
  effectiveness: 'Effectiveness - Organic (D)',
  engagementRateImpressions: 'Engagement Rate - Organic (I) (D)',
  impressions: 'Impressions - Organic (D)',
  videoViews: 'Video Views - Organic (D)',
  effectivenessEngagements: 'Effectiveness - Organic',
  engagementRateViews: 'Engagement Rate - Organic (V)',
  views: 'Views - Organic',
};

const overviewLikeShopPostsLimit = 6;
const overviewLikeshopPostsSorting = '-CLICKS';
const overviewTopPostsLimit = 5;
const overviewTopUgcCsvFilePrefix = 'top_ugc_csv';
const overviewMostEntertainingPostsLimit = 8;

const overviewTopLineMetricsDeprecated = [
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
      promoted: dashboardMetricTypes.PAID_VIDEO_VIEWS,
      total: dashboardMetricTypes.TOTAL_VIDEO_VIEWS,
    },
    value: dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
    label: 'Plays',
    percent: false,
    tooltip: toolTips.instagramOverview.totalVideoViews,
  },
  {
    keys: {
      netNew: dashboardMetricTypes.NET_NEW_FOLLOWERS,
      total: dashboardMetricTypes.TOTAL_FOLLOWERS,
    },
    value: dashboardMetricTypes.NET_NEW_FOLLOWERS,
    label: 'Net New Followers',
    percent: false,
    tooltip: toolTips.instagramOverview.netNewFollowers,
  },
  {
    keys: {
      organic: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
      promoted: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
      total: dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
    },
    value: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
    label: 'Engagements',
    percent: false,
    tooltip: toolTips.instagramOverview.totalEngagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    },
    value: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    label: 'Avg. Engagement Rate',
    percent: true,
    tooltip: toolTips.instagramOverview.averageEngagementRate,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_EFFECTIVENESS,
    },
    value: dashboardMetricTypes.AVG_EFFECTIVENESS,
    label: 'Avg. Effectiveness',
    percent: true,
    tooltip: toolTips.instagramOverview.averageEffectiveness,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_REACH,
    },
    value: dashboardMetricTypes.AVG_REACH,
    label: 'Avg. Reach',
    percent: false,
    tooltip: toolTips.instagramOverview.averageReach,
  },
  {
    keys: {
      organic: dashboardMetricTypes.IMPRESSIONS,
      promoted: dashboardMetricTypes.PAID_IMPRESSIONS,
      total: dashboardMetricTypes.TOTAL_IMPRESSIONS,
    },
    value: dashboardMetricTypes.IMPRESSIONS,
    label: 'Impressions',
    percent: false,
    tooltip: toolTips.instagramOverview.impressions,
  },
];

const overviewTopLineMetrics = [
  {
    keys: {
      organic: dashboardMetricTypes.VIEWS,
    },
    value: dashboardMetricTypes.VIEWS,
    label: 'Views',
    percent: false,
    tooltip: toolTips.instagramOverview.views,
  },
  {
    keys: {
      netNew: dashboardMetricTypes.NET_NEW_FOLLOWERS,
      total: dashboardMetricTypes.TOTAL_FOLLOWERS,
    },
    value: dashboardMetricTypes.NET_NEW_FOLLOWERS,
    label: 'Net New Followers',
    percent: false,
    tooltip: toolTips.instagramOverview.netNewFollowers,
  },
  {
    keys: {
      organic: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
      promoted: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
      total: dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
    },
    value: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
    label: 'Engagements',
    percent: false,
    tooltip: toolTips.instagramOverview.totalEngagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    },
    value: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    label: 'Avg. Engagement Rate',
    percent: true,
    tooltip: toolTips.instagramOverview.averageEngagementRate,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_EFFECTIVENESS_ENGAGEMENTS,
    },
    value: dashboardMetricTypes.AVG_EFFECTIVENESS_ENGAGEMENTS,
    label: 'Avg. Effectiveness',
    percent: true,
    tooltip: toolTips.instagramOverview.avgEffectivenessEngagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_REACH,
    },
    value: dashboardMetricTypes.AVG_REACH,
    label: 'Avg. Reach',
    percent: false,
    tooltip: toolTips.instagramOverview.averageReach,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_SHARES,
      promoted: dashboardMetricTypes.PAID_SHARES,
      total: dashboardMetricTypes.TOTAL_SHARES,
    },
    value: dashboardMetricTypes.TOTAL_SHARES,
    label: 'Shares',
    percent: false,
    tooltip: toolTips.instagramOverview.shares,
  },
];

const promotedOverviewTopLineMetricsDeprecated = [
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
      promoted: dashboardMetricTypes.PAID_VIDEO_VIEWS,
      total: dashboardMetricTypes.TOTAL_VIDEO_VIEWS,
    },
    value: dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
    label: 'Plays',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.plays,
  },
  {
    keys: {
      netNew: dashboardMetricTypes.NET_NEW_FOLLOWERS,
      total: dashboardMetricTypes.TOTAL_FOLLOWERS,
    },
    value: dashboardMetricTypes.NET_NEW_FOLLOWERS,
    label: 'Net New Followers',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.netNewFollowers,
  },
  {
    keys: {
      organic: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
      promoted: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
      total: dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
    },
    value: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
    label: 'Engagements',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.engagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    },
    value: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    label: 'Avg. Engagement Rate',
    percent: true,
    tooltip: promotedTopLineStatsTooltips.avgEngagementRate,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_EFFECTIVENESS,
    },
    value: dashboardMetricTypes.AVG_EFFECTIVENESS,
    label: 'Avg. Effectiveness',
    percent: true,
    tooltip: promotedTopLineStatsTooltips.avgEffectiveness,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_REACH,
      promoted: dashboardMetricTypes.AVG_PAID_REACH,
      total: dashboardMetricTypes.AVG_PAID_AND_ORGANIC_REACH,
    },
    value: dashboardMetricTypes.AVG_PAID_AND_ORGANIC_REACH,
    label: 'Avg. Reach',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.avgReach,
  },
  {
    keys: {
      organic: dashboardMetricTypes.IMPRESSIONS,
      promoted: dashboardMetricTypes.PAID_IMPRESSIONS,
      total: dashboardMetricTypes.TOTAL_IMPRESSIONS,
    },
    value: dashboardMetricTypes.IMPRESSIONS,
    label: 'Impressions',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.impressions,
  },
];

const promotedOverviewTopLineMetrics = [
  {
    keys: {
      organic: dashboardMetricTypes.VIEWS,
    },
    value: dashboardMetricTypes.VIEWS,
    label: 'Views',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.views,
  },
  {
    keys: {
      netNew: dashboardMetricTypes.NET_NEW_FOLLOWERS,
      total: dashboardMetricTypes.TOTAL_FOLLOWERS,
    },
    value: dashboardMetricTypes.NET_NEW_FOLLOWERS,
    label: 'Net New Followers',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.netNewFollowers,
  },
  {
    keys: {
      organic: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
      promoted: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
      total: dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
    },
    value: dashboardMetricTypes.TOTAL_ENGAGEMENTS,
    label: 'Engagements',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.engagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    },
    value: dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
    label: 'Avg. Engagement Rate',
    percent: true,
    tooltip: promotedTopLineStatsTooltips.avgEngagementRate,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_EFFECTIVENESS_ENGAGEMENTS,
    },
    value: dashboardMetricTypes.AVG_EFFECTIVENESS_ENGAGEMENTS,
    label: 'Avg. Effectiveness',
    percent: true,
    tooltip: promotedTopLineStatsTooltips.avgEffectivenessEngagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.AVG_REACH,
      promoted: dashboardMetricTypes.AVG_PAID_REACH,
      total: dashboardMetricTypes.AVG_PAID_AND_ORGANIC_REACH,
    },
    value: dashboardMetricTypes.AVG_PAID_AND_ORGANIC_REACH,
    label: 'Avg. Reach',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.avgReach,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_SHARES,
      promoted: dashboardMetricTypes.PAID_SHARES,
      total: dashboardMetricTypes.TOTAL_SHARES,
    },
    value: dashboardMetricTypes.TOTAL_SHARES,
    label: 'Shares',
    percent: false,
    tooltip: promotedTopLineStatsTooltips.shares,
  },
];

const promotedInsightsTopLineMetrics = [
  {
    keys: {
      promoted: dashboardMetricTypes.AMOUNT_SPENT,
    },
    value: dashboardMetricTypes.AMOUNT_SPENT,
    label: 'Amount Spent',
    percent: false,
    tooltip: promotedInsightsTooltips.amountSpent,
  },
  {
    keys: {
      promoted: dashboardMetricTypes.COST_PER_CLICK,
    },
    value: dashboardMetricTypes.COST_PER_CLICK,
    label: 'CPC',
    percent: false,
    tooltip: promotedInsightsTooltips.cpc,
  },
  {
    keys: {
      promoted: dashboardMetricTypes.COST_PER_MILE,
    },
    value: dashboardMetricTypes.COST_PER_MILE,
    label: 'CPM',
    percent: false,
    tooltip: promotedInsightsTooltips.cpm,
  },
  {
    keys: {
      promoted: dashboardMetricTypes.CLICK_THROUGH_RATE,
    },
    value: dashboardMetricTypes.CLICK_THROUGH_RATE,
    label: 'CTR',
    percent: true,
    tooltip: promotedInsightsTooltips.ctr,
    valueFormatter: (value) => {
      if (!value) return '-';
      return `${value?.toFixed(2)}%`;
    },
  },
];

const promotedInsightsBreakdownListMetricsDeprecated = [
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_COMMENTS_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_COMMENTS,
      total: dashboardMetricTypes.TOTAL_COMMENTS_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_COMMENTS_POSTS_PAID,
    label: 'Comments',
    percent: false,
    tooltip: promotedInsightsTooltips.comments,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_ENGAGEMENTS_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
      total: dashboardMetricTypes.TOTAL_ENGAGEMENTS_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_ENGAGEMENTS_POSTS_PAID,
    label: 'Engagements',
    percent: false,
    tooltip: promotedInsightsTooltips.engagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_IMPRESSIONS_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_IMPRESSIONS,
      total: dashboardMetricTypes.TOTAL_IMPRESSIONS_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_IMPRESSIONS_POSTS_PAID,
    label: 'Impressions',
    percent: false,
    tooltip: promotedInsightsTooltips.impressions,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_LIKES_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_LIKES,
      total: dashboardMetricTypes.TOTAL_LIKES_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_LIKES_POSTS_PAID,
    label: 'Likes',
    percent: false,
    tooltip: promotedInsightsTooltips.likes,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_VIDEO_VIEWS_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_VIDEO_VIEWS,
      total: dashboardMetricTypes.TOTAL_VIDEO_VIEWS_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_VIDEO_VIEWS_POSTS_PAID,
    label: 'Plays',
    percent: false,
    tooltip: promotedInsightsTooltips.videoPlays,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_REACH_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_REACH,
      total: dashboardMetricTypes.TOTAL_REACH_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_REACH,
    label: 'Reach',
    percent: false,
    tooltip: promotedInsightsTooltips.reach,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_SHARES_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_SHARES,
      total: dashboardMetricTypes.TOTAL_SHARES_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_SHARES_POSTS_PAID,
    label: 'Shares',
    percent: false,
    tooltip: promotedInsightsTooltips.shares,
  },
];

const promotedInsightsBreakdownListMetrics = [
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_COMMENTS_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_COMMENTS,
      total: dashboardMetricTypes.TOTAL_COMMENTS_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_COMMENTS_POSTS_PAID,
    label: 'Comments',
    percent: false,
    tooltip: promotedInsightsTooltips.comments,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_ENGAGEMENTS_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
      total: dashboardMetricTypes.TOTAL_ENGAGEMENTS_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_ENGAGEMENTS_POSTS_PAID,
    label: 'Engagements',
    percent: false,
    tooltip: promotedInsightsTooltips.engagements,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_LIKES_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_LIKES,
      total: dashboardMetricTypes.TOTAL_LIKES_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_LIKES_POSTS_PAID,
    label: 'Likes',
    percent: false,
    tooltip: promotedInsightsTooltips.likes,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_REACH_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_REACH,
      total: dashboardMetricTypes.TOTAL_REACH_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_REACH,
    label: 'Reach',
    percent: false,
    tooltip: promotedInsightsTooltips.reach,
  },
  {
    keys: {
      organic: dashboardMetricTypes.ORGANIC_SHARES_POSTS_PAID,
      promoted: dashboardMetricTypes.PAID_SHARES,
      total: dashboardMetricTypes.TOTAL_SHARES_POSTS_PAID,
    },
    value: dashboardMetricTypes.TOTAL_SHARES_POSTS_PAID,
    label: 'Shares',
    percent: false,
    tooltip: promotedInsightsTooltips.shares,
  },
];

const overviewTopVideosMetricsBreakdown = {
  '-INSTAGRAM_ENTERTAINMENT_SCORE': {
    label: instagramPostMetricLabelsDeprecated.ENTERTAINMENT_SCORE,
    field: 'entertainmentScore',
    format: 'two_decimal_float',
  },
  '-INSTAGRAM_TOTAL_VIDEO_VIEWS': {
    label: instagramPostMetricLabelsDeprecated.VIDEO_VIEWS,
    organicField: 'videoViews',
    paidField: 'paidVideoViews',
    totalField: 'totalVideoViews',
    format: 'number_with_commas',
  },
  '-INSTAGRAM_VIEWS': {
    label: instagramPostMetricLabels.ORGANIC_VIEWS,
    organicField: 'views',
    format: 'number_with_commas',
  },
  '-INSTAGRAM_SUM_TOTAL_ENGAGEMENTS': {
    label: instagramPostMetricLabelsDeprecated.ENGAGEMENTS,
    organicField: 'totalEngagement',
    paidField: 'paidTotalEngagements',
    totalField: 'sumTotalEngagements',
    format: 'number_with_commas',
  },
  '-INSTAGRAM_ENGAGEMENT_RATE': {
    label: instagramPostMetricLabelsDeprecated.AVG_ENGAGEMENT_RATE,
    organicField: 'engagement',
    format: 'percent',
  },
  '-INSTAGRAM_EFFECTIVENESS': {
    label: instagramPostMetricLabelsDeprecated.AVG_EFFECTIVENESS,
    organicField: 'effectiveness',
    format: 'percent',
  },
  '-INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS': {
    label: instagramPostMetricLabels.AVG_EFFECTIVENESS,
    organicField: 'effectivenessEngagements',
    format: 'percent',
  },
  '-INSTAGRAM_REACH': {
    label: instagramPostMetricLabelsDeprecated.AVG_REACH,
    organicField: 'reach',
    format: 'number_with_commas',
  },
};

const overviewChartOptionsDeprecated = {
  titleOptions: [
    {
      label: 'Engagements - Total',
      value: dashboardMetricReportMetrics.SUM_TOTAL_ENGAGEMENTS,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.TOTAL_ENGAGEMENTS,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_TOTAL_ENGAGEMENTS,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.SUM_TOTAL_ENGAGEMENTS,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Avg. Engagement Rate - Organic (F)',
      value: dashboardMetricReportMetrics.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      displayTotalLabel: false,
    },
    {
      label: 'Avg. Effectiveness - Organic',
      value: dashboardMetricReportMetrics.AVG_EFFECTIVENESS,
      format: 'percent',
      displayTotalLabel: false,
    },
    {
      label: 'Avg. Reach - Organic',
      value: dashboardMetricReportMetrics.AVG_REACH,
      displayTotalLabel: false,
    },
    {
      label: 'Impressions - Total',
      value: dashboardMetricReportMetrics.TOTAL_IMPRESSIONS,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.IMPRESSIONS,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_IMPRESSIONS,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.TOTAL_IMPRESSIONS,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Likes - Total',
      value: dashboardMetricReportMetrics.TOTAL_LIKES,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.ORGANIC_LIKES,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_LIKES,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.TOTAL_LIKES,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Comments - Total',
      value: dashboardMetricReportMetrics.TOTAL_COMMENTS,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.ORGANIC_COMMENTS,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_COMMENTS,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.TOTAL_COMMENTS,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Saves - Organic',
      value: dashboardMetricReportMetrics.SAVES,
      displayTotalLabel: true,
    },
    {
      label: 'Video Views - Total',
      value: dashboardMetricReportMetrics.TOTAL_VIDEO_VIEWS,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.ORGANIC_VIDEO_VIEWS,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_VIDEO_VIEWS,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.TOTAL_VIDEO_VIEWS,
        },
      },
      displayTotalLabel: true,
    },
  ],
  tooltipCountLabel: 'Posts',
  tooltipCountField: dashboardMetricReportMetrics.NUMBER_OF_POSTS,
};

const overviewChartOptions = {
  titleOptions: [
    {
      label: 'Engagements - Total',
      value: dashboardMetricReportMetrics.SUM_TOTAL_ENGAGEMENTS,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.TOTAL_ENGAGEMENTS,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_TOTAL_ENGAGEMENTS,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.SUM_TOTAL_ENGAGEMENTS,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Avg. Engagement Rate - Organic (F)',
      value: dashboardMetricReportMetrics.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      displayTotalLabel: false,
    },
    {
      label: 'Avg. Effectiveness - Organic',
      value: dashboardMetricReportMetrics.AVG_EFFECTIVENESS_ENGAGEMENTS,
      format: 'percent',
      displayTotalLabel: false,
    },
    {
      label: 'Avg. Reach - Organic',
      value: dashboardMetricReportMetrics.AVG_REACH,
      displayTotalLabel: false,
    },
    {
      label: 'Views - Organic',
      value: dashboardMetricReportMetrics.VIEWS,
      displayTotalLabel: false,
    },
    {
      label: 'Likes - Total',
      value: dashboardMetricReportMetrics.TOTAL_LIKES,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.ORGANIC_LIKES,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_LIKES,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.TOTAL_LIKES,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Comments - Total',
      value: dashboardMetricReportMetrics.TOTAL_COMMENTS,
      chartMetrics: {
        Organic: {
          label: 'Organic',
          value: dashboardMetricReportMetrics.ORGANIC_COMMENTS,
        },
        Promoted: {
          label: 'Promoted',
          value: dashboardMetricReportMetrics.PAID_COMMENTS,
        },
        Total: {
          label: 'Total',
          value: dashboardMetricReportMetrics.TOTAL_COMMENTS,
        },
      },
      displayTotalLabel: true,
    },
    {
      label: 'Saves - Organic',
      value: dashboardMetricReportMetrics.SAVES,
      displayTotalLabel: true,
    },
    {
      label: 'Avg. Watch Time - Organic',
      value: dashboardMetricReportMetrics.AVG_AVG_WATCH_TIME,
      displayTotalLabel: false,
    },
  ],
  tooltipCountLabel: 'Posts',
  tooltipCountField: dashboardMetricReportMetrics.NUMBER_OF_POSTS,
};

const storyNarrativeMetricKeys = Object.fromEntries(
  Object.entries(storyOverviewMetricKeys).filter(([, value]) =>
    [
      storyOverviewMetricKeys.AVG_REACH,
      storyOverviewMetricKeys.AVG_IMPRESSIONS,
      storyOverviewMetricKeys.AVG_COMPLETION_RATE,
      storyOverviewMetricKeys.AVG_EXIT_RATE,
      storyOverviewMetricKeys.SWIPE_UPS,
    ].includes(value),
  ),
);

const storyOverviewToplineMetricKeys = Object.fromEntries(
  Object.entries(storyOverviewMetricKeys).filter(([, value]) =>
    [
      storyOverviewMetricKeys.STORY_FRAMES_COUNT,
      storyOverviewMetricKeys.AVG_REACH,
      storyOverviewMetricKeys.TOTAL_IMPRESSIONS,
      storyOverviewMetricKeys.AVG_COMPLETION_RATE,
      storyOverviewMetricKeys.AVG_EXIT_RATE,
      storyOverviewMetricKeys.SWIPE_UPS,
    ].includes(value),
  ),
);

const storyOverviewMetricLabels = {
  [storyOverviewMetricKeys.STORY_FRAMES_COUNT]: 'Story Frames',
  [storyOverviewMetricKeys.AVG_IMPRESSIONS]: 'Avg. Impressions',
  [storyOverviewMetricKeys.TOTAL_IMPRESSIONS]: 'Impressions',
  [storyOverviewMetricKeys.AVG_REACH]: 'Avg. Reach',
  [storyOverviewMetricKeys.AVG_COMPLETION_RATE]: 'Avg. Completion Rate',
  [storyOverviewMetricKeys.AVG_EXIT_RATE]: 'Avg. Exit Rate',
  [storyOverviewMetricKeys.SWIPE_UPS]: 'Link Clicks',
  [storyOverviewMetricKeys.TOTAL_REPLIES]: 'Replies',
};

const storyOverviewMetricField = {
  [storyOverviewMetricKeys.STORY_FRAMES_COUNT]: 'storyFramesCount',
  [storyOverviewMetricKeys.TOTAL_IMPRESSIONS]: 'totalImpressions',
  [storyOverviewMetricKeys.AVG_REACH]: 'avgReach',
  [storyOverviewMetricKeys.AVG_COMPLETION_RATE]: 'avgCompletionRate',
  [storyOverviewMetricKeys.AVG_EXIT_RATE]: 'avgExitRate',
  [storyOverviewMetricKeys.SWIPE_UPS]: 'totalSwipeUps',
  [storyOverviewMetricKeys.TOTAL_REPLIES]: 'totalReplies',
};

const storyOverviewToplineMetrics = {
  [storyOverviewMetricKeys.STORY_FRAMES_COUNT]: {
    field: storyOverviewMetricField[storyOverviewMetricKeys.STORY_FRAMES_COUNT],
    label: storyOverviewMetricLabels[storyOverviewMetricKeys.STORY_FRAMES_COUNT],
    tooltip: toolTips.storyOverview.metrics.storyFramesCount,
  },
  [storyOverviewMetricKeys.AVG_REACH]: {
    field: storyOverviewMetricField[storyOverviewMetricKeys.AVG_REACH],
    label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_REACH],
    tooltip: toolTips.storyOverview.metrics.avgReach,
  },
  [storyOverviewMetricKeys.TOTAL_IMPRESSIONS]: {
    field: storyOverviewMetricField[storyOverviewMetricKeys.TOTAL_IMPRESSIONS],
    label: storyOverviewMetricLabels[storyOverviewMetricKeys.TOTAL_IMPRESSIONS],
    tooltip: toolTips.storyOverview.metrics.totalImpressions,
  },
  [storyOverviewMetricKeys.AVG_COMPLETION_RATE]: {
    field: storyOverviewMetricField[storyOverviewMetricKeys.AVG_COMPLETION_RATE],
    label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_COMPLETION_RATE],
    percent: true,
    tooltip: toolTips.storyOverview.metrics.avgCompletionRate,
  },
  [storyOverviewMetricKeys.AVG_EXIT_RATE]: {
    field: storyOverviewMetricField[storyOverviewMetricKeys.AVG_EXIT_RATE],
    label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_EXIT_RATE],
    percent: true,
    tooltip: toolTips.storyOverview.metrics.avgExitRate,
  },
  [storyOverviewMetricKeys.SWIPE_UPS]: {
    field: storyOverviewMetricField[storyOverviewMetricKeys.SWIPE_UPS],
    label: storyOverviewMetricLabels[storyOverviewMetricKeys.SWIPE_UPS],
    tooltip: toolTips.storyOverview.metrics.totalSwipeUps,
  },
};

const storyOverviewChartOptions = {
  titleOptions: [
    {
      label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_REACH],
      value: storyOverviewMetricField[storyOverviewMetricKeys.AVG_REACH],
      displayTotalLabel: false,
    },
    {
      label: storyOverviewMetricLabels[storyOverviewMetricKeys.TOTAL_IMPRESSIONS],
      value: storyOverviewMetricField[storyOverviewMetricKeys.TOTAL_IMPRESSIONS],
    },
    {
      label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_COMPLETION_RATE],
      value: storyOverviewMetricField[storyOverviewMetricKeys.AVG_COMPLETION_RATE],
      displayTotalLabel: false,
      displayAvgLabel: false,
      format: 'percent',
    },
    {
      label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_EXIT_RATE],
      value: storyOverviewMetricField[storyOverviewMetricKeys.AVG_EXIT_RATE],
      displayTotalLabel: false,
      displayAvgLabel: false,
      format: 'percent',
    },
    {
      label: storyOverviewMetricLabels[storyOverviewMetricKeys.SWIPE_UPS],
      value: storyOverviewMetricField[storyOverviewMetricKeys.SWIPE_UPS],
    },
    {
      label: storyOverviewMetricLabels[storyOverviewMetricKeys.TOTAL_REPLIES],
      value: storyOverviewMetricField[storyOverviewMetricKeys.TOTAL_REPLIES],
    },
  ],
  tooltipCountLabel: 'Frames',
  tooltipCountField: storyOverviewMetricField[storyOverviewMetricKeys.STORY_FRAMES_COUNT],
};
const storyNarrativeMetrics = {};
storyNarrativeMetrics[storyOverviewMetricKeys.AVG_REACH] = {
  field: 'igStoryAvgReach',
  label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_REACH],
};

storyNarrativeMetrics[storyOverviewMetricKeys.AVG_IMPRESSIONS] = {
  field: 'igStoryAvgImpressions',
  label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_IMPRESSIONS],
};
storyNarrativeMetrics[storyOverviewMetricKeys.AVG_COMPLETION_RATE] = {
  field: 'igStoryAvgCompletionRate',
  label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_COMPLETION_RATE],
  format: 'percent',
};
storyNarrativeMetrics[storyOverviewMetricKeys.AVG_EXIT_RATE] = {
  field: 'igStoryAvgExitRate',
  label: storyOverviewMetricLabels[storyOverviewMetricKeys.AVG_EXIT_RATE],
  format: 'percent',
};
storyNarrativeMetrics[storyOverviewMetricKeys.SWIPE_UPS] = {
  field: 'igStorySwipeUps',
  label: storyOverviewMetricLabels[storyOverviewMetricKeys.SWIPE_UPS],
};

const storyFramesSortOptions = [
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
    label: 'Completion Rate',
    field: 'completion_rate',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_EXITS',
    label: 'Exits',
    field: 'exits',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
    label: 'Exit Rate',
    field: 'exit_rate',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
    label: 'Impressions',
    field: 'impressions',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
    label: 'Link Clicks',
    field: 'swipe_ups',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_REACH',
    label: 'Reach',
    field: 'reach',
  },
  {
    value: 'INSTAGRAM_STORY_FRAME_REPLIES',
    label: 'Replies',
    field: 'replies',
  },
];

const storyBoardDetailsSortOptions = [
  ...storyFramesSortOptions.slice(0, 1),
  {
    value: 'INSTAGRAM_STORY_FRAME_ADDED',
    label: 'Recently Added',
  },
  ...storyFramesSortOptions.slice(1),
];

const storyBoardEmptyCopy = {
  title: "You don't have any story boards yet.",
  filterSelectedTitle: 'No boards were created in your selected date range or filters',
  ctaLink: 'Add a story board',
  ctaSuffix: ' to get started.',
};

const likeshopTabNames = {
  media: 'All Media',
  feed: 'LikeShop Feed',
  customContent: 'Custom Content',
};

const likeshopTabs = [
  {
    name: likeshopTabNames.media,
    tooltip:
      'Add links to content published on Instagram and sort to see top performing media. View Instagram owned content and uploaded media.',
  },
  {
    name: likeshopTabNames.feed,
    tooltip: `Upload media to your LikeShop and reorder to match your Instagram feed. View media with links and uploaded media. Only media with links will show on your live LikeShop.`,
  },
  {
    name: likeshopTabNames.customContent,
    tooltip:
      'View the performance of, and customize, your banner image, pinned media, and enable email capture.',
  },
];

const postTypeFilters = {
  reel: ['reel'],
  video: ['video'],
  videoReel: ['video', 'reel'],
};

const overviewCSVTypes = {
  followers: { label: 'Followers CSV', filenamePrefix: 'followers_csv' },
  metrics: { label: 'Metrics CSV', filenamePrefix: 'metrics_csv' },
  engagingPosts: { label: 'Engaging Posts CSV', filenamePrefix: 'engaging_posts_csv' },
  topUGC: { label: 'Top UGC CSV', filenamePrefix: 'top_ugc_csv' },
  likeshop: { label: 'LikeShop Posts CSV', filenamePrefix: 'likeshop_csv' },
};

const timeScaleTypes = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};

const ugcTypes = {
  ALL: 'ALL',
  MENTIONED: 'MENTIONED',
  TAGGED: 'TAGGED',
};

export const instagramBoardsDetailSortOptionsDeprecated = [
  {
    label: 'Recently Published',
    value: '-DATE',
  },
  {
    label: 'Comments - Organic',
    value: '-INSTAGRAM_ORGANIC_COMMENTS',
    field: 'commentsCount',
  },
  {
    label: 'Comments - Promoted',
    value: '-INSTAGRAM_PAID_COMMENTS',
    field: 'paidComments',
  },
  {
    label: 'Comments - Total',
    value: '-INSTAGRAM_TOTAL_COMMENTS',
    field: 'totalComments',
  },
  {
    label: 'Effectiveness - Organic',
    value: '-INSTAGRAM_EFFECTIVENESS',
    field: 'effectiveness',
  },
  {
    displayLabel: 'Engagement Rate - Organic (Followers)',
    label: 'Engagement Rate - Organic (F)',
    value: '-INSTAGRAM_ENGAGEMENT_RATE',
    field: 'engagement',
  },
  {
    label: 'Engagements - Organic',
    value: '-INSTAGRAM_TOTAL_ENGAGEMENTS',
    field: 'totalEngagement',
  },
  {
    label: 'Engagements - Promoted',
    value: '-INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
    field: 'paidTotalEngagements',
  },
  {
    label: 'Engagements - Total',
    value: '-INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    field: 'sumTotalEngagements',
  },
  {
    label: 'Entertainment Score',
    value: '-INSTAGRAM_ENTERTAINMENT_SCORE',
    field: 'entertainmentScore',
  },
  {
    label: 'Impressions - Organic',
    value: '-INSTAGRAM_ORGANIC_IMPRESSIONS',
    field: 'impressions',
  },
  {
    label: 'Impressions - Promoted',
    value: '-INSTAGRAM_PAID_IMPRESSIONS',
    field: 'paidImpressions',
  },
  {
    label: 'Impressions - Total',
    value: '-INSTAGRAM_TOTAL_IMPRESSIONS',
    field: 'totalImpressions',
  },
  {
    label: 'Likes - Organic',
    value: '-INSTAGRAM_ORGANIC_LIKES',
    field: 'likeCount',
  },
  {
    label: 'Likes - Promoted',
    value: '-INSTAGRAM_PAID_LIKES',
    field: 'paidLikes',
  },
  {
    label: 'Likes - Total',
    value: '-INSTAGRAM_TOTAL_LIKES',
    field: 'totalLikes',
  },
  {
    label: 'Reach - Organic',
    value: '-INSTAGRAM_REACH',
    field: 'reach',
  },
  {
    label: 'Reels - Shares - Organic',
    value: '-INSTAGRAM_SHARES',
    field: 'shares',
  },
  {
    label: 'Saves - Organic',
    value: '-INSTAGRAM_SAVES',
    field: 'saved',
  },
  {
    label: 'Video Views - Organic',
    value: '-INSTAGRAM_VIDEO_VIEWS',
    field: 'videoViews',
  },
  {
    label: 'Video Views - Promoted',
    value: '-INSTAGRAM_PAID_VIDEO_VIEWS',
    field: 'paidVideoViews',
  },
  {
    label: 'Video Views - Total',
    value: '-INSTAGRAM_TOTAL_VIDEO_VIEWS',
    field: 'totalVideoViews',
  },
];

export const instagramBoardsDetailSortOptions = [
  {
    label: 'Recently Published',
    value: '-DATE',
  },
  {
    label: 'Comments - Organic',
    value: '-INSTAGRAM_ORGANIC_COMMENTS',
    field: 'commentsCount',
  },
  {
    label: 'Comments - Promoted',
    value: '-INSTAGRAM_PAID_COMMENTS',
    field: 'paidComments',
  },
  {
    label: 'Comments - Total',
    value: '-INSTAGRAM_TOTAL_COMMENTS',
    field: 'totalComments',
  },
  {
    label: 'Effectiveness - Organic',
    value: '-INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS',
    field: 'effectivenessEngagements',
  },
  {
    displayLabel: 'Effectiveness - Organic (Discontinued)',
    label: 'Effectiveness - Organic (D)',
    value: '-INSTAGRAM_EFFECTIVENESS',
    field: 'effectiveness',
  },
  {
    displayLabel: 'Engagement Rate - Organic (Followers)',
    label: 'Engagement Rate - Organic (F)',
    value: '-INSTAGRAM_ENGAGEMENT_RATE',
    field: 'engagement',
  },
  {
    label: 'Engagements - Organic',
    value: '-INSTAGRAM_TOTAL_ENGAGEMENTS',
    field: 'totalEngagement',
  },
  {
    label: 'Engagements - Promoted',
    value: '-INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
    field: 'paidTotalEngagements',
  },
  {
    label: 'Engagements - Total',
    value: '-INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    field: 'sumTotalEngagements',
  },
  {
    label: 'Entertainment Score',
    value: '-INSTAGRAM_ENTERTAINMENT_SCORE',
    field: 'entertainmentScore',
  },
  {
    displayLabel: 'Impressions - Organic (Discontinued)',
    label: 'Impressions - Organic (D)',
    value: '-INSTAGRAM_ORGANIC_IMPRESSIONS',
    field: 'impressions',
  },
  {
    label: 'Impressions - Promoted',
    value: '-INSTAGRAM_PAID_IMPRESSIONS',
    field: 'paidImpressions',
  },
  {
    displayLabel: 'Impressions - Total (Discontinued)',
    label: 'Impressions - Total (D)',
    value: '-INSTAGRAM_TOTAL_IMPRESSIONS',
    field: 'totalImpressions',
  },
  {
    label: 'Likes - Organic',
    value: '-INSTAGRAM_ORGANIC_LIKES',
    field: 'likeCount',
  },
  {
    label: 'Likes - Promoted',
    value: '-INSTAGRAM_PAID_LIKES',
    field: 'paidLikes',
  },
  {
    label: 'Likes - Total',
    value: '-INSTAGRAM_TOTAL_LIKES',
    field: 'totalLikes',
  },
  {
    label: 'Reach - Organic',
    value: '-INSTAGRAM_REACH',
    field: 'reach',
  },
  {
    label: 'Reels - Shares - Organic',
    value: '-INSTAGRAM_SHARES',
    field: 'shares',
  },
  {
    label: 'Saves - Organic',
    value: '-INSTAGRAM_SAVES',
    field: 'saved',
  },
  {
    displayLabel: 'Video Views - Organic (Discontinued)',
    label: 'Video Views - Organic (D)',
    value: '-INSTAGRAM_VIDEO_VIEWS',
    field: 'videoViews',
  },
  {
    label: 'Video Views - Promoted',
    value: '-INSTAGRAM_PAID_VIDEO_VIEWS',
    field: 'paidVideoViews',
  },
  {
    displayLabel: 'Video Views - Total (Discontinued)',
    label: 'Video Views - Total (D)',
    value: '-INSTAGRAM_TOTAL_VIDEO_VIEWS',
    field: 'totalVideoViews',
  },
  {
    label: 'Views - Organic',
    value: '-INSTAGRAM_VIEWS',
    field: 'views',
  },
];

export const instagramBoardsSortOptions = [
  {
    label: 'Recently Added',
    value: 'CREATED',
  },
  {
    label: 'Avg. Reach - Organic',
    value: 'INSTAGRAM_AVG_REACH',
    field: 'reach',
  },
  {
    label: 'Likes',
    value: 'INSTAGRAM_TOTAL_LIKES',
    field: 'likes',
  },
  {
    label: 'Comments',
    value: 'INSTAGRAM_TOTAL_COMMENTS',
    field: 'comments',
  },
  {
    displayLabel: 'Avg. Engagement Rate - Organic (Followers)',
    label: 'Avg. Engagement Rate - Organic (F)',
    value: 'INSTAGRAM_ENGAGEMENT',
    field: 'engagement',
  },
  {
    label: 'Avg. Effectiveness - Organic',
    value: 'INSTAGRAM_EFFECTIVENESS',
    field: 'effectiveness',
  },
];

export const storyBoardsDetailSortOptions = [
  {
    label: 'Recently Published',
    value: '-POSTED',
  },
  {
    label: 'Recently Added',
    value: '-ADDED',
  },
  {
    label: 'Reach',
    value: '-INSTAGRAM_STORY_REACH',
  },
  {
    label: 'Impressions',
    value: '-INSTAGRAM_STORY_IMPRESSIONS',
  },
  {
    label: 'Link Clicks',
    value: '-INSTAGRAM_STORY_SWIPE_UPS',
  },
  {
    label: 'Replies',
    value: '-INSTAGRAM_STORY_REPLIES',
  },
  {
    label: 'Exit Rate',
    value: '-INSTAGRAM_STORY_EXIT_RATE',
  },
];

export const topPerformingVideosSortOptionsDeprecated = [
  {
    value: '-INSTAGRAM_TOTAL_VIDEO_VIEWS',
    label: 'Video Views - Total',
  },
  {
    value: '-INSTAGRAM_EFFECTIVENESS',
    label: 'Avg. Effectiveness - Organic',
  },
  {
    value: '-INSTAGRAM_ENGAGEMENT_RATE',
    label: 'Avg. Engagement Rate - Organic',
  },
  {
    value: '-INSTAGRAM_REACH',
    label: 'Avg. Reach - Organic',
  },
  {
    value: '-INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    label: 'Engagements - Total',
  },
  {
    value: '-INSTAGRAM_ENTERTAINMENT_SCORE',
    label: 'Entertainment Score',
    post_type: postTypeFilters.reel,
  },
];

export const topPerformingVideosSortOptions = [
  {
    value: '-INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS',
    label: 'Avg. Effectiveness - Organic',
  },
  {
    value: '-INSTAGRAM_ENGAGEMENT_RATE',
    label: 'Avg. Engagement Rate - Organic',
  },
  {
    value: '-INSTAGRAM_REACH',
    label: 'Avg. Reach - Organic',
  },
  {
    value: '-INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    label: 'Engagements - Total',
  },
  {
    value: '-INSTAGRAM_ENTERTAINMENT_SCORE',
    label: 'Entertainment Score',
    post_type: postTypeFilters.reel,
  },
  {
    value: '-INSTAGRAM_VIEWS',
    label: 'Views - Organic',
  },
];

export const galleriesFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

export const galleriesFilterWidgetsConfig = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

export const instagramCommentRequiredScopes = ['instagram_manage_comments'];

export const instagramDirectMessageRequiredScopes = ['instagram_manage_messages'];

export const mostEntertainingPostsSortOptions = [
  {
    value: '-INSTAGRAM_ENTERTAINMENT_SCORE',
    label: instagramPostMetricLabels.ENTERTAINMENT_SCORE,
  },
  {
    value: '-INSTAGRAM_VIEWS',
    label: instagramPostMetricLabels.VIEWS,
  },
];

export const mostEntertainingPostsSortOptionsDeprecated = [
  {
    value: '-INSTAGRAM_ENTERTAINMENT_SCORE',
    label: instagramPostMetricLabelsDeprecated.ENTERTAINMENT_SCORE,
  },
  {
    value: '-INSTAGRAM_VIDEO_VIEWS',
    label: instagramPostMetricLabelsDeprecated.VIDEO_VIEWS,
  },
];

export const instagramOverviewDateOptions = [
  { label: 'Last 3 Days', value: 3 },
  { label: 'Last 7 Days', value: 7 },
  { label: 'Last 4 Weeks', value: 28 },
];

export const instagramOverviewScaleOptions = [
  { label: 'By Day', value: timeScaleTypes.DAILY },
  { label: 'By Month', value: timeScaleTypes.MONTHLY },
];

export const ugcMediaTypeOptions = [
  { label: 'All Posts', value: ugcTypes.ALL },
  { label: 'Mentioned You', value: ugcTypes.MENTIONED },
  { label: 'Tagged You', value: ugcTypes.TAGGED },
];

export const instagramOverviewTopLineMetricsDeprecated = [
  dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
  dashboardMetricTypes.PAID_VIDEO_VIEWS,
  dashboardMetricTypes.TOTAL_VIDEO_VIEWS,
  dashboardMetricTypes.TOTAL_FOLLOWERS,
  dashboardMetricTypes.NET_NEW_FOLLOWERS,
  dashboardMetricTypes.TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
  dashboardMetricTypes.AVG_EFFECTIVENESS,
  dashboardMetricTypes.AVG_REACH,
  dashboardMetricTypes.AVG_PAID_REACH,
  dashboardMetricTypes.AVG_PAID_AND_ORGANIC_REACH,
  dashboardMetricTypes.IMPRESSIONS,
  dashboardMetricTypes.PAID_IMPRESSIONS,
  dashboardMetricTypes.TOTAL_IMPRESSIONS,
  dashboardMetricTypes.AVG_ENTERTAINMENT_SCORE,
];

export const instagramOverviewTopLineMetrics = [
  dashboardMetricTypes.VIEWS,
  dashboardMetricTypes.TOTAL_FOLLOWERS,
  dashboardMetricTypes.NET_NEW_FOLLOWERS,
  dashboardMetricTypes.TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.SUM_TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.AVG_ENGAGEMENT_RATE,
  dashboardMetricTypes.AVG_EFFECTIVENESS_ENGAGEMENTS,
  dashboardMetricTypes.AVG_REACH,
  dashboardMetricTypes.AVG_PAID_REACH,
  dashboardMetricTypes.AVG_PAID_AND_ORGANIC_REACH,
  dashboardMetricTypes.AVG_ENTERTAINMENT_SCORE,
  dashboardMetricTypes.ORGANIC_SHARES,
  dashboardMetricTypes.PAID_SHARES,
  dashboardMetricTypes.TOTAL_SHARES,
  dashboardMetricTypes.ORGANIC_VIDEO_VIEWS,
  dashboardMetricTypes.PAID_VIDEO_VIEWS,
  dashboardMetricTypes.TOTAL_VIDEO_VIEWS,
  dashboardMetricTypes.AVG_EFFECTIVENESS,
  dashboardMetricTypes.IMPRESSIONS,
  dashboardMetricTypes.PAID_IMPRESSIONS,
  dashboardMetricTypes.TOTAL_IMPRESSIONS,
];

export const instagramPromotedInsightsMetrics = [
  dashboardMetricTypes.NUMBER_OF_POSTS_PAID,
  // top line stats metrics
  dashboardMetricTypes.AMOUNT_SPENT,
  dashboardMetricTypes.COST_PER_CLICK,
  dashboardMetricTypes.COST_PER_MILE,
  dashboardMetricTypes.CLICK_THROUGH_RATE,
  // breakdown list metrics
  // comments
  dashboardMetricTypes.ORGANIC_COMMENTS_POSTS_PAID,
  dashboardMetricTypes.PAID_COMMENTS,
  dashboardMetricTypes.TOTAL_COMMENTS_POSTS_PAID,
  // engagements
  dashboardMetricTypes.ORGANIC_ENGAGEMENTS_POSTS_PAID,
  dashboardMetricTypes.PAID_TOTAL_ENGAGEMENTS,
  dashboardMetricTypes.TOTAL_ENGAGEMENTS_POSTS_PAID,
  // impressions
  dashboardMetricTypes.ORGANIC_IMPRESSIONS_POSTS_PAID,
  dashboardMetricTypes.PAID_IMPRESSIONS,
  dashboardMetricTypes.TOTAL_IMPRESSIONS_POSTS_PAID,
  // likes
  dashboardMetricTypes.ORGANIC_LIKES_POSTS_PAID,
  dashboardMetricTypes.PAID_LIKES,
  dashboardMetricTypes.TOTAL_LIKES_POSTS_PAID,
  // shares
  dashboardMetricTypes.ORGANIC_SHARES_POSTS_PAID,
  dashboardMetricTypes.PAID_SHARES,
  dashboardMetricTypes.TOTAL_SHARES_POSTS_PAID,
  // reach
  dashboardMetricTypes.ORGANIC_REACH_POSTS_PAID,
  dashboardMetricTypes.PAID_REACH,
  dashboardMetricTypes.TOTAL_REACH_POSTS_PAID,
  // video plays
  dashboardMetricTypes.ORGANIC_VIDEO_VIEWS_POSTS_PAID,
  dashboardMetricTypes.PAID_VIDEO_VIEWS,
  dashboardMetricTypes.TOTAL_VIDEO_VIEWS_POSTS_PAID,
];

const igHashtagUser = 'IG-HASHTAG-USER';

const yourPostsSortOptionsAdsNeverConnectedDeprecated = [
  {
    label: 'Comments',
    value: 'INSTAGRAM_ORGANIC_COMMENTS',
    field: 'commentsCount',
  },
  {
    label: 'Effectiveness',
    value: 'INSTAGRAM_EFFECTIVENESS',
    field: 'effectiveness',
  },
  {
    label: 'Engagements',
    value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    field: 'totalEngagement',
  },
  {
    label: 'Engagement Rate (F)',
    displayLabel: 'Engagement Rate - Organic (Followers)',
    value: 'INSTAGRAM_ENGAGEMENT_RATE',
    field: 'engagement',
  },
  {
    label: 'Engagement Rate (I)',
    displayLabel: 'Engagement Rate - Organic (Impressions)',
    value: 'INSTAGRAM_ENGAGEMENT_RATE_IMPRESSIONS',
    field: 'engagementRateImpressions',
  },
  {
    label: 'Entertainment Score',
    value: 'INSTAGRAM_ENTERTAINMENT_SCORE',
    field: 'entertainmentScore',
  },
  {
    label: 'Impressions',
    value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
    field: 'impressions',
  },
  {
    label: 'Likes',
    value: 'INSTAGRAM_ORGANIC_LIKES',
    field: 'likeCount',
  },
  {
    label: 'Plays',
    value: 'INSTAGRAM_VIDEO_VIEWS',
    field: 'videoViews',
  },
  {
    label: 'All Plays',
    value: 'INSTAGRAM_TOTAL_PLAYS',
    field: 'totalPlays',
  },
  {
    label: 'Reach',
    value: 'INSTAGRAM_REACH',
    field: 'reach',
  },
  {
    label: 'Recently Published',
    value: 'DATE',
  },
  {
    label: 'Saves',
    value: 'INSTAGRAM_SAVES',
    field: 'saved',
  },
  {
    label: 'Shares',
    value: 'INSTAGRAM_SHARES',
    field: 'shares',
  },
];

const yourPostsSortOptionsAdsConnectedDeprecated = [
  { label: 'Recently Published', value: 'DATE' },
  {
    label: 'Organic',
    children: [
      {
        label: 'Comments',
        displayLabel: 'Comments - Organic',
        value: 'INSTAGRAM_ORGANIC_COMMENTS',
        field: 'commentsCount',
      },
      {
        label: 'Effectiveness',
        displayLabel: 'Effectiveness - Organic',
        value: 'INSTAGRAM_EFFECTIVENESS',
        field: 'effectiveness',
      },
      {
        label: 'Engagements',
        displayLabel: 'Engagements - Organic',
        value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
        field: 'totalEngagement',
      },
      {
        label: 'Engagement Rate (F)',
        displayLabel: 'Engagement Rate - Organic (Followers)',
        value: 'INSTAGRAM_ENGAGEMENT_RATE',
        field: 'engagement',
      },
      {
        label: 'Engagement Rate (I)',
        displayLabel: 'Engagement Rate - Organic (Impressions)',
        value: 'INSTAGRAM_ENGAGEMENT_RATE_IMPRESSIONS',
        field: 'engagementRateImpressions',
      },
      {
        label: 'Entertainment Score',
        value: 'INSTAGRAM_ENTERTAINMENT_SCORE',
        field: 'entertainmentScore',
      },
      {
        label: 'Impressions',
        displayLabel: 'Impressions - Organic',
        value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
        field: 'impressions',
      },
      {
        label: 'Likes',
        displayLabel: 'Likes - Organic',
        value: 'INSTAGRAM_ORGANIC_LIKES',
        field: 'likeCount',
      },
      {
        label: 'Plays',
        displayLabel: 'Plays - Organic',
        value: 'INSTAGRAM_VIDEO_VIEWS',
        field: 'videoViews',
      },
      {
        label: 'All Plays',
        displayLabel: 'All Plays - Organic',
        value: 'INSTAGRAM_TOTAL_PLAYS',
        field: 'totalPlays',
      },
      {
        label: 'Reach',
        displayLabel: 'Reach - Organic',
        value: 'INSTAGRAM_REACH',
        field: 'reach',
      },
      {
        label: 'Saves',
        displayLabel: 'Saves - Organic',
        value: 'INSTAGRAM_SAVES',
        field: 'saved',
      },
      {
        label: 'Shares',
        displayLabel: 'Shares - Organic',
        value: 'INSTAGRAM_SHARES',
        field: 'shares',
      },
    ],
  },
  {
    label: 'Promoted',
    children: [
      {
        label: 'Comments',
        displayLabel: 'Comments - Promoted',
        value: 'INSTAGRAM_PAID_COMMENTS',
        field: 'paidComments',
      },
      {
        label: 'CTR',
        value: 'INSTAGRAM_CTR',
        field: 'ctr',
      },
      {
        label: 'CPC',
        value: 'INSTAGRAM_CPC',
        field: 'cpc',
      },
      {
        label: 'CPM',
        value: 'INSTAGRAM_CPM',
        field: 'cpm',
      },
      {
        label: 'Engagements',
        displayLabel: 'Engagements - Promoted',
        value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
        field: 'paidTotalEngagements',
      },
      {
        label: 'Engagement Rate',
        displayLabel: 'Engagement Rate - Promoted (Impressions)',
        value: 'INSTAGRAM_PAID_ENGAGEMENT_RATE',
        field: 'paidEngagementRate',
      },
      {
        label: 'Impressions',
        displayLabel: 'Impressions - Promoted',
        value: 'INSTAGRAM_PAID_IMPRESSIONS',
        field: 'paidImpressions',
      },
      {
        label: 'Likes',
        displayLabel: 'Likes - Promoted',
        value: 'INSTAGRAM_PAID_LIKES',
        field: 'paidLikes',
      },
      {
        label: 'Plays',
        displayLabel: 'Plays - Promoted',
        value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
        field: 'paidVideoViews',
      },
      {
        label: 'Reach',
        displayLabel: 'Reach - Promoted',
        value: 'INSTAGRAM_PAID_REACH',
        field: 'paidReach',
      },
      {
        label: 'Saves',
        displayLabel: 'Saves - Promoted',
        value: 'INSTAGRAM_PAID_SAVED',
        field: 'paidSaved',
      },
      {
        label: 'Shares',
        displayLabel: 'Shares - Promoted',
        value: 'INSTAGRAM_PAID_SHARES',
        field: 'paidShares',
      },
    ],
  },
  {
    label: 'Total',
    children: [
      {
        label: 'Comments',
        displayLabel: 'Comments - Total',
        value: 'INSTAGRAM_TOTAL_COMMENTS',
        field: 'totalComments',
      },
      {
        label: 'Engagements',
        displayLabel: 'Engagements - Total',
        value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
        field: 'sumTotalEngagements',
      },
      {
        label: 'Impressions',
        displayLabel: 'Impressions - Total',
        value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
        field: 'totalImpressions',
      },
      {
        label: 'Likes',
        displayLabel: 'Likes - Total',
        value: 'INSTAGRAM_TOTAL_LIKES',
        field: 'totalLikes',
      },
      {
        label: 'Plays',
        displayLabel: 'Plays - Total',
        value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
        field: 'totalVideoViews',
      },
      {
        label: 'Reach',
        displayLabel: 'Reach - Total',
        value: 'INSTAGRAM_PAID_AND_ORGANIC_REACH',
        field: 'paidAndOrganicReach',
      },
      {
        label: 'Saves',
        displayLabel: 'Saves - Total',
        value: 'INSTAGRAM_PAID_AND_ORGANIC_SAVED',
        field: 'paidAndOrganicSaved',
      },
      {
        label: 'Shares',
        displayLabel: 'Shares - Total',
        value: 'INSTAGRAM_PAID_AND_ORGANIC_SHARES',
        field: 'paidAndOrganicShares',
      },
    ],
  },
];

const yourPostsSortOptionsOrganicGroup = [
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_AVG_WATCH_TIME,
    value: 'INSTAGRAM_AVG_WATCH_TIME',
    field: postMetricFields.ORGANIC_AVG_WATCH_TIME,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_COMMENTS,
    value: 'INSTAGRAM_ORGANIC_COMMENTS',
    field: postMetricFields.ORGANIC_COMMENTS,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_EFFECTIVENESS,
    value: 'INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS',
    field: postMetricFields.ORGANIC_EFFECTIVENESS,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_ENGAGEMENTS,
    value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    field: postMetricFields.ORGANIC_ENGAGEMENTS,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_ENGAGEMENT_RATE_FOLLOWERS,
    value: 'INSTAGRAM_ENGAGEMENT_RATE',
    field: postMetricFields.ORGANIC_ENGAGEMENT_RATE_FOLLOWERS,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_ENGAGEMENT_RATE_VIEWS,
    value: 'INSTAGRAM_ENGAGEMENT_RATE_VIEWS',
    field: postMetricFields.ORGANIC_ENGAGEMENT_RATE_VIEWS,
  },
  {
    displayLabel: instagramPostMetricLabels.ENTERTAINMENT_SCORE,
    value: 'INSTAGRAM_ENTERTAINMENT_SCORE',
    field: postMetricFields.ENTERTAINMENT_SCORE,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_LIKES,
    value: 'INSTAGRAM_ORGANIC_LIKES',
    field: postMetricFields.ORGANIC_LIKES,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_REACH,
    value: 'INSTAGRAM_REACH',
    field: postMetricFields.ORGANIC_REACH,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_SAVES,
    value: 'INSTAGRAM_SAVES',
    field: postMetricFields.ORGANIC_SAVES,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_SHARES,
    value: 'INSTAGRAM_SHARES',
    field: postMetricFields.ORGANIC_SHARES,
  },
  {
    displayLabel: instagramPostMetricLabels.ORGANIC_VIEWS,
    value: 'INSTAGRAM_VIEWS',
    field: postMetricFields.ORGANIC_VIEWS,
  },
];

const yourPostsSortOptionsPromotedGroup = [
  {
    displayLabel: instagramPostMetricLabels.PAID_COMMENTS,
    value: 'INSTAGRAM_PAID_COMMENTS',
    field: postMetricFields.PAID_COMMENTS,
  },
  {
    displayLabel: instagramPostMetricLabels.CTR,
    value: 'INSTAGRAM_CTR',
    field: postMetricFields.CTR,
  },
  {
    displayLabel: instagramPostMetricLabels.CPC,
    value: 'INSTAGRAM_CPC',
    field: postMetricFields.CPC,
  },
  {
    displayLabel: instagramPostMetricLabels.CPM,
    value: 'INSTAGRAM_CPM',
    field: postMetricFields.CPM,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_ENGAGEMENTS,
    value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
    field: postMetricFields.PAID_ENGAGEMENTS,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_ENGAGEMENT_RATE,
    value: 'INSTAGRAM_PAID_ENGAGEMENT_RATE',
    field: postMetricFields.PAID_ENGAGEMENT_RATE,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_IMPRESSIONS,
    value: 'INSTAGRAM_PAID_IMPRESSIONS',
    field: postMetricFields.PAID_IMPRESSIONS,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_LIKES,
    value: 'INSTAGRAM_PAID_LIKES',
    field: postMetricFields.PAID_LIKES,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_PLAYS,
    value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
    field: postMetricFields.PAID_PLAYS,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_REACH,
    value: 'INSTAGRAM_PAID_REACH',
    field: postMetricFields.PAID_REACH,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_SAVES,
    value: 'INSTAGRAM_PAID_SAVED',
    field: postMetricFields.PAID_SAVES,
  },
  {
    displayLabel: instagramPostMetricLabels.PAID_SHARES,
    value: 'INSTAGRAM_PAID_SHARES',
    field: postMetricFields.PAID_SHARES,
  },
];

const yourPostsSortOptionsTotalGroup = [
  {
    displayLabel: instagramPostMetricLabels.TOTAL_COMMENTS,
    value: 'INSTAGRAM_TOTAL_COMMENTS',
    field: postMetricFields.TOTAL_COMMENTS,
  },
  {
    displayLabel: instagramPostMetricLabels.TOTAL_ENGAGEMENTS,
    value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    field: postMetricFields.TOTAL_ENGAGEMENTS,
  },
  {
    displayLabel: instagramPostMetricLabels.TOTAL_LIKES,
    value: 'INSTAGRAM_TOTAL_LIKES',
    field: postMetricFields.TOTAL_LIKES,
  },
  {
    displayLabel: instagramPostMetricLabels.TOTAL_REACH,
    value: 'INSTAGRAM_PAID_AND_ORGANIC_REACH',
    field: postMetricFields.TOTAL_REACH,
  },
  {
    displayLabel: instagramPostMetricLabels.TOTAL_SAVES,
    value: 'INSTAGRAM_PAID_AND_ORGANIC_SAVED',
    field: postMetricFields.TOTAL_SAVES,
  },
  {
    displayLabel: instagramPostMetricLabels.TOTAL_SHARES,
    value: 'INSTAGRAM_PAID_AND_ORGANIC_SHARES',
    field: postMetricFields.TOTAL_SHARES,
  },
];

const yourPostsSortOptionsDiscontinuedGroup = [
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_ORGANIC_EFFECTIVENESS,
    value: 'INSTAGRAM_EFFECTIVENESS',
    field: postMetricFields.DISCONTINUED_ORGANIC_EFFECTIVENESS,
  },
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_ENGAGEMENT_RATE_IMPRESSIONS,
    value: 'INSTAGRAM_ENGAGEMENT_RATE_IMPRESSIONS',
    field: postMetricFields.DISCONTINUED_ENGAGEMENT_RATE_IMPRESSIONS,
  },
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_ORGANIC_IMPRESSIONS,
    value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
    field: postMetricFields.DISCONTINUED_ORGANIC_IMPRESSIONS,
  },
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_ORGANIC_PLAYS,
    value: 'INSTAGRAM_VIDEO_VIEWS',
    field: postMetricFields.DISCONTINUED_ORGANIC_PLAYS,
  },
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_ORGANIC_ALL_PLAYS,
    value: 'INSTAGRAM_TOTAL_PLAYS',
    field: postMetricFields.DISCONTINUED_ORGANIC_ALL_PLAYS,
  },
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_TOTAL_IMPRESSIONS,
    value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
    field: postMetricFields.DISCONTINUED_TOTAL_IMPRESSIONS,
  },
  {
    displayLabel: instagramPostMetricLabels.DISCONTINUED_TOTAL_PLAYS,
    value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
    field: postMetricFields.DISCONTINUED_TOTAL_PLAYS,
  },
];

const yourPostsSortOptionsOrganic = [
  { label: 'Recently Published', value: 'DATE' },
  {
    label: 'Organic',
    children: yourPostsSortOptionsOrganicGroup,
  },
  {
    label: 'Discontinued',
    children: yourPostsSortOptionsDiscontinuedGroup,
  },
];

const yourPostsSortOptionsComplete = [
  { label: 'Recently Published', value: 'DATE' },
  {
    label: 'Organic',
    children: yourPostsSortOptionsOrganicGroup,
  },
  {
    label: 'Promoted',
    children: yourPostsSortOptionsPromotedGroup,
  },
  {
    label: 'Total',
    children: yourPostsSortOptionsTotalGroup,
  },
  {
    label: 'Discontinued',
    children: yourPostsSortOptionsDiscontinuedGroup,
  },
];

const yourPostsMediaCardLabelsDeprecated = {
  commentsCount: 'Comments - Organic',
  effectiveness: 'Effectiveness - Organic',
  totalEngagement: 'Engagements - Organic',
  engagement: 'Engagement Rate - Organic (F)',
  engagementRateImpressions: 'Engagement Rate - Organic (I)',
  impressions: 'Impressions - Organic',
  likeCount: 'Likes - Organic',
  reach: 'Reach - Organic',
  saved: 'Saves - Organic',
  shares: 'Shares - Organic',
  videoViews: 'Plays - Organic',
  totalPlays: 'All Plays - Organic',
  paidComments: 'Comments - Promoted',
  ctr: 'CTR',
  cpc: 'CPC',
  cpm: 'CPM',
  paidTotalEngagements: 'Engagements - Promoted',
  paidEngagementRate: 'Engagement Rate - Promoted (I)',
  paidImpressions: 'Impressions - Promoted',
  paidLikes: 'Likes - Promoted',
  paidReach: 'Reach - Promoted',
  paidSaved: 'Saves - Promoted',
  paidShares: 'Shares - Promoted',
  paidVideoViews: 'Plays - Promoted',
  totalComments: 'Comments - Total',
  sumTotalEngagements: 'Engagements - Total',
  totalImpressions: 'Impressions - Total',
  totalLikes: 'Likes - Total',
  paidAndOrganicReach: 'Reach - Total',
  paidAndOrganicSaved: 'Saves - Total',
  paidAndOrganicShares: 'Shares - Total',
  totalVideoViews: 'Plays - Total',
};

const yourPostMediaCardLabels = {};

Object.keys(postMetricFields).forEach((key) => {
  const field = postMetricFields[key];
  const label = instagramPostMetricLabelsShortForms[key];
  if (label) {
    yourPostMediaCardLabels[field] = label;
  }
});

export default {
  timeScaleTypes,
  routeNames,
  pdfReportTypes,
  boostedInsightsKeysLabels,
  organicInsightsKeysLabels,
  organicInsightsKeysLabelsDeprecated,
  instagramPostMetricLabels: instagramPostMetricLabelsDeprecated,
  dashboardMetricTypes,
  dashboardFollowersReportMetrics,
  dashboardMetricReportMetrics,
  dashboardContentReportMetrics,
  overviewLikeShopPostsLimit,
  overviewLikeshopPostsSorting,
  overviewTopPostsLimit,
  overviewMostEntertainingPostsLimit,
  overviewTopUgcCsvFilePrefix,
  overviewTopLineMetricsDeprecated,
  overviewTopLineMetrics,
  promotedOverviewTopLineMetricsDeprecated,
  promotedOverviewTopLineMetrics,
  promotedInsightsTopLineMetrics,
  promotedInsightsBreakdownListMetricsDeprecated,
  promotedInsightsBreakdownListMetrics,
  overviewTopVideosMetricsBreakdown,
  overviewChartOptionsDeprecated,
  overviewChartOptions,
  storyOverviewMetricKeys,
  storyBoardEmptyCopy,
  instagramBoardsDetailSortOptions,
  instagramBoardsDetailSortOptionsDeprecated,
  storyBoardsDetailSortOptions,
  topPerformingVideosSortOptionsDeprecated,
  topPerformingVideosSortOptions,
  ugcStoryMetricWarning:
    'Due to a limitation with the Meta API, UGC Story metrics are only available for connected Creator accounts.',
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
  connectCallToAction:
    "Dash Social brings you all of the insights you love, including Instagram! Ask your Dash Social administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
  contactCSCallToAction: 'Contact your Customer Service Representative to try it out!',
  askAdminCallToAction: 'Ask your Dash Social administrator to provide you access.',
  noBrandUGCPermission: "UGC Isn't Included In Your Plan",
  noUserUGCPermission: "UGC Isn't Included In Your Plan",
  noBrandUGCOrganicReachPermission: "UGC Isn't Included In Your Plan",
  noUserUGCOrganicReachPermission: "Looks Like You Don't Have Permission To Use This Feature!",
  noBrandUGCHashtagMonitorPermission: "Hashtag Monitoring Isn't Included In Your Plan",
  noUserUGCHashtagMonitorPermission: "Hashtag Monitoring Isn't Included In Your Plan",
  connectCallToActionUGC:
    'Dash Social brings you all the insights you love, including User Generated Content. Ask your Dash Social administrator to provide you access.',
  connectCallToActionContentRights:
    'Dash Social brings you all the insights you love, including UGC Content Rights. Ask your Dash Social administrator to provide you access.',
  connectCallToActionOrganicReach:
    'Dash Social brings you all the insights you love, including User Generated Content. Ask your Dash Social administrator to provide you access.',
  connectCallToActionHashtagMonitor:
    'Dash Social brings you all the insights you love, including Hashtag Monitoring. Ask your Dash Social administrator to provide you access.',
  accountOverviewTooltips: {
    contentReach:
      'Total number of times that posts, stories, and promoted posts have been uniquely viewed.',
    contentImpression:
      'Total number of times that posts, stories, and promoted posts have been viewed.',
    profileViews: 'The total number of times your profile has been viewed.',
    interactions:
      'This set of insights measures the actions people take when they engage with your account.',
    websiteClicks: 'Total number of clicks on the website link in your profile.',
    emailContactClicks: 'Total number of clicks on the email link in your profile.',
    textMessageClicks: 'Total number of clicks on the text message link in your profile.',
    getDirectionClicks: 'Total number of clicks on the directions link in your profile.',
  },
  followerTooltips: {
    followersChart: 'Lost followers data is received once per day in the Pacific Timezone.',
    followersGrowth: 'Based on PST last day',
    totalFollowers: 'Total followers is hourly, expected behaviour is not going to align.',
    topInfluentialFans:
      'The top accounts that have @ mentioned or photo-tagged you sorted by audience size',
    ageDistributionChart: {
      All: 'The breakdown of your followers by age. This metric is estimated by Instagram.',
      Men: 'The breakdown of your male followers by age. This metric is estimated by Instagram.',
      Women:
        'The breakdown of your female followers by age. This metric is estimated by Instagram.',
      Undefined:
        'The breakdown of your undefined followers by age. This metric is estimated by Instagram.',
    },
    locationDistributionChart: {
      City: 'The top cities where your followers are located. This metric is estimated by Instagram.',
      Country:
        'The top countries your followers are located. This metric is estimated by Instagram.',
    },
    genderChart:
      'The breakdown of your followers by gender. This metric is estimated by Instagram.',
    followersHourlyCSVDownload: 'We are able to receive lost followers data only once a day.',
    followersCSVDownload:
      'We are able to receive data about lost followers only once a day, and in the Pacific Timezone. Therefore, the net new follower count (displayed hourly and custom to user’s timezone) can have discrepancies.',
    onlineFollowers:
      'The average times your followers were active during the reporting period. Data from the Instagram API may be delayed by up to 3 days.',
  },
  followerBannerOnlineFollowers:
    'Follower activity data from the Instagram API may be delayed by up to 3 days',
  followerChartTypes: {
    growth: 'Growth',
    lost: 'Lost',
  },
  instagramMediaCardDefaultsDeprecated: {
    labels: {
      comments_count: 'Comments',
      engagement: 'Engagement Rate',
      likeCount: 'Likes',
      saved: 'Saves',
      totalEngagement: 'Total Engagements',
    },
    metrics: ['engagement', 'effectiveness', 'totalEngagement', 'reach'],
  },
  instagramMediaCardDefaults: {
    labels: {
      comments_count: 'Comments',
      engagement: 'Engagement Rate',
      likeCount: 'Likes',
      saved: 'Saves',
      totalEngagement: 'Total Engagements',
    },
    metrics: ['engagement', 'effectivenessEngagements', 'totalEngagement', 'reach'],
  },
  storyFramesDefaultSort: 'DATE',
  storyFramesSortOptions,
  storyFramesFilterOptions: [
    { label: 'Media Type', value: filters.MEDIA_TYPE },
    { label: 'Caption', value: filters.CAPTION },
  ],
  storyFrameWidgets: {
    media_types: {
      name: 'MediaFilterRadioWidget',
      label: 'Media Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { label: 'Photo', value: 'IMAGE' },
          { label: 'Video', value: 'VIDEO' },
        ],
      },
    },
    caption_keywords: {
      name: 'MediaFilterSearchWidget',
      label: 'Caption',
      options: {
        placeholder: 'Enter keywords, #hashtags or @mentions',
      },
    },
  },
  instagramStoryFrameMetricKeys,
  instagramStoryFrameDefaultMetrics: [
    instagramStoryFrameMetricKeys.REACH,
    instagramStoryFrameMetricKeys.IMPRESSIONS,
    instagramStoryFrameMetricKeys.COMPLETION_RATE,
    instagramStoryFrameMetricKeys.EXIT_RATE,
    instagramStoryFrameMetricKeys.EXITS,
    instagramStoryFrameMetricKeys.SWIPE_UPS,
    instagramStoryFrameMetricKeys.REPLIES,
  ],
  instagramStoryFrameMetrics,

  storyBoardSkeletonDefaultProps: {
    gap: '1rem',
    minItemWidth: '17rem',
    skeleton: {
      type: 'galleryCard',
      count: 24,
    },
  },
  instagramStoryCardSkeletonDefaultProps: {
    gap: '1rem',
    minItemWidth: '17rem',
    skeleton: {
      type: 'instagramStoryCard',
      count: 24,
    },
  },
  storyBoardsSortOptions: {
    date: {
      value: 'CREATED',
      label: 'Recently Added',
    },
    reach: {
      value: 'IG_STORY_AVG_REACH',
      label: 'Avg. Reach',
    },
    impressions: {
      value: 'IG_STORY_AVG_IMPRESSIONS',
      label: 'Avg. Impressions',
    },
    completionRate: {
      value: 'IG_STORY_AVG_COMPLETION_RATE',
      label: 'Avg. Completion Rate',
    },
    exitRate: {
      value: 'IG_STORY_AVG_EXIT_RATE',
      label: 'Avg. Exit Rate',
    },
    swipeUps: {
      value: 'IG_STORY_SWIPE_UPS',
      label: 'Link Clicks',
    },
  },
  storyBoardsDatesOptions: {
    ALL_TIME: { label: 'All Time', value: null },
    TWENTY_FOUR_HORUS: { label: 'Last 24 Hours', value: 1 },
    THREE_DAYS: { label: 'Last 3 Days', value: 3 },
    SEVEN_DAYS: { label: 'Last 7 Days', value: 7 },
    FOUR_WEEKS: { label: 'Last 4 Weeks', value: 28 },
  },
  storyBoardDetailsSortOptions,
  storyNarrativeMetricKeys,
  storyOverviewToplineMetricKeys,
  storyOverviewToplineMetrics,
  storyOverviewChartOptions,
  storyNarrativeMetrics,
  storyOverviewListSortOptions: {
    date: {
      value: 'PUBLISHED_AT',
      label: 'Recently Published',
    },
    reach: {
      value: storyOverviewMetricKeys.AVG_REACH,
      label: 'Avg. Reach',
    },
    impressions: {
      value: storyOverviewMetricKeys.AVG_IMPRESSIONS,
      label: 'Avg. Impressions',
    },
    completionRate: {
      value: storyOverviewMetricKeys.AVG_COMPLETION_RATE,
      label: 'Avg. Completion Rate',
    },
    exitRate: {
      value: storyOverviewMetricKeys.AVG_EXIT_RATE,
      label: 'Avg. Exit Rate',
    },
    swipeUps: {
      value: storyOverviewMetricKeys.SWIPE_UPS,
      label: 'Link Clicks',
    },
  },
  storyOverviewListPageSize: 20,

  yourPostsDefaultSort: 'DATE',
  // yourPostsSortOptions: value is used for sorting;
  // label is used for displaying;
  // field means the returned key from instagram_post_stats
  yourPostsSortOptions: [
    { value: 'DATE', label: 'Recently Published' },
    { value: 'INSTAGRAM_ORGANIC_COMMENTS', label: 'Comments - Organic', field: 'commentsCount' },
    { value: 'INSTAGRAM_PAID_COMMENTS', label: 'Comments - Promoted', field: 'paidComments' },
    { value: 'INSTAGRAM_TOTAL_COMMENTS', label: 'Comments - Total ', field: 'totalComments' },
    { value: 'INSTAGRAM_EFFECTIVENESS', label: 'Effectiveness - Organic', field: 'effectiveness' },
    {
      value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
      label: 'Engagements - Organic',
      field: 'totalEngagement',
    },
    {
      value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
      label: 'Engagements - Promoted ',
      field: 'paidTotalEngagements',
    },
    {
      value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
      label: 'Engagements - Total',
      field: 'sumTotalEngagements',
    },
    { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate - Organic', field: 'engagement' },
    {
      value: 'INSTAGRAM_ENTERTAINMENT_SCORE',
      label: 'Entertainment Score',
      field: 'entertainmentScore',
    },
    {
      value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
      label: 'Impressions - Organic',
      field: 'impressions',
    },
    {
      value: 'INSTAGRAM_PAID_IMPRESSIONS',
      label: 'Impressions - Promoted',
      field: 'paidImpressions',
    },
    {
      value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
      label: 'Impressions - Total',
      field: 'totalImpressions',
    },
    { value: 'INSTAGRAM_ORGANIC_LIKES', label: 'Likes - Organic', field: 'likeCount' },
    { value: 'INSTAGRAM_PAID_LIKES', label: 'Likes - Promoted', field: 'paidLikes' },
    { value: 'INSTAGRAM_TOTAL_LIKES', label: 'Likes - Total', field: 'totalLikes' },
    { value: 'INSTAGRAM_REACH', label: 'Reach - Organic', field: 'reach' },
    { value: 'INSTAGRAM_SAVES', label: 'Saves - Organic', field: 'saved' },
    { value: 'INSTAGRAM_SHARES', label: 'Reels - Shares - Organic', field: 'shares' },
    { value: 'INSTAGRAM_VIDEO_VIEWS', label: 'Video Views - Organic', field: 'videoViews' },
    {
      value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
      label: 'Video Views - Promoted',
      field: 'paidVideoViews',
    },
    {
      value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
      label: 'Video Views - Total',
      field: 'totalVideoViews',
    },
  ],
  yourPostsSortOptionsAdsNeverConnectedDeprecated,
  yourPostsSortOptionsAdsConnectedDeprecated,
  yourPostsSortOptionsOrganic,
  yourPostsSortOptionsComplete,
  yourPostsFilterOptions: [
    { label: 'Post Type', value: filters.POST_TYPE },
    { label: 'Media Type', value: filters.MEDIA_TYPE },
    { label: 'Caption', value: filters.CAPTION },
  ],
  yourPostsWidgets: {
    is_boosted: {
      name: 'MediaFilterRadioWidget',
      label: 'Post Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { label: 'Organic Posts', value: 'false' },
          { label: 'Promoted Posts', value: 'true' },
        ],
      },
    },
    media_types: {
      name: 'MediaFilterCheckboxWidget',
      label: 'Media Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { formattedLabel: 'Photo', label: 'photo', value: 'IMAGE' },
          { formattedLabel: 'Video', label: 'video', value: 'VIDEO' },
          { formattedLabel: 'Carousel', label: 'carousel', value: 'CAROUSEL' },
          { formattedLabel: 'Reel', label: 'reel', value: 'REEL' },
        ],
      },
    },
    caption_keywords: {
      name: 'MediaFilterSearchWidget',
      label: 'Caption',
    },
  },
  yourPostsDefaultMetricsDeprecated: ['engagement', 'effectiveness', 'totalEngagement', 'reach'],
  yourPostsDefaultMetricsAdsDeprecated: [
    'engagement',
    'effectiveness',
    'sumTotalEngagements',
    'paidAndOrganicReach',
  ],
  yourPostsDefaultMetricsOrganic: [
    postMetricFields.ORGANIC_ENGAGEMENT_RATE_FOLLOWERS,
    postMetricFields.ORGANIC_EFFECTIVENESS,
    postMetricFields.TOTAL_ENGAGEMENTS,
    postMetricFields.ORGANIC_REACH,
  ],
  yourPostsDefaultMetricsWithPaid: [
    postMetricFields.ORGANIC_ENGAGEMENT_RATE_FOLLOWERS,
    postMetricFields.ORGANIC_EFFECTIVENESS,
    postMetricFields.TOTAL_ENGAGEMENTS,
    postMetricFields.TOTAL_REACH,
  ],
  yourPostsMediaCardLabelsDeprecated,
  yourPostMediaCardLabels,
  organicReachDefaultSort: 'DATE',
  organicReachSortOptions: [
    { value: 'DATE', label: 'Recently Published' },
    { value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS', label: 'Engagements', field: 'totalEngagement' },
    { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate', field: 'engagement' },
    { value: 'INSTAGRAM_EFFECTIVENESS', label: 'Effectiveness', field: 'effectiveness' },
    { value: 'INSTAGRAM_REACH', label: 'Estimated Reach', field: 'reach' },
    { value: 'INSTAGRAM_TOTAL_LIKES', label: 'Likes', field: 'totalLikes' },
    { value: 'INSTAGRAM_TOTAL_COMMENTS', label: 'Comments', field: 'totalComments' },
  ],
  organicReachFilterOptions: [
    { label: 'UGC Type', value: filters.UGC_TYPE },
    { label: 'Media Type', value: filters.UGC_POST_TYPE },
    { label: 'Relationship', value: filters.IS_RIQ },
  ],
  organicReachMetricsLabels: {
    totalEngagement: 'Engagements',
    engagement: 'Engagement Rate',
    effectiveness: 'Effectiveness',
    reach: 'Estimated Reach',
    totalLikes: 'Likes',
    totalComments: 'Comments',
  },
  organicReachWidgets: {
    ugc_media_type: {
      name: 'MediaFilterRadioWidget',
      label: 'UGC Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { label: 'Mentioned You', value: 'MENTIONED' },
          { label: 'Tagged You', value: 'TAGGED' },
        ],
      },
    },
    post_types: {
      name: 'MediaFilterCheckboxWidget',
      label: 'Media Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { formattedLabel: 'Photo', label: 'photo', value: 'IMAGE' },
          { formattedLabel: 'Video', label: 'video', value: 'VIDEO' },
          { formattedLabel: 'Carousel', label: 'carousel', value: 'CAROUSEL' },
          { formattedLabel: 'Reel', label: 'reel', value: 'REEL' },
        ],
      },
    },
    is_riq: {
      name: 'MediaFilterRadioWidget',
      label: 'Relationship',
      noDuplicate: true,
      options: {
        filterOptions: [
          { label: 'Added', value: 'true' },
          { label: 'Not Added', value: 'false' },
        ],
      },
    },
    caption_sentiment: {
      name: 'MediaFilterCheckboxWidget',
      label: 'Sentiment',
      noDuplicate: true,
      disableDelete: true,
      options: {
        filterOptions: [
          {
            label: SENTIMENT_LABEL_BY_TYPE.positivePercentage.title,
            icon: {
              name: SENTIMENT_LABEL_BY_TYPE.positivePercentage.iconName,
              color: SENTIMENT_LABEL_BY_TYPE.positivePercentage.iconColor,
            },
            value: SENTIMENT_LABEL_BY_TYPE.positivePercentage.title,
          },
          {
            label: SENTIMENT_LABEL_BY_TYPE.neutralPercentage.title,
            icon: {
              name: SENTIMENT_LABEL_BY_TYPE.neutralPercentage.iconName,
              color: SENTIMENT_LABEL_BY_TYPE.neutralPercentage.iconColor,
            },
            value: SENTIMENT_LABEL_BY_TYPE.neutralPercentage.title,
          },
          {
            label: SENTIMENT_LABEL_BY_TYPE.negativePercentage.title,
            icon: {
              name: SENTIMENT_LABEL_BY_TYPE.negativePercentage.iconName,
              color: SENTIMENT_LABEL_BY_TYPE.negativePercentage.iconColor,
            },
            value: SENTIMENT_LABEL_BY_TYPE.negativePercentage.title,
          },
        ],
      },
    },
  },
  organicReachFilterMappings: {
    captionSentiment: {
      label: '',
      field: 'caption_sentiment',
      emptyOperator: true,
      value: [],
      props: {
        allowEmpty: true,
        minimalFilterStyle: true,
        disableOpenOnMountIfValueNull: true,
      },
      operator: 'is',
    },
    postTypes: {
      field: 'post_types',
      operator: 'is',
    },
    ugcMediaType: {
      field: 'ugc_media_type',
      operator: 'is',
    },
    isRiq: {
      field: 'is_riq',
      operator: 'is',
    },
  },
  likeshopAllMediaSortOptions: [
    {
      value: '-POSTED',
      label: 'Recently Published',
    },
    {
      value: '-CLICKS',
      label: 'Clicks',
    },
  ],
  likeshopCustomContentGuide: {
    buttonText: 'Customize LikeShop',
    buttonTextExistingContent: 'Customize',
    title: 'Customize Your LikeShop',
    message:
      'Pin top performing content, collect emails, and brand your LikeShop by adding a banner.',
  },
  likeshopCustomContentSetting: {
    bannerLinksLimit: 1,
    pinnedImageLinksLimit: 25,
    numOfPinnedItems: 3,
    recommendedCustomContentWidth: 1080,
    emailDescriptionMaxLen: 93,
    emailPrivacyPolicyLinkMaxLen: 2048,
    emailButtonLabelMaxLen: 13,
    bannerTitle: 'Banner Image',
    bannerMessage: '1080px width or greater required. 2:1 aspect ratio recommended.',
    pinnedContentTitle: 'Pin Content',
    pinnedContentMessage:
      'Pin up to three pieces. At least one link is required on each piece of content.',
    emailCaptureTitle: 'Email Capture',
    emailCaptureMessage:
      'Convert your followers to newsletter subscribers or get in touch through email marketing. All submissions can be downloaded in a CSV.',
    privacyPolicyTooltip:
      'Link provided to your audience outlining your privacy policy. Required as per the Interactive Features section of our Terms of Service and applicable legislation.',
    bannerWidthErrorMessage:
      'Your banner has a low resolution which will compromise its quality when published. Please add an image with a width of 1080px or greater.',
    pinnedContentWidthWarningMessage:
      'One or more of your images are low resolution, which may compromise its quality when published. We recommend using an image with a width of 1080px or greater.',
    existingMediaErrorMessage:
      'Existing LikeShop media cannot be used as a banner image or pinned content.',
    invalidPrivacyPolicyLinkMessage: 'Please enter a valid URL.',
    missingPrivacyPolicyLinkMessage: 'A privacy policy link is required to enable email capture.',
    pinnedContentMissingLinksMessage: 'Pinned content must contain links.',
    hasUploadingMediaMessage: 'Please wait for media to finish uploading.',
    // NOTE: placeholders that are updated here must be updated in shopability too and vice versa
    placeholderDescription:
      'Subscribe to our newsletter and be the first to hear about our upcoming launches.',
    placeholderButton: 'Subscribe',
    placeholderPrivacyPolicyLink: 'https://',
  },
  likeshopTabs,
  likeshopTabNames,
  dashboardReportTypes: {
    GRAPH: 'GRAPH',
    SINGLE_METRIC: 'SINGLE_METRIC',
    CONTENT: 'CONTENT',
  },
  ugcTypes,
  overviewWidgetTypes: {
    UGC: 'UGC',
    LIKESHOP: 'LIKESHOP',
  },
  overviewUGCStartDateCutoff: new Date(2019, 8),
  overviewUGCCutoffEmptyMessage: 'Top UGC may be unavailable for dates prior to September 2019',
  overviewUGCEmptyMessage: 'No posts found during this time range',
  instagramStoryBoardFilterWidgets,
  instagramStoryBoardFilterOptions,
  overviewCSVTypes,
  disabledLikeCountTooltip: 'Like count is hidden on this post.',
  disabledVideoViewsCountTooltip:
    'Video Views only available for accounts connected in Relationships',
  userDataUnavailable:
    'Due to API limitations, follower data and reach are unavailable for this post',
  hideFbAdsBannerCacheKey: 'dh-hide-yp-fb-ads-banner',
  emptyLikeshopFeedMessage:
    'Add links to media posted on your Instagram in the "All Media" tab to populate your LikeShop',
  emptyLikeshopAllMediaMessage:
    'No active LikeShop posts found. Adjust your filters or add links to your Instagram posts.',
  instagramPostTypes,
  igHashtagUser,
  likeShopLinkCopyTooltip:
    'Click to copy. Paste this link into the links field of your Instagram profile on mobile.',
};

const metaPromotedLevelSharedFields = Object.freeze({
  sharesTotal: ['shares', 'sharesPaid'],
  savedTotal: ['saved', 'savedPaid'],
  amountSpentPaid: null,
  clicksPaid: null,
  comments: ['commentsOrganic', 'commentsCountPaid'],
  costPerThruplayPaid: null,
  cpcPaid: null,
  cpmPaid: null,
  ctrPaid: null,
  effectiveness: null,
  totalEngagements: ['totalEngagementsOrganic', 'totalEngagementsPaid'],
  engagementRate: null,
  engagementRateImpressions: null,
  engagementRatePaid: null,
  frequencyPaid: [], // Comes from ad account scoped insights
  impressions: ['impressionsOrganic', 'impressionsPaid'],
  likes: ['likesOrganic', 'likeCountPaid'],
  reachTotal: ['reach', 'reachPaid'],
  thruplaysPaid: null,
  videoPlays25Paid: null,
  videoPlays50Paid: null,
  videoPlays75Paid: null,
  videoPlays100Paid: null,
  likeshopClicks: null,
});

export const metaPromotedLevelFieldsDeprecated = Object.freeze({
  ...metaPromotedLevelSharedFields,
  follows: null,
  profileVisits: null,
  profileClicks: ['bioLinkClicks', 'calls', 'directions', 'emails', 'other', 'text'],
  effectivenessEngagements: null,
  engagementRateViews: null,
  views: null,
});

export const metricsDiscontinuationDate = '2025-04-21';
export const newMetricsStartDate = '2024-07-02';
export const newMetricFields = ['effectivenessEngagements', 'engagementRateViews', 'views'];
export const discontinuedFields = [
  'effectiveness',
  'engagementRateImpressions',
  'impressions',
  'totalPlays',
  'replays',
  'videoViews',
  'videoViewsTotal',
  'exitRate',
  'completionRate',
];

export const newStoriesMetricFields = ['completionRateViews', 'exitRateViews', 'views'];

export const storiesMediaPopupKeysToLabelsDeprecated = Object.freeze({
  likeshopClicks: 'LikeShop Clicks',
  emv: 'EMV',
  impressions: 'Impressions',
  completionRate: 'Completion Rate',
  exitRate: 'Exit Rate',
});
export const storiesMediaPopupKeysToLabels = Object.freeze({
  likeshopClicks: 'LikeShop Clicks',
  emv: 'EMV',
  impressions: 'Impressions (Discontinued)',
  completionRate: 'Completion Rate (Discontinued)',
  exitRate: 'Exit Rate (Discontinued)',
  completionRateViews: 'Completion Rate (Views)',
  exitRateViews: 'Exit Rate (Views)',
  views: 'Views',
});

export const metaPromotedTopLevelFieldsDeprecated = Object.keys(metaPromotedLevelFieldsDeprecated);

export const metaPromotedLevelFields = Object.freeze({
  ...omit(metaPromotedLevelFieldsDeprecated, discontinuedFields),
  impressionsPaid: null,
});

export const metaPromotedTopLevelFields = [
  ...metaPromotedTopLevelFieldsDeprecated.filter((key) => !discontinuedFields.includes(key)),
  'impressionsPaid',
];

export const metaPromotedFieldsToVerboseDeprecated = Object.freeze({
  amountSpentPaid: 'Amount Spent - Promoted',
  costPerThruplayPaid: 'Cost Per ThruPlay - Promoted',
  cpcPaid: 'CPC - Promoted',
  cpmPaid: 'CPM - Promoted',
  ctrPaid: 'CTR - Promoted',
  sharesTotal: 'Shares',
  videoPlaysTotalPaid: 'Video Plays - Promoted',
  savedTotal: 'Saves - Total',
  thruplaysPaid: 'ThruPlays - Promoted',
  totalEngagements: 'Engagements - Total',
  engagementRate: 'Engagement Rate - Organic (F)',
  engagementRateImpressions: 'Engagement Rate - Organic (I)',
  engagementRatePaid: 'Engagement Rate - Promoted (I)',
  impressions: 'Impressions - Total',
  likes: 'Likes - Total',
  reachTotal: 'Reach - Total',
  comments: 'Comments - Total',
  effectiveness: 'Effectiveness - Organic',
  follows: 'Follows - Organic',
  profileVisits: 'Profile Visits - Organic',
  profileClicks: 'Profile Clicks - Organic',
  bioLinkClicks: 'Bio Link Clicks - Organic',
  calls: 'Calls - Organic',
  directions: 'Directions - Organic',
  emails: 'Emails - Organic',
  other: 'Other - Organic',
  text: 'Text - Organic',
});

export const metaPromotedFieldsToVerbose = Object.freeze({
  ...metaPromotedFieldsToVerboseDeprecated,
  impressions: { label: 'Impressions - Total', nested: { organic: 'Organic (Discontinued)' } },
  engagementRateImpressions: 'Engagement Rate - Organic (I) (D)',
  effectiveness: 'Effectiveness - Organic (D)',
  effectivenessEngagements: 'Effectiveness - Organic',
  engagementRateViews: 'Engagement Rate - Organic (V)',
  views: 'Views - Organic',
});

export const metaPromotedReelFieldsToVerboseDeprecated = Object.freeze({
  ...metaPromotedFieldsToVerboseDeprecated,
  replays: 'Replays - Organic',
  totalPlays: 'All Plays - Organic',
  avgTimeViewedSec: 'Average Watch Time - Organic',
  totalTimeViewedSec: 'Total Time Viewed - Organic',
  videoViewsTotal: 'Plays - Total',
});

export const metaPromotedReelFieldsToVerbose = Object.freeze({
  ...metaPromotedFieldsToVerbose,
  replays: 'Replays - Organic (D)',
  totalPlays: 'All Plays - Organic (D)',
  avgTimeViewedSec: 'Average Watch Time - Organic',
  totalTimeViewedSec: 'Total Time Viewed - Organic',
  videoViewsTotal: { label: 'Plays - Total', nested: { organic: 'Organic (Discontinued)' } },
});

export const organicInsightsLabelsVerboseDeprecated = Object.freeze({
  engagementRate: 'Engagement Rate - Organic (F)',
  engagementRateImpressions: 'Engagement Rate - Organic (I)',
});

export const organicInsightsLabelsVerbose = Object.freeze({
  engagementRate: 'Engagement Rate - Organic (F)',
  engagementRateImpressions: 'Engagement Rate - Organic (I) (D)',
  engagementRateViews: 'Engagement Rate - Organic (V)',
  totalPlays: 'All Plays - Organic (D)',
  effectiveness: 'Effectiveness - Organic (D)',
  impressions: 'Impressions - Organic (D)',
  videoViews: 'Plays - Organic (D)',
  replays: 'Replays - Organic (D)',
});

export const connectedUgcInsightsLabelsVerbose = {
  totalPlays: 'All Plays (D)',
  effectiveness: 'Effectiveness (D)',
  effectivenessEngagements: 'Effectiveness',
  engagementRateViews: 'Engagement Rate (V)',
  impressions: 'Impressions (D)',
  videoViews: 'Plays (D)',
  replays: 'Replays (D)',
};

export const metaPromotedLevelReelFieldsDeprecated = Object.freeze({
  ...metaPromotedLevelSharedFields,
  replays: null,
  totalPlays: null,
  avgTimeViewedSec: null,
  totalTimeViewedSec: null,
  videoViewsTotal: ['videoViewsOrganic', 'videoViewsPaid'],
  sharesTotal: ['sharesOrganic', 'sharesPaid'],
});

export const metaPromotedLevelReelFields = Object.freeze({
  ...omit(metaPromotedLevelReelFieldsDeprecated, discontinuedFields),
  impressionsPaid: null,
  videoViewsPaid: null,
});

export const rateFieldFormat = Object.freeze([
  'ctrPaid',
  'effectiveness',
  'engagementRate',
  'engagementRateImpressions',
  'engagementRatePaid',
  'effectivenessEngagements',
  'engagementRateViews',
]);

export const accountingField = Object.freeze([
  'amountSpentPaid',
  'costPerThruplayPaid',
  'cpcPaid',
  'cpmPaid',
]);

export const durationFields = Object.freeze(['avgTimeViewedSec', 'totalTimeViewedSec']);

export const SORT_DETAILS_DEPRECATED = Object.freeze({
  INSTAGRAM_BOARDS: {
    options: [
      { label: 'Recently Added', value: 'CREATED' },
      { label: 'Avg. Effectiveness - Organic', value: 'INSTAGRAM_EFFECTIVENESS' },
      { label: 'Avg. Engagement Rate - Organic (Followers)', value: 'INSTAGRAM_ENGAGEMENT' },
      { label: 'Avg. Reach - Organic', value: 'INSTAGRAM_AVG_REACH' },
      { label: 'Comments', value: 'INSTAGRAM_TOTAL_COMMENTS' },
      { label: 'Likes', value: 'INSTAGRAM_TOTAL_LIKES' },
    ],
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Added', value: 'CREATED' },
    },
  },
  INSTAGRAM_STORY_BOARDS: {
    options: [
      { label: 'Recently Added', value: 'CREATED' },
      { label: 'Avg. Completion Rate', value: 'IG_STORY_AVG_COMPLETION_RATE' },
      { label: 'Avg. Exit Rate', value: 'IG_STORY_AVG_EXIT_RATE' },
      { label: 'Avg. Impressions', value: 'IG_STORY_AVG_IMPRESSIONS' },
      { label: 'Avg. Reach', value: 'IG_STORY_AVG_REACH' },
      { label: 'Link Clicks', value: 'IG_STORY_SWIPE_UPS' },
    ],
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Added', value: 'CREATED' },
    },
  },
});

export const SORT_DETAILS = Object.freeze({
  INSTAGRAM_BOARDS: {
    options: [
      { label: 'Recently Added', value: 'CREATED' },
      { label: 'Avg. Effectiveness - Organic', value: 'INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS' },
      { label: 'Avg. Engagement Rate - Organic (Followers)', value: 'INSTAGRAM_ENGAGEMENT' },
      { label: 'Avg. Reach - Organic', value: 'INSTAGRAM_AVG_REACH' },
      { label: 'Comments', value: 'INSTAGRAM_TOTAL_COMMENTS' },
      { label: 'Likes', value: 'INSTAGRAM_TOTAL_LIKES' },
    ],
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Added', value: 'CREATED' },
    },
  },
  INSTAGRAM_STORY_BOARDS: {
    options: [
      { label: 'Recently Added', value: 'CREATED' },
      { label: 'Avg. Completion Rate', value: 'IG_STORY_AVG_COMPLETION_RATE' },
      { label: 'Avg. Exit Rate', value: 'IG_STORY_AVG_EXIT_RATE' },
      { label: 'Avg. Impressions', value: 'IG_STORY_AVG_IMPRESSIONS' },
      { label: 'Avg. Reach', value: 'IG_STORY_AVG_REACH' },
      { label: 'Link Clicks', value: 'IG_STORY_SWIPE_UPS' },
    ],
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Added', value: 'CREATED' },
    },
  },
});

export const INTERACTION_INSIGHTS_METRIC = Object.freeze({
  REACH: 'REACH',
  REACH_ALL: 'REACH_ALL',
  REACH_FOLLOWERS: 'REACH_FOLLOWERS',
  REACH_FOLLOWERS_TOTAL: 'REACH_FOLLOWERS_TOTAL',
  REACH_NON_FOLLOWERS: 'REACH_NON_FOLLOWERS',
  REACH_NON_FOLLOWERS_TOTAL: 'REACH_NON_FOLLOWERS_TOTAL',
  IMPRESSIONS: 'IMPRESSIONS',
  ACCOUNTS_ENGAGED: 'ACCOUNTS_ENGAGED',
  TOTAL_INTERACTIONS: 'TOTAL_INTERACTIONS',
  TOTAL_INTERACTIONS_MEDIA_PRODUCT_TYPE: 'TOTAL_INTERACTIONS:MEDIA_PRODUCT_TYPE',
  LIKES: 'LIKES',
  LIKES_MEDIA_PRODUCT_TYPE: 'LIKES:MEDIA_PRODUCT_TYPE',
  COMMENTS: 'COMMENTS',
  COMMENTS_MEDIA_PRODUCT_TYPE: 'COMMENTS:MEDIA_PRODUCT_TYPE',
  SAVES: 'SAVES',
  SAVES_MEDIA_PRODUCT_TYPE: 'SAVES:MEDIA_PRODUCT_TYPE',
  SHARES: 'SHARES',
  SHARES_MEDIA_PRODUCT_TYPE: 'SHARES:MEDIA_PRODUCT_TYPE',
  REPLIES: 'REPLIES',
  FOLLOWERS: 'FOLLOWERS',
  PROFILE_LINKS_TAPS: 'PROFILE_LINKS_TAPS',
  WEBSITE_CLICKS: 'WEBSITE_CLICKS',
  PROFILE_VIEWS: 'PROFILE_VIEWS',
  VIEWS: 'VIEWS',
  VIEWS_ALL: 'VIEWS_ALL',
  VIEWS_FOLLOWERS: 'VIEWS_FOLLOWERS',
  VIEWS_FOLLOWERS_TOTAL: 'VIEWS_FOLLOWERS_TOTAL',
  VIEWS_NON_FOLLOWERS: 'VIEWS_NON_FOLLOWERS',
  VIEWS_NON_FOLLOWERS_TOTAL: 'VIEWS_NON_FOLLOWERS_TOTAL',
});

export const FOLLOWER_INTERACTIONS_BREAKDOWN = Object.freeze({
  GAINED: 'GAINED',
  LOST: 'LOST',
  NET_NEW: 'NET_NEW',
});

export const MEDIA_PRODUCT_TYPE_BREAKDOWN = Object.freeze({
  AD: 'AD',
  CAROUSEL_CONTAINER: 'CAROUSEL_CONTAINER',
  IGTV: 'IGTV',
  LIVE: 'LIVE',
  POST: 'POST',
  REEL: 'REEL',
});

export const MEDIA_PRODUCT_TYPE_BREAKDOWN_ALL = Object.freeze({
  ...MEDIA_PRODUCT_TYPE_BREAKDOWN,
  STORY: 'STORY',
});

export const CONTACT_BUTTON_BREAKDOWN = Object.freeze({
  BOOK_NOW: 'BOOK_NOW',
  CALL: 'CALL',
  DIRECTION: 'DIRECTION',
  EMAIL: 'EMAIL',
  TEXT: 'TEXT',
});

export const PROFILE_ACTIONS_DATA_KEY_TO_LABEL = Object.freeze({
  websiteClicks: 'Website',
  bookNow: 'Book Now',
  call: 'Calls',
  direction: 'Get Directions',
  email: 'Email',
  text: 'Text Message',
});

export const MEDIA_PRODUCT_TYPE_DATA_KEY_TO_LABEL = Object.freeze({
  ad: 'Promoted',
  carouselContainer: 'Carousels',
  igtv: 'IGTV',
  live: 'Live Videos',
  post: 'Posts',
  reel: 'Reels',
});

export const MEDIA_PRODUCT_TYPE_ALL_DATA_KEY_TO_LABEL = Object.freeze({
  ...MEDIA_PRODUCT_TYPE_DATA_KEY_TO_LABEL,
  story: 'Stories',
});

export const FOLLOWER_BREAKDOWN_DATA_KEY_TO_LABEL = Object.freeze({
  netNew: 'Net New Followers',
  gained: 'Followers Gained',
  lost: 'Followers Lost',
});

export const REACH_BREAKDOWN_KEY_TO_LABEL = Object.freeze({
  reachAll: 'All Audience',
  reachFollowers: 'Followers',
  reachNonFollowers: 'Non Followers',
});

export const VIEWS_BREAKDOWN_KEY_TO_LABEL = Object.freeze({
  viewsAll: 'All Audience',
  viewsFollowers: 'Followers',
  viewsNonFollowers: 'Non Followers',
});

export const VIEW_IMPRESSION_KEY_TO_LABEL = Object.freeze({
  profileViews: 'Profile Views',
  impressions: 'Content Impressions',
});

export const PROFILE_VIEW_KEY_TO_LABEL = Object.freeze({
  profileViews: 'Profile Views',
});

export const ACCOUNTS_REACHED_AND_VIEWS_OPTIONS = [
  { label: 'Accounts Reached', value: 'reach' },
  { label: 'Views', value: 'views' },
];

export const INTERACTION_INSIGHTS_DATA_KEY_TO_LABEL = Object.freeze({
  accountsEngaged: 'Accounts Engaged',
  totalInteractions: 'Content Engagements',
  likes: 'Likes',
  comments: 'Comments',
  shares: 'Shares',
  saves: 'Saves',
  replies: 'Story Replies',
});

export const PROFILE_ACTIVITY_INSIGHTS_TOOLTIPS = Object.freeze({
  reach:
    'The number of unique accounts that have seen your content. Reach does not include multiple views from the same accounts.',
  profileViews: 'The number of times your profile was visited',
  // TODO: Remove impressions tooltip
  impressions:
    'The number of times your content has been displayed. Impressions may include multiple views from the same accounts.',
  views:
    "The number of times your posts were displayed on a person's screen. This includes repeat views",
});

export const INTERACTIONS_INSIGHTS_TOOLTIPS = Object.freeze({
  accountsEngaged:
    'The number of accounts that have interacted with your organic and promoted posts during the reporting period.',
  totalInteractions: 'The number of likes, comments, shares and saves your content has received',
  likes: 'The number of likes your content has received',
  comments: 'The number of comments your content has received',
  shares: 'The number of shares your content has received',
  saves: 'The number of saves your content has received',
  replies:
    'The number of times people replied to one of your Instagram Stories. ' +
    'Due to API restrictions, replies from accounts in the European Economic Area (EEA) ' +
    'or Japan are not included. For Stories created in the EEA or Japan, ' +
    'Replies return a value of 0.',
  profileActions: 'The number of times people clicked one of the action buttons on your profile',
});

export const DEMOGRAPHICS_INSIGHTS_METRIC = Object.freeze({
  FOLLOWERS: 'FOLLOWERS',
  ENGAGED_AUDIENCE_WEEK: 'ENGAGED_AUDIENCE_WEEK',
  ENGAGED_AUDIENCE_MONTH: 'ENGAGED_AUDIENCE_MONTH',
  REACHED_AUDIENCE_WEEK: 'REACHED_AUDIENCE_WEEK',
  REACHED_AUDIENCE_MONTH: 'REACHED_AUDIENCE_MONTH',
});

export const AUDIENCE_DEMOGRAPHICS_KEY_TO_LABEL = Object.freeze({
  [DEMOGRAPHICS_INSIGHTS_METRIC.ENGAGED_AUDIENCE_WEEK]: 'Accounts Engaged',
  [DEMOGRAPHICS_INSIGHTS_METRIC.REACHED_AUDIENCE_WEEK]: 'Accounts Reached',
});

export const DEMOGRAPHICS_INSIGHTS_TOOLTIPS = Object.freeze({
  ageDistributionChart: {
    All: 'The breakdown of your audience by age. This metric is estimated by Instagram.',
    Men: 'The breakdown of your male audience by age. This metric is estimated by Instagram.',
    Women: 'The breakdown of your female audience by age. This metric is estimated by Instagram.',
    Undefined:
      'The breakdown of your undefined audience by age. This metric is estimated by Instagram.',
  },
  locationDistributionChart: {
    City: 'The top cities where your audience is located. This metric is estimated by Instagram.',
    Country:
      'The top countries where your audience is located. This metric is estimated by Instagram.',
  },
  genderChart: 'The breakdown of your audience by gender. This metric is estimated by Instagram.',
});

export const BACKEND_USER_INSIGHTS_CSV_TYPES = Object.freeze({
  profileActivity: 'PROFILE_ACTIVITY_INSIGHTS',
  interaction: 'INTERACTION_INSIGHTS',
  followers: 'FOLLOWERS_INSIGHTS',
});

export const USER_INSIGHTS_CSV_TYPES = Object.freeze({
  profileActivity: 'instagramProfileActivityInsights',
  interaction: 'instagramInteractionInsights',
  demographics: 'instagramDemographicsInsights',
  onlineFollowers: 'instagramOnlineFollowersInsights',
});

export const INSTAGRAM_FILTER_DETAILS = Object.freeze({
  CONTENT_RIGHTS: {
    scope: 'instagramContentRights',
    options: {
      reportingPeriod: {},
    },
    default: {
      reportingPeriod: {
        preset: 'ALL_TIME',
      },
      requestedBy: undefined,
    },
  },
});

export const ACCOUNT_OVERVIEW_QUERY_PARAM_NAMES = Object.freeze({
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  SCALE: 'scale',
  BREAKDOWN: 'breakdown',
  AUDIENCE_DEMOGRAPHICS_DATE: 'audienceDemographicsDate',
  AUDIENCE_DEMOGRAPHICS_METRIC: 'audienceDemographicsMetric',
  INTERACTION_METRIC: 'interactionMetric',
});

function createMetricBreakdownArray(baseMetric, breakdowns, { includeBaseMetric = true } = {}) {
  return [
    ...(includeBaseMetric ? [baseMetric] : []),
    ...Object.values(breakdowns).map((breakdown) => `${baseMetric}.${breakdown}`),
  ];
}

export const INTERACTIONS_DATA_KEY_TO_CSV_METRICS_SET = Object.freeze({
  accountsEngaged: Object.freeze([INTERACTION_INSIGHTS_METRIC.ACCOUNTS_ENGAGED]),
  totalInteractions: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.TOTAL_INTERACTIONS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.TOTAL_INTERACTIONS_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN_ALL,
    ),
  ]),
  likes: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.LIKES,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.LIKES_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  comments: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.COMMENTS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.COMMENTS_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  shares: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.SHARES,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.SHARES_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN_ALL,
    ),
  ]),
  saves: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.SAVES,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.SAVES_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  replies: Object.freeze([INTERACTION_INSIGHTS_METRIC.REPLIES]),
  profileActions: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.WEBSITE_CLICKS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.PROFILE_LINKS_TAPS,
      CONTACT_BUTTON_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  followers: Object.freeze([
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.FOLLOWERS,
      FOLLOWER_INTERACTIONS_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  reachAll: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.REACH,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.REACH_ALL,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  reachFollowers: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.REACH_FOLLOWERS_TOTAL,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.REACH_FOLLOWERS,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  reachNonFollowers: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.REACH_NON_FOLLOWERS_TOTAL,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.REACH_NON_FOLLOWERS,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  viewsAll: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.VIEWS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.VIEWS_ALL,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  viewsFollowers: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.VIEWS_FOLLOWERS_TOTAL,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.VIEWS_FOLLOWERS,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  viewsNonFollowers: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.VIEWS_NON_FOLLOWERS_TOTAL,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.VIEWS_NON_FOLLOWERS,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
      { includeBaseMetric: false },
    ),
  ]),
  profileViews: Object.freeze([INTERACTION_INSIGHTS_METRIC.PROFILE_VIEWS]),
  impressions: Object.freeze([INTERACTION_INSIGHTS_METRIC.IMPRESSIONS]),
});

export const UGC_LIMIT_EMPTY_STATE = {
  title: 'Experience Unlimited UGC',
  message: (limit) =>
    `Your current subscription is limited to displaying ${limit || '50'} post${limit === 1 ? '' : 's'}. Trial the Advance Social Plan to unlock more UGC.`,
};
