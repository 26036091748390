/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bluesky': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z" _fill="url(#paint0_linear_22061_426087)"/><path pid="1" d="M8.034 6.848C9.64 8.08 11.366 10.578 12 11.917c.634-1.34 2.36-3.837 3.966-5.069C17.123 5.96 19 5.272 19 7.46c0 .437-.245 3.67-.39 4.196-.498 1.825-2.32 2.29-3.94 2.009 2.832.493 3.552 2.124 1.997 3.756-2.954 3.098-4.246-.778-4.578-1.771-.06-.182-.088-.267-.089-.195 0-.072-.029.013-.09.195-.33.993-1.622 4.87-4.577 1.771-1.555-1.632-.835-3.263 1.996-3.756-1.62.282-3.44-.184-3.94-2.01C5.244 11.132 5 7.898 5 7.46c0-2.188 1.876-1.5 3.034-.612z" _fill="#fff"/><defs><linearGradient id="paint0_linear_22061_426087" x1="24" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse"><stop stop-color="#0B7BFF"/><stop offset="1" stop-color="#59B9FF"/></linearGradient></defs>'
  }
})
