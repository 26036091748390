import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import { getDateRangeOffsetFromToday } from '@/utils/dateUtils';
import { getSrcSet, IMG } from '@/assets';

export const SEARCH_DEBOUNCE_MS = 250;

export const CAMPAIGN_LIST_SORT_OPTIONS_LEGACY = [
  {
    label: 'Recently Added',
    value: 'CREATED',
  },
  {
    label: 'Campaign Name',
    value: 'NAME',
  },
];

export const CAMPAIGN_LIST_SORT_OPTIONS = [
  {
    label: 'Date Added',
    value: 'CREATED',
  },
  {
    label: 'Name',
    value: 'NAME',
  },
];

// For users with access to premium analytics (specifically can_access_campaigns)
export const PREMIUM_ANALYTICS_ADDITIONAL_CAMPAIGN_SORT_OPTIONS = [
  {
    label: 'Avg. Eng. Rate',
    value: 'AVG_ENGAGEMENT_RATE',
  },
  {
    label: 'Video Views',
    value: 'TOTAL_VIDEO_VIEWS',
  },
  {
    label: 'Engagements',
    value: 'TOTAL_ENGAGEMENTS',
  },
  {
    label: 'Impressions',
    value: 'TOTAL_IMPRESSIONS',
  },
];

export const CAMPAIGN_LIST_DEFAULT_SORT_LEGACY = {
  sortOrder: 'ASC',
  selected: CAMPAIGN_LIST_SORT_OPTIONS_LEGACY[0],
};

export const CAMPAIGN_LIST_DEFAULT_SORT = {
  sortOrder: 'ASC',
  selected: CAMPAIGN_LIST_SORT_OPTIONS[0],
};

export const tooltips = {
  topStatNumberOfPosts: 'The number of posts published across all channels',
  topStatVideoViews: 'The number of video views on posts published across all channels',
  topStatVideoViewsV2:
    'The number of video views on posts published across all channels. Includes Instagram Views',
  topStatTotalEngagements: 'The total engagements on posts published across all channels',
  topStatAvgEngagementRate:
    'The average engagement rate for your posts published across all channels',
  topStatImpressions: 'The number of impressions for your posts published across all channels',
  topStatImpressionsV2:
    'The number of impressions for your posts published across all channels. Includes Instagram Views',
  topStatClicks: 'The number of clicks on posts published across all channels',
  noMediaOnBoard: 'There is no media in this board.',
  BarLineChartAvgEngagement: 'Your average engagement rate over the last 12 weeks from today.',
  engagementRateChart: {
    campaign: 'The average engagement rate for your posts on this channel within this Campaign',
    campaignAverage: 'The average engagement rate across all channels within this Campaign',
    channelAverage: 'The average engagement rate for this channel',
  },
  sentiment: {
    overall: 'The sentiment breakdown of comments on owned posts in this Campaign',
  },
  impressionsChartDeprecated:
    'The number of impressions on all posts published across all channels',
  impressionsChart:
    'The number of impressions on all posts published across all channels. Includes Instagram Views',
};

export const accessMessages = {
  noUserAccessTitle: "Looks like you don't have permission to use this feature!",
  noAccessMessage:
    'Dash Social brings you all of the insights you love, including Campaigns! Ask your Dash Social administrator to provide you access.',
};

export const chartColors = {
  blue: colours.CHART.SET.CHART_SET_01,
  green: colours.CHART.SET.CHART_SET_02,
  orange: colours.CHART.SET.CHART_SET_03,
};

export const timeScaleTypes = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};

export const channelList = {
  TIKTOK: 'TikTok',
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  PINTEREST: 'Pinterest',
  TWITTER: 'X',
  YOUTUBE: 'YouTube',
  INSTAGRAM_STORY: ['Instagram', 'Stories'],
};
const CAMPAIGN_STATS_METRICS = {
  NUMBER_OF_POSTS: {
    valueName: 'number_of_media',
    label: 'Number of Posts',
    isPercent: false,
  },
  VIDEO_VIEWS: {
    valueName: 'video_views',
    label: 'Video Views',
    isPercent: false,
  },
  PAID_VIDEO_VIEWS: {
    valueName: 'paid_video_views',
    label: 'Video Views (Promoted)',
    isPercent: false,
  },
  TOTAL_VIDEO_VIEWS: {
    valueName: 'total_video_views',
    label: 'Video Views',
    isPercent: false,
  },
  VIDEO_VIEWS_PROMOTED: {
    valueName: 'video_views_promoted',
    label: 'Video Views (Promoted)',
    isPercent: false,
  },
  VIDEO_VIEWS_ORGANIC: {
    valueName: 'video_views_organic',
    label: 'Video Views (Organic)',
    isPercent: false,
  },
  ENGAGEMENTS: {
    valueName: 'engagements',
    label: 'Total Engagements',
    isPercent: false,
  },
  ORGANIC_ENGAGEMENTS: {
    valueName: 'engagements',
    label: 'Engagements (Organic)',
    isPercent: false,
  },
  PAID_ENGAGEMENTS: {
    valueName: 'paid_engagements',
    label: 'Engagements (Paid)',
    isPercent: false,
  },
  ENGAGEMENTS_ORGANIC: {
    valueName: 'engagements_organic',
    label: 'Engagements (Organic)',
    isPercent: false,
  },
  ENGAGEMENTS_PROMOTED: {
    valueName: 'engagements_promoted',
    label: 'Engagements (Promoted)',
    isPercent: false,
  },
  TOTAL_ENGAGEMENTS: {
    valueName: 'total_engagements',
    label: 'Engagements',
    isPercent: false,
  },
  AVG_ENGAGEMENT_RATE: {
    valueName: 'avg_engagement_rate',
    label: 'Avg. Engagement Rate',
    isPercent: true,
  },
  AVG_ENGAGEMENT_RATE_ORGANIC: {
    valueName: 'avg_engagement_rate_organic',
    label: 'Avg. Engagement Rate (Organic)',
    isPercent: true,
  },
  AVG_ENGAGEMENT_RATE_PROMOTED: {
    valueName: 'avg_engagement_rate_promoted',
    label: 'Avg. Engagement Rate (Promoted)',
    isPercent: true,
  },
  AVG_RETENTION_RATE: {
    valueName: 'avg_retention_rate',
    label: 'Avg. Retention Rate',
    isPercent: true,
  },
  AVG_REACH: {
    valueName: 'avg_reach',
    label: 'Avg. Reach',
    isPercent: false,
  },
  FYP_VIEWS: {
    valueName: 'fyp_views_percentage',
    label: 'Avg. FYP Views %',
    isPercent: true,
  },
  AVG_EFFECTIVENESS_RATE: {
    valueName: 'avg_effectiveness_rate',
    label: 'Avg. Effectiveness Rate',
    isPercent: true,
  },
  IMPRESSIONS: {
    valueName: 'impressions',
    label: 'Impressions',
    isPercent: false,
  },
  PAID_IMPRESSIONS: {
    valueName: 'paid_impressions',
    label: 'Impressions (Paid)',
    isPercent: false,
  },
  IMPRESSIONS_ORGANIC: {
    valueName: 'impressions_organic',
    label: 'Impressions (Organic)',
    isPercent: false,
  },
  IMPRESSIONS_PROMOTED: {
    valueName: 'impressions_promoted',
    label: 'Impressions (Promoted)',
    isPercent: false,
  },
  TOTAL_IMPRESSIONS: {
    valueName: 'total_impressions',
    label: 'Impressions',
    isPercent: false,
  },
  AVG_COMPLETION_RATE: {
    valueName: 'avg_completion_rate',
    label: 'Avg. Completion Rate',
    isPercent: true,
  },
  AVG_EXIT_RATE: {
    valueName: 'avg_exit_rate',
    label: 'Avg. Exit Rate',
    isPercent: true,
  },
  EXITS: {
    valueName: 'exits',
    label: 'Exits',
    isPercent: false,
  },
  LINK_CLICKS: {
    valueName: 'link_clicks',
    label: 'Link Clicks',
    isPercent: false,
  },
  LINK_CLICKS_ORGANIC: {
    valueName: 'link_clicks_organic',
    label: 'Link Clicks (Organic)',
    isPercent: false,
  },
  LINK_CLICKS_PROMOTED: {
    valueName: 'link_clicks_promoted',
    label: 'Link Clicks (Promoted)',
    isPercent: false,
  },
  OUTBOUND_CLICKS: {
    valueName: 'link_clicks',
    label: 'Outbound Clicks',
    isPercent: false,
  },
  SAVES: {
    valueName: 'saves',
    label: 'Saves',
    isPercent: false,
  },
  COMMENTS: {
    valueName: 'comments',
    label: 'Comments',
    isPercent: false,
  },
  MENTIONS: {
    valueName: 'mentions',
    label: 'Mentions',
    isPercent: false,
  },
  PEOPLE_TAGS: {
    valueName: 'people_tags',
    label: 'People Tags',
    isPercent: false,
  },
  AVG_ESTIMATED_REACH: {
    valueName: 'estimated_reach',
    label: 'Avg. Estimated Reach',
    tooltip:
      'The average of the estimated number of unique accounts who saw each post published during the reporting period',
    isPercent: false,
  },
  LIKES: {
    valueName: 'likes',
    label: 'Likes',
    isPercent: false,
  },
  LIKES_ORGANIC: {
    valueName: 'likes_organic',
    label: 'Likes (Organic)',
    isPercent: false,
  },
  LIKES_PROMOTED: {
    valueName: 'likes_promoted',
    label: 'Likes (Promoted)',
    isPercent: false,
  },
  WATCH_TIME: {
    valueName: 'watch_time',
    label: 'Watch Time',
    isPercent: false,
  },
  AVG_VIEW_DURATION: {
    valueName: 'avg_view_duration',
    label: 'Avg. View Duration',
    isPercent: false,
  },
  DISLIKES: {
    valueName: 'dislikes',
    label: 'Dislikes',
    isPercent: false,
  },
};

export const CAMPAIGN_TOP_LINE_STATS = {
  INSTAGRAM: [
    {
      title: 'Views',
      valueKey: 'views',
      organicKey: 'views',
      tooltip:
        "The number of times your posts in this Campaign were displayed on a person's screen. This includes repeat views.",
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Watch Time',
      valueKey: 'avg_avg_watch_time_sec',
      organicKey: 'avg_avg_watch_time_sec',
      tooltip:
        'The average amount of time users spent playing your Reels in this Campaign in a single viewing',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      organicKey: 'engagements',
      tooltip: `The total engagements received on your posts in this Campaign\n\nEngagements = (Likes + Saves + Comments + Shares) - (Unlikes + Unsaves + Deleted comments)`,
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Eng. Rate (Followers)',
      valueKey: 'avg_engagement_rate',
      organicKey: 'avg_engagement_rate',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      tooltip: `The average engagement rate for your posts in this Campaign\n\nEngagement Rate = Engagements / Followers`,
    },
    {
      title: 'Avg. Effectiveness Rate',
      valueKey: 'avg_effectiveness_engagements_rate',
      organicKey: 'avg_effectiveness_engagements_rate',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      tooltip:
        'The average percentage of users that saw your posts in this Campaign and engaged with them\n\nAvg. Effectiveness Rate = Engagements / Reach',
    },
    {
      title: 'Avg. Reach',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      tooltip: 'The average number of unique accounts who saw your posts in this Campaign',
      valueKey: 'avg_reach',
      organicKey: 'avg_reach',
    },
  ],
  INSTAGRAM_DEPRECATED: [
    {
      title: 'Plays',
      valueKey: 'total_video_views',
      promotedKey: 'paid_video_views',
      organicKey: 'video_views',
      tooltip:
        'The number of times your videos in this Campaign were initially played for at least 1ms. This excludes replays.',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Impressions',
      valueKey: 'total_impressions',
      promotedKey: 'paid_impressions',
      organicKey: 'impressions',
      tooltip: 'The number of impressions for your posts in this Campaign',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      organicKey: 'engagements',
      tooltip: `The total engagements received on your posts in this Campaign\n\nEngagements = (Likes + Saves + Comments + Shares) - (Unlikes + Unsaves + Deleted comments)`,
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Eng. Rate (Followers)',
      valueKey: 'avg_engagement_rate',
      organicKey: 'avg_engagement_rate',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      tooltip: `The average engagement rate for your posts in this Campaign\n\nEngagement Rate = Engagements / Followers`,
    },
    {
      title: 'Avg. Effectiveness Rate',
      valueKey: 'avg_effectiveness_rate',
      organicKey: 'avg_effectiveness_rate',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      tooltip:
        'The average effectiveness rate for your posts in this Campaign\n' +
        '\n' +
        'Feed Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Reach\n' +
        '\n' +
        'Reels Effectiveness Rate = (Likes + Comments + Saves + Video Views) / Est. Reach',
    },
    {
      title: 'Avg. Reach',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      tooltip: 'The average number of unique accounts who saw your posts in this Campaign',
      valueKey: 'avg_reach',
      organicKey: 'avg_reach',
    },
  ],
  TIKTOK: [
    {
      title: 'Video Views',
      valueKey: 'video_views',
      tooltip: 'The number of times your posts in this Campaign were viewed',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip: `The total engagements for your posts in this Campaign\n\nEngagements = Likes + Comments + Shares`,
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Engagement Rate',
      valueKey: 'avg_engagement_rate',
      tooltip: `The average engagement rate for your posts in this Campaign\n\nEngagement Rate = Total Engagements / Reach`,
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    {
      title: 'Avg. Retention Rate',
      valueKey: 'avg_retention_rate',
      tooltip: `The average percentage of your videos in this Campaign that your audience watched\n\nRetention Rate = Average Time Watched / Video Duration`,
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Reach',
      valueKey: 'avg_reach',
      tooltip: 'The average number of unique accounts who saw your posts in this Campaign',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. FYP Views %',
      valueKey: 'fyp_views_percentage',
      tooltip:
        'The average percentage of your videos in this Campaign that your audience watched on their For You Page',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  INSTAGRAM_STORY: [
    {
      title: 'Avg. Reach',
      valueKey: 'avg_reach',
      tooltip: 'The average number of unique accounts who saw your story frames in this Campaign',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Impressions',
      valueKey: 'impressions',
      tooltip: 'The number of times your story frames in this Campaign were displayed ',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Completion Rate',
      valueKey: 'avg_completion_rate',
      tooltip:
        'The average completion rate of the story frames in this Campaign\n\nCompletion Rate = (Impression - Exits) / Impressions',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Exit Rate',
      valueKey: 'avg_exit_rate',
      tooltip:
        'The average exit rate of the story frames in this Campaign\n\nExit Rate = Exits / Impressions',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Exits',
      valueKey: 'exits',
      tooltip:
        'The amount of users who exited the stories view without clicking forward or watching the entire story. An exit is counted even if it occurs at the last millisecond.',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Link Clicks',
      valueKey: 'link_clicks',
      tooltip:
        'The number of link clicks on story frames in this Campaign.\n\nOnly links scheduled through the Dash scheduler are trackable here.',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  FACEBOOK: [
    {
      title: 'Video Views',
      valueKey: 'video_views',
      tooltip:
        'The number of times your videos were played for at least 3 seconds, or for nearly its total length if shorter than 3 seconds. This excludes replays.',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Impressions',
      valueKey: 'impressions',
      tooltip: 'The number of times your posts in this Campaign were displayed',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip:
        'The total number of engagements your posts in this Campaign received\n\nEngagements = Reactions + Comments + Shares + Post Clicks + Post Clicks + Video View Clicks + Other Clicks',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Engagement Rate',
      valueKey: 'avg_engagement_rate',
      tooltip:
        'The average engagement rate for your posts in this Campaign\n\nEngagement Rate = Total Engagements / Impressions',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Effectiveness Rate',
      valueKey: 'avg_effectiveness_rate',
      tooltip:
        'The average Effectiveness Rate for your posts in this Campaign\n\nEffectiveness Rate = (Total Engagements + Video Views) / Reach',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Reach',
      valueKey: 'avg_reach',
      tooltip:
        'The average reach for your posts in this Campaign\n\nAverage Reach = Total Reach / Number of Posts Published',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  PINTEREST: [
    {
      title: 'Video Views',
      valueKey: 'video_views',
      tooltip: 'The number of views for at least two seconds with 50% of the video Pin in view',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Impressions',
      valueKey: 'impressions',
      tooltip: 'The number of times your Pins in this Campaign were displayed ',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip:
        'The total number of engagements your Pins in this Campaign received\n\nEngagements = Pin Clicks + Outbound Clicks + Saves',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Engagement Rate',
      valueKey: 'avg_engagement_rate',
      tooltip:
        'The average engagement rate for your Pins in this Campaign\n\nEngagement Rate = (Pin Clicks + Outbound Clicks + Saves) / Impressions',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Saves',
      valueKey: 'saves',
      tooltip: 'The number of times your Pins in this Campaign were saved',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Outbound Clicks',
      valueKey: 'outbound_clicks',
      tooltip:
        'The total number of times people clicked and followed the links attached to your Pins to the destination URL',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  TWITTER: [
    {
      title: 'Video Views',
      valueKey: 'video_views',
      tooltip: 'The number of times your videos in this Campaign were viewed',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Impressions',
      valueKey: 'impressions',
      tooltip: 'The number of times your posts in this Campaign were displayed',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip:
        'The total number of engagements your posts in this Campaign received\n\nEngagements = Reposts + Replies + Likes + Link Clicks',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Engagement Rate',
      valueKey: 'avg_engagement_rate',
      tooltip:
        'The average engagement rate for your posts in this Campaign\n\nEngagement Rate = (Total Engagements / Impressions)',
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Likes',
      valueKey: 'likes',
      tooltip: 'The number of times your posts in this Campaign were liked',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Link Clicks',
      valueKey: 'link_clicks',
      tooltip: 'The number of times people clicked your links in this Campaign',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  UGC: [
    {
      title: 'Number of Posts',
      valueKey: 'number_of_media',
      tooltip: 'The number of posts that mentioned or tagged you',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Mentions',
      valueKey: 'mentions',
      tooltip: `The number of times you were @mentioned by other accounts in post captions`,
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'People Tags',
      valueKey: 'people_tags',
      tooltip: `The number of times you were tagged in posts by other accounts`,
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Estimated Reach',
      valueKey: 'estimated_reach',
      tooltip:
        'The estimated number of unique accounts who saw UGC posts that @mentioned or tagged you',
      format: 'number',
      formatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip:
        'The total number of engagements UGC posts that @mentioned or tagged you received\n\nEngagements = Likes + Comments',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  YOUTUBE: [
    {
      title: 'Video Views',
      valueKey: 'video_views',
      tooltip: 'The number of times your videos in this Campaign were viewed',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip: 'The total number of engagements your videos in this Campaign received',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Watch Time',
      valueKey: 'watch_time',
      tooltip: 'The total amount of time that users watched your videos in this Campaign',
      format: 'duration',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. View Duration',
      valueKey: 'avg_view_duration',
      tooltip: 'The average amount of time that viewers watched per video view ',
      format: 'duration',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Likes',
      valueKey: 'likes',
      tooltip: 'The number of times your videos in this Campaign were liked',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Dislikes',
      valueKey: 'dislikes',
      tooltip: 'The number of dislikes on your videos in this Campaign',
      format: 'number',
      postType: 'ORGANIC_PROMOTED',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
  RELATIONSHIPS: [
    {
      title: 'Number of Posts',
      valueKey: 'number_of_media',
      tooltip: 'The number of posts that mentioned or tagged you',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Video Views',
      valueKey: 'video_views',
      tooltip: 'The number of views on your videos in this Campaign',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Avg. Engagement Rate',
      valueKey: 'avg_engagement_rate',
      tooltip:
        "The average percentage of the creators' audiences that engaged\n\nEngagement Rate = (Likes + Comments) / Followers",
      format: 'percent',
      formatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Engagements',
      valueKey: 'engagements',
      tooltip:
        'The total number of engagements on posts in this Campaign\n\nEngagements = Likes + Comments',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Likes',
      valueKey: 'likes',
      tooltip: 'The number of likes on posts in this Campaign',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
    {
      title: 'Comments',
      valueKey: 'comments',
      tooltip: 'The number of comments on posts in this Campaign',
      format: 'number',
      postType: 'ORGANIC',
      metricTimeFrame: 'POSTS_THIS_PERIOD',
    },
  ],
};

export const topLineValues = {
  tiktok: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip:
        'The average engagement rate of posts published during the reporting period. Engagement Rate = Total Engagements / Reach',
    },
    CAMPAIGN_STATS_METRICS.AVG_RETENTION_RATE,
    CAMPAIGN_STATS_METRICS.AVG_REACH,
    CAMPAIGN_STATS_METRICS.FYP_VIEWS,
  ],
  instagram: [
    {
      ...CAMPAIGN_STATS_METRICS.TOTAL_VIDEO_VIEWS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.PAID_VIDEO_VIEWS,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.TOTAL_IMPRESSIONS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.IMPRESSIONS,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.PAID_IMPRESSIONS,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.TOTAL_ENGAGEMENTS,
      valueName: 'engagements',
      tooltip: toolTips.instagramOverview.metrics.TOTAL_ENGAGEMENTS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
        label: 'Organic',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      label: 'Avg. Engagement Rate (Followers)',
      tooltip: toolTips.instagramOverview.averageEngagementRate,
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
        label: 'Organic',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_EFFECTIVENESS_RATE,
      tooltip: toolTips.instagramOverview.averageEffectiveness,
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_EFFECTIVENESS_RATE,
        label: 'Organic',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_REACH,
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_REACH,
        label: 'Organic',
      },
    },
  ],
  'instagram-stories': [
    CAMPAIGN_STATS_METRICS.AVG_REACH,
    CAMPAIGN_STATS_METRICS.IMPRESSIONS,
    CAMPAIGN_STATS_METRICS.AVG_COMPLETION_RATE,
    CAMPAIGN_STATS_METRICS.AVG_EXIT_RATE,
    CAMPAIGN_STATS_METRICS.EXITS,
    CAMPAIGN_STATS_METRICS.LINK_CLICKS,
  ],
  facebook: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.IMPRESSIONS,
    { ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS, tooltip: toolTips.facebook.engagements },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip: toolTips.facebook.averageEngagementRate,
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_EFFECTIVENESS_RATE,
      tooltip: toolTips.facebook.averageEffectiveness,
    },
    CAMPAIGN_STATS_METRICS.AVG_REACH,
  ],
  pinterest: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.IMPRESSIONS,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip:
        'The average engagement rate of the posts published during the reporting period. Engagement Rate = (Total Engagements / Impressions)',
    },
    CAMPAIGN_STATS_METRICS.SAVES,
    CAMPAIGN_STATS_METRICS.OUTBOUND_CLICKS,
  ],
  twitter: [
    {
      ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.IMPRESSIONS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.IMPRESSIONS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.IMPRESSIONS_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip:
        'The average engagement rate of the posts published during the reporting period. Engagement Rate = (Total Engagements / Impressions)',
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.LIKES,
      organic: {
        ...CAMPAIGN_STATS_METRICS.LIKES_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.LIKES_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.LINK_CLICKS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.LINK_CLICKS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.LINK_CLICKS_PROMOTED,
        label: 'Promoted',
      },
    },
  ],
  youtube: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    CAMPAIGN_STATS_METRICS.WATCH_TIME,
    CAMPAIGN_STATS_METRICS.AVG_VIEW_DURATION,
    CAMPAIGN_STATS_METRICS.LIKES,
    CAMPAIGN_STATS_METRICS.DISLIKES,
  ],
  relationships: [
    CAMPAIGN_STATS_METRICS.NUMBER_OF_POSTS,
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip: 'The average engagement rate for the posts published during the reporting period',
    },
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    CAMPAIGN_STATS_METRICS.LIKES,
    CAMPAIGN_STATS_METRICS.COMMENTS,
  ],
  ugc: [
    CAMPAIGN_STATS_METRICS.NUMBER_OF_POSTS,
    CAMPAIGN_STATS_METRICS.MENTIONS,
    CAMPAIGN_STATS_METRICS.PEOPLE_TAGS,
    CAMPAIGN_STATS_METRICS.AVG_ESTIMATED_REACH,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
  ],
  'creator-insights': [],
  'creator-deliverables': [],
  ecomm: [],
};

export const postMetricKeys = {
  TIKTOK: {
    VIDEO_VIEWS: 'TIKTOK_VIEWS',
    TOTAL_ENGAGEMENTS: 'TIKTOK_TOTAL_ENGAGEMENTS',
    EFFECTIVENESS: 'TIKTOK_EFFECTIVENESS',
    ENGAGEMENT_RATE: 'TIKTOK_ENGAGEMENT_RATE',
    RETENTION_RATE: 'TIKTOK_AVERAGE_COMPLETION_RATE',
    FYP_VIEWS: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
    DATE: 'DATE',
    IMPRESSIONS_PERSONAL_PROFILE_RATE: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
    AVERAGE_TIME_WATCHED: 'TIKTOK_AVERAGE_TIME_WATCHED',
    REACH: 'TIKTOK_REACH',
    COMMENTS: 'TIKTOK_COMMENTS',
    FULL_VIDEO_WATCHED_RATE: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
    IMPRESSIONS_SEARCH_RATE: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
    SHARES: 'TIKTOK_SHARES',
    IMPRESSIONS_SOUND_RATE: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
    IMPRESSIONS_FOLLOW_RATE: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
    TOTAL_TIME_WATCHED: 'TIKTOK_TOTAL_TIME_WATCHED',
    IMPRESSIONS_HASHTAG_RATE: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
    DURATION: 'TIKTOK_DURATION',
    LIKES: 'TIKTOK_LIKES',
    ENTERTAINMENT_SCORE: 'TIKTOK_ENTERTAINMENT_SCORE',
  },
  FACEBOOK: {
    POST_CLICKS: 'FACEBOOK_POST_CLICKS',
    COMMENTS: 'FACEBOOK_COMMENTS',
    REACH: 'FACEBOOK_REACH',
    EFFECTIVENESS: 'FACEBOOK_EFFECTIVENESS',
    REACTIONS: 'FACEBOOK_REACTIONS',
    ENGAGEMENT_RATE: 'FACEBOOK_ENGAGEMENT_RATE',
    DATE: 'DATE',
    SHARES: 'FACEBOOK_SHARES',
    IMPRESSIONS: 'FACEBOOK_IMPRESSIONS',
    TOTAL_ENGAGEMENTS: 'FACEBOOK_TOTAL_ENGAGEMENTS',
    LINK_CLICKS: 'FACEBOOK_LINK_CLICKS',
    VIDEO_COMPLETE_VIEWS: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
    OTHER_CLICKS: 'FACEBOOK_OTHER_CLICKS',
    VIDEO_VIEWS: 'FACEBOOK_VIDEO_VIEWS',
    PHOTO_VIEW_CLICKS: 'FACEBOOK_PHOTO_VIEW_CLICKS',
  },
  TWITTER: {
    DATE: 'DATE',
    ENGAGEMENT_RATE: 'TWITTER_ENGAGEMENT_RATE',
    TOTAL_ENGAGEMENTS: 'TWITTER_TOTAL_ENGAGEMENTS',
    IMPRESSIONS: 'TWITTER_IMPRESSIONS',
    LIKES: 'TWITTER_LIKES',
    VIDEO_VIEWS: 'TWITTER_VIDEO_VIEWS',
    RETWEETS: 'TWITTER_RETWEETS',
    REPLIES: 'TWITTER_REPLIES',
    TOTAL_RETWEETS: 'TWITTER_TOTAL_RETWEETS',
    URL_CLICKS: 'TWITTER_URL_CLICKS',
    FOLLOWS: 'TWITTER_FOLLOWS',
    PROFILE_CLICKS: 'TWITTER_USER_PROFILE_CLICKS',
    QUOTE_TWEETS: 'TWITTER_QUOTE_TWEETS',
  },
  INSTAGRAM_STORY: {
    DATE: 'DATE',
    REACH: 'INSTAGRAM_STORY_FRAME_REACH',
    IMPRESSIONS: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
    COMPLETION_RATE: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
    EXIT_RATE: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
    EXITS: 'INSTAGRAM_STORY_FRAME_EXITS',
    LINK_CLICKS: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
    REPLIES: 'INSTAGRAM_STORY_FRAME_REPLIES',
  },
  YOUTUBE: {
    DATE: 'DATE',
    VIDEO_VIEWS: 'YOUTUBE_VIEWS',
    WATCH_TIME: 'YOUTUBE_EST_SECONDS_WATCHED',
    AVERAGE_VIEW_DURATION: 'YOUTUBE_AVG_VIEW_DURATION',
    AVERAGE_PERCENTAGE_VIEWED: 'YOUTUBE_AVG_VIEW_PERCENTAGE',
    SUBSCRIBERS: 'YOUTUBE_SUBSCRIBERS',
    CARD_CLICKS: 'YOUTUBE_CARD_CLICKS',
    CARD_CLICK_RATE: 'YOUTUBE_CARD_CLICK_RATE',
    CARD_TEASER_CLICKS: 'YOUTUBE_CARD_TEASER_CLICKS',
    CARD_TEASER_CLICK_RATE: 'YOUTUBE_CARD_TEASER_CLICK_RATE',
    CARD_TEASER_IMPRESSIONS: 'YOUTUBE_CARD_TEASER_IMPRESSIONS',
    TOTAL_ENGAGEMENTS: 'YOUTUBE_ENGAGEMENTS',
    LIKES: 'YOUTUBE_LIKES',
    DISLIKES: 'YOUTUBE_DISLIKES',
    COMMENTS: 'YOUTUBE_COMMENTS',
    PREMIUM_VIEWS: 'YOUTUBE_PREMIUM_VIEWS',
    PREMIUM_WATCH_TIME: 'YOUTUBE_EST_PREMIUM_SECONDS_WATCHED',
    SHARES: 'YOUTUBE_SHARES',
    ADDED_TO_PLAYLIST: 'YOUTUBE_VIDEOS_ADDED_TO_PLAYLIST',
  },
  PINTEREST: {
    DATE: 'DATE',
    ENGAGEMENT_RATE: 'PINTEREST_ENGAGEMENT_RATE',
    IMPRESSIONS: 'PINTEREST_TOTAL_IMPRESSIONS',
    OUTBOUND_CLICKS: 'PINTEREST_TOTAL_CLICKS',
    SAVES: 'PINTEREST_TOTAL_SAVES',
    PIN_CLICKS: 'PINTEREST_TOTAL_CLOSEUPS',
    VIDEO_VIEWS: 'PINTEREST_TOTAL_VIDEO_VIEWS',
    AVERAGE_VIDEO_WATCH_TIME: 'PINTEREST_AVERAGE_VIDEO_WATCH_TIME',
  },
  INSTAGRAM: {
    DATE: 'DATE',
    ORGANIC_COMMENTS: 'INSTAGRAM_ORGANIC_COMMENTS',
    PAID_COMMENTS: 'INSTAGRAM_PAID_COMMENTS',
    TOTAL_COMMENTS: 'INSTAGRAM_TOTAL_COMMENTS',
    ORGANIC_EFFECTIVENESS_ENGAGEMENTS: 'INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS',
    ENGAGEMENT_RATE: 'INSTAGRAM_ENGAGEMENT_RATE',
    PAID_IMPRESSIONS: 'INSTAGRAM_PAID_IMPRESSIONS',
    ORGANIC_LIKES: 'INSTAGRAM_ORGANIC_LIKES',
    PAID_LIKES: 'INSTAGRAM_PAID_LIKES',
    TOTAL_LIKES: 'INSTAGRAM_TOTAL_LIKES',
    LIKESHOP_CLICKS: 'LIKESHOP_CLICKS',
    REACH: 'INSTAGRAM_REACH',
    SAVES: 'INSTAGRAM_SAVES',
    TOTAL_ENGAGEMENTS: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    PAID_TOTAL_ENGAGEMENTS: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
    SUM_TOTAL_ENGAGEMENTS: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    PAID_VIDEO_VIEWS: 'INSTAGRAM_PAID_VIDEO_VIEWS',
    ORGANIC_VIEWS: 'INSTAGRAM_VIEWS',
    // Discontinued metrics
    EFFECTIVENESS_DISCONTINUED: 'INSTAGRAM_EFFECTIVENESS',
    ORGANIC_IMPRESSIONS_DISCONTINUED: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
    VIDEO_VIEWS_DISCONTINUED: 'INSTAGRAM_VIDEO_VIEWS',
    TOTAL_IMPRESSIONS_DISCONTINUED: 'INSTAGRAM_TOTAL_IMPRESSIONS',
    TOTAL_VIDEO_VIEWS_DISCONTINUED: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
  },
  INSTAGRAM_DEPRECATED: {
    DATE: 'DATE',
    ORGANIC_COMMENTS: 'INSTAGRAM_ORGANIC_COMMENTS',
    PAID_COMMENTS: 'INSTAGRAM_PAID_COMMENTS',
    TOTAL_COMMENTS: 'INSTAGRAM_TOTAL_COMMENTS',
    EFFECTIVENESS: 'INSTAGRAM_EFFECTIVENESS',
    ENGAGEMENT_RATE: 'INSTAGRAM_ENGAGEMENT_RATE',
    ORGANIC_IMPRESSIONS: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
    PAID_IMPRESSIONS: 'INSTAGRAM_PAID_IMPRESSIONS',
    TOTAL_IMPRESSIONS: 'INSTAGRAM_TOTAL_IMPRESSIONS',
    ORGANIC_LIKES: 'INSTAGRAM_ORGANIC_LIKES',
    PAID_LIKES: 'INSTAGRAM_PAID_LIKES',
    TOTAL_LIKES: 'INSTAGRAM_TOTAL_LIKES',
    LIKESHOP_CLICKS: 'LIKESHOP_CLICKS',
    REACH: 'INSTAGRAM_REACH',
    SAVES: 'INSTAGRAM_SAVES',
    TOTAL_ENGAGEMENTS: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    PAID_TOTAL_ENGAGEMENTS: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
    SUM_TOTAL_ENGAGEMENTS: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    VIDEO_VIEWS: 'INSTAGRAM_VIDEO_VIEWS',
    PAID_VIDEO_VIEWS: 'INSTAGRAM_PAID_VIDEO_VIEWS',
    TOTAL_VIDEO_VIEWS: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
  },
  UGC: {
    DATE: 'DATE',
    ESTIMATED_REACH: 'INSTAGRAM_REACH',
    ENGAGEMENT_RATE: 'INSTAGRAM_ENGAGEMENT_RATE',
    ENGAGEMENTS: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    LIKES: 'INSTAGRAM_TOTAL_LIKES',
    MENTIONED_YOU: 'MENTIONED_YOU',
    TAGGED_YOU: 'TAGGED_YOU',
    REACH: 'INSTAGRAM_STORY_FRAME_REACH',
    IMPRESSIONS: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
    COMPLETION_RATE: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
    EXIT_RATE: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
    EXITS: 'INSTAGRAM_STORY_FRAME_EXITS',
  },
  RELATIONSHIPS: {
    ENGAGEMENT_RATE: 'ENGAGEMENT_RATE',
    FOLLOWERS_GAINED: 'FOLLOWERS_GAINED',
    EFFECTIVENESS: 'EFFECTIVENESS',
    EMV: 'EMV',
    REACH: 'REACH',
    IMPRESSIONS: 'IMPRESSIONS',
    COMPLETTION_RATE: 'COMPLETTION_RATE',
    EXIT_RATE: 'EXIT_RATE',
  },
};

export const defaultChannelMetrics = {
  TIKTOK: [
    postMetricKeys.TIKTOK.ENTERTAINMENT_SCORE,
    postMetricKeys.TIKTOK.VIDEO_VIEWS,
    postMetricKeys.TIKTOK.TOTAL_ENGAGEMENTS,
    postMetricKeys.TIKTOK.AVERAGE_TIME_WATCHED,
  ],
  FACEBOOK: [
    postMetricKeys.FACEBOOK.ENGAGEMENT_RATE,
    postMetricKeys.FACEBOOK.IMPRESSIONS,
    postMetricKeys.FACEBOOK.TOTAL_ENGAGEMENTS,
    postMetricKeys.FACEBOOK.REACH,
  ],
  TWITTER: [
    postMetricKeys.TWITTER.ENGAGEMENT_RATE,
    postMetricKeys.TWITTER.TOTAL_ENGAGEMENTS,
    postMetricKeys.TWITTER.IMPRESSIONS,
    postMetricKeys.TWITTER.LIKES,
  ],
  INSTAGRAM_STORY: [
    postMetricKeys.INSTAGRAM_STORY.REACH,
    postMetricKeys.INSTAGRAM_STORY.IMPRESSIONS,
    postMetricKeys.INSTAGRAM_STORY.COMPLETION_RATE,
    postMetricKeys.INSTAGRAM_STORY.EXIT_RATE,
    postMetricKeys.INSTAGRAM_STORY.EXITS,
    postMetricKeys.INSTAGRAM_STORY.LINK_CLICKS,
  ],
  YOUTUBE: [
    postMetricKeys.YOUTUBE.VIDEO_VIEWS,
    postMetricKeys.YOUTUBE.WATCH_TIME,
    postMetricKeys.YOUTUBE.AVERAGE_VIEW_DURATION,
    postMetricKeys.YOUTUBE.TOTAL_ENGAGEMENTS,
    postMetricKeys.YOUTUBE.AVERAGE_PERCENTAGE_VIEWED,
  ],
  PINTEREST: [
    postMetricKeys.PINTEREST.ENGAGEMENT_RATE,
    postMetricKeys.PINTEREST.SAVES,
    postMetricKeys.PINTEREST.IMPRESSIONS,
    postMetricKeys.PINTEREST.PIN_CLICKS,
  ],
  INSTAGRAM: [
    postMetricKeys.INSTAGRAM.ENGAGEMENT_RATE,
    postMetricKeys.INSTAGRAM.ORGANIC_EFFECTIVENESS_ENGAGEMENTS,
    postMetricKeys.INSTAGRAM.TOTAL_ENGAGEMENTS,
    postMetricKeys.INSTAGRAM.REACH,
  ],
  INSTAGRAM_DEPRECATED: [
    postMetricKeys.INSTAGRAM_DEPRECATED.ENGAGEMENT_RATE,
    postMetricKeys.INSTAGRAM_DEPRECATED.EFFECTIVENESS,
    postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_ENGAGEMENTS,
    postMetricKeys.INSTAGRAM_DEPRECATED.REACH,
  ],
  UGC: [
    postMetricKeys.UGC.ESTIMATED_REACH,
    postMetricKeys.UGC.ENGAGEMENT_RATE,
    postMetricKeys.UGC.ENGAGEMENTS,
    postMetricKeys.UGC.LIKES,
  ],
  UGC_STORY: [
    postMetricKeys.UGC.ESTIMATED_REACH,
    postMetricKeys.UGC.IMPRESSIONS,
    postMetricKeys.UGC.COMPLETION_RATE,
    postMetricKeys.UGC.EXIT_RATE,
  ],
  RELATIONSHIP_INSTAGRAM: [
    postMetricKeys.RELATIONSHIPS.ENGAGEMENT_RATE,
    postMetricKeys.RELATIONSHIPS.EFFECTIVENESS,
    postMetricKeys.RELATIONSHIPS.FOLLOWERS_GAINED,
    postMetricKeys.RELATIONSHIPS.EMV,
  ],
  RELATIONSHIP_STORY: [
    postMetricKeys.RELATIONSHIPS.REACH,
    postMetricKeys.RELATIONSHIPS.IMPRESSIONS,
    postMetricKeys.RELATIONSHIPS.COMPLETTION_RATE,
    postMetricKeys.RELATIONSHIPS.EXIT_RATE,
  ],
  RELATIONSHIPS: [
    postMetricKeys.RELATIONSHIPS.ENGAGEMENT_RATE,
    postMetricKeys.RELATIONSHIPS.EFFECTIVENESS,
    postMetricKeys.RELATIONSHIPS.FOLLOWERS_GAINED,
    postMetricKeys.RELATIONSHIPS.EMV,
  ],
};

export const legacyChannelSortOptions = {
  TIKTOK: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
      label: 'Profile Views %',
      field: 'impressions_personal_profile_rate',
    },
    {
      value: 'TIKTOK_AVERAGE_TIME_WATCHED',
      label: 'Avg. Time Watched',
      field: 'average_time_watched',
    },
    {
      value: 'TIKTOK_REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'TIKTOK_COMMENTS',
      label: 'Comments',
      field: 'comments',
    },
    {
      value: 'TIKTOK_AVERAGE_COMPLETION_RATE',
      label: 'Retention Rate',
      field: 'average_completion_rate',
    },
    {
      value: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
      label: 'Completion Rate',
      field: 'full_video_watched_rate',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
      label: 'Search Views %',
      field: 'impressions_search_rate',
    },
    {
      value: 'TIKTOK_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    {
      value: 'TIKTOK_SHARES',
      label: 'Shares',
      field: 'shares',
    },
    {
      value: 'TIKTOK_ENTERTAINMENT_SCORE',
      label: 'Entertainment Score',
      field: 'entertainment_score',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
      label: 'Sound Views %',
      field: 'impressions_sound_rate',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
      label: 'Following Page Views %',
      field: 'impressions_follow_rate',
    },
    {
      value: 'TIKTOK_TOTAL_ENGAGEMENTS',
      label: 'Engagements',
      field: 'total_engagements',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
      label: 'For You Page Views %',
      field: 'impressions_for_you_rate',
    },
    {
      value: 'TIKTOK_TOTAL_TIME_WATCHED',
      label: 'Total Time Watched',
      field: 'total_time_watched',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
      label: 'Hashtag Views %',
      field: 'impressions_hashtag_rate',
    },
    {
      value: 'TIKTOK_DURATION',
      label: 'Video Duration',
      field: 'duration',
    },
    {
      value: 'TIKTOK_LIKES',
      label: 'Likes',
      field: 'likes',
    },
    {
      value: 'TIKTOK_VIEWS',
      label: 'Video Views',
      field: 'views',
    },
  ],
  FACEBOOK: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'FACEBOOK_POST_CLICKS',
      label: 'Post Clicks',
      field: 'post_clicks',
    },
    {
      value: 'FACEBOOK_COMMENTS',
      label: 'Comments',
      field: 'comments',
    },
    {
      value: 'FACEBOOK_REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'FACEBOOK_EFFECTIVENESS',
      label: 'Effectiveness',
      field: 'effectiveness',
    },
    {
      value: 'FACEBOOK_REACTIONS',
      label: 'Reactions',
      field: 'reactions',
    },
    {
      value: 'FACEBOOK_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    {
      value: 'FACEBOOK_SHARES',
      label: 'Shares',
      field: 'shares',
    },
    {
      value: 'FACEBOOK_IMPRESSIONS',
      label: 'Impressions',
      field: 'impressions',
    },
    {
      value: 'FACEBOOK_TOTAL_ENGAGEMENTS',
      label: 'Engagements',
      field: 'total_engagements',
    },
    {
      value: 'FACEBOOK_LINK_CLICKS',
      label: 'Link Clicks',
      field: 'link_clicks',
    },
    {
      value: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
      label: 'Video Complete Views',
      field: 'video_complete_views',
    },
    {
      value: 'FACEBOOK_OTHER_CLICKS',
      label: 'Other Clicks',
      field: 'other_clicks',
    },
    {
      value: 'FACEBOOK_VIDEO_VIEWS',
      label: 'Video Views',
      field: 'video_views',
    },
    {
      value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
      label: 'Photo View Clicks',
      field: 'photo_view_clicks',
    },
  ],
  TWITTER: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'TWITTER_REPLIES',
      label: 'Replies',
      field: 'replies',
    },
    {
      value: 'TWITTER_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    {
      value: 'TWITTER_RETWEETS',
      label: 'Reposts',
      field: 'retweets',
    },
    {
      value: 'TWITTER_IMPRESSIONS',
      label: 'Impressions',
      field: 'impressions',
    },
    {
      value: 'TWITTER_TOTAL_ENGAGEMENTS',
      label: 'Engagements',
      field: 'total_engagements',
    },
    {
      value: 'TWITTER_LIKES',
      label: 'Likes',
      field: 'likes',
    },
    {
      value: 'TWITTER_TOTAL_RETWEETS',
      label: 'Total Reposts',
      field: 'total_retweets',
    },
    {
      value: 'TWITTER_URL_CLICKS',
      label: 'Link Clicks',
      field: 'url_clicks',
    },
    {
      value: 'TWITTER_FOLLOWS',
      label: 'User Follows',
      field: 'follows',
    },
    {
      value: 'TWITTER_USER_PROFILE_CLICKS',
      label: 'Profile Clicks',
      field: 'user_profile_clicks',
    },
    {
      value: 'TWITTER_VIDEO_VIEWS',
      label: 'Video Views',
      field: 'video_views',
    },
    {
      value: 'TWITTER_QUOTE_TWEETS',
      label: 'Quote Posts',
      field: 'quote_tweets',
    },
  ],
  INSTAGRAM_STORY: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    { value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS', label: 'Impressions', field: 'views' },
    {
      value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
      label: 'Completion Rate',
      field: 'completion_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
      label: 'Link Clicks',
      field: 'swipe_ups',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXITS',
      label: 'Exits',
      field: 'exits',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_REPLIES',
      label: 'Replies',
      field: 'replies',
    },
  ],
  YOUTUBE: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'YOUTUBE_COMMENTS',
      label: 'Comments',
      field: 'comments',
    },

    {
      value: 'YOUTUBE_VIDEOS_ADDED_TO_PLAYLIST',
      label: 'Added to Playlist',
      field: 'videos_added_to_playlist',
    },
    {
      value: 'YOUTUBE_DISLIKES',
      label: 'Dislikes',
      field: 'dislikes',
    },
    {
      value: 'YOUTUBE_AVG_VIEW_PERCENTAGE',
      label: 'Avg. % Viewed',
      field: 'avg_view_percentage',
    },
    {
      value: 'YOUTUBE_LIKES',
      label: 'Likes',
      field: 'likes',
    },
    {
      value: 'YOUTUBE_PREMIUM_VIEWS',
      label: 'Premimum Views',
      field: 'premimum_views',
    },

    {
      value: 'YOUTUBE_AVG_VIEW_DURATION',
      label: 'Avg. View Duration',
      field: 'avg_view_duration',
    },
    {
      value: 'YOUTUBE_EST_PREMIUM_SECONDS_WATCHED',
      label: 'Premium Watch Time',
      field: 'est_premium_seconds_watched',
    },

    {
      value: 'YOUTUBE_CARD_CLICKS',
      label: 'Card Clicks',
      field: 'card_clicks',
    },
    {
      value: 'YOUTUBE_SHARES',
      label: 'Shares',
      field: 'shares',
    },
    {
      value: 'YOUTUBE_CARD_CLICK_RATE',
      label: 'Card Click Rate',
      field: 'card_click_rate',
    },
    {
      value: 'YOUTUBE_SUBSCRIBERS',
      label: 'Subscribers',
      field: 'subscribers',
    },

    {
      value: 'YOUTUBE_CARD_TEASER_CLICKS',
      label: 'Card Teaser Clicks',
      field: 'card_teaser_clicks',
    },
    {
      value: 'YOUTUBE_ENGAGEMENTS',
      label: 'Engagements',
      field: 'engagements',
    },

    {
      value: 'YOUTUBE_CARD_TEASER_CLICK_RATE',
      label: 'Card Teaser Click Rate',
      field: 'card_teaser_click_rate',
    },

    {
      value: 'YOUTUBE_VIEWS',
      label: 'Video Views',
      field: 'views',
    },
    {
      value: 'YOUTUBE_CARD_TEASER_IMPRESSIONS',
      label: 'Card Teaser Impressions',
      field: 'card_teaser_impressions',
    },

    {
      value: 'YOUTUBE_EST_SECONDS_WATCHED',
      label: 'Watch Time',
      field: 'est_seconds_watched',
    },
  ],
  PINTEREST: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'PINTEREST_TOTAL_CLICKS',
      label: 'Outbound Clicks',
      field: 'total_clicks',
    },
    {
      value: 'PINTEREST_AVERAGE_VIDEO_WATCH_TIME',
      label: 'Average Watch Time',
      field: 'average_video_watch_time',
    },
    {
      value: 'PINTEREST_TOTAL_CLOSEUPS',
      label: 'Pin Clicks',
      field: 'total_closeups',
    },
    {
      value: 'PINTEREST_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },

    {
      value: 'PINTEREST_TOTAL_SAVES',
      label: 'Saves',
      field: 'total_saves',
    },
    {
      value: 'PINTEREST_TOTAL_IMPRESSIONS',
      label: 'Impressions',
      field: 'total_impressions',
    },

    {
      value: 'PINTEREST_TOTAL_VIDEO_VIEWS',
      label: 'Video Views',
      field: 'total_video_views',
    },
  ],
  INSTAGRAM: [
    { value: 'DATE', label: 'Recently Published' },
    { value: 'INSTAGRAM_ORGANIC_COMMENTS', label: 'Comments (Organic)', field: 'comments_count' },
    { value: 'INSTAGRAM_PAID_COMMENTS', label: 'Comments (Promoted)', field: 'paid_comments' },
    { value: 'INSTAGRAM_TOTAL_COMMENTS', label: 'Comments (Total)', field: 'total_comments' },
    { value: 'INSTAGRAM_EFFECTIVENESS', label: 'Effectiveness', field: 'effectiveness' },
    { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate', field: 'engagement' },
    {
      value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
      label: 'Impressions (Organic)',
      field: 'impressions',
    },
    {
      value: 'INSTAGRAM_PAID_IMPRESSIONS',
      label: 'Impressions (Promoted)',
      field: 'paid_impressions',
    },
    {
      value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
      label: 'Impressions (Total)',
      field: 'total_impressions',
    },
    { value: 'INSTAGRAM_ORGANIC_LIKES', label: 'Likes (Organic)', field: 'like_count' },
    { value: 'INSTAGRAM_PAID_LIKES', label: 'Likes (Promoted)', field: 'paid_likes' },
    { value: 'INSTAGRAM_TOTAL_LIKES', label: 'Likes (Total)', field: 'total_likes' },
    { value: 'LIKESHOP_CLICKS', label: 'LikeShop Clicks', field: 'likeshop_clicks' },
    { value: 'INSTAGRAM_REACH', label: 'Reach (Organic)', field: 'reach' },
    { value: 'INSTAGRAM_SAVES', label: 'Saves (Organic)', field: 'saved' },
    {
      value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
      label: 'Engagements (Organic)',
      field: 'total_engagement',
    },
    {
      value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
      label: 'Engagements (Promoted) ',
      field: 'paid_total_engagements',
    },
    {
      value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
      label: 'Engagements (Total)',
      field: 'sum_total_engagements',
    },
    { value: 'INSTAGRAM_VIDEO_VIEWS', label: 'Video Views (Organic)', field: 'video_views' },
    {
      value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
      label: 'Video Views (Promoted)',
      field: 'paid_video_views',
    },
    {
      value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
      label: 'Video Views (Total)',
      field: 'total_video_views',
    },
  ],
  UGC: [
    { value: 'DATE', label: 'Recently Published' },
    { value: 'INSTAGRAM_REACH', label: 'Reach', field: 'reach' },
    { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate', field: 'engagement' },
    { value: 'INSTAGRAM_TOTAL_ENGAGEMENTS', label: 'Engagements', field: 'total_engagement' },
    { value: 'INSTAGRAM_TOTAL_LIKES', label: 'Likes', field: 'total_likes' },
    { value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS', label: 'Impressions', field: 'views' },
    {
      value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
      label: 'Completion Rate',
      field: 'completion_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXITS',
      label: 'Exits',
      field: 'exits',
    },
  ],
};

export const CHANNEL_SORT_OPTIONS = {
  ...legacyChannelSortOptions,
  INSTAGRAM: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      title: 'Organic',
      children: [
        {
          value: 'INSTAGRAM_ORGANIC_COMMENTS',
          label: 'Comments (Organic)',
          field: 'comments_count',
        },
        {
          value: 'INSTAGRAM_EFFECTIVENESS_ENGAGEMENTS',
          label: 'Effectiveness (Organic)',
          field: 'effectiveness_engagements',
        },
        {
          value: 'INSTAGRAM_ORGANIC_LIKES',
          label: 'Likes (Organic)',
          field: 'like_count',
        },
        {
          value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
          label: 'Engagements (Organic)',
          field: 'total_engagement',
        },
        {
          value: 'INSTAGRAM_REACH',
          label: 'Reach (Organic)',
          field: 'reach',
        },
        {
          value: 'INSTAGRAM_SAVES',
          label: 'Saves (Organic)',
          field: 'saved',
        },
        {
          value: 'INSTAGRAM_ENGAGEMENT_RATE',
          label: 'Engagement Rate (Organic)',
          field: 'engagement',
        },
        {
          value: 'LIKESHOP_CLICKS',
          label: 'LikeShop Clicks',
          field: 'likeshop_clicks',
        },
        {
          value: 'INSTAGRAM_VIEWS',
          label: 'Views (Organic)',
          field: 'views',
        },
      ],
    },
    {
      title: 'Promoted',
      children: [
        {
          value: 'INSTAGRAM_PAID_COMMENTS',
          label: 'Comments (Promoted)',
          field: 'paid_comments',
        },
        {
          value: 'INSTAGRAM_PAID_IMPRESSIONS',
          label: 'Impressions (Promoted)',
          field: 'paid_impressions',
        },
        {
          value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
          label: 'Engagements (Promoted)',
          field: 'paid_total_engagements',
        },
        {
          value: 'INSTAGRAM_PAID_LIKES',
          label: 'Likes (Promoted)',
          field: 'paid_likes',
        },
        {
          value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
          label: 'Video Views (Promoted)',
          field: 'paid_video_views',
        },
      ],
    },
    {
      title: 'Total',
      children: [
        {
          value: 'INSTAGRAM_TOTAL_COMMENTS',
          label: 'Comments (Total)',
          field: 'total_comments',
        },
        {
          value: 'INSTAGRAM_TOTAL_LIKES',
          label: 'Likes (Total)',
          field: 'total_likes',
        },
        {
          value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
          label: 'Engagements (Total)',
          field: 'sum_total_engagements',
        },
      ],
    },
    {
      title: 'Discontinued',
      children: [
        {
          value: 'INSTAGRAM_EFFECTIVENESS',
          label: 'Effectiveness (Organic) (Discontinued)',
          field: 'effectiveness',
        },
        {
          value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
          label: 'Impressions (Organic) (Discontinued)',
          field: 'impressions',
        },
        {
          value: 'INSTAGRAM_VIDEO_VIEWS',
          label: 'Video Views (Organic) (Discontinued)',
          field: 'video_views',
        },
        {
          value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
          label: 'Impressions (Total) (Discontinued)',
          field: 'total_impressions',
        },
        {
          value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
          label: 'Video Views (Total) (Discontinued)',
          field: 'total_video_views',
        },
      ],
    },
  ],
  INSTAGRAM_DEPRECATED: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      title: 'Organic',
      children: [
        {
          value: 'INSTAGRAM_ORGANIC_COMMENTS',
          label: 'Comments (Organic)',
          field: 'comments_count',
        },
        {
          value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
          label: 'Impressions (Organic)',
          field: 'impressions',
        },
        {
          value: 'INSTAGRAM_ORGANIC_LIKES',
          label: 'Likes (Organic)',
          field: 'like_count',
        },
        {
          value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
          label: 'Engagements (Organic)',
          field: 'total_engagement',
        },
        {
          value: 'INSTAGRAM_REACH',
          label: 'Reach (Organic)',
          field: 'reach',
        },
        {
          value: 'INSTAGRAM_SAVES',
          label: 'Saves (Organic)',
          field: 'saved',
        },
        {
          value: 'INSTAGRAM_VIDEO_VIEWS',
          label: 'Video Views (Organic)',
          field: 'video_views',
        },
        {
          value: 'INSTAGRAM_EFFECTIVENESS',
          label: 'Effectiveness (Organic)',
          field: 'effectiveness',
        },
        {
          value: 'INSTAGRAM_ENGAGEMENT_RATE',
          label: 'Engagement Rate (Organic)',
          field: 'engagement',
        },
        {
          value: 'LIKESHOP_CLICKS',
          label: 'LikeShop Clicks',
          field: 'likeshop_clicks',
        },
      ],
    },
    {
      title: 'Promoted',
      children: [
        {
          value: 'INSTAGRAM_PAID_COMMENTS',
          label: 'Comments (Promoted)',
          field: 'paid_comments',
        },
        {
          value: 'INSTAGRAM_PAID_IMPRESSIONS',
          label: 'Impressions (Promoted)',
          field: 'paid_impressions',
        },
        {
          value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
          label: 'Engagements (Promoted)',
          field: 'paid_total_engagements',
        },
        {
          value: 'INSTAGRAM_PAID_LIKES',
          label: 'Likes (Promoted)',
          field: 'paid_likes',
        },
        {
          value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
          label: 'Video Views (Promoted)',
          field: 'paid_video_views',
        },
      ],
    },
    {
      title: 'Total',
      children: [
        {
          value: 'INSTAGRAM_TOTAL_COMMENTS',
          label: 'Comments (Total)',
          field: 'total_comments',
        },
        {
          value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
          label: 'Impressions (Total)',
          field: 'total_impressions',
        },
        {
          value: 'INSTAGRAM_TOTAL_LIKES',
          label: 'Likes (Total)',
          field: 'total_likes',
        },
        {
          value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
          label: 'Engagements (Total)',
          field: 'sum_total_engagements',
        },
        {
          value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
          label: 'Video Views (Total)',
          field: 'total_video_views',
        },
      ],
    },
  ],
};

export const postMetrics = {
  TIKTOK: {
    [postMetricKeys.TIKTOK.VIDEO_VIEWS]: {
      field: 'views',
      label: 'Video Views',
    },
    [postMetricKeys.TIKTOK.TOTAL_ENGAGEMENTS]: {
      field: 'total_engagements',
      label: 'Engagements',
    },
    [postMetricKeys.TIKTOK.EFFECTIVENESS]: {
      field: 'effectiveness',
      label: 'Effectiveness',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.ENGAGEMENT_RATE]: {
      field: 'engagement_rate',
      label: 'Engagement Rate',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.ENTERTAINMENT_SCORE]: {
      field: 'entertainment_score',
      label: 'Entertainment Score',
      format: 'two_decimal_float',
    },
    [postMetricKeys.TIKTOK.RETENTION_RATE]: {
      field: 'average_completion_rate',
      label: 'Retention Rate',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.FYP_VIEWS]: {
      field: 'impressions_for_you_rate',
      label: 'FYP Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_PERSONAL_PROFILE_RATE]: {
      field: 'impressions_personal_profile_rate',
      label: 'Profile Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.AVERAGE_TIME_WATCHED]: {
      field: 'average_time_watched',
      label: 'Avg. Time Watched',
      format: 'duration',
      num_decimals: 1,
    },
    [postMetricKeys.TIKTOK.REACH]: {
      field: 'reach',
      label: 'Reach',
    },
    [postMetricKeys.TIKTOK.COMMENTS]: {
      field: 'comments',
      label: 'Comments',
    },
    [postMetricKeys.TIKTOK.FULL_VIDEO_WATCHED_RATE]: {
      field: 'full_video_watched_rate',
      label: 'Completion Rate',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_SEARCH_RATE]: {
      field: 'impressions_search_rate',
      label: 'Search Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.SHARES]: {
      field: 'shares',
      label: 'Shares',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_SOUND_RATE]: {
      field: 'impressions_sound_rate',
      label: 'Sound Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_FOLLOW_RATE]: {
      field: 'impressions_follow_rate',
      label: 'Following Page Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.TOTAL_TIME_WATCHED]: {
      field: 'total_time_watched',
      label: 'Total Time Watched',
      format: 'duration',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_HASHTAG_RATE]: {
      field: 'impressions_hashtag_rate',
      label: 'Hashtag Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.DURATION]: {
      field: 'duration',
      label: 'Video Duration',
      format: 'duration',
    },
    [postMetricKeys.TIKTOK.LIKES]: {
      field: 'likes',
      label: 'Likes',
    },
  },
  FACEBOOK: {
    [postMetricKeys.FACEBOOK.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.FACEBOOK.POST_CLICKS]: {
      label: 'Post Clicks',
      field: 'post_clicks',
    },
    [postMetricKeys.FACEBOOK.COMMENTS]: {
      label: 'Comments',
      field: 'comments',
    },
    [postMetricKeys.FACEBOOK.REACH]: {
      label: 'Reach',
      field: 'reach',
    },
    [postMetricKeys.FACEBOOK.EFFECTIVENESS]: {
      label: 'Effectiveness',
      field: 'effectiveness',
      format: 'percent',
    },
    [postMetricKeys.FACEBOOK.REACTIONS]: {
      label: 'Reactions',
      field: 'reactions',
    },
    [postMetricKeys.FACEBOOK.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement_rate',
      format: 'percent',
    },
    [postMetricKeys.FACEBOOK.SHARES]: {
      label: 'Shares',
      field: 'shares',
    },
    [postMetricKeys.FACEBOOK.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'impressions',
    },
    [postMetricKeys.FACEBOOK.TOTAL_ENGAGEMENTS]: {
      label: 'Engagements',
      field: 'total_engagements',
    },
    [postMetricKeys.FACEBOOK.LINK_CLICKS]: {
      label: 'Link Clicks',
      field: 'link_clicks',
    },
    [postMetricKeys.FACEBOOK.VIDEO_COMPLETE_VIEWS]: {
      label: 'Video Complete Views',
      field: 'video_complete_views',
    },
    [postMetricKeys.FACEBOOK.OTHER_CLICKS]: {
      label: 'Other Clicks',
      field: 'other_clicks',
    },
    [postMetricKeys.FACEBOOK.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'video_views',
    },
    [postMetricKeys.FACEBOOK.PHOTO_VIEW_CLICKS]: {
      label: 'Photo View Clicks',
      field: 'photo_view_clicks',
    },
  },
  TWITTER: {
    [postMetricKeys.TWITTER.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.TWITTER.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    [postMetricKeys.TWITTER.TOTAL_ENGAGEMENTS]: {
      label: 'Engagements',
      field: 'total_engagements',
    },
    [postMetricKeys.TWITTER.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'impressions',
    },
    [postMetricKeys.TWITTER.LIKES]: {
      label: 'Likes',
      field: 'likes',
    },
    [postMetricKeys.TWITTER.URL_CLICKS]: {
      label: 'Link Clicks',
      field: 'url_clicks',
    },
    [postMetricKeys.TWITTER.TOTAL_RETWEETS]: {
      label: 'Total Reposts',
      field: 'total_retweets',
    },
    [postMetricKeys.TWITTER.RETWEETS]: {
      label: 'Reposts',
      field: 'retweets',
    },
    [postMetricKeys.TWITTER.FOLLOWS]: {
      label: 'User Follows',
      field: 'follows',
    },
    [postMetricKeys.TWITTER.PROFILE_CLICKS]: {
      label: 'Profile Clicks',
      field: 'user_profile_clicks',
    },
    [postMetricKeys.TWITTER.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'video_views',
    },
    [postMetricKeys.TWITTER.QUOTE_TWEETS]: {
      label: 'Quote Posts',
      field: 'quote_tweets',
    },
    [postMetricKeys.TWITTER.REPLIES]: {
      label: 'Replies',
      field: 'replies',
    },
  },
  INSTAGRAM_STORY: {
    [postMetricKeys.INSTAGRAM_STORY.REACH]: {
      label: 'Reach',
      field: 'reach',
    },
    [postMetricKeys.INSTAGRAM_STORY.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'views',
    },
    [postMetricKeys.INSTAGRAM_STORY.COMPLETION_RATE]: {
      label: 'Completion Rate',
      field: 'completion_rate',
      format: 'percent',
    },
    [postMetricKeys.INSTAGRAM_STORY.EXIT_RATE]: {
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    [postMetricKeys.INSTAGRAM_STORY.EXITS]: {
      label: 'Exits',
      field: 'exits',
    },
    [postMetricKeys.INSTAGRAM_STORY.LINK_CLICKS]: {
      label: 'Link Clicks',
      field: 'swipe_ups',
    },
    [postMetricKeys.INSTAGRAM_STORY.REPLIES]: {
      label: 'Replies',
      field: 'replies',
    },
  },
  YOUTUBE: {
    [postMetricKeys.YOUTUBE.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.YOUTUBE.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'views',
    },
    [postMetricKeys.YOUTUBE.AVERAGE_VIEW_DURATION]: {
      label: 'Avg. View Duration',
      field: 'avg_view_duration',
    },
    [postMetricKeys.YOUTUBE.AVERAGE_PERCENTAGE_VIEWED]: {
      label: 'Avg. % Viewed',
      field: 'avg_view_percentage',
    },
    [postMetricKeys.YOUTUBE.WATCH_TIME]: {
      label: 'Watch Time',
      field: 'est_seconds_watched',
    },
    [postMetricKeys.YOUTUBE.SUBSCRIBERS]: {
      label: 'Subscribers',
      field: 'subscribers',
    },
    [postMetricKeys.YOUTUBE.TOTAL_ENGAGEMENTS]: {
      label: 'Engagements',
      field: 'engagements',
    },
    [postMetricKeys.YOUTUBE.CARD_CLICKS]: {
      label: 'Card Clicks',
      field: 'card_clicks',
    },
    [postMetricKeys.YOUTUBE.CARD_CLICK_RATE]: {
      label: 'Card Click Rate',
      field: 'card_click_rate',
    },
    [postMetricKeys.YOUTUBE.CARD_TEASER_CLICKS]: {
      label: 'Card Teaser Clicks',
      field: 'card_teaser_clicks',
    },
    [postMetricKeys.YOUTUBE.CARD_TEASER_CLICK_RATE]: {
      label: 'Card Teaser Click Rate',
      field: 'card_teaser_click_rate',
    },
    [postMetricKeys.YOUTUBE.CARD_TEASER_IMPRESSIONS]: {
      label: 'Card Teaser Impressions',
      field: 'card_teaser_impressions',
    },
    [postMetricKeys.YOUTUBE.PREMIUM_VIEWS]: {
      label: 'Premium Views',
      field: 'premium_views',
    },
    [postMetricKeys.YOUTUBE.PREMIUM_WATCH_TIME]: {
      label: 'Premium Watch Time',
      field: 'est_premium_seconds_watched',
    },
    [postMetricKeys.YOUTUBE.LIKES]: {
      label: 'Likes',
      field: 'likes',
    },
    [postMetricKeys.YOUTUBE.DISLIKES]: {
      label: 'Dislikes',
      field: 'dislikes',
    },
    [postMetricKeys.YOUTUBE.COMMENTS]: {
      label: 'Comments',
      field: 'comments',
    },
    [postMetricKeys.YOUTUBE.SHARES]: {
      label: 'Shares',
      field: 'shares',
    },
    [postMetricKeys.YOUTUBE.ADDED_TO_PLAYLIST]: {
      label: 'Added to Playlist',
      field: 'videos_added_to_playlist',
    },
  },
  PINTEREST: {
    [postMetricKeys.PINTEREST.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.PINTEREST.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'total_impressions',
    },
    [postMetricKeys.PINTEREST.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    [postMetricKeys.PINTEREST.SAVES]: {
      label: 'Saves',
      field: 'total_saves',
    },
    [postMetricKeys.PINTEREST.OUTBOUND_CLICKS]: {
      label: 'Outbound Clicks',
      field: 'total_clicks',
    },
    [postMetricKeys.PINTEREST.PIN_CLICKS]: {
      label: 'Pin Clicks',
      field: 'total_closeups',
    },
    [postMetricKeys.PINTEREST.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'total_video_views',
    },
    [postMetricKeys.PINTEREST.AVERAGE_VIDEO_WATCH_TIME]: {
      label: 'Average Watch Time',
      field: 'average_video_watch_time',
    },
  },
  INSTAGRAM: {
    [postMetricKeys.INSTAGRAM.DATE]: {
      label: '',
    },
    [postMetricKeys.INSTAGRAM.ORGANIC_COMMENTS]: {
      label: 'Comments (Organic)',
      field: 'comments_count',
    },
    [postMetricKeys.INSTAGRAM.PAID_COMMENTS]: {
      label: 'Comments (Promoted)',
      field: 'paid_comments',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_COMMENTS]: {
      label: 'Comments (Total) ',
      field: 'total_comments',
    },
    [postMetricKeys.INSTAGRAM.ORGANIC_EFFECTIVENESS_ENGAGEMENTS]: {
      label: 'Effectiveness (Organic)',
      field: 'effectiveness_engagements',
    },
    [postMetricKeys.INSTAGRAM.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate (Organic)',
      field: 'engagement',
    },
    [postMetricKeys.INSTAGRAM.PAID_IMPRESSIONS]: {
      label: 'Impressions (Promoted)',
      field: 'paid_impressions',
    },
    [postMetricKeys.INSTAGRAM.ORGANIC_LIKES]: {
      label: 'Likes (Organic)',
      field: 'like_count',
    },
    [postMetricKeys.INSTAGRAM.PAID_LIKES]: {
      label: 'Likes (Promoted)',
      field: 'paid_likes',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_LIKES]: {
      label: 'Likes (Total)',
      field: 'total_likes',
    },
    [postMetricKeys.INSTAGRAM.LIKESHOP_CLICKS]: {
      label: 'LikeShop Clicks',
      field: 'likeshop_clicks',
    },
    [postMetricKeys.INSTAGRAM.REACH]: {
      label: 'Reach (Organic)',
      field: 'reach',
    },
    [postMetricKeys.INSTAGRAM.SAVES]: {
      label: 'Saves (Organic)',
      field: 'saved',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Organic)',
      field: 'total_engagement',
    },
    [postMetricKeys.INSTAGRAM.PAID_TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Promoted) ',
      field: 'paid_total_engagements',
    },
    [postMetricKeys.INSTAGRAM.SUM_TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Total)',
      field: 'sum_total_engagements',
    },
    [postMetricKeys.INSTAGRAM.PAID_VIDEO_VIEWS]: {
      label: 'Video Views (Promoted)',
      field: 'paid_video_views',
    },
    [postMetricKeys.INSTAGRAM.ORGANIC_VIEWS]: {
      label: 'Views (Organic)',
      field: 'views',
    },
    // Discontinued metrics
    [postMetricKeys.INSTAGRAM.EFFECTIVENESS_DISCONTINUED]: {
      label: 'Effectiveness (Organic) (Discontinued)',
      field: 'effectiveness',
    },
    [postMetricKeys.INSTAGRAM.ORGANIC_IMPRESSIONS_DISCONTINUED]: {
      label: 'Impressions (Organic) (Discontinued)',
      field: 'impressions',
    },
    [postMetricKeys.INSTAGRAM.VIDEO_VIEWS_DISCONTINUED]: {
      label: 'Video Views (Organic) (Discontinued)',
      field: 'video_views',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_IMPRESSIONS_DISCONTINUED]: {
      label: 'Impressions (Total) (Discontinued)',
      field: 'total_impressions',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_VIDEO_VIEWS_DISCONTINUED]: {
      label: 'Video Views (Total) (Discontinued)',
      field: 'total_video_views',
    },
  },
  INSTAGRAM_DEPRECATED: {
    [postMetricKeys.INSTAGRAM_DEPRECATED.DATE]: {
      label: '',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.ORGANIC_COMMENTS]: {
      label: 'Comments (Organic)',
      field: 'comments_count',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.PAID_COMMENTS]: {
      label: 'Comments (Promoted)',
      field: 'paid_comments',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_COMMENTS]: {
      label: 'Comments (Total) ',
      field: 'total_comments',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.EFFECTIVENESS]: {
      label: 'Effectiveness (Organic)',
      field: 'effectiveness',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate (Organic)',
      field: 'engagement',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.ORGANIC_IMPRESSIONS]: {
      label: 'Impressions (Organic)',
      field: 'impressions',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.PAID_IMPRESSIONS]: {
      label: 'Impressions (Promoted)',
      field: 'paid_impressions',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_IMPRESSIONS]: {
      label: 'Impressions (Total)',
      field: 'total_impressions',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.ORGANIC_LIKES]: {
      label: 'Likes (Organic)',
      field: 'like_count',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.PAID_LIKES]: {
      label: 'Likes (Promoted)',
      field: 'paid_likes',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_LIKES]: {
      label: 'Likes (Total)',
      field: 'total_likes',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.LIKESHOP_CLICKS]: {
      label: 'LikeShop Clicks',
      field: 'likeshop_clicks',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.REACH]: {
      label: 'Reach (Organic)',
      field: 'reach',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.SAVES]: {
      label: 'Saves (Organic)',
      field: 'saved',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Organic)',
      field: 'total_engagement',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.PAID_TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Promoted) ',
      field: 'paid_total_engagements',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.SUM_TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Total)',
      field: 'sum_total_engagements',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.VIDEO_VIEWS]: {
      label: 'Video Views (Organic)',
      field: 'video_views',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.PAID_VIDEO_VIEWS]: {
      label: 'Video Views (Promoted)',
      field: 'paid_video_views',
    },
    [postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_VIDEO_VIEWS]: {
      label: 'Video Views (Total)',
      field: 'total_video_views',
    },
  },
  UGC: {
    [postMetricKeys.UGC.DATE]: { field: '' },
    [postMetricKeys.UGC.ESTIMATED_REACH]: {
      label: 'Estimated Reach',
      field: 'reach',
    },
    [postMetricKeys.UGC.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement',
    },
    [postMetricKeys.UGC.ENGAGEMENTS]: {
      label: 'Engagements - Organic',
      field: 'total_engagement',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_LIKES]: {
      label: 'Likes',
      field: 'total_likes',
    },
    [postMetricKeys.UGC.MENTIONED_YOU]: {
      label: 'Mentioned You',
      field: 'is_mentioned',
    },
    [postMetricKeys.UGC.TAGGED_YOU]: {
      label: 'Tagged You',
      field: 'is_tagged',
    },
    [postMetricKeys.UGC.REACH]: {
      label: 'Estimated Reach',
      field: 'reach',
    },
    [postMetricKeys.UGC.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'views',
    },
    [postMetricKeys.UGC.COMPLETION_RATE]: {
      label: 'Completion Rate',
      field: 'completion_rate',
    },
    [postMetricKeys.UGC.EXIT_RATE]: {
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    [postMetricKeys.UGC.EXITS]: {
      label: 'Exits',
      field: 'exits',
    },
  },
  RELATIONSHIPS: {
    [postMetricKeys.RELATIONSHIPS.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement',
    },
    [postMetricKeys.RELATIONSHIPS.EFFECTIVENESS]: {
      label: 'Effectiveness',
      field: 'effectiveness',
    },
    [postMetricKeys.RELATIONSHIPS.FOLLOWERS_GAINED]: {
      label: 'Followers Gained',
      field: 'followersGained',
    },
    [postMetricKeys.RELATIONSHIPS.EMV]: {
      label: 'EMV',
      field: 'emv',
    },
    [postMetricKeys.RELATIONSHIPS.REACH]: {
      label: 'Reach',
      field: 'reach',
    },
    [postMetricKeys.RELATIONSHIPS.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'impressions',
    },
    [postMetricKeys.RELATIONSHIPS.COMPLETTION_RATE]: {
      label: 'Completion Rate',
      field: 'completionRate',
    },
    [postMetricKeys.RELATIONSHIPS.EXIT_RATE]: {
      label: 'Exit Rate',
      field: 'exitRate',
    },
  },
};

export const MEDIA_CARD_DISPLAY_METRICS = {
  TIKTOK: [
    postMetrics.TIKTOK[postMetricKeys.TIKTOK.ENTERTAINMENT_SCORE],
    postMetrics.TIKTOK[postMetricKeys.TIKTOK.VIDEO_VIEWS],
    postMetrics.TIKTOK[postMetricKeys.TIKTOK.TOTAL_ENGAGEMENTS],
    postMetrics.TIKTOK[postMetricKeys.TIKTOK.AVERAGE_TIME_WATCHED],
  ],
  FACEBOOK: [
    postMetrics.FACEBOOK[postMetricKeys.FACEBOOK.ENGAGEMENT_RATE],
    postMetrics.FACEBOOK[postMetricKeys.FACEBOOK.IMPRESSIONS],
    postMetrics.FACEBOOK[postMetricKeys.FACEBOOK.TOTAL_ENGAGEMENTS],
    postMetrics.FACEBOOK[postMetricKeys.FACEBOOK.REACH],
  ],
  TWITTER: [
    postMetrics.TWITTER[postMetricKeys.TWITTER.ENGAGEMENT_RATE],
    postMetrics.TWITTER[postMetricKeys.TWITTER.TOTAL_ENGAGEMENTS],
    postMetrics.TWITTER[postMetricKeys.TWITTER.IMPRESSIONS],
    postMetrics.TWITTER[postMetricKeys.TWITTER.LIKES],
  ],
  INSTAGRAM_STORY: [
    postMetrics.INSTAGRAM_STORY[postMetricKeys.INSTAGRAM_STORY.REACH],
    postMetrics.INSTAGRAM_STORY[postMetricKeys.INSTAGRAM_STORY.IMPRESSIONS],
    postMetrics.INSTAGRAM_STORY[postMetricKeys.INSTAGRAM_STORY.COMPLETION_RATE],
    postMetrics.INSTAGRAM_STORY[postMetricKeys.INSTAGRAM_STORY.EXIT_RATE],
    postMetrics.INSTAGRAM_STORY[postMetricKeys.INSTAGRAM_STORY.EXITS],
    postMetrics.INSTAGRAM_STORY[postMetricKeys.INSTAGRAM_STORY.LINK_CLICKS],
  ],
  YOUTUBE: [
    postMetrics.YOUTUBE[postMetricKeys.YOUTUBE.VIDEO_VIEWS],
    postMetrics.YOUTUBE[postMetricKeys.YOUTUBE.WATCH_TIME],
    postMetrics.YOUTUBE[postMetricKeys.YOUTUBE.AVERAGE_VIEW_DURATION],
    postMetrics.YOUTUBE[postMetricKeys.YOUTUBE.TOTAL_ENGAGEMENTS],
    postMetrics.YOUTUBE[postMetricKeys.YOUTUBE.AVERAGE_PERCENTAGE_VIEWED],
  ],
  PINTEREST: [
    postMetrics.PINTEREST[postMetricKeys.PINTEREST.ENGAGEMENT_RATE],
    postMetrics.PINTEREST[postMetricKeys.PINTEREST.SAVES],
    postMetrics.PINTEREST[postMetricKeys.PINTEREST.IMPRESSIONS],
    postMetrics.PINTEREST[postMetricKeys.PINTEREST.PIN_CLICKS],
  ],
  INSTAGRAM: [
    postMetrics.INSTAGRAM[postMetricKeys.INSTAGRAM.ENGAGEMENT_RATE],
    postMetrics.INSTAGRAM[postMetricKeys.INSTAGRAM.ORGANIC_EFFECTIVENESS_ENGAGEMENTS],
    postMetrics.INSTAGRAM[postMetricKeys.INSTAGRAM.TOTAL_ENGAGEMENTS],
    postMetrics.INSTAGRAM[postMetricKeys.INSTAGRAM.REACH],
  ],
  INSTAGRAM_DEPRECATED: [
    postMetrics.INSTAGRAM_DEPRECATED[postMetricKeys.INSTAGRAM_DEPRECATED.ENGAGEMENT_RATE],
    postMetrics.INSTAGRAM_DEPRECATED[postMetricKeys.INSTAGRAM_DEPRECATED.EFFECTIVENESS],
    postMetrics.INSTAGRAM_DEPRECATED[postMetricKeys.INSTAGRAM_DEPRECATED.TOTAL_ENGAGEMENTS],
    postMetrics.INSTAGRAM_DEPRECATED[postMetricKeys.INSTAGRAM_DEPRECATED.REACH],
  ],
  UGC: [
    postMetrics.UGC[postMetricKeys.UGC.ESTIMATED_REACH],
    postMetrics.UGC[postMetricKeys.UGC.ENGAGEMENT_RATE],
    postMetrics.UGC[postMetricKeys.UGC.ENGAGEMENTS],
    postMetrics.UGC[postMetricKeys.UGC.LIKES],
  ],
  UGC_STORY: [
    postMetrics.UGC[postMetricKeys.UGC.ESTIMATED_REACH],
    postMetrics.UGC[postMetricKeys.UGC.IMPRESSIONS],
    postMetrics.UGC[postMetricKeys.UGC.COMPLETION_RATE],
    postMetrics.UGC[postMetricKeys.UGC.EXIT_RATE],
  ],
  RELATIONSHIP_INSTAGRAM: [
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.ENGAGEMENT_RATE],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.EFFECTIVENESS],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.FOLLOWERS_GAINED],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.EMV],
  ],
  RELATIONSHIP_STORY: [
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.REACH],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.IMPRESSIONS],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.COMPLETION_RATE],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.EXIT_RATE],
  ],
  RELATIONSHIPS: [
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.ENGAGEMENT_RATE],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.EFFECTIVENESS],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.FOLLOWERS_GAINED],
    postMetrics.RELATIONSHIPS[postMetricKeys.RELATIONSHIPS.EMV],
  ],
};

export const verticalMediaPanelCopy = {
  campaignsTopPerformingVideos: {
    title: 'Top Performing Videos - Owned',
    tooltip:
      'The top performing videos in this Campaign across all owned channels, sorted by video views',
    emptyMessage: 'No videos found during this reporting period',
  },
  campaignsLowestPerformingVideos: {
    title: 'Lowest Performing Videos - Owned',
    tooltip:
      'The lowest performing videos in this Campaign across all owned channels, sorted by video views ',
    emptyMessage: 'No videos found during this reporting period',
  },
  campaignsTopPerformingPosts: {
    title: 'Top Performing Posts - Owned',
    tooltip: 'The top performing posts in this Campaign across all owned channels',
    emptyMessage: 'No posts found during this reporting period',
  },
  campaignsLowestPerformingPosts: {
    title: 'Lowest Performing Posts - Owned',
    tooltip: 'The lowest performing posts in this Campaign across all owned channels',
    emptyMessage: 'No posts found during this reporting period',
  },
};

export const videoViewsAliasMap = {
  INSTAGRAM: 'video_views',
  TIKTOK: 'views',
  FACEBOOK: 'video_views',
  PINTEREST: 'total_video_views',
  TWITTER: 'video_views',
  INSTAGRAM_STORY: 'views',
  YOUTUBE: 'views',
};

export const impressionsAliasMap = {
  INSTAGRAM: 'total_impressions',
  TIKTOK: 'views',
  FACEBOOK: 'impressions',
  PINTEREST: 'total_impressions',
  TWITTER: 'impressions_organic',
  INSTAGRAM_STORY: 'views',
};

export const engagementRateAliasMap = {
  INSTAGRAM: 'engagement',
  TIKTOK: 'engagement_rate',
  FACEBOOK: 'engagement_rate',
  PINTEREST: 'engagement_rate',
  TWITTER: 'engagement_rate_organic',
};

export const videoSortOptions = {
  CROSS_CHANNEL_VIDEO_VIEWS: 'CROSS_CHANNEL_VIDEO_VIEWS',
};

export const CHANNELS = Object.freeze({
  TIKTOK: 'TIKTOK',
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  FACEBOOK: 'FACEBOOK',
  PINTEREST: 'PINTEREST',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
  RELATIONSHIPS: 'RELATIONSHIPS',
  UGC: 'UGC',
});

export const DATA_SOURCES = Object.freeze({
  TIKTOK_LIKESHOP: 'TIKTOK_LIKESHOP',
  LIKESHOP: 'LIKESHOP',
  INSTAGRAM_CREATORS: 'INSTAGRAM_CREATORS',
  TIKTOK_CREATORS: 'TIKTOK_CREATORS',
  ...CHANNELS,
});

export const CHANNEL_LINK_CLICK_TO_TEXT = Object.freeze({
  [CHANNELS.FACEBOOK]: 'Facebook Link Clicks',
  [CHANNELS.PINTEREST]: 'Pinterest Total Clicks',
  [CHANNELS.LINKESHOP]: 'Instagram LikeShop',
  [CHANNELS.INSTAGRAM_STORY]: 'Instagram Stories Link Clicks',
  [CHANNELS.TIKTOK_LIKESHOP]: 'TikTok LikeShop',
});

export const overviewChannelStatsFormatLegacy = {
  number_of_media: {
    label: 'Number of Posts',
    tooltip: tooltips.topStatNumberOfPosts,
    channelsToOmit: [DATA_SOURCES.LIKESHOP, DATA_SOURCES.TIKTOK_LIKESHOP],
  },
  video_views: {
    label: 'Video Views',
    tooltip: tooltips.topStatVideoViews,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.INSTAGRAM_STORY,
      CHANNELS.UGC,
    ],
  },
  video_views_v2: {
    label: 'Video Views',
    tooltip: tooltips.topStatVideoViewsV2,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.INSTAGRAM_STORY,
      CHANNELS.UGC,
    ],
  },
  engagements: {
    label: 'Engagements',
    tooltip: tooltips.topStatTotalEngagements,
    channelsToOmit: [DATA_SOURCES.LIKESHOP, DATA_SOURCES.TIKTOK_LIKESHOP, CHANNELS.INSTAGRAM_STORY],
  },
  avg_engagement_rate: {
    label: 'Avg. Engagement Rate',
    tooltip: tooltips.topStatAvgEngagementRate,
    percent: true,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.INSTAGRAM_STORY,
      CHANNELS.YOUTUBE,
    ],
  },
  impressions: {
    label: 'Impressions',
    tooltip: tooltips.topStatImpressions,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.TIKTOK,
      CHANNELS.YOUTUBE,
      CHANNELS.RELATIONSHIPS,
      CHANNELS.UGC,
    ],
  },
  impressions_v2: {
    label: 'Impressions',
    tooltip: tooltips.topStatImpressionsV2,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.TIKTOK,
      CHANNELS.YOUTUBE,
      CHANNELS.RELATIONSHIPS,
      CHANNELS.UGC,
    ],
  },
  link_clicks: {
    label: 'Clicks',
    tooltip: tooltips.topStatClicks,
    channelsToOmit: [
      CHANNELS.TIKTOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.YOUTUBE,
      CHANNELS.RELATIONSHIPS,
      CHANNELS.UGC,
      DATA_SOURCES.INSTAGRAM_CREATORS,
      DATA_SOURCES.TIKTOK_CREATORS,
    ],
  },
};

export const overviewChannelStatsFormatDeprecated = {
  number_of_media: {
    ...overviewChannelStatsFormatLegacy.number_of_media,
    format: 'number',
  },
  video_views: {
    ...overviewChannelStatsFormatLegacy.video_views,
    format: 'number',
  },
  engagements: {
    ...overviewChannelStatsFormatLegacy.engagements,
    label: 'Engagements',
    format: 'number',
  },
  avg_engagement_rate: {
    ...overviewChannelStatsFormatLegacy.avg_engagement_rate,
    format: 'percent',
    formatOptions: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  impressions: {
    ...overviewChannelStatsFormatLegacy.impressions,
    format: 'number',
  },
  link_clicks: {
    ...overviewChannelStatsFormatLegacy.link_clicks,
    format: 'number',
  },
};

export const overviewChannelStatsFormat = {
  number_of_media: {
    ...overviewChannelStatsFormatLegacy.number_of_media,
    format: 'number',
  },
  video_views_v2: {
    ...overviewChannelStatsFormatLegacy.video_views_v2,
    format: 'number',
  },
  engagements: {
    ...overviewChannelStatsFormatLegacy.engagements,
    label: 'Engagements',
    format: 'number',
  },
  avg_engagement_rate: {
    ...overviewChannelStatsFormatLegacy.avg_engagement_rate,
    format: 'percent',
    formatOptions: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  impressions_v2: {
    ...overviewChannelStatsFormatLegacy.impressions_v2,
    format: 'number',
  },
  link_clicks: {
    ...overviewChannelStatsFormatLegacy.link_clicks,
    format: 'number',
  },
};

export const graphMetrics = {
  POSTS: {
    value: 'NUMBER_OF_MEDIA',
    text: 'Posts',
  },
  NUMBER_OF_MEDIA: {
    value: 'NUMBER_OF_MEDIA',
    text: 'Posts',
  },
  NET_NEW_FOLLOWERS: {
    value: 'NET_NEW_FOLLOWERS',
    text: 'Net New Followers',
  },
  VIDEO_VIEWS: {
    value: 'VIDEO_VIEWS',
    text: 'Video Views',
  },
  TOTAL_VIDEO_VIEWS: {
    value: 'TOTAL_VIDEO_VIEWS',
    text: 'Video Views',
  },
  VIEWS_ONLY_VIDEOS: {
    value: 'VIEWS_ONLY_VIDEOS',
    text: 'Video Views',
  },
  IMPRESSIONS: {
    value: 'IMPRESSIONS',
    text: 'Impressions',
  },
  LINK_CLICKS: {
    value: 'LINK_CLICKS',
    text: 'Link Clicks',
  },
  SUBSCRIBERS_GAINED: {
    value: 'SUBSCRIBERS_GAINED',
    text: 'Subscribers Gained',
  },
  SUBSCRIBERS_LOST: {
    value: 'SUBSCRIBERS_LOST',
    text: 'Subscribers Lost',
  },
};

export const postsDefaultSortOption = 'CROSS_CHANNEL_ENGAGEMENT_RATE';

export const campaignRouteNames = {
  CAMPAIGNS: 'campaigns',
  CAMPAIGNS_CREATE: 'campaigns.create',
  CAMPAIGN_DETAIL: 'campaigns.detail',
  CAMPAIGN_DETAIL_EDIT: 'campaigns.detail.edit',
  CAMPAIGN_DETAIL_DELETE: 'campaigns.detail.delete',
  CAMPAIGN_DETAIL_OVERVIEW: 'campaigns.detail.overview',
  CAMPAIGN_DETAIL_TIKTOK: 'campaigns.detail.tiktok',
  CAMPAIGN_DETAIL_INSTAGRAM: 'campaigns.detail.instagram',
  CAMPAIGN_DETAIL_INSTAGRAM_STORIES: 'campaigns.detail.instagram-stories',
  CAMPAIGN_DETAIL_FACEBOOK: 'campaigns.detail.facebook',
  CAMPAIGN_DETAIL_PINTEREST: 'campaigns.detail.pinterest',
  CAMPAIGN_DETAIL_TWITTER: 'campaigns.detail.twitter',
  CAMPAIGN_DETAIL_YOUTUBE: 'campaigns.detail.youtube',
  CAMPAIGN_DETAIL_ECOMM: 'campaigns.detail.ecomm',
  CAMPAIGN_DETAIL_ECOMM_REPORT: 'campaigns.detail.ecomm.report',
  CAMPAIGN_DETAIL_RELATIONSHIPS: 'campaigns.detail.relationships',
  CAMPAIGN_DETAIL_UGC: 'campaigns.detail.ugc',
  CAMPAIGN_DETAIL_CREATOR_INSIGHTS: 'campaigns.detail.creator-insights',
  CAMPAIGN_DETAIL_CREATOR_DELIVERABLES: 'campaigns.detail.creator-deliverables',
  CAMPAIGN_DETAIL_REPORT: 'campaigns.detail.report',
  CAMPAIGN_DETAIL_OVERVIEW_REPORT: 'campaigns.detail.overview.report',
  CAMPAIGN_DETAIL_TIKTOK_REPORT: 'campaigns.detail.tiktok.report',
  CAMPAIGN_DETAIL_INSTAGRAM_REPORT: 'campaigns.detail.instagram.report',
  CAMPAIGN_DETAIL_INSTAGRAM_STORIES_REPORT: 'campaigns.detail.instagram-stories.report',
  CAMPAIGN_DETAIL_FACEBOOK_REPORT: 'campaigns.detail.facebook.report',
  CAMPAIGN_DETAIL_PINTEREST_REPORT: 'campaigns.detail.pinterest.report',
  CAMPAIGN_DETAIL_TWITTER_REPORT: 'campaigns.detail.twitter.report',
  CAMPAIGN_DETAIL_YOUTUBE_REPORT: 'campaigns.detail.youtube.report',
  CAMPAIGN_DETAIL_RELATIONSHIPS_REPORT: 'campaigns.detail.relationships.report',
  CAMPAIGN_DETAIL_UGC_REPORT: 'campaigns.detail.ugc.report',
  CAMPAIGN_DETAIL_CREATOR_INSIGHTS_REPORT: 'campaigns.detail.creator-insights.report',
  CAMPAIGN_DETAIL_CREATOR_DELIVERABLES_REPORT: 'campaigns.detail.creator-deliverables.report',
  CAMPAIGN_DETAIL_ALL_TABS_REPORT: 'campaigns.detail.all.report',
  COMPARISONS: 'comparisons',
  CAMPAIGNS_COMPARISONS: 'campaigns.comparisons',
  CAMPAIGNS_COMPARISONS_CREATE: 'campaigns.comparisons.create',
  CAMPAIGNS_COMPARISON_DETAIL: 'campaigns.comparison.detail',
};

export const campaignScaleOptions = [
  { label: 'By Day', value: timeScaleTypes.DAILY },
  { label: 'By Month', value: timeScaleTypes.MONTHLY },
];

export const mixpanelEventProperties = Object.freeze({
  reportTypes: {
    keyword: 'Keyword',
    sentiment: 'Sentiment',
    channel: 'Channel',
  },
  interactionType: {
    labelClicked: 'Label Clicked',
    keywordClicked: 'Keyword Clicked',
    keywordCheckboxUpdated: 'Keyword Checkbox Updated',
  },
  metricTypes: {
    sentiment: 'Campaign sentiment',
  },
});

export const NO_DATA_TOP_KEYWORDS_ERROR_MESSAGE =
  'No top keywords found during this reporting period';

export const CAMPAIGN_TOP_KEYWORDS_TOOLTIP =
  'The top keywords used in the comments on owned posts in this Campaign';

export const CAMPAIGN_SENTIMENT_CHANNEL_DISTRIBUTION_TOOLTIP =
  'The channel and sentiment breakdown of comments on owned posts in this Campaign';

export const CAMPAIGN_SENTIMENT_BAR_CHART_CONFIG = {
  positive: {
    label: 'Positive',
    backgroundColor: colours.SUCCESS.SUCCESS_500,
    order: 1,
  },
  neutral: {
    label: 'Neutral',
    backgroundColor: colours.ACTION.ACTION_500,
    order: 2,
  },
  negative: {
    label: 'Negative',
    backgroundColor: colours.ERROR.ERROR_500,
    order: 3,
  },
};

export const CAMPAIGN_SENTIMENT_CHANNEL_DISTRIBUTION_PLATFORMS = [
  CHANNELS.TIKTOK,
  CHANNELS.INSTAGRAM,
  CHANNELS.FACEBOOK,
  CHANNELS.TWITTER,
  CHANNELS.YOUTUBE,
];

export const FAILED_TO_LOAD_CAMPAIGN_SENTIMENT_ERROR_MESSAGE =
  'Data failed to load. Refresh the page to try again';

export const CAMPAIGN_SENTIMENT_OVERVIEW_NO_DATA_MESSAGE =
  'No data found during this reporting period';

export const CAMPAIGN_PLATFORM_LABELS = {
  [CHANNELS.TIKTOK]: 'TikTok',
  [CHANNELS.INSTAGRAM]: 'Instagram',
  [CHANNELS.FACEBOOK]: 'Facebook',
  [CHANNELS.TWITTER]: 'X',
  [CHANNELS.YOUTUBE]: 'YouTube',
  [CHANNELS.PINTEREST]: 'Pinterest',
  [CHANNELS.INSTAGRAM_STORY]: 'Instagram Story',
  [CHANNELS.RELATIONSHIPS]: 'Relationships',
  [CHANNELS.UGC]: 'UGC',
  [DATA_SOURCES.INSTAGRAM_CREATORS]: 'Instagram Creators',
  [DATA_SOURCES.TIKTOK_CREATORS]: 'TikTok Creators',
};

export const DOTTED_STATS_UP_TO = 1;

export const campaignCreatorStatusOptions = [
  { label: 'New', value: 'NEW', severity: 'statusNeutral' },
  { label: 'Contacted', value: 'CONTACTED', severity: 'statusNeutral' },
  { label: 'Agreement Sent', value: 'AGREEMENT_SENT', severity: 'info' },
  { label: 'Brief Sent', value: 'BRIEF_SENT', severity: 'info' },
  { label: 'Product Shipped', value: 'PRODUCT_SHIPPED', severity: 'info' },
  { label: 'In Progress', value: 'IN_PROGRESS', severity: 'info' },
  { label: 'Payment Initiated', value: 'PAYMENT_INITIATED', severity: 'info' },
  { label: 'Paid', value: 'PAID', severity: 'info' },
  { label: 'Creative Approved', value: 'CREATIVE_APPROVED', severity: 'success' },
  { label: 'Complete', value: 'COMPLETE', severity: 'success' },
  { label: 'Declined', value: 'DECLINED', severity: 'negative' },
];

export const limitedCampaignCreatorStatusOptions = [
  { label: 'New', value: 'NEW', severity: 'statusNeutral' },
  { label: 'Contacted', value: 'CONTACTED', severity: 'statusNeutral' },
  { label: 'Product Shipped', value: 'PRODUCT_SHIPPED', severity: 'info' },
  { label: 'Complete', value: 'COMPLETE', severity: 'success' },
  { label: 'Declined', value: 'DECLINED', severity: 'negative' },
];

export const campaignCreatorPostTypeOptions = [
  { label: 'TikTok Videos', value: 'TIKTOK_VIDEO', icon: 'tiktok-color' },
  { label: 'Instagram Feed', value: 'INSTAGRAM_FEED', icon: 'instagram-color' },
  { label: 'Instagram Reels', value: 'INSTAGRAM_REELS', icon: 'instagram-reel-color' },
  { label: 'Instagram Stories', value: 'INSTAGRAM_STORIES', icon: 'instagram-story-color' },
];

export const campaignCreatorChannelOptions = [
  { label: 'TikTok', value: 'TIKTOK', icon: 'tiktok-color' },
  { label: 'Instagram', value: 'INSTAGRAM', icon: 'instagram-color' },
];

export const campaignCreatorDeliverableFilterDetails = {
  scope: 'creatorDeliverables',
  options: {
    channel: campaignCreatorChannelOptions,
    postType: campaignCreatorPostTypeOptions,
    status: campaignCreatorStatusOptions,
  },
  default: {
    channel: [],
    postType: [],
    tags: [],
    status: [],
  },
};

export const campaignCreatorDeliverableTopLineStats = [
  {
    title: 'Creators',
    value: 'totalCreators',
    format: 'number',
    type: 'Total',
    tooltip: 'The total number of creators added to this Campaign, including gifted and paid',
  },
  {
    title: 'Deliverables',
    value: 'totalDeliverables',
    format: 'number',
    type: 'Total',
    tooltip: 'The total number of expected creator posts as part of this Campaign',
  },
  {
    title: 'Gifted',
    value: 'totalGifted',
    format: 'number',
    type: 'Total',
    tooltip:
      'The total number of unique creators that were gifted product as part of this Campaign',
  },
  {
    title: 'Posts',
    value: 'totalPosts',
    format: 'number',
    type: 'Total',
    tooltip: 'The total number of published posts by creators as part of this Campaign',
  },
  {
    title: 'Completion',
    value: 'completion',
    format: 'percent',
    type: 'Average',
    tooltip:
      'The percentage of creators who have fulfilled their deliverables or have been marked as complete',
  },
];

export const campaignCreatorDeliverableBannerValues = {
  SINGLE_CREATOR_NO_ACCESS_INSTAGRAM: {
    message:
      'To assign deliverables for Instagram to this creator, please add this handle to their Creator Profile before proceeding',
    severity: 'warn',
  },
  SINGLE_CREATOR_NO_ACCESS_TIKTOK: {
    message:
      'To assign deliverables for TikTok to this creator, please add this handle to their Creator Profile before proceeding',
    severity: 'warn',
  },
  MULTIPLE_CREATORS_NO_BOTH_CHANNELS: {
    message:
      'Creator profiles without both Instagram and TikTok handles will only be assigned deliverables to the added handle. To assign deliverables for both channels, ensure all handles are added to profiles before proceeding.',
    severity: 'info',
  },
};

export const campaignCreatorInsightsTopLineStats = [
  {
    title: 'Posts',
    value: 'totalPosts',
    format: 'abbr',
    breakdownFormat: 'number',
    tooltip: 'The number of posts in this Campaign by all added creators',
  },
  {
    title: 'EMV',
    value: 'emv',
    format: 'currency',
    breakdownFormat: 'currency',
    breakdownFormatOptions: { notation: 'standard' },
    tooltip:
      'The earned media value of all posts in this Campaign.' +
      '\n\n' +
      'EMV is calculated by factoring in the channel, audience size, content type, engagement and current market values for comparable posts.',
  },
  {
    title: 'Avg. EMV',
    value: 'avgEmv',
    format: 'currency',
    breakdownFormat: 'currency',
    breakdownFormatOptions: { notation: 'standard' },
    tooltip:
      'The average earned media value per post in this Campaign.' +
      '\n\n' +
      'EMV is calculated by factoring in the channel, audience size, content type, engagement and current market values for comparable posts.',
  },
  {
    title: 'Likes',
    value: 'likes',
    format: 'abbr',
    breakdownFormat: 'number',
    tooltip: 'The number of likes across all applicable creator posts in this Campaign',
  },
  {
    title: 'Comments',
    value: 'comments',
    format: 'abbr',
    breakdownFormat: 'number',
    tooltip: 'The number of comments on all applicable creator posts in this Campaign',
  },
  {
    title: 'Avg. Engagements',
    value: 'avgEngagements',
    format: 'abbr',
    breakdownFormat: 'number',
    tooltip:
      'The average engagements per creator post in this Campaign' +
      '\n\n' +
      'Instagram Engagements = Likes + Comments\n\n' +
      'TikTok Engagements = Likes + Comments + Shares',
  },
  {
    title: 'Avg. Eng. Rate',
    value: 'avgEngagementRate',
    format: 'percent',
    breakdownFormat: 'percent',
    tooltip:
      'The average engagement rate per creator post in this Campaign' +
      '\n\n' +
      'Instagram Engagement Rate = (Likes + Comments) / Followers\n\n' +
      'TikTok Engagement Rate = (Likes + Comments + Shares) / Video Views',
  },
  {
    title: 'Creator Followers',
    value: 'audienceSize',
    format: 'abbr',
    breakdownFormat: 'number',
    tooltip:
      'The follower count of creators added to this Campaign across TikTok and Instagram. Use this metric to gauge the potential reach of your Campaign.',
  },
];

export const campaignCreatorInsightsInstagramChannelStats = [
  {
    label: 'Creators',
    value: 'totalCreators',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The number of creators in this Campaign with a Instagram handle added to their profile',
  },
  {
    label: 'Creator Followers',
    value: 'audienceSize',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The follower count of creators added to this Campaign for Instagram. Use this metric to gauge the potential reach of your Campaign.',
  },
  {
    label: 'EMV',
    value: 'emv',
    formatType: 'formatCurrency',
    tooltip:
      'The total earned media value for all Instagram posts in this Campaign.' +
      '\n\n' +
      'EMV is calculated by factoring in the channel, audience size, content type, engagement and current market values for comparable posts.',
  },
  {
    label: 'Avg. EMV',
    value: 'avgEmv',
    formatType: 'formatCurrency',
    tooltip:
      'The average earned media value per Instagram post in this Campaign.' +
      '\n\n' +
      'EMV is calculated by factoring in the channel, audience size, content type, engagement and current market values for comparable posts.',
  },
  {
    label: 'Engagements',
    value: 'engagements',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The total engagements across all creator Instagram posts in this Campaign\n' +
      '\n' +
      'Instagram Engagements = Likes + Comments',
  },
  {
    label: 'Avg. Engagements',
    value: 'avgEngagements',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The average engagements per creator Instagram post in this Campaign\n' +
      '\n' +
      'Instagram Engagements = Likes + Comments',
  },
  {
    label: 'Avg. Engagement Rate',
    value: 'avgEngagementRate',
    formatType: 'formatPercentage',
    formatOptions: { maximumFractionDigits: 2 },
    tooltip:
      'The average engagement rate per creator Instagram post in this Campaign\n' +
      '\n' +
      'Instagram Engagement Rate = (Likes + Comments) / Followers',
  },
  {
    label: 'Impressions',
    value: 'impressions',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      "The number of times your creators' videos in this Campaign were played or replayed for 1 ms or more. Replays are counted after the initial play in the same video session.",
  },
  {
    label: 'Avg. Impressions',
    value: 'avgImpressions',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The average number of times per Creator in this Campaign that their videos were played or replayed',
  },
  {
    label: 'Views',
    value: 'views',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      "The number of times your creators' posts in this Campaign were viewed for 1 ms or more",
  },
  {
    label: 'Avg. Views',
    value: 'avgViews',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The average number of times per Creator in this Campaign that their posts have been viewed',
  },
  {
    label: 'Avg. Est. Reach',
    value: 'avgReach',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The average reach per creator Instagram post in this Campaign. For accounts that are not connected, reach is estimated.',
  },
];

export const campaignCreatorInsightsInstagramChannelStatsExpanded = [
  {
    label: 'Avg. Effectiveness',
    value: 'avgEffectiveness',
    formatType: 'formatPercentage',
    formatOptions: { maximumFractionDigits: 2 },
    tooltip:
      'The average Effectiveness per creator post in this Campaign. For accounts that are not connected, reach is estimated.\n' +
      '\n' +
      'Effectiveness = (Likes + Comments) / Reach',
  },
  {
    label: 'Followers Gained',
    value: 'followersGained',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The estimated number of followers that creators in this Campaign have gained for you by tagging or mentioning your account in their posts',
  },
  {
    label: 'Stories - Avg. Completion Rate',
    value: 'storyAvgCompletionRate',
    formatType: 'formatPercentage',
    formatOptions: { maximumFractionDigits: 2 },
    tooltip:
      "The average completion rate of creators' Stories in this Campaign\n" +
      '\n' +
      'Completion Rate = (Impression - Exits) / Impressions',
  },
  {
    label: 'Stories - Avg. Reach',
    formatType: 'formatIntegerAbbreviated',
    value: 'storyAvgReach',
    tooltip: 'The average number of unique accounts reached per story by creators in this Campaign',
  },
  {
    label: 'Stories - Avg. Impressions',
    formatType: 'formatIntegerAbbreviated',
    value: 'storyAvgImpressions',
    tooltip: 'The average number of impressions per creator Instagram Story in this Campaign',
  },
];

export const campaignCreatorInsightsTikTokChannelStats = [
  {
    label: 'Creators',
    value: 'totalCreators',
    formatType: 'formatIntegerAbbreviated',
    tooltip: 'The number of creators in this Campaign with a TikTok handle added to their profile',
  },
  {
    label: 'Creator Followers',
    value: 'audienceSize',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The follower count of creators added to this Campaign for TikTok. Use this metric to gauge the potential reach of your campaign.',
  },
  {
    label: 'EMV',
    value: 'emv',
    formatType: 'formatCurrency',
    tooltip:
      'The earned media value of all TikTok posts in this Campaign.' +
      '\n\n' +
      'EMV is calculated by factoring in the channel, audience size, content type, engagement and current market values for comparable posts.',
  },
  {
    label: 'Avg. EMV',
    value: 'avgEmv',
    formatType: 'formatCurrency',
    tooltip:
      'The average earned media value per TikTok post in this Campaign.' +
      '\n\n' +
      'EMV is calculated by factoring in the channel, audience size, content type, engagement and current market values for comparable posts.',
  },
  {
    label: 'Engagements',
    value: 'engagements',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The total engagements across all creator TikTok posts in this Campaign\n' +
      '\n' +
      'TikTok Engagements = Likes + Comments + Shares',
  },
  {
    label: 'Avg. Engagements',
    value: 'avgEngagements',
    formatType: 'formatIntegerAbbreviated',
    tooltip:
      'The average engagements per creator TikTok post in this Campaign\n' +
      '\n' +
      'TikTok Engagements = Likes + Comments + Shares',
  },
  {
    label: 'Avg. Engagement Rate',
    value: 'avgEngagementRate',
    formatType: 'formatPercentage',
    formatOptions: { maximumFractionDigits: 2 },
    tooltip:
      'The average engagement rate per creator TikTok post in this Campaign\n' +
      '\n' +
      'TikTok Engagement Rate = (Likes + Comments + Shares) / Video Views',
  },
  {
    label: 'Video Views',
    value: 'videoViews',
    formatType: 'formatIntegerAbbreviated',
    tooltip: 'The number of video views across all creator TikTok posts in this Campaign',
  },
  {
    label: 'Avg. Video Views',
    value: 'avgVideoViews',
    formatType: 'formatIntegerAbbreviated',
    tooltip: 'The average number of video views per creator TikTok post in this Campaign',
  },
  {
    label: 'Shares',
    value: 'shares',
    formatType: 'formatIntegerAbbreviated',
    tooltip: 'The total shares across all creator TikTok posts in this Campaign',
  },
];

export const creatorInsightPostSortOptions = {
  DEFAULT: {
    sortOrder: 'DESC',
    selected: { value: 'DATE', label: 'Recently Published' },
  },
  ALL_CHANNELS: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'CROSS_CHANNEL_EMV',
      label: 'EMV',
    },
  ],
  TIKTOK: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'TIKTOK_TOTAL_ENGAGEMENTS',
      label: 'Engagements',
    },
    {
      value: 'TIKTOK_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
    },
    {
      value: 'CROSS_CHANNEL_EMV',
      label: 'EMV',
    },
    {
      value: 'TIKTOK_REACH',
      label: 'Reach',
    },
    {
      value: 'TIKTOK_SHARES',
      label: 'Shares',
    },
    {
      value: 'TIKTOK_VIEWS',
      label: 'Video Views',
    },
  ],
  INSTAGRAM: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'CROSS_CHANNEL_EMV',
      label: 'EMV',
    },
    {
      value: 'CROSS_CHANNEL_IMPRESSIONS',
      label: 'Impressions',
    },
  ],
  INSTAGRAM_FEED: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'INSTAGRAM_EFFECTIVENESS',
      label: 'Effectiveness',
    },
    {
      value: 'INSTAGRAM_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
    },
    {
      value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
      label: 'Engagements',
    },
    {
      value: 'INSTAGRAM_REACH',
      label: 'Reach',
    },
    {
      value: 'INSTAGRAM_EMV',
      label: 'EMV',
    },
    {
      value: 'INSTAGRAM_FOLLOWERS_GAINED',
      label: 'Followers Gained',
    },
    {
      value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
      label: 'Impressions',
    },
  ],
  INSTAGRAM_STORY: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
      label: 'Completion Rate',
    },
    {
      value: 'EMV',
      label: 'EMV',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
      label: 'Exit Rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
      label: 'Impressions',
    },
  ],
};

export const CREATOR_AVATAR_TOOLTIP = 'Creator deliverables date range:';

export const ADD_CREATORS_WIZARD_STEP_TWO_TEXTS = Object.freeze({
  NO_CREATORS: {
    PANEL_TITLE: 'Select Date Range',
    MESSAGE_HEADER: 'Select your creator Campaign dates',
    MESSAGE_BODY:
      'Choose a date range to automatically import content from your selected creators. Only posts mentioning your brand and published within this period will be added to this Campaign.',
  },
  HAS_CREATORS: {
    PANEL_TITLE: 'Review Date Range',
    MESSAGE_HEADER: 'Dates applied to Campaign. Add Creators to proceed.',
    MESSAGE_BODY:
      'Only posts mentioning your brand and published within this period will be added to this Campaign. Changes to this date range will affect all creator posts in this Campaign.',
  },
});

export const topPerformingCreatorsSortOptions = [
  { label: 'EMV', value: 'TOTAL_EMV' },
  { label: 'Likes', value: 'LIKES' },
  { label: 'Comments', value: 'COMMENTS' },
  { label: 'Avg. Engagements', value: 'AVG_ENGAGEMENT' },
  { label: 'Avg. Engagement Rate', value: 'AVG_ENGAGEMENT_RATE' },
  { label: 'Avg. Reach', value: 'AVG_REACH' },
  { label: 'Video Views', value: 'VIDEO_VIEWS' },
  { label: 'Impressions', value: 'TOTAL_IMPRESSIONS' },
  { label: 'Avg. Impressions', value: 'AVG_IMPRESSIONS' },
  { label: 'Avg. Completion Rate', value: 'AVG_COMPLETION_RATE' },
  { label: 'Instagram Followers', value: 'INSTAGRAM_FOLLOWERS' },
  { label: 'TikTok Followers', value: 'TIKTOK_FOLLOWERS' },
];

export const POST_PERFORMANCE_POST_TYPES = {
  TIKTOK: 'TIKTOK',
  INSTAGRAM_FEED: 'FEED_POSTS',
  INSTAGRAM_STORY: 'STORY',
};

export const POST_PERFORMANCE_DEFAULT_POST_TYPES = [];

export const postTypeToMediaType = {
  [POST_PERFORMANCE_POST_TYPES.TIKTOK]: 'TIKTOK_UGC',
  [POST_PERFORMANCE_POST_TYPES.INSTAGRAM_FEED]: 'INSTAGRAM_UGC',
  [POST_PERFORMANCE_POST_TYPES.INSTAGRAM_STORY]: 'INSTAGRAM_STORY_UGC',
};

export const POST_PERFORMANCE_POST_TYPES_SELECTED = {
  TIKTOK_POST: [POST_PERFORMANCE_POST_TYPES.TIKTOK],
  INSTAGRAM_FEED: [POST_PERFORMANCE_POST_TYPES.INSTAGRAM_FEED],
  INSTAGRAM_STORY: [POST_PERFORMANCE_POST_TYPES.INSTAGRAM_STORY],
  INSTAGRAM_ALL_POST_TYPES: [
    POST_PERFORMANCE_POST_TYPES.INSTAGRAM_FEED,
    POST_PERFORMANCE_POST_TYPES.INSTAGRAM_STORY,
  ],
};

export const POST_PERFORMANCE_DEFAULT_SORT = ['-DATE'];

export const POST_PERFORMANCE_DEFAULT_MEDIA_TYPES = [
  'INSTAGRAM_UGC',
  'INSTAGRAM_STORY_UGC',
  'TIKTOK_UGC',
];

export const REPORTING_PERIOD_PRESETS = Object.freeze({
  LAST_24_HOURS: {
    value: 'LAST_24_HOURS',
    label: 'Last 24 Hours',
    range: () => getDateRangeOffsetFromToday(1),
  },
  LAST_3_DAYS: {
    value: 'LAST_3_DAYS',
    label: 'Last 3 Days',
    range: () => getDateRangeOffsetFromToday(3),
  },
  LAST_7_DAYS: {
    value: 'LAST_7_DAYS',
    label: 'Last 7 Days',
    range: () => getDateRangeOffsetFromToday(7),
  },
  LAST_4_WEEKS: {
    value: 'LAST_4_WEEKS',
    label: 'Last 4 Weeks',
    range: () => getDateRangeOffsetFromToday(28),
  },
});

export const CAMPAIGN_DETAIL_FILTER_SCOPE = 'campaignDetail';

export const CAMPAIGN_SWITCH_SCALE_THRESHOLD_DAYS = 60;

export const SOCIAL_COMMERCE_TOOLTIPS = Object.freeze({
  USERS: 'The number of unique users who have logged at least one event',
  SESSIONS: 'The number of website or app sessions initiated',
  CONVERSION_RATE: 'The percentage of sessions triggering a specific purchase conversion event',
  TRANSACTIONS: 'The number of transaction events with purchase revenue',
  REVENUE:
    'The sum of revenue during the reporting period from purchases, subscriptions and advertising, minus refunded transaction revenue',
});

export const SOCIAL_COMMERCE_TABLE_SORT_OPTIONS = Object.freeze([
  { label: 'Source / Medium', value: 'sourceMedium' },
  { label: 'Users', value: 'totalUsers' },
  { label: 'Sessions', value: 'sessions' },
  { label: 'Conversion Rate', value: 'ecommerceConversionRate' },
  { label: 'Transactions', value: 'transactions' },
  { label: 'Revenue', value: 'revenue' },
]);

export const CAMPAIGN_RELATIONSHIP_METRICS = Object.freeze([
  {
    title: 'Posts',
    valueKey: 'number_of_media',
    tooltip: 'The number of posts that mentioned or tagged you',
    format: 'number',
    postType: 'ORGANIC',
    metricTimeFrame: 'POSTS_THIS_PERIOD',
  },
  {
    title: 'Followers Gained',
    valueKey: 'followers_gained',
    tooltip: 'The estimated number of followers gained for you',
    format: 'number',
    postType: 'ORGANIC',
    metricTimeFrame: 'POSTS_THIS_PERIOD',
  },
  {
    title: 'Avg. Engagement Rate',
    valueKey: 'avg_engagement_rate',
    tooltip:
      "The average percentage of the poster's audience that engaged\n\nEngagement Rate = (Likes + Comments) / Followers",
    format: 'percent',
    formatOptions: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    postType: 'ORGANIC',
    metricTimeFrame: 'POSTS_THIS_PERIOD',
  },
  {
    title: 'Avg. Effectiveness',
    valueKey: 'avg_effectiveness_rate',
    tooltip:
      'The percentage of unique accounts who saw these posts and engaged with it\n\nEffectiveness = Likes + Comments / Est. Reach',
    format: 'percent',
    formatOptions: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    postType: 'ORGANIC',
    metricTimeFrame: 'POSTS_THIS_PERIOD',
  },
  {
    title: 'Engagements',
    valueKey: 'engagements',
    tooltip: 'The total number of engagements received\n\nEngagements = Likes + Comments',
    format: 'number',
    postType: 'ORGANIC',
    metricTimeFrame: 'POSTS_THIS_PERIOD',
  },
  {
    title: 'Total EMV',
    valueKey: 'total_emv',
    tooltip:
      "The Earned Media Value of posts in this Campaign.\n\nThis value is based on the creators' audience size, media type, and number of engagements.",
    format: 'currency',
    postType: 'ORGANIC',
    metricTimeFrame: 'POSTS_THIS_PERIOD',
  },
]);

export const NO_DATA_FOUND = 'No posts found during this reporting period.';
export const EMPTY_CHART_DATA_MESSAGE = 'Data during the reporting period will be displayed here';
export const ERROR_CHART_DATA_MESSAGE =
  'There was an issue loading your data. Please refresh the page to try again.';
export const PLATFORM_DISCONNECTED_MESSAGE = (platform) =>
  `Your ${platform} account has been disconnected. Please reconnect to see applicable data.`;

export const MEDIA_LIST_EMPTY_STATES = Object.freeze({
  NO_MEDIA_STATE: {
    title: "Couldn't Find a Match",
    variant: 'sectionEmpty',
    icon: 'search',
    message: 'Review or adjust your filters to uncover more results',
    ctaButton: { label: 'Reset Filters' },
  },
  ERROR_STATE: {
    title: "Couldn't Load Posts",
    variant: 'sectionEmpty',
    icon: 'alert-triangle',
    message: 'There was an issue loading your posts. Please refresh the page to try again.',
  },
});

export const CAMPAIGN_EMPTY_STATE_IMAGES = {
  ADD_BOARDS: {
    BANNER_SRC: IMG.campaigns.emptyCampaignAddBoards,
    BANNER_SRC_SET: getSrcSet('campaigns/empty-campaign-add-boards'),
  },
  ADD_MEDIA: {
    BANNER_SRC: IMG.campaigns.emptyCampaignAddMedia,
    BANNER_SRC_SET: getSrcSet('campaigns/empty-campaign-add-media'),
  },
  ADD_CREATORS: {
    BANNER_SRC: IMG.campaigns.emptyCampaignAddCreators,
    BANNER_SRC_SET: getSrcSet('campaigns/empty-campaign-add-creators'),
  },
};

export const CAMPAIGN_METRICS = {
  creator: [
    {
      label: 'Avg. Engagements',
      value: 'avg_engagements',
      formatType: 'number',
    },
    {
      label: 'Avg. Engagement Rate',
      value: 'avg_engagement_rate',
      formatType: 'percent',
      formatOptions: {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    {
      label: 'Likes',
      value: 'total_creator_likes',
      formatType: 'number',
    },
    {
      label: 'EMV',
      value: 'total_emv',
      formatType: 'currency',
    },
  ],
  owned: [
    {
      label: 'Avg. Engagement Rate',
      value: 'avg_engagement_rate',
      formatType: 'percent',
      formatOptions: {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    {
      label: 'Engagements',
      value: 'total_engagements',
      formatType: 'number',
    },
    {
      label: 'Impressions',
      value: 'total_impressions',
      formatType: 'number',
    },
    {
      label: 'Video Views',
      value: 'total_video_views',
      formatType: 'number',
    },
  ],
};
