import { constants } from '@/config';
import enumTypes from '@/app/library/constants';
import { competitiveRouteNames } from '@/app/competitive/constants';
import { DETAIL_DEFAULT_SORT, METRICS, GALLERIES_DEFAULT_SORT } from '@/app/boards/metrics.enum';

export const BOARDS_DRAWERS = Object.freeze({
  CREATE_BOARD: 'createBoard',
  DETAIL: 'boardDetail',
  GALLERY_REORDER: 'galleryReorder',
});

export const TOAST_MESSAGES = Object.freeze({
  BOARD_CREATED: 'Board Created',
  BOARD_CREATED_ERROR: 'There was an error creating your board. Please try again.',
  BOARD_UPDATED: 'Board Updated',
  BOARD_UPDATED_ERROR: 'There was an error updating your board. Please try again.',
  BOARD_CREATED_DUPLICATE_NAME: (name) =>
    `A board with the name "${name}" already exists, please choose a new name for your board.`,
  MEDIA_ADDED: 'Media Added',
  MEDIA_ADDED_ERROR: 'There was an error while adding media to your board. Please try again.',
});

export const LIBRARY_GALLERY_STATE_FILTER_OPTIONS = [
  {
    label: 'Shoppable',
    value: 'shoppable',
  },
  {
    label: 'Non Shoppable',
    value: 'nonShoppable',
  },
];

export const onsiteBannerMessage =
  'On-Site Galleries create a media carousel or grid to showcase UGC or owned content on your website. Add the provided code, and Dash will automatically display the most recently added media. Note: Changes made in the Customize section won’t update on your site after embedding.';
export const urlBannerMessage =
  'A URL Gallery is a custom webpage designed to let creators showcase their favorite products in a curated gallery.';
export const emailBannerMessage =
  'The process of integrating your gallery into an email template depends on your CMS.';
export const shopLearnMoreLinkOnsite =
  'https://help.dashsocial.com/hc/en-us/articles/4411349410573-Setting-Up-Shoppable-Galleries';
export const shopLearnMoreLinkEmail =
  'https://help.dashsocial.com/hc/en-us/articles/4411349410573-Setting-Up-Shoppable-Galleries#h_01H9V4QSB8S9JKHQNBENBJXPFB';
export const widgetCustomizationGuide =
  'https://developer.dashsocial.com/docs/shoppable-galleries-doc';

export const boardsIndexDefaultFilters = Object.freeze({
  reportingPeriod: { preset: 'ALL_TIME' },
  search: null,
  sort: {
    sortOrder: 'DESC',
    selected: { label: 'Recently Added', value: 'CREATED' },
  },
  tags: [],
  tagsFilterType: 'OR',
});

export const boardsIndexQueryParamMapping = Object.freeze({
  'reportingPeriod.range.start': { queryParamName: 'startDate', type: 'date' },
  'reportingPeriod.range.end': { queryParamName: 'endDate', type: 'date' },
  search: { queryParamName: 'search', type: 'string' },
  'sort.selected.value': { queryParamName: 'sort', type: 'string' },
  'sort.sortOrder': { queryParamName: 'sortOrder', type: 'string' },
  tags: { queryParamName: 'tags', type: 'array', elementType: 'number' },
  tagsFilterType: { queryParamName: 'tagsFilterType', type: 'string' },
});

export const libraryGalleriesDefaultFilters = Object.freeze({
  search: null,
  galleryState: [],
  sort: {
    sortOrder: 'DESC',
    selected: { label: 'Recently Added', value: 'CREATED' },
  },
  tags: [],
  tagsFilterType: 'OR',
});

export const libraryGalleriesQueryParamMapping = Object.freeze({
  search: { queryParamName: 'search', type: 'string' },
  galleryState: { queryParamName: 'galleryState', type: 'array', elementType: 'string' },
  'sort.selected.value': { queryParamName: 'sort', type: 'string' },
  'sort.sortOrder': { queryParamName: 'sortOrder', type: 'string' },
  tags: { queryParamName: 'tags', type: 'array', elementType: 'number' },
  tagsFilterType: { queryParamName: 'tagsFilterType', type: 'string' },
});

const galleriesDefaultSort = Object.freeze({
  sortOrder: 'DESC',
  selected: GALLERIES_DEFAULT_SORT,
});

const boardDetailDefaultSort = Object.freeze({
  sortOrder: 'DESC',
  selected: DETAIL_DEFAULT_SORT,
});

export const GALLERY_TYPE_CONFIG = Object.freeze({
  [constants.INSTAGRAM]: {
    mediaSource: enumTypes.INSTAGRAM,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.INSTAGRAM.AVG_REACH,
        METRICS.INSTAGRAM.GALLERY_TOTAL_LIKES,
        METRICS.INSTAGRAM.GALLERY_TOTAL_COMMENTS,
        METRICS.INSTAGRAM.ENGAGEMENT,
        METRICS.INSTAGRAM.EFFECTIVENESS,
      ],
      downloadOptions: {
        csv: {
          label: 'Instagram Boards CSV',
          type: 'instagramGalleries',
        },
      },
      filters: {
        scope: 'instagramBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.INSTAGRAM.AVG_REACH,
          METRICS.INSTAGRAM.GALLERY_TOTAL_LIKES,
          METRICS.INSTAGRAM.GALLERY_TOTAL_COMMENTS,
          METRICS.INSTAGRAM.ENGAGEMENT,
          METRICS.INSTAGRAM.EFFECTIVENESS,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'instagram.galleries',
    boardDetail: {
      csvType: 'instagramBoard',
      csvSort: 'INSTAGRAM_ENGAGEMENT',
      canDownloadPdf: true,
      canDownloadUgcAltImageCsv: true,
      mediaCardMetrics: [
        METRICS.INSTAGRAM.ENGAGEMENT_RATE,
        METRICS.INSTAGRAM.EFFECTIVENESS,
        METRICS.INSTAGRAM.TOTAL_ENGAGEMENTS,
        METRICS.INSTAGRAM.REACH,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic',
          children: [
            METRICS.INSTAGRAM.ORGANIC_COMMENTS,
            METRICS.INSTAGRAM.ORGANIC_IMPRESSIONS,
            METRICS.INSTAGRAM.ORGANIC_LIKES,
            METRICS.INSTAGRAM.TOTAL_ENGAGEMENTS,
            METRICS.INSTAGRAM.REACH,
            METRICS.INSTAGRAM.SAVES,
            METRICS.INSTAGRAM.REELS_SHARES,
            METRICS.INSTAGRAM.VIDEO_VIEWS,
            METRICS.INSTAGRAM.EFFECTIVENESS,
            METRICS.INSTAGRAM.ENGAGEMENT,
            METRICS.INSTAGRAM.ENGAGEMENT_RATE,
          ],
        },
        {
          title: 'Promoted',
          children: [
            METRICS.INSTAGRAM.PAID_COMMENTS,
            METRICS.INSTAGRAM.PAID_IMPRESSIONS,
            METRICS.INSTAGRAM.PAID_TOTAL_ENGAGEMENTS,
            METRICS.INSTAGRAM.PAID_LIKES,
            METRICS.INSTAGRAM.PAID_VIDEO_VIEWS,
          ],
        },
        {
          title: 'Organic + Promoted',
          children: [
            METRICS.INSTAGRAM.TOTAL_COMMENTS,
            METRICS.INSTAGRAM.TOTAL_IMPRESSIONS,
            METRICS.INSTAGRAM.TOTAL_LIKES,
            METRICS.INSTAGRAM.SUM_TOTAL_ENGAGEMENTS,
            METRICS.INSTAGRAM.TOTAL_VIDEO_VIEWS,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.INSTAGRAM_STORY]: {
    mediaSource: enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.INSTAGRAM_STORY.AVG_REACH,
        METRICS.INSTAGRAM_STORY.AVG_IMPRESSIONS,
        METRICS.INSTAGRAM_STORY.AVG_COMPLETION_RATE,
        METRICS.INSTAGRAM_STORY.AVG_EXIT_RATE,
        METRICS.INSTAGRAM_STORY.SWIPE_UPS,
      ],
      downloadOptions: {
        csv: {
          label: 'Story Boards CSV',
          type: 'instagramStoryGalleries',
        },
        pdf: {
          label: 'Story Boards PDF',
          type: 'instagramStoryBoards',
        },
      },
      filters: {
        scope: 'instagramStoryBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.INSTAGRAM_STORY.AVG_REACH,
          METRICS.INSTAGRAM_STORY.AVG_IMPRESSIONS,
          METRICS.INSTAGRAM_STORY.AVG_COMPLETION_RATE,
          METRICS.INSTAGRAM_STORY.AVG_EXIT_RATE,
          METRICS.INSTAGRAM_STORY.SWIPE_UPS,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'instagram.stories.galleries',
    boardDetail: {
      csvType: 'instagramStoryBoardMedia',
      mixpanelBoardType: 'Story Board',
      canDownloadPdf: true,
      canDownloadUgcAltImageCsv: true,
      isStoryV2: true,
      mediaCardMetrics: [
        METRICS.INSTAGRAM_STORY.REACH,
        METRICS.INSTAGRAM_STORY.IMPRESSIONS,
        METRICS.INSTAGRAM_STORY.COMPLETION_RATE,
        METRICS.INSTAGRAM_STORY.LINK_CLICKS,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic',
          children: [
            METRICS.INSTAGRAM_STORY.COMPLETION_RATE,
            METRICS.INSTAGRAM_STORY.EXITS,
            METRICS.INSTAGRAM_STORY.EXIT_RATE,
            METRICS.INSTAGRAM_STORY.IMPRESSIONS,
            METRICS.INSTAGRAM_STORY.LINK_CLICKS,
            METRICS.INSTAGRAM_STORY.REACH,
            METRICS.INSTAGRAM_STORY.REPLIES,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.FACEBOOK]: {
    mediaSource: enumTypes.FACEBOOK,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.FACEBOOK.TOTAL_ENGAGEMENTS,
        METRICS.FACEBOOK.AVG_ENGAGEMENT_RATE,
        METRICS.FACEBOOK.AVG_EFFECTIVENESS,
        METRICS.FACEBOOK.AVG_REACH,
        METRICS.FACEBOOK.TOTAL_IMPRESSIONS,
      ],
      downloadOptions: {
        csv: {
          label: 'Facebook Boards CSV',
          type: 'facebookGalleries',
        },
      },
      filters: {
        scope: 'facebookBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.FACEBOOK.TOTAL_ENGAGEMENTS,
          METRICS.FACEBOOK.AVG_ENGAGEMENT_RATE,
          METRICS.FACEBOOK.AVG_EFFECTIVENESS,
          METRICS.FACEBOOK.AVG_REACH,
          METRICS.FACEBOOK.TOTAL_IMPRESSIONS,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'facebook.galleries',
    boardDetail: {
      csvType: 'facebookBoard',
      canDownloadPdf: false,
      canDownloadUgcAltImageCsv: false,
      mediaCardMetrics: [
        METRICS.FACEBOOK.ENGAGEMENT_RATE,
        METRICS.FACEBOOK.IMPRESSIONS,
        METRICS.FACEBOOK.TOTAL_ENGAGEMENTS,
        METRICS.FACEBOOK.REACH,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic',
          children: [
            METRICS.FACEBOOK.COMMENTS,
            METRICS.FACEBOOK.EFFECTIVENESS,
            METRICS.FACEBOOK.ENGAGEMENT_RATE,
            METRICS.FACEBOOK.TOTAL_ENGAGEMENTS,
            METRICS.FACEBOOK.IMPRESSIONS,
            METRICS.FACEBOOK.LINK_CLICKS,
            METRICS.FACEBOOK.OTHER_CLICKS,
            METRICS.FACEBOOK.PHOTO_VIEW_CLICKS,
            METRICS.FACEBOOK.POST_CLICKS,
            METRICS.FACEBOOK.REACH,
            METRICS.FACEBOOK.REACTIONS,
            METRICS.FACEBOOK.SHARES,
            METRICS.FACEBOOK.VIDEO_COMPLETE_VIEWS,
            METRICS.FACEBOOK.VIDEO_VIEWS,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.TIKTOK]: {
    mediaSource: enumTypes.TIKTOK,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.TIKTOK.TOTAL_VIDEO_VIEWS,
        METRICS.TIKTOK.AVG_VIDEO_VIEWS,
        METRICS.TIKTOK.AVG_RETENTION_RATE,
        METRICS.TIKTOK.AVG_ENGAGEMENT_RATE,
      ],
      downloadOptions: {
        csv: {
          label: 'TikTok Boards CSV',
          type: 'tiktokGalleries',
        },
      },
      filters: {
        scope: 'tikTokBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.TIKTOK.TOTAL_VIDEO_VIEWS,
          METRICS.TIKTOK.AVG_VIDEO_VIEWS,
          METRICS.TIKTOK.AVG_RETENTION_RATE,
          METRICS.TIKTOK.AVG_ENGAGEMENT_RATE,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'tiktok.galleries',
    boardDetail: {
      csvType: 'tiktokBoard',
      canDownloadPdf: false,
      canDownloadUgcAltImageCsv: false,
      mediaCardMetrics: [
        METRICS.TIKTOK.ENTERTAINMENT_SCORE,
        METRICS.TIKTOK.VIDEO_VIEWS,
        METRICS.TIKTOK.TOTAL_ENGAGEMENTS,
        METRICS.TIKTOK.AVERAGE_TIME_WATCHED,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic + Promoted',
          children: [
            METRICS.TIKTOK.AVERAGE_TIME_WATCHED,
            METRICS.TIKTOK.COMMENTS,
            METRICS.TIKTOK.FULL_VIDEO_WATCHED_RATE,
            METRICS.TIKTOK.ENGAGEMENT_RATE,
            METRICS.TIKTOK.TOTAL_ENGAGEMENTS,
            METRICS.TIKTOK.ENTERTAINMENT_SCORE,
            METRICS.TIKTOK.IMPRESSIONS_FOLLOW_RATE,
            METRICS.TIKTOK.FYP_VIEWS,
            METRICS.TIKTOK.IMPRESSIONS_HASHTAG_RATE,
            METRICS.TIKTOK.LIKES,
            METRICS.TIKTOK.IMPRESSIONS_PERSONAL_PROFILE_RATE,
            METRICS.TIKTOK.REACH,
            METRICS.TIKTOK.RETENTION_RATE,
            METRICS.TIKTOK.IMPRESSIONS_SEARCH_RATE,
            METRICS.TIKTOK.SHARES,
            METRICS.TIKTOK.IMPRESSIONS_SOUND_RATE,
            METRICS.TIKTOK.TOTAL_TIME_WATCHED,
            METRICS.TIKTOK.DURATION,
            METRICS.TIKTOK.VIDEO_VIEWS,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.PINTEREST]: {
    mediaSource: enumTypes.PINTEREST,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.PINTEREST.TOTAL_ENGAGEMENT_RATE,
        METRICS.PINTEREST.TOTAL_CLICKS,
        METRICS.PINTEREST.TOTAL_SAVES,
        METRICS.PINTEREST.TOTAL_IMPRESSIONS,
        METRICS.PINTEREST.TOTAL_PIN_CLICKS,
      ],
      downloadOptions: {
        csv: {
          label: 'Pinterest Boards CSV',
          type: 'pinterestGalleries',
        },
        pdf: {
          label: 'Pinterest Boards PDF',
          type: 'DHBoards',
        },
      },
      filters: {
        scope: 'pinterestBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.PINTEREST.TOTAL_ENGAGEMENT_RATE,
          METRICS.PINTEREST.TOTAL_CLICKS,
          METRICS.PINTEREST.TOTAL_SAVES,
          METRICS.PINTEREST.TOTAL_IMPRESSIONS,
          METRICS.PINTEREST.TOTAL_PIN_CLICKS,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'pinterest.galleries',
    boardDetail: {
      csvType: 'pinterestBoard',
      canDownloadPdf: true,
      canDownloadUgcAltImageCsv: false,
      mediaCardMetrics: [
        METRICS.PINTEREST.SAVES,
        METRICS.PINTEREST.IMPRESSIONS,
        METRICS.PINTEREST.PIN_CLICKS,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic',
          children: [
            METRICS.PINTEREST.ENGAGEMENT_RATE,
            METRICS.PINTEREST.OUTBOUND_CLICKS,
            METRICS.PINTEREST.SAVES,
            METRICS.PINTEREST.IMPRESSIONS,
            METRICS.PINTEREST.PIN_CLICKS,
            METRICS.PINTEREST.VIDEO_VIEWS,
            METRICS.PINTEREST.AVERAGE_VIDEO_WATCH_TIME,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.YOUTUBE]: {
    mediaSource: enumTypes.YOUTUBE,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.YOUTUBE.TOTAL_VIDEO_VIEWS,
        METRICS.YOUTUBE.TOTAL_WATCH_TIME,
        METRICS.YOUTUBE.AVG_VIEW_DURATION,
        METRICS.YOUTUBE.TOTAL_ENGAGEMENTS,
        METRICS.YOUTUBE.SUBSCRIBERS,
      ],
      downloadOptions: {
        csv: {
          label: 'YouTube Boards CSV',
          type: 'youtubeGalleries',
        },
      },
      filters: {
        scope: 'youTubeBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.YOUTUBE.TOTAL_VIDEO_VIEWS,
          METRICS.YOUTUBE.TOTAL_WATCH_TIME,
          METRICS.YOUTUBE.AVG_VIEW_DURATION,
          METRICS.YOUTUBE.TOTAL_ENGAGEMENTS,
          METRICS.YOUTUBE.SUBSCRIBERS,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'youtube.galleries',
    boardDetail: {
      csvType: 'youtubeBoard',
      canDownloadPdf: false,
      canDownloadUgcAltImageCsv: false,
      mediaCardMetrics: [
        METRICS.YOUTUBE.VIDEO_VIEWS,
        METRICS.YOUTUBE.AVG_VIEW_DURATION,
        METRICS.YOUTUBE.TOTAL_ENGAGEMENTS,
        METRICS.YOUTUBE.AVG_VIEW_PERCENTAGE,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic + Promoted',
          children: [
            METRICS.YOUTUBE.AVG_VIEW_PERCENTAGE,
            METRICS.YOUTUBE.AVG_VIEW_DURATION,
            METRICS.YOUTUBE.CARD_CLICKS,
            METRICS.YOUTUBE.CARD_CLICK_RATE,
            METRICS.YOUTUBE.CARD_TEASER_CLICKS,
            METRICS.YOUTUBE.CARD_TEASER_CLICK_RATE,
            METRICS.YOUTUBE.CARD_TEASER_IMPRESSIONS,
            METRICS.YOUTUBE.COMMENTS,
            METRICS.YOUTUBE.DISLIKES,
            METRICS.YOUTUBE.ENGAGEMENTS,
            METRICS.YOUTUBE.LIKES,
            METRICS.YOUTUBE.PREMIUM_VIEWS,
            METRICS.YOUTUBE.PREMIUM_WATCH_TIME,
            METRICS.YOUTUBE.SHARES,
            METRICS.YOUTUBE.SUBSCRIBERS,
            METRICS.YOUTUBE.VIDEOS_ADDED_TO_PLAYLIST,
            METRICS.YOUTUBE.VIDEO_VIEWS,
            METRICS.YOUTUBE.WATCH_TIME,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.TWITTER]: {
    mediaSource: enumTypes.TWITTER,
    boardsIndex: {
      defaultCardMetrics: [
        METRICS.TWITTER.ENGAGEMENTS,
        METRICS.TWITTER.AVG_ENGAGEMENT_RATE,
        METRICS.TWITTER.REPOSTS,
        METRICS.TWITTER.LIKES,
        METRICS.TWITTER.IMPRESSIONS,
      ],
      downloadOptions: {
        csv: {
          label: 'X Boards CSV',
          type: 'twitterGalleries',
        },
      },
      filters: {
        scope: 'twitterBoards',
        default: boardsIndexDefaultFilters,
      },
      queryParamMapping: boardsIndexQueryParamMapping,
      sortDetails: {
        options: [
          GALLERIES_DEFAULT_SORT,
          METRICS.TWITTER.ENGAGEMENTS,
          METRICS.TWITTER.AVG_ENGAGEMENT_RATE,
          METRICS.TWITTER.REPOSTS,
          METRICS.TWITTER.LIKES,
          METRICS.TWITTER.IMPRESSIONS,
        ],
        default: galleriesDefaultSort,
      },
    },
    galleryRouteName: 'twitter.galleries',
    boardDetail: {
      csvType: 'twitterBoard',
      canDownloadPdf: false,
      canDownloadUgcAltImageCsv: false,
      mediaCardMetrics: [
        METRICS.TWITTER.ENGAGEMENT_RATE,
        METRICS.TWITTER.ENGAGEMENTS,
        METRICS.TWITTER.IMPRESSIONS,
        METRICS.TWITTER.LIKES,
      ],
      sortOptions: [
        DETAIL_DEFAULT_SORT,
        {
          title: 'Organic + Promoted',
          children: [
            METRICS.TWITTER.ENGAGEMENT_RATE,
            METRICS.TWITTER.ENGAGEMENTS,
            METRICS.TWITTER.IMPRESSIONS,
            METRICS.TWITTER.LIKES,
            METRICS.TWITTER.LINK_CLICKS,
            METRICS.TWITTER.PROFILE_CLICKS,
            METRICS.TWITTER.QUOTE_POSTS,
            METRICS.TWITTER.REPLIES,
            METRICS.TWITTER.REPOSTS,
            METRICS.TWITTER.TOTAL_REPOSTS,
            METRICS.TWITTER.USER_FOLLOWS,
            METRICS.TWITTER.VIDEO_VIEWS,
          ],
        },
      ],
      defaultSort: boardDetailDefaultSort,
    },
  },
  [constants.LIBRARY]: {
    mediaSource: null,
    boardsIndex: {
      defaultCardMetrics: null,
      filters: {
        scope: 'libraryGalleries',
        default: libraryGalleriesDefaultFilters,
      },
      queryParamMapping: libraryGalleriesQueryParamMapping,
      sortDetails: {
        options: [
          { label: 'Recently Added', value: 'CREATED' },
          // { label: 'Number of Media', value: 'IG_STORY_AVG_COMPLETION_RATE' },
          { label: 'Total Traffic', value: 'LIBRARY_TRAFFIC' },
        ],
        default: galleriesDefaultSort,
      },
    },
    boardDetail: {
      mediaCardMetrics: [METRICS.LIBRARY.CLICKS],
      sortOptions: [
        { label: 'Customized Order', value: 'ORDER' },
        { label: 'Predicted Performance', value: 'PERFORMANCE' },
        { label: 'Recently Added', value: 'ADDED' },
        { label: 'Recently Published', value: 'POSTED' },
      ],
      defaultSort: {
        sortOrder: 'ASC',
        selected: { label: 'Customized Order', value: 'ORDER' },
      },
    },
  },
});

export const COMPETITOR_GALLERY_TYPE_CONFIG = Object.freeze({
  [constants.INSTAGRAM]: {
    competitiveSources: ['INSTAGRAM_OTHER', 'INSTAGRAM_OTHER_IGTV'],
    galleryRouteName: competitiveRouteNames.instagram.boards.main,
  },
  [constants.TWITTER]: {
    competitiveSources: ['TWITTER_OTHER', 'TWITTER_OTHER_LINK', 'TWITTER_OTHER_TEXT'],
    galleryRouteName: competitiveRouteNames.twitter.boards.main,
  },
  [constants.YOUTUBE]: {
    competitiveSources: ['YOUTUBE_OTHER'],
    galleryRouteName: competitiveRouteNames.youtube.boards.main,
  },
});
