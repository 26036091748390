import { useAuthStore } from '@/stores/auth';

// used for finding every mention in our custom format (i.e. @[Jane:1234])
export const encodedMentionRegex = /@\[((?:[^:[\]\\]|\\[:[\]\\])+):(\d+)]/g;

// Converts mentions in a string to plain text (i.e. "Hello @[Jane:1234]" becomes "Hello Jane")
export function decodeMentionsAsPlainText(text) {
  return (text || '').replace(encodedMentionRegex, '$1');
}

function encodeHashtagsForTiptap(message) {
  /*
   * Wrap all hashtags in a span.
   * i.e.:
   *   before: This #hashtag
   *   after:  This <span class="hashtag">#hashtag</span>
   */
  return message.replace(/(#[A-Za-z0-9_]+)/g, '<span class="hashtag">$1</span>');
}

export function encodeLinkedinNonReservedCharsForTiptap(message) {
  return message
    .replace(/\\\(/g, '(')
    .replace(/\\\)/g, ')')
    .replace(/\\\[/g, '[')
    .replace(/\\\]/g, ']')
    .replace(/\\\{/g, '{')
    .replace(/\\\}/g, '}');
}

function decodeHashtagsFromTiptap(html) {
  /*
   * Unwraps all hashtags from their span.
   * i.e.:
   *   before: This <span class="hashtag">#hashtag</span>
   *   after:  This #hashtag
   */
  return html.replace(/<span class="hashtag">([^<]+)<\/span>/g, '$1');
}

export function decodeLinkedinNonReservedCharsForTiptap(html) {
  return html
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(/\[/g, '\\[')
    .replace(/\]/g, '\\]')
    .replace(/\{/g, '\\{')
    .replace(/\}/g, '\\}');
}

export function encodeForTiptap(message) {
  // account for bad data where the message may be null
  let encoded = message != null ? message : '';

  // escape special characters
  encoded = encoded
    .replace(/&/g, '&amp;')
    .replace(/\u00A0/g, '&nbsp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');

  // Convert \n to <br>
  encoded = encoded.replace(/\n/g, '<br>');

  return `<p>${encoded}</p>`;
}

export function decodeFromTiptap(html) {
  // Convert <br> to \n
  let decoded = html.replace(/<br>/g, '\n');

  // Remove all other tags
  decoded = decoded.replace(/<[^>]+>/g, '');

  // Unescape special characters
  decoded = decoded
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, '\u00A0')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"');
  return decoded;
}

function encodeMentionsForTwitter(message) {
  /*
   * Wrap all mentions in an anchor and link to Twitter profile.
   * i.e.:
   *   before: Hey @dhdevtest
   *   after:  Hey <a href="https://twitter.com/@dhdevtest" class="twitter-mention" target="_blank" rel="noopener">@dhdevtest</a>
   */
  return message.replace(
    /(^|[^(@\w)]@*)@(\w+)((?=[^\w@])|$)/g,
    '$1<span class="mention" data-mention-handle="$2">@$2</span>$3',
  );
}

function encodeMentionsForLinkedin(message) {
  /*
   * Wrap all mentions in an anchor and link to LinkedIn profile.
   * i.e.:
   *   before: @[Jasmine Gray Cosmetics](urn:li:organization:100117288)
   *   after:  <a href="https://www.linkedin.com/company/100117288/feed/posts/" >@Jasmine Gray Cosmetics</a>
   */
  const regex = /@\[([^\]]*)\]\(([^)]*)\)/g;
  // const mentionsData = regex.exec(message);
  const mentionsData = message.match(regex);
  if (!mentionsData) return message;
  return mentionsData.reduce((acc, rawString) => {
    const readableStr = rawString.substring(rawString.indexOf('[') + 1, rawString.indexOf(']'));
    const urn = rawString.substring(rawString.indexOf('(') + 1, rawString.length - 1);
    const urnData = urn.split(':');
    let url = '';
    if (urnData[2] === 'organization') {
      url = `https://www.linkedin.com/company/${urnData[3]}/feed/posts/`;
      return acc.replace(rawString, `<a href="${url}">@${readableStr}</a>`);
    }
    if (urnData[2] === 'person') {
      return acc.replace(rawString, `@${readableStr}`);
    }
    return acc;
  }, message);
}

function encodeHashtagsForLinkedin(message) {
  /*
   * Wrap all hashtags in an anchor and link to LinkedIn hashtags.
   * i.e.:
   *   before: {hashtag|\#|cutecats}
   *   after:  <a href="https://www.linkedin.com/feed/hashtag/?keywords=cutecats">#cutecats</a>
   */
  const regex = /\{hashtag\|\\#\|([^}]*)\}/g;
  return message.replace(
    regex,
    '<a href="https://www.linkedin.com/feed/hashtag/?keywords=$1">#$1</a>',
  );
}

export function encodeForTiptapFromLinkedinCaption(message) {
  const encoded = encodeForTiptap(message);
  const mentionsEncoded = encodeMentionsForLinkedin(encoded);
  return encodeHashtagsForLinkedin(mentionsEncoded);
}

export function encodeForTiptapFromFacebook(message) {
  let encoded = encodeForTiptap(message);
  encoded = encodeHashtagsForTiptap(encoded);

  // Wrap mentions in a span
  encoded = encoded.replace(
    encodedMentionRegex,
    (_, p1, p2) =>
      `<span class="mention" data-mention-id="${p2}">${p1
        .replace('\\[', '[')
        .replace('\\]', ']')
        .replace('\\:', ':')
        .replace('\\', '')
        .replace('\\\\', '\\')}</span>`,
  );

  return encoded;
}

export function decodeFromTiptapForFacebook(html) {
  // Unwrap mentions from their span
  let decoded = html.replace(
    /<span class="mention" data-mention-id="(\d+)">([^<]+)<\/span>/g,
    (_, p1, p2) =>
      `@[${p2
        .replace('\\', '\\\\')
        .replace(':', '\\:')
        .replace('[', '\\[')
        .replace(']', '\\]')}:${p1}]`,
  );

  decoded = decodeHashtagsFromTiptap(decoded);
  decoded = decodeFromTiptap(decoded);
  return decoded;
}

export function encodeForTiptapFromComment(message, users, fromCommunity = false) {
  let encoded = encodeForTiptap(message);
  // Wrap mentions in a span
  encoded = encoded.replace(/@\[(\d+)]/g, (match, userId) => {
    let user = users[userId];
    /**
     * When encoding a mention from a specific ID from community ie. @[83826] the users param will not have the targeted user (userId)
     * This is because we are not choosing a suggestion from tiptap so the `onMentionLookup` prop in RichTextArea.vue returns nothing
     * Using the fallback of all brand accessible users will allow us to get the required information for a user that has been directly mentioned
     */
    if (fromCommunity && !user) {
      const authStore = useAuthStore();
      user = authStore.brandAccessibleUsers.find((u) => u.id === Number(userId));
    }
    return `<span class="mention" data-mention-id="${user.id}">@${
      user.first_name ?? user.firstName
    } ${user.last_name ?? user.lastName}</span>`;
  });

  // Unescape any user-entered text that matches our mention format
  encoded = encoded.replace(/\\([@\\])/g, '$1');
  return encoded;
}

export function decodeFromTiptapForComment(html, fromCommunity = false) {
  // Escape any user-entered text that matches our mention format
  let decoded = html.replace(/([@\\])/g, '\\$1');
  if (fromCommunity) {
    /**
     * When a user is directly mentioned by id i.e @[83826] we don't use tiptap suggestions therefore a leading '\' is left due to how we encode/decode
     * This regex just removes the leading '\' for Community currently
     */
    decoded = html.replace(/\\([@\\])/g, '\\$1');
  }

  // Unwrap mentions from their span
  decoded = decoded.replace(
    /<span class="mention" data-mention-id="(\d+)">[^<]+<\/span>/g,
    '@[$1]',
  );

  return decodeFromTiptap(decoded);
}

export function encodeForTiptapFromInstagramFeed(message) {
  const encoded = encodeForTiptap(message);
  return encodeHashtagsForTiptap(encoded);
}

export function decodeFromTiptapForInstagramFeed(html) {
  // Unwrap mentions from their span
  let decoded = html.replace(/<span class="simple-mention">([^<]+)<\/span>/g, '$1');
  decoded = decodeHashtagsFromTiptap(decoded);
  decoded = decodeFromTiptap(decoded);

  return decoded;
}

export function encodeForTiptapFromTweetStatus(message) {
  const encoded = encodeForTiptap(message);
  return encodeMentionsForTwitter(encoded);
}

export function encodeForTiptapFromLinkedinPost(message) {
  let encoded = encodeForTiptap(message);
  encoded = encodeLinkedinNonReservedCharsForTiptap(encoded);
  return encodeHashtagsForTiptap(encoded);
}

export function decodeFromTiptapForLinkedinPost(message) {
  const decoded = decodeLinkedinNonReservedCharsForTiptap(message);
  return decodeFromTiptap(decoded);
}
