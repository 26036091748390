import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useCommunityPermissions } from '@/app/community/composables/useCommunityPermissions';
import { useIdentityStore } from '@/stores/identity';
import { useRoute } from 'vue-router';
import {
  COMMUNITY_ASSIGNMENT_REQUEST_LIMIT,
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_INTERNAL_NOTES_REQUEST_LIMIT,
  COMMUNITY_PERMISSION_TYPES,
  COMMUNITY_ROUTE_NAMES,
} from '@/app/community/constants';
import uniq from 'lodash/uniq';
import { useAuthStore } from '@/stores/auth';
import communityApi from '@/apis/community';
import { useCommunityInteractionRulesStore } from '@/stores/community-interaction-rules';
import * as collaborationApi from '@/apis/collaboration';
import { getMostRecentUserIds, getMostRecentUsers } from '@/app/community/utils/teams';
import keyBy from 'lodash/keyBy';
import union from 'lodash/union';
import sortBy from 'lodash/sortBy';
import { useCommunityMessageTagsStore } from '@/stores/community-message-tags';
import { logger } from '@/utils/logger';

export const useCommunityMultiBrandsStore = defineStore('communityMultiBrands', () => {
  const identityStore = useIdentityStore();
  const authStore = useAuthStore();
  const interactionRuleStore = useCommunityInteractionRulesStore();
  const messageTagStore = useCommunityMessageTagsStore();

  const route = useRoute();
  const { verifyBrandAndUserAccess } = useCommunityPermissions();

  const organizationBrandTags = ref([]);
  const allBrandsAccessibleUsers = ref([]);
  const communityAssignments = ref([]);
  const communityInternalNotes = ref([]);

  const communityAccessibleBrands = computed(() => {
    const activeBrands = identityStore.allActiveUserBrands;
    return activeBrands.filter((brand) => verifyBrandAndUserAccess(brand));
  });

  const communityAccessibleBrandIds = computed(() => {
    return communityAccessibleBrands.value.map(({ id }) => id);
  });

  const interactionRuleAccessibleBrands = computed(() => {
    return communityAccessibleBrands.value.filter((brand) =>
      verifyBrandAndUserAccess(brand, COMMUNITY_PERMISSION_TYPES.INTERACTION_RULE),
    );
  });

  const interactionRuleAccessibleBrandIds = computed(() => {
    return interactionRuleAccessibleBrands.value.map((b) => b.id);
  });

  const textAnalysisAccessibleBrandIds = computed(() => {
    return communityAccessibleBrands.value
      .filter((brand) => verifyBrandAndUserAccess(brand, COMMUNITY_PERMISSION_TYPES.TEXT_ANALYSIS))
      .map((b) => b.id);
  });

  const brandIdToAccessibleBrandUsersMap = computed(() => {
    const mapper = {};
    // TODO: consider making an endpoint to get this data broken down by brand_id
    communityAccessibleBrandIds.value.forEach((brandId) => {
      mapper[brandId] = [];
      allBrandsAccessibleUsers.value.forEach((user, index, arr) => {
        if (user.accessibleBrands.some((id) => id === brandId)) {
          mapper[brandId].push(user);
        }
        if (index === arr.length - 1) {
          mapper[brandId] = sortBy(mapper[brandId], ['firstName', 'lastName']);
        }
      });
    });
    return mapper;
  });

  const brandIdToAssignmentsMap = computed(() => {
    const mapper = {};
    communityAccessibleBrandIds.value.forEach((brandId) => {
      mapper[brandId] = [];
      communityAssignments.value.forEach((assignment) => {
        if (assignment.brandId === brandId) {
          mapper[brandId].push(assignment);
        }
      });
    });
    return mapper;
  });

  function formatMultiBrandGroupItems(itemsByBrand) {
    const sanitized = Object.keys(itemsByBrand ?? {}).filter((brandId) => {
      return communityAccessibleBrandIds.value.includes(Number(brandId));
    });
    if (!sanitized?.length) {
      return [];
    }
    return sanitized.map((brandId) => {
      const brandData = communityAccessibleBrands.value?.find(
        (b) => b.id === parseInt(brandId, 10),
      );
      const sortedChildren = itemsByBrand[brandId].sort((a, b) => a.name.localeCompare(b.name));
      return {
        label: brandData?.name,
        value: `brand_${brandData?.id}`,
        avatarUrl: brandData?.avatarUrl,
        type: 'brand',
        children: sortedChildren.map((item) => ({
          label: item.name,
          value: item.id,
          ...item,
        })),
      };
    });
  }

  const currentRouteAccessibleBrands = computed(() => {
    if (route?.name === COMMUNITY_ROUTE_NAMES.INSIGHTS) {
      return communityAccessibleBrands.value.filter((brand) =>
        verifyBrandAndUserAccess(brand, COMMUNITY_PERMISSION_TYPES.INSIGHTS),
      );
    }
    if (route?.name === COMMUNITY_ROUTE_NAMES.INTERNAL_NOTES) {
      return communityAccessibleBrands.value.filter((brand) =>
        verifyBrandAndUserAccess(brand, COMMUNITY_PERMISSION_TYPES.INTERNAL_NOTES),
      );
    }
    return communityAccessibleBrands.value;
  });

  const currentRouteAccessibleBrandIds = computed(() => {
    return currentRouteAccessibleBrands.value.map((brand) => brand.id);
  });

  const currentRouteAccessibleBrandTags = computed(() => {
    const accessibleBrandTags = organizationBrandTags.value.map((brandTag) => {
      return {
        ...brandTag,
        brands: brandTag?.brands.filter((brand) =>
          currentRouteAccessibleBrandIds.value.includes(brand.id),
        ),
      };
    });
    return accessibleBrandTags.filter((brandTag) => brandTag?.brands?.length > 0);
  });

  const formattedMultiBrandMessageTags = computed(() => {
    return formatMultiBrandGroupItems(messageTagStore.messageTagsByBrand);
  });

  const currentRouteAccessibleMessageTags = computed(() => {
    return formattedMultiBrandMessageTags.value.filter((brand) => {
      const tagGroupBrandId = parseInt(brand.value.split('_')[1], 10);
      return currentRouteAccessibleBrandIds.value.includes(tagGroupBrandId);
    });
  });

  const formattedMultiBrandInteractionRules = computed(() => {
    return formatMultiBrandGroupItems(interactionRuleStore.interactionRulesByBrand);
  });

  const currentRouteAccessibleInteractionRules = computed(() => {
    return formattedMultiBrandInteractionRules.value.filter((brand) => {
      const ruleGroupBrandId = parseInt(brand.value.split('_')[1], 10);
      return (
        currentRouteAccessibleBrandIds.value.includes(ruleGroupBrandId) &&
        interactionRuleAccessibleBrandIds.value.includes(ruleGroupBrandId)
      );
    });
  });

  const mostRecentAssignedUserIds = computed(() => {
    return getMostRecentUserIds({
      comments: communityAssignments.value,
      identity: authStore.identity,
    });
  });

  const mostRecentMentionedUserIds = computed(() => {
    return getMostRecentUserIds({
      comments: communityInternalNotes.value,
      identity: authStore.identity,
    });
  });

  const mostRecentlyAssignedUsers = computed(() => {
    return getMostRecentUsers({
      identity: authStore.identity,
      mostRecentUserIds: mostRecentAssignedUserIds.value,
      accessibleUsers: allBrandsAccessibleUsers.value,
    });
  });

  const mostRecentlyMentionedUsers = computed(() => {
    return getMostRecentUsers({
      identity: authStore.identity,
      mostRecentUserIds: mostRecentMentionedUserIds.value,
      accessibleUsers: allBrandsAccessibleUsers.value,
    });
  });

  function getBrandIdsFromBrandTagIds(selectedBrandTagIds = []) {
    const brandTagMap = keyBy(currentRouteAccessibleBrandTags.value, 'id');
    const brandIdsFromBrandTags = selectedBrandTagIds.map((tagId) => {
      const tag = brandTagMap[tagId];
      return tag ? tag.brands?.map((b) => b.id) : [];
    });
    return union(...brandIdsFromBrandTags).filter((brandId) =>
      currentRouteAccessibleBrandIds.value.includes(brandId),
    );
  }

  async function getOrganizationBrandTags() {
    const organizationIds = uniq(
      communityAccessibleBrands.value.map((brand) => brand.organizationId),
    );
    if (organizationIds.length === 0 || organizationBrandTags.value.length > 0) {
      return;
    }

    const res = await Promise.all(
      organizationIds.map((orgId) => authStore.getBrandTagsByOrganizationId(orgId)),
    );
    organizationBrandTags.value = res.flat();
  }

  async function getMessageTagsForBrands() {
    if (!communityAccessibleBrands.value?.length || messageTagStore.messageTagsByBrand.length > 0) {
      return;
    }
    const brandIds = communityAccessibleBrandIds.value;
    await messageTagStore.getTagsForBrands(brandIds);
  }

  async function getMessageRulesForBrands() {
    if (
      !interactionRuleAccessibleBrandIds.value?.length ||
      interactionRuleStore.interactionRulesByBrand.length > 0
    ) {
      return;
    }
    const brandIds = interactionRuleAccessibleBrandIds.value;
    await interactionRuleStore.getInteractionRulesForBrands(brandIds);
  }

  async function getUsersForAssigneeList() {
    const response = await communityApi.getAssignees({
      brandIds: communityAccessibleBrandIds.value.join(','),
    });
    allBrandsAccessibleUsers.value = response.data ?? [];
  }

  async function getCommunityAssignments() {
    let response;
    try {
      response = await collaborationApi.getComments({
        brandIds: communityAccessibleBrandIds.value.join(','),
        resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT,
        limit: COMMUNITY_ASSIGNMENT_REQUEST_LIMIT,
      });
    } catch (e) {
      logger.error(`Failed to get community assignments: ${e}`);
    } finally {
      communityAssignments.value = response?.data?.data ?? [];
    }
  }

  async function getCommunityInternalNotes() {
    let response;
    try {
      response = await collaborationApi.getComments({
        brandIds: communityAccessibleBrandIds.value.join(','),
        resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE,
        limit: COMMUNITY_INTERNAL_NOTES_REQUEST_LIMIT,
      });
    } catch (e) {
      logger.error(`Failed to get community internal notes: ${e}`);
    } finally {
      communityInternalNotes.value = response?.data?.data ?? [];
    }
  }

  async function setupMultiBrandFilters() {
    await Promise.allSettled([
      getOrganizationBrandTags(),
      getMessageTagsForBrands(),
      getMessageRulesForBrands(),
      getUsersForAssigneeList(),
      getCommunityAssignments(),
      getCommunityInternalNotes(),
    ]);
  }

  return {
    allBrandsAccessibleUsers,
    brandIdToAccessibleBrandUsersMap,
    brandIdToAssignmentsMap,
    mostRecentlyAssignedUsers,
    mostRecentlyMentionedUsers,
    communityAssignments,
    communityInternalNotes,
    organizationBrandTags,
    communityAccessibleBrands,
    communityAccessibleBrandIds,
    interactionRuleAccessibleBrands,
    interactionRuleAccessibleBrandIds,
    textAnalysisAccessibleBrandIds,
    currentRouteAccessibleBrands,
    currentRouteAccessibleBrandIds,
    currentRouteAccessibleBrandTags,
    formattedMultiBrandMessageTags,
    currentRouteAccessibleMessageTags,
    formattedMultiBrandInteractionRules,
    currentRouteAccessibleInteractionRules,
    getBrandIdsFromBrandTagIds,
    setupMultiBrandFilters,
    mostRecentAssignedUserIds,
    mostRecentMentionedUserIds,
  };
});
