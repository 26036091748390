/**
 * Peform a request using the provided callback function, retrying
 * the request in the event of a retryable failed response up to the
 * specified number of retries with an exponential backoff starting
 * at the specified number of milliseconds.
 *
 * @param callback An anonymous function which will be called to perform a request.
 * @param retries The number of total times we should retry the request.
 * @param backoffTime The initial number of milliseconds to wait before retrying.
 * @returns {Promise<Object>}
 */
export async function backoffRetry(callback, retries = 5, backoffTime = 250) {
  try {
    return await callback();
  } catch (e) {
    if ([429, 503].includes(e?.response?.status) && retries >= 1) {
      await new Promise((resolve) => {
        setTimeout(resolve, backoffTime);
      });
      return backoffRetry(callback, retries - 1, backoffTime * 2);
    }
    throw e;
  }
}
