import { GRAPH_SCALE } from '@/app/socialListening/constants';

export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const defaultFilterValues = (optionMap) => {
  return Object.values(optionMap).map((o) => o.value);
};

export const CHANNEL_FILTER_OPTIONS = Object.freeze({
  INSTAGRAM: {
    label: 'Instagram',
    value: 'INSTAGRAM',
    icon: 'instagram-color',
    channel: 'instagram',
  },
  TWITTER: { label: 'X', value: 'TWITTER', icon: 'twitter-color', channel: 'twitter' },
  YOUTUBE: { label: 'YouTube', value: 'YOUTUBE', icon: 'youtube-color', channel: 'youtube' },
});

export const SENTIMENT_FILTER_OPTIONS = Object.freeze({
  POSITIVE: {
    label: 'Positive',
    value: 'POSITIVE',
    severity: 'positive',
    field: 'includePositive',
  },
  NEUTRAL: { label: 'Neutral', value: 'NEUTRAL', severity: 'neutral', field: 'includeNeutral' },
  NEGATIVE: {
    label: 'Negative',
    value: 'NEGATIVE',
    severity: 'negative',
    field: 'includeNegative',
  },
});

export const MEDIA_TYPE_FILTER_OPTIONS = Object.freeze({
  IMAGE: { label: 'Photos', value: 'IMAGE' },
  VIDEO: { label: 'Videos', value: 'VIDEO' },
  LINK: { label: 'Links', value: 'LINK' },
  TEXT: { label: 'Text', value: 'TEXT' },
});

export const KEYWORD_TYPE_FILTER_OPTIONS = Object.freeze({
  KEYWORD: { label: 'Keywords', value: 'KEYWORD' },
  HASHTAG: { label: 'Hashtags', value: 'HASHTAG' },
});

export const TRENDS_DATE_FILTER_OPTIONS = Object.freeze({
  LAST_DAY: {
    label: 'Last 24 Hours',
    value: 'LAST_DAY',
    subtractUnit: 'day',
    subtractValue: 1,
    scale: GRAPH_SCALE.HOUR,
  },
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    value: 'LAST_7_DAYS',
    subtractUnit: 'day',
    subtractValue: 6,
    scale: GRAPH_SCALE.DAY,
  },
  LAST_30_DAYS: {
    label: 'Last 30 Days',
    value: 'LAST_30_DAYS',
    subtractUnit: 'day',
    subtractValue: 29,
    scale: GRAPH_SCALE.DAY,
  },
  LAST_3_MONTHS: {
    label: 'Last 3 Months',
    value: 'LAST_3_MONTHS',
    subtractUnit: 'month',
    subtractValue: 3,
    scale: GRAPH_SCALE.WEEK,
  },
  LAST_6_MONTHS: {
    label: 'Last 6 Months',
    value: 'LAST_6_MONTHS',
    subtractUnit: 'month',
    subtractValue: 6,
    scale: GRAPH_SCALE.MONTH,
  },
  LAST_365_DAYS: {
    label: 'Last 365 Days',
    value: 'LAST_365_DAYS',
    subtractUnit: 'day',
    subtractValue: 364,
    scale: GRAPH_SCALE.MONTH,
  },
});

export const ROLLING_DATE_RANGE_OFFSET_MAP = Object.freeze({
  LAST_7_DAYS: 6,
  LAST_4_WEEKS: 27,
});

export const TOPIC_DATE_FILTER_OPTIONS = Object.freeze({
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    value: 'LAST_7_DAYS',
    subtractUnit: 'day',
    subtractValue: ROLLING_DATE_RANGE_OFFSET_MAP.LAST_7_DAYS,
  },
  LAST_4_WEEKS: {
    label: 'Last 4 Weeks',
    value: 'LAST_4_WEEKS',
    subtractUnit: 'day',
    subtractValue: ROLLING_DATE_RANGE_OFFSET_MAP.LAST_4_WEEKS,
  },
  LAST_WEEK: { label: 'Last Week', value: 'LAST_WEEK', subtractUnit: 'week', subtractValue: 1 },
  LAST_MONTH: { label: 'Last Month', value: 'LAST_MONTH', subtractUnit: 'month', subtractValue: 1 },
});

export const TOPIC_PREVIEW_DATE_FILTER_OPTIONS = Object.freeze({
  LAST_DAY: {
    label: 'Last 24 Hours',
    value: 'LAST_DAY',
    subtractUnit: 'day',
    subtractValue: 1,
  },
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    value: 'LAST_7_DAYS',
    subtractUnit: 'day',
    subtractValue: ROLLING_DATE_RANGE_OFFSET_MAP.LAST_7_DAYS,
  },
  LAST_4_WEEKS: {
    label: 'Last 4 Weeks',
    value: 'LAST_4_WEEKS',
    subtractUnit: 'day',
    subtractValue: ROLLING_DATE_RANGE_OFFSET_MAP.LAST_4_WEEKS,
  },
});

export const TOPIC_PREVIEW_DATE_RANGE_OPTIONS = [
  {
    value: TOPIC_PREVIEW_DATE_FILTER_OPTIONS.LAST_DAY.value,
    label: TOPIC_PREVIEW_DATE_FILTER_OPTIONS.LAST_DAY.label,
  },
  {
    value: TOPIC_PREVIEW_DATE_FILTER_OPTIONS.LAST_7_DAYS.value,
    label: TOPIC_PREVIEW_DATE_FILTER_OPTIONS.LAST_7_DAYS.label,
  },
  {
    value: TOPIC_PREVIEW_DATE_FILTER_OPTIONS.LAST_4_WEEKS.value,
    label: TOPIC_PREVIEW_DATE_FILTER_OPTIONS.LAST_4_WEEKS.label,
  },
];

export const CUSTOM_DATE_FILTER_OPTION = Object.freeze({
  label: 'Custom',
  value: 'CUSTOM',
});

export const TOPIC_FILTER_MESSAGES = Object.freeze({
  VISUAL_FILTER: 'The visual content in your topic will be narrowed down by the media you select.',
});

export const TRENDS_DEFAULT_FILTERS = Object.freeze({
  channels: defaultFilterValues(CHANNEL_FILTER_OPTIONS),
  sentiments: defaultFilterValues(SENTIMENT_FILTER_OPTIONS),
  mediaTypes: defaultFilterValues(MEDIA_TYPE_FILTER_OPTIONS),
  keywordTypes: defaultFilterValues(KEYWORD_TYPE_FILTER_OPTIONS),
  dateRange: TRENDS_DATE_FILTER_OPTIONS.LAST_DAY.value,
  keyword: '',
  customDateRange: null,
});

export const LISTENING_FILTER_TOOLTIPS = Object.freeze({
  REPORTING_PERIOD: 'Activity on posts published within the reporting period',
  COMPETITORS: 'Filter Topic results by selecting up to 12 competitors',
  LEGACY_COMPETITOR:
    "We've updated Topics to make them easier to manage. This is a legacy Competitor Topic and cannot be edited. To take advantage of new functionality, please create a new Topic.",
  LEGACY_INDUSTRY:
    "We've updated Topics to make them easier to manage. This is a legacy Industry Topic and cannot be edited. To take advantage of new functionality, please create a new Topic.",
});

export const LISTENING_SCOPED_FILTERS = {
  TRENDS: {
    scope: 'listeningTrends',
    options: {
      channels: defaultFilterValues(CHANNEL_FILTER_OPTIONS),
      sentiments: Object.values(SENTIMENT_FILTER_OPTIONS),
      mediaTypes: Object.values(MEDIA_TYPE_FILTER_OPTIONS),
      keywordTypes: Object.values(KEYWORD_TYPE_FILTER_OPTIONS),
      dateRange: Object.values(TRENDS_DATE_FILTER_OPTIONS),
    },
    default: {
      channels: [],
      sentiments: [],
      mediaTypes: [],
      keywordTypes: [],
      dateRange: TRENDS_DATE_FILTER_OPTIONS.LAST_DAY.value,
      keyword: '',
      customDateRange: null,
      industryIds: [],
    },
  },
  TOPIC: {
    scope: 'listeningTopic',
    options: {
      sources: defaultFilterValues(CHANNEL_FILTER_OPTIONS),
      sentiment: Object.values(SENTIMENT_FILTER_OPTIONS),
      mediaTypes: Object.values(MEDIA_TYPE_FILTER_OPTIONS),
    },
    default: {
      sources: [],
      sentiment: [],
      mediaTypes: [],
      industryIds: [],
      sourceAndCreatorIds: [],
      visualData: undefined,
      sourceCreated: {
        preset: TOPIC_DATE_FILTER_OPTIONS.LAST_4_WEEKS.value,
      },
    },
  },
};
