import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { logger } from '@/utils/logger';
import * as VisionAiAPI from '@/apis/vision-ai';

import { useNotificationStore } from '@/stores/notification';
import { useTrackingStore } from '@/stores/tracking';
import { VISION_AI_SENDER } from '@/config';
import {
  ACTION_TYPES,
  DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE,
  INTENT_TYPES,
  INTENT_TYPES_BY_ROUTE_NAME,
  VISION_AI_MIXPANEL_EVENTS,
  VISION_AI_SOCKET_EVENTS,
  DEFAULT_METRIC_AND_ANALYTICS_PROMPTS,
  SUMMARY_INTENT_TYPES,
  VISION_AI_WIDGET_INTERACTIONS,
  VISION_AI_RESOURCE_CENTER_URL,
  VISION_AI_WIDGET_LAUNCH_INTERACTIONS,
  BACKUP_PROMPT_MESSAGE,
  CLEAR_CHAT_HISTORY_CONFIRMATION_MESSAGE,
} from '@/components/VisionAi/constants';
import { useRoute } from 'vue-router';
import { getIntentQuery } from '@/components/VisionAi/utils/intent_query';
import { communityRouteName } from '@/app/community/routes';
import { useDashboardsStore } from '@/stores/dashboards';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { DASHBOARD_ROUTE_NAMES } from '@/app/dashboards/constants';
import { useCommunityVisionAi } from '@/app/community/composables/useCommunityVisionAi';
import { useSocketStore } from '@/stores/socket';
import { useIdentityStore } from '@/stores/identity';
import { useVisionAiSourceLinks } from '@/components/VisionAi/composables/useVisionAiSourceLinks';
import { useVisionAiPermissions } from '@/components/VisionAi/composables/useVisionAiPermissions';
import { widgetLaunchEvent } from '@/components/VisionAi/utils/mixpanel-events';
import { COMMUNITY_ROUTE_NAMES } from '@/app/community/constants';
import dayjs from 'dayjs';
import { useIntentMap } from '@/components/VisionAi/composables/useIntentMap';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';
import { useVisionAiConversationsStore } from './vision-ai-conversations';
import { useVisionAiMessagesStore } from './vision-ai-messages';

export const useVisionAiStore = defineStore('visionAi', () => {
  const socketStore = useSocketStore();
  const notificationStore = useNotificationStore();
  const dashboardsStore = useDashboardsStore();
  const identityStore = useIdentityStore();
  const visionAiConversationsStore = useVisionAiConversationsStore();
  const visionAiMessagesStore = useVisionAiMessagesStore();
  const { getWarningMessage } = useIntentMap();

  const route = useRoute();

  // refs
  const visionAiChatWindowOpen = ref(false);
  const forceHideVisionAi = ref(false);
  const currentSourceUrl = ref(null);
  const popupIsExpanded = ref(false);
  const showConversationHistorySidebar = ref(false);

  const { userHasVisionAiPermissionForCurrentRoute } = useVisionAiPermissions();

  const pending = ref({
    conversation: false,
    messages: false,
    regenerateMessage: false,
  });

  const messages = ref([]);
  const notificationPopupOpen = ref(false);

  // computed

  const canAccessConversational = computed(() => {
    const {
      someBrandCanAccessDashboardsAndVisionAi,
      someUserBrandCanAccessVisionAiConversational,
    } = useVisionAiPermissions();
    return (
      someBrandCanAccessDashboardsAndVisionAi.value &&
      someUserBrandCanAccessVisionAiConversational.value
    );
  });

  function expandChatPopup() {
    popupIsExpanded.value = true;
    showConversationHistorySidebar.value = true;
  }

  function shrinkChatPopup() {
    popupIsExpanded.value = false;
    showConversationHistorySidebar.value = false;
  }

  function hideConversationHistory() {
    showConversationHistorySidebar.value = false;
  }

  const promptForRoute = computed(() => {
    if (route?.name?.startsWith(communityRouteName)) {
      const { communityDefaultPrompt } = useCommunityVisionAi();
      return communityDefaultPrompt.value;
    }

    if (route?.name === SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW) {
      return BACKUP_PROMPT_MESSAGE;
    }

    const intent = INTENT_TYPES_BY_ROUTE_NAME?.[route?.name];
    return DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE?.[intent];
  });

  const defaultPrompts = computed(() => {
    let prompts = [];

    if (route && userHasVisionAiPermissionForCurrentRoute.value) {
      prompts.push(promptForRoute.value);
    }
    if (canAccessConversational.value) {
      prompts = prompts.concat(DEFAULT_METRIC_AND_ANALYTICS_PROMPTS);
    }

    return prompts;
  });

  const loadingMessageInCurrentConversation = computed(() => {
    if (visionAiConversationsStore.summariesSelected) {
      return visionAiMessagesStore.summaryLoading;
    }
    return visionAiConversationsStore.isMessageLoadingInCurrentConversation;
  });

  const isCommunityInsightsRoute = computed(() => {
    return route?.name === COMMUNITY_ROUTE_NAMES.INSIGHTS;
  });

  const currentIntentType = computed(() => {
    if (route?.name?.startsWith(communityRouteName)) {
      const { communityIntentType } = useCommunityVisionAi();
      return communityIntentType.value;
    }
    return INTENT_TYPES_BY_ROUTE_NAME?.[route?.name];
  });

  function clearLocalChatHistory() {
    visionAiMessagesStore.setMessages({});
  }

  async function clearChatHistory() {
    pending.value.messages = true;
    let response;
    try {
      response = await VisionAiAPI.clearMessages();
      visionAiMessagesStore.setMessages({});
      visionAiConversationsStore.setCurrentConversationId(null);
      visionAiMessagesStore.anySummaryLoaded = false;
    } catch (error) {
      logger.error('Failed to clear vision ai messages', {}, error);
      throw error;
    } finally {
      pending.value.messages = false;
    }

    return response ?? {};
  }

  function openChatWindow() {
    if (visionAiChatWindowOpen.value === true) return;

    visionAiChatWindowOpen.value = true;
    widgetLaunchEvent({
      interaction: VISION_AI_WIDGET_LAUNCH_INTERACTIONS.OPEN,
      path: route?.path,
    });
  }

  function closeChatWindow() {
    if (visionAiChatWindowOpen.value === false) return;
    visionAiChatWindowOpen.value = false;
    widgetLaunchEvent({
      interaction: VISION_AI_WIDGET_LAUNCH_INTERACTIONS.CLOSE,
      path: route?.path,
    });
  }

  function sendToResourceCenter() {
    window.open(VISION_AI_RESOURCE_CENTER_URL, '_blank');
  }

  async function createMockMessages({ isMockPrompt = false }) {
    // We want the fake summary intent type added to summaries tab, so we should grab any
    // summary intent
    const intentType = isMockPrompt ? SUMMARY_INTENT_TYPES[0] : currentIntentType.value;

    const mockedUserMessage = {
      id: 'mockedUserMessage',
      message: defaultPrompts.value[0],
      sender: VISION_AI_SENDER.USER,
      createdAt: dayjs(),
      isWarningMessage: true,
      intentType,
      intentQuery: {},
    };
    visionAiMessagesStore.addMessageToChatWindow(mockedUserMessage);

    const warningMessage = {
      id: 'warning',
      message: getWarningMessage(currentIntentType.value),
      isWarningMessage: true,
      sender: VISION_AI_SENDER.ASSISTANT,
      createdAt: dayjs(),
      intentType,
      intentQuery: {},
    };
    visionAiMessagesStore.addMessageToChatWindow(warningMessage);
  }

  async function handleClearChatHistory() {
    notificationPopupOpen.value = true;

    if (
      await notificationStore.confirm(
        'Delete Chat History?',
        CLEAR_CHAT_HISTORY_CONFIRMATION_MESSAGE,
        {
          confirmAlias: 'Delete',
          onCancel: () => {
            notificationPopupOpen.value = false;
          },
          onConfirm: () => {
            notificationPopupOpen.value = false;
          },
        },
      )
    ) {
      try {
        const response = await clearChatHistory();
        const trackingStore = useTrackingStore();
        trackingStore.track(VISION_AI_MIXPANEL_EVENTS.AI_WIDGET_INTERACTION, {
          interaction: VISION_AI_WIDGET_INTERACTIONS.DELETE_ALL_HISTORY,
          summaryCount: response?.data?.assistantMessagesDeleted ?? null,
        });
      } catch {
        notificationStore.setToast({
          message: 'Your chat history could not be deleted.',
          type: 'error',
        });
      }
    }
  }

  function onConversationCreatedNotification(conversationData) {
    visionAiConversationsStore.addConversationWebSocket(conversationData);
  }

  function onConversationsDeletedNotification(conversationsData) {
    visionAiConversationsStore.deleteConversationsWebSocket(conversationsData);
  }

  function onConversationUpdatedNotification(conversationData) {
    visionAiConversationsStore.updateConversationWebSocket(conversationData);
  }

  async function copyResponse(message) {
    try {
      let messageToCopy = message.replace(/(<\/?p>)/g, '');
      messageToCopy = messageToCopy.replace(/(<ul>)/g, '');
      messageToCopy = messageToCopy.replace(/(<\/ul>)/g, '\n');
      messageToCopy = messageToCopy.replace(/(<li>)/g, '- ');
      messageToCopy = messageToCopy.replace(/(<\/li>)/g, '');
      messageToCopy = messageToCopy.replace(/(<\/?(strong|i)>)/g, '');
      await navigator.clipboard.writeText(messageToCopy);
      notificationStore.setToast({
        message: `Summary copied`,
      });
    } catch (error) {
      notificationStore.setToast({
        message: `Failed to copy Summary. Please try again.`,
        type: 'error',
      });
    }
  }

  async function startConversation({ message }) {
    const isMetricAnalyticsPrompt = DEFAULT_METRIC_AND_ANALYTICS_PROMPTS.includes(message);
    const intentType = isMetricAnalyticsPrompt
      ? INTENT_TYPES.METRIC_ANALYTICS
      : currentIntentType.value;
    const sender = VISION_AI_SENDER.USER;
    const intentQuery = getIntentQuery(intentType);
    const actionType = isMetricAnalyticsPrompt ? ACTION_TYPES.QUESTION : null;
    let conversationId = visionAiConversationsStore.currentConversationId;

    if (!conversationId) {
      const conversation = await visionAiConversationsStore.createConversation();
      conversationId = conversation.id;
    }

    if (JSON.stringify(intentQuery).includes('updates.0.data')) {
      logger.error(`Malformed intent_query sent for conversation id: ${conversationId}`);
    }

    await visionAiMessagesStore.createMessage({
      conversationId,
      sender,
      message,
      intentType,
      intentQuery,
      actionType,
      usedPrompt: true,
    });
  }

  async function handleSourceButtonClicked(intentType, sourceUrl) {
    if (intentType === INTENT_TYPES.DASHBOARD_REPORTS && route.name === DASHBOARD_ROUTE_NAMES.ID) {
      // if the new dashboard route.id is not equal to the current dashboard route.id
      if (sourceUrl.params.id.toString() !== route.params.id) {
        await dashboardsStore.setCurrentDashboard({ id: sourceUrl.params.id });
      }

      // if the new dashboard route.id is equal to the current dashboard route.id but the query is different
      const routeQuery = pick(route.query, Object.keys(sourceUrl.query));

      if (!isEqual(sourceUrl.query, routeQuery)) {
        const { startDate, endDate, contextStartDate, contextEndDate } = sourceUrl.query;
        await dashboardsStore.updateDateRanges({
          reportDateRange: [startDate, endDate],
          contextDateRange: [contextStartDate, contextEndDate],
        });
      }
    }

    if (
      (intentType === INTENT_TYPES.COMMENTS || intentType === INTENT_TYPES.DMS) &&
      route.name.includes(communityRouteName)
    ) {
      if (sourceUrl.params.brandLabel !== identityStore.currentBrandLabel) {
        currentSourceUrl.value = sourceUrl;
      }
    }
  }

  function clearCurrentSourceUrl() {
    currentSourceUrl.value = null;
  }

  function checkIfSamePageSource(sourceUrl) {
    const {
      isDashboardsRoute,
      isTrendsRoute,
      isTopicsRoute,
      isCommunityRoute,
      checkTopicSourceParamsEqual,
      checkTrendsSourceParamsEqual,
      checkCommunitySourceParamsEqual,
    } = useVisionAiSourceLinks();
    if (isDashboardsRoute.value) {
      if (sourceUrl && sourceUrl.query) {
        const routeQuery = pick(route.query, Object.keys(sourceUrl.query));
        return isEqual(sourceUrl.query, routeQuery);
      }
    }

    if (isTrendsRoute.value && sourceUrl && sourceUrl.query) {
      return checkTrendsSourceParamsEqual(sourceUrl);
    }

    if (isTopicsRoute.value && sourceUrl && sourceUrl.query) {
      return checkTopicSourceParamsEqual(sourceUrl);
    }

    if (isCommunityRoute.value) {
      return checkCommunitySourceParamsEqual(sourceUrl);
    }

    return false;
  }

  function startNewChat() {
    visionAiConversationsStore.setSummariesSelectedValue(false);
    visionAiConversationsStore.setCurrentConversationId(null);
    clearLocalChatHistory();
  }

  function initSocketListeners() {
    // Register socket events
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_MESSAGE_CREATED,
      visionAiMessagesStore.onMessageCreatedNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CLEAR_MESSAGES,
      visionAiMessagesStore.onClearMessagesNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CONVERSATION_CREATED,
      onConversationCreatedNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CONVERSATION_UPDATED,
      onConversationUpdatedNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CONVERSATIONS_DELETED,
      onConversationsDeletedNotification,
      null,
    );
  }

  // watchers
  watch(
    () => socketStore.id,
    (id) => {
      if (id) {
        initSocketListeners();
      }
    },
    { immediate: true },
  );

  watch(
    () => visionAiConversationsStore.conversationMessages,
    () => {
      clearLocalChatHistory();
      visionAiMessagesStore.setMessages({
        newMessages: visionAiConversationsStore.conversationMessages,
      });
    },
  );

  watch(
    () => route?.name,
    () => {
      if (canAccessConversational.value) {
        clearLocalChatHistory();
        visionAiConversationsStore.resetToDefaultConversationState();
      }
    },
  );

  return {
    // states
    canAccessConversational,
    currentIntentType,
    currentSourceUrl,
    defaultPrompts,
    forceHideVisionAi,
    isCommunityInsightsRoute,
    loadingMessageInCurrentConversation,
    messages,
    notificationPopupOpen,
    pending,
    popupIsExpanded,
    showConversationHistorySidebar,
    visionAiChatWindowOpen,

    // methods
    checkIfSamePageSource,
    clearChatHistory,
    clearCurrentSourceUrl,
    clearLocalChatHistory,
    closeChatWindow,
    copyResponse,
    createMockMessages,
    expandChatPopup,
    handleClearChatHistory,
    handleSourceButtonClicked,
    hideConversationHistory,
    onConversationCreatedNotification,
    onConversationsDeletedNotification,
    onConversationUpdatedNotification,
    openChatWindow,
    sendToResourceCenter,
    shrinkChatPopup,
    startConversation,
    startNewChat,
  };
});
