import { getAsset as originalGetAsset } from './getAsset';

export function getAsset(...args) {
  return originalGetAsset(...args);
}

export function getImageSource(path) {
  return getAsset(`img/${path}`);
}

export function getSrcSet(folder) {
  return [...Array(3).keys()].map((i) => `${getImageSource(`${folder}/${i + 1}x.png`)} ${i + 1}x`);
}

export const IMG = {
  googleAnalytics: getImageSource('googleAnalytics.svg'),
  google: getImageSource('logos/google.svg'),
  connectionRevokedAvatar: getImageSource('connection-revoked-avatar@2x.png'),
  igMessageEnableAccess: getImageSource('ig-message-enable-access.png'),
  twitterMessageEnableAccess: getImageSource('twitter-message-enable-access.png'),
  dashboardsPreviewCompetitiveReport: getImageSource('dashboards/preview-competitive-report.png'),
  dashboardsPreviewContentReport: getImageSource('dashboards/preview-contentreport.png'),
  dashboardsCompetitorGraphPreview: getImageSource('dashboards/competitor-graph-preview.png'),
  dashboardsGraphSingleLine: getImageSource('dashboards/graph_single-line.png'),
  dashboardsSingleBarGraphPreview: getImageSource('dashboards/single-bar-graph-preview.png'),
  dashboardsStackedBarGraphPreview: getImageSource('dashboards/stacked-bar-graph-preview.png'),
  dashboardsPreviewGraph: getImageSource('dashboards/preview-graph.png'),
  dashboardsMultipleBrandGraph: getImageSource('dashboards/multi-graph-preview.png'),
  emptyThumbup: getImageSource('empty-thumbup.png'),
  atSymbol: getImageSource('at-symbol.svg'),
  friends: getImageSource('friends.png'),
  checkMarkBlue: getImageSource('checkmark-blue.svg'),
  logosTiktokTrendingSounds: getImageSource('logos/tiktok-trending-sounds.svg'),
  soundsList: getImageSource('sounds-list.svg'),
  emptySeashore: getImageSource('empty-seashore.png'),
  chromeExtension: getImageSource('chrome-extension.png'),
  engageVisual: getImageSource('engage-visual.png'),
  importing: getImageSource('importing.png'),
  arrowLeft: getImageSource('arrow-left.png'),
  arrowRight: getImageSource('arrow-right.png'),
  iconsTwitterWhite: getAsset('icons/twitter.svg'),
  iconsFacebookWhite: getAsset('icons/facebookWhite.svg'),
  iconsTwitterDarkGrey: getAsset('icons/twitterDarkGrey.svg'),
  emptyKanpai: getImageSource('empty-kanpai.png'),
  emptyWave: getImageSource('empty-wave@2x.png'),
  emptyCattub: getImageSource('empty-cattub.png'),
  emptyBeach: getImageSource('empty-beach.png'),
  emptyField: getImageSource('empty-field@2x.png'),
  radioButtonOn: getImageSource('radioButtonOn.svg'),
  radioButtonOff: getImageSource('radioButtonOff.svg'),
  beisLogo: getImageSource('inAppTrials/beis-logo.png'),
  toryBurchLogo: getImageSource('inAppTrials/tory-burch-logo.png'),
  coterieLogo: getImageSource('inAppTrials/coterie-logo.png'),
  stellMcCartneyLogo: getImageSource('inAppTrials/stellmccartney-logo.png'),
  summerFridaysLogo: getImageSource('inAppTrials/summerfridays-logo.png'),
  tiktokCreatorOverviewBanner: getImageSource('tiktok-creator-overview-banner.png'),
  instagramCreatorOverviewBanner: getImageSource('instagram-creator-overview-banner.png'),
  emailCreatorOverviewBanner: getImageSource('email-creator-overview-banner.png'),
  firstTimeUserBanner: getImageSource('first-time-user-banner.png'),
  tiktokUgcDisconnected: getImageSource('tiktokUgcDisconnected/1x.png'),
  tiktokUgcUpgrade: getImageSource('tiktokUgcUpgrade/1x.png'),
  addFacebookProductCatalog: getImageSource('products/add-facebook-product-catalog/1x.png'),
  addProductLinks: getImageSource('products/add-product-links/1x.png'),
  boards: {
    emptyBoard: getImageSource('campaigns/empty-campaign-add-boards/1x.png'),
  },
  dashboard: {
    template: {
      blank: getImageSource('dashboards/templates/blank-overview/1x.png'),
      socialOverview: getImageSource('dashboards/templates/social-competitive-overview/1x.png'),
      competitiveOverview: getImageSource(
        'dashboards/templates/social-competitive-overview/1x.png',
      ),
      brandHealth: getImageSource('dashboards/templates/brand-health-overview/1x.png'),
      tiktokOverview: getImageSource('dashboards/templates/tiktok-overview/1x.png'),
      instagramOverview: getImageSource('dashboards/templates/instagram-overview/1x.png'),
      facebookOverview: getImageSource('dashboards/templates/facebook-overview/1x.png'),
      pinterestOverview: getImageSource('dashboards/templates/pinterest-overview/1x.png'),
      xOverview: getImageSource('dashboards/templates/x-overview/1x.png'),
      youtubeOverview: getImageSource('dashboards/templates/blank-overview/1x.png'),
      linkedinOverview: getImageSource('dashboards/templates/linkedin-overview/1x.png'),
      dashboardOverview: getImageSource('dashboards/templates/dashboard-overview/3x.png'),
    },
    noReports: {
      competitive: getImageSource('dashboards/noReports/competitive-report/1x.png'),
      industryEarned: getImageSource('dashboards/noReports/industry-earned-report/1x.png'),
      owned: getImageSource('dashboards/noReports/owned-report/1x.png'),
      socialAdvertising: getImageSource('dashboards/noReports/social-advertising-report/1x.png'),
    },
  },
  campaigns: {
    emptyCampaignAddMedia: getImageSource('campaigns/empty-campaign-add-media/1x.png'),
    emptyCampaignAddBoards: getImageSource('campaigns/empty-campaign-add-boards/1x.png'),
    emptyCampaignAddCreators: getImageSource('campaigns/empty-campaign-add-creators/1x.png'),
    emptyCampaignsWithAccess: getImageSource('campaigns/empty-campaigns-with-access/1x.png'),
    socialCommerceNoConnection: getImageSource('campaigns/social-commerce-no-connection/1x.png'),
  },
  creators: {
    emptyYourCreators: getImageSource('creators/empty-your-creators/1x.png'),
  },
};

export const inAppTrials = {
  engage: [
    getImageSource('inAppTrials/engage/3x.png'),
    getImageSource('inAppTrials/engage/2x.png'),
    getImageSource('inAppTrials/engage/1x.png'),
  ],
  advance: [
    getImageSource('inAppTrials/advance/3x.png'),
    getImageSource('inAppTrials/advance/2x.png'),
    getImageSource('inAppTrials/advance/1x.png'),
  ],
  premium: [
    getImageSource('inAppTrials/premium/3x.png'),
    getImageSource('inAppTrials/premium/2x.png'),
    getImageSource('inAppTrials/premium/1x.png'),
  ],
  socialListening: [
    getImageSource('inAppTrials/socialListening/3x.png'),
    getImageSource('inAppTrials/socialListening/2x.png'),
    getImageSource('inAppTrials/socialListening/1x.png'),
  ],
  creators: [
    getImageSource('inAppTrials/creators/3x.png'),
    getImageSource('inAppTrials/creators/2x.png'),
    getImageSource('inAppTrials/creators/1x.png'),
  ],
};
