<template>
  <div
    ref="container"
    v-drag-move-2="!productTaggingViewOnly && dragMoveParams"
    class="shopping-tagger"
    @click.left="handleTaggerClick"
  >
    <template v-if="containerRendered && !transitioning">
      <MediaTag
        v-for="(shoppingTag, index) in instagramShoppingTaggerStore.activeMediaTaggedProducts"
        :key="index"
        :boundary="boundary"
        :title="shoppingTag.title"
        :style="{ left: ratioToOffset(shoppingTag.x, 'x'), top: ratioToOffset(shoppingTag.y, 'y') }"
        :data-tag-index="index"
        class="draggable"
        :product-tagging-view-only="productTaggingViewOnly"
        @delete-tag="instagramShoppingTaggerStore.deleteShoppingTag({ index })"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import MediaTag from '@/app/scheduler/components/EditPost/MediaTag.vue';
import {
  maxShoppingTagsPerMedia,
  maxShoppingTagsPerPost,
  maxTagsPerMedia,
  maxTagsPerPost,
  tooManyShoppingTagsPerMediaMessage,
  tooManyShoppingTagsPerPostMessage,
  tooManyTagsPerMediaMessage,
  tooManyTagsPerPostMessage,
} from '@/app/scheduler/constants';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ShoppingTagger',
  components: { MediaTag },
  props: {
    transitioning: { type: Boolean, required: false, default: false },
    productTaggingViewOnly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      containerRendered: false,
      zIndex: 1,
      dragMoveParams: {
        draggableClass: 'draggable',
        onDragStarted: this.handleDragStarted,
        onDragFinished: this.handleDragFinished,
      },
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useSchedulerStore, useInstagramShoppingTaggerStore),
    boundary() {
      return this.$el.closest('.media-container');
    },
  },
  watch: {
    'schedulerStore.carouselActiveMedia': {
      handler(newVal, oldVal) {
        if (this.instagramShoppingTaggerStore.editingShoppingTag) {
          this.instagramShoppingTaggerStore.deleteShoppingTag({ mediaId: oldVal.id });
          this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: false });
        }
      },
    },
  },
  mounted() {
    this.containerRendered = true;
  },
  methods: {
    getAxisLength(axis) {
      return axis === 'x' ? this.$refs.container.clientWidth : this.$refs.container.clientHeight;
    },
    ratioToOffset(ratio, axis) {
      const offset = Math.round(ratio * this.getAxisLength(axis));
      return `${offset}px`;
    },
    offsetToRatio(offset, axis) {
      const ratio = offset / this.getAxisLength(axis);

      // In some very rare scenarios, the ratio can be slightly above 1 (or potentially slightly
      // below 0, but I haven't seen that yet). Instead of spending more time trying to debug this
      // very rare scenario, I decided to fix the issue by round the number to within the range of
      // 0 - 1 (inclusive). This should have no negative effects.
      if (ratio > 1) {
        return 1;
      }
      if (ratio < 0) {
        return 0;
      }
      return ratio;
    },
    canAddShoppingTag() {
      if (this.instagramShoppingTaggerStore.taggedProducts.length >= maxShoppingTagsPerPost) {
        this.notificationStore.setToast({
          message: tooManyShoppingTagsPerPostMessage,
          type: 'warning',
        });
        return false;
      }
      if (this.instagramShoppingTaggerStore.totalMediaTagsAndProductTags >= maxTagsPerPost) {
        this.notificationStore.setToast({
          message: tooManyTagsPerPostMessage,
          type: 'warning',
        });
        return false;
      }
      if (
        this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length >=
        maxShoppingTagsPerMedia
      ) {
        this.notificationStore.setToast({
          message: tooManyShoppingTagsPerMediaMessage,
          type: 'warning',
        });
        return false;
      }
      if (this.instagramShoppingTaggerStore.totalActiveMediaTags >= maxTagsPerMedia) {
        this.notificationStore.setToast({
          message: tooManyTagsPerMediaMessage,
          type: 'warning',
        });
        return false;
      }
      return true;
    },
    handleTaggerClick(e) {
      if (
        e.target.classList.contains('shopping-tagger') &&
        !this.instagramShoppingTaggerStore.editingShoppingTag
      ) {
        if (this.canAddShoppingTag()) {
          this.instagramShoppingTaggerStore.addShoppingTag({
            shoppingTag: {
              fbProductId: null,
              title: null,
              imageUrl: null,
              price: null,
              x: this.offsetToRatio(e.offsetX, 'x'),
              y: this.offsetToRatio(e.offsetY, 'y'),
            },
          });
          this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: true });
        }
      }
    },
    handleDragStarted(target) {
      target.style.zIndex = this.zIndex;
      this.zIndex += 1;
    },
    handleDragFinished(target, x, y) {
      const xRatio = this.offsetToRatio(x, 'x');
      const yRatio = this.offsetToRatio(y, 'y');

      const index = parseInt(target.dataset.tagIndex, 10);
      const taggedProduct = this.instagramShoppingTaggerStore.activeMediaTaggedProducts[index];

      // if the tag coordinates have changed, update it
      if (taggedProduct.x !== xRatio || taggedProduct.y !== yRatio) {
        this.instagramShoppingTaggerStore.updateShoppingTag({
          index,
          shoppingTag: {
            x: xRatio,
            y: yRatio,
          },
        });
        return;
      }
      // if target is the editing dot and hasn't changed coordinates, remove it
      if (target.getElementsByClassName('dot').length !== 0) {
        this.instagramShoppingTaggerStore.deleteShoppingTag({ index });
        this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: false });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.shopping-tagger {
  height: 100%;
  position: relative;
  cursor: pointer;
}

.draggable {
  position: absolute;
  white-space: nowrap;
}
</style>
