import { mapStores } from 'pinia';
import {
  duplicationOptions,
  convertPostFrom,
  convertPostTo,
  DUPLICATION_POST_TYPES,
  DUPLICATION_TYPES,
  postTypeByPlatform,
} from '@/utils/postDuplication';
import {
  postStatus,
  PLATFORMS,
  mixpanelActions,
  SCHEDULER_DRAWERS,
} from '@/app/scheduler/constants';
import enumTypes from '@/app/library/constants';
import { toolTips } from '@/config';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
import { useTrackingStore } from '@/stores/tracking';
import { useFlagStore } from '@/stores/flag';
import { useDrawer } from '@dashhudson/dashing-ui';
import { getPlatformPostTypeFromPost } from '@/app/scheduler/utils';

export default {
  computed: {
    ...mapStores(useNotificationStore, useAuthStore, useTrackingStore),
    DUPLICATION_TYPES() {
      return DUPLICATION_TYPES;
    },
    DUPLICATION_POST_TYPES() {
      return DUPLICATION_POST_TYPES;
    },
  },
  methods: {
    pdPostTypeByPlatform(platform, post) {
      return postTypeByPlatform(platform, post);
    },
    pdDuplicationOptions(action) {
      return duplicationOptions(this.flagStore, action);
    },
    duplicateToBrandSubList(post) {
      return Object.values(this.authStore.identity?.brands || {})
        .map((toBrand) => {
          return toBrand.id !== post.brand_id
            ? {
                text: toBrand.name,
                action: this.pdDuplicatePostToBrand,
                actionParams: { post, toBrand },
              }
            : null;
        })
        .filter(Boolean);
    },
    async pdDuplicatePostToBrand({ post, toBrand }) {
      const postDuplicationData = {
        duplicatedFrom: post.brand_id,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_BRAND,
        duplicateBrandID: toBrand.id,
        duplicateBrandName: toBrand.name,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.$router.push({
        name: this.$route.name,
        params: { brandLabel: toBrand.label },
      });
      await this.$router.push({
        name: `scheduler.${post.platform}.posts`,
        params: {
          brandLabel: toBrand.label,
          id: 'new',
        },
        query: {
          duplicatePostId: post.id,
          duplicatePostPlatform: post.platform,
        },
      });
    },
    pdCanCrossBrandDuplicateMedia(media) {
      let canCrossBrand = true;
      media.forEach((mediaItem) => {
        if (![enumTypes.UPLOAD, enumTypes.EDITOR].includes(mediaItem.source)) {
          canCrossBrand = false;
        }
      });
      return canCrossBrand;
    },
    pdCrossBrandDuplicationDisabled(post, media) {
      const isThreadedTweet = post?.platform === PLATFORMS.TWITTER && post?.replies?.length > 0;
      if (![postStatus.IMPORTED, postStatus.POSTED].includes(post?.status)) {
        return { isDisabled: true, tip: toolTips.crossBrandDisabledPostStatus };
      }
      if (isThreadedTweet) {
        return { isDisabled: true, tip: toolTips.crossBrandDisabledTweetThread };
      }
      if (!this.pdCanCrossBrandDuplicateMedia(media)) {
        return { isDisabled: true, tip: toolTips.crossBrandDisabledMedia };
      }

      return { isDisabled: false, tip: '' };
    },
    pdDuplicatePostToChannel({ post, toType, fromType, replaceModal = true }) {
      const validTypes = Object.values(DUPLICATION_POST_TYPES);
      if (!validTypes.includes(toType) || !validTypes.includes(fromType)) {
        this.notificationStore.setToast({
          message: 'The specified platform does not yet have post duplication implemented.',
          type: 'error',
        });
      } else {
        const basePost = convertPostFrom(post, fromType);
        const newPost = convertPostTo(basePost, toType);
        const isPinterestBulk = newPost.type === 'pinterestBulk';
        const routeName = isPinterestBulk
          ? 'scheduler.pinterest.posts.new.bulk'
          : `scheduler.${newPost.type}.posts`;
        const params = isPinterestBulk ? {} : { id: 'new' };
        const flagStore = useFlagStore();
        const { launchDrawer } = useDrawer();
        const crossChannelPublishingFlag =
          flagStore.ready && flagStore.flags.crossChannelPublishing;
        if (crossChannelPublishingFlag) {
          launchDrawer({
            name: SCHEDULER_DRAWERS.EDIT_POST_DRAWER,
            props: {
              id: newPost.id,
              initialPostType: getPlatformPostTypeFromPost(newPost),
              type: toType,
            },
          });
        } else {
          this.$router.push({
            name: routeName,
            params,
            query: {
              duplicatePostId: post.id,
              duplicatePostPlatform: post.platform,
              toType,
              replaceModal,
            },
          });
        }
      }
    },
  },
};
