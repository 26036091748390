<template>
  <div>
    <Dropzone
      ref="dropzone"
      :on-accept="uploadStarted"
      :on-error="uploadError"
      :on-preview-generated="uploadPreviewGenerated"
      :on-upload-progress="uploadProgress"
      :on-success="uploadSuccess"
      :accept-video="acceptVideo"
      :accept-image="acceptImage"
      :max-files="mediaCountLimit"
      :class="{ 'full-height': fullHeight }"
    >
      <component
        v-bind="viewerComponentProps"
        :is="viewerComponent"
        v-model="media"
        :disable-editing="disableEditing"
        :enable-cropping="true"
        :media-selected="mediaSelected"
        :upload-clicked="uploadClicked"
        :crop-clicked="openMediaCropperPopup"
        :download-media-clicked="downloadMediaClicked"
        :library-clicked="openSelectMediaPopup"
        :replace-media-item="replaceMediaItem"
        :remove-clicked="removeClicked"
        :allow-add="allowAdd"
        :save-alt-text="saveAltText"
        :creating-brand-media="creatingBrandMedia"
      />

      <MediaCropperPopup
        v-if="
          showMediaCropperPopup &&
          mediaToCrop &&
          (mediaToCrop.type === 'IMAGE' || typeof mediaToCrop === 'string')
        "
        :close="closeMediaCropperPopup"
        :media="mediaToCrop"
        :original-media="cropperStore.cropperOriginalMedia"
        :on-save="replaceMediaItem"
        :platform="viewerComponentProps.platform"
        :custom-cropper-presets="cropperPresetImage"
        :enable-free-crop="enableFreeCrop"
        :post-type="cropperPostType"
      />

      <VideoCropperPopup
        v-if="showMediaCropperPopup && mediaToCrop && mediaToCrop.type === 'VIDEO'"
        :close="closeMediaCropperPopup"
        :media="mediaToCrop"
        :original-media="cropperStore.cropperOriginalMedia"
        :on-save="replaceMediaItem"
        :platform="viewerComponentProps.platform"
        :custom-cropper-presets="cropperPresetVideo"
        :enable-free-crop="enableFreeCrop"
        :post-type="cropperPostType"
        :reset-thumbnail="() => $emit('reset-thumb-details')"
      />

      <SelectMediaPopup
        v-if="showSelectMediaPopup"
        :close="closeSelectMediaPopup"
        :media-selected="libraryMediaSelected"
        :media-type-limit="mediaTypeLimitCombined"
        :media-count-limit="remainingMediaCountLimit"
        :video-count-limit="remainingVideoCountLimit"
        :allow-mixed-media="allowMixedMedia"
        :can-be-published-at="canBePublishedAt"
        :disallow-past-publish-dates="disallowPastPublishDates"
        :disallow-incompatible-publish-dates="disallowIncompatiblePublishDates"
        :publish-dates-must-overlap-with="publishDatesMustOverlapWith"
      />
    </Dropzone>
    <div v-if="hasCrossChannelPublishingFlag && isDrawerEditor && multiChannelActive" class="mt-4">
      <DashingBanner message="Media will be synced across channels" :closable="false" />
    </div>
    <Banner
      v-if="visionUsageInfo"
      alert-type="white"
      custom-icon="magic-wand-1-filled"
      class="usage-info-banner"
      data-cy="usage-info-banner"
    >
      {{ visionUsageInfo }}
    </Banner>
    <div
      v-if="showCoverImageButton"
      :class="{ 'vision-usage-info-margin': visionUsageInfo }"
      class="cover-image-button"
    >
      <DropdownButton
        v-if="!viewerComponentProps?.coverImage"
        data-cy="add-cover-image-button-text"
        :dropdown-list="addDropdownList"
        align-left
        @dropdown-action-clicked="addActionClicked"
      >
        <template #buttonContent>
          <span class="cover-image-button-text">
            {{ coverImageButtonText }}
          </span>
        </template>
      </DropdownButton>
      <span v-else class="cover-image-button-text" @click="removeCoverPhoto">
        {{ coverImageButtonText }}
      </span>
    </div>
    <CoverImageDropzone
      ref="coverImageUploader"
      :display-error-message="displayErrorMessage"
      @on-update-cover-image-upload-status="updateCoverImageUploadStatus"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { externalAxios } from '@/apis/external-apis';
import { UPLOAD_STATUS } from '@/config';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import MediaCropperPopup from '@/components/MediaCropping/MediaCropperPopup.vue';
import VideoCropperPopup from '@/components/MediaCropping/VideoCropperPopup.vue';
import enumTypes, {
  CONVERTED_VIDEO_DURATION_WARNING,
  CONVERTED_VIDEO_DURATION_WARNING_SOME_MEDIA,
  mediaTypes,
} from '@/app/library/constants';
import { enumProp } from '@/utils/props';
import { useMediaSelectStore } from '@/stores/media-select';
import { useMediaStore } from '@/stores/media';
import { useCropperStore } from '@/stores/cropper';
import SocketsMixin from '@/mixins/socketsMixin';
import { logger } from '@/utils/logger';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';
import { BRAND } from '@/models/auth/permissions.enum';
import { useFlagStore } from '@/stores/flag';
import {
  CROSS_BRAND_DUPLICATE_ERROR,
  tabContexts,
  fbPostTypes,
  PLATFORM_POST_TYPE_MAP,
} from '@/app/scheduler/constants';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { useSocketStore } from '@/stores/socket';
import MediaModel from '@/models/media';
import { ALL_TIME, isValidInterval } from '@/utils/dateUtils';
import MediaTile from '@/components/MediaTile.vue';
import { useDrawer, Banner as DashingBanner } from '@dashhudson/dashing-ui';
import Dropzone from './Dropzone.vue';
import SelectMediaPopup from '../../../../../components/core/SelectMediaPopup.vue';
import CoverImageDropzone from './CoverImageDropzone.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaManager',
  components: {
    MediaTile,
    Dropzone,
    DropdownButton,
    CoverImageDropzone,
    MediaCropperPopup,
    SelectMediaPopup,
    VideoCropperPopup,
    Banner,
    DashingBanner,
  },
  mixins: [SocketsMixin],
  props: {
    mediaTypeLimit: enumProp(mediaTypes, null),
    mediaCountLimit: { type: Number, default: null },
    videoCountLimit: { type: Number, default: null },
    mediaList: { type: Array, default: () => [] },
    mediaSelected: { type: Function, required: true },
    downloadMediaClicked: { type: Function, default: () => {} },
    allowMixedMedia: { type: Boolean, default: true },
    disableEditing: { type: Boolean, default: false },
    displayErrorMessage: { type: Function, default: null },
    viewerComponent: { type: Object, required: true },
    viewerComponentProps: { type: Object, default: () => ({}) },
    onUploadStatusChange: { type: Function, default: () => {} },
    singleSelectMedia: { type: Boolean, default: false },
    clearMediaOnAdd: { type: Boolean, default: false },
    fullHeight: { type: Boolean, default: false },
    customCropperPresets: { type: Object, default: null },
    enableFreeCrop: { type: Boolean, default: true },
    postType: { type: String, default: null },
    onCroppingCoverImage: { type: Function, default: () => {} },
    visionUsageInfo: { type: String, default: null },
    saveAltText: { type: Function, default: () => {} },
    canBePublishedAt: { type: [Date, Object], default: null },
    publishDatesMustOverlapWith: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
    disallowPastPublishDates: { type: Boolean, default: false },
    disallowIncompatiblePublishDates: { type: Boolean, default: false },
  },
  emits: ['select-cover-image', 'remove-cover-photo', 'reset-thumb-details'],
  setup() {
    const { launchDrawer, backDrawer } = useDrawer();

    return {
      launchDrawer,
      backDrawer,
    };
  },
  data() {
    return {
      isCroppingCoverImage: false,
      mediaToCrop: null,
      mediaToCropIdx: null,
      showMediaCropperPopup: false,
      showSelectMediaPopup: false,
      media: cloneDeep(this.mediaList),
      creatingBrandMedia: false,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useAuthStore,
      useMediaSelectStore,
      useCropperStore,
      useMediaStore,
      useFlagStore,
      useSchedulerStore,
      useInstagramShoppingTaggerStore,
      useInstagramUserTaggerStore,
      useFacebookProductTaggerStore,
      useTrackingStore,
      useSocketStore,
    ),
    hasCrossChannelFlag() {
      return this.flagStore.ready && this.flagStore.flags.crossChannelPublishing;
    },
    isDrawerEditor() {
      return this.schedulerStore.postEditorData.isDrawerEditor;
    },
    multiChannelActive() {
      return this.schedulerStore.postEditorData.selectedChannels.length > 1;
    },
    cropperPresetImage() {
      return this.customCropperPresets?.image || this.customCropperPresets;
    },
    cropperPresetVideo() {
      return this.customCropperPresets?.video || this.customCropperPresets;
    },
    remainingMediaCountLimit() {
      if (this.isSelectingCoverPhoto) {
        return 1;
      }
      return this.mediaCountLimit === null
        ? null
        : Math.max(0, this.mediaCountLimit - this.media.length);
    },
    remainingVideoCountLimit() {
      return this.videoCountLimit === null
        ? null
        : Math.max(0, this.videoCountLimit - this.videoCount);
    },
    mediaTypeLimitCombined() {
      if (this.isSelectingCoverPhoto) {
        return mediaTypes.IMAGE;
      }
      if (this.mediaTypeLimit === mediaTypes.VIDEO || !this.acceptImage) {
        return mediaTypes.VIDEO;
      }
      if (this.mediaTypeLimit === mediaTypes.IMAGE || !this.acceptVideo) {
        return mediaTypes.IMAGE;
      }
      return null;
    },
    acceptVideo() {
      return (
        (!this.mediaTypeLimit || this.mediaTypeLimit === mediaTypes.VIDEO) &&
        (this.videoCountLimit === null || this.videoCount < this.videoCountLimit) &&
        (this.allowMixedMedia || this.imageCount === 0)
      );
    },
    acceptImage() {
      return (
        (!this.mediaTypeLimit || this.mediaTypeLimit === mediaTypes.IMAGE) &&
        (this.allowMixedMedia || this.videoCount === 0)
      );
    },
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    addDropdownList() {
      const fromLibrary = this.hasLibraryAccess
        ? [
            {
              text: 'From Library',
              action: 'openSelectMediaPopup',
            },
          ]
        : [];
      return fromLibrary.concat([
        {
          text: 'From Your Computer',
          action: 'uploadCoverImageClicked',
        },
      ]);
    },
    isPostTypeReel() {
      return [tabContexts.REEL, fbPostTypes.REEL].includes(this.postType);
    },
    isReelVideo() {
      return (
        this.isPostTypeReel ||
        (this.postType === tabContexts.FEED_POST &&
          this.media?.length === 1 &&
          this.media[0]?.type === mediaTypes.VIDEO)
      );
    },
    cropperPostType() {
      return this.isReelVideo ? tabContexts.REEL : this.postType;
    },
    showCoverImageButton() {
      return (
        !this.disableEditing &&
        !this.viewingSubScreen &&
        this.flagStore.ready &&
        this.isPostTypeReel &&
        this.media.length === 1 &&
        this.media[0].type === mediaTypes.VIDEO
      );
    },
    uploadPending() {
      const coverImageUploadStatus = this.viewerComponentProps?.coverImage?.uploadStatus;
      return (
        !this.media.every((m) => !m?.uploadStatus || m?.uploadStatus === UPLOAD_STATUS.SUCCESS) ||
        !(!coverImageUploadStatus || coverImageUploadStatus === UPLOAD_STATUS.SUCCESS)
      );
    },
    allowAdd() {
      return (
        (this.acceptImage || this.acceptVideo) &&
        (this.mediaCountLimit === null || this.media.length < this.mediaCountLimit) &&
        (!this.singleSelectMedia || this.media.length === 0)
      );
    },
    videoCount() {
      return this.media.filter((item) => item.type === mediaTypes.VIDEO).length;
    },
    imageCount() {
      return this.media.filter((item) => item.type === mediaTypes.IMAGE).length;
    },
    coverImageButtonText() {
      // Update the logic here to show the text conditionally.
      return this.viewerComponentProps?.coverImage
        ? 'Remove Custom Cover Image'
        : 'Add Custom Cover Image';
    },
    isSelectingCoverPhoto() {
      return (
        this.videoCount === 1 &&
        this.isPostTypeReel &&
        (this.showSelectMediaPopup || this.hasCrossChannelPublishingFlag)
      );
    },
    viewingSubScreen() {
      return this.schedulerStore.activeSubScreen && this.schedulerStore.activeSubScreen !== 'index';
    },
    hasCrossChannelPublishingFlag() {
      return this.flagStore.ready && this.flagStore.flags.crossChannelPublishing;
    },
  },
  watch: {
    uploadPending(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.onUploadStatusChange(newVal);
      }
    },
    mediaList(val) {
      if (!isEqual(val, this.media)) {
        this.media = cloneDeep(val);
      }
      const processingMedia = this.mediaList.filter(
        (m) => m.uploadStatus === UPLOAD_STATUS.PROCESSING,
      );
      if (processingMedia.length > 0) {
        this.checkMediaUploadStatus(processingMedia);
      }
      this.checkForVideoConversionWarnings();
    },
    media(val) {
      if (!isEqual(val, this.mediaList)) {
        this.mediaSelected(val);
      }
    },
    'authStore.currentBrand': {
      immediate: true,
      async handler(toBrand) {
        const mediaOwner = this.media[0]?.brandId;
        if (toBrand && this.media.length && mediaOwner && toBrand.id !== mediaOwner) {
          // Media starts to process
          this.creatingBrandMedia = true;
          this.onUploadStatusChange(this.creatingBrandMedia);
          if (
            this.media.every((mediaItem) =>
              [enumTypes.EDITOR, enumTypes.UPLOAD].includes(mediaItem.source),
            )
          ) {
            const brandMedia = await this.createNewBrandMedia();
            // If the media is video, wait for the video conversion callback to finish and set media to post
            // there.
            if (brandMedia && brandMedia?.[0]?.type !== 'VIDEO') {
              await this.setBrandMediaToPost(brandMedia);
              this.mediaStore.pending.createMediaV2 = false;
            }
          }
        }
      },
    },
  },
  sockets: {
    async convert_video_callback(videoData) {
      logger.info('convert_video_callback websocket received.', videoData, '');
      if (this.creatingBrandMedia) {
        await this.setBrandMediaToPost([videoData]);
        this.mediaStore.pending.createMediaV2 = false;
      } else if (videoData.source === enumTypes.UPLOAD) {
        this.uploadConverted(videoData);
      }
    },
    media_processed(data) {
      this.uploadProcessed(data);
    },
  },
  methods: {
    updateCoverImageUploadStatus(media) {
      this.$emit('select-cover-image', media);
    },
    addActionClicked(option) {
      this[option](true);
    },
    uploadClicked() {
      this.$refs.dropzone.openFileDialog();
    },
    uploadCoverImageClicked() {
      this.$refs.coverImageUploader.openFileDialog();
    },
    launchSelectMediaDrawer() {
      this.launchDrawer({
        name: 'selectMedia',
        props: {
          close: this.closeSelectMediaPopup,
          mediaSelected: this.libraryMediaSelected,
          mediaTypeLimit: this.mediaTypeLimitCombined,
          mediaCountLimit: this.remainingMediaCountLimit,
          videoCountLimit: this.remainingVideoCountLimit,
          allowMixedMedia: this.allowMixedMedia,
          canBePublishedAt: this.canBePublishedAt,
          disallowPastPublishDates: this.disallowPastPublishDates,
          disallowIncompatiblePublishDates: this.disallowIncompatiblePublishDates,
          publishDatesMustOverlapWith: this.publishDatesMustOverlapWith,
          size: 'large',
        },
      });
    },
    openSelectMediaPopup(selectingCoverPhoto = false) {
      if (this.singleSelectMedia || selectingCoverPhoto) {
        this.mediaSelectStore.setSingleMode();
      }
      if (this.hasCrossChannelPublishingFlag) {
        this.launchSelectMediaDrawer();
      } else {
        this.showSelectMediaPopup = true;
      }
    },
    closeSelectMediaPopup() {
      this.mediaSelectStore.setMultipleMode();
      this.showSelectMediaPopup = false;
      this.backDrawer();
    },
    async openMediaCropperPopup(media, idx, isCoverImage = false) {
      if (isCoverImage) {
        this.isCroppingCoverImage = true;
      }

      if (!isCoverImage) {
        await this.schedulerStore.setCarouselPage({
          carouselPage: idx,
          activeMedia: media,
          postType: this.postType
            ? PLATFORM_POST_TYPE_MAP[this.viewerComponentProps.platform][this.postType]
            : PLATFORM_POST_TYPE_MAP[this.viewerComponentProps.platform],
        });
      }

      this.mediaToCropIdx = idx;
      if (media?.transforms && media?.transforms.parentMediaId) {
        await this.cropperStore.getCropperOriginalMedia({
          brandId: this.authStore.currentBrand.id,
          mediaId: media.transforms.parentMediaId,
        });
      }
      this.mediaToCrop = media;
      if (this.flagStore?.flags?.crossChannelPublishing) {
        if (
          this.mediaToCrop &&
          (this.mediaToCrop.type === 'IMAGE' || typeof this.mediaToCrop === 'string')
        ) {
          this.launchDrawer({
            name: 'schedulerMediaCropper',
            props: {
              close: this.closeMediaCropperPopup,
              media: this.mediaToCrop,
              originalMedia: this.cropperStore.cropperOriginalMedia,
              onSave: this.replaceMediaItem,
              platform: this.viewerComponentProps.platform,
              customCropperPresets: this.cropperPresetImage,
              enableFreeCrop: this.enableFreeCrop,
              postType: this.cropperPostType,
            },
          });
        } else {
          this.launchDrawer({
            name: 'schedulerVideoCropper',
            props: {
              close: this.closeMediaCropperPopup,
              media: this.mediaToCrop,
              originalMedia: this.cropperStore.cropperOriginalMedia,
              onSave: this.replaceMediaItem,
              platform: this.viewerComponentProps.platform,
              customCropperPresets: this.cropperPresetVideo,
              enableFreeCrop: this.enableFreeCrop,
              postType: this.cropperPostType,
              resetThumbDetails: () => this.$emit('reset-thumb-details'),
            },
          });
        }
      } else {
        this.showMediaCropperPopup = true;
      }
    },
    closeMediaCropperPopup() {
      this.isCroppingCoverImage = false;
      this.backDrawer();
      this.showMediaCropperPopup = false;
      this.mediaToCropIdx = null;
      this.cropperStore.removeCropperOriginalMedia();
      this.onCroppingCoverImage(false);
    },
    libraryMediaSelected(media) {
      if (this.isSelectingCoverPhoto) {
        this.$emit('select-cover-image', cloneDeep(media[0]));
      } else {
        // Remove any existing error message
        this.displayErrorMessage(null);

        if (this.clearMediaOnAdd) {
          this.media = [];
        }

        this.media = this.media.concat(media);
        this.mediaSelected(cloneDeep(this.media));
      }
    },
    removeClicked(media) {
      this.instagramUserTaggerStore.deleteMediaUserTags({ mediaId: media.id });
      this.instagramShoppingTaggerStore.deleteMediaShoppingTags({ mediaId: media.id });
      this.facebookProductTaggerStore.deleteMediaFacebookProductTags({ mediaId: media.id });
      this.media = this.media.filter((m) => m.id !== media.id);
      this.mediaSelected(cloneDeep(this.media));
      this.$refs.dropzone.removeFile(media);
      this.$refs.coverImageUploader.removeFile();
      if (this.media.every((mediaItem) => mediaItem?.brandId === this.authStore.currentBrand.id)) {
        this.displayErrorMessage(null);
      }
      if (this.viewerComponentProps?.platform === 'facebook') {
        this.saveAltText(media.id, '');
      }
    },
    removeCoverPhoto() {
      this.$emit('remove-cover-photo');
      this.$refs.coverImageUploader.removeFile();
    },
    checkMediaUploadStatus(processingMedia) {
      processingMedia.forEach((m) => {
        if (this.uploadPending && m.uploadStatus === UPLOAD_STATUS.PROCESSING) {
          // If it times out, there might be something wrong with the convert_video_callback
          // websocket. Send a separate request to check if the media is processed and already had
          // the original_converted url.
          setTimeout(async () => {
            // Recheck to see if there's still no update on uploadStatus.
            if (this.uploadPending && m.uploadStatus === UPLOAD_STATUS.PROCESSING) {
              await this.mediaStore.validateMediaObject({
                brandId: this.authStore.currentBrand.id,
                media: m,
              });

              const uploadedMedia = this.mediaStore.mediaV2;
              const f = this.findFile(uploadedMedia);

              // If it has the correct original_converted url, mark it as successfully processed.
              if (uploadedMedia?.sizes?.originalConverted?.url) {
                f.type = uploadedMedia.type;
                f.uploadStatus = UPLOAD_STATUS.SUCCESS;
                f.isProcessing = true;
                f.fullMediaObject = uploadedMedia;

                this.mediaSelected(cloneDeep(this.media));
              }
            }
          }, 60 * 1000);
        }
      });
    },
    findFile(file) {
      /* The contents of media.id change over time and and can flip flop between a UUID and a media
       * ID. The file parameter passed into findFile() also is entirely dependent on which callback
       * function it comes from. Sometimes it has a UUID, sometimes it is a media ID. The easiest fix
       * was just to have findFile check a few combinations of the two. */
      return this.media.find((m) => {
        return (
          m.id === file.upload?.uuid ||
          m.id === file.id ||
          (m.uuid && [file.uuid, file.upload?.uuid, file.sourceId].includes(m.uuid))
        );
      });
    },
    uploadStarted(file) {
      // Remove any existing error message
      this.displayErrorMessage(null);

      if (this.clearMediaOnAdd) {
        this.media = [];
      }

      this.media.push({
        id: file.upload.uuid,
        uuid: file.upload.uuid,
        type: file.type.split('/')[0].toUpperCase(),
        previewUrl: null,
        uploadProgress: 0,
        uploadStatus: file.status,
      });
    },
    uploadPreviewGenerated(file, dataUrl) {
      const f = this.findFile(file);
      if (f) {
        f.previewUrl = dataUrl;
        f.width = file.width ? file.width : null;
        f.height = file.height ? file.height : null;
      }
    },
    uploadProgress(file, progress) {
      const f = this.findFile(file);
      if (f) {
        f.uploadProgress = progress;
        f.uploadStatus = file.status;
      }
    },
    async uploadSuccess(file, media) {
      const f = this.findFile(file);
      let updatedFile;
      if (f) {
        // Before passing media to the scheduler popup, validate that the fullMediaObject is the correct object shape
        // and if not, call the Media V2 endpoint for it.
        // TODO: Get rid of "fullMediaObject" so all media data is uniform
        f.id = media.mediaId;
        /* This is the cause of a potential race condition. Allowing validateMediaObject to make
         *  an async API call allows other functions to execute. This means uploadConverted can
         * start executing before uploadSuccess if the validation takes a while to call back.
         * Preferably we'd completely remove validateMediaObject and just use V2 media objects
         * everywhere. */
        await this.mediaStore.validateMediaObject({
          brandId: this.authStore.currentBrand.id,
          media: {
            ...f,
            id: media.mediaId,
          },
        });
        const uploadedMedia = this.mediaStore.mediaV2;

        // Finding file again, as it may now be deepClone'd since we last checked.
        updatedFile = this.findFile(file);
        updatedFile.id = media.mediaId;
        if (updatedFile.type === mediaTypes.IMAGE) {
          updatedFile.uploadStatus = UPLOAD_STATUS.SUCCESS;
          updatedFile.previewUrl = media.urls.full;
        } else {
          updatedFile.uploadStatus = UPLOAD_STATUS.PROCESSING;
        }
        updatedFile.fullMediaObject = uploadedMedia;
        updatedFile.uploadSuccessCalled = true;
      }

      this.mediaSelected(cloneDeep(this.media));

      /* Take the data that was passed back from uploadConverted and fully process it this time
       * once we get rid of the validateMediaObject call we can get rid of this bit of code. */
      if (updatedFile?.uploadConvertedData) {
        await this.uploadConverted(updatedFile.uploadConvertedData);
      }
    },
    uploadError(file) {
      // Remove media that caused error
      if (file && file.upload && file.upload.uuid) {
        this.media = this.media.filter((m) => m.id !== file.upload.uuid);
      }

      logger.error(
        `An error occurred while trying to upload your media. ID ${file?.upload?.uuid}`,
        {},
        '',
      );
      // Display error message
      this.displayErrorMessage({
        level: 'error',
        message: 'An error occurred while trying to upload your media.  Please try again.',
      });

      setTimeout(() => {
        this.displayErrorMessage(null);
      }, 3000);
    },
    async uploadConverted(file) {
      const f = this.findFile(file);
      if (f && !f?.uploadSuccessCalled) {
        /* uploadSuccess has not been called, cannot do uploadConverted at this time. Store the data
         * so uploadSuccess can call uploadConverted later. */
        f.uploadConvertedData = file;
        return;
      }

      const res = await externalAxios.get(file.urls.full, { responseType: 'blob' });
      if (res.status === 200) {
        const vid = URL.createObjectURL(res.data);
        if (f) {
          f.type = file.type;
          f.previewUrl = vid;
          f.uploadStatus = UPLOAD_STATUS.SUCCESS;
          f.isProcessing = true;
          // Before passing media to the scheduler popup, validate that the fullMediaObject is
          // the correct object shape and if not, call the Media V2 endpoint for it.
          await this.mediaStore.validateMediaObject({
            brandId: this.authStore.currentBrand.id,
            media: {
              ...file,
              id: f.id,
            },
          });
          f.fullMediaObject = this.mediaStore.mediaV2;
          f.isProcessing = false;
        }
      } else {
        // Response other than 200, some sort of error occurred while converting the video.
        logger.error(`Failed to convert the video. ID: ${f?.id}, Uuid: ${f?.uuid}.`, {}, '');
        this.uploadError(file);
        return;
      }
      logger.info(
        `Video converted successfully. Passing to scheduler popup. ID: ${f?.id}, Uuid: ${f?.uuid}.`,
        {},
        '',
      );
      this.mediaSelected(cloneDeep(this.media));
    },
    async uploadProcessed(media) {
      const item = this.media.find((m) => m.id === media.id);
      if (!item) {
        return;
      }
      await this.mediaStore.validateMediaObject({
        brandId: this.authStore.currentBrand.id,
        media: { id: media.id },
      });
      const fullMediaObject = this.mediaStore.mediaV2;
      Object.assign(item, fullMediaObject);
      Object.assign(item, { isProcessing: false });
      logger.info(`Media processed successfully. ID: ${media?.id}.`, {}, '');
      this.mediaSelected(cloneDeep(this.media));
    },
    async replaceMediaItem(mediaItem) {
      // Replace the old media item with the new cropped media in the mediaList array
      // First verify new media is in the correct object shape for MediaV2
      const oldMediaId = this.media[this.mediaToCropIdx]?.id;
      await this.mediaStore.validateMediaObject({
        brandId: this.authStore.currentBrand.id,
        media: mediaItem,
      });
      const newMedia = this.mediaStore.mediaV2;
      if (newMedia.type === 'VIDEO') {
        Object.assign(newMedia, { isProcessing: true });
      }
      if (this.isCroppingCoverImage) {
        this.$emit('select-cover-image', newMedia);
      } else {
        this.media.splice(this.mediaToCropIdx, 1, newMedia);
        this.mediaSelected(cloneDeep(this.media));
      }
      this.saveAltText(newMedia.id, '', oldMediaId);
      this.closeMediaCropperPopup();
    },
    async setBrandMediaToPost(media) {
      // create new media object and set it to post
      let error = false;
      this.media = await Promise.all(
        media.map(async (mediaItem) => {
          if (
            mediaItem?.brandId !== this.authStore.currentBrand.id &&
            mediaItem instanceof MediaModel
          ) {
            return mediaItem;
          }
          try {
            await this.mediaStore.validateMediaObject({
              brandId: this.authStore.currentBrand.id,
              media: mediaItem,
            });
            return this.mediaStore.mediaV2;
          } catch (e) {
            logger.error('Failed to validate new brand media for cross brand duplication.', {}, e);
            error = true;
            this.creatingBrandMedia = false;
            this.onUploadStatusChange(this.creatingBrandMedia);
            return {};
          }
        }),
      );
      this.mediaSelected(cloneDeep(this.media));
      // Media done processing
      this.creatingBrandMedia = false;
      this.onUploadStatusChange(this.creatingBrandMedia);
      if (error) {
        this.displayErrorMessage({
          level: 'error',
          message: CROSS_BRAND_DUPLICATE_ERROR,
        });
        this.notificationStore.setToast({
          message: CROSS_BRAND_DUPLICATE_ERROR,
          type: 'error',
        });
      }
    },
    async createNewBrandMedia() {
      let error = false;
      const newMedia = await Promise.all(
        this.media.map(async (mediaItem) => {
          const mediaProps = {
            sizes: { original: mediaItem.sizes.originalConverted },
          };
          const data = {
            source: 'UPLOAD',
            sourceId: mediaItem?.sourceId?.toString(),
            callback_socket_id: mediaItem.type === 'IMAGE' ? undefined : this.socketStore?.id,
            type: mediaItem.type,
            ...(mediaItem.type === 'IMAGE' ? { image: mediaProps } : { video: mediaProps }),
          };
          try {
            this.mediaStore.pending.createMediaV2 = true;
            await this.mediaStore.createMediaV2({ brandId: this.authStore.currentBrand.id }, data);
            if (
              this.mediaStore?.newMediaV2 &&
              this.viewerComponentProps?.altTextMediaMap?.[mediaItem?.id]
            ) {
              this.saveAltText(
                this.mediaStore?.newMediaV2?.id,
                this.viewerComponentProps?.altTextMediaMap[mediaItem?.id],
                mediaItem.id,
              );
            }
            return this.mediaStore.newMediaV2;
          } catch (e) {
            logger.error('Failed to create new brand media for cross brand duplication.', {}, e);
            error = true;
            this.creatingBrandMedia = false;
            this.onUploadStatusChange(this.creatingBrandMedia);
            return mediaItem;
          }
        }),
      );
      if (error) {
        this.displayErrorMessage({
          level: 'error',
          message: CROSS_BRAND_DUPLICATE_ERROR,
        });
        this.notificationStore.setToast({
          message: CROSS_BRAND_DUPLICATE_ERROR,
          type: 'error',
        });
      }
      return newMedia;
    },
    checkForVideoConversionWarnings() {
      const atLeastOneMediaWithWarnings = this.mediaList.some((mediaObject) => {
        return mediaObject.videoConversionWarnings;
      });
      if (!atLeastOneMediaWithWarnings) return;

      const message =
        this.mediaList.length > 1
          ? CONVERTED_VIDEO_DURATION_WARNING_SOME_MEDIA
          : CONVERTED_VIDEO_DURATION_WARNING;
      this.displayErrorMessage({
        level: 'warning',
        message,
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.upload-media-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--space-80));
  grid-gap: var(--space-12);
}

.cover-image-button {
  margin-top: var(--space-16);

  :deep(.button) {
    all: unset !important;
  }

  .cover-image-button-text {
    font-weight: 500;
    font-size: var(--x12);
    text-decoration: underline;
    cursor: pointer;
    color: var(--text-primary);
  }
}

.vision-usage-info-margin {
  margin-top: var(--space-4);
}

.full-height {
  height: 100%;
}

.add {
  :deep(.button) {
    width: var(--space-80);
    height: var(--space-80);
  }

  :deep(.icon) {
    width: var(--space-24) !important;
    height: var(--space-24) !important;
  }

  :deep(.dropdown-list) {
    top: 5.5rem;
  }
}

.usage-info-banner {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--space-24);
  border-radius: var(--round-corner-small);
  font-size: var(--x14);
  margin-top: var(--space-14);
  margin-bottom: 0;
  min-height: fit-content;
  align-items: center;
}
</style>
