<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useFilterSidePanel, useDrawer, Page } from '@dashhudson/dashing-ui';
import { useFlagStore } from '@/stores/flag';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import PrimaryNavigation from '@/components/layout/navigation/PrimaryNavigation.vue';
import TrialBanner from '@/components/InAppTrials/TrialBanner.vue';
import FilterSidePanelProvider from '@/components/dashingUI/FilterSidePanelProvider.vue';
import DrawerProvider from '@/components/dashingUI/DrawerProvider.vue';

const route = useRoute();

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  showNav: {
    type: Boolean,
    default: true,
  },
});

const flagStore = useFlagStore();

const { visible: filterVisible } = useFilterSidePanel();
const { stateKey } = useDrawer();
const { hidden, expanded } = useSecondaryNavigationPanel();

const filterSidePanelTransitionClasses = ref('-z-10 hidden');
const contentAreaTransitionClasses = ref('');
const dashingLayoutWrapperClasses = ref('');

const isDrawerOpen = computed(() => !!stateKey.value);
const showPageOverlay = computed(() => isDrawerOpen.value);

const secondaryNavHidden = computed(() => {
  return hidden.value;
});
const secondaryNavExpanded = computed(() => {
  const hasAccessToSecondaryNavViaFeatureFlag =
    flagStore.ready && flagStore.flags?.[route?.meta?.showSecondaryNavFeatureFlagOverride];

  if (route?.meta?.showSecondaryNavFeatureFlagOverride)
    return expanded.value && hasAccessToSecondaryNavViaFeatureFlag;
  return expanded.value && !route?.meta?.hideSecondaryNav;
});

const useReportLayout = computed(() => {
  return route?.meta?.report;
});

const hidePrimaryNav = computed(() => {
  return route?.meta?.hidePrimaryNav;
});

const backgroundClass = computed(() => {
  return [
    'before:fixed',
    'before:bottom-0',
    'before:right-0',
    'before:top-0',
    'before:left-[var(--primary-navigation-width)]',
    'before:h-screen',
    'before:w-[calc(100%-var(--primary-navigation-width))]',
    'before:rounded-tl-[2.5rem]',
    'background-transition',
    {
      'before:bg-[#f9fbff]': !useReportLayout.value,
      "before:content-['']": !hidePrimaryNav.value,
    },
  ];
});

const secondaryNavigationVisible = computed(() => {
  return !secondaryNavHidden.value && !useReportLayout.value && secondaryNavExpanded.value;
});

function handleContentTransitionStart($event) {
  const { propertyName, target } = $event;
  if (propertyName === 'transform' && target.id === 'mainContentArea') {
    filterSidePanelTransitionClasses.value =
      '-z-10 block main-content-open before:top-0 before:fixed before:h-full before:content-[""] before:w-[100px] before:z-40 before:right-[var(--filter-side-panel-width)]';
    contentAreaTransitionClasses.value = 'z-10 dash-bg-background-page';
  }
}

function handleContentTransitionEnd($event) {
  const { propertyName, target } = $event;
  if (propertyName === 'transform' && target.id === 'mainContentArea') {
    if (filterVisible.value) {
      filterSidePanelTransitionClasses.value =
        'block main-content-open before:top-0 before:fixed before:h-full before:content-[""] before:w-[100px] before:z-40 before:right-[var(--filter-side-panel-width)]';
      contentAreaTransitionClasses.value = 'z-10 dash-bg-background-page';
      dashingLayoutWrapperClasses.value = 'before:z-[1]';
    } else {
      filterSidePanelTransitionClasses.value = '-z-10 hidden';
      contentAreaTransitionClasses.value = '';
      dashingLayoutWrapperClasses.value = '';
    }
  }
}

const primaryNavigationTranslateX = computed(() => {
  if (filterVisible.value) {
    if (secondaryNavigationVisible.value) {
      return '-translate-x-[var(--combined-navigation-width)]';
    }
    return '-translate-x-[var(--primary-navigation-width)]';
  }
  return '';
});

const mainContentAreaTranslateX = computed(() => {
  if (filterVisible.value) {
    return '-translate-x-[var(--combined-navigation-width)]';
  }
  return '';
});

const backgroundTranslateX = computed(() => {
  if (filterVisible.value) {
    return 'before:-translate-x-[var(--combined-navigation-width)]';
  }
  return '';
});

const mainContentPadding = computed(() => {
  if (useReportLayout.value || hidePrimaryNav.value) {
    return 'pl-0';
  }
  if (filterVisible.value) {
    return 'pl-[var(--combined-navigation-width)]';
  }
  if (secondaryNavigationVisible.value && !filterVisible.value) {
    return 'pl-[var(--primary-navigation-width)] xl:pl-[var(--combined-navigation-width)]';
  }
  return 'pl-[var(--primary-navigation-width)]';
});

const innerPadding = computed(() => {
  if (!useReportLayout.value && !secondaryNavHidden.value && !hidePrimaryNav.value) {
    return 'px-10';
  }
  return null;
});
</script>

<template>
  <div
    class="relative"
    :class="[backgroundClass, backgroundTranslateX, dashingLayoutWrapperClasses]"
    data-dashing-layout-wrapper
  >
    <PrimaryNavigation
      v-if="props.showNav"
      :hide-toggle-button="filterVisible"
      class="dash-transition-md"
      :class="primaryNavigationTranslateX"
    />
    <Page
      id="mainContentArea"
      class="main-content-transition relative min-h-screen w-full rounded-tl-[2.5rem]"
      :class="[
        filterVisible ? 'main-content-transition-open' : 'main-content-transition-close',
        contentAreaTransitionClasses,
        mainContentAreaTranslateX,
        mainContentPadding,
      ]"
      data-main-content-area
      @transitionstart="handleContentTransitionStart"
      @transitionend="handleContentTransitionEnd"
    >
      <div :class="['dash-relative', 'dash-pb-20', innerPadding]">
        <slot />
        <transition
          enter-active-class="dash-transition-lg"
          leave-active-class="dash-transition-lg"
          enter-to-class="dash-opacity-100"
          enter-from-class="opacity-0"
          leave-to-class="opacity-0"
          leave-from-class="dash-opacity-100"
        >
          <div
            v-if="showPageOverlay"
            class="dash-absolute dash-left-0 dash-right-0 dash-top-0 dash-bottom-0 dash-bg-background-drawer-overlay"
            style="z-index: var(--z-index-nav)"
          />
        </transition>
      </div>
    </Page>
    <template v-if="flagStore.flags.gdiHeadersAndFilters">
      <FilterSidePanelProvider :class="filterSidePanelTransitionClasses" />
    </template>
    <DrawerProvider
      :class="[
        filterVisible
          ? 'main-content-transition-open dash-elevation-2xl'
          : 'main-content-transition-close',
        mainContentAreaTranslateX,
      ]"
      @transitionstart="handleContentTransitionStart"
      @transitionend="handleContentTransitionEnd"
    />
    <TrialBanner />
  </div>
</template>

<style lang="postcss" scoped>
.main-content-transition-open {
  transition: all 0.3s cubic-bezier(0.35, 0, 0.3, 1.1);
}

.main-content-transition-close {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 0.35, 1.1, 0.3);
  transition-property: transform, padding;
}

.background-transition::before {
  transition: all 0.3s cubic-bezier(0.35, 0, 0.3, 1.1);
}

.main-content-open::before {
  box-shadow:
    0 6px 12px 0 rgb(45 69 104 / 4%),
    0 12px 24px 0 rgb(45 69 104 / 15%),
    0 24px 32px 0 rgb(45 69 104 / 15%);
}
</style>
<style lang="postcss">
.hs-web-interactives-top-banner-open {
  [data-dashing-layout-wrapper]::before {
    top: var(--hubspot-banner-height) !important;
  }

  [data-dash-page-header] {
    padding-top: calc(var(--hubspot-banner-height) + 24px) !important;
  }
}
</style>
