import { FILTERS_OPERATORS, LOGICAL_OPERATORS } from '@/config';

export const tooltips = {
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
  connectCallToAction:
    'Dash Social brings you all of the insights you love, including Relationships. Ask your Dash Social administrator to provide you access.',
  creatorDiscovery:
    "Filter and sort through Dash Social's database of creators. More are added every day.",
};

const instagramFilters = {
  FILTER_TAGS: 'tags',
  FILTER_ACCOUNT_HANDLE: 'keyword',
  FILTER_ACCOUNT_TYPE: 'is_business',
  FILTER_MENTION_TYPE: 'type',
  FILTER_CONNECTION: 'connection',
};

const metricKeys = {
  PUBLISH_TIME: 'PUBLISH_TIME',
  ADDED_TIME: 'ADDED_TIME',
  TOTAL_EMV: 'TOTAL_EMV',
  TOTAL_FOLLOWERS_GAINED: 'FOLLOWERS_GAINED',
  AVG_EFFECTIVENESS: 'AVG_EFFECTIVENESS',
  AVG_ENGAGEMENT: 'AVG_ENGAGEMENT',
  AVG_ENGAGEMENT_RATE: 'AVG_ENGAGEMENT_RATE',
  AVG_ESTIMATED_REACH: 'AVG_ESTIMATED_REACH',
  POST_COUNT: 'POST_COUNT',
  FOLLOWERS: 'FOLLOWERS',
};

const instagramCreatorMetricKeys = {
  AVG_ENGAGEMENT: 'instagram.engagements',
  AVG_ENGAGEMENT_RATE: 'instagram.engagement_rate',
  FOLLOWERS: 'followers',
};

const instagramRelationshipPostSortValues = {
  TIMESTAMP: 'TIMESTAMP',
  EFFECTIVENESS: 'EFFECTIVENESS',
  ENGAGEMENT: 'ENGAGEMENT',
  REACH: 'REACH',
  TOTAL_ENGAGEMENTS: 'TOTAL_ENGAGEMENTS',
  LIKES: 'LIKES',
  COMMENTS: 'COMMENTS',
  VIDEO_VIEWS: 'VIDEO_VIEWS',
  EMV: 'EMV',
  FOLLOWERS_GAINED: 'FOLLOWERS_GAINED',
  IMPRESSIONS: 'IMPRESSIONS',
  SAVED: 'SAVED',
};

const instagramCreatorMetrics = {};
instagramCreatorMetrics[instagramCreatorMetricKeys.AVG_ENGAGEMENT] = {
  field: 'engagements',
  label: 'Avg. Engagements',
};
instagramCreatorMetrics[instagramCreatorMetricKeys.AVG_ENGAGEMENT_RATE] = {
  field: 'engagementRate',
  label: 'Avg. Engagement Rate',
  format: 'percent',
};
instagramCreatorMetrics[instagramCreatorMetricKeys.FOLLOWERS] = {
  field: 'relationFollowers',
  label: 'Followers',
};

const instagramRelationshipMetrics = {};
instagramRelationshipMetrics[metricKeys.PUBLISH_TIME] = {
  field: 'lastPostCreatedAt',
  label: 'Recently Published',
  format: 'datetime',
};
instagramRelationshipMetrics[metricKeys.ADDED_TIME] = {
  field: 'createdAt',
  label: 'Relationship Added',
  format: 'datetime',
};
instagramRelationshipMetrics[metricKeys.TOTAL_EMV] = {
  field: 'totalEmv',
  label: 'Total EMV',
  format: 'currency',
};
instagramRelationshipMetrics[metricKeys.TOTAL_FOLLOWERS_GAINED] = {
  field: 'totalFollowersGained',
  label: 'Followers Gained',
};
instagramRelationshipMetrics[metricKeys.AVG_EFFECTIVENESS] = {
  field: 'avgEffectiveness',
  label: 'Avg. Effectiveness',
  format: 'percent',
};
instagramRelationshipMetrics[metricKeys.AVG_ENGAGEMENT] = {
  field: 'avgEngagement',
  label: 'Avg. Eng. Rate',
  format: 'percent',
};
instagramRelationshipMetrics[metricKeys.POST_COUNT] = {
  field: 'totalPosts',
  label: 'Posts ft. You',
};
instagramRelationshipMetrics[metricKeys.FOLLOWERS] = {
  field: 'relationFollowers',
  label: 'Followers',
};
instagramRelationshipMetrics[metricKeys.AVG_ESTIMATED_REACH] = {
  field: 'avgReach',
  label: 'Avg. Est. Reach',
};

const storyMetricKeys = {
  REACH: 'REACH',
  IMPRESSIONS: 'IMPRESSIONS',
  EXITS: 'EXITS',
  EXIT_RATE: 'EXIT_RATE',
  REPLIES: 'REPLIES',
  COMPLETION_RATE: 'COMPLETION_RATE',
};

const instagramRelationshipStoryMetrics = {};
instagramRelationshipStoryMetrics[storyMetricKeys.REACH] = {
  field: 'reach',
  label: 'Reach',
};
instagramRelationshipStoryMetrics[storyMetricKeys.IMPRESSIONS] = {
  field: 'views',
  label: 'Impressions',
};
instagramRelationshipStoryMetrics[storyMetricKeys.EXITS] = {
  field: 'exits',
  label: 'Exits',
};
instagramRelationshipStoryMetrics[storyMetricKeys.EXIT_RATE] = {
  field: 'exitRate',
  label: 'Exit Rate',
  format: 'percent',
};
instagramRelationshipStoryMetrics[storyMetricKeys.REPLIES] = {
  field: 'replies',
  label: 'Replies',
  tooltip:
    'Due to API restrictions, replies from users in the European Economic Area (EEA) or Japan will no longer be included. For stories created in the EEA or Japan, Replies will now return a value of 0.',
};
instagramRelationshipStoryMetrics[storyMetricKeys.COMPLETION_RATE] = {
  field: 'completionRate',
  label: 'Completion Rate',
  format: 'percent',
};

const connectionStatus = {
  NOT_INVITED: 'NOT_INVITED',
  INVITATION_SENT: 'INVITATION_SENT',
  ACCEPTED: 'ACCEPTED',
  ACCEPTED_AND_CONNECTED: 'ACCEPTED_AND_CONNECTED',
  ACCEPTED_AND_DISCONNECTED: 'ACCEPTED_AND_DISCONNECTED',
  LAPSED: 'LAPSED',
  REVOKED: 'REVOKED',
};

const connectionStatusTreatments = {};
const defaultTreatment = {
  copy: '',
  labelCopy: 'Not Invited',
  class: '',
};
connectionStatusTreatments[connectionStatus.NOT_INVITED] = defaultTreatment;
connectionStatusTreatments[connectionStatus.REVOKED] = defaultTreatment;
connectionStatusTreatments[connectionStatus.INVITATION_SENT] = {
  class: 'invited',
  copy: 'Invite Sent',
  tooltip: 'This account has not yet accepted your invitation to connect.',
};
connectionStatusTreatments[connectionStatus.ACCEPTED_AND_CONNECTED] = {
  class: 'accepted',
  copy: 'Accepted',
  labelCopy: 'Accepted - Connected',
  tooltip: 'This account has accepted your invite and is connected.',
};
connectionStatusTreatments[connectionStatus.ACCEPTED_AND_DISCONNECTED] = {
  class: 'accepted-disconnected',
  copy: 'Accepted',
  labelCopy: 'Accepted - Disconnected',
  tooltip: 'This account has been disconnected.',
};
connectionStatusTreatments[connectionStatus.LAPSED] = {
  class: 'lapsed',
  copy: 'Lapsed',
  tooltip: 'The invite sent to this account has not been accepted in over 14 days.',
};

const creatorSources = {
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK: 'TIKTOK',
};

const tooltipSuffixes = {
  AVG_ENGAGEMENT_RATE:
    "This is a 12 week rolling average across this creator's most recent posts.<br><br>Engagement Rate = (Likes + Comments) / Followers",
  AVG_EFFECTIVENESS:
    "This is a 12 week rolling average across this creator's most recent posts.<br><br>Effectiveness = (Likes + Comments) / Est. Reach",
};

export default {
  instagramFilters,
  relationshipDefaultMetrics: [
    metricKeys.POST_COUNT,
    metricKeys.AVG_ENGAGEMENT,
    metricKeys.AVG_EFFECTIVENESS,
    metricKeys.TOTAL_FOLLOWERS_GAINED,
  ],
  relationshipPIQDefaultMetrics: [
    metricKeys.POST_COUNT,
    metricKeys.AVG_ENGAGEMENT,
    metricKeys.TOTAL_FOLLOWERS_GAINED,
    metricKeys.TOTAL_EMV,
  ],
  relationshipStoryDefaultMetrics: [
    storyMetricKeys.REACH,
    storyMetricKeys.IMPRESSIONS,
    storyMetricKeys.COMPLETION_RATE,
    storyMetricKeys.EXITS,
    storyMetricKeys.EXIT_RATE,
    storyMetricKeys.REPLIES,
  ],
  creatorCardDefaultMetrics: [
    instagramCreatorMetricKeys.AVG_ENGAGEMENT,
    instagramCreatorMetricKeys.AVG_ENGAGEMENT_RATE,
    instagramCreatorMetricKeys.FOLLOWERS,
  ],
  metricKeys,
  instagramRelationshipMetrics,
  instagramCreatorMetrics,
  storyMetricKeys,
  instagramRelationshipStoryMetrics,
  kpiTooltips: {
    upTooltip: 'The engagement rate of this content is higher than the account’s average.',
    downTooltip: 'The engagement rate of this content is lower than the account’s average.',
  },
  instagramFilterOptions: [
    { label: 'Tags', value: instagramFilters.FILTER_TAGS },
    { label: 'Account Handle', value: instagramFilters.FILTER_ACCOUNT_HANDLE },
    { label: 'Account Type', value: instagramFilters.FILTER_ACCOUNT_TYPE },
    { label: 'Connection Status', value: instagramFilters.FILTER_CONNECTION },
    { label: 'Content Type', value: instagramFilters.FILTER_MENTION_TYPE },
  ],
  instagramFilterWidgets: {
    tags: {
      name: 'MediaFilterTagsWidget',
      label: 'Tags',
      noDuplicate: true,
      options: {
        tagsList: [
          {
            id: 1000,
            name: 'Cool',
            brandId: 1,
            color: 'red',
          },
          {
            id: 2000,
            name: 'Little',
            brandId: 1,
            color: 'orange',
          },
        ],
      },
    },
    keyword: {
      name: 'MediaFilterSearchWidget',
      label: 'Account Handle',
      noDuplicate: true,
      options: {
        inputOnly: true,
        inputOnlyOperator: FILTERS_OPERATORS.CONTAIN,
      },
    },
    is_business: {
      name: 'MediaFilterRadioWidget',
      label: 'Account Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { label: 'Professional Account', value: 'true' },
          { label: 'Personal Account', value: 'false' },
        ],
      },
    },
    connection: {
      name: 'MediaFilterCheckboxWidget',
      label: 'Connection Status',
      noDuplicate: true,
      options: {
        filterOptions: [
          {
            formattedLabel: connectionStatusTreatments[connectionStatus.NOT_INVITED].labelCopy,
            label: connectionStatusTreatments[connectionStatus.NOT_INVITED].labelCopy,
            value: connectionStatus.NOT_INVITED,
          },
          {
            formattedLabel: connectionStatusTreatments[connectionStatus.INVITATION_SENT].copy,
            label: connectionStatusTreatments[connectionStatus.INVITATION_SENT].copy,
            value: connectionStatus.INVITATION_SENT,
          },
          {
            label: connectionStatusTreatments[connectionStatus.ACCEPTED_AND_CONNECTED].labelCopy,
            formattedLabel:
              connectionStatusTreatments[connectionStatus.ACCEPTED_AND_CONNECTED].labelCopy,
            value: connectionStatus.ACCEPTED_AND_CONNECTED,
            info: 'Influencers who have accepted your invite with an active connection.',
          },
          {
            label: connectionStatusTreatments[connectionStatus.ACCEPTED_AND_DISCONNECTED].labelCopy,
            formattedLabel:
              connectionStatusTreatments[connectionStatus.ACCEPTED_AND_DISCONNECTED].labelCopy,
            value: connectionStatus.ACCEPTED_AND_DISCONNECTED,
            info: 'Influencers who have accepted your invite without a current active connection.',
          },
          {
            label: connectionStatusTreatments[connectionStatus.LAPSED].copy,
            value: connectionStatus.LAPSED,
            info: 'Influencers who have not responded to your invite in over 14 days.',
          },
        ],
      },
    },
    type: {
      name: 'MediaFilterRadioWidget',
      label: 'Content Type',
      noDuplicate: true,
      options: {
        filterOptions: [
          { label: 'Who Featured You', value: 'RIQ' },
          { label: "Who You've Featured", value: 'PIQ' },
        ],
      },
    },
  },
  creatorDiscoveryWidgets: {
    keywords: {
      name: 'MediaFilterKeywordsWidget',
      label: 'Keywords',
      icon: 'key',
      iconColour: '#adadad',
      noDuplicate: true,
      disableDelete: true,
      showNumberBadge: true,
      options: {
        itemName: 'creators',
        locationOptions: [
          { value: 'anywhere', text: 'Anywhere' },
          { value: 'caption', text: 'Caption' },
          { value: 'bio', text: 'Instagram Bio' },
        ],
      },
    },
  },
  creatorDiscoveryFilterMapping: {
    field: 'keywords',
    props: {
      allowEmpty: true,
      disableOpenOnMountIfValueNull: true,
    },
    value: [],
  },
  tagsFilterTypeOptions: [
    {
      value: LOGICAL_OPERATORS.OR,
      label: 'OR',
    },
    {
      value: LOGICAL_OPERATORS.AND,
      label: 'AND',
    },
  ],
  instagramSortOptions: [
    {
      value: metricKeys.PUBLISH_TIME,
      label: 'Recently Published',
    },
    {
      value: metricKeys.ADDED_TIME,
      label: 'Recently Added',
    },
    {
      value: metricKeys.TOTAL_EMV,
      label: 'Total EMV',
    },
    {
      value: metricKeys.TOTAL_FOLLOWERS_GAINED,
      label: 'Followers Gained',
    },
    {
      value: metricKeys.AVG_EFFECTIVENESS,
      label: 'Avg. Effectiveness Rate',
    },
    {
      value: metricKeys.AVG_ENGAGEMENT,
      label: 'Avg. Engagements',
    },
    {
      value: metricKeys.AVG_ESTIMATED_REACH,
      label: 'Avg. Reach',
    },
    {
      value: metricKeys.POST_COUNT,
      label: 'Posts feat. You',
    },
    {
      value: metricKeys.FOLLOWERS,
      label: 'Followers',
    },
  ],
  creatorDiscoverySortOptions: [
    {
      value: 'instagram.engagements',
      label: 'Avg. Engagements',
    },
    {
      value: 'instagram.engagement_rate',
      label: 'Avg. Engagement Rate',
    },
    {
      value: 'followers',
      label: 'Followers',
    },
  ],
  relationshipInfluencerBannerKey: 'relationships.influencer.hideBanner',
  instagramInfluencerPostsMetrics: ['likeCount', 'commentsCount'],
  instagramInfluencerPostsMetricLabels: {
    likeCount: 'Likes',
    commentsCount: 'Comments',
    impressions: 'Impressions',
  },
  instagramRelationshipPostMetrics: [
    'engagement',
    'effectiveness',
    'reach',
    'followersGained',
    'emv',
  ],
  instagramRelationshipPostMetricLabelsUnconnected: {
    engagement: 'Engagement Rate',
    effectiveness: 'Effectiveness',
    reach: 'Est. Reach',
    followersGained: 'Followers Gained',
    emv: 'EMV',
    totalEngagement: 'Total Engagements',
    likeCount: 'Likes',
    commentsCount: 'Comments',
    impressions: 'Impressions',
    saved: 'Saves',
  },
  instagramRelationshipPostMetricLabelsConnected: {
    engagement: 'Engagement Rate - Public',
    effectiveness: 'Effectiveness',
    reach: 'Reach',
    followersGained: 'Followers Gained',
    emv: 'EMV',
    totalEngagement: 'Total Engagements',
    likeCount: 'Likes',
    commentsCount: 'Comments',
    impressions: 'Impressions',
    saved: 'Saves',
  },
  instagramRelationshipPostSortValues,
  instagramRelationshipPostSortOptions: [
    {
      value: instagramRelationshipPostSortValues.TIMESTAMP,
      label: 'Recently Published',
    },
    {
      value: instagramRelationshipPostSortValues.EFFECTIVENESS,
      label: 'Effectiveness',
      field: 'effectiveness',
    },
    {
      value: instagramRelationshipPostSortValues.ENGAGEMENT,
      label: 'Engagement Rate',
      field: 'engagement',
    },
    {
      value: instagramRelationshipPostSortValues.REACH,
      label: 'Reach',
      field: 'reach',
    },
    {
      value: instagramRelationshipPostSortValues.TOTAL_ENGAGEMENTS,
      label: 'Total Engagements',
      field: 'totalEngagement',
    },
    {
      value: instagramRelationshipPostSortValues.LIKES,
      label: 'Likes',
      field: 'likeCount',
    },
    {
      value: instagramRelationshipPostSortValues.COMMENTS,
      label: 'Comments',
      field: 'commentsCount',
    },
    {
      value: instagramRelationshipPostSortValues.EMV,
      label: 'EMV',
      field: 'emv',
    },
    {
      value: instagramRelationshipPostSortValues.FOLLOWERS_GAINED,
      label: 'Followers Gained',
      field: 'followersGained',
    },
    {
      value: instagramRelationshipPostSortValues.IMPRESSIONS,
      label: 'Views',
      field: 'impressions',
    },
    {
      value: instagramRelationshipPostSortValues.SAVED,
      label: 'Saves',
      field: 'saved',
    },
  ],
  instagramRelationshipStorySortOptions: [
    {
      value: 'PUBLISH_TIME',
      label: 'Recently Published',
      field: 'date',
    },
    {
      value: 'REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'IMPRESSIONS',
      label: 'Views',
      field: 'impressions',
    },
    {
      value: 'EXITS',
      label: 'Exits',
      field: 'exits',
    },
    {
      value: 'EXIT_RATE',
      label: 'Exit Rate',
      field: 'exitRate',
    },
    {
      value: 'COMPLETION_RATE',
      label: 'Completion Rate',
      field: 'completionRate',
    },
  ],
  dateOptions: {
    ALL_TIME: { label: 'All Time', value: null },
    TWENTY_FOUR_HORUS: { label: 'Last 24 Hours', value: 1 },
    THREE_DAYS: { label: 'Last 3 Days', value: 3 },
    SEVEN_DAYS: { label: 'Last 7 Days', value: 7 },
    FOUR_WEEKS: { label: 'Last 4 Weeks', value: 28 },
  },
  dateOptionsArray: [
    { label: 'All Time', value: null },
    { label: 'Last 24 Hours', value: 1 },
    { label: 'Last 3 Days', value: 3 },
    { label: 'Last 7 Days', value: 7 },
    { label: 'Last 4 Weeks', value: 28 },
  ],
  reportDateRangeFormats: {
    REPORT_PAGE_FORMAT: 'MMM D, YYYY',
    REPORT_NAME_FORMAT: 'DDMMMYYYY',
  },
  reportFilterDefaultValues: {
    ORDER_ASC: 'Lowest to Highest',
    ORDER_DESC: 'Highest to Lowest',
    NO_DATE_RANGE: 'All Time',
  },
  reportFilterLabels: {
    DATE_RANGE: 'Published On',
    ORDER: 'Order',
    SORT: 'Sort By',
  },
  pdfReportTypes: {
    OVERVIEW: 'relationshipsOverview',
    DETAIL: 'relationshipsDetail',
  },
  reportConfigDefaults: {
    idleMaxInflightRequest: 1,
    idleTimeout: 10,
    width: '1120px',
    height: '1200px',
    landscape: false,
  },
  csvReportTypes: {
    RELATIONSHIPS_OVERVIEW: 'relationshipsOverview',
    RELATIONSHIPS_OVERVIEW_POSTS: 'relationshipsAllPostsDetails',
    RELATIONSHIP_POSTS: 'relationshipPosts',
    RELATIONSHIP_STORY_POSTS: 'instagramUGCStoryPosts',
  },
  csvOverviewReportVariants: {
    HANDLES: 'RELATIONSHIP_HANDLES',
    POSTS: 'RELATIONSHIP_POSTS',
  },
  connectionStatus,
  connectionStatusTreatments,
  detailPageDateFormat: 'MMM D, YYYY h:mmA',
  disconnectConfirmation: {
    title: 'Disconnect Account?',
    message:
      "Disconnecting will revoke this influencer's invite acceptance and you will no longer be able to access stories and some metrics for this account.",
  },
  facebookDisconnected: {
    message:
      'account has been disconnected. You will not receive stories or true reach until reconnection. An invitation has been sent.',
  },
  emptyRelationship: {
    title: 'No Relationships',
    message:
      'No tracked Relationships can be displayed with your selected filters or timeframe. Adjust and try again.',
  },
  removeAccountClicked: {
    title: 'Remove Account from Your Creators?',
    message: 'Removing this account will also stop tracking content featuring you.',
  },
  emptyCreators: {
    message:
      'No creators found matching this criteria.<br>Please change or remove some of the filters.',
  },
  connectionRevokedMessage:
    'This account has revoked their acceptance. Please send another invite if you would like them to reconnect.',
  connectionRevokedBannerKeyPrefix: 'relationships.connectionRevoked.hideBanner',
  brandDisconnectedBannerKey: 'relationships.brandDisconnected.hideBanner',
  pageOffsetCacheKey: 'relationships.pageOffset',
  feedPostLimit: 20,
  feedPostReportLimit: 120,
  maxTagsPerTile: 10,
  creatorSources,
  addToYourCreatorsButtonText: 'Add to Your Creators',
  addToYourCreatorsSuccessMessage: 'Account added to Your Creators.',
  addToYourCreatorsFailureMessage:
    'Unable to add account to Your Creators. Please try again later.',
  addToYourCreatorsFailureBecauseAlreadyAddedMessage:
    'This account has already been added to Your Creators.',
  creatorPreviewStatsTooltips: {
    FOLLOWERS: 'The total number of followers this creator has on Instagram.',
    AVG_WEEKLY_POSTS:
      "The average number of posts published per week. This is a 12 week rolling average across this creator's most recent posts.",
    AVG_ENGAGEMENT_RATE: `The average engagement rate per post. ${tooltipSuffixes.AVG_ENGAGEMENT_RATE}`,
    AVG_ENGAGEMENTS:
      "The average number of engagements per post. This is a 12 week rolling average across this creator's most recent posts.<br><br>Engagements = Likes + Comments",
    AVG_EFFECTIVENESS: `The average effectiveness per post. ${tooltipSuffixes.AVG_EFFECTIVENESS}`,
    AVG_ESTIMATED_REACH:
      "The average estimated reach per post. This is a 12 week rolling average across this creator's most recent posts.",
  },
  creatorPreviewStatsInflatedTooltips: {
    AVG_ENGAGEMENT_RATE: `This engagement rate may be inflated due to an outlier within the current measurement period. ${tooltipSuffixes.AVG_ENGAGEMENT_RATE}`,
    AVG_EFFECTIVENESS: `This effectiveness rate may be inflated due to an outlier within the current measurement period. ${tooltipSuffixes.AVG_EFFECTIVENESS}`,
    MEDIA_CARD_ENG_RATE: 'This post may be trending due to a spike in engagements.',
  },
  trendingMetricThreshold: 3,
  creatorLocationProcessing: {
    message: 'processing region...',
    tooltip: `Processing the region of this creator.`,
  },
  creatorLocationUnknown: {
    message: '-',
    tooltip: `Unable to estimate the region of this creator.`,
  },
  creatorInterestProcessing: {
    message: 'processing interests...',
    tooltip: `Processing the interests of this creator.`,
  },
  creatorInterestUnknown: {
    message: '-',
    tooltip: `Unable to estimate the interests of this creator.`,
  },
  creatorMentionedBadgeTooltip: 'This creator has mentioned you',
  professionalAccountBadgeTooltip: 'Professional account',
  relationshipsNoConnectionTitle: 'Instagram Connection Required',
  relationshipsNoConnectionMessage:
    'In order to view Instagram creators you must have an Instagram account connected to Dash Social.',
};

export const relationshipRouteNames = {
  IG_OVERVIEW_PAGE: 'relationships.instagram',
  IG_OVERVIEW_REPORT_PAGE: 'relationships.instagram.report.main',
  IG_DETAIL_PAGE: 'relationships.instagram.detail',
  IG_DETAIL_REPORT_PAGE: 'relationships.instagram.detail.report',
  IG_YOUR_CREATORS_PAGE: 'relationships.instagram.your-creators',
  IG_CREATOR_DISCOVERY_PAGE: 'relationships.instagram.discovery',
  IG_INFLUENCER_PAGE: 'relationships.influencer',
  IG_CREATOR_PREVIEW_PAGE: 'relationships.instagram.creator-preview',
  TT_CREATOR_DISCOVERY_PAGE: 'creators.tiktok.discovery',
};

export const visualSearchMediaDropdownText = {
  header: 'Find Creators Posting Similar Media',
  subHeading:
    'Select a piece of media to discover creators that are posting visually similar content',
  chooseMediaButtonText: 'Select Media',
  uploadLinkText: 'Upload an Image',
};

export const discoveryInterests = [
  {
    type: 'category',
    label: 'Animals and Pets',
    value: 'Animals and Pets',
    items: [
      { type: 'item', label: 'Animals', value: 'Animals' },
      { type: 'item', label: 'Cats', value: 'Cats' },
      { type: 'item', label: 'Dogs', value: 'Dogs' },
      { type: 'item', label: 'Pets', value: 'Pets' },
    ],
  },
  {
    type: 'category',
    label: 'Arts and Music',
    value: 'Arts and Music',
    items: [
      { type: 'item', label: 'Acting', value: 'Acting' },
      { type: 'item', label: 'Art', value: 'Art' },
      { type: 'item', label: 'Ceramics', value: 'Ceramics' },
      { type: 'item', label: 'Country Music', value: 'Country Music' },
      { type: 'item', label: 'Dance', value: 'Dance' },
      { type: 'item', label: 'Hip Hop', value: 'Hip Hop' },
      { type: 'item', label: 'Jazz', value: 'Jazz' },
      { type: 'item', label: 'Music', value: 'Music' },
      { type: 'item', label: 'Painting', value: 'Painting' },
      { type: 'item', label: 'Photography', value: 'Photography' },
      { type: 'item', label: 'Singing', value: 'Singing' },
      { type: 'item', label: 'Tattoos', value: 'Tattoos' },
      { type: 'item', label: 'Theater', value: 'Theater' },
      { type: 'item', label: 'Writing', value: 'Writing' },
      { type: 'item', label: 'Concerts', value: 'Concerts' },
    ],
  },
  {
    type: 'category',
    label: 'Automotive',
    value: 'Automotive',
    items: [
      { type: 'item', label: 'Cars', value: 'Cars' },
      { type: 'item', label: 'Motorcycles', value: 'Motorcycles' },
    ],
  },
  {
    type: 'category',
    label: 'Beauty and Esthetics',
    value: 'Beauty and Esthetics',
    items: [
      { type: 'item', label: 'Beauty', value: 'Beauty' },
      { type: 'item', label: 'Botox', value: 'Botox' },
      { type: 'item', label: 'Fragrance', value: 'Fragrance' },
      { type: 'item', label: 'Hair', value: 'Hair' },
      { type: 'item', label: 'Hairstyling', value: 'Hairstyling' },
      { type: 'item', label: 'Makeup', value: 'Makeup' },
      { type: 'item', label: 'Nails', value: 'Nails' },
      { type: 'item', label: 'Skincare', value: 'Skincare' },
    ],
  },
  {
    type: 'category',
    label: 'Business and Entrepreneurship',
    value: 'Business and Entrepreneurship',
    items: [
      { type: 'item', label: 'Business', value: 'Business' },
      { type: 'item', label: 'Entrepreneurship', value: 'Entrepreneurship' },
      { type: 'item', label: 'Leadership', value: 'Leadership' },
      { type: 'item', label: 'Marketing', value: 'Marketing' },
      { type: 'item', label: 'Real Estate', value: 'Real Estate' },
      { type: 'item', label: 'Small Business', value: 'Small Business' },
      { type: 'item', label: 'Graphic Design', value: 'Graphic Design' },
    ],
  },
  {
    type: 'category',
    label: 'Design and Home',
    value: 'Design and Home',
    items: [
      { type: 'item', label: 'DIY', value: 'DIY' },
      { type: 'item', label: 'Architecture', value: 'Architecture' },
      { type: 'item', label: 'Design', value: 'Design' },
      { type: 'item', label: 'Floral Design', value: 'Floral Design' },
      { type: 'item', label: 'Home Decor', value: 'Home Decor' },
      { type: 'item', label: 'Interior Design', value: 'Interior Design' },
      { type: 'item', label: 'Renovation', value: 'Renovation' },
    ],
  },
  {
    type: 'category',
    label: 'Media and Entertainment',
    value: 'Media and Entertainment',
    items: [
      { type: 'item', label: 'Books', value: 'Books' },
      { type: 'item', label: 'Comedy', value: 'Comedy' },
      { type: 'item', label: 'Entertainment', value: 'Entertainment' },
      { type: 'item', label: 'Events', value: 'Events' },
      { type: 'item', label: 'Festivals', value: 'Festivals' },
      { type: 'item', label: 'Gaming', value: 'Gaming' },
      { type: 'item', label: 'Movies', value: 'Movies' },
      { type: 'item', label: 'Podcasting', value: 'Podcasting' },
      { type: 'item', label: 'Reality TV', value: 'Reality TV' },
      { type: 'item', label: 'Toys', value: 'Toys' },
      { type: 'item', label: 'TV Shows', value: 'TV Shows' },
    ],
  },
  {
    type: 'category',
    label: 'Family and Relationships',
    value: 'Family and Relationships',
    items: [
      { type: 'item', label: 'Family', value: 'Family' },
      { type: 'item', label: 'Friendship', value: 'Friendship' },
      { type: 'item', label: 'LGBTQ+', value: 'LGBTQ+' },
      { type: 'item', label: 'Marriage', value: 'Marriage' },
      { type: 'item', label: 'Motherhood', value: 'Motherhood' },
      { type: 'item', label: 'Parenting', value: 'Parenting' },
      { type: 'item', label: 'Pregnancy', value: 'Pregnancy' },
      { type: 'item', label: 'Relationships', value: 'Relationships' },
      { type: 'item', label: 'Weddings', value: 'Weddings' },
    ],
  },
  {
    type: 'category',
    label: 'Clothing and Accessories',
    value: 'Clothing and Accessories',
    items: [
      { type: 'item', label: 'Accessories', value: 'Accessories' },
      { type: 'item', label: 'Activewear', value: 'Activewear' },
      { type: 'item', label: 'Fashion', value: 'Fashion' },
      { type: 'item', label: 'Jewelry', value: 'Jewelry' },
      { type: 'item', label: 'Lingerie', value: 'Lingerie' },
      { type: 'item', label: "Men's Fashion", value: "Men's Fashion" },
      { type: 'item', label: 'Shoes', value: 'Shoes' },
      { type: 'item', label: 'Streetwear', value: 'Streetwear' },
      { type: 'item', label: 'Sustainable Fashion', value: 'Sustainable Fashion' },
      { type: 'item', label: 'Swimwear', value: 'Swimwear' },
      { type: 'item', label: 'Watches', value: 'Watches' },
    ],
  },
  {
    type: 'category',
    label: 'Fitness and Training',
    value: 'Fitness and Training',
    items: [
      { type: 'item', label: 'Barre', value: 'Barre' },
      { type: 'item', label: 'Bodybuilding', value: 'Bodybuilding' },
      { type: 'item', label: 'Crossfit', value: 'Crossfit' },
      { type: 'item', label: 'Fitness', value: 'Fitness' },
      { type: 'item', label: 'Gym', value: 'Gym' },
      { type: 'item', label: 'Personal Training', value: 'Personal Training' },
      { type: 'item', label: 'Pilates', value: 'Pilates' },
      { type: 'item', label: 'Running', value: 'Running' },
      { type: 'item', label: 'Strength Training', value: 'Strength Training' },
      { type: 'item', label: 'Yoga', value: 'Yoga' },
    ],
  },
  {
    type: 'category',
    label: 'Food and Beverage',
    value: 'Food and Beverage',
    items: [
      { type: 'item', label: 'Baking', value: 'Baking' },
      { type: 'item', label: 'Cocktails', value: 'Cocktails' },
      { type: 'item', label: 'Coffee', value: 'Coffee' },
      { type: 'item', label: 'Cooking', value: 'Cooking' },
      { type: 'item', label: 'Desserts', value: 'Desserts' },
      { type: 'item', label: 'Food', value: 'Food' },
      { type: 'item', label: 'Gluten-free', value: 'Gluten-free' },
      { type: 'item', label: 'Healthy Eating', value: 'Healthy Eating' },
      { type: 'item', label: 'Recipes', value: 'Recipes' },
      { type: 'item', label: 'Tea', value: 'Tea' },
      { type: 'item', label: 'Vegan', value: 'Vegan' },
      { type: 'item', label: 'Wine', value: 'Wine' },
    ],
  },
  {
    type: 'category',
    label: 'Health and Wellness',
    value: 'Health and Wellness',
    items: [
      { type: 'item', label: 'Body Positivity', value: 'Body Positivity' },
      { type: 'item', label: 'Gut Health', value: 'Gut Health' },
      { type: 'item', label: 'Health', value: 'Health' },
      { type: 'item', label: 'Holistic Health', value: 'Holistic Health' },
      { type: 'item', label: 'Meditation', value: 'Meditation' },
      { type: 'item', label: 'Mental Health', value: 'Mental Health' },
      { type: 'item', label: 'Nutrition', value: 'Nutrition' },
      { type: 'item', label: 'Personal Growth', value: 'Personal Growth' },
      { type: 'item', label: 'Self-care', value: 'Self-care' },
      { type: 'item', label: 'Supplements', value: 'Supplements' },
      { type: 'item', label: 'Wellness', value: 'Wellness' },
    ],
  },
  { type: 'item', label: 'Luxury', value: 'Luxury' },
  {
    type: 'category',
    label: 'Nature and Outdoor Activities',
    value: 'Nature and Outdoor Activities',
    items: [
      { type: 'item', label: 'Beaches', value: 'Beaches' },
      { type: 'item', label: 'Flowers', value: 'Flowers' },
      { type: 'item', label: 'Mountains', value: 'Mountains' },
      { type: 'item', label: 'Nature', value: 'Nature' },
      { type: 'item', label: 'Plants', value: 'Plants' },
      { type: 'item', label: 'Camping', value: 'Camping' },
      { type: 'item', label: 'Fishing', value: 'Fishing' },
      { type: 'item', label: 'Gardening', value: 'Gardening' },
      { type: 'item', label: 'Hiking', value: 'Hiking' },
      { type: 'item', label: 'Hunting', value: 'Hunting' },
      { type: 'item', label: 'Outdoors', value: 'Outdoors' },
    ],
  },
  {
    type: 'category',
    label: 'Political and Social',
    value: 'Political and Social',
    items: [
      { type: 'item', label: 'Activism', value: 'Activism' },
      { type: 'item', label: 'Charity', value: 'Charity' },
      { type: 'item', label: 'Culture', value: 'Culture' },
      { type: 'item', label: 'Education', value: 'Education' },
      { type: 'item', label: 'Faith', value: 'Faith' },
      { type: 'item', label: 'History', value: 'History' },
      { type: 'item', label: 'Politics', value: 'Politics' },
      { type: 'item', label: 'Social Justice', value: 'Social Justice' },
      { type: 'item', label: 'Spirituality', value: 'Spirituality' },
      { type: 'item', label: 'Sustainability', value: 'Sustainability' },
    ],
  },
  {
    type: 'category',
    label: 'Sports and Athletics',
    value: 'Sports and Athletics',
    items: [
      { type: 'item', label: 'Basketball', value: 'Basketball' },
      { type: 'item', label: 'Boxing', value: 'Boxing' },
      { type: 'item', label: 'Cycling', value: 'Cycling' },
      { type: 'item', label: 'Football', value: 'Football' },
      { type: 'item', label: 'Golf', value: 'Golf' },
      { type: 'item', label: 'Skateboarding', value: 'Skateboarding' },
      { type: 'item', label: 'Skiing', value: 'Skiing' },
      { type: 'item', label: 'Snowboarding', value: 'Snowboarding' },
      { type: 'item', label: 'Soccer', value: 'Soccer' },
      { type: 'item', label: 'Sports', value: 'Sports' },
      { type: 'item', label: 'Surfing', value: 'Surfing' },
      { type: 'item', label: 'Tennis', value: 'Tennis' },
      { type: 'item', label: 'Wrestling', value: 'Wrestling' },
    ],
  },
  { type: 'item', label: 'Technology', value: 'Technology' },
  { type: 'item', label: 'Travel', value: 'Travel' },
];

export const discoveryLocations = [
  { type: 'item', label: 'Argentine', value: 'Argentina' },
  { type: 'item', label: 'Australia', value: 'Australia' },
  { type: 'item', label: 'Austria', value: 'Austria' },
  { type: 'item', label: 'Belgium', value: 'Belgium' },
  { type: 'item', label: 'Brazil', value: 'Brazil' },
  { type: 'item', label: 'Canada', value: 'Canada' },
  { type: 'item', label: 'Chile', value: 'Chile' },
  { type: 'item', label: 'China', value: 'China' },
  { type: 'item', label: 'Colombia', value: 'Colombia' },
  { type: 'item', label: 'Costa Rica', value: 'Costa Rica' },
  { type: 'item', label: 'Croatia', value: 'Croatia' },
  { type: 'item', label: 'Czech Republic', value: 'Czech Republic' },
  { type: 'item', label: 'Denmark', value: 'Denmark' },
  { type: 'item', label: 'Dominican Republic', value: 'Dominican Republic' },
  { type: 'item', label: 'Finland', value: 'Finland' },
  { type: 'item', label: 'France', value: 'France' },
  { type: 'item', label: 'Germany', value: 'Germany' },
  { type: 'item', label: 'Greece', value: 'Greece' },
  { type: 'item', label: 'Hungary', value: 'Hungary' },
  { type: 'item', label: 'India', value: 'India' },
  { type: 'item', label: 'Indonesia', value: 'Indonesia' },
  { type: 'item', label: 'Ireland', value: 'Ireland' },
  { type: 'item', label: 'Italy', value: 'Italy' },
  { type: 'item', label: 'Israel', value: 'Israel' },
  { type: 'item', label: 'Japan', value: 'Japan' },
  { type: 'item', label: 'Kuwait', value: 'Kuwait' },
  { type: 'item', label: 'Lebanon', value: 'Lebanon' },
  { type: 'item', label: 'Malaysia', value: 'Malaysia' },
  { type: 'item', label: 'Mexico', value: 'Mexico' },
  { type: 'item', label: 'Morocco', value: 'Morocco' },
  { type: 'item', label: 'Netherlands', value: 'Netherlands' },
  { type: 'item', label: 'New Zealand', value: 'New Zealand' },
  { type: 'item', label: 'Nigeria', value: 'Nigeria' },
  { type: 'item', label: 'Norway', value: 'Norway' },
  { type: 'item', label: 'Pakistan', value: 'Pakistan' },
  { type: 'item', label: 'Philippines', value: 'Philippines' },
  { type: 'item', label: 'Poland', value: 'Poland' },
  { type: 'item', label: 'Portugal', value: 'Portugal' },
  { type: 'item', label: 'Romania', value: 'Romania' },
  { type: 'item', label: 'Russia', value: 'Russia' },
  { type: 'item', label: 'Serbia', value: 'Serbia' },
  { type: 'item', label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { type: 'item', label: 'Singapore', value: 'Singapore' },
  { type: 'item', label: 'South Africa', value: 'South Africa' },
  { type: 'item', label: 'South Korea', value: 'South Korea' },
  { type: 'item', label: 'Spain', value: 'Spain' },
  { type: 'item', label: 'Sweden', value: 'Sweden' },
  { type: 'item', label: 'Switzerland', value: 'Switzerland' },
  { type: 'item', label: 'Taiwan', value: 'Taiwan' },
  { type: 'item', label: 'Thailand', value: 'Thailand' },
  { type: 'item', label: 'Turkey', value: 'Turkey' },
  { type: 'item', label: 'Ukraine', value: 'Ukraine' },
  { type: 'item', label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { type: 'item', label: 'United Kingdom', value: 'United Kingdom' },
  { type: 'item', label: 'United States', value: 'United States' },
  { type: 'item', label: 'Venezuela', value: 'Venezuela' },
  { type: 'item', label: 'Vietnam', value: 'Vietnam' },
];

export const salesTerritoryMap = Object.freeze({
  Beauty: 'Beauty and Esthetics',
  Fashion: 'Clothing and Accessories',
  Publishing: 'Media and Entertainment',
  Retail: 'Clothing and Accessories',
  'Media & Entertainment': 'Media and Entertainment',
  Automotive: 'Automotive',
  'Consumer Electronics': 'Technology',
  CPG: 'Consumer Packaged Goods',
  'Financial Services': 'Financial Services',
  Home: 'Design and Home',
  'Luxury Goods & Jewelry': 'Luxury',
  'Wellness, Lifestyle, and Healthcare': 'Health and Wellness',
  'Travel, Tourism & Hospitality': 'Travel',
  Technology: 'Technology',
  'Non Profit': 'Political and Social',
});
