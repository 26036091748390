/* eslint-disable camelcase */
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import humps from 'humps';

import {
  BOARD_LIMIT_PERMISSIONS,
  guard as permissionsGuard,
  guardAll,
} from '@/models/auth/permissions.enum';
import {
  browserStorageGetItem,
  browserStorageRemoveItem,
  browserStorageSetItem,
} from '@/utils/browserStorage';

export const useIdentityStore = defineStore('identity', () => {
  const identity = ref(null);
  const subscriptions = ref(null);
  const actionMetadata = ref(null);
  const currentBrandLabel = ref(browserStorageGetItem('currentBrand'));

  const canOnlyAccessInactiveBrand = computed(() => {
    return (
      (identity.value?.accessible_brands ?? []).length === 0 &&
      Object.keys(identity.value?.inactive_brands ?? {}).length === 1
    );
  });

  const currentBrand = computed(() => {
    if (identity.value?.brands[currentBrandLabel.value]) {
      return identity.value?.brands[currentBrandLabel.value];
    }
    if (canOnlyAccessInactiveBrand.value) {
      return identity.value?.inactive_brands[currentBrandLabel.value];
    }
    return null;
  });

  const allUserBrands = computed(() => {
    const identityBrands = identity.value?.brands || {};
    return Object.values(identityBrands);
  });

  const allActiveUserBrands = computed(() => {
    return Object.values(identity.value?.accessible_brands || [])
      .map((brandId) => {
        return allUserBrands.value.find((brand) => brand.id === brandId);
      })
      .filter((brand) => brand?.isActive);
  });

  const currentBrandSubscriptions = computed(() => {
    if (!currentBrand.value) {
      return null;
    }
    return subscriptions.value?.brand?.[currentBrand.value.id];
  });

  const currentOrganizationSubscriptions = computed(() => {
    if (!currentBrand.value) {
      return null;
    }
    return subscriptions.value?.organization?.[currentBrand.value.organization?.id];
  });

  const currentSubscriptions = computed(() => {
    if (!currentBrand.value) {
      return null;
    }
    return [
      ...(currentBrandSubscriptions.value || []),
      ...(currentOrganizationSubscriptions.value || []),
    ];
  });

  const brand_can = computed(() => {
    return (module, permission, customBrand = null) => {
      const brand = customBrand || currentBrand.value;
      return brand?.permissions?.[module] ? brand.permissions[module][permission] : false;
    };
  });
  const user_can = computed(() => {
    return (module, permission) => {
      const brand = currentBrand.value;
      return brand && identity.value?.permissions?.[module]?.[brand.label]?.[permission];
    };
  });

  const identityBrandsById = computed(() => {
    const a = Object.keys(identity.value?.brands ?? {}).reduce((acc, brandLabel) => {
      const brand = identity.value.brands[brandLabel];
      acc[brand?.id] = brand;
      return acc;
    }, {});
    return a;
  });
  const isSuperAdmin = computed(() => {
    return identity.value && identity.value.is_superadmin;
  });

  const canAccessOrganic = computed(() => {
    return (
      user_can.value('scheduler', 'can_access_scheduler') ||
      user_can.value('pinterest', 'can_access_pint') ||
      user_can.value('instagram', 'can_access_instagram')
    );
  });
  const canAccessVision = computed(() => {
    return (
      brand_can.value('vision', 'can_access_vision') &&
      user_can.value('vision', 'can_access_vision')
    );
  });
  const canAccessStoriesSwipeups = computed(() => {
    return user_can.value('instagram', 'can_access_stories_swipeup');
  });
  const brandCanAccessScheduler = computed(() => {
    return (platform) => {
      return (
        platform !== 'tiktok' || brand_can.value('scheduler', `can_access_scheduler_${platform}`)
      );
    };
  });
  const canAccessSchedulerPlatform = computed(() => {
    return (platform) => {
      const name =
        {
          instagram: 'ig',
          pinterest: 'pint',
          twitter: 'twit',
          facebook: 'fb',
        }[platform] ?? platform;
      return (
        brandCanAccessScheduler.value(platform) &&
        user_can.value('scheduler', 'can_access_scheduler') &&
        user_can.value('scheduler', `can_access_scheduler_${name}`)
      );
    };
  });
  const canAccessAutoPublish = computed(() => {
    return (platform) => {
      const name =
        {
          twitter: 'twit',
        }[platform] ?? platform;
      return (
        canAccessSchedulerPlatform.value(platform) &&
        (['tiktok', 'pinterest'].includes(platform) ||
          brand_can.value('scheduler', `can_auto_publish_${name}`)) &&
        user_can.value('scheduler', `can_auto_publish_${name}`)
      );
    };
  });
  const brandCanAccessIndustryBenchmarks = computed(() => {
    return brand_can.value('dashboard', 'can_access_industry_benchmarks');
  });

  const hasBoardsAccess = computed(() => {
    return (boardType) => {
      const brand = currentBrand.value;
      const { module, permission } = BOARD_LIMIT_PERMISSIONS[boardType];
      const value =
        brand && identity.value?.brands?.[brand.label]?.permissions?.[module]?.[permission];
      return value > 0;
    };
  });
  const currentBrandRole = computed(() => {
    const brandRoles = identity.value?.permissions?.brand_roles ?? [];
    return brandRoles.find((brandRole) => brandRole.brand_id === currentBrand.value?.id)?.role;
  });
  const currentPlanType = computed(() => {
    return currentBrand.value?.plan?.plan_type;
  });
  const isCurrentBrandRoleAdmin = computed(() => {
    return currentBrandRole.value === 'admin';
  });
  const identityBrandIds = computed(() => {
    if (Object.keys(identity.value?.brands ?? {}).length > 0) {
      return Object.keys(identity.value?.brands).map(
        (brandLabel) => identity.value.brands[brandLabel].id,
      );
    }
    if (canOnlyAccessInactiveBrand.value) {
      return [currentBrand.value.id];
    }
    return [];
  });

  const canAccessMultipleBrands = computed(() => {
    return (identity.value?.accessible_brands ?? []).length > 1;
  });

  function extractBrands(incomingIdentity) {
    // Only store a subset of brand fields, converting some to camel-case
    // TODO: We should probably just use humps to do this and include all brand fields but we have
    // code that relies on this mix of snake & camel case properties.
    return Object.keys(incomingIdentity?.brands ?? {}).reduce((brandsMap, key) => {
      const {
        avatar_url: avatarUrl,
        created_at: createdAt,
        id,
        label,
        name,
        organization_id: organizationId,
        organization,
        permissions,
        plan,
        paragon_jwt_token: paragonJwtToken,
        stripe_subscription_id: stripeSubscriptionId,
        stripe_subscription_status: stripeSubscriptionStatus,
        stripe_subscription_billing_interval: stripeSubscriptionBillingInterval,
        stripe_subscription_current_period_end: stripeSubscriptionCurrentPeriodEnd,
        is_active: isActive,
        connect_pinterest_v5_token: connectPinterestV5Token,
        tags,
      } = incomingIdentity.brands[key];
      return {
        ...brandsMap,
        [key]: {
          avatarUrl,
          createdAt,
          id,
          label,
          name,
          organizationId,
          organization,
          permissions,
          plan,
          paragonJwtToken,
          stripeSubscriptionId,
          stripeSubscriptionStatus,
          stripeSubscriptionBillingInterval,
          stripeSubscriptionCurrentPeriodEnd,
          isActive,
          connectPinterestV5Token,
          tags: tags?.map((tag) => humps.camelizeKeys(tag)).reverse(),
        },
      };
    }, {});
  }

  function guard(permissionObject, customBrand = null) {
    const brand = customBrand ?? currentBrand.value;
    return Array.isArray(permissionObject)
      ? guardAll(identity.value, brand, permissionObject)
      : permissionsGuard(identity.value, brand, permissionObject);
  }

  function guardSome(permissionObjects, targetBrand = null) {
    const brand = targetBrand ?? currentBrand.value;
    return permissionObjects.some((permissionObject) => guard(permissionObject, brand));
  }

  function gtmTrack() {
    window.dataLayer?.push({
      event: 'Brand Change',
      location: identity?.value?.time_zone_name,
      brandId: currentBrand.value?.id,
      brandName: currentBrandLabel?.value,
      orgId: identity?.value?.organization_id,
      orgName: identity?.value?.organization.name,
      jobTitle: identity?.value?.job_title,
      plan: currentPlanType?.value,
      userId: identity?.value?.id,
      userEmail: identity?.value?.email,
    });
  }

  function setCurrentBrand({ brandLabel }) {
    browserStorageSetItem('currentBrand', brandLabel);
    currentBrandLabel.value = brandLabel;
    gtmTrack();
  }

  function clearCurrentBrand() {
    browserStorageRemoveItem('currentBrand');
    currentBrandLabel.value = null;
  }

  function setIdentity({ identity: newIdentity }) {
    const identityBrandLabels = Object.keys(newIdentity?.brands ?? {});
    const inactiveBrandLabels = Object.keys(newIdentity?.inactive_brands ?? {});
    identity.value = { ...newIdentity, brands: extractBrands(newIdentity) };

    // Initialize current brand to first brand in user's list of accessible brands (priority) or
    // inactive brands if there isn't one already selected.
    if (canOnlyAccessInactiveBrand.value) {
      setCurrentBrand({ brandLabel: inactiveBrandLabels[0] });
    } else if (
      (!currentBrandLabel.value || !identityBrandLabels.includes(currentBrandLabel.value)) &&
      identityBrandLabels.length > 0
    ) {
      setCurrentBrand({ brandLabel: identityBrandLabels[0] });
    }
  }

  function setSubscriptions(payload) {
    if (payload) {
      subscriptions.value = payload;
    }
  }

  function setActionMetadata(payload) {
    if (payload) {
      actionMetadata.value = payload;
    }
  }

  function updateIdentity({ data }) {
    const updatedData = Object.keys(data);
    updatedData.forEach((item) => {
      identity.value[item] = data[item];
    });
  }

  function clearIdentity() {
    identity.value = null;
  }

  async function setCurrentBrandByAttributes({ brand }) {
    identity.value = {
      ...identity.value,
      brands: {
        'Token User': brand,
      },
    };
    currentBrandLabel.value = 'Token User';
    // to make brand id accessible from currentBrand getter, i.e., currentBrand: state =>
    // identity?.brands[currentBrandLabel.value].
  }

  function checkIfBrandPermissionsExist(permissions, brands = null) {
    const identityBrands = brands ?? identity.value?.brands ?? {};
    if (permissions) {
      return Object.values(identityBrands).some((brand) =>
        permissions.every((permissionObject) => {
          const module =
            permissionObject.module?.value?.toLowerCase() ??
            permissionObject.module?.toLowerCase() ??
            '';
          const permission = permissionObject?.value?.toLowerCase();
          return !!brand?.permissions?.[module]?.[permission];
        }),
      );
    }
    return true;
  }

  function brandIdInIdentityBrands(brandId) {
    return identityBrandIds.value.includes(brandId);
  }

  function checkIfUserPermissionsExist(permissions, brands = null) {
    const identityBrands = brands || identity.value?.brands || {};
    if (permissions) {
      return Object.values(identityBrands).some((brand) =>
        permissions.every((permissionObject) => {
          const module = permissionObject?.module?.value?.toLowerCase();
          const permission = permissionObject?.value?.toLowerCase();
          return !!identity.value?.permissions?.[module]?.[brand.label]?.[permission];
        }),
      );
    }
    return false;
  }

  function filterBrands(search) {
    const stringToMatch = search?.toLowerCase() ?? '';
    return Object.values(identity.value?.brands ?? {}).filter((brand) => {
      const matchesBrandName = brand?.name?.toLowerCase()?.includes(stringToMatch);
      const matchesBrandLabel = brand?.label?.toLowerCase()?.includes(stringToMatch);
      return matchesBrandName || matchesBrandLabel;
    });
  }

  return {
    allUserBrands,
    identity,
    subscriptions,
    actionMetadata,
    currentBrandLabel,
    identityBrandsById,
    isSuperAdmin,
    currentBrand,
    currentBrandSubscriptions,
    currentOrganizationSubscriptions,
    currentSubscriptions,
    canAccessOrganic,
    canAccessVision,
    canAccessStoriesSwipeups,
    brandCanAccessScheduler,
    canAccessSchedulerPlatform,
    canAccessAutoPublish,
    brandCanAccessIndustryBenchmarks,
    brand_can,
    user_can,
    hasBoardsAccess,
    currentBrandRole,
    currentPlanType,
    isCurrentBrandRoleAdmin,
    identityBrandIds,
    canOnlyAccessInactiveBrand,
    canAccessMultipleBrands,
    allActiveUserBrands,
    guard,
    guardSome,
    setCurrentBrand,
    clearCurrentBrand,
    setIdentity,
    setSubscriptions,
    setActionMetadata,
    updateIdentity,
    clearIdentity,
    setCurrentBrandByAttributes,
    checkIfBrandPermissionsExist,
    checkIfUserPermissionsExist,
    filterBrands,
    brandIdInIdentityBrands,
  };
});
