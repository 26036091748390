<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import UtmPreviewBox from '@/app/settings/components/Utm/UtmPreviewBox.vue';
import UtmAutocomplete from '@/app/settings/components/Utm/UtmAutocomplete.vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import { LibraryAPI } from '@/apis/index';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
import Button from '@/components/foundation/Button.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import {
  decodeUtmErrorMessage,
  noUtmEmptyStateMessage,
  utmScheduleEditTrackingTitle,
  utmNotEditableTooltip,
} from '@/app/settings/components/Utm/const';
import { parseQuery, stringifyQuery } from '@/utils/query';
import { decodeDynamicValuesInUrl, sortSettings } from '@/app/settings/components/Utm/utils';
import { trackSchedulerUtmCustomize } from '@/app/scheduler/mixpanel';

// Constants
const { decodeUtmSettings } = LibraryAPI;

// Hooks / Composables
const authStore = useAuthStore();
const { currentBrand } = storeToRefs(authStore);

const notificationStore = useNotificationStore();
const { setToast } = notificationStore;

const router = useRouter();

// Props
const props = defineProps({
  url: { type: String, required: true },
  channel: { type: String, required: true },
  brandChannelUtmSettings: { type: Object, required: true },
  linkTitle: { type: String, default: '' },
  linkNumber: { type: Number, default: undefined },
  trackingData: { type: Object, default: null },
});

// Emits
const emits = defineEmits(['trackingParametersUpdated', 'close']);

// Refs
const decodedTrackingParameters = ref({});
const trackingParameters = ref({});
const previousTrackingParameters = ref({});
const apiRequestError = ref(false);
const apiRequestLoading = ref(false);

// Computed
const emptyStateMessage = computed(() => {
  if (apiRequestError.value) {
    return decodeUtmErrorMessage;
  }
  if (Object.keys(trackingParameters.value).length === 0) {
    return noUtmEmptyStateMessage;
  }
  return null;
});

const formattedTrackingParameters = computed(() => {
  return Object.keys(trackingParameters.value).reduce((acc, key) => {
    acc[key] = trackingParameters.value[key].value;
    return acc;
  }, {});
});

const urlWithQueryParameters = computed(() => {
  // We want the decoded query parameters, so we can show the literal value in the input
  const queryStringStart = props.url.indexOf('?');
  const baseUrl = queryStringStart === -1 ? props.url : props.url.substring(0, queryStringStart);
  const existingQueryParams = queryStringStart === -1 ? {} : parseQuery(props.url, {}, true);

  const combinedQueryParameters = { ...existingQueryParams, ...formattedTrackingParameters.value };

  const queryString = `?${decodeDynamicValuesInUrl(
    stringifyQuery(combinedQueryParameters, false),
  )}`;
  return `${baseUrl}${queryString}`;
});

// Methods
function handleAutocompleteInput(parameter, newValue) {
  trackingParameters.value[parameter].value = newValue;
  const urlLinkCustomized = Object.entries(trackingParameters.value).some(
    ([param, { value }]) => props.brandChannelUtmSettings[param].value !== value,
  );
  // emits full URL and query parameters when needed
  emits(
    'trackingParametersUpdated',
    urlWithQueryParameters.value,
    formattedTrackingParameters.value,
    urlLinkCustomized,
  );
}

function onUtmParameterValueChanged(parameter, newValue) {
  trackSchedulerUtmCustomize({
    postId: props.trackingData?.postId ?? null,
    postPlatform: props.trackingData?.postPlatform ?? null,
    postType: props.trackingData?.postType ?? null,
    currentUtms: Object.fromEntries(
      Object.entries(trackingParameters.value).map(([param, { value }]) => [param, value]),
    ),
    defaultUtms: Object.fromEntries(
      Object.entries(props.brandChannelUtmSettings).map(([param, { value }]) => [param, value]),
    ),
    keyChanged: parameter,
    value: newValue.replace(/[<>]/g, ''),
    previousValue: previousTrackingParameters.value[parameter]?.value.replace(/[<>]/g, ''),
  });
}

async function updateDecodedUtms() {
  apiRequestError.value = false;
  apiRequestLoading.value = true;

  try {
    const result = await decodeUtmSettings({
      brandId: currentBrand.value.id,
      channel: props.channel,
      content: props.url,
    });
    decodedTrackingParameters.value = result.data.tracking_parameters;
  } catch (e) {
    setToast({
      message: 'Not able to retrieve UTMs for URL',
      type: 'error',
    });
    apiRequestError.value = true;
    decodedTrackingParameters.value = {};
  } finally {
    apiRequestLoading.value = false;
  }
}

function goToUtmSettings() {
  const routeData = router.resolve({
    name: 'settings.utm',
    query: { channel: props.channel },
  });
  // Open the route in a new tab
  window.open(routeData.href, '_blank');
}

function updateTrackingParameters() {
  trackingParameters.value = sortSettings(
    merge(cloneDeep(props.brandChannelUtmSettings), decodedTrackingParameters.value),
  );
  previousTrackingParameters.value = cloneDeep(trackingParameters.value);
}

// Lifecycle Hooks
onMounted(async () => {
  await updateDecodedUtms();
  updateTrackingParameters();
});

// Watch
watch(
  () => [props.url, props.channel],
  async () => {
    if (props.url !== urlWithQueryParameters.value) {
      await updateDecodedUtms();
      updateTrackingParameters();
    }
  },
);

watch(
  () => props.brandChannelUtmSettings,
  () => {
    updateTrackingParameters();
  },
);

defineExpose({
  decodedTrackingParameters,
  trackingParameters,
  formattedTrackingParameters,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div class="overflow-y-scroll pl-6 pr-4">
    <div class="top-0 z-50 mb-4 flex bg-[var(--background-300)] pb-4 pt-6">
      <Icon
        class="mr-2 cursor-pointer"
        custom-class="svg-down"
        name="caret"
        medium
        :hover-color="colours.ICON.ICON_LINK"
        :color="colours.ICON.ICON_SECONDARY"
        @click="$emit('close')"
      />
      <span>{{ utmScheduleEditTrackingTitle }}</span>
    </div>
    <div class="flex items-center gap-3">
      <div
        v-if="linkNumber"
        class="flex min-h-[--x30] min-w-[--x30] items-center justify-center rounded-full bg-black text-white"
      >
        {{ linkNumber }}
      </div>
      <div v-if="linkTitle" v-tooltip="linkTitle" class="line-clamp-2 text-base font-normal">
        {{ linkTitle }}
      </div>
    </div>
    <CircularLoader v-if="apiRequestLoading" />
    <div v-else-if="emptyStateMessage" class="mt-10">
      <div
        v-if="emptyStateMessage.title"
        data-cy="UtmEditorEmptyStateTitle"
        class="mb-5 text-lg font-normal"
      >
        {{ emptyStateMessage.title }}
      </div>
      <div data-cy="UtmEditorEmptyStateMessage" class="text-center text-sm font-normal">
        {{ emptyStateMessage.message }}
      </div>
      <Button v-if="apiRequestError" class="mx-auto mb-4 mt-5" primary @click="updateDecodedUtms"
        >Retry</Button
      >
    </div>
    <div v-else>
      <div v-for="(parameter, index) in Object.keys(trackingParameters)" :key="index" class="mt-4">
        <p
          v-tooltip="parameter"
          class="mb-[--space-6] truncate text-[length:--x14]"
          :class="{
            'text-[color:--text-secondary]': !trackingParameters[parameter].edit_per_post,
          }"
        >
          {{ parameter }}
        </p>
        <UtmAutocomplete
          v-tooltip="!trackingParameters[parameter].edit_per_post && utmNotEditableTooltip"
          :value="trackingParameters[parameter].value"
          :channel="channel"
          :tracking-key="parameter"
          :disabled="!trackingParameters[parameter].edit_per_post"
          :default-values="props.brandChannelUtmSettings"
          @input="(newValue) => handleAutocompleteInput(parameter, newValue)"
          @blur="(newValue) => onUtmParameterValueChanged(parameter, newValue)"
        />
      </div>
      <UtmPreviewBox
        class="mt-6"
        :url="url"
        :utm-settings="formattedTrackingParameters"
        :use-dummy-values="false"
      />
    </div>

    <Button data-cy="UtmManagementButton" class="mt-6" link @click="goToUtmSettings">
      Manage UTM Settings
    </Button>
  </div>
</template>
