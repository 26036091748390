import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { generateDistinctRandomColor } from '@/utils/color';
import communityApi from '@/apis/community';
import { useIdentityStore } from '@/stores/identity';
import { v4 as uuidv4 } from 'uuid';
import { useNotificationStore } from '@/stores/notification';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

export const useCommunityMessageTagsStore = defineStore('communityMessageTags', () => {
  const identityStore = useIdentityStore();
  const notificationStore = useNotificationStore();

  const multiBrandMessageTags = ref([]);

  const messageTagsByBrand = computed(() => {
    if (!multiBrandMessageTags.value?.length) {
      return {};
    }
    const groupedItems = groupBy(multiBrandMessageTags.value, 'brandId');
    return mapValues(groupedItems, (items) => items.sort((a, b) => a.name.localeCompare(b.name)));
  });

  function getSelectedBrandTags(brandId) {
    return messageTagsByBrand.value?.[brandId] ?? [];
  }

  function generateTagColor(brandId) {
    const brandTagList = messageTagsByBrand.value?.[brandId] ?? [];
    const existingColors = brandTagList.map((t) => t.color);
    return generateDistinctRandomColor(existingColors);
  }

  async function getTagsForBrands(brandIds) {
    const tagBrandIds = Array.isArray(brandIds) ? brandIds.join(',') : brandIds;
    const res = await communityApi.getTags({ brandIds: tagBrandIds });
    multiBrandMessageTags.value = res.data.data;
  }

  async function createTag({ brandId, tag: newTag, optimismCallback }) {
    let tag = {
      ...newTag,
      color: generateTagColor(brandId),
      brandId: brandId ?? identityStore.currentBrand?.id,
    };
    const temporaryId = uuidv4();
    const optimisticUpdate = optimismCallback && typeof optimismCallback === 'function';

    try {
      if (optimisticUpdate) {
        // Create a temporary tag optimistically
        tag.id = temporaryId;
        multiBrandMessageTags.value.push(tag);
        optimismCallback(tag.id);
      }

      const res = await communityApi.createTag({
        brandId: tag.brandId,
        name: tag.name,
        color: tag.color,
      });
      tag = res.data.data;
      multiBrandMessageTags.value.push(tag);
      return tag;
    } catch (e) {
      notificationStore.setToast({
        message: 'There was an error creating your tag',
        type: 'error',
      });
      throw e;
    } finally {
      if (optimisticUpdate) {
        // Remove temporary tag
        const index = multiBrandMessageTags.value.findIndex((t) => t.id === temporaryId);
        if (index !== -1) {
          multiBrandMessageTags.value.splice(index, 1);
        }
      }
    }
  }

  async function deleteTag(tagId) {
    const index = multiBrandMessageTags.value.findIndex((t) => t.id === tagId);
    const deletedTag = multiBrandMessageTags.value.splice(index, 1);
    try {
      await communityApi.deleteTag(tagId);
    } catch (e) {
      notificationStore.setToast({
        message: 'There was an error deleting your tag',
        type: 'error',
      });
      multiBrandMessageTags.value.push(deletedTag);
      throw e;
    }
  }

  return {
    multiBrandMessageTags,
    messageTagsByBrand,
    getSelectedBrandTags,
    getTagsForBrands,
    createTag,
    deleteTag,
  };
});
