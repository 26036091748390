import * as CreatorsAPI from '@/apis/creators';
import { QUERY_OPTIONS } from '@/config';
import { useIdentityStore } from '@/stores/identity';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import { defineQuery, useQuery } from '@pinia/colada';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

export function generateMetaPaidPartnersStatusKey({ brandId, brandInstagramId, handle }) {
  return [brandId, brandInstagramId, handle, 'meta-paid-partners-status'];
}

export const useMetaPaidPartnersStatus = defineQuery(() => {
  // Stores
  const { currentBrand } = storeToRefs(useIdentityStore());
  const { brandInstagramAccount } = storeToRefs(useInstagramAccountStore());

  // State
  const handle = ref(null);

  const { data, ...query } = useQuery({
    key: () =>
      generateMetaPaidPartnersStatusKey({
        brandId: currentBrand.value.id,
        brandInstagramId: brandInstagramAccount.value?.fbBusinessInstagramId,
        handle: handle.value,
      }),
    query: async () => {
      const response = await CreatorsAPI.fetchMetaPaidPartnersStatus({
        brandId: currentBrand.value.id,
        brandInstagramId: brandInstagramAccount.value?.fbBusinessInstagramId,
        handle: handle.value,
      });
      return response.data.data;
    },
    ...QUERY_OPTIONS,
    refetchOnMount: false,
    enabled: computed(() => Boolean(handle.value)),
  });

  return {
    metaPaidPartnersStatus: data,
    handle,
    ...query,
  };
});
