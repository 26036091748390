import dayjs from 'dayjs';
import { computed, reactive } from 'vue';
import { format, subWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { format as formatInTimeZone } from 'date-fns-tz';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { GRAPH_SCALES, isValidGraphScale } from '@/models/dashboards/graph-scales.enum';
import {
  CONTEXT_PERIOD_MODES,
  REPORTING_PERIOD_MODES,
  isValidContextPeriodMode,
  isValidReportingPeriodMode,
} from '@/components/core/comparison-date-picker/constants';
import constants from '@/app/dashboards/constants';
import { useFlagStore } from '@/stores/flag';

export function useDashboardsReportFilters() {
  const flagStore = useFlagStore();

  const dashboardsFilters = reactive({
    reportDateRange: [],
    contextDateRange: [],
    reportingPeriodMode: null,
    contextPeriodMode: null,
    graphScale: null,
    contentTagIds: [],
    brandIds: [],
    brandTagIds: [],
    competitorSourceAccountIds: [],
    competitorTagIds: [],
  });

  const hasGDIHeadersAndFilterFlag = computed(() => {
    return flagStore.ready && flagStore.flags.gdiHeadersAndFilters;
  });

  function isValidDateRange(dateRange) {
    return (
      Array.isArray(dateRange) &&
      dateRange.length === 2 &&
      dateRange.every((date) => dayjs(date, 'YYYY-MM-DD', true).isValid())
    );
  }

  function isValidMode(value, mode) {
    return value && value in mode;
  }

  function generateDashboardLocalStorageKey(key, dashboardId, currentDashboard) {
    const dashboardKey = `-${dashboardId || currentDashboard?.id}`;
    return `${key}${dashboardKey}`;
  }

  function generateDefaultReportDateRange(timeZone) {
    const oneWeekAgoFromToday = subWeeks(new Date(), 1);

    const start = startOfWeek(oneWeekAgoFromToday);
    const end = endOfWeek(oneWeekAgoFromToday);

    return timeZone
      ? [
          formatInTimeZone(start, constants.FORMAT_STRING, { timeZone }),
          formatInTimeZone(end, constants.FORMAT_STRING, { timeZone }),
        ]
      : [format(start, constants.FORMAT_STRING), format(end, constants.FORMAT_STRING)];
  }

  function generateDefaultContextDateRange(timeZone) {
    const twoWeeksAgoFromToday = subWeeks(new Date(), 2);

    const start = startOfWeek(twoWeeksAgoFromToday);
    const end = endOfWeek(twoWeeksAgoFromToday);

    return timeZone
      ? [
          formatInTimeZone(start, constants.FORMAT_STRING, { timeZone }),
          formatInTimeZone(end, constants.FORMAT_STRING, { timeZone }),
        ]
      : [format(start, constants.FORMAT_STRING), format(end, constants.FORMAT_STRING)];
  }

  function updateReportDate({ newReportDateRange, dashboardId, currentDashboard, timeZone }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.REPORT_DATE_RANGE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageDateRange =
      browserStorageGetItem(localStorageKey)?.toString()?.split(',') || null;
    let dateToBeUsed = null;

    if (isValidDateRange(newReportDateRange)) {
      dateToBeUsed = newReportDateRange;
    } else if (isValidDateRange(localStorageDateRange)) {
      dateToBeUsed = localStorageDateRange;
    } else {
      dateToBeUsed = generateDefaultReportDateRange(timeZone);
    }

    dashboardsFilters.reportDateRange = dateToBeUsed;
    browserStorageSetItem(localStorageKey, dateToBeUsed.join(','));
  }

  function updateContextDate({ newContextDateRange, dashboardId, currentDashboard, timeZone }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.CONTEXT_DATE_RANGE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageDateRange =
      browserStorageGetItem(localStorageKey)?.toString()?.split(',') || null;
    let dateToBeUsed = null;
    if (isValidDateRange(newContextDateRange)) {
      dateToBeUsed = newContextDateRange;
    } else if (isValidDateRange(localStorageDateRange)) {
      dateToBeUsed = localStorageDateRange;
    } else {
      dateToBeUsed = generateDefaultContextDateRange(timeZone);
    }

    dashboardsFilters.contextDateRange = dateToBeUsed;
    browserStorageSetItem(localStorageKey, dateToBeUsed.join(','));
  }

  function checkLegacyReportingPeriodMode(mode, localStorageValue) {
    if (isValidReportingPeriodMode(mode)) {
      return mode;
    }
    if (isValidReportingPeriodMode(localStorageValue)) {
      return localStorageValue;
    }
    return REPORTING_PERIOD_MODES.LAST_WEEK.value;
  }

  function updateReportingPeriodMode({ mode, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.REPORTING_PERIOD_MODE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageValue = browserStorageGetItem(localStorageKey)?.toString() || null;
    let valueToBeUsed = null;

    if (hasGDIHeadersAndFilterFlag.value) {
      if (isValidMode(mode, constants.REPORTING_PERIOD_FILTERS)) {
        valueToBeUsed = mode;
      } else if (isValidMode(localStorageValue, constants.REPORTING_PERIOD_FILTERS)) {
        valueToBeUsed = localStorageValue;
      } else {
        valueToBeUsed = constants.REPORTING_PERIOD_FILTERS.LAST_7_DAYS.value;
      }
    } else {
      valueToBeUsed = checkLegacyReportingPeriodMode(mode, localStorageValue);
    }

    dashboardsFilters.reportingPeriodMode = valueToBeUsed;
    browserStorageSetItem(localStorageKey, valueToBeUsed);
  }

  function checkLegacyContextPeriodMode(mode, localStorageValue) {
    if (isValidContextPeriodMode(mode)) {
      return mode;
    }
    if (isValidContextPeriodMode(localStorageValue)) {
      return localStorageValue;
    }
    return CONTEXT_PERIOD_MODES.PREVIOUS_WEEK.value;
  }

  function updateContextPeriodMode({ mode, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.CONTEXT_PERIOD_MODE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageValue = browserStorageGetItem(localStorageKey)?.toString() || null;
    let valueToBeUsed = null;

    if (hasGDIHeadersAndFilterFlag.value) {
      if (isValidMode(mode, constants.COMPARISON_PERIOD_FILTERS)) {
        valueToBeUsed = mode;
      } else if (isValidMode(localStorageValue, constants.COMPARISON_PERIOD_FILTERS)) {
        valueToBeUsed = localStorageValue;
      } else {
        valueToBeUsed = constants.COMPARISON_PERIOD_FILTERS.PREVIOUS_7_DAYS.value;
      }
    } else {
      valueToBeUsed = checkLegacyContextPeriodMode(mode, localStorageValue);
    }

    dashboardsFilters.contextPeriodMode = valueToBeUsed;
    browserStorageSetItem(localStorageKey, valueToBeUsed);
  }

  function updateGraphScale({ scale, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.GRAPH_SCALE_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageGraphScale = browserStorageGetItem(localStorageKey)?.toString() || null;
    let graphScaleToBeUsed = null;
    if (isValidGraphScale(scale)) {
      graphScaleToBeUsed = scale;
    } else if (isValidGraphScale(localStorageGraphScale)) {
      graphScaleToBeUsed = localStorageGraphScale;
    } else {
      graphScaleToBeUsed = GRAPH_SCALES.DAILY.value;
    }
    dashboardsFilters.graphScale = graphScaleToBeUsed;
    browserStorageSetItem(localStorageKey, graphScaleToBeUsed);
  }

  function updateContentTagIds({ contentTagIds, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.CONTENT_TAG_IDS_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageContentTagIds = browserStorageGetItem(localStorageKey) || null;
    let useContentTagIds;
    if (contentTagIds) {
      useContentTagIds = contentTagIds;
    } else if (localStorageContentTagIds) {
      useContentTagIds = localStorageContentTagIds
        .split(',')
        .map((contentTagId) => parseInt(contentTagId, 10));
    } else {
      useContentTagIds = [];
    }
    dashboardsFilters.contentTagIds = useContentTagIds;
    browserStorageSetItem(localStorageKey, useContentTagIds);
  }

  function updateBrandIds({ brandIds, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.BRAND_IDS_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageBrandIds = browserStorageGetItem(localStorageKey) || null;
    let useBrandIds;
    if (brandIds) {
      useBrandIds = brandIds;
    } else if (localStorageBrandIds) {
      useBrandIds = localStorageBrandIds.split(',').map((brandId) => parseInt(brandId, 10));
    } else {
      useBrandIds = [];
    }
    dashboardsFilters.brandIds = useBrandIds;
    browserStorageSetItem(localStorageKey, useBrandIds);
  }

  function updateBrandTagIds({ brandTagIds, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.BRAND_TAG_IDS_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageBrandIds = browserStorageGetItem(localStorageKey) || null;
    let useBrandTagIds;
    if (brandTagIds) {
      useBrandTagIds = brandTagIds;
    } else if (localStorageBrandIds) {
      useBrandTagIds = localStorageBrandIds.split(',').map((brandId) => parseInt(brandId, 10));
    } else {
      useBrandTagIds = [];
    }
    dashboardsFilters.brandTagIds = useBrandTagIds;
    browserStorageSetItem(localStorageKey, useBrandTagIds);
  }

  function updateCompetitorSourceAccountIds({
    competitorSourceAccountIds,
    dashboardId,
    currentDashboard,
  }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.COMPETITOR_IDS_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageCompetitorIds = browserStorageGetItem(localStorageKey) || null;
    let useCompetitorSourceAccountIds;
    if (competitorSourceAccountIds) {
      useCompetitorSourceAccountIds = competitorSourceAccountIds;
    } else if (localStorageCompetitorIds) {
      useCompetitorSourceAccountIds = localStorageCompetitorIds.split(',');
    } else {
      useCompetitorSourceAccountIds = [];
    }
    dashboardsFilters.competitorSourceAccountIds = useCompetitorSourceAccountIds;
    browserStorageSetItem(localStorageKey, useCompetitorSourceAccountIds);
  }

  function updateCompetitorTagIds({ competitorTagIds, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.COMPETITOR_TAG_IDS_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageBrandIds = browserStorageGetItem(localStorageKey) || null;
    let useCompetitorTagIds;
    if (competitorTagIds) {
      useCompetitorTagIds = competitorTagIds;
    } else if (localStorageBrandIds) {
      useCompetitorTagIds = localStorageBrandIds.split(',').map((brandId) => parseInt(brandId, 10));
    } else {
      useCompetitorTagIds = [];
    }
    dashboardsFilters.competitorTagIds = useCompetitorTagIds;
    browserStorageSetItem(localStorageKey, useCompetitorTagIds);
  }

  return {
    dashboardsFilters,
    updateReportDate,
    updateContextDate,
    updateReportingPeriodMode,
    updateContextPeriodMode,
    updateGraphScale,
    updateContentTagIds,
    updateBrandIds,
    updateBrandTagIds,
    updateCompetitorSourceAccountIds,
    updateCompetitorTagIds,
  };
}
