<template>
  <div ref="container" class="video-cropper-container">
    <div :style="{ width: `${videoWidth}px`, height: `${videoHeight}px` }" class="video-container">
      <div v-if="!cropperReady" class="loader-wrapper">
        <CircularLoader />
      </div>
      <video
        v-show="cropperReady"
        ref="video"
        :src="videoUrl"
        :data-muted="disableAudioTrack"
        :muted="disableAudioTrack"
        :style="{
          width: `${videoWidth}px`,
          height: `${videoHeight}px`,
          top: `${top}px`,
          left: `${left}px`,
        }"
        data-cy="cropper-video"
        @loadedmetadata="metadataLoaded"
        @loadeddata="videoLoaded"
      />
    </div>

    <div
      :style="{
        width: `${videoWidth}px`,
        height: `${videoHeight}px`,
        visibility:
          cropperReady && cropperStore.cropperRatio.ratioType !== 'original' ? 'visible' : 'hidden',
      }"
      class="crop-container"
    >
      <canvas ref="cropCanvas" :width="videoWidth" :height="videoHeight" />
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
/**
 * Out of the box, vue-cropperjs does not support videos. This is a workaround I (@pmdarrow)
 * developed to support video without having to write a cropper from scratch. By using cropper.js
 * directly instead of its Vue wrapper, I can pass it an empty canvas object. This empty canvas
 * is adjusted to match the size of the video. Then, the empty canvas is overlaid on the video.
 * When cropper.js makes changes to the canvas, I mimic the changes on the video, resulting in the
 * appearance that video is being controlled directly by cropper.js.
 */
import { mapStores } from 'pinia';
import Cropper from 'cropperjs';
import CircularLoader from '@/components/CircularLoader.vue';
import { getVideoDataFromMedia } from '@/utils/media';
import { useCropperStore } from '@/stores/cropper';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'VideoCropper',
  components: {
    CircularLoader,
  },
  props: {
    disableAudioTrack: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false },
    endTime: { type: Number, default: 0 },
    onCropChange: { type: Function, required: true },
    onPlayingChanged: { type: Function, default: () => {} },
    onVideoCurrentTimeChanged: { type: Function, default: () => {} },
    onVideoLoaded: { type: Function, default: () => {} },
    onVideoRestarted: { type: Function, default: () => {} },
    originalMedia: { type: Object, default: null },
    playheadPosition: { type: Number, default: 0 },
    shouldRestartVideo: { type: Boolean, default: false },
    startTime: { type: Number, default: 0 },
    videoMedia: { type: Object, required: true },
  },
  data() {
    return {
      canvasScale: 1,
      cropperReady: false,
      currentTimeAnimationId: null,
      left: 0,
      top: 0,
      videoHeight: null,
      videoWidth: null,
    };
  },
  computed: {
    ...mapStores(useCropperStore),
    video() {
      return getVideoDataFromMedia(this.originalMedia || this.videoMedia);
    },
    videoUrl() {
      return this.video.url;
    },
  },
  watch: {
    playheadPosition(newVal) {
      // seconds must be rounded here to keep them consistent with startTime and endTime coming in
      // from VideoTrimmer
      const seconds = Math.round(newVal * 1000) / 1000;
      if (seconds <= this.startTime || seconds >= this.endTime) {
        this.$refs.video.currentTime = this.startTime;
        this.$refs.video.pause();
        this.onPlayingChanged(false);
      }
    },
    startTime(newVal) {
      this.$refs.video.currentTime = newVal;
    },
    endTime(newVal, oldVal) {
      if (oldVal !== 0) {
        // When endTime is changed, show the video frame without triggering the playhead watcher
        this.$refs.video.pause();
        this.onPlayingChanged(false);
        this.$refs.video.currentTime = newVal - 0.001;
      }
    },
    'cropperStore.cropperRatio': {
      handler(newRatio, oldRatio) {
        // Note: same code as in ImageCropper.vue / cropperRatio().
        if (this.cropper) {
          if (newRatio !== null || oldRatio === null || newRatio.ratioType !== oldRatio.ratioType) {
            if (newRatio.ratio) {
              const res = newRatio.ratio.split(':');
              const widthRatio = Number(res[0]);
              const heightRatio = Number(res[1]);
              this.cropper.setAspectRatio(widthRatio / heightRatio);
            } else {
              this.cropper.setAspectRatio(null);
            }
            this.onCropMove();
          }
        }
      },
    },
    disabled(newVal) {
      if (this.cropper) {
        if (newVal) {
          this.cropper.disable();
          this.$refs.video.pause();
        } else {
          this.cropper.enable();
        }
      }
    },
  },
  created() {
    nextTick(() => {
      if (this.$refs.video) {
        this.$refs.video.currentTime = this.startTime;
      }
    });
  },
  mounted() {
    this.positionCropper();

    // Refs are not computed yet, have to wait until the next tick to get the container dimensions
    nextTick(() => {
      this.cropper = new Cropper(this.$refs.cropCanvas, {
        autoCropArea: 1,
        background: false,
        cropend: this.onCropMove,
        highlight: false,
        minContainerHeight: 1,
        minContainerWidth: 1,
        modal: false,
        ready: this.setCropData,
        viewMode: 1,
        zoomable: false,
        toggleDragModeOnDblclick: false,
      });

      if (this.cropperStore.cropperRatio && this.cropperStore.cropperRatio.ratio) {
        const aspectRatio = this.cropperStore.cropperRatio.ratio.split(':');
        const widthRatio = Number(aspectRatio[0]);
        const heightRatio = Number(aspectRatio[1]);
        this.cropper.setAspectRatio(widthRatio / heightRatio);
      }
    });
    window.addEventListener('resize', this.positionCropper);
    this.currentTimeAnimationId = window.requestAnimationFrame(() => this.updateCurrentTime());
  },
  beforeUnmount() {
    const { video } = this.$refs;

    video.removeEventListener('loadedmetadata', this.videoLoaded);
    video.pause();
    video.removeAttribute('src');
    video.load();
  },
  unmounted() {
    this.cropperStore.removeVideoDuration();
    window.removeEventListener('resize', this.positionCropper);
    window.cancelAnimationFrame(this.currentTimeAnimationId);
  },
  methods: {
    metadataLoaded(e) {
      this.cropperStore.setVideoDuration(Math.round(e.target.duration * 1e3) / 1e3);
    },
    videoLoaded() {
      this.cropperReady = true;
      // emit event to the trimmer tool to show the trimming presets
      this.onVideoLoaded(true);
    },
    onPlayClicked(newVal) {
      if (this.shouldRestartVideo) {
        // Restart the video without triggering the playhead watcher
        this.$refs.video.currentTime = this.startTime + 0.001;
        this.onVideoRestarted();
      }
      const playPromise = this.$refs.video.play();

      if (playPromise && !newVal) {
        playPromise.then(() => {
          this.$refs.video.pause();
        });
      }
    },
    updateCurrentTime() {
      this.onVideoCurrentTimeChanged(this.$refs.video.currentTime);
      this.currentTimeAnimationId = window.requestAnimationFrame(() => this.updateCurrentTime());
    },
    positionCropper() {
      // Find the best fit for the video within the current viewport and apply it
      const maxWidth = this.$refs.container.clientWidth;
      const maxHeight = this.$refs.container.clientHeight;
      const widthRatio = maxWidth / this.video.width;
      const heightRatio = maxHeight / this.video.height;
      const bestRatio = Math.min(widthRatio, heightRatio);

      this.videoWidth = this.video.width * bestRatio;
      this.videoHeight = this.video.height * bestRatio;

      if (this.cropper) {
        this.setCanvasScale();
      }
    },
    onCropMove() {
      // Note: same code as in ImageCropper.vue / onCropMove().
      const originalCanvasData = this.cropper.getCanvasData();
      const canvasData = {};
      Object.keys(originalCanvasData).forEach((key) => {
        canvasData[key] = Math.round(originalCanvasData[key] * 100) / 100;
      });
      const originalCropData = this.cropper.getData();
      const cropData = {};
      Object.keys(originalCropData).forEach((key) => {
        cropData[key] = Math.round(originalCropData[key] * 100) / 100;
      });
      const data = {
        canvas: this.cropper.getCroppedCanvas(),
        cropData: this.calculateActualCrop(canvasData, cropData),
        canvasData,
      };
      this.onCropChange(data);
    },
    calculateActualCrop(canvasData, cropData) {
      // Since we are forcing the size of the video and canvas to fit the popup using explict
      // width and height, we need to calculate the crop data scaled to the original width/height
      // of the video. (Note that image cropping uses 100% width/height, so it doesn't suffer from
      // this issue.)
      const { x, y, width, height } = cropData;

      return {
        x: Math.round(x * this.canvasScale),
        y: Math.round(y * this.canvasScale),
        width:
          this.cropperStore.cropperRatio?.ratioType === 'original'
            ? this.videoMedia.sizes.original.width
            : Math.round(width * this.canvasScale),
        height:
          this.cropperStore.cropperRatio?.ratioType === 'original'
            ? this.videoMedia.sizes.original.height
            : Math.round(height * this.canvasScale),
      };
    },
    setCanvasScale() {
      const canvasData = this.cropper.getCanvasData();
      const actualWidth = this.video.width;
      this.canvasScale = actualWidth / canvasData.width;
    },
    setCropData() {
      this.setCanvasScale();
      if (this.originalMedia && this.videoMedia.transforms && this.videoMedia.transforms.crop) {
        const { canvasScale, cropper } = this;
        const { x, y, width, height } = this.videoMedia.transforms.crop;

        const scaledCropData = {
          x: x / canvasScale,
          y: y / canvasScale,
          width: width / canvasScale,
          height: height / canvasScale,
        };
        cropper.setData(scaledCropData);
      }
      this.onCropMove();
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.video-cropper-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.video-container {
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  video {
    position: absolute;
  }

  .loader-wrapper {
    display: flex;
    height: 100%;
  }
}

.crop-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  canvas {
    display: block;
    max-width: 100%;
  }
}

/* Cropper.js style overrides specific to video cropping */
:deep(.cropper-container) {
  .cropper-face {
    background-color: transparent;
  }

  .cropper-crop-box {
    box-shadow: 0 0 0 9999px rgb(255 255 255 / 50%);
  }

  .cropper-modal {
    opacity: 0;
  }
}
</style>
