/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'priority': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.332 7.882c0 1.13.002 2.26 0 3.391 0 .416-.323.734-.706.709-.155-.01-.28-.09-.396-.184-.636-.509-1.267-1.026-1.907-1.53-.702-.556-1.386-1.135-2.102-1.672-.13-.098-.223-.11-.372-.011-.51.34-.967.755-1.448 1.136-.876.696-1.748 1.399-2.615 2.106-.232.19-.477.225-.728.1-.259-.13-.39-.371-.39-.676L.666 4.504c0-.269.115-.476.314-.637L2.869 2.34C3.756 1.62 4.644.9 5.533.182c.301-.243.63-.242.93-.001 1.026.822 2.052 1.643 3.077 2.466.506.407 1.01.815 1.512 1.226.193.159.281.375.28.635-.002 1.125 0 2.249 0 3.374z" _fill="url(#paint0_linear_8834_18845)"/><defs><linearGradient id="paint0_linear_8834_18845" x1="6" y1="6" x2="6.2" y2="11.993" gradientUnits="userSpaceOnUse"><stop stop-color="#FF932F"/><stop offset="1" stop-color="#FF932F" stop-opacity=".5"/></linearGradient></defs>'
  }
})
