<template>
  <Button
    v-tooltip="tooltip"
    :class="buttonClass"
    data-cy="connect-button"
    @click="connectApp(platform)"
  >
    <Icon
      v-if="!plainButton"
      :name="iconName"
      medium
      :color="iconColor"
      :original="isOriginalIcon"
      class="mr-1.5"
    />
    {{ buttonText }}
  </Button>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import { formatPlatformConnectionLabel } from '@/utils/formatters';
import { usePlatformStore, PLATFORM_COPY } from '@/stores/platform';
import { useFlagStore } from '@/stores/flag';
import { connectionPlatforms } from '@/app/library/constants';
import { BASIC, SOCIAL_CHANNEL } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ConnectButton',
  components: {
    Button,
    Icon,
  },
  props: {
    plainButton: { type: Boolean, default: false },
    customText: { type: String, default: null },
    platform: { type: String, required: true },
    invitedByBrandId: { type: Number, default: null },
    invitedByBrandName: { type: String, default: null },
    influencer: { type: String, default: null },
    sourceCreatorId: { type: String, default: null },
    brand: { type: Object, default: null },
    bypassConnectionCheck: { type: Boolean, default: false },
    fromExternalLink: {
      type: Boolean,
      default: false,
    },
    openPopup: {
      type: Boolean,
      default: true,
    },
    disableOnMissingHandle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useAuthStore, usePlatformStore, useFlagStore),
    instagramButton() {
      return this.openPopup ? 'instagram' : 'facebook';
    },
    youTubeButton() {
      return this.openPopup ? 'youtube' : 'google';
    },
    buttonClass() {
      const classes = ['button'];
      if (!this.plainButton) {
        if (this.platform === 'youtube') {
          classes.push(this.youTubeButton);
        } else if (this.platform === 'ga') {
          classes.push('google');
        } else if (['facebook', 'facebook_sandbox'].includes(this.platform)) {
          classes.push(this.instagramButton);
        } else if (['tiktok_ads', 'tiktok_creator_marketplace'].includes(this.platform)) {
          classes.push('tiktok');
        } else if (this.platform === 'threads') {
          classes.push('threads');
        } else if (this.platform === 'bluesky') {
          classes.push('bluesky');
        } else {
          classes.push(this.platform);
        }
      }
      if (this.requiredConnectionMissing) {
        classes.push('disabled');
      }
      return classes;
    },
    buttonText() {
      if (this.customText) {
        return this.customText;
      }
      if (this.plainButton) {
        return 'Connect';
      }
      if (['facebook', 'facebook_sandbox'].includes(this.platform)) {
        return `Connect ${formatPlatformConnectionLabel(this.instagramButton)}`;
      }
      return `Connect ${formatPlatformConnectionLabel(this.platform)}`;
    },
    isOriginalIcon() {
      return ['ga', 'youtube', 'bluesky'].includes(this.platform);
    },
    iconName() {
      const iconMap = {
        facebook: this.instagramButton,
        facebook_sandbox: this.instagramButton,
        instagram_influencer: 'facebook',
        ga: 'google',
        youtube: this.youTubeButton,
      };
      return iconMap[this.platform] || this.platform.split('_')[0];
    },
    iconColor() {
      if (this.platform === 'threads') {
        return SOCIAL_CHANNEL.SC_THREADS;
      }
      return BASIC.WHITE;
    },
    connectionId() {
      return this.platformStore.platformConnectionsMap?.[this.platform]?.[this.brandId]?.id ?? null;
    },
    brandId() {
      return this.brand?.id ?? this.authStore.currentBrand?.id;
    },
    hasAdAccountConnectionFlag() {
      return this.flagStore.ready && this.flagStore.flags.adAccountConnection;
    },
    requiredConnectionMissing() {
      if (this.bypassConnectionCheck || this.hasAdAccountConnectionFlag) {
        return false;
      }
      if (this.platform === 'facebook_ads') {
        return (
          this.platformStore.platformConnectionsMap?.[connectionPlatforms.FACEBOOK.toLowerCase()]?.[
            this.brandId
          ]?.status !== 'connected'
        );
      }
      return false;
    },
    tooltip() {
      if (this.requiredConnectionMissing) {
        return PLATFORM_COPY.noRequiredPermissionTooltip?.[this.platform];
      }
      return ``;
    },
  },
  methods: {
    connectApp(provider) {
      if (this.requiredConnectionMissing) {
        return;
      }

      if (this.openPopup) {
        this.platformStore.connect(provider, {
          bypassCheckConnection: this.bypassConnectionCheck,
          openedFrom: this.fromExternalLink ? 'ExternalLink' : 'Connect Button',
        });
      } else {
        this.platformStore.connectPlatform({
          router: this.$router,
          provider,
          connectionId: this.connectionId,
          brandId: this.brandId,
          invitedByBrandId: this.invitedByBrandId,
          invitedByBrandName: this.invitedByBrandName,
          influencer: this.influencer,
          fromExternalLink: this.fromExternalLink,
          sourceCreatorId: this.sourceCreatorId,
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.button {
  border: none;
  height: var(--space-40);
  background: var(--action-500);
  color: var(--white);

  &.focus,
  &:hover,
  &:active {
    pointer-events: all !important;
  }

  &:hover {
    text-decoration: none;
    color: var(--white);
  }

  &.instagram {
    background: var(--sc-instagram);
    color: var(--white);
  }

  &.pinterest,
  &.pinterest_v5 {
    background-color: var(--sc-pinterest);
    color: var(--white);
  }

  &.twitter {
    background-color: var(--sc-twitter);
    color: var(--white);
  }

  &.linkedin {
    background-color: var(--sc-linkedin);
    color: var(--white);
  }

  &.facebook,
  &.facebook_sandbox,
  &.facebook_ads,
  &.facebook_analytics,
  &.instagram_influencer {
    background-color: var(--sc-facebook);
    color: var(--white);
  }

  &.threads {
    background-color: var(--white);
    color: var(--sc-threads);
    border: solid var(--border);
    border-width: 1px;
  }

  &.bluesky {
    background-color: var(--sc-bluesky);
    color: var(--white);
  }

  &.tiktok {
    background-color: var(--black);
    color: var(--white);
  }

  &.youtube {
    background-color: var(--sc-youtube);
    color: var(--white);
  }

  &.google {
    height: var(--space-40);
    background-color: var(--background-0);
    color: var(--text-primary);
    border: solid var(--border);
    border-width: 1px;
  }
}
</style>
