<template>
  <span :class="['pair-row', indented ? 'indented' : null]">
    <span
      v-tooltip="adAccountScopedInsight ? formattedLabel : null"
      class="stat-name"
      :class="[adAccountScopedInsight ? 'inline-block max-w-[23%] truncate' : null]"
      data-cy="data-stat-name"
    >
      <slot name="label">
        {{ formattedLabel }}
        <InfoTooltip v-if="!!labelTooltip" :tooltip="labelTooltip" xsmall />
      </slot>
    </span>
    <span v-tooltip="valueTooltip" class="stat">
      {{ formattedValue }}
    </span>
  </span>
</template>

<script>
import { defineComponent } from 'vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'InsightsItem',
  components: {
    InfoTooltip,
  },
  props: {
    label: { type: String, default: '' },
    labelTooltip: { type: String, default: '' },
    value: { type: [Number, String], default: null },
    valueTooltip: { type: String, default: '' },
    indented: { type: Boolean, default: false },
    adAccountScopedInsight: { type: Boolean, default: false },
  },
  computed: {
    formattedLabel() {
      return this.label;
    },
    formattedValue() {
      return this.value != null ? String(this.value.toLocaleString()) : '-';
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
span {
  display: flex;
  align-items: center;
  color: var(--text-primary);

  svg {
    margin-left: var(--space-8);
  }
}

.pair-row {
  margin: var(--space-8) 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .stat-name {
    padding-right: var(--space-8);
  }

  .stat {
    color: var(--action-500);
  }

  &::before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: '--------------------------------------------------------------------------------';
    color: var(--border);
  }

  span + span {
    float: right;
    padding-left: var(--space-8);
    background: var(--background-300);
  }

  span:first-child {
    position: absolute;
    background: var(--background-300);
  }

  &.indented {
    padding-left: var(--space-32);
    font-size: var(--x12);

    .stat {
      color: var(--text-primary);
    }
  }
}
</style>
