import { defineStore } from 'pinia';

export const useXlsxStore = defineStore('xlsx', {
  state: () => ({
    creatorPostsXLSXPending: null,
  }),
  actions: {
    requestXLSX({ type }) {
      this[`${type}XLSXPending`] = true;
    },
    xlsxReceived({ type }) {
      this[`${type}XLSXPending`] = false;
    },
    xlsxRequestFailed({ type }) {
      this[`${type}XLSXPending`] = false;
    },
  },
});
