import { computed } from 'vue';
import { getMetricText, getMetricTimeframe } from '@/models/dashboards/metrics';
import { useReport } from '@/app/dashboards/composables/useReport';
import { bothRangesInCurrentYear } from '@/utils/dateUtils';
import { formatDateRangeLabel } from '@/utils/formatters';
import { FORMAT_TYPES, QUERY_TIMEFRAME } from '@/models/dashboards/metrics.constants';
import { MEDIA_TYPES } from '@/models/dashboards/media-types.enum';
import {
  REPORT_MEDIA_TYPE_LABEL_MAP,
  REPORT_MENU_ITEMS,
  ORGANIC_TABLE_COLUMNS,
} from '@/app/dashboards/constants';
import { useDashboards } from '@/app/dashboards/composables/useDashboards';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import { useContentTagsStore } from '@/stores/content-tags';
import { REPORTS } from '@/app/dashboards/utils/reports.enum';
import { useReportType } from '@/app/dashboards/composables/useReportType';
import { ADS_REPORT_AGGREGATE_BY_TYPES } from '@/models/dashboards/aggregate-type.enum';

export function useReportDetails({ report, loading = true } = {}) {
  const {
    metricTypeReportKey,
    reportBrandsMetrics,
    reportChannels,
    metricReportRows,
    reportType,
    aggregateBy,
    reportMetricDetails,
    firstMetric,
    firstChannel,
    reportMediaTypeMetrics,
    isCustomMetricReportTile,
    reportCompetitorsMetrics,
    reportMeta,
    reportBrands,
  } = useReport({
    report,
  });

  const { extractUniqueChannelIconNames } = useDashboards();
  const { getCustomReportDetailsFromId } = useCustomMetrics();
  const { allOrganizationsContentTags } = useContentTagsStore();
  const { isCompetitiveReport, isBenchmarkReport } = useReportType({
    reportType,
  });

  const overrideReportDateRange = computed(() => {
    if (!reportMeta.value?.static_dates?.start_date || !reportMeta.value?.static_dates?.end_date)
      return null;
    return [reportMeta.value.static_dates.start_date, reportMeta.value.static_dates.end_date];
  });

  const overrideContextDateRange = computed(() => {
    if (
      !reportMeta.value?.static_dates?.context_start_date ||
      !reportMeta.value?.static_dates?.context_end_date
    )
      return null;
    return [
      reportMeta.value.static_dates.context_start_date,
      reportMeta.value.static_dates.context_end_date,
    ];
  });

  const bothRangesInSameYear = computed(() => {
    return bothRangesInCurrentYear(overrideReportDateRange.value, overrideContextDateRange.value);
  });

  const reportPeriodDisplay = computed(() => {
    const forceYearDisplay = !bothRangesInSameYear.value;
    return formatDateRangeLabel(
      overrideReportDateRange.value[0],
      overrideReportDateRange.value[1],
      {
        forceYearDisplay,
      },
    );
  });
  const contextPeriodDisplay = computed(() => {
    const forceYearDisplay = !bothRangesInSameYear.value;
    return formatDateRangeLabel(
      overrideContextDateRange.value[0],
      overrideContextDateRange.value[1],
      {
        forceYearDisplay,
      },
    );
  });

  const dateRangeDisplay = computed(() => {
    if (!overrideReportDateRange.value || !overrideContextDateRange.value) return null;

    return `${reportPeriodDisplay.value} vs ${contextPeriodDisplay.value}`;
  });

  const reportChips = computed(() => {
    const metricTimeFrame = getMetricTimeframe(firstChannel.value, firstMetric.value);

    return {
      metricTimeFrame:
        metricTimeFrame === QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME
          ? 'Activity During Timeframe'
          : 'Posts this Period',
      isCustomMetric: isCustomMetricReportTile.value,
      dateRange: dateRangeDisplay.value,
    };
  });

  const customMetricDetails = computed(() => {
    return getCustomReportDetailsFromId(firstMetric.value);
  });

  const customMetricName = computed(() => {
    return customMetricDetails?.value?.name ?? reportMeta.value.name;
  });

  const metricTitle = computed(() => {
    if (isCustomMetricReportTile.value) return customMetricName.value;
    return getMetricText(
      firstChannel,
      firstMetric,
      reportChannels.value,
      null,
      aggregateBy.value,
      metricTypeReportKey.value,
    );
  });

  const titleTooltip = computed(() => {
    if (isCustomMetricReportTile.value) {
      return {
        title: customMetricName.value,
        content: customMetricDetails.value.reportTooltip.description,
        footer: customMetricDetails.value.reportTooltip.formula,
      };
    }
    return reportMetricDetails.value.tooltip
      ? {
          title: metricTitle.value,
          content: reportMetricDetails.value.tooltip,
        }
      : null;
  });

  const contentTagIds = computed(() => {
    return reportMeta.value?.content_tag_ids || [];
  });

  const contentTagNames = computed(() => {
    const contentTagIdsSet = new Set(contentTagIds.value);
    const foundContentTags = allOrganizationsContentTags.filter((contentTag) =>
      contentTagIdsSet.has(contentTag.id),
    );
    if (foundContentTags.length < 1) return null;
    return foundContentTags.map((foundContentTag) => foundContentTag.name);
  });

  const avatarGroups = computed(() => {
    const brandAvatars = reportBrands.value.map((brand) => {
      return {
        avatar: brand.avatar,
        label: brand.name,
      };
    });
    const competitorAvatars = reportCompetitorsMetrics.value.map((competitor) => {
      return {
        avatar: competitor.avatar,
        label: competitor.name,
      };
    });
    const groups = [
      {
        label: 'Brands',
        avatars: brandAvatars,
      },
      competitorAvatars.length
        ? {
            label: 'Competitors',
            avatars: competitorAvatars,
          }
        : undefined,
    ].filter(Boolean);
    return {
      groups,
      maxAvatarsToDisplay: 12,
    };
  });

  const showVideo = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return mediaType === MEDIA_TYPES.VIDEO.value || mediaType === MEDIA_TYPES.VIDEO_REEL.value;
    });
  });

  const showReel = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return mediaType === MEDIA_TYPES.REEL.value || mediaType === MEDIA_TYPES.VIDEO_REEL.value;
    });
  });

  const showPhoto = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return (
        mediaType === MEDIA_TYPES.PHOTO.value || mediaType === MEDIA_TYPES.PHOTO_CAROUSEL.value
      );
    });
  });

  const showCarousel = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return (
        mediaType === MEDIA_TYPES.CAROUSEL.value || mediaType === MEDIA_TYPES.PHOTO_CAROUSEL.value
      );
    });
  });

  const showLink = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return mediaType === MEDIA_TYPES.LINK.value;
    });
  });

  const showStatus = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return mediaType === MEDIA_TYPES.STATUS.value;
    });
  });

  const showAllMedia = computed(() => {
    return reportMediaTypeMetrics.value.some((reportMediaTypeMetric) => {
      const mediaType = reportMediaTypeMetric?.mediaType?.value;
      return (
        mediaType === MEDIA_TYPES.ALL_MEDIA.value ||
        reportMediaTypeMetric?.mediaType?.metric.startsWith('UGC_')
      );
    });
  });

  const mediaTypeTooltips = computed(() => {
    const tooltips = [];
    if (showAllMedia.value) tooltips.push(MEDIA_TYPES.ALL_MEDIA.tooltip);
    if (showVideo.value) tooltips.push(MEDIA_TYPES.VIDEO.tooltip);
    if (showReel.value) tooltips.push(MEDIA_TYPES.REEL.tooltip);
    if (showPhoto.value) tooltips.push(MEDIA_TYPES.PHOTO.tooltip);
    if (showCarousel.value) tooltips.push(MEDIA_TYPES.CAROUSEL.tooltip);
    if (showLink.value) tooltips.push(MEDIA_TYPES.LINK.tooltip);
    if (showStatus.value) tooltips.push(MEDIA_TYPES.STATUS.tooltip);
    return tooltips.join(', ');
  });

  const mediaTypeIcons = computed(() => {
    const icons = [];
    if (showAllMedia.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.ALL_MEDIA);
    if (showVideo.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.VIDEO);
    if (showReel.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.REEL);
    if (showPhoto.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.PHOTO);
    if (showCarousel.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.CAROUSEL);
    if (showLink.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.LINK);
    if (showStatus.value) icons.push(REPORT_MEDIA_TYPE_LABEL_MAP.STATUS);

    if (icons.length > 1) {
      return {
        icon: icons[0],
        iconRight: icons[1],
      };
    }
    return {
      icon: icons[0],
    };
  });

  const formattedValue = computed(() => {
    return reportMetricDetails.value.formatMetricValue(
      reportBrandsMetrics.value[0].metric.value,
      FORMAT_TYPES.NORMAL,
    );
  });

  const channels = computed(() => {
    return extractUniqueChannelIconNames(reportChannels.value).map((channel) => ({ channel }));
  });

  const isAdsTotalGroupedMetricReport = computed(() => {
    return reportType.value === REPORTS.ADS_TOTAL_GROUPED_METRIC.value;
  });

  const tableMetricReportReportItemsGroupedByCampaign = computed(() => {
    return metricReportRows.value.map((row) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          row.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        campaign: row.name || row.campaign,
        ...dataValues,
      };
    });
  });

  const tableMetricReportReportItemsGroupedByCountry = computed(() => {
    return metricReportRows.value.map((row) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          row.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        country: row.name || row.country,
        ...dataValues,
      };
    });
  });

  const tableMetricReportReportItemsGroupedByRegion = computed(() => {
    return metricReportRows.value.map((row) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          row.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        region: row.name || row.region,
        ...dataValues,
      };
    });
  });

  const tableMetricReportReportItemsGroupedByAgeGender = computed(() => {
    return metricReportRows.value.map((row) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          row.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          row.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        ageGender: row.name || row.age_gender,
        ...dataValues,
      };
    });
  });

  const tableMetricReportReportItemsGroupedByChannel = computed(() => {
    return metricReportRows.value.map((channel) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          channel.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          channel.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          channel.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        channel: channel.name,
        ...dataValues,
      };
    });
  });

  const tableMetricReportReportItemsGroupedByBrand = computed(() => {
    return metricReportRows.value.map((brand) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          brand.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          brand.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          brand.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        brand,
        ...dataValues,
      };
    });
  });

  const tableMetricReportReportItemsGroupedByMedia = computed(() => {
    return metricReportRows.value.map((metricReportRow) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          metricReportRow.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          metricReportRow.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          metricReportRow.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        ...dataValues,
        mediaType: metricReportRow.mediaType?.tooltip,
      };
    });
  });

  const tableMetricReportItemsCompetitive = computed(() => {
    return metricReportRows.value.map((brand) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          brand.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          brand.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          brand.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      return {
        brand,
        ...dataValues,
      };
    });
  });

  const tableMetricReportItemsBenchmark = computed(() => {
    return metricReportRows.value.map((reportRow) => {
      const dataValues = {
        reportingPeriod: reportMetricDetails.value.formatMetricValue(
          reportRow.metric.value,
          FORMAT_TYPES.NORMAL,
        ),
        comparisonPeriod: reportMetricDetails.value.formatMetricValue(
          reportRow.metric.context,
          FORMAT_TYPES.NORMAL,
        ),
        change: reportMetricDetails.value.formatMetricValue(
          reportRow.metric.context_change,
          FORMAT_TYPES.NORMAL,
        ),
      };
      reportRow.subtitle = reportRow.follower_bucket;
      return {
        benchmark: reportRow,
        ...dataValues,
      };
    });
  });

  const extractTableMetricReportItems = computed(() => {
    if (reportType.value === REPORTS.MULTI_METRIC_MEDIA_TYPE.value) {
      return tableMetricReportReportItemsGroupedByMedia.value;
    }

    if (isCompetitiveReport.value) {
      return tableMetricReportItemsCompetitive.value;
    }

    if (isBenchmarkReport.value) {
      return tableMetricReportItemsBenchmark.value;
    }

    if (isAdsTotalGroupedMetricReport.value) {
      switch (aggregateBy.value) {
        case ORGANIC_TABLE_COLUMNS.BRAND:
          return tableMetricReportReportItemsGroupedByBrand.value;
        case ORGANIC_TABLE_COLUMNS.CHANNEL:
          return tableMetricReportReportItemsGroupedByChannel.value;
        case ADS_REPORT_AGGREGATE_BY_TYPES.COUNTRY.value:
          return tableMetricReportReportItemsGroupedByCountry.value;
        case ADS_REPORT_AGGREGATE_BY_TYPES.REGION.value:
          return tableMetricReportReportItemsGroupedByRegion.value;
        case ADS_REPORT_AGGREGATE_BY_TYPES.AGE_GENDER.value:
          return tableMetricReportReportItemsGroupedByAgeGender.value;
        case ADS_REPORT_AGGREGATE_BY_TYPES.SOURCE_CAMPAIGN.value:
          return tableMetricReportReportItemsGroupedByCampaign.value;
        case ORGANIC_TABLE_COLUMNS.MEDIA_TYPE:
          return tableMetricReportReportItemsGroupedByMedia.value;
        default:
          return tableMetricReportReportItemsGroupedByChannel.value;
      }
    }
    return aggregateBy.value === ORGANIC_TABLE_COLUMNS.BRAND
      ? tableMetricReportReportItemsGroupedByBrand.value
      : tableMetricReportReportItemsGroupedByChannel.value;
  });

  const singleMetricReportProps = computed(() => {
    if (loading) return { loading };
    return {
      channelGroups: {
        channels: channels.value,
      },
      value: formattedValue.value,
      change: reportBrandsMetrics.value[0].metric.context_change,
      metricTitle: metricTitle.value,
      avatarGroups: avatarGroups.value,
      titleTooltip: titleTooltip.value,
      reportChips: reportChips.value,
      tags: contentTagNames.value,
      loading,
      currency: null,
      mediaType: reportMetricDetails.value.mediaTypeLabel
        ? {
            ...mediaTypeIcons.value,
            tooltip: mediaTypeTooltips.value,
          }
        : null,
      menuItems: REPORT_MENU_ITEMS,
    };
  });

  const tableMetricReportProps = computed(() => {
    if (loading) return { loading };
    return {
      channelGroups: {
        channels: channels.value,
      },
      reportItems: extractTableMetricReportItems.value,
      metricTitle: metricTitle.value,
      avatarGroups: avatarGroups.value,
      titleTooltip: titleTooltip.value,
      reportChips: reportChips.value,
      tags: contentTagNames.value,
      loading,
      currency: null,
      mediaType: reportMetricDetails.value.mediaTypeLabel
        ? {
            ...mediaTypeIcons.value,
            tooltip: mediaTypeTooltips.value,
          }
        : null,
      menuItems: REPORT_MENU_ITEMS,
    };
  });

  return {
    contentTagIds,
    singleMetricReportProps,
    tableMetricReportProps,
    extractTableMetricReportItems,
    mediaTypeTooltips,
    metricTitle,
    dateRangeDisplay,
  };
}
