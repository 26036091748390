import { relationshipRouteNames } from '@/app/relationships/constants';

const App = () => import('@/app/relationships/App.vue');
const Instagram = () => import('@/app/relationships/pages/Instagram.vue');
const InstagramRelationships = () => import('@/app/relationships/pages/InstagramRelationships.vue');
const InstagramDiscovery = () => import('@/app/relationships/pages/InstagramDiscovery.vue');
const TikTokDiscovery = () => import('@/app/creators/pages/TikTokDiscovery.vue');
const CreatorPreview = () => import('@/app/relationships/pages/CreatorPreview.vue');
const Influencer = () => import('@/app/relationships/pages/Influencer.vue');

const PAGE_LEVEL_TOP = `Relationships`;
const PAGE_INSTAGRAM = `Instagram`;
const PAGE_TIKTOK = 'TikTok';

export default {
  path: 'relationships',
  component: App,
  children: [
    {
      name: 'relationships',
      path: '',
      redirect: { name: relationshipRouteNames.IG_CREATOR_DISCOVERY_PAGE },
    },
    {
      name: relationshipRouteNames.IG_OVERVIEW_PAGE,
      path: 'instagram',
      component: Instagram,
      redirect: { name: relationshipRouteNames.IG_YOUR_CREATORS_PAGE },
    },
    {
      name: relationshipRouteNames.IG_OVERVIEW_REPORT_PAGE,
      path: 'instagram/report/main',
      component: Instagram,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_INSTAGRAM} - Main Report`,
        report: true,
      },
    },
    {
      name: relationshipRouteNames.IG_DETAIL_PAGE,
      path: 'instagram/relationship/:id',
      component: InstagramRelationships,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_INSTAGRAM} - Detail`,
      },
    },
    {
      name: relationshipRouteNames.IG_DETAIL_REPORT_PAGE,
      path: 'instagram/:id/report',
      component: InstagramRelationships,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_INSTAGRAM} - Detail Report`,
        report: true,
      },
    },
    {
      name: relationshipRouteNames.IG_YOUR_CREATORS_PAGE,
      path: 'instagram/your-creators',
      component: Instagram,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_INSTAGRAM} - Your Creators`,
      },
    },
    {
      name: relationshipRouteNames.IG_CREATOR_DISCOVERY_PAGE,
      path: 'instagram/discovery',
      component: InstagramDiscovery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_INSTAGRAM} - Discovery`,
      },
    },
    {
      name: relationshipRouteNames.TT_CREATOR_DISCOVERY_PAGE,
      path: 'tiktok/discovery',
      component: TikTokDiscovery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_TIKTOK} - Discovery`,
      },
    },
    {
      name: relationshipRouteNames.IG_INFLUENCER_PAGE,
      path: 'instagram-influencer/:name',
      component: Influencer,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Instagram Influencer - Detail`,
      },
    },
    {
      name: relationshipRouteNames.IG_CREATOR_PREVIEW_PAGE,
      path: 'creators/instagram/profile/:sourceCreatorId',
      component: CreatorPreview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_INSTAGRAM} - Creator Preview`,
      },
    },
  ],
  meta: {
    requiresAuth: true,
    internal: true,
  },
};
