import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { loadScript } from '@/utils/dom';
import { isDatadogSyntheticsBrowser } from '@/config';
import { env } from '@/env';
import { useIdentityStore } from '@/stores/identity';
import * as AuthAPI from '@/apis/auth';
// import { useFlagStore } from './flag';

export const useHubspotStore = defineStore('hubspot', () => {
  const identityStore = useIdentityStore();
  const ready = ref(false);
  const ctaReady = ref(false);
  const isOpen = ref(false);
  const loadingWidget = ref(false);

  const showConversation = ref(false);

  // const flagStore = useFlagStore();

  const isGdsVersion = computed(() => true); // flagStore.ready && flagStore.flags.hupspotLiveChatGds);

  const enabled = env.hubspotEnabled && !isDatadogSyntheticsBrowser && !window.Cypress;

  // Initializing HubSpot Tracking Array
  window._hsq = [];

  function onConversationsAPIReady() {
    ready.value = true;
  }

  function onCallToActionAPIReady() {
    ctaReady.value = true;
  }

  function boot() {
    if (enabled) {
      window.hsConversationsSettings = {
        loadImmediately: false,
        inlineEmbedSelector: isGdsVersion.value ? '#dh-hubspot-chat' : undefined,
      };

      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }

      if (window.HubSpotCallsToActions) {
        onCallToActionAPIReady();
      } else {
        window.hsCallsToActionsReady = [onCallToActionAPIReady];
      }

      loadScript(`//js-na1.hs-scripts.com/${env.hubspotTrackingCode}.js`, {
        id: 'hs-script-loader',
      });
    }
  }

  function reset(resetWidget = false) {
    window.HubSpotConversations.clear({ resetWidget });
  }

  async function getToken() {
    try {
      const res = await AuthAPI.getHubspotSupportToken();
      return res.data.token;
    } catch (e) {
      return '';
    }
  }

  function loadWidget() {
    const status = window.HubSpotConversations.widget.status();
    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
      loadingWidget.value = true;
    }
  }

  async function initWidget() {
    reset();

    // Hubspot Chat Identity
    const identity = identityStore.identity;
    const email = identity.email;

    // Hubspot Tracking Identity
    window._hsq = [];
    window._hsq.push(['identify', { email }]);

    const token = await getToken();

    window.hsConversationsSettings.identificationEmail = email;
    window.hsConversationsSettings.identificationToken = token;

    window.HubSpotConversations.on('widgetLoaded', () => {
      loadingWidget.value = false;
    });
    window.HubSpotConversations.on('widgetClosed', () => {
      isOpen.value = false;
    });

    const loadDelay = isGdsVersion.value ? 10000 : 0;
    setTimeout(() => {
      loadWidget();
    }, loadDelay);
  }

  function show() {
    if (isGdsVersion.value) {
      loadWidget();
    }

    window.HubSpotConversations.widget.open();

    isOpen.value = true;
    showConversation.value = true;
  }

  function toggle() {
    if (!isGdsVersion.value) {
      show();
      return;
    }

    loadWidget();

    if (isOpen.value) {
      window.HubSpotConversations.widget.close();
    } else {
      window.HubSpotConversations.widget.open();
      isOpen.value = true;
    }
    showConversation.value = !showConversation.value;
  }

  function refreshCallToAction() {
    if (ctaReady.value) {
      window.HubSpotCallsToActions.refresh();
    }
  }

  function trackEvent({ eventName, eventDetails }) {
    window._hsq.push([eventName], eventDetails);
  }

  watch(
    () => ctaReady.value,
    () => {
      refreshCallToAction();
    },
    { immediate: true },
  );

  const chatTargetReady = ref(false);

  function chatMounted() {
    chatTargetReady.value = true;
  }

  function chatUnmounted() {
    chatTargetReady.value = false;
  }

  watch(
    () => ({
      identity: identityStore.identity,
      ready: ready.value,
      mounted: chatTargetReady.value,
    }),
    (context) => {
      if (enabled && context.ready && context.mounted) {
        if (context.identity) {
          initWidget();
        } else {
          reset();
        }
      }
    },
    { immediate: true },
  );

  return {
    isGdsVersion,
    loadingWidget,
    enabled,
    ready,
    showConversation,
    boot,
    show,
    toggle,
    refreshCallToAction,
    trackEvent,
    chatMounted,
    chatUnmounted,
  };
});
