import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';

const baseDashUrl = 'https://dashhudson.com';
export const LoginMethod = {
  any: 'any',
  email_password_only: 'email_password_only',
  sso_only: 'sso_only',
  oauth_only: 'oauth_only',
};

export const LoginMethodLabel = {
  any: 'All Enabled',
  oauth: 'Google OAuth',
  sso: 'Single Sign-On',
  email_password: 'Email & Password',
  oauth_only: 'Google OAuth Only',
  sso_only: 'Single Sign-On Only',
  email_password_only: 'Email & Password Only',
};

export const LoginMethodMessage = {
  sso_only: 'Please log in using the SSO option.',
  oauth_only: 'Please log in using the Google option.',
  email_password_only: 'Please log in using the email and password option.',
};

export const LoginFailedMessage = {
  sso_only: 'This organization requires users to log in via SSO.',
  oauth_only: 'This organization requires users to log in via Google.',
  email_password_only: 'This organization requires users to log in via email and password.',
};

export const INFLUENCER_CONNECT_FAQ = [
  {
    question: 'What is Dash Social?',
    answer:
      'The world’s most important companies use Dash Social to create and share photos and videos that people care about. Dash Social takes it to the next level by empowering brands to predict the performance of photos and videos, analyze trends, and accelerate brand growth across key visual marketing and e-commerce channels.',
  },
  {
    question: 'Why should I connect to Dash Social?',
    answer:
      'Influencers connect to Dash Social for a number of reasons. Mainly the convenience of the brands you’re partnering with automatically receiving the metrics for your feed posts and stories that feature them. Say goodbye to screenshots! Connecting is the only way a brand can view the stories you feature them in. Others find it strengthens the relationship between them and the brand they’re working with to allow for additional partnerships.',
  },
  {
    question: 'What do I need to connect?',
    answer: '',
    answerHtml: `
      <p>To connect with a brand in Dash Social you don't need a Dash Social account, but you do need:</p>
      <ul>
          <li class="list-disc ml-5 mt-1">Your Professional (business or creator) Instagram account to be connected to your Facebook business page.</li>
          <li class="list-disc ml-5 mt-1">To have a Facebook business page and be the Admin of that page.</li>
          <li class="list-disc ml-5 mt-1">To have Two-Factor Authentication in place.</li>
      </ul>
    `,
  },
  {
    question: 'What information will the brand receive when I connect?',
    answer:
      'The brand will receive metrics related to the feed posts or stories you feature them in. They also receive your overall account statistics such as number of followers, average reach, average engagement rate etc.',
  },
  {
    question:
      "I'm very careful about my account's privacy. When I connect, what will you do with my data and how will you keep it safe?",
    answer: '',
    answerHtml: `
     <p>As the Dash Social Platform is <a href='${baseDashUrl}/gdpr' target='_blank'>GDPR</a> and
        <a href='${baseDashUrl}/ccpa' target='_blank'>CCPA</a> compliant, we are mandated to follow specific
        guidelines/controls with respect to
        <a href='${baseDashUrl}/privacy' target='_blank'>Privacy</a> and
        <a href='${baseDashUrl}/security' target='_blank'>Security</a> and
        we have a compliance program in place to ensure that these are followed.
     </p>
    `,
  },
];

export const TIKTOK_CREATOR_CONNECT_FAQ = [
  {
    question: 'What is Dash Social?',
    answer:
      'Dash Social is a social media management platform that equips brands with intelligence and speed to stay ahead of the curve. Through its sophisticated cross-channel insights and workflow tools, Dash Social enables brands to create content that entertains, engages and drives consistent business results.',
  },
  {
    question: 'Why should I connect to Dash Social?',
    answer:
      "Creators choose to connect with Dash Social for several reasons, with convenience being a top benefit. By connecting, the brands you collaborate with automatically receive the performance metrics for your feed posts and stories that feature them—no more manual screenshots or performance reports! It's also the only way brands can access the insights from the Stories you tag or mention them. Many creators find that sharing these metrics strengthens their relationship with brands and opens the door for future partnerships.",
  },
  {
    question: 'What do I need to connect?',
    answer:
      "You don't need a Dash Social account to connect with a brand. You can connect using any type of TikTok account.",
  },
  {
    question: 'What information will the brand receive when I connect?',
    answer: '',
    answerHtml: `
      <p>The brand will receive the following metrics related to your account & the posts you feature them in.</p>
      <br>
      <p class="dash-typo-lg-semibold">Account:</p>
      <ul>
        <li class="list-disc ml-5 mt-1">Vacation (Start/End)</li>
        <li class="list-disc ml-5 mt-1">Location (Country Only)</li>
        <li class="list-disc ml-5 mt-1">Creator Rate</li>
        <li class="list-disc ml-5 mt-1">Average Views</li>
        <li class="list-disc ml-5 mt-1">6 Sec View Rate</li>
        <li class="list-disc ml-5 mt-1">Engagement Rate</li>
        <li class="list-disc ml-5 mt-1">Completion Rate</li>
        <li class="list-disc ml-5 mt-1">Average Likes</li>
        <li class="list-disc ml-5 mt-1">Average Shares</li>
        <li class="list-disc ml-5 mt-1">Average Comments</li>
        <li class="list-disc ml-5 mt-1">Benchmark Metrics</li>
        <li class="list-disc ml-5 mt-1">Sponsored Video Metrics</li>
      </ul>
      <br>
      <p class="dash-typo-lg-semibold">Audience:</p>
      <ul>
        <li class="list-disc ml-5 mt-1">Location</li>
        <li class="list-disc ml-5 mt-1">Genders</li>
        <li class="list-disc ml-5 mt-1">Age</li>
        <li class="list-disc ml-5 mt-1">Device</li>
        <li class="list-disc ml-5 mt-1">Usage</li>
        <li class="list-disc ml-5 mt-1">Views</li>
      </ul>
      <br>
      <p class="dash-typo-lg-semibold">Post Level:</p>
      <ul>
        <li class="list-disc ml-5 mt-1">Engagement Count</li>
        <li class="list-disc ml-5 mt-1">Engagement Rate</li>
      </ul>
    `,
  },
];

export const INSTAGRAM_CREATOR_CONNECT_FAQ = [
  {
    question: 'What is Dash Social?',
    answer:
      'Dash Social is a social media management platform that equips brands with intelligence and speed to stay ahead of the curve. Through its sophisticated cross-channel insights and workflow tools, Dash Social enables brands to create content that entertains, engages and drives consistent business results.',
  },
  {
    question: 'Why should I connect to Dash Social?',
    answer:
      "Creators choose to connect with Dash Social for several reasons, with convenience being a top benefit. By connecting, the brands you collaborate with automatically receive the performance metrics for your feed posts and stories that feature them—no more manual screenshots or performance reports! It's also the only way brands can access the insights from the Stories you tag or mention them. Many creators find that sharing these metrics strengthens their relationship with brands and opens the door for future partnerships.",
  },
  {
    question: 'What do I need to connect?',
    answer: '',
    answerHtml: `
      <p>You don’t need a Dash Social account to connect with a brand, but you will need the following:</p>
      <ul>
          <li class="list-disc ml-5 mt-1">Your Professional (business or creator) Instagram account to be connected to your Facebook business page.</li>
          <li class="list-disc ml-5 mt-1">To have a Facebook business page and be the Admin of that page.</li>
          <li class="list-disc ml-5 mt-1">To have Two-Factor Authentication in place.</li>
      </ul>
    `,
  },
  {
    question: 'What information will the brand receive when I connect?',
    answer: '',
    answerHtml: `
      <p>The brand will receive the following metrics related to the posts/reels or stories you feature them in.</p>
      <br>
      <p class="dash-typo-lg-semibold">Feed/Reel:</p>
      <ul>
        <li class="list-disc ml-5 mt-1">Impressions</li>
        <li class="list-disc ml-5 mt-1">Reach</li>
        <li class="list-disc ml-5 mt-1">Shares</li>
        <li class="list-disc ml-5 mt-1">Saves</li>
      </ul>
      <br>
      <p class="dash-typo-lg-semibold">Stories:</p>
      <ul>
        <li class="list-disc ml-5 mt-1">Avg. Completion Rate</li>
        <li class="list-disc ml-5 mt-1">Avg. Exit Rate</li>
        <li class="list-disc ml-5 mt-1">Avg. Impressions</li>
        <li class="list-disc ml-5 mt-1">Avg. Reach</li>
        <li class="list-disc ml-5 mt-1">Completion Rate</li>
        <li class="list-disc ml-5 mt-1">Reach</li>
        <li class="list-disc ml-5 mt-1">Replies</li>
        <li class="list-disc ml-5 mt-1">Stories ft. You</li>
        <li class="list-disc ml-5 mt-1">Taps Back</li>
        <li class="list-disc ml-5 mt-1">Taps Forward</li>
      </ul>
    `,
  },
];

export const influencerInviteFaqCopy = {
  MORE_QUESTIONS: 'Have more questions?',
  LEARN: 'Learn more',
};

const influencerInviteCopyCommon = {
  title: {
    REVOKED: 'You have revoked your acceptance',
  },
  revoked: {
    PARAGRAPH_ONE_GROUP_ONE:
      'To work with this brand again, please return to your original email invite and click to reconnect, or ask the brand to send a new invite.',
  },
};

export const influencerInviteCopy = {
  title: {
    JOIN_NAMES: 'is inviting',
    SUFFIX: 'to collaborate with them',
    ...influencerInviteCopyCommon.title,
  },
  accept: {
    ACCEPT_INVITE: 'Accept Invite',
  },
  connected: {
    PARAGRAPH_ONE_GROUP_ONE: 'Accepting the invite will allow',
    PARAGRAPH_ONE_GROUP_THREE:
      'to see metrics and stats for feed or story posts you @mention or photo tag',
    PARAGRAPH_ONE_GROUP_FIVE:
      'in, without you needing to send screenshots or email stats yourself!',
  },
  disconnected: {
    PARAGRAPH_ONE_GROUP_ONE: 'is inviting you to connect your Instagram account',
    PARAGRAPH_ONE_GROUP_THREE: 'to the Dash Social platform and collaborate with them.',
    PARAGRAPH_TWO_GROUP_ONE: 'Connecting will allow',
    PARAGRAPH_TWO_GROUP_THREE:
      'to see metrics and stats for feed or story posts you @mention or photo tag',
    PARAGRAPH_TWO_GROUP_FIVE:
      'in, without you needing to send screenshots or email stats yourself!',
    PARAGRAPH_THREE_GROUP_ONE:
      'To accept the invite and connect, please click on the button below.',
  },
  revoked: {
    ...influencerInviteCopyCommon.revoked,
  },
};

export const creatorInviteCopy = {
  title: ['is inviting', 'to share more metrics on'],
  accept: 'To accept the invite and connect, please click on the “Accept & Connect” button below.',
};

export const influencerInviteSuccessCopy = {
  common: {
    REVOCATION_GROUP: 'revoke your acceptance.',
    FAQ_GROUP: 'here.',
  },
  title: {
    PREFIX: 'You have accepted the invite from',
    JOIN_NAMES: 'for',
    FALLBACK: 'You have accepted the invite!',
    REVOKED: 'You have revoked your acceptance',
  },
  success: {
    PARAGRAPH_ONE_GROUP_ONE: 'If you want to stop working with',
    PARAGRAPH_ONE_GROUP_THREE: 'you can',
    PARAGRAPH_ONE_GROUP_FOUR: 'revoke your acceptance.',
  },
  fallback: {
    PARAGRAPH_ONE_GROUP_ONE: 'If you want to stop working with them,',
  },
  revoked: {
    ...influencerInviteCopyCommon.revoked,
  },
};

export const creatorInviteSuccessCopy = {
  title: "You're All Set!",
  common: {
    REVOCATION_GROUP: 'revoke your acceptance.',
  },
  success: {
    PARAGRAPH_ONE: ["You've accepted the invite from", 'for', '.'],
    PARAGRAPH_TWO: ['To remove', 'access to your stats and metrics, you can'],
  },
};

export const creatorInviteErrorCopy = {
  title: 'Connection Failed',
  instagram: {
    message:
      "The account you're attempting to connect may not be a Professional account, there may not be a linked Facebook Page, or you may not have the correct permissions.",
  },
  tiktok: {
    message:
      'Something went wrong connecting your TikTok account. Please try again or contact us if the issue persists.',
  },
};

export const creatorInviteRevokedCopy = {
  title: 'Your connection has been revoked',
  message: [
    'will no longer receive additional metrics from your tagged posts on',
    '. To collaborate with this brand again, revisit your original email invite and click the link to connect or request a new invite from the brand.',
  ],
};

export const revokeErrorCopy = {
  title: 'Something went wrong',
  message: 'We were unable to revoke your data connection. Please try again.',
};

export const instagramBusinessAccount = {
  title: 'Connection Failed',
  message:
    'Please ensure you are an admin on your Facebook Business page, that page is connected to your Professional Instagram account, and your two-factor authentication is enabled.',
};

export const onboardingWalnutTourLink =
  'https://app.teamwalnut.com/player/?demoId=39c9ae30-c192-43a4-80aa-e0dac6933d46&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app';

export const accountSelectionNotFoundMessage = Object.freeze({
  facebook: {
    title: 'No Facebook Page Found',
    message: {
      prefix:
        'You may not have the correct permissions for the Page you are attempting to connect. ',
      suffix: ' for help.',
    },
    link: {
      text: 'Visit Troubleshooting your Facebook Page Connection',
      url: 'https://help.dashsocial.com/hc/en-us/articles/4415312938125-Troubleshooting-Your-Facebook-Page-Connection',
    },
    buttonText: 'OK',
  },
  instagram: {
    title: 'No Instagram Account Found',
    message: {
      prefix:
        'The account you’re attempting to connect may not be a Professional account, there may not be a linked Facebook Page, or you may not have the correct permissions. ',
      suffix: ' for help and troubleshooting.',
    },
    link: {
      text: 'Visit Connecting your Instagram Account to Dash Social',
      url: 'https://help.dashsocial.com/hc/en-us/articles/11979158014989-Connecting-your-Instagram-Account-to-Dash-Hudson-#heading-1',
    },
    buttonText: 'OK',
  },
  linkedin: {
    title: 'No LinkedIn Page Found',
    message: {
      prefix:
        'The Page you’re attempting to connect may not be a Company Page or you may not have the correct permissions. Visit ',
      suffix: ' for help and troubleshooting.',
    },
    link: {
      text: 'Connecting Your Company LinkedIn Page to Dash Social',
      url: 'https://help.dashsocial.com/hc/en-us/articles/19523630216717',
    },
    buttonText: 'OK',
  },
});

export const accountSelectionInfoBoxCopy = {
  instagram: {
    content:
      'If the account you’re looking for is not in this list or you do not have the correct permissions visit <a href="https://help.dashsocial.com/hc/en-us/articles/11979158014989-Connecting-your-Instagram-Account-to-Dash-Hudson-#heading-1" target="_blank">Connecting your Instagram Account to Dash Social</a> for detailed troubleshooting.',
  },
  facebook: {
    content:
      'If the Page you’re looking for is not in this list or you do not have the correct permissions visit <a href="https://help.dashsocial.com/hc/en-us/articles/11505763603853-Connecting-Your-Facebook-Page-to-Dash-Hudson" target="_blank">Connecting Your Facebook Page to Dash Social</a> for detailed troubleshooting.',
  },
  linkedin: {
    content:
      'If the Page you’re looking for is not in this list or you do not have the correct permissions visit <a href="https://help.dashsocial.com/hc/en-us/articles/19523630216717" target="_blank">Connecting Your Company LinkedIn Page to Dash Social</a> for detailed troubleshooting.',
  },
};

export const accountSelectionLoadingInfoCopy = {
  linkedin: 'It may take up to 10 seconds to load your available Page(s)',
};

export const influencerGetHelp = {
  FAQ_URL: 'https://dashhudson.com/influencer-faq/',
  GET_HELP_CTA: 'Having trouble connecting to Dash Social?',
};

export const influencerEventNames = {
  ACCEPT_CLICKED: 'Relationship Influencer Accept Invite',
  REVOKE: 'Relationship Influencer Revoke Acceptance',
  CONNECTION_SUCCESS: 'Relationship Influencer Connection Success',
  CONNECTION_FAILED: 'Relationship Influencer Connection Failed',
};

export const influencerCacheKeys = {
  INVITED_BY_NAME: 'connect-relationship-invited-by-name',
  INFLUENCER_NAME: 'connect-relationship-influencer-name',
};

export const creatorCacheKeys = {
  LANDING_PAGE_PATH: 'connect-creator-landing-path',
  INVITED_BY_BRAND_ID: 'connect-creator-invited-by-brand-id',
  INVITED_BY_BRAND_NAME: 'connect-creator-invited-by-brand-name',
  INVITED_BY_BRAND_AVATAR_URL: 'connect-creator-invited-by-brand-avatar-url',
  CHANNEL: 'connect-creator-channel',
  CREATOR_HANDLE: 'connect-creator-handle-name',
  SOURCE_CREATOR_ID: 'connect-creator-source-id',
  AVATAR_URL: 'connect-creator-avatar-url',
  ERROR_MESSAGE: 'connect-creator-error-message',
};

export const defaultErrorMessage = 'Something went wrong, please try again.';

export const invalidPermissionsTooltips = {
  facebook: 'You don’t have the correct permissions for this Page',
  instagram: 'You don’t have the correct permissions for this account',
  linkedin: 'You must be a Super Admin to connect this Page',
};

export const educationalModalCopy = Object.freeze({
  facebook: {
    info: [
      {
        content:
          'In order to connect, you must have the correct permissions on the Facebook Page within both Meta business settings and the Facebook Page itself.',
        icon: 'user-shield',
        style: 'default',
        title: 'Permissions',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/11505763603853-Connecting-Your-Facebook-Page-to-Dash-Hudson',
      },
    ],
  },
  // TODO sc-106472: Remove this legacy copy
  facebook_ads: {
    info: [
      {
        content:
          'The Facebook ad connection pulls in promoted metrics for boosted Instagram posts and reels. You will have access to the breakdown of organic vs. promoted Impressions, Video Views, Likes, Comments, and Engagements. <strong>This connection does not import Facebook or Instagram ads.</strong>',
        icon: 'link',
        style: 'default',
        title: 'Facebook Ad Connection',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
      {
        content: 'A Facebook ad account must be associated with your Meta business account.',
        icon: 'megaphone',
        style: 'default',
        title: 'Facebook Ad Account',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
      {
        content:
          'In order to connect, you must have access to the Facebook ad account you would like to link with Dash Social.',
        icon: 'user-shield',
        style: 'default',
        title: 'Account Access',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
      {
        content:
          'Promoted metrics will only pull in if you select the Facebook ad account that is also linked to the Instagram account you have connected to Dash Social.',
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
    ],
  },
  // TODO sc-106472: Remove _new and make this the default
  facebook_ads_new: {
    info: [
      {
        content:
          'This ad connection pulls in available promoted metrics for boosted Instagram posts and Reels. Based on your plan, this connection is also required for importing Facebook and Instagram Ads data and comments.',
        icon: 'meta',
        style: 'default',
        title: 'Meta Ads Connection',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
      {
        content: 'A Facebook ad account must be associated with your Meta Business account.',
        icon: 'link',
        style: 'default',
        title: 'Meta Business Account',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
      {
        content:
          'In order to connect, you must have permissions for the Meta Business account you would like to link with Dash Social.',
        icon: 'user-shield',
        style: 'default',
        title: 'Account Access',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
      {
        content:
          'Promoted metrics will only pull in if you select the Facebook ad account that is also linked to the Instagram account you have connected to Dash Social.',
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408951687181-Connecting-Your-Facebook-Ads-Account-to-Dash-Hudson',
      },
    ],
  },
  ga: {
    info: [
      {
        content:
          'In order to connect, you must have access to the Google Analytics account you would like to link with Dash Social.',
        icon: 'user-shield',
        style: 'default',
        title: 'Account Access',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/12485992646797-Measuring-your-Campaign-by-Integrating-Google-Analytics#heading-2',
      },
      {
        content:
          'When reviewing permissions, please ensure "See and download your Google Analytics data" is selected.',
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  instagram: {
    info: [
      {
        content: 'In order to connect, you must have an Instagram professional account.',
        icon: 'store',
        style: 'default',
        title: 'Instagram Professional Account',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/11979158014989-Connecting-your-Instagram-Account-to-Dash-Hudson-',
      },
      {
        content: 'Your Instagram professional account must be linked to a Facebook Page.',
        icon: 'link',
        style: 'default',
        title: 'Linked Facebook Page',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/11979158014989-Connecting-your-Instagram-Account-to-Dash-Hudson-#heading-2',
      },
      {
        content:
          'In order to connect, you must have the correct access permissions on the Facebook Page within both Meta business settings and the Facebook Page itself.',
        icon: 'user-shield',
        style: 'default',
        title: 'Permissions',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/11505763603853-Connecting-Your-Facebook-Page-to-Dash-Hudson#heading-2',
      },
      {
        content:
          "You'll need to verify your personal Facebook account in order to connect your Instagram account.",
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  linkedin: {
    info: [
      {
        content: 'In order to connect, you must be a Super Admin on your LinkedIn Company Page.',
        icon: 'user-shield',
        style: 'default',
        title: 'Page and Permissions',
        linkText: 'Learn More',
        linkUrl: 'https://help.dashsocial.com/hc/en-us/articles/19523630216717',
      },
      {
        content:
          "You'll need to verify your personal LinkedIn account in order to connect your Company Page.",
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  pinterest: {
    info: [
      {
        content: 'In order to connect, you must have a Pinterest business account.',
        icon: 'store',
        style: 'default',
        title: 'Pinterest Business Account',
        linkText: 'Learn More',
        linkUrl: null,
      },
      {
        content:
          "To ensure you connect the right account, open a new browser tab and log into the Pinterest account you'd like to connect.",
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  tiktok: {
    info: [
      {
        content:
          'You can connect TikTok Business and Personal accounts to Dash Social. If you are connecting a Personal Account, you must have analytics enabled in your TikTok settings.',
        icon: 'user',
        style: 'default',
        title: 'Supported Account Types',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/6045974975757-Connecting-Your-TikTok-Account-to-Dash-Hudson',
      },
      {
        content:
          "To ensure you connect the right account, open a new browser tab and log into the TikTok account you'd like to connect.",
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  tiktok_ads: {
    info: [
      {
        content:
          'This connection is required to create Social Advertising reports for your brand in Dashboards.',
        icon: 'tiktok',
        style: 'default',
        title: 'TikTok Ads Connection',
        linkText: 'Learn More',
        linkUrl: null,
      },
      {
        content: 'A TikTok Ad account must be associated with your TikTok Business Center account.',
        icon: 'link',
        style: 'default',
        title: 'TikTok Business Account',
        linkText: 'Learn More',
        linkUrl: 'https://help.dashsocial.com/hc/en-us/articles/24745503489549',
      },
      {
        content:
          'In order to connect, you must have admin permissions within TikTok Business Center for the handles connected to your TikTok Ad accounts.',
        icon: 'user-shield',
        style: 'default',
        title: 'Account Access',
        linkText: 'Learn More',
        linkUrl: 'https://help.dashsocial.com/hc/en-us/articles/24745503489549',
      },
    ],
  },
  tiktok_creator_marketplace: {
    info: [
      {
        content:
          'This connection is required to find and work with TikTok Creators through Dash Social.',
        icon: 'tiktok',
        style: 'default',
        title: 'TikTok Creator Marketplace Connection',
        linkText: 'Learn More',
        linkUrl: null,
      },
      {
        content:
          'Your TikTok Creator Marketplace account must be verified and associated with your TikTok Business Center account.',
        icon: 'link',
        style: 'default',
        title: 'TikTok Business Account',
        linkText: 'Learn More',
        linkUrl: 'https://help.dashsocial.com/hc/en-us/articles/24745503489549',
      },
      {
        content:
          'In order to connect, you must have admin permissions within TikTok Business Center for the handles connected to your TikTok Creator Marketplace account.',
        icon: 'user-shield',
        style: 'default',
        title: 'Account Access',
        linkText: 'Learn More',
        linkUrl: 'https://help.dashsocial.com/hc/en-us/articles/24745503489549',
      },
      {
        content:
          'If you already have a TikTok Ads account, you can use it to sign in to the TikTok Creator Marketplace.',
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  twitter: {
    info: [
      {
        content:
          'In order to connect, you must have an X Ads account with a confirmed payment method and at least one campaign.',
        icon: 'megaphone',
        style: 'default',
        title: 'X Ads Account',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408943597453-Connecting-your-Twitter-Account-to-Dash-Hudson#heading-2',
      },
      {
        content:
          'You must be an Account administrator on the X Ads account you are attempting to connect.',
        icon: 'user-shield',
        style: 'default',
        title: 'Admin Permissions',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/4408943597453-Connecting-your-Twitter-Account-to-Dash-Hudson#heading-3',
      },
    ],
  },
  youtube: {
    info: [
      {
        content:
          'In order to connect, you must have Owner level admin permissions on your YouTube channel.',
        icon: 'user-shield',
        style: 'default',
        title: 'Owner Permissions',
        linkText: 'Learn More',
        linkUrl:
          'https://help.dashsocial.com/hc/en-us/articles/5894661996045-Connecting-Your-YouTube-Channel-to-Dash-Hudson',
      },
      {
        content:
          "You'll need to confirm your Google Account and authorize Dash Social on YouTube. Ensure you select all of the permissions.",
        icon: 'light-bulb',
        style: 'dotted',
        title: 'Tip',
        linkText: 'Learn More',
        linkUrl: null,
      },
    ],
  },
  threads: {
    info: [
      {
        content:
          'You must have access to the Instagram account associated with the Threads account you are connecting.',
        icon: 'instagram',
        style: 'default',
        title: 'Instagram Account Access',
      },
      {
        content:
          'When reviewing permissions, please ensure "Manage insights of posts on Threads" is selected on the following screen.',
        icon: 'user-shield',
        style: 'default',
        title: 'Permissions',
      },
    ],
  },
  bluesky: {
    info: [
      {
        content:
          'Your Bluesky account must be hosted on the Bluesky server. Self-hosted accounts are not currently supported.',
        icon: 'light-bulb',
        style: 'default',
        title: 'Bluesky Account Access',
        linkText: 'Learn More',
        linkUrl: 'https://help.dashsocial.com/hc/en-us/articles/34458044704269',
      },
    ],
  },
});

export const connectionNotifications = Object.freeze({
  facebook: {
    error: {
      message:
        'Instagram not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your Instagram account is now connected. It may take up to an hour to populate your data.',
    },
  },
  facebook_analytics: {
    error: {
      message:
        'Facebook not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your Facebook Page is now connected. It may take up to an hour to populate your data.',
    },
  },
  facebook_ads: {
    error: {
      message:
        'Facebook ad not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your Facebook ad account is now connected. It may take up to an hour to populate your data.',
    },
  },
  // TODO sc-106472: Remove _v2 and remove legacy copy above
  facebook_ads_v2: {
    error: {
      message:
        'Meta Ads not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your Meta Ads account is now connected. It may take up to an hour to populate your data.',
    },
    update: {
      message: 'Meta Ads connection has been updated.',
    },
  },
  ga: {
    error: {
      message:
        'Google Analytics not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your Google Analytics account is now connected',
    },
  },
  pinterest: {
    error: {
      message:
        'Pinterest not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your Pinterest account is now connected. It may take up to an hour to populate your data.',
    },
  },
  tiktok: {
    error: {
      message:
        'TikTok not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your TikTok account is now connected. It may take up to an hour to populate your data.',
    },
  },
  tiktok_ads: {
    error: {
      message:
        'TikTok Ads account not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your TikTok Ads account is now connected. It may take up to an hour to populate your data.',
    },
  },
  tiktok_creator: {
    error: {
      message:
        'TikTok not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your TikTok account is now connected.',
    },
  },
  tiktok_creator_marketplace: {
    error: {
      message:
        'TikTok Creator Marketplace account not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your TikTok Creator Marketplace account is now connected. It may take up to an hour to populate your data.',
    },
    update: {
      message: 'Your TikTok Creator Marketplace connection has been updated.',
    },
  },
  twitter: {
    error: {
      message:
        'X not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your X account is now connected. It may take up to an hour to populate your data.',
    },
  },
  youtube: {
    error: {
      message:
        'YouTube not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message:
        'Your YouTube channel is now connected. It may take up to 72 hours to populate your data.',
    },
  },
  linkedin: {
    error: {
      message:
        'LinkedIn not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your LinkedIn Page is now connected',
    },
  },
  threads: {
    error: {
      message:
        'Threads not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your Threads profile is now connected',
    },
  },
  bluesky: {
    error: {
      message:
        'Bluesky not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your Bluesky account is now connected',
    },
  },
  instagram_influencer: {
    error: {
      message:
        'Instagram not connected. Please complete sign-in and grant full permissions for connection.',
      type: 'error',
    },
    success: {
      message: 'Your Instagram account is now connected',
    },
  },
});

export const providerChannelMap = Object.freeze({
  facebook: 'instagram',
  facebook_analytics: 'facebook',
});

export const accountSelectionTitleMap = Object.freeze({
  facebook: 'Select a Page',
  linkedin: 'Select a Page',
  instagram: 'Select an Account',
});

export const channelNameMap = Object.freeze({
  instagram: 'Instagram',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
});

export const exploreDHButtonTooltip =
  'A social connection is required to access analytics and workflow tools. You can disconnect at any time.';

export const exploreDHButtonRoute = {
  [PLATFORM_CONNECTION.FACEBOOK.value]: 'instagram.insights.yourPosts',
  [PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value]: 'facebook.yourPosts',
  [PLATFORM_CONNECTION.LINKEDIN.value]: 'linkedin.yourPosts',
  [PLATFORM_CONNECTION.PINTEREST.value]: 'pinterest.insights.pins',
  [PLATFORM_CONNECTION.TWITTER.value]: 'twitter.yourTweets',
  [PLATFORM_CONNECTION.TIKTOK.value]: 'tiktok.yourPosts',
  [PLATFORM_CONNECTION.YOUTUBE.value]: 'youtube.yourVideos',
};

export const setPassWordPageCopy = Object.freeze({
  pageTitle: 'Set Your Password',
  loginCopy: 'Already have an account?',
});

export const onboardSteps = Object.freeze({
  1: {
    sidebarContent: {
      numStars: 5,
      title: 'Easy-to-use interface and intuitive UX',
      body: 'I have used many social media marketing tools and Dash Social is by far my favourite.',
      authorName: 'Courtney H.',
      authorPosition: 'Social Media Manager',
      source: 'Via G2',
    },
  },
  2: {
    sidebarContent: {
      numStars: 5,
      title: 'A must-have tool for industry professionals',
      body: 'Dash Social is the best all encompassing tool for social media marketers.',
      authorName: 'Michelle P.',
      authorPosition: 'Fashion & Lifestyle Blogger',
      source: 'Via G2',
    },
  },
  3: {
    time: '3 minutes',
    sidebarContent: {
      numStars: 4.5,
      title: 'Key cross-channel metrics in one place',
      body: 'Instant cross-channel reporting all in one place.',
      authorName: 'Sophie D.',
      authorPosition: 'Head of Social, Content & Community',
      source: 'Via G2',
    },
  },
  4: {
    time: '1 minute',
    sidebarContent: {
      numStars: 5,
      title: 'Easy collaboration across multiple teams',
      body: 'We couldn’t do our job without this program and team!',
      authorName: 'Sonya L.',
      authorPosition: 'Director of Digital',
      source: 'Via G2',
    },
  },
});

export const onboardingInterview = Object.freeze({
  responsibilitiesForm: {
    options: [
      'Social Media Strategy',
      'Content Creation or Publishing',
      'Analytics & Reporting',
      'Social Commerce',
      'Community Management',
      'Influencer Management',
      'Campaign Management',
      'I do all of the above.',
    ],
  },
  goalForm: {
    options: [
      {
        label: 'Measure owned performance',
        description: 'with our multi-channel Analytics.',
        key: 'measure_performance',
      },
      {
        label: 'Drive social commerce revenue',
        description: 'with our link-in-bio solution, LikeShop.',
        key: 'social_commerce_revenue',
      },
      {
        label: 'Plan and publish content',
        description: 'with our multi-channel Scheduler.',
        key: 'publish_content',
      },
    ],
  },
});

export const brandTagNotifications = Object.freeze({
  deleteTagConfirm: {
    title: 'Delete Tag?',
    message:
      'This action will permanently delete it from your list of tags and remove it from all competitors it was associated with. Are you sure you want to proceed?',
    confirm: 'Delete',
  },
  deleteTagSuccess: {
    message: 'Tags have been removed.',
  },
  deleteTagError: {
    message: 'Failed to remove tag, please try again.',
  },
});
