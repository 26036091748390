/**
 * Directly ported from our Vuex implementation at @/store/commenting.js, comments included.
 */
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import * as CollaborationAPI from '@/apis/collaboration';
import humps from 'humps';

export const useCommentingStore = defineStore('commenting', {
  state: () => ({
    users: {},
    notifications: [],
    notificationsPaging: {},
    comments: [],
    newComment: null,
    newCommentText: '',
    pending: {
      comments: false,
      newComment: false,
      notificationsPaging: false,
      notifications: false,
    },
  }),
  getters: {
    hasUnsavedComments: (state) =>
      state.newCommentText?.trim() ||
      state.comments.some(
        (comment) =>
          comment._reply_text?.trim() ||
          (comment._edit_text && comment._edit_text !== comment.text) ||
          comment.replies?.some?.((reply) => reply._edit_text && reply._edit_text !== reply.text),
      ),
  },
  actions: {
    async getNotifications() {
      this.pending.notifications = true;
      try {
        const response = await CollaborationAPI.getNotifications();
        const page = response?.data;
        this.notifications = page.data || [];
        this.notificationsPaging = page.paging;
        if (page?.referencedObjects?.user) {
          this.users = {
            ...this.users,
            ...page.referencedObjects.user,
          };
        }
      } finally {
        this.pending.notifications = false;
      }
    },
    async getNotificationsNextPage() {
      const next = this.notificationsPaging?.next;
      const loading = this.pending.notificationsPaging;
      if (next && !loading) {
        this.pending.notificationsPaging = true;
        try {
          const response = await CollaborationAPI.getNotifications({ next });
          const page = response?.data;
          const existingNotificationIds = this.notifications.map((notification) => notification.id);
          const newNotifications = (page?.data || []).filter(
            (notification) => !existingNotificationIds.includes(notification.id),
          );
          this.notifications = [...this.notifications, ...newNotifications];
          this.notificationsPaging = page.paging;
          if (page?.referencedObjects?.user) {
            this.users = {
              ...this.users,
              ...page.referencedObjects.user,
            };
          }
        } finally {
          this.pending.notificationsPaging = false;
        }
      }
    },
    async markNotificationRead({ id }) {
      this.notifications = this.notifications.map((notification) => ({
        ...notification,
        readAt: notification.id === id ? dayjs().toISOString() : notification.readAt,
        originalReadAt: notification.readAt,
      }));

      try {
        const response = await CollaborationAPI.updateNotification({ id, readAt: 'NOW' });
        const page = response?.data;
        this.notifications = this.notifications.map((notification) =>
          notification.id === id ? page.data : notification,
        );
        if (page?.referencedObjects?.user) {
          this.users = {
            ...this.users,
            ...page.referencedObjects.user,
          };
        }
      } catch (error) {
        this.notifications = {
          ...this.notifications,
          // eslint-disable-next-line camelcase
          data: this.notifications?.data?.map(({ originalReadAt, ...notification }) => ({
            ...notification,
            // eslint-disable-next-line camelcase
            readAt: notification.id === id ? originalReadAt : notification.readAt,
          })),
        };
        throw error;
      }
    },
    async markNotificationsRead({ ids = [] } = {}) {
      // pre-emptively mark notifications read before we send the request
      this.notifications = this.notifications.map((notification) => {
        // If notification ids are specific, mark only those as read. Otherwise mark all as read.
        const markAsRead = !ids || ids.includes(notification.id);
        return {
          ...notification,
          readAt: markAsRead ? notification.readAt || dayjs().toISOString() : notification.readAt,
          originalReadAt: notification.readAt,
        };
      });

      try {
        const response = await CollaborationAPI.updateNotifications({ ids, readAt: 'NOW' });
        const page = response?.data;
        this.notifications = Object.freeze(page.data || []);
        this.notificationsPaging = page.paging;
        if (page?.referencedObjects?.user) {
          this.users = {
            ...this.users,
            ...page.referencedObjects.user,
          };
        }
      } catch (error) {
        // eslint-disable-next-line camelcase
        this.notifications = this.notifications.map(({ originalReadAt, ...notification }) => ({
          ...notification,
          // reset to previous readAt state
          // eslint-disable-next-line camelcase
          readAt: originalReadAt,
        }));
      }
    },
    async getComments({ brandIds, resourceId, resourceType }) {
      this.pending.comments = true;
      try {
        const response = await CollaborationAPI.getComments({ brandIds, resourceId, resourceType });
        const page = response.data;
        this.comments = page?.data;
        if (page?.referencedObjects?.user) {
          this.users = {
            ...this.users,
            ...page.referencedObjects.user,
          };
        }
      } finally {
        this.pending.comments = false;
      }
    },
    async createComment({ brandId, resourceId, resourceType, text, parentId }) {
      this.pending.newComment = true;
      try {
        const response = await CollaborationAPI.createComment({
          brandId,
          resourceId,
          resourceType,
          text,
          parentId,
        });
        const payload = response?.data;
        const newComment = payload?.data;
        if (newComment.parentId) {
          this.comments = this.comments.map((comment) => ({
            ...comment,
            replies:
              newComment.parentId === comment.id
                ? [...(comment.replies ?? []), newComment]
                : comment.replies,
          }));
        } else {
          this.comments = [...this.comments, newComment];
        }
        if (payload?.referencedObjects?.user) {
          this.users = {
            ...this.users,
            ...payload.referencedObjects.user,
          };
        }
      } finally {
        this.pending.newComment = false;
      }
    },
    async updateComment({ id, text }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        replies: comment.replies?.map?.((reply) => ({
          ...reply,
          _saving: reply.id === id || reply._saving,
        })),
        _saving: comment.id === id || comment._saving,
      }));

      try {
        const response = await CollaborationAPI.updateComment({ id, text });
        const payload = response.data;
        const updatedComment = payload?.data;
        this.comments = this.comments.map((comment) => {
          if (updatedComment.parentId === comment.id) {
            return {
              ...comment,
              replies: comment.replies?.map?.((reply) =>
                reply.id === updatedComment.id ? updatedComment : reply,
              ),
            };
          }
          if (updatedComment.id === comment.id) {
            return updatedComment;
          }
          return comment;
        });
        if (payload?.referencedObjects?.user) {
          this.users = {
            ...this.users,
            ...payload.referencedObjects.user,
          };
        }
      } catch (error) {
        this.comments = this.comments.map((comment) => ({
          ...comment,
          replies: comment.replies?.map?.((reply) => ({
            ...reply,
            _saving: reply.id !== id && reply._saving,
          })),
          _saving: comment.id !== id && comment._saving,
        }));
        throw error;
      }
    },
    async deleteComment({ id }) {
      await CollaborationAPI.deleteComment({ id });
      this.comments = this.comments
        .map((comment) => ({
          ...comment,
          replies: comment.replies && comment.replies.filter((reply) => reply.id !== id),
          deletedAt: comment.id === id ? dayjs().toISOString() : comment.deletedAt,
        }))
        .filter((comment) => comment.replies?.length || !comment.deletedAt);
    },
    clearComments() {
      this.comments = [];
      this.newComment = null;
      this.newCommentText = '';
    },
    clearUnsavedComments() {
      this.newComment = null;
      this.newCommentText = '';
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _composing_reply: undefined,
        _composing_edit: undefined,
        _reply_text: undefined,
        _edit_text: undefined,
        replies: comment.replies?.map?.((reply) => ({
          ...reply,
          _composing_reply: undefined,
          _composing_edit: undefined,
          _reply_text: undefined,
          _edit_text: undefined,
        })),
      }));
    },
    createCommentNotificationSocket({ notification }) {
      this.notifications = [humps.camelizeKeys(notification.data), ...this.notifications];
      if (notification?.referenced_objects?.user) {
        this.users = {
          ...notification.referenced_objects.user,
          ...this.users,
        };
      }
    },
    startReply({ id }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _reply_text: comment.id === id ? '' : comment._reply_text,
        _composing_reply: comment.id === id || comment._composing_reply,
      }));
    },
    setReplyText({ id, value }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _reply_text: comment.id === id ? value : comment._reply_text,
      }));
    },
    cancelReply({ id }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _reply_text: comment.id === id ? '' : comment._reply_text,
        _composing_reply: comment.id !== id && comment._composing_reply,
      }));
    },
    setCommentText({ value }) {
      this.newCommentText = value;
    },
    cancelComment() {
      this.newCommentText = '';
    },
    startEdit({ id }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _edit_text: comment.id === id ? comment.text : comment._edit_text,
        _composing_edit: comment.id === id || comment._composing_edit,
        replies: comment.replies?.map?.((reply) => ({
          ...reply,
          _edit_text: reply.id === id ? reply.text : reply._edit_text,
          _composing_edit: reply.id === id || reply._composing_edit,
        })),
      }));
    },
    setEditText({ id, value }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _edit_text: comment.id === id ? value : comment._edit_text,
        replies: comment.replies?.map?.((reply) => ({
          ...reply,
          _edit_text: reply.id === id ? value : reply._edit_text,
        })),
      }));
    },
    cancelEdit({ id }) {
      this.comments = this.comments.map((comment) => ({
        ...comment,
        _edit_text: comment.id === id ? '' : comment._edit_text,
        _composing_edit: comment.id !== id && comment._composing_edit,
        replies: comment.replies?.map?.((reply) => ({
          ...reply,
          _edit_text: reply.id === id ? '' : reply._edit_text,
          _composing_edit: reply.id !== id && reply._composing_edit,
        })),
      }));
    },
  },
});
