import { constants } from '@/config';

const App = () => import('@/app/youtube/App.vue');
const BoardsWrapper = () => import('@/app/boards/pages/BoardsWrapper.vue');
const Gallery = () => import('@/components/Galleries/Gallery.vue');
const YoutubeGalleryEdit = () => import('@/app/youtube/pages/YouTubeGalleryEdit.vue');
const Overview = () => import('@/app/youtube/pages/Overview.vue');
const YourVideos = () => import('@/app/youtube/pages/YourVideos.vue');

const PAGE_LEVEL_TOP = 'YouTube';

export default {
  path: 'youtube',
  component: App,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      name: 'youtube',
      path: '',
      redirect: { name: 'youtube.overview' },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'youtube.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      path: 'boards',
      children: [
        {
          name: 'youtube.galleries',
          path: '',
          component: BoardsWrapper,
          props: {
            galleryType: constants.YOUTUBE,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Boards`,
          },
          children: [
            {
              name: 'youtube.galleries.new',
              path: 'new',
              component: YoutubeGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - New`,
              },
            },
          ],
        },
        {
          name: 'youtube.gallery.detail',
          path: ':id',
          component: Gallery,
          props: {
            galleryType: constants.YOUTUBE,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Board Detail`,
          },
          children: [
            {
              name: 'youtube.galleries.edit',
              path: 'edit',
              component: YoutubeGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - Edit`,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'youtube.yourVideos',
      path: 'your-videos',
      component: YourVideos,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Videos`,
      },
    },
  ],
};
