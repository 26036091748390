<template>
  <div :style="divStyles">
    <img :src="svgComponent" :style="primaryStyles" />
    <img
      v-if="competitive"
      class="secondary-icon"
      :src="secondaryComponent"
      :style="secondaryStyles"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BrandhealthSvg from '@/assets/img/channels/brandhealth.svg';
import CompetitiveSvg from '@/assets/img/channels/competitive.svg';
import InstagramSvg from '@/assets/img/channels/instagram.svg';
import InstagramStoriesSvg from '@/assets/img/channels/instagram-stories.svg';
import FacebookSvg from '@/assets/img/channels/facebook-light.svg';
import PinterestSvg from '@/assets/img/channels/pinterest.svg';
import TikTokSvg from '@/assets/img/channels/tiktok.svg';
import TwitterSvg from '@/assets/img/channels/twitter.svg';
import YoutubeSvg from '@/assets/img/channels/youtube.svg';
import GoogleAnalyticsSvg from '@/assets/img/channels/ga-with-border.svg';
import RedditSvg from '@/assets/img/channels/reddit.svg';
import QuoraSvg from '@/assets/img/channels/quora.svg';
import WebSvg from '@/assets/img/channels/web.svg';
import FacebookAdsSvg from '@/assets/img/channels/facebook-ads.svg';
import LinkedInSvg from '@/assets/img/channels/linkedin.svg';
import MetaSvg from '@/assets/img/channels/meta.svg';
import MessengerSvg from '@/assets/img/channels/messenger.svg';
import AudienceNetworkSvg from '@/assets/img/channels/audience-network.svg';
import ThreadsSvg from '@/assets/img/channels/threads.svg';
import BlueskySvg from '@/assets/img/channels/bluesky.svg';

const SIZE_MAP = {
  tiny: '0.5',
  xxxsmall: '0.625',
  xxsmall: '0.75',
  xsmall: '1',
  small: '1.25',
  medium: '1.5',
  large: '1.75',
  xlarge: '2',
  xxlarge: '3',
  xxxlarge: '4',
  xxxxlarge: '8',
};

const ICONS = {
  brandhealth: BrandhealthSvg,
  competitive: CompetitiveSvg,
  instagram: InstagramSvg,
  instagramStories: InstagramStoriesSvg,
  instagram_stories: InstagramStoriesSvg,
  facebook: FacebookSvg,
  facebook_ads: FacebookAdsSvg,
  pinterest: PinterestSvg,
  pinterest_v5: PinterestSvg,
  tiktok: TikTokSvg,
  tiktok_ads: TikTokSvg,
  tiktok_creator_marketplace: TikTokSvg,
  twitter: TwitterSvg,
  youtube: YoutubeSvg,
  ga: GoogleAnalyticsSvg,
  reddit: RedditSvg,
  quora: QuoraSvg,
  web: WebSvg,
  linkedin: LinkedInSvg,
  meta: MetaSvg,
  messenger: MessengerSvg,
  audience_network: AudienceNetworkSvg,
  x: TwitterSvg,
  threads: ThreadsSvg,
  bluesky: BlueskySvg,
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  props: {
    value: {
      type: String,
      default: null,
      validator(value) {
        if (value === null) {
          return true;
        }
        return Object.keys(ICONS).indexOf(value) !== -1;
      },
    },
    /**
     * Tiny size.
     */
    tiny: { type: Boolean, default: false },
    /**
     * xxxsmall size.
     */
    xxxsmall: { type: Boolean, default: false },
    /**
     * xxsmall size.
     */
    xxsmall: { type: Boolean, default: false },
    /**
     * xmsmall size.
     */
    xsmall: { type: Boolean, default: false },
    /**
     * small size.
     */
    small: { type: Boolean, default: false },
    /**
     * medium size.
     */
    medium: { type: Boolean, default: false },
    /**
     * large size.
     */
    large: { type: Boolean, default: false },
    /**
     * xlarge size.
     */
    xlarge: { type: Boolean, default: false },
    /**
     * xxlarge size.
     */
    xxlarge: { type: Boolean, default: false },
    /**
     * xxxlarge size.
     */
    xxxlarge: { type: Boolean, default: false },
    /**
     * xxxxlarge size.
     */
    xxxxlarge: { type: Boolean, default: false },
    competitive: { type: Boolean, default: false },
  },
  computed: {
    svgComponent() {
      if (this.competitive) {
        return ICONS.competitive;
      }
      return ICONS[this.value];
    },
    secondaryComponent() {
      return ICONS[this.value];
    },
    size() {
      const {
        tiny,
        xxxsmall,
        xxsmall,
        xsmall,
        small,
        medium,
        large,
        xlarge,
        xxlarge,
        xxxlarge,
        xxxxlarge,
      } = this.$props;
      const sizes = {
        tiny,
        xxxsmall,
        xxsmall,
        xsmall,
        small,
        medium,
        large,
        xlarge,
        xxlarge,
        xxxlarge,
        xxxxlarge,
      };
      const size = Object.keys(sizes).find((key) => sizes[key]) || 'medium';
      return SIZE_MAP[size];
    },
    divStyles() {
      const fullSize = Number(this.size);
      return {
        height: `${fullSize}rem`,
        position: 'relative',
      };
    },
    primaryStyles() {
      return {
        height: `${this.size}rem`,
        width: `${this.size}rem`,
      };
    },
    secondaryStyles() {
      const fullSize = Number(this.size);
      const secondarySize = Math.round(fullSize * 0.6);
      const leftOffset = Math.round(fullSize * 0.8);
      return {
        height: `${secondarySize}rem`,
        width: `${secondarySize}rem`,
        position: 'absolute',
        bottom: 0,
        left: `${leftOffset}rem`,
      };
    },
  },
});
export default comp;
</script>
