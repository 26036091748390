<template>
  <main class="insights" data-cy="data-ig-insights">
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon name="instagram" />
        <span class="insights-title">{{ insightsTitle }}</span>
        <Icon
          v-if="isBoosted"
          v-tooltip="tooltips.boosted"
          :color="colours.BRAND.BRAND_ACCENT"
          name="boosted"
        />
        <InfoTooltip
          v-else-if="mediaItem.sourceType === 'INSTAGRAM_OTHER' && isHashtagMonitor"
          :tooltip="tooltips.igOther"
          xsmall
        />
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <div v-if="!isEmpty(callToActionBanner)" class="pt-4">
      <Banner v-bind="callToActionBanner" small>
        {{ callToActionBanner.message }}
      </Banner>
    </div>
    <div :class="['insights-list', { 'visible h-auto opacity-100': dropdownOpen }]">
      <div v-if="isStalePost" class="outdated-post">
        This post is more than 1 year old. Up-to-date metrics are no longer available.
      </div>
      <div v-if="disabledLikeCount('likes')" class="hidden-likes">
        Like count is hidden on this post.
        <br />
        Impacted metrics will not be shown.
      </div>
      <CircularLoader v-if="!mediaItem.insights" />
      <ul v-else :class="{ 'outdated-metrics': isStalePost }">
        <template v-for="(value, key) in topLevelInsights">
          <li v-if="value != null && showInsightKey(key)" :key="key" data-cy="data-ig-stat">
            <span class="pair-row" :class="getStatClass(key)">
              <span class="stat-name" data-cy="data-stat-name">
                {{ formatKey(key) }}
                <InfoTooltip
                  v-if="showTooltipIcon(key)"
                  :tooltip="{ html: true, content: tooltips[key] }"
                  xsmall
                />
              </span>

              <span v-tooltip="formatTooltip(key)" class="stat">
                {{ formatValue(key, value) }}
              </span>
            </span>

            <!-- sub level -->
            <template v-for="(subValue, subKey) in subLevels(key)">
              <span
                v-if="subValue != null && showInsightKey(subKey)"
                :key="subKey"
                class="pair-row"
              >
                <span v-if="subKey === 'comments'" class="stat">
                  <a
                    v-if="isMentionedUgc"
                    :source-id="mediaItem.postSourceId"
                    :class="classValue"
                    class="sub-level stat-name comment-link"
                    @click="checkComment"
                  >
                    {{ formatKey(subKey, key) }}
                    <Icon
                      v-if="!mediaItem.insights.is_commented"
                      :source-id="mediaItem.postSourceId"
                      :custom-class="classValue"
                      name="comment"
                      xsmall
                    />
                    <Icon
                      v-else
                      v-tooltip="'You have commented on this post.'"
                      :source-id="mediaItem.postSourceId"
                      :color="greenBubble"
                      :custom-class="classValue"
                      name="chatCheck"
                      xsmall
                    />
                  </a>
                  <span v-else class="sub-level stat-name" data-cy="data-stat-name">
                    {{ formatKey(subKey, key) }}
                  </span>
                </span>
                <span
                  v-else-if="subKey.startsWith('promoted_scoped')"
                  v-tooltip="formatKey(subKey, key)"
                  class="sub-level stat-name inline-block max-w-[63%] truncate"
                >
                  {{ formatKey(subKey, key) }}
                </span>
                <span v-else class="sub-level stat-name" data-cy="sub-stat-name">
                  {{ formatKey(subKey, key) }}
                  <InfoTooltip
                    v-if="
                      ['paidImpressions', 'paidVideoViews'].includes(subKey) &&
                      reachAdsInsightsDatePresetMax
                    "
                    :tooltip="tooltips.unavailablePaidMetrics"
                    xsmall
                  />
                  <InfoTooltip
                    v-else-if="
                      [
                        'paidImpressions',
                        'paidTotalEngagements',
                        'organicTotalEngagements',
                      ].includes(subKey)
                    "
                    :tooltip="tooltips[subKey]"
                    xsmall
                  />
                </span>
                <span class="sub-level stat">{{ formatValue(subKey, subValue) }}</span>
              </span>
            </template>
          </li>
        </template>
      </ul>
      <CustomMetricsInsights v-if="showCustomMetricsInsights" :media-item="mediaItem" />
    </div>
  </main>
</template>

<script>
import differenceInHours from 'date-fns/differenceInHours';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { defineComponent } from 'vue';

import instagramConstants, {
  discontinuedFields,
  metaPromotedFieldsToVerbose,
  metaPromotedFieldsToVerboseDeprecated,
  metaPromotedLevelFields,
  metaPromotedLevelFieldsDeprecated,
  metaPromotedTopLevelFields,
  metaPromotedTopLevelFieldsDeprecated,
  metricsDiscontinuationDate,
  newMetricFields,
  newMetricsStartDate,
  connectedUgcInsightsLabelsVerbose,
} from '@/app/instagram/constants';
import {
  formatMetaPromoted,
  getAdAccountScopedNestings,
  getFormattedNestings,
  sortMetaPromotedFields,
} from '@/app/instagram/utils';
import CustomMetricsInsights from '@/app/library/components/MediaPopup/CustomMetricsInsights.vue';
import enumTypes from '@/app/library/constants';
import constants from '@/app/relationships/constants';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import CircularLoader from '@/components/CircularLoader.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import Icon from '@/components/foundation/Icon.vue';
import { toolTips } from '@/config';
import { METRIC_FORMATS } from '@/models/dashboards/metrics.constants';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useAuthStore } from '@/stores/auth';
import { useFlagStore } from '@/stores/flag';
import { useInstagramPostsStore } from '@/stores/instagram-posts';
import { useInstagramRelationshipsStore } from '@/stores/instagram-relationships';
import { formatInstagramData, useMediaDetailStore } from '@/stores/media-detail';
import { usePlatformStore } from '@/stores/platform';
import { formatValueByFormatType, localize } from '@/utils/formatters';
import { validateRealUser } from '@/utils/user';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'IGInsights',
  components: {
    Icon,
    InfoTooltip,
    CustomMetricsInsights,
    CircularLoader,
    Banner,
  },
  props: {
    mediaItem: { type: Object, default: null },
    isHashtagMonitor: { type: Boolean, default: false },
  },
  emits: ['updatePopupStatus'],
  setup() {
    const { canAccessCustomMetrics } = useCustomMetrics();
    return {
      canAccessCustomMetrics,
    };
  },
  data() {
    const profileClickInsights = [
      'bioLinkClicks',
      'calls',
      'directions',
      'emails',
      'other',
      'text',
    ];
    return {
      boostedInsightsKeys: ['comments', 'likes', 'impressions', 'videoViews', 'totalEngagements'],
      scaledPercentageKeys: [
        'effectiveness',
        'engagementRate',
        'engagementRatePaid',
        'engagementRateImpressions',
        'effectivenessEngagements',
        'engagementRateViews',
      ],
      directPercentageKeys: ['ctrPaid'],
      profileClickInsights,
      nonInsightKeys: ['is_commented', 'showBusinessInsights'],
      privateOnlyInsights: [
        'impressions',
        'saved',
        'shares',
        'follows',
        'profileVisits',
        'profileClicks',
        ...profileClickInsights,
      ],
      OwnedOnlyInsights: ['engagementRateImpressions'],
      insightsNotApplicableForCarousels: [
        'follows',
        'profileVisits',
        'profileClicks',
        ...profileClickInsights,
      ],
      insightsNotApplicableForHashtagMedia: ['comments', 'likes', 'totalEngagements'],

      dropdownOpen: true,
      greenBubble: colours.SUCCESS.SUCCESS_500,
      classValue: 'js-comment-popup',
      colours,
    };
  },
  computed: {
    ...mapStores(
      useMediaDetailStore,
      useInstagramPostsStore,
      useInstagramRelationshipsStore,
      useFlagStore,
      usePlatformStore,
    ),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'identity', 'user_can', 'brand_can']),
    hasInstagramMetricUpdates() {
      return this.flagStore.ready && this.flagStore.flags.instagramMetricUpdates;
    },
    hasBoostedPostUpdatesEnabled() {
      return this.flagStore.ready && this.flagStore.flags.boostedPostUpdates;
    },
    isMetaAdsConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    hasConnectedMetaAdsPreviously() {
      return !this.platformStore.isPlatformNeverConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    isMetaAdsNeverConnected() {
      return !this.isMetaAdsConnected && !this.hasConnectedMetaAdsPreviously;
    },
    callToActionBanner() {
      if (!this.hasConnectedMetaAdsPreviously) {
        return {
          customIcon: 'boosted',
          message: 'Inform your social strategy with promoted post insights!',
          actionText: 'Connect Meta Ads Account',
          alertType: 'white',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      if (!this.isMetaAdsConnected) {
        return {
          customIcon: 'alertCircle',
          message: 'Meta Ads not connected, data may not be current',
          actionText: 'Reconnect in Settings',
          alertType: 'warning',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      return {};
    },
    metaPromotedLevelFields() {
      return this.hideDiscontinuedMetrics
        ? metaPromotedLevelFields
        : metaPromotedLevelFieldsDeprecated;
    },
    metaPromotedTopLevelFields() {
      const topLevelFields = (
        this.hideDiscontinuedMetrics
          ? metaPromotedTopLevelFields
          : metaPromotedTopLevelFieldsDeprecated
      ).toSorted(sortMetaPromotedFields);
      return this.isMetaAdsNeverConnected
        ? topLevelFields.filter((key) => !key.endsWith('Paid'))
        : topLevelFields;
    },
    engagementInsights() {
      if (!this.showBusinessInsights) {
        return ['likes', 'comments'];
      }
      return ['likes', 'comments', 'saved', 'shares'];
    },
    isMentionedUgc() {
      return this.isUGC && this.instagramPostsStore.igPost?.is_mentioned;
    },
    isCarousel() {
      return this.mediaItem.type === instagramConstants.instagramPostTypes.CAROUSEL.toLowerCase();
    },
    isVideoOrReel() {
      return (
        this.mediaItem.type === instagramConstants.instagramPostTypes.VIDEO.toLowerCase() ||
        this.mediaItem.type === instagramConstants.instagramPostTypes.REEL.toLowerCase()
      );
    },
    showCustomMetricsInsights() {
      return this.canAccessCustomMetrics && this.isOwned && this.mediaItem.insights;
    },
    isUGC() {
      return [
        enumTypes.INSTAGRAM_UGC,
        enumTypes.INSTAGRAM_UGC_IGTV,
        enumTypes.INSTAGRAM_STORY_UGC,
      ].includes(this.mediaItem.sourceType);
    },
    isOwned() {
      return this.mediaItem?.sourceType?.startsWith(enumTypes.INSTAGRAM_OWNED);
    },
    isBoosted() {
      return !isEmpty(this.mediaItem.boostedData);
    },
    tooltips() {
      const tooltips = cloneDeep(
        this.hasInstagramMetricUpdates
          ? toolTips.instagramInsights
          : toolTips.instagramInsightsDeprecated,
      );
      if (this.hasInstagramMetricUpdates && this.isVideoOrReel) {
        tooltips.views = tooltips.viewsVideo;
      }
      if (!this.isOwned) {
        tooltips.engagementRate = tooltips.engagementRateUgc;
      }
      if (this.isBoosted) {
        const tooltipsPromotedView = this.hasInstagramMetricUpdates
          ? toolTips.metaInstagramMediaPopup
          : toolTips.metaInstagramMediaPopupDeprecated;

        const mergedTooltips = { ...tooltips, ...tooltipsPromotedView };
        if (this.hasBoostedPostUpdatesEnabled) {
          Object.entries(toolTips.instagramAdAccountScopedInsightsSuffixes).forEach(
            ([key, suffix]) => {
              mergedTooltips[key] = [mergedTooltips[key], suffix].join('.\n\n');
            },
          );
        }

        return mergedTooltips;
      }
      if (!this.isVideoOrReel) {
        tooltips.effectiveness = tooltips.effectivenessNonVideo;
      }
      if (!this.showBusinessInsights) {
        tooltips.reach = tooltips.estimatedReach;
        tooltips.effectiveness = tooltips.effectivenessUgc;
        tooltips.totalEngagements = tooltips.totalEngagementsUGC;
      }
      return tooltips;
    },
    insightsTitle() {
      return !this.isOwned ? 'Organic Instagram Insights' : 'Instagram Insights';
    },
    topLevelInsights() {
      if (this.isBoosted) {
        return pick(this.mediaItem.insights, this.metaPromotedTopLevelFields);
      }
      return omit(this.mediaItem.insights, [
        // call, direction, email, other, text are subLevel of profileClicks
        ...this.profileClickInsights,
        // likes, comments are always subLevel of totalEngagements
        // saves, shares are shown and also subLevel of totalEngagements only if showBusinessInsights
        ...this.engagementInsights,
        ...(this.hideDiscontinuedMetrics ? discontinuedFields : []),
      ]);
    },
    showBusinessInsights() {
      return this.mediaItem?.insights?.showBusinessInsights;
    },
    // According to Facebook API, from v10.0+, Ads Insights API will return an error when requests
    // contain date ranges beyond the 37-month window.
    // https://developers.facebook.com/docs/graph-api/changelog/version10.0#ads-insights-api
    reachAdsInsightsDatePresetMax() {
      const datePresetMaximum = dayjs().subtract(37, 'months');
      return !dayjs(this.mediaItem.datePosted).isAfter(datePresetMaximum);
    },
    hideDiscontinuedMetrics() {
      return dayjs(this.mediaItem.datePosted).isAfter(dayjs(metricsDiscontinuationDate), 'day');
    },
    showNewMetrics() {
      return (
        this.showBusinessInsights &&
        this.hasInstagramMetricUpdates &&
        dayjs(this.mediaItem.datePosted).isAfter(dayjs(newMetricsStartDate), 'day')
      );
    },
    isFacebookConnected() {
      return (
        !!this.instagramRelationshipsStore.mediaRelationship &&
        this.instagramRelationshipsStore.mediaRelationship.acceptanceStatus ===
          constants.connectionStatus.ACCEPTED &&
        this.instagramRelationshipsStore.mediaRelationship.isFbConnected
      );
    },
    isStalePost() {
      if (!this.mediaItem.datePosted || this.mediaItem.sourceType !== 'INSTAGRAM_OTHER') {
        return false;
      }
      const datePosted = new Date(this.mediaItem.datePosted);
      const yearOld = new Date();
      yearOld.setFullYear(yearOld.getFullYear() - 1);
      return datePosted < yearOld;
    },
    userNameExists() {
      return this.mediaItem.userName && validateRealUser(this.mediaItem.userName);
    },
    metaAdsAccounts() {
      if (!this.hasConnectedMetaAdsPreviously) return undefined;
      return keyBy(this.platformStore.getAccount(PLATFORM_CONNECTION.FACEBOOK_ADS), 'account_id');
    },
    metaAdsAccountScopedNestingsByMetric() {
      const adAccountScopedInsights = this.mediaItem?.boostedData?.adAccountScopedInsights ?? [];
      return {
        reachTotal: getAdAccountScopedNestings(
          adAccountScopedInsights,
          this.metaAdsAccounts,
          'reach',
          {
            organic: this.mediaItem.insights?.reach,
          },
        ),
        frequencyPaid: getAdAccountScopedNestings(
          adAccountScopedInsights,
          this.metaAdsAccounts,
          'frequency',
        ),
      };
    },
    currency() {
      return Object.values(this.metaAdsAccounts ?? {})?.[0]?.currency;
    },
    keysWithToolTipsOrganic() {
      const keysWithTooltips = [
        'effectiveness',
        'engagementRate',
        'engagementRateImpressions',
        'totalEngagements',
        'reach',
        'follows',
        'profileVisits',
        'profileClicks',
        'followersGained',
        'emv',
      ];
      if (this.hasInstagramMetricUpdates) {
        keysWithTooltips.push('impressions', 'videoViews', ...newMetricFields);
      }
      return keysWithTooltips;
    },
  },
  watch: {
    'instagramPostsStore.igPost': {
      handler(to) {
        if (to) {
          this.mediaDetailStore.setMediaDetailInsights({
            insights: {
              ...formatInstagramData(to),
              likeshopClicks: this.mediaItem.likeshopClicks,
            },
          });
        }
      },
    },
  },
  mounted() {
    this.instagramPostsStore.updateIGPost({
      postSourceId: this.mediaItem.postSourceId,
      postBrandId: this.mediaItem.brandId,
    });
    if (this.userNameExists) {
      this.instagramRelationshipsStore.findMediaRelationshipByHandle({
        brandId: this.currentBrand.id,
        handle: this.mediaItem.userName,
      });
    }
  },
  methods: {
    isEmpty,
    async showMetaAdsConnectionPopup() {
      await this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW, {
        showWhenDrawerOpen: true,
      });
    },
    formatKey(key, parentKey) {
      if (this.isBoosted) {
        return formatMetaPromoted(key, {
          parentField: parentKey,
          verboseFields: this.hasInstagramMetricUpdates
            ? metaPromotedFieldsToVerbose
            : metaPromotedFieldsToVerboseDeprecated,
        });
      }
      if (key === 'likeshopClicks') {
        // we want LikeShop instead of Likeshop, have to change it manually.
        return 'LikeShop Clicks';
      }
      if (key === 'reach' && !this.showBusinessInsights) {
        return 'Estimated Reach';
      }
      // Text formatting for UGC, OTHER
      if (!this.isOwned) {
        if (key === 'totalEngagements') {
          return 'Engagements';
        }
        if (key === 'saved') {
          return 'Saves';
        }
        if (key === 'emv') {
          return 'EMV';
        }
        if (this.hasInstagramMetricUpdates && this.showBusinessInsights) {
          return connectedUgcInsightsLabelsVerbose[key] || startCase(key);
        }
        return startCase(key);
      }
      // Text formatting for organic insights
      return this.formatOrganicKeys(key);
    },
    formatOrganicKeys(key) {
      const keyLabels = this.hasInstagramMetricUpdates
        ? instagramConstants.organicInsightsKeysLabels
        : instagramConstants.organicInsightsKeysLabelsDeprecated;
      return keyLabels[key] || `${startCase(key)} - Organic`;
    },
    formatValue(key, value) {
      if (
        value == null ||
        this.disabledLikeCount(key) ||
        this.disabledVideoViewsCount(key) ||
        this.userDataUnavailable(key)
      ) {
        return '-';
      }
      if (this.scaledPercentageKeys.includes(key)) {
        return `${(value * 100).toFixed(2)}%`;
      }
      if (this.directPercentageKeys.includes(key)) {
        return `${value.toFixed(2)}%`;
      }
      if (
        this.hasBoostedPostUpdatesEnabled &&
        this.metaAdsAccountScopedNestingsByMetric[key] &&
        key.toLowerCase().endsWith('paid')
      ) {
        return '-';
      }
      if (['amountSpentPaid', 'cpcPaid', 'cpmPaid'].includes(key)) {
        const formattedValue = formatValueByFormatType(value, METRIC_FORMATS.MONETARY_LONG, '-');
        return this.currency ? `${formattedValue} ${this.currency}` : formattedValue;
      }
      if (key === 'emv') {
        return `$${localize(value)}`;
      }
      return value.toLocaleString();
    },
    formatTooltip(key) {
      if (this.disabledLikeCount(key)) {
        return instagramConstants.disabledLikeCountTooltip;
      }
      if (this.disabledVideoViewsCount(key)) {
        return instagramConstants.disabledVideoViewsCountTooltip;
      }
      if (this.userDataUnavailable(key)) {
        return instagramConstants.userDataUnavailable;
      }
      return '';
    },
    disabledLikeCount(key) {
      const likes = this.mediaItem.insights?.likes;
      const moreThanOneHourAgo =
        differenceInHours(new Date(), new Date(this.mediaItem.datePosted)) > 1;
      return (
        !this.showBusinessInsights &&
        ['totalEngagements', 'effectiveness', 'engagementRate', 'likes', 'emv'].includes(key) &&
        likes === 0 &&
        moreThanOneHourAgo
      );
    },
    disabledVideoViewsCount(key) {
      return !this.isOwned && key === 'videoViews' && !this.isFacebookConnected;
    },
    userDataUnavailable(key) {
      const isRealUser = validateRealUser(this.mediaItem.userName);
      return !isRealUser && ['effectiveness', 'engagementRate', 'reach'].includes(key);
    },
    getStatClass(key) {
      return this.disabledLikeCount(key) ? ['grey-text-light'] : [];
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    showInsightKey(key) {
      if (this.nonInsightKeys.includes(key)) {
        return false;
      }
      if (this.isHashtagMonitor && this.mediaItem.sourceType === enumTypes.INSTAGRAM_OTHER) {
        return this.insightsNotApplicableForHashtagMedia.includes(key);
      }
      if (!this.showBusinessInsights && this.privateOnlyInsights.includes(key)) {
        return false;
      }
      if (!this.isOwned && this.OwnedOnlyInsights.includes(key)) {
        return false;
      }
      if (this.isCarousel && this.insightsNotApplicableForCarousels.includes(key)) {
        return false;
      }
      if (this.isUGC && key === 'impressions' && !this.mediaItem.insights.impressions) {
        return false;
      }
      if (this.disabledVideoViewsCount(key)) {
        return false;
      }
      if ((key.startsWith('videoPlays') || key === 'thruplaysPaid') && !this.isVideoOrReel) {
        return false;
      }
      if (newMetricFields.includes(key) && !this.showNewMetrics) {
        return false;
      }
      return true;
    },
    showTooltipIcon(key) {
      if (this.isBoosted) return key in this.tooltips;
      return (
        this.keysWithToolTipsOrganic.includes(key) ||
        (!this.isOwned && ['likes', 'comments'].includes(key))
      );
    },
    checkComment() {
      this.$router.push({
        name: 'instagram.ugc.comments',
        params: {
          brandLabel: this.currentBrand.label,
          postId: `${this.mediaItem.postSourceId}`,
        },
      });
    },
    hasSubLevel(key) {
      if (this.isBoosted) {
        return this.metaPromotedLevelFields[key] !== null;
      }
      return key === 'totalEngagements' || (key === 'profileClicks' && this.showBusinessInsights);
    },
    subLevels(key) {
      if (!this.hasSubLevel(key)) {
        return [];
      }
      if (this.isBoosted) {
        if (this.isMetaAdsNeverConnected) {
          return {};
        }
        if (this.hasBoostedPostUpdatesEnabled && this.metaAdsAccountScopedNestingsByMetric[key]) {
          return this.metaAdsAccountScopedNestingsByMetric[key];
        }
        return getFormattedNestings(this.metaPromotedLevelFields[key], this.mediaItem.insights);
      }
      if (key === 'totalEngagements') {
        return pick(this.mediaItem.insights, this.engagementInsights);
      }
      if (key === 'profileClicks') {
        return pick(this.mediaItem.insights, this.profileClickInsights);
      }
      return [];
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    .insights-title {
      margin-right: var(--space-12);
    }

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-12);
        cursor: help;
      }
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .comment-link {
    font-weight: inherit;
    display: flex;
    align-items: center;
    font-size: var(--x14);
    color: var(--text-primary);
    background: var(--background-300);
    text-decoration: underline var(--border);
    text-underline-offset: 8px;
    text-decoration-style: dashed;

    &:hover:not([disabled]) {
      color: var(--action-500);
      text-decoration-color: var(--action-500);
    }
  }

  a {
    position: relative;

    &[disabled=''] {
      cursor: default;
    }
  }

  .at-mentions {
    display: flex;
    flex-wrap: wrap;
    margin: var(--space-16) 0 0 var(--space-32);
    padding-left: var(--space-4);

    span {
      display: flex;
      align-items: center;
      font-weight: var(--font-medium);
      color: var(--text-primary);
      font-size: var(--x12);
      line-height: 1.6rem;
      text-align: center;
      border: 1px solid var(--border);
      border-radius: var(--button-border-radius);
      padding: 0 var(--space-12);
      margin: 0 var(--space-4) var(--space-8) 0;
      height: 1.8rem;

      .svg-icon {
        margin-right: var(--space-4);
        color: var(--text-primary);
      }
    }
  }

  .insights-list {
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.3s;

    .hidden-likes {
      margin: var(--space-16) var(--space-32) 0 var(--space-32);
      background-color: var(--background-400);
      padding: var(--space-16) var(--space-16) var(--space-16) var(--space-16);
      text-align: left;
    }

    ul {
      margin: var(--space-16) 0 0 var(--space-32);
      padding-left: var(--space-4);

      li {
        span {
          display: flex;
          align-items: center;
          color: var(--text-primary);
          white-space: nowrap;

          svg {
            margin-left: var(--space-8);
          }
        }

        .pair-row {
          margin: var(--space-8) 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          .stat-name {
            padding-right: var(--space-8);
          }

          .stat {
            color: var(--action-500);
          }

          & > span + span {
            float: right;
            padding-left: var(--space-8);
            background: var(--background-300);
          }

          & > span:first-child {
            position: absolute;
            background: var(--background-300);
          }
        }

        .grey-text-light {
          .stat-name {
            color: var(--text-secondary);
          }

          .stat {
            color: var(--text-secondary);
            cursor: help;
          }
        }

        .pair-row::before {
          float: left;
          width: 0;
          white-space: nowrap;
          content: '--------------------------------------------------------------------------------';
          color: var(--border);
        }

        .sub-level {
          font-size: var(--x12);
          padding-left: var(--space-24);
          color: var(--text-secondary);
        }
      }
    }
  }

  .post-caption {
    margin: var(--space-8) 0 0 var(--space-32);
    padding-left: var(--space-4);
    text-align: left;
    color: var(--text-primary);
    overflow-wrap: break-word;
    word-wrap: break-word;
    user-select: text;
  }

  .outdated-post {
    background: var(--background-400);
    margin: var(--space-22);
    margin-left: var(--space-32);
    padding: var(--space-22);
    text-align: left;
  }

  .outdated-metrics {
    opacity: 0.5;
  }
}
</style>
