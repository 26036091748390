<script setup>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { ref, computed, onBeforeMount } from 'vue';
import { LibraryAPI } from '@/apis';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import List from '@/components/foundation/List.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import Popup from '@/components/Popup.vue';
import { discardConfirmMessage } from '@/config';
import {
  utmEditPresetValueInfo,
  utmEditPresetValueError,
  utmOptions,
} from '@/app/settings/components/Utm/const';
import {
  trackUtmManagementPresetList,
  trackUtmManagementPresetValueUpdated,
} from '@/app/settings/mixpanel';
import { logger } from '@/utils/logger';

const authStore = useAuthStore();
const notificationStore = useNotificationStore();

const props = defineProps({
  channel: { type: String, required: true },
  trackingKey: { type: String, required: true },
  presetValues: { type: Array, default: () => [] },
  showWhenDrawerOpen: { type: Boolean, default: false },
});

const emit = defineEmits(['close']);

const currentValues = ref([]);
const formValues = ref([{ value: '' }]);
const isSaving = ref(false);

const formHasChanges = computed(() => {
  return !isEqual(currentValues.value, formValues.value);
});

const formHasEmptyField = computed(() => {
  return formValues.value.some(({ value }) => value === '');
});

const formIsEmpty = computed(() => {
  return formValues.value.length === 1 && formHasEmptyField.value;
});

const formValueIsDuplicate = computed(() => {
  const seenValues = new Set();
  return formValues.value.map(({ value }) => {
    const isDuplicate = seenValues.has(value);
    seenValues.add(value);
    return isDuplicate;
  });
});

const disableSaveButton = computed(() => {
  // button disabled if:
  return (
    !formHasChanges.value || // there are no changes
    formValueIsDuplicate.value.some((value) => value === true) || // there are duplicate values in form
    (formHasEmptyField.value && // there is an empty field...
      (!formIsEmpty.value || currentValues.value.length === 0)) // ...and it's not alone
  );
});

const closeConfirmation = computed(() => {
  return formHasChanges.value ? discardConfirmMessage : null;
});

const channelLabel = computed(() => {
  return utmOptions.find((option) => option.value === props.channel).text;
});

function addFormField() {
  formValues.value.push({ value: '' });
  trackUtmManagementPresetValueUpdated({
    channel: channelLabel.value,
    trackingKey: props.trackingKey,
    action: 'add',
  });
}

function deleteFormField(index) {
  if (formValues.value.length > 1) {
    formValues.value.splice(index, 1);
  } else {
    formValues.value.splice(index, 1, { value: '' });
  }
  trackUtmManagementPresetValueUpdated({
    channel: channelLabel.value,
    trackingKey: props.trackingKey,
    action: 'delete',
  });
}

async function onSave() {
  isSaving.value = true;

  const brandId = authStore.currentBrand.id;
  const currentValuesCopy = cloneDeep(currentValues.value.filter((currItem) => currItem.value));
  const formValuesCopy = cloneDeep(formValues.value.filter((formItem) => formItem.value));
  const promises = [];

  const formValueSet = new Set(formValues.value.map(({ value }) => value));
  const formValueIdSet = new Set(formValues.value.map(({ id }) => id).filter((id) => id != null));

  currentValuesCopy.forEach((presetValue) => {
    // Delete preset value if missing from form values (if missing id, check value)
    const { id, value } = presetValue;
    if (!formValueIdSet.has(id) && !formValueSet.has(value)) {
      promises.push(
        LibraryAPI.deleteUtmPresetValue({ brandId, id }).then(() => {
          presetValue.deleted = true; // Mark as deleted, can't asynchronously remove from array
        }),
      );
    }
  });

  const currentValueMap = new Map(currentValuesCopy.map((value) => [value.id, value]));
  const existingValueSet = new Set(currentValuesCopy.map(({ value }) => value));

  formValuesCopy.forEach((presetValue) => {
    const currentValue = currentValueMap.get(presetValue.id);
    const alreadyExists = existingValueSet.has(presetValue.value);
    if (currentValue) {
      // Update preset value if the value has changed
      const { id, value } = presetValue;
      if (value !== currentValue.value) {
        promises.push(
          LibraryAPI.updateUtmPresetValue({ brandId, id, value }).then((response) => {
            Object.assign(currentValue, response.data);
            Object.assign(presetValue, response.data);
          }),
        );
      }
    } else if (!alreadyExists) {
      // Create preset value if value isn't saved in the database yet
      const { channel, trackingKey } = props;
      const { value } = presetValue;
      promises.push(
        LibraryAPI.createUtmPresetValue({ brandId, channel, trackingKey, value }).then(
          (response) => {
            Object.assign(presetValue, response.data);
            currentValuesCopy.push(response.data);
          },
        ),
      );
    }
  });

  const promiseResults = await Promise.allSettled(promises);
  const finalizedValues = currentValuesCopy.filter(({ deleted }) => !deleted);

  trackUtmManagementPresetList({
    channel: channelLabel.value,
    trackingKey: props.trackingKey,
    presetValues: finalizedValues.map(({ value }) => value),
    previousPresetValues: currentValues.value.map(({ value }) => value),
  });

  const errors = promiseResults.filter(({ status }) => status === 'rejected');
  if (errors.length > 0) {
    errors.forEach(({ reason }) => {
      logger.error('Request failed while saving UTM preset values', {}, reason);
    });
    notificationStore.setToast({ message: utmEditPresetValueError, type: 'error' });

    // Update form state so front-end doesn't desync from backend
    currentValues.value = finalizedValues;
    formValues.value = formValuesCopy;
  } else {
    emit('close', finalizedValues);
  }

  isSaving.value = false;
}

function onClose() {
  emit('close', currentValues.value);
}

onBeforeMount(() => {
  if (props.presetValues.length > 0) {
    // We don't want a prop change to change our current form state so we deliberately update these
    // refs only once before mounting
    currentValues.value = cloneDeep(props.presetValues);
    formValues.value = cloneDeep(props.presetValues);
  }
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <Popup
    type="small"
    :close="onClose"
    :close-confirmation="closeConfirmation"
    :show-when-drawer-open="props.showWhenDrawerOpen"
  >
    <div class="flex flex-col items-center">
      <h4 class="mb-10 normal-case">Preset List for {{ trackingKey }}</h4>
      <Banner alert-type="information" class="mb-8">{{ utmEditPresetValueInfo }}</Banner>
      <span
        v-if="presetValues.length > 0"
        class="mb-3 ml-6 self-start text-xs font-medium text-[color:--text-secondary]"
      >
        Preset Value
      </span>
      <List class="self-stretch" :items="formValues">
        <template #main-content="{ item, index }">
          <input
            v-model.trim="item.value"
            :class="['self-start', { 'input-error': formValueIsDuplicate[index] }]"
            type="text"
            @keyup.enter="addFormField"
          />
        </template>
        <template #action-content="{ index }">
          <Button
            data-cy="delete-preset-value-button"
            icon-name="bin"
            :disabled="formIsEmpty"
            @click="deleteFormField(index)"
          />
        </template>
      </List>
      <Button
        data-cy="add-preset-value-button"
        class="mb-8 mt-4 self-start text-sm"
        link
        :disabled="formHasEmptyField"
        @click="addFormField"
      >
        <Icon xxsmall name="add" class="mr-2" />Add Preset Value
      </Button>
      <Button
        data-cy="save-button"
        primary
        wide
        :disabled="disableSaveButton"
        :loading="isSaving"
        @click="onSave"
      >
        Save
      </Button>
    </div>
  </Popup>
</template>
