import filter from 'lodash/filter';

class DragSelect {
  /**
   * @param {object} el - Element selection box is bounded by.
   * @param {number} selectionMargin - Extra buffer (in pixels) around `el` to make selection easier.
   * @param {Function} selectableGetter - A function that returns an array of elements that are selectable. The array
   * contents should be objects like [{ el: <HTMLElement>, item: <Object> }, ...], where `item` is data associated with
   * the element.
   * @param {Function} selectedSetter - A function called when items are selected. Will be supplied two parameters -
   * an array of items `selectingElements` like [{ el: <HTMLElement>, item: <Object> }], and a boolean `additive`
   * indicating whether the elements should add to selection or not.
   * @param {string} scrollableAreaSelector - CSS selector for element that should scroll while drag selecting down
   * or up.
   * @param {boolean} additiveSelect - Whether subsequent drag selects should add to selection or not.
   * @param {string} ignoreSelectionClass - Ignore selection click when target element has this class.
   * @param {string} ignoreSelectionWithinSelector - Ignore selection click when element that matches this selector
   * contains the target element.
   * @param {boolean} popup - If drag select scrolling is active in a modal type element
   */
  constructor({
    el,
    selectionMargin,
    selectableGetter,
    selectedSetter,
    scrollableAreaSelector,
    additiveSelect,
    ignoreSelectionClass,
    ignoreSelectionWithinSelector,
    popup = false,
  }) {
    this.el = el;
    this.selectableGetter = selectableGetter;
    this.selectedSetter = selectedSetter;
    this.scrollableAreaSelector = scrollableAreaSelector;
    this.additiveSelect = additiveSelect;
    this.ignoreSelectionTagNames = ['INPUT', 'TEXTAREA'];
    this.ignoreSelectionClass = ignoreSelectionClass;
    this.ignoreSelectionWithinSelector = ignoreSelectionWithinSelector;

    this.isDragging = false;
    this.dragTimer = null;
    this.startX = null;
    this.startY = null;
    this.lastMouseX = null;
    this.lastMouseY = null;
    this.endX = null;
    this.endY = null;
    this.selectableElements = [];
    this.selectingElements = [];
    this.scrollTick = 8;
    this.scrollDistance = 0;
    this.scrollTimer = null;
    this.selectionMargin = selectionMargin;
    this.popup = popup;

    const elem = document.createElement('div');
    this.scrollableElement = this.popup
      ? document.querySelectorAll(this.scrollableAreaSelector)[0]
      : this.el;
    elem.className = 'drag-selection-box';
    if (this.popup) {
      this.scrollableElement.style.position = 'relative';
      this.scrollableElement.appendChild(elem);
    } else {
      document.body.appendChild(elem);
    }

    this.selectionBoxEl = elem;
  }

  mouseDownHandler = (e) => {
    // Ignore selection on some overlapping input / textarea elements
    // to avoid default click-focus being disabled by e.preventDefault()
    if (this.ignoreSelectionTagNames.includes(e.target.tagName)) {
      return;
    }

    if (this.ignoreSelectionClass && e.target.classList.contains(this.ignoreSelectionClass)) {
      return;
    }

    // Ignore selection on elements contained within `ignoreSelectionWithinSelector`
    if (this.ignoreSelectionWithinSelector) {
      const ignores = Array.from(document.querySelectorAll(this.ignoreSelectionWithinSelector));
      if (ignores.length > 0 && ignores.some((ignore) => ignore.contains(e.target))) {
        return;
      }
    }

    // Ignore right click events
    if (e.which === 3) {
      return;
    }

    const element = this.popup ? this.scrollableElement.firstElementChild : this.scrollableElement;
    const box = DragSelect.getBoundingBox(element);
    const isMouseInBox = this.isMouseInBox(box, e.pageY);
    if (isMouseInBox) {
      e.preventDefault();
      const x = this.getX(box, e.pageX);
      const y = this.getY(box, e.pageY);
      this.endX = x;
      this.startX = x;
      this.endY = y;
      this.startY = y;
      this.scrollDistance = 0;
      this.dragTimer = setTimeout(() => {
        this.isDragging = true;
      }, 250);
    }
  };

  mouseMoveHandler = (e) => {
    this.scrollDistance = 0;
    if (this.isDragging) {
      e.preventDefault();
      let box = DragSelect.getBoundingBox(this.scrollableElement);
      const isMouseInBox = this.isMouseInBox(box, e.pageY);
      const height = this.popup ? box.top + box.height - e.clientY : window.innerHeight - e.clientY;
      const topCondition = e.clientY - box.top < 16;
      if ((this.popup && height < 16) || (isMouseInBox && height < 16)) {
        if (this.scrollTimer === null) {
          this.incrementalScroll(this.scrollTick);
        }
      } else if ((this.popup && topCondition) || (isMouseInBox && topCondition)) {
        if (this.scrollTimer === null) {
          this.incrementalScroll(this.scrollTick * -1);
        }
      } else {
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
      }
      this.lastMouseX = e.pageX;
      this.lastMouseY = e.pageY;
      box = this.popup ? DragSelect.getBoundingBox(this.scrollableElement.firstElementChild) : box;
      this.endX = this.getX(box, e.pageX);
      this.endY = this.getY(box, e.pageY);
      this.renderSelection();
    }
  };

  mouseUpHandler = (e) => {
    this.scrollDistance = 0;
    if (this.isDragging) {
      const element = this.popup
        ? this.scrollableElement.firstElementChild
        : this.scrollableElement;
      const box = DragSelect.getBoundingBox(element);
      this.endX = this.getX(box, e.pageX);
      this.endY = this.getY(box, e.pageY);
      this.isDragging = false;
      this.updateSelection();

      if (this.selectingElements.length > 0) {
        this.selectedSetter({
          els: this.selectingElements,
          additive: this.additiveSelect,
        });
        this.selectingElements = [];
      }
    }
    if (this.dragTimer) {
      clearTimeout(this.dragTimer);
    }
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }
    this.renderSelection();
  };

  scrollHandler = () => {
    if (this.isDragging) {
      const element = this.popup ? this.scrollableElement.firstElementChild : this.el;
      const box = DragSelect.getBoundingBox(element);
      if (this.popup) {
        this.endX = this.lastMouseX - box.left;
        this.endY = this.lastMouseY - box.top;
      } else {
        this.endX = Math.min(Math.max(box.left, this.lastMouseX), box.width + box.left);
        this.endY = Math.min(
          Math.max(box.top, this.lastMouseY + this.scrollDistance),
          box.height + box.top,
        );
      }
      this.renderSelection();
    }
  };

  init() {
    document.addEventListener('mousedown', this.mouseDownHandler);
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
    document.addEventListener('scroll', this.scrollHandler);
    this.scrollableElement.addEventListener('scroll', this.scrollHandler);
  }

  deinit() {
    document.removeEventListener('mousedown', this.mouseDownHandler);
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    document.removeEventListener('scroll', this.scrollHandler);
    this.scrollableElement.addEventListener('scroll', this.scrollHandler);
  }

  isMouseInBox(box, eventPageY) {
    return (
      box.top - this.selectionMargin <= eventPageY &&
      eventPageY <= box.top + box.height + this.selectionMargin
    );
  }

  incrementalScroll(scrollTick) {
    this.scrollTimer = setTimeout(() => {
      if (!this.popup) {
        window.scrollTo(0, window.scrollY + scrollTick);
      } else {
        const box = DragSelect.getBoundingBox(this.scrollableElement);
        const scrollBox = DragSelect.getBoundingBox(this.scrollableElement.firstElementChild);
        this.scrollableElement.scrollTo(0, box.top - scrollBox.top + scrollTick);
      }
      this.scrollDistance += scrollTick;
      this.incrementalScroll(scrollTick);
    }, 50);
  }

  getSelectionBox(updateSelection) {
    const selectionBox = {
      left: Math.min(this.startX, this.endX),
      top: Math.min(this.startY, this.endY),
      width: Math.abs(this.startX - this.endX),
      height: Math.abs(this.startY - this.endY),
    };

    if (this.popup) {
      const box = DragSelect.getBoundingBox(this.scrollableElement.firstElementChild);
      if (updateSelection) {
        selectionBox.left += box.left;
        selectionBox.top += box.top;
      } else {
        const parentBox = DragSelect.getBoundingBox(this.scrollableElement);
        selectionBox.left += box.left - parentBox.left;
      }
    }

    return selectionBox;
  }

  updateSelection() {
    const s = this.getSelectionBox(true);
    this.selectableElements = filter(this.selectableGetter(), (x) => x.item != null);
    this.selectingElements = filter(this.selectableElements, (x) => {
      const box = DragSelect.getBoundingBox(x.el);
      return (
        Math.abs((s.left - box.left) * 2 + s.width - box.width) < s.width + box.width &&
        Math.abs((s.top - box.top) * 2 + s.height - box.height) < s.height + box.height
      );
    });
  }

  renderSelection() {
    if (this.selectionBoxEl) {
      const { style } = this.selectionBoxEl;
      if (this.isDragging) {
        const s = this.getSelectionBox(false);
        style.display = 'block';
        style.left = `${s.left}px`;
        style.top = `${s.top}px`;
        style.width = `${s.width}px`;
        style.height = `${s.height}px`;
      } else {
        style.display = 'none';
      }
    }
  }

  static getBoundingBox(element) {
    const box = element.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset,
      left: box.left + window.pageXOffset,
      width: box.width,
      height: box.height,
    };
  }

  getY(box, y) {
    if (this.popup) {
      return y - box.top;
    }

    return Math.min(
      Math.max(box.top - this.selectionMargin, y),
      box.height + box.top + this.selectionMargin,
    );
  }

  getX(box, x) {
    if (this.popup) {
      return x - box.left;
    }
    return Math.min(
      Math.max(box.left - this.selectionMargin, x),
      box.width + box.left + this.selectionMargin,
    );
  }
}

export default {
  beforeMount: (el, binding) => {
    const {
      enabled,
      selectableGetter,
      selectedSetter,
      selectionMargin,
      scrollableAreaSelector,
      additiveSelect,
      ignoreSelectionClass,
      ignoreSelectionWithinSelector,
      popup,
    } = binding.value;
    if (enabled) {
      el.dragSelect = new DragSelect({
        el,
        selectableGetter,
        selectedSetter,
        selectionMargin,
        scrollableAreaSelector,
        additiveSelect,
        ignoreSelectionClass,
        ignoreSelectionWithinSelector,
        popup,
      });
      el.dragSelect.init();
    }
  },
  updated: (el, binding) => {
    if (el.dragSelect && binding.value.enabled !== binding.oldValue.enabled) {
      if (binding.value.enabled) {
        el.dragSelect.init();
      } else {
        el.dragSelect.deinit();
      }
    }
  },
  unmounted: (el) => {
    if (el.dragSelect) {
      el.dragSelect.deinit();
    }
  },
  // get rid of componentUpdated hook as it will make drag & drop buggy.
  // (Everywhere will be disabled and not able to re-enable once a disabled component is mounted)
  // We can always enable it and use ignoreSelectionClass to control where we want to disable it.
};
