import dayjs from 'dayjs';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import humps, { camelizeKeys, decamelizeKeys } from 'humps';
import { externalAxios } from '@/apis/external-apis';
import { imageCropperPresets, toolTips, UPLOAD_STATUS, videoCropperPresets } from '@/config';
import { env } from '@/env';
import { colours } from '@/ux/colours';
import { getImageDataFromMedia, getVideoDataFromMedia, getVideoDuration } from '@/utils/media';
import { ImageAPI, IMAGE_API_DOMAINS } from '@/utils/imageApi';
import { canvasToBlob } from '@/utils';
import { axios as libraryAxios } from '@/apis/library';
import {
  postStatus,
  mediaValidationMap,
  minRecommendedImageWidth,
  minRecommendedVideoWidth,
  pinterestNoImageMessage,
  PLATFORMS,
  postTypes,
  MULTI_CHANNEL_POST_TYPES,
  PLATFORM_POST_TYPE_MAP,
  SCHEDULER_ADD_NEW_DROPDOWN_OPTIONS,
  SCHEDULER_ADD_NEW_OPTIONS_EVENT,
  PLATFORM_POST_TYPE,
  EVENT_COLOR_OPTIONS,
  DEFAULT_COLOR_CLASS,
} from '@/app/scheduler/constants';
import pinia from '@/stores/pinia';
import { mediaTypes } from '@/app/library/constants';
import MediaModel from '@/models/media';
import { guessTimezone } from '@/utils/timezone';
import { useFlagStore } from '@/stores/flag';
import cloneDeep from 'lodash/cloneDeep';

const flagStore = useFlagStore(pinia);

export function pinBoardAndTitleCheck(noBoard, noTitle) {
  const missingFields = [];
  if (noBoard) {
    missingFields.push('Board');
  }
  if (noTitle) {
    missingFields.push('Title');
  }
  const message = missingFields.join(' & ');
  if (message) {
    return `Pin ${message} ${
      missingFields.length > 1 ? 'are' : 'is'
    } required for auto-publishing.`;
  }
  return null;
}

export function getPostThumbnail(post) {
  const formattedPost = humps.camelizeKeys(post);
  // MediaV2 thumbnail object
  const media =
    formattedPost?.media && formattedPost?.media?.length > 0 ? formattedPost?.media[0] : null;
  let thumbs = null;
  if (media) thumbs = media.thumbnails ? media.thumbnails : media.sizes;

  // coming from library
  let thumbUrl = null;
  if (formattedPost?.image) thumbUrl = formattedPost?.image?.sizes?.small?.url;
  if (formattedPost?.video) thumbUrl = formattedPost?.video?.thumbnails?.small?.url;

  const resolvedUrl =
    thumbUrl ||
    formattedPost?.thumbnailUrl ||
    formattedPost?.imageUrl ||
    (formattedPost?.linkPreview && formattedPost?.linkPreview?.image) ||
    (thumbs && thumbs.small.url) ||
    null;

  if (!resolvedUrl) return null;
  const isImageAPIUrl = IMAGE_API_DOMAINS.some((domain) => resolvedUrl?.includes(domain));

  // Dynamically fetch smaller sizes from image API so browser rendering is faster
  // for published IG posts (identified by isLive)
  if (post?.isLive && !isImageAPIUrl) {
    return ImageAPI.url(resolvedUrl, { w: 225, h: 400 });
  }

  return resolvedUrl;
}

export function shouldClearVideoThumbnail(originalPost, updatedPost) {
  const postMedia = originalPost.media?.[0];
  const updateMedia = updatedPost.media?.[0];
  return postMedia?.type === 'VIDEO' && (updateMedia?.type === 'IMAGE' || !updateMedia);
}

// Format a post object for display in full calendar
export function formatPost(post) {
  return {
    id: `post-${post?.id}`,
    start: dayjs(post?.timestamp).tz(guessTimezone()).format('YYYY-MM-DDTHH:mm:ss.sss'),
    durationEditable: false,
    ...([postStatus.AUTOPUBLISHING, postStatus.IMPORTED, postStatus.POSTED].includes(
      post?.status,
    ) && { editable: false }),
    extendedProps: {
      type: 'post',
      imageUrl: getPostThumbnail(post),
      fullObj: post,
    },
  };
}

export function rotatePosts(posts, oldIndex, newIndex) {
  // oldIndex/newIndex are generated by the event, eg: 0, 1 etc. != backend sort_index values
  let newSortIndexVal = null;

  if (newIndex === 0) {
    // To head of page
    newSortIndexVal = posts[0].sort_index + 1;
  } else if (newIndex === posts.length - 1) {
    // To tail of page
    const newUpperNeighbor = posts[posts.length - 1];
    // Temporarily set the sort_index to move post to the end of the page in the UI until the
    // precise calculation is returned from the back end. These numbers may or may not be the same,
    // depending on whether there is another page of posts not yet been retrieved from the back end.
    newSortIndexVal = newUpperNeighbor.sort_index - 1;
  } else {
    // Insert within page
    // New neighbor will be to the right or left of the new post position,
    // depending on whether the post moves forwards or back
    const newNeighbor = newIndex > oldIndex ? posts[newIndex + 1] : posts[newIndex - 1];
    const postToReplace = posts[newIndex];
    newSortIndexVal = (postToReplace.sort_index + newNeighbor.sort_index) / 2;
  }
  return [{ id: posts[oldIndex].id, sort_index: newSortIndexVal }];
}

export function rotatePostsByProperty(posts, oldIndex, newIndex, property, hasDraftAccess = false) {
  // Extract array of values that need to be shifted
  const [start, end] = [oldIndex, newIndex].sort((a, b) => a - b);
  const postsSubset = posts.slice(start, end + 1);
  const ids = postsSubset.map((post) => post.id);
  const values = postsSubset.map((post) => post[property]);

  // Rotate the values array left or right
  if (oldIndex < newIndex) {
    values.unshift(values.pop());
  } else {
    values.push(values.shift());
  }
  return ids.map((id, index) => ({
    id,
    [property]: values[index],
    ...(postsSubset[index].status === postStatus.DRAFT && hasDraftAccess
      ? { status: postStatus.DRAFT }
      : {}),
  }));
}

export function getPublishOptions(canAutoPublish, autopublishTooltip) {
  return [
    {
      text: 'Auto Publish',
      iconName: 'flash',
      iconColor: colours.SUCCESS.SUCCESS_500,
      value: 'autoPublish',
      disabled: !canAutoPublish,
      tooltip: autopublishTooltip,
    },
    {
      text: 'Send Notification',
      iconName: 'alarm-timeout',
      iconColor: colours.BRAND.BRAND_ACCENT,
      value: 'notification',
    },
  ];
}

async function hasLongerParentMediaDuration(media, brandId, videoDuration, minDuration) {
  // check if there is a parent media and compare duration
  if (media.meta && media.meta.parentMediaId) {
    if (media.meta.parentMediaDuration) {
      return media.meta.parentMediaDuration;
    }

    const res = await libraryAxios.get(`/brands/${brandId}/media/${media.meta.parent_media_id}`);
    const parentVideoDuration = res.data.duration;
    return parentVideoDuration && parentVideoDuration >= minDuration
      ? parentVideoDuration > videoDuration
      : false;
  }
  return false;
}

async function validateImage(
  media,
  validations,
  isPluralValidationMessage = false,
  isAbbrevMessage = false,
) {
  const aspectRatioSpecs = validations.aspectRatio?.image;
  const fileSizeSpecs = validations.fileSize?.image;
  const dimensionsSpecs = validations.dimensions?.image;

  const minImageAspectRatio = aspectRatioSpecs?.minNumeric;
  const maxImageAspectRatio = aspectRatioSpecs?.maxNumeric;
  const maxImageSize = fileSizeSpecs?.maxNumeric;
  const minImageDimension = dimensionsSpecs?.minNumeric;
  const maxImageDimension = dimensionsSpecs?.maxNumeric;
  const maxImageArea = dimensionsSpecs?.maxAreaNumeric;

  const reelCoverPhotoSpecs = validations.fileSize?.coverImage;
  const maxReelCoverImageSize = reelCoverPhotoSpecs?.maxNumeric;

  if (media.uploadStatus && media.uploadStatus !== UPLOAD_STATUS.SUCCESS) {
    // If media is still uploading then we can't validate it
    return null;
  }

  const image = getImageDataFromMedia(media);

  if (isEmpty(image)) {
    return (
      'An error has occurred while trying to validate your image. Please try uploading your ' +
      'image again!'
    );
  }

  if (reelCoverPhotoSpecs && image.size > maxReelCoverImageSize) {
    return `The cover image you have selected doesn't meet Instagram's size requirements. Please select an image that is less than ${reelCoverPhotoSpecs.max} and try again.`;
  }

  // If file size validation is required
  if (fileSizeSpecs && image.size > maxImageSize) {
    if (isPluralValidationMessage) {
      return (
        `The image(s) you have selected don't meet ${fileSizeSpecs.platformName}'s ` +
        `size requirements. Please make sure the image is smaller than ` +
        `${validations.fileSize.image.max} and try again.`
      );
    }
    if (isAbbrevMessage) {
      return `${fileSizeSpecs.platformName} requires images to be less than ${fileSizeSpecs.max}.`;
    }
    return `The image file is too large. Please ensure that it is smaller than ${fileSizeSpecs.max} and try again!`;
  }

  // If aspect ratio validation is required
  // If the media is already cropped, use the selected aspect ratio instead of width/height,
  // since ffmpeg in the backend may convert width.
  const aspectRatioLabel = media.editorData?.aspectRatio;
  const ratioNumericValue = imageCropperPresets[aspectRatioLabel]?.ratioNumericValue;
  const aspectRatio = ratioNumericValue || (image.width / image.height).toFixed(4);

  if (
    aspectRatioSpecs &&
    (aspectRatio < minImageAspectRatio || aspectRatio > maxImageAspectRatio)
  ) {
    if (isPluralValidationMessage) {
      return (
        `The image(s) you've selected don't meet ${aspectRatioSpecs.platformName}'s ` +
        `size requirements. Please use the cropping tools to adjust your images to an aspect ratio ` +
        `between ${aspectRatioSpecs.min} and ` +
        `${aspectRatioSpecs.max} (width:height) and try again!`
      );
    }
    const adjective = aspectRatio < minImageAspectRatio ? 'tall' : 'wide';
    return (
      `This image is too ${adjective}. Please use the cropping tools to crop your image to an ` +
      `aspect ratio (width:height) between ${aspectRatioSpecs.min} and ${aspectRatioSpecs.max} and try again!`
    );
  }

  // If dimensions validation is required
  const imageTooSmall =
    minImageDimension && (image.height < minImageDimension || image.width < minImageDimension);
  const imageTooLarge =
    maxImageDimension && (image.height > maxImageDimension || image.width > maxImageDimension);
  const imageAreaTooLarge = maxImageArea && image.height * image.width > maxImageArea;

  if (dimensionsSpecs && imageAreaTooLarge) {
    let errorMessage = `This image doesn't meet ${dimensionsSpecs.platformName}'s size requirements. Please crop your image to be less than ${dimensionsSpecs.maxArea} and try again!`;
    if (isAbbrevMessage) {
      errorMessage = `${dimensionsSpecs.platformName} requires images to be less than ${dimensionsSpecs.maxArea}.`;
    } else if (dimensionsSpecs.platformName === PLATFORMS.LINKEDIN) {
      errorMessage = `The dimensions of your image (${image.width}px width x ${image.height}px height) exceed the publishing limit of 36 million pixels`;
    }
    return errorMessage;
  }
  if (dimensionsSpecs && imageTooSmall) {
    if (isPluralValidationMessage) {
      return (
        `The image(s) you have selected don't meet ${dimensionsSpecs.platformName}'s size requirements. Please ` +
        `make sure the image has a width and height that are greater than ${dimensionsSpecs.min}.`
      );
    }
    if (isAbbrevMessage) {
      return `${dimensionsSpecs.platformName} requires images to be larger than ${dimensionsSpecs.min}.`;
    }
    return (
      `The image you have selected doesn't meet ${dimensionsSpecs.platformName}'s size requirements. Please ` +
      `make sure the image has a width and height that are greater than ${dimensionsSpecs.min}.`
    );
  }

  if (dimensionsSpecs && imageTooLarge) {
    if (isPluralValidationMessage) {
      return (
        `The image(s) you have selected don't meet ${dimensionsSpecs.platformName}'s size requirements. Please ` +
        `make sure the image has a width and height that are less than ${dimensionsSpecs.max}.`
      );
    }
    if (isAbbrevMessage) {
      return `${dimensionsSpecs.platformName} requires images to be less than ${dimensionsSpecs.max}.`;
    }
    return `The image you have selected doesn't meet ${dimensionsSpecs.platformName}'s size requirements.
      Please make sure the image has a width and height that less than ${dimensionsSpecs.max}.`;
  }

  // If image passes all validation checks, return null.
  return null;
}

async function validateVideo(media, brandId, validations) {
  const aspectRatioSpecs = validations.aspectRatio && validations.aspectRatio.video;
  const fileSizeSpecs = validations.fileSize && validations.fileSize.video;
  const dimensionsSpecs = validations.dimensions && validations.dimensions.video;
  const durationSpecs = validations.duration && validations.duration.video;

  let minVideoAspectRatio = null;
  let maxVideoAspectRatio = null;
  let maxVideoSize = null;
  let minVideoDimension = null;
  let maxVideoDimensionWidth = null;
  let maxVideoDimensionHeight = null;
  let minDuration = null;
  let maxDuration = null;

  if (aspectRatioSpecs) {
    minVideoAspectRatio = validations.aspectRatio.video.minNumeric;
    maxVideoAspectRatio = validations.aspectRatio.video.maxNumeric;
  }
  if (fileSizeSpecs) {
    maxVideoSize = validations.fileSize.video.maxNumeric;
  }

  if (dimensionsSpecs) {
    minVideoDimension = validations.dimensions.video.minNumeric;
    maxVideoDimensionWidth = validations.dimensions.video.maxWidthNumeric;
    maxVideoDimensionHeight = validations.dimensions.video.maxHeightNumeric;
  }

  if (durationSpecs) {
    minDuration = validations.duration.video.minNumeric;
    maxDuration = validations.duration.video.maxNumeric;
  }

  const videoObj = getVideoDataFromMedia(media);

  if (isEmpty(videoObj)) {
    return (
      'An error has occurred while trying to validate your video. Please try uploading your ' +
      'video again!'
    );
  }
  if (media.uploadStatus && media.uploadStatus !== UPLOAD_STATUS.SUCCESS) {
    // If media is still uploading then we can't validate it
    return null;
  }
  // If aspect ratio validation is required
  // If the media is already cropped, use the selected aspect ratio instead of width/height,
  // since ffmpeg in the backend may convert width.
  const aspectRatioLabel = media.editorData?.aspectRatio;
  const ratioNumericValue = videoCropperPresets[aspectRatioLabel]?.ratioNumericValue;
  const aspectRatio = ratioNumericValue || videoObj.width / videoObj.height;

  if (
    aspectRatioSpecs &&
    (aspectRatio < minVideoAspectRatio || aspectRatio > maxVideoAspectRatio)
  ) {
    const adjective = aspectRatio < minVideoAspectRatio ? 'tall' : 'wide';
    return (
      `This video is too ${adjective}. Please use the video cropper to crop your video to an ` +
      `aspect ratio (width:height) between ${validations.aspectRatio.video.min} and ` +
      `${validations.aspectRatio.video.max}.`
    );
  }

  // If file size validation is required
  if (fileSizeSpecs && videoObj.size >= maxVideoSize) {
    return (
      `This video file is too large. Please select a video smaller than ` +
      `${validations.fileSize.video.max}.`
    );
  }

  // If duration validation is required
  const videoDuration = await getVideoDuration(media, brandId);
  if (durationSpecs && videoDuration > maxDuration) {
    if (durationSpecs.trimmerMaxNumeric && videoDuration > durationSpecs.trimmerMaxNumeric) {
      return `This video is too long and the video trimmer can't be used due to the length.
        Please select a video between ${validations.duration.video.min} and
        ${durationSpecs.trimmerMax} to access the trimmer.`;
    }
    return (
      `This video is too long. Please use the video trimmer to trim your video to be ` +
      `between ${validations.duration.video.min} and ` +
      `${validations.duration.video.max}.`
    );
  }
  if (durationSpecs && videoDuration < minDuration) {
    // Check to see if user is able to extend video length of previously trimmed video
    if (await hasLongerParentMediaDuration(media, brandId, videoDuration, minDuration)) {
      return (
        `This video is too short. Please use the video trimmer to extend your video to be ` +
        `between ${validations.duration.video.min} and ${validations.duration.video.max} and try ` +
        `again.`
      );
    }
    return (
      `This video is too short. Please add a new video between ` +
      `${validations.duration.video.min} and ${validations.duration.video.max}.`
    );
  }

  // If dimensions validation is required
  if (
    minVideoDimension &&
    (videoObj.width < minVideoDimension || videoObj.height < minVideoDimension)
  ) {
    return `This video file doesn't meet ${validations.dimensions.video.platformName}'s size
      requirements. Please select a video with a width and height greater than
      ${validations.dimensions.video.min}.`;
  }

  if (maxVideoDimensionWidth && videoObj.width > maxVideoDimensionWidth) {
    return (
      `This video file doesn't meet ${validations.dimensions.video.platformName}'s size ` +
      `requirements. Please use the video cropper to crop your video to have a maximum ` +
      `width of ${validations.dimensions.video.maxWidth}.`
    );
  }

  if (maxVideoDimensionHeight && videoObj.height > maxVideoDimensionHeight) {
    return (
      `This video file doesn't meet ${validations.dimensions.video.platformName}'s size ` +
      `requirements. Please use the video cropper to crop your video to have a maximum height of
      ${validations.dimensions.video.maxHeight}.`
    );
  }
  // If video passes all validation checks, return null.
  return null;
}

export function hasOriginalConverted(media) {
  return (
    media?.sizes?.originalConverted ||
    media?.fullMediaObject?.sizes?.originalConverted ||
    media?.fullMediaObject?.videoSizes?.originalConverted
  );
}

export async function validateTiktokMedia(media, brandId, autoPublish) {
  const validations = mediaValidationMap.tiktok;

  if (
    !media ||
    (media.uploadStatus && media.uploadStatus !== UPLOAD_STATUS.SUCCESS) ||
    media.isProcessing
  ) {
    return null;
  }

  if (media.type === mediaTypes.IMAGE) {
    return { level: 'error', message: 'Images are not supported by TikTok. Please select a video' };
  }

  if (autoPublish && !hasOriginalConverted(media)) {
    return { level: 'error', message: toolTips.mediaConversionError };
  }

  if (media.type === mediaTypes.VIDEO && autoPublish) {
    // Check duration
    if (media.duration > validations.duration?.video?.maxNumeric) {
      return {
        level: 'error',
        message:
          `Videos longer than ${validations.duration?.video?.max} cannot be auto published` +
          ` to TikTok. Please use the video trimmer, select the Send Notification option, or ` +
          `select a shorter video.`,
      };
    }

    const errorMessage = await validateVideo(media, brandId, validations);
    if (errorMessage) {
      return {
        level: 'error',
        message: errorMessage,
      };
    }
  }

  return null;
}

export async function validateLinkedInMedia(media) {
  const validations = mediaValidationMap.linkedin;

  if (
    !media ||
    (media.uploadStatus && media.uploadStatus !== UPLOAD_STATUS.SUCCESS) ||
    media.isProcessing
  ) {
    return null;
  }

  if (media.type === mediaTypes.VIDEO) {
    return { level: 'error', message: 'Videos are not supported for now. Please select an image.' };
  }

  if (media.type === 'CAROUSEL') {
    return {
      level: 'error',
      message: 'Carousels are not supported. Please select an image.',
    };
  }

  if (!hasOriginalConverted(media)) {
    return { level: 'error', message: toolTips.mediaConversionError };
  }

  const errorMessage = await validateImage(media, validations);
  if (errorMessage) {
    return {
      level: 'error',
      message: errorMessage,
    };
  }

  return null;
}

export async function validatePinterestMedia(media, brandId, isAbbrevMessage) {
  const validations = mediaValidationMap.pinterest;
  if (
    !media ||
    // If media is still uploading then we can't validate it
    (media.uploadStatus && media.uploadStatus !== UPLOAD_STATUS.SUCCESS) ||
    media.isProcessing
  ) {
    return null;
  }

  if (!hasOriginalConverted(media)) {
    return toolTips.mediaConversionError;
  }

  if (media.type === 'IMAGE') {
    // Images must be no larger than 32MB, have a min width and height of 100px,
    // and a max area of 82.9 megapixels
    return validateImage(media, validations, false, isAbbrevMessage);
  }

  if (media.type === 'VIDEO') {
    // Video duration must be between 4s and 15mins inclusively, have an aspect ratio between 1:2
    // and 1.91:1, and no larger than 2GB
    return validateVideo(media, brandId, validations);
  }
  return null;
}

export function validatePin(media, boardName, pinTitle, autoPublish, timestamp) {
  const error = pinBoardAndTitleCheck(!boardName, !pinTitle);
  if (!media) {
    return pinterestNoImageMessage;
  }
  if (!timestamp || !autoPublish) {
    return null;
  }
  if (error) {
    return error;
  }
  return null;
}

export function validateRecommendedMediaSize(media, coverImage = false) {
  if (!media) return null;

  if (media.uploadStatus && media.uploadStatus !== 'success') {
    // If media is still uploading then we can't validate it
    return null;
  }

  if (media.type === 'IMAGE') {
    const imageData = getImageDataFromMedia(media);
    const coverImagePrefix = coverImage ? 'cover ' : '';
    if (imageData && imageData.width < minRecommendedImageWidth) {
      return {
        level: 'warning',
        message: `Your ${coverImagePrefix}image is ${imageData.width}px wide, which may compromise its quality when
          published. We recommend using an image with a width of ${minRecommendedImageWidth}px or
          greater.`,
      };
    }
    return null;
  }

  if (media.type === 'VIDEO') {
    const videoData = getVideoDataFromMedia(media);
    if (videoData && videoData.width < minRecommendedVideoWidth) {
      return {
        level: 'warning',
        message: `Your video is ${videoData.width}px wide, which may compromise its quality when
          published. We recommend using a video with a width of ${minRecommendedVideoWidth}px or
          greater.`,
      };
    }
    return null;
  }
  return null;
}

export async function checkTikTokWarnings(media) {
  if (!media) return null;

  if (media.uploadStatus && media.uploadStatus !== 'success') {
    // If media is still uploading then we can't validate it
    return null;
  }

  const videoData = await getVideoDataFromMedia(media);
  const minRecommendedDimensionNumeric = 600;

  // Check dimensions
  if (
    videoData &&
    (videoData.width < minRecommendedDimensionNumeric ||
      videoData.height < minRecommendedDimensionNumeric)
  ) {
    return {
      level: 'warning',
      message: `This video is ${videoData.height} x ${videoData.width}, which may compromise its quality when published.
      We recommend using a video with a width and height greater than ${minRecommendedDimensionNumeric}px.`,
    };
  }

  return null;
}

function timestampUpdated(scheduledTime, post) {
  if (scheduledTime && post?.timestamp) {
    return !scheduledTime.isSame(post.timestamp);
  }

  return !scheduledTime !== !post?.timestamp;
}

export function checkUnsavedChanges(postData, post, scheduledTime, excludeFields = []) {
  const formattedPostData = humps.camelizeKeys(postData);
  const formattedPost = humps.camelizeKeys(post);

  const valuesDiffer = (a, b) => !!a !== !!b || (!!a && !!b && !isEqual(a, b));

  // Ignore approval request status changes if approval requests are the same
  if (formattedPostData?.approvalRequests?.length === formattedPost?.approvalRequests?.length) {
    excludeFields.push('approvalRequests');
    excludeFields.push('approvalStatus');
  }

  excludeFields.forEach((field) => {
    delete formattedPostData[field];
    delete formattedPost[field];
  });

  const fieldDiff = formattedPost?.id
    ? Object.keys(formattedPostData)
        .filter((f) => !['media', 'timestamp'].includes(f))
        .some((field) => valuesDiffer(formattedPost[field], formattedPostData[field]))
    : !Object.values(formattedPostData).every(isEmpty);
  return fieldDiff || timestampUpdated(scheduledTime, formattedPost);
}

function isUpload(mediaList) {
  if (mediaList && mediaList[0]) {
    return Boolean(mediaList[0].uploadStatus);
  }
  return false;
}

function contentPublishing(post) {
  return post && post.status === postStatus.AUTOPUBLISHING && post.auto_publish_error === null;
}

export function mediaUrl(mediaList) {
  if (isUpload(mediaList) && mediaList[0].previewUrl) {
    return mediaList[0].previewUrl;
  }
  // MediaV2 Object
  const media = mediaList[0].fullMediaObject || mediaList[0];
  if (media && media.sizes) {
    return media.sizes.originalConverted
      ? media.sizes.originalConverted.url
      : media.sizes.original.url;
  }
  return null;
}

export function shouldDisplayKeyFrames(mediaList, autoPublish, post) {
  if (!mediaList[0]) {
    return null;
  }
  const mediaType = mediaList[0].type;
  return (
    mediaList &&
    mediaList.length === 1 &&
    mediaType === 'VIDEO' &&
    autoPublish &&
    !contentPublishing(post) &&
    (post ? post.status !== postStatus.IMPORTED : true)
  );
}

// Create a video element in memory, seek it to the thumbnail offset, and capture the frame
// TODO: We should be able to re-use the existing video element that the user sees for this instead
// of re-creating one in memory. This may reduce memory usage. Investigate when possible.
// A better option may be to move this into Library Backend and let ffmpeg create the thumbnail
export class ThumbnailUrlGenerator {
  constructor() {
    this.video = document.createElement('video');
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.video.crossOrigin = 'anonymous';
    this.video.preload = 'auto';
    this.destroyed = false;
  }

  async createThumbnailUrl(mediaList, thumbOffset, onThumbnailError) {
    return new Promise((resolve, reject) => {
      const videoMedia = getVideoDataFromMedia(mediaList[0]);
      // using the mp4 url in case jpeg is the default route in library galleries case
      const videoSrc =
        videoMedia?.url?.endsWith('jpeg') && videoMedia?.urls
          ? videoMedia.urls?.original
          : videoMedia?.url;

      if (!videoSrc) {
        reject(new Error('Video src does not exist'));
      }

      // Prevent Chrome from trying to use cached responses that don't have the appropriate CORS
      // headers. See https://serverfault.com/a/856948 for more detail.
      this.video.src = `${videoSrc}?x-bust-cache=true`;

      // no-dd-sa:javascript-browser-security/event-check-origin
      this.video.addEventListener('loadeddata', () => {
        this.canvas.height = this.video.videoHeight;
        this.canvas.width = this.video.videoWidth;
        this.video.currentTime = thumbOffset;
      });

      // no-dd-sa:javascript-browser-security/event-check-origin
      this.video.addEventListener('seeked', async () => {
        try {
          this.ctx.drawImage(this.video, 0, 0);
          const blob = await canvasToBlob(this.canvas);
          // Prevent thumbnail generation process from running in background if component is destroyed
          if (this.destroyed) return;

          // Request a signed S3 upload URL
          const params = { filename: 'image.jpeg', content_type: blob.type };
          const res = await libraryAxios.post(`/media_upload_url`, params);
          const thumbnailUrl = res.data
            .split('?')[0]
            .replace(/(.*\.s3.amazonaws.com)/, env.uploadedMediaStorageUrl);
          const options = {
            headers: {
              'Content-Type': blob.type,
            },
          };
          // Upload thumbnail to S3
          await externalAxios.put(res.data, blob, options);
          resolve(thumbnailUrl);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`Error happened in video seeked:${e.message}`);
          reject(new Error(`Error happened in video seeked:${e.message}`));
        }
      });

      this.video.addEventListener('error', (event) => {
        let err = new Error('Video could not be loaded.');
        if (event && event.path && event.path[0] && event.path[0].error) {
          err = event.path[0].error;
        }
        reject(err);
      });
    }).catch((error) => {
      if (onThumbnailError) {
        onThumbnailError();
      }
      // eslint-disable-next-line no-console
      console.error(`Promise rejected: ${error?.message}`, error);
    });
  }

  destroy() {
    // Fixes memory leak that occurs in Safari. Visit link for more details:
    // https://stackoverflow.com/questions/5170398/ios-safari-memory-leak-when-loading-unloading-html5-video
    this.destroyed = true;
    this.video.src = '';
    this.video.load();
  }
}

export function validateMediaLimits(
  type,
  existingMediaCount,
  existingVideoCount,
  mediaCountLimit,
  videoCountLimit,
  allowMixedMedia,
) {
  const mediaCountMessage = `${mediaCountLimit} ${allowMixedMedia ? 'item' : 'photo'}${
    mediaCountLimit > 1 ? 's' : ''
  }`;
  const videoCountMessage = `${videoCountLimit} video${videoCountLimit > 1 ? 's' : ''}`;
  const conjunction = allowMixedMedia ? ' with ' : ' or ';
  const conditions = [];
  if (mediaCountLimit !== null) {
    conditions.push(mediaCountMessage);
  }
  if (videoCountLimit !== null) {
    conditions.push(videoCountMessage);
  }

  if (
    (mediaCountLimit !== null && existingMediaCount >= mediaCountLimit) ||
    (videoCountLimit !== null && existingVideoCount >= videoCountLimit && type === 'VIDEO') ||
    (!allowMixedMedia && type === 'VIDEO' && existingVideoCount < existingMediaCount) ||
    (!allowMixedMedia && type === 'IMAGE' && existingVideoCount > 0)
  ) {
    if (conditions.length > 0) {
      return `You can only select up to ${conditions.join(conjunction)}.`;
    }
    return `You cannot select both images and videos.`;
  }
  return null;
}

export function videoPredictionMixpanelEvent(suggestedThumbnails, thumbOffset, brandId) {
  const variance = 0.25;
  let pickedSuggestion = false;
  suggestedThumbnails.forEach((thumbnail) => {
    if (
      thumbOffset >= thumbnail.framePosition - variance &&
      thumbOffset <= thumbnail.framePosition + variance
    ) {
      pickedSuggestion = true;
    }
  });
  return {
    action: 'Vision Suggested thumbnail',
    properties: {
      brand_id: brandId,
      pickedSuggestion,
    },
  };
}

export const mergeCase = (obj) => Object.freeze(merge(camelizeKeys(obj), decamelizeKeys(obj)));

export const parsePost = (platform) => (post) => ({
  ...mergeCase({
    ...post,
    platform,
    timestamp: post.timestamp && dayjs(post.timestamp).toDate(),
    replies: post.replies?.map((reply) => ({
      ...reply,
      platform,
      timestamp: reply.timestamp && dayjs(reply.timestamp).toDate(),
      media: reply.media?.map((media) => new MediaModel(media)),
    })),
    coverImageMedia: post.coverImageMedia ? new MediaModel(post.coverImageMedia) : null,
  }),
  // Do not mergeCase post media values to maintain media object constructed as MediaModel
  media: post.media?.map((media) => new MediaModel(media)),
});

export const getPostKey = (post) => `${post.platform}-${post.id}`;

export const mapPostsByKey = (posts) =>
  Object.fromEntries(posts.map((post) => [getPostKey(post), Object.freeze(post)]));

export const getMultiChannelPostType = (post) => {
  switch (post.platform) {
    case PLATFORMS.TIKTOK:
      return MULTI_CHANNEL_POST_TYPES.TIKTOK_POST;
    case PLATFORMS.INSTAGRAM:
      switch (post.postType) {
        case postTypes.STORY:
          return MULTI_CHANNEL_POST_TYPES.INSTAGRAM_STORY_FRAME;
        default:
          return MULTI_CHANNEL_POST_TYPES.INSTAGRAM_POST;
      }
    case PLATFORMS.FACEBOOK:
      return MULTI_CHANNEL_POST_TYPES.FACEBOOK_POST;
    case PLATFORMS.PINTEREST:
      return MULTI_CHANNEL_POST_TYPES.PINTEREST_PIN;
    case PLATFORMS.TWITTER:
      return MULTI_CHANNEL_POST_TYPES.TWITTER_TWEET;
    case PLATFORMS.LINKEDIN:
      return MULTI_CHANNEL_POST_TYPES.LINKEDIN_POST;
    default:
      throw new Error(`Unknown post platform: ${post.platform}!`);
  }
};

/**
 * This function serves as a way of mapping a platform type string such as 'facebook', 'instagram',
 * 'pinterest', 'twitter', or 'tiktok' to the corresponding platform connection type. This should
 * centralize the confusion until we have a chance to refactor platform connections.
 *
 * @param platformType Platform type string, e.g. facebook, instagram, pinterest, twitter, tiktok.
 */
export function platformToProvider(platformType) {
  if (platformType === 'instagram') {
    return 'facebook';
  }
  if (platformType === 'facebook') {
    return 'facebook_analytics';
  }
  return platformType;
}

export function twitterMentionLimitExceeded(error) {
  const validationErrors = error.response?.data?.errors;
  return error.response.status === 400 && validationErrors?.includes?.('MENTION_LIMIT_EXCEEDED');
}

export function isPublishDateError(error, errorCode) {
  const validationErrors = error.response?.data?.errors;
  const matchingErrors = validationErrors?.filter((e) => e.code === errorCode);
  return error.response.status === 400 && matchingErrors.length > 0;
}

export const isPostThumbnailPortrait = (post) =>
  post.platform === PLATFORMS.TIKTOK ||
  ((post.platform === PLATFORMS.INSTAGRAM || post.platform === PLATFORMS.FACEBOOK) &&
    [postTypes.STORY, postTypes.REEL].includes(post.post_type));

export const showPostOnFeedPreview = (post) =>
  [postStatus.SCHEDULED, postStatus.DRAFT].includes(post.status) &&
  (post.postType === 'FEED' || (post.postType === 'REEL' && post.shareToFeed)) &&
  post.timestamp &&
  post.timestamp > new Date();

export const allMediaUploaded = (mediaList) => {
  const mediaExists = mediaList?.some(
    (m) => !m?.uploadStatus || (m?.uploadStatus && m?.uploadStatus === UPLOAD_STATUS.SUCCESS),
  );
  return mediaExists;
};

export const filterDrafts = (posts, hasDraftToggleOn) => {
  return hasDraftToggleOn ? posts : posts.filter((post) => post.status !== postStatus.DRAFT);
};

export const getUnscheduledRouteName = (platform) => {
  return `scheduler.${platform}.unscheduled`;
};

export const getPostTypeIcon = (post) => {
  if (post?.postType === postTypes.STORY) return 'story';
  if (post?.postType === postTypes.REEL) return 'reel';
  if (post?.replies?.length > 0) return 'twitter-thread';
  if (post?.media[0]?.type === 'VIDEO') return 'play';
  return null;
};

export const updateTabRoute = (newTab, router) => {
  const currentRoute = router.currentRoute.value.name;
  const currentTab = currentRoute.split('.')[2];
  const newRoute = currentRoute.replace(currentTab, newTab);
  router.push({ name: newRoute });
};

export const getPlatform = (platform) => (platform === 'pinterest_v5' ? 'pinterest' : platform);

export const getPlatformPostTypeFromPost = (post) => {
  const platform = getPlatform(post.platform);
  const platformPostType = PLATFORM_POST_TYPE_MAP[platform];
  return platform === PLATFORMS.INSTAGRAM
    ? platformPostType[post?.postType || postTypes.FEED]
    : platformPostType;
};

export const getPlatformPostType = (platform, postType = 'FEED') =>
  platform === PLATFORMS.INSTAGRAM
    ? PLATFORM_POST_TYPE_MAP[platform][postType]
    : PLATFORM_POST_TYPE_MAP[platform];

export const getPlatformFromPost = (post) => {
  if (post.platform === PLATFORMS.INSTAGRAM && post.postType === postTypes.STORY)
    return 'instagramStory';
  if (post.platform === PLATFORMS.INSTAGRAM && post.postType === postTypes.REEL)
    return 'instagramReel';
  return post.platform;
};

export const getCalendarAddPostMenuOptions = () => {
  let dropdownList = cloneDeep(
    SCHEDULER_ADD_NEW_DROPDOWN_OPTIONS.filter(
      (option) => !SCHEDULER_ADD_NEW_OPTIONS_EVENT.includes(option),
    ),
  );
  if (!flagStore.flags.linkedinAutoPublishingWeb) {
    dropdownList = dropdownList.filter(
      (channel) => channel.postType !== PLATFORM_POST_TYPE.LINKEDIN_POST,
    );
  }
  return dropdownList;
};

export const parseApprovalRequestsByBrand = (approvalRequests, postType, currentRequests) => {
  const channelMap = {
    tiktok: [],
    facebook: [],
    instagramPost: [],
    instagramStory: [],
    instagramReel: [],
    pinterest: [],
    twitter: [],
    linkedin: [],
  };
  const currentApprovalsMap = { ...currentRequests };

  // Add new approval requests that do not exist in the map yet
  approvalRequests.forEach((request) => {
    if (!currentApprovalsMap[request.brandId]) currentApprovalsMap[request.brandId] = channelMap;
    const isRequestExisting = currentApprovalsMap[request.brandId]?.[postType].find(
      (req) => req.reviewUserId === request.reviewUserId,
    );

    if (!isRequestExisting) {
      currentApprovalsMap[request.brandId][postType].push(request);
    }
  });

  // Remove deleted approval requests from the map.
  Object.keys(currentApprovalsMap).forEach((brandId) => {
    currentApprovalsMap[brandId][postType] = currentApprovalsMap[brandId][postType].filter(
      (existingReq) => {
        return approvalRequests.some((req) => req.reviewUserId === existingReq.reviewUserId);
      },
    );
  });
  return currentApprovalsMap;
};

export const getEventColourClass = (dashEvent) => {
  const flag = flagStore.ready && flagStore.flags.schedulerColouredEvents;
  if (!flag) return 'bg-[var(--color-chart-blueberry-blast)]';
  return (
    EVENT_COLOR_OPTIONS.find((option) => option.value === dashEvent?.color)?.class ||
    DEFAULT_COLOR_CLASS
  );
};
