import * as CreatorsAPI from '@/apis/creators';
import { generateMetaPaidPartnersStatusKey } from '@/app/library/queries/meta-paid-partners';
import { useIdentityStore } from '@/stores/identity';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import { useToast } from '@dashhudson/dashing-ui';
import { defineMutation, useMutation, useQueryCache } from '@pinia/colada';
import { storeToRefs } from 'pinia';

export const useRevokeMetaPaidPartners = defineMutation(() => {
  const queryCache = useQueryCache();
  const { addToast } = useToast({ life: 5000 });

  // Stores
  const { currentBrand } = storeToRefs(useIdentityStore());
  const { brandInstagramAccount } = storeToRefs(useInstagramAccountStore());

  const { mutate, ...mutation } = useMutation({
    mutation: ({ creatorInstagramId, handle }) =>
      CreatorsAPI.revokeMetaPaidPartners({
        brandId: currentBrand.value.id,
        brandInstagramId: brandInstagramAccount.value?.fbBusinessInstagramId,
        creatorInstagramId,
        handle,
      }),
    onSuccess: (_data, { handle }) => {
      queryCache.invalidateQueries({
        key: generateMetaPaidPartnersStatusKey({
          brandId: currentBrand.value.id,
          brandInstagramId: brandInstagramAccount.value?.fbBusinessInstagramId,
          handle,
        }),
      });
      addToast({
        severity: 'success',
        summary: 'Partnership Request Revoked',
        message: 'The creator can no longer authorize your permission to promote their content.',
      });
    },
    onError: () => {
      addToast({
        severity: 'error',
        summary: 'Failed to Revoke Request',
        message: 'Unable to revoke this request. Please try again.',
      });
    },
  });

  return {
    revokeMetaPaidPartners: mutate,
    ...mutation,
  };
});

export const useRequestMetaPaidPartners = defineMutation(() => {
  const queryCache = useQueryCache();
  const { addToast } = useToast({ life: 5000 });

  // Stores
  const { currentBrand } = storeToRefs(useIdentityStore());
  const { brandInstagramAccount } = storeToRefs(useInstagramAccountStore());

  const { mutate, ...mutation } = useMutation({
    mutation: ({ handle }) =>
      CreatorsAPI.requestMetaPaidPartners({
        brandId: currentBrand.value.id,
        brandInstagramId: brandInstagramAccount.value?.fbBusinessInstagramId,
        handle,
      }),
    onSuccess: (_data, { handle }) => {
      queryCache.invalidateQueries({
        key: generateMetaPaidPartnersStatusKey({
          brandId: currentBrand.value.id,
          brandInstagramId: brandInstagramAccount.value?.fbBusinessInstagramId,
          handle,
        }),
      });
      addToast({
        severity: 'success',
        summary: 'Partnership Ads Request Sent',
        message: `You've sent a request to ${handle}. They'll receive it via Instagram Direct Message.`,
      });
    },
    onError: () => {
      addToast({
        severity: 'error',
        summary: 'Partnership Ads Request Failed',
        message: 'Unable to send the request. Please try again.',
      });
    },
  });

  return {
    requestMetaPaidPartners: mutate,
    ...mutation,
  };
});
