import { ref } from 'vue';
import { defineStore } from 'pinia';
import mixpanel from 'mixpanel-browser';
import { isDatadogSyntheticsBrowser } from '@/config';
import { env } from '@/env';

const EVENT_NAMES = {
  PAGE_VIEWED: 'Page Viewed',
};

const ready = ref(false);

function initMixpanel() {
  const mixpanelConfig = { track_pageview: false, secure_cookie: true };

  if (env.mixpanelApiHost) {
    mixpanelConfig.api_host = env.mixpanelApiHost;
  }

  const enabled = env.mixpanelEnabled && !isDatadogSyntheticsBrowser && !window.Cypress;
  if (enabled) {
    mixpanel.init(env.mixpanelToken, mixpanelConfig);
    ready.value = true;
  } else {
    mixpanel.init(null, { ...mixpanelConfig, test: true }, 'mixpanel');
    mixpanel.disable();
  }
}
initMixpanel();

export const useMixpanelStore = defineStore('mixpanel', () => {
  function track(eventName, ...args) {
    mixpanel.track(eventName, ...args);
  }

  function registerSuperProperties(properties) {
    if (properties) {
      mixpanel.register(properties);
    }
  }

  function trackPage({ page, payload, unload }) {
    if (unload) {
      mixpanel.set_config({ api_transport: 'sendBeacon' });
    }
    registerSuperProperties({ page_sp_test: page });
    track(EVENT_NAMES.PAGE_VIEWED, payload);
    mixpanel.time_event(EVENT_NAMES.PAGE_VIEWED);
  }

  function setIdentity(properties = {}) {
    if (properties) {
      mixpanel.identify(properties.userId);
      mixpanel.people.set(properties);
    }
  }

  function setUtmQueryParams({ firstTouchParams = {}, lastTouchParams = {} } = {}) {
    if (Object.keys(firstTouchParams).length > 0) {
      mixpanel.people.set_once(firstTouchParams);
    }
    mixpanel.people.set({
      ...lastTouchParams,
    });
    registerSuperProperties({
      ...lastTouchParams,
    });
  }

  function reset() {
    mixpanel.reset();
  }

  return {
    ready,
    track,
    trackPage,
    registerSuperProperties,
    reset,
    setUtmQueryParams,
    setIdentity,
  };
});
