import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { camelCase } from 'lodash/string';
import numeral from 'numeral';
import constants from '@/app/settings/components/CustomMetrics/constants';

import { toolTips } from '@/config';
import { ADS_CHANNELS, CHANNELS } from '@/models/dashboards/channels.enum';
import { GRAPH_SCALES } from '@/models/dashboards/graph-scales.enum';
import { GRAPH_STATS } from '@/models/dashboards/graph-stats.enum';
import {
  DURATION_FORMATS,
  QUERY_TIMEFRAME,
  UPDATED_CONFIG_CHANNELS,
} from '@/models/dashboards/metrics.constants';
import { getEnumText, getEnumValue } from '@/models/enum.utils';
import { useIdentityStore } from '@/stores/identity';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';
import { formatDuration, formatDynamicDuration } from '@/utils/formatters';
import {
  BAR_CHART_REPORTS,
  METRIC_TYPE_REPORT_KEYS,
  getReportConfigProp,
} from '@/app/dashboards/utils/reports.enum';

import { ADS_REPORT_AGGREGATE_BY_TYPES } from '@/models/dashboards/aggregate-type.enum';
import { getMetricDetails } from '@/utils/metrics';
import { useFlagStore } from '@/stores/flag';
import { MULTI_CHANNEL_METRICS } from './multi-channel-metrics.enum';

// TODO 104187: Remove this file and all references. Consts should be moved to consts file.
export const METRICS = {};

const UNAVAILABLE_METRICS_FALLBACK = {};
const multiChannelTooltips = {
  ...toolTips.multiChannelDashboard,
  ...toolTips.sentimentDashboard,
};

export function isUnavailableMetricByFlags(channel, metric, flags) {
  if (flags) {
    return (flags.dashboardUnavailableMetrics?.[channel] || []).includes(metric);
  }
  return (UNAVAILABLE_METRICS_FALLBACK[channel] || []).includes(metric);
}

export const DEFAULT_FORMAT = Object.freeze({
  NORMAL: {
    value: 'NORMAL',
    format: '0.[00]a',
  },
  LONG: {
    value: 'LONG',
    format: '0,0.[00]',
  },
});

// TODO 119109: Remove this entire object. Should use backend field in filterMetricsIfHidden now
const METRIC_FLAGS = Object.freeze({
  FACEBOOK: {
    REELS_BLUE_REELS_PLAYS_COUNT: 'fbReelsDashboards',
    REELS_FB_REELS_REPLAY_COUNT: 'fbReelsDashboards',
    REELS_FB_REELS_TOTAL_PLAYS: 'fbReelsDashboards',
    REELS_POST_IMPRESSIONS_UNIQUE: 'fbReelsDashboards',
    REELS_POST_VIDEO_AVG_TIME_WATCHED: 'fbReelsDashboards',
    REELS_POST_VIDEO_VIEW_TIME: 'fbReelsDashboards',
    REELS_POST_VIDEO_FOLLOWERS: 'fbReelsDashboards',
    REELS_AVG_EFFECTIVENESS: 'fbReelsDashboards',
  },
});

export function getMetricConfigProp(channel, metric, property, defaultValue) {
  const channelKey = getEnumValue(channel);
  const metricKey = getEnumValue(metric);
  return get(METRICS, `${channelKey}.${metricKey}.${property}`, defaultValue);
}

export function getMediaV2MetricName(channel, metric) {
  const mediaV2MetricName = getMetricConfigProp(channel, metric, 'mediaV2MetricName');
  const metricValue = getEnumValue(metric);
  const defaultMediaV2tMetricName = metricValue?.toLowerCase();
  return mediaV2MetricName || defaultMediaV2tMetricName || metricValue;
}

export function isMultiChannel(channels) {
  return Array.isArray(channels) && channels.length > 1;
}

export function getMetricText(
  firstChannel,
  metric,
  allChannels = null,
  report = null,
  aggregatedBy = '',
  metricReportTypeKey = null,
) {
  const metricTypeReportKey =
    metricReportTypeKey ?? getReportConfigProp(report, 'metricTypeReportKey');
  // get displayName from back end data if available
  const channels = allChannels ?? [firstChannel];
  let { displayName: textOverride } = getMetricDetails(metric, channels, metricTypeReportKey);
  if (textOverride && !ADS_CHANNELS.includes(firstChannel)) {
    return textOverride;
  }

  const contentReportTextOverride = METRICS?.[firstChannel]?.[metric]?.contentReportMetricText;
  const isContentReport = metricTypeReportKey === METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT;

  const textProp =
    contentReportTextOverride && isContentReport ? 'contentReportMetricText' : 'text';

  if (!textOverride) {
    // get displayName from front end config
    textOverride = getMetricConfigProp(firstChannel, metric, textProp) || getEnumText(metric);
  }

  if (
    [CHANNELS.META_ADS.value, CHANNELS.TIKTOK_ADS.value].includes(firstChannel) &&
    textOverride &&
    aggregatedBy
  ) {
    textOverride = textOverride.replace(
      '- Ads',
      `${ADS_REPORT_AGGREGATE_BY_TYPES[aggregatedBy].text} - Ads`,
    );
  }

  return isMultiChannel(allChannels) ? MULTI_CHANNEL_METRICS[metric]?.text : textOverride;
}

function getMetricFormat(channel, metric, type = DEFAULT_FORMAT.NORMAL) {
  const formatType = getEnumValue(type);
  const metricFormats = getMetricConfigProp(channel, metric, 'formats');
  const format = metricFormats?.[formatType];
  return format || DEFAULT_FORMAT[formatType]?.format || DEFAULT_FORMAT.NORMAL.format;
}

export function getMetricTooltip(channels, metric) {
  if (isMultiChannel(channels)) {
    return get(multiChannelTooltips, camelCase(metric), '');
  }
  const firstChannel = [].concat(channels || [])?.[0];
  return getMetricConfigProp(firstChannel, metric, 'tooltip') || '';
}

export function getMetricGraphScale(channel, metric) {
  return getMetricConfigProp(channel, metric, 'graphScales') || Object.keys(GRAPH_SCALES);
}

export function getMetricGraphStats(
  channel,
  metric,
  reportType = null,
  metricType = null,
  isCustomMetric = false,
) {
  if (metricType === constants.METRIC_TYPE.PERCENTAGE.value && isCustomMetric) {
    return [];
  }
  if (BAR_CHART_REPORTS.includes(reportType)) {
    return [GRAPH_STATS.SUM.value];
  }
  return (
    getMetricConfigProp(channel, metric, 'graphStats') || [
      GRAPH_STATS.SUM.value,
      GRAPH_STATS.MEAN_BY_DAY.value,
    ]
  );
}

function formatValue(value, format = DEFAULT_FORMAT.NORMAL.format, emptyValue = undefined) {
  switch (format) {
    case DURATION_FORMATS.HOURS:
      return formatDuration(value, 0, 'hour');
    case DURATION_FORMATS.HOURS_MINUTES:
      return formatDuration(value, 0, 'minute');
    case DURATION_FORMATS.HOURS_MINUTES_SECONDS:
      return formatDuration(value, value >= 60 ? 0 : 1);
    case DURATION_FORMATS.SHORTENED_YEARS_DAYS_HOURS_MINUTES_SECONDS:
      return formatDynamicDuration(value);
    default:
      return isNumber(value) ? numeral(value).format(format).toUpperCase() : emptyValue;
  }
}

export function formatMetric(channel, metric, value, type, unit = '') {
  let metricKey = metric;
  const dashboardReportsStore = useDashboardReportsStore();
  const channelHasMetricConfig = METRICS?.[channel]?.[metric];
  if (!channelHasMetricConfig) {
    metricKey = dashboardReportsStore.getComparableMetricBasedOnChannel(channel, metric);
  }
  const emptyValue =
    METRICS?.[channel]?.[metricKey] &&
    Object.keys(METRICS?.[channel]?.[metricKey]).includes('emptyValue')
      ? String(METRICS?.[channel]?.[metricKey].emptyValue)
      : '-';
  let formattedValue = formatValue(value, getMetricFormat(channel, metricKey, type), emptyValue);
  if (unit) {
    formattedValue = formattedValue.concat(` ${unit}`);
  }
  return formattedValue;
}

// TODO 104187: Filter functions should be simplified and moved into metrics util file
export function filterMetricsByBrandPermissions(channels, brands = null) {
  const identityStore = useIdentityStore();
  return (metric) => {
    return channels.every((channel) =>
      identityStore.checkIfBrandPermissionsExist(METRICS?.[channel]?.[metric]?.permissions, brands),
    );
  };
}

export function filterMetricByFlags(flags, channels) {
  return (metric) => {
    const channelList = isMultiChannel(channels) ? channels : [channels];
    const channelsWithFlags = []
      .concat(channelList)
      .filter((channel) => METRIC_FLAGS?.[channel]?.[metric]);
    const filterOutMetricBasedOnFlag = channelsWithFlags.length > 0;
    if (filterOutMetricBasedOnFlag) {
      const flagsToCheck = channelsWithFlags.map((channel) => {
        return METRIC_FLAGS[channel][metric];
      });
      return [].concat(flagsToCheck).every((flag) => !!flags?.[flag]);
    }
    return true;
  };
}

export function filterMetricsIfHidden(channels, allMetricDetails, metricTypeReportKey) {
  return (metric) => {
    return channels.every((channel) => {
      if (UPDATED_CONFIG_CHANNELS.includes(channel)) {
        if (channel === CHANNELS.INSTAGRAM.value || channel === CHANNELS.INSTAGRAM_UGC.value) {
          return (
            !allMetricDetails?.[channel]?.[metricTypeReportKey]?.[metric]?.hide_on_dashboards &&
            !allMetricDetails?.[CHANNELS.INSTAGRAM_UGC.value]?.[metricTypeReportKey]?.[metric]
              ?.hide_on_dashboards
          );
        }
        const dashboardReportsStore = useDashboardReportsStore();
        const flagStore = useFlagStore();

        const isMultipleChannels = channels.length > 1;
        const comparableMetricKey = isMultipleChannels
          ? dashboardReportsStore.comparableMetrics?.[metric]?.[channel]
          : null;

        const metricDetails =
          allMetricDetails?.[channel]?.[metricTypeReportKey]?.[comparableMetricKey ?? metric];
        const requiredFeatureFlag = metricDetails?.required_feature_flag;
        const hasRequiredFlag = Boolean(
          !requiredFeatureFlag || (flagStore.ready && flagStore.flags?.[requiredFeatureFlag]),
        );
        return !metricDetails?.hide_on_dashboards && hasRequiredFlag;
      }

      const metricConfig = METRICS?.[channel]?.[metric];

      return Boolean(metricConfig && !metricConfig.hideOnDashboards);
    });
  };
}

export function filterMetricByDisabledMultiChannel(channels) {
  return (metric) => {
    if (channels.length === 1) {
      return true;
    }
    const channelMetricDisabledMap = channels.map((channel) => {
      return METRICS?.[channel]?.[metric]?.disableMultiChannelGraph === true;
    });
    const channelMetricDisabled = channelMetricDisabledMap.includes(true);
    return !channelMetricDisabled;
  };
}

export function filterMetricIfUnknownInChannel(
  channels,
  comparableMetrics,
  allMetricDetails,
  metricTypeReportKey,
) {
  return (metric) => {
    return channels.every((channel) => {
      const mappedMetric = comparableMetrics?.[metric]?.[channel] ?? metric;
      if (UPDATED_CONFIG_CHANNELS.includes(channel)) {
        if (
          channel === CHANNELS.INSTAGRAM.value &&
          ![
            'AVG_EFFECTIVENESS_V2',
            'IMPRESSIONS_V2',
            'TOTAL_VIDEO_VIEWS_V2',
            'ORGANIC_VIDEO_VIEWS_V2',
          ].includes(metric)
        ) {
          return (
            allMetricDetails?.[channel]?.[metricTypeReportKey]?.[metric] ||
            allMetricDetails?.[CHANNELS.INSTAGRAM_UGC.value]?.[metricTypeReportKey]?.[metric]
          );
        }
        return allMetricDetails?.[channel]?.[metricTypeReportKey]?.[mappedMetric];
      }
      if (channel === CHANNELS.INSTAGRAM.value) {
        return (
          METRICS?.[CHANNELS.INSTAGRAM.value]?.[mappedMetric] ||
          METRICS?.[CHANNELS.INSTAGRAM_UGC.value]?.[mappedMetric]
        );
      }
      return METRICS?.[channel]?.[mappedMetric];
    });
  };
}

export function metricSupportsContentTags(channel, metrics, report, allMetricDetails) {
  const metricTypeReportKey = getReportConfigProp(report, 'metricTypeReportKey');
  const isMetricsReport = metricTypeReportKey === METRIC_TYPE_REPORT_KEYS.METRICS_REPORT;
  const isGraphReport = metricTypeReportKey === METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT;

  return [].concat(metrics ?? []).every((metric) => {
    if (UPDATED_CONFIG_CHANNELS.includes(channel)) {
      return allMetricDetails?.[channel]?.[metricTypeReportKey]?.[metric]?.allow_content_tags;
    }
    const metricEnum = METRICS?.[channel]?.[metric] ?? {};
    const allowsContentTags = metricEnum?.allowContentTags ?? false;
    const isActivityInTimeframe =
      metricEnum?.timeframe === QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME;
    return allowsContentTags && !((isGraphReport || isMetricsReport) && isActivityInTimeframe);
  });
}

export function filterMetricsIfContentTags(channels, contentTagIds, report, allMetricDetails) {
  const dashboardReportsStore = useDashboardReportsStore();
  const hasContentTags = contentTagIds?.length > 0;
  const metricTypeReportKey = getReportConfigProp(report, 'metricTypeReportKey');
  const isContentReport = metricTypeReportKey === METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT;
  return (metric) => {
    if (hasContentTags) {
      return channels.every((channel) => {
        const comparableMetric =
          channels.length > 1
            ? dashboardReportsStore.getComparableMetricBasedOnChannel(channel, metric)
            : metric;
        return metricSupportsContentTags(channel, comparableMetric, report, allMetricDetails);
      });
    }

    return channels.every((channel) => {
      // TODO: Remove backend restriction on only using story frames when content tags are used.
      //  Then, can remove this section of code and have access to all stories content metrics
      const isInstagramStories = channel === CHANNELS.INSTAGRAM_STORIES.value;
      if (isContentReport && isInstagramStories) {
        return !allMetricDetails?.[channel]?.[metricTypeReportKey]?.[metric]?.allow_content_tags;
      }
      return true;
    });
  };
}

export function getMetricTimeframe(channel, metric) {
  const timeframe = getMetricConfigProp(channel, metric, 'timeframe');
  return timeframe ?? QUERY_TIMEFRAME.POSTS_PUBLISHED_IN_TIMEFRAME;
}
