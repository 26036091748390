import { computed, ref } from 'vue';

import snakeCase from 'lodash/snakeCase';
import {
  CUSTOM_METRIC_CHANNELS_FOR_CHANNEL_ICONS,
  DASHBOARD_EVENT_NAMES,
  SEND_TO_SETTINGS,
  DASHBOARD_CUSTOM_METRICS_PANEL,
} from '@/app/dashboards/constants';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { useDashboardsStore } from '@/stores/dashboards';
import { usePlatformStore } from '@/stores/platform';
import { useMetricsStore } from '@/stores/metrics';
import constants from '@/app/settings/components/CustomMetrics/constants';
import { toolTips } from '@/config';
import { BRAND, ORGANIZATION_USER } from '@/models/auth/permissions.enum';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { PLATFORM_CONNECTION_STATUS } from '@/models/platform/platform-connection.enum';
import { METRIC_TYPE_REPORT_KEYS } from '@/app/dashboards/utils/reports.enum';
import cloneDeep from 'lodash/cloneDeep';
import { useCustomMetricsStore } from '@/stores/custom-metrics';
import { useMetricDetails } from '@/app/dashboards/composables/useMetricDetails';
import { useRoute, useRouter } from 'vue-router';
import { useTrackingStore } from '@/stores/tracking';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
import { formatValueByFormatType } from '@/utils/formatters';
import { DATA_TYPE_FORMATS } from '@/models/dashboards/metrics.constants';
import isNil from 'lodash/isNil';
import { logger } from '@/utils/logger';
import isNumber from 'lodash/isNumber';

export function useCustomMetrics() {
  const customMetricChannelMap = ref({});
  const { CHANNEL_TO_CONNECTION_FIELD_MAP } = constants;
  const platformStore = usePlatformStore();
  const flagStore = useFlagStore();
  const identityStore = useIdentityStore();
  const customMetricsStore = useCustomMetricsStore();
  const metricsStore = useMetricsStore();
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const trackingStore = useTrackingStore();

  const router = useRouter();
  const route = useRoute();

  const isCustomMetricsFlagEnabled = computed(() => {
    return flagStore.ready && flagStore.flags?.customMetricsV1;
  });

  const canAccessCustomMetrics = computed(() => {
    const brandHasAccessToCustomMetrics = identityStore.guard(
      BRAND.SETTINGS.CAN_ACCESS_CUSTOM_METRICS,
    );
    return isCustomMetricsFlagEnabled.value && brandHasAccessToCustomMetrics;
  });

  const noAccessTooltip = computed(() =>
    !canAccessCustomMetrics.value ? DASHBOARD_CUSTOM_METRICS_PANEL.LINK_TEXT_TOOLTIP : null,
  );

  /**
   * NOTE: **Deprecated** The current user org doesn't make sense for custom metrics we should stop
   * using this and in its place check the if the user has org permissions for the brand orgs
   */
  const canManageCustomMetrics = computed(() => {
    const userOrgCanManageCustomMetrics = identityStore.guard(
      ORGANIZATION_USER.SETTINGS.CAN_MANAGE_CUSTOM_METRICS,
    );
    return isCustomMetricsFlagEnabled.value && userOrgCanManageCustomMetrics;
  });

  /**
   * NOTE: We use the brand orgs to check permission as the user may have a user org record
   * that's not applicable to there list of available brands
   */
  const someUserBrandHasCanManageCustomMetrics = computed(() => {
    const { brands: userBrands, organization_user_permissions: userOrganizationPermissions } =
      identityStore.identity;
    const brandOrganizationIds = Object.values(userBrands).map((brand) => brand.organizationId);

    return Object.entries(userOrganizationPermissions).some(
      ([key, userOrganizationPermission]) =>
        brandOrganizationIds.includes(Number(key)) &&
        userOrganizationPermission?.settings?.can_manage_custom_metrics,
    );
  });

  const canViewOrManageCustomMetrics = computed(() => {
    return canAccessCustomMetrics.value || canManageCustomMetrics.value;
  });

  function getChannelConnectionStatuses(uniqueChannelsUsed, brandIds) {
    return uniqueChannelsUsed.reduce((statuses, channel) => {
      const connectionField = CHANNEL_TO_CONNECTION_FIELD_MAP[channel];
      const accounts = connectionField
        ? platformStore.platformConnectionsMap[connectionField]
        : null;
      if (!connectionField || !accounts) {
        return { ...statuses, [channel]: false };
      }
      const hasDisconnected = brandIds.some((brandId) => {
        const status = accounts[brandId]?.status;
        return status !== PLATFORM_CONNECTION_STATUS.CONNECTED;
      });
      return { ...statuses, [channel]: hasDisconnected };
    }, {});
  }
  function sanitizeFormulaForChip(formulaString) {
    if (!formulaString) return [];

    return [formulaString.replace(/\s+/g, '')];
  }

  function updateCustomMetricBuilder(payload, mode) {
    const mappedPayload = {
      aggregation: payload.aggregation,
      brandIds: payload.brand_ids,
      description: payload.description || '',
      formula: payload.formula,
      metricFormat: payload.metric_format,
      name: payload.name,
      trendInterpretation: payload.trend_interpretation,
      id: payload.id,
      organizationId: payload.organization_id,
    };

    switch (mode) {
      case constants.CUSTOM_METRIC_SAVE_MODES.DUPLICATE:
        mappedPayload.name = `${mappedPayload.name} (copy)`;
        customMetricsStore.customMetricWizardMode = constants.CUSTOM_METRIC_SAVE_MODES.DUPLICATE;
        break;

      case constants.CUSTOM_METRIC_SAVE_MODES.UPDATE:
        customMetricsStore.customMetricWizardMode = constants.CUSTOM_METRIC_SAVE_MODES.UPDATE;
        break;

      case constants.CUSTOM_METRIC_SAVE_MODES.CREATE:
        customMetricsStore.customMetricWizardMode = constants.CUSTOM_METRIC_SAVE_MODES.CREATE;
        break;

      case constants.CUSTOM_METRIC_SAVE_MODES.DELETE:
        customMetricsStore.customMetricWizardMode = constants.CUSTOM_METRIC_SAVE_MODES.DELETE;
        break;

      default:
    }

    Object.assign(customMetricsStore.customMetricBuilder, cloneDeep(mappedPayload));
    customMetricsStore.customMetricBuilder.mode = mode;

    customMetricsStore.originalCustomMetricData = cloneDeep(mappedPayload);

    customMetricsStore.currentCustomMetricFormula = sanitizeFormulaForChip(
      customMetricsStore.originalCustomMetricData.formula,
    );
    customMetricsStore.currentCustomMetricFormat = mappedPayload.metricFormat;
  }

  function updateChannelConnectionStatuses(brandIds) {
    customMetricsStore.channelConnectionStatuses = getChannelConnectionStatuses(
      constants.PRE_DEFINED_CHANNELS,
      brandIds,
    );
  }
  function brandCanAccessCustomMetrics(brand) {
    return identityStore.guard(BRAND.SETTINGS.CAN_ACCESS_CUSTOM_METRICS, brand);
  }

  function brandHasReachedCustomMetricsLimit(brandUsage) {
    return brandUsage?.usage >= brandUsage?.limit;
  }

  function brandHasAlmostReachedCustomMetricsLimit(brandUsage) {
    if (!brandUsage) return false;

    const percentUsage = (brandUsage.usage / brandUsage.limit) * 100;

    return (
      brandUsage.usage < brandUsage.limit &&
      percentUsage > constants.CUSTOM_METRICS_USAGE_WARNING_PERCENTAGE
    );
  }

  function userCanManageCustomMetricsForSomeBrandOrganizationByBrandIds(brandIds) {
    const customMetricBrands = Object.values(identityStore.identity?.brands).filter((brand) =>
      brandIds.includes(brand.id),
    );
    const brandOrganizationIds = Object.values(customMetricBrands).map(
      (customMetricBrand) => customMetricBrand.organizationId,
    );

    const { organization_user_permissions: userOrganizationPermissions } = identityStore.identity;

    return Object.entries(userOrganizationPermissions).some(
      ([key, userOrganizationPermission]) =>
        brandOrganizationIds.includes(Number(key)) &&
        userOrganizationPermission?.settings?.can_manage_custom_metrics,
    );
  }

  function disableBrand(brand, brandUsage) {
    const brandOrganizationCanManageCustomMetrics =
      userCanManageCustomMetricsForSomeBrandOrganizationByBrandIds([brand.id]);
    return (
      !brandCanAccessCustomMetrics(brand) ||
      brandHasReachedCustomMetricsLimit(brandUsage) ||
      !brandOrganizationCanManageCustomMetrics
    );
  }

  function setCustomMetricBrandChip(brandUsage) {
    if (
      brandHasAlmostReachedCustomMetricsLimit(brandUsage) ||
      brandHasReachedCustomMetricsLimit(brandUsage)
    ) {
      return `${brandUsage.usage} / ${brandUsage.limit}`;
    }
    return null;
  }

  function disabledBrandTooltip(brand, brandUsage) {
    /**
     * TODO: Come up with tooltip copy for when the user doesn't have org permission for the
     * selected brand's organization
     */
    const brandOrganizationCanManageCustomMetrics =
      userCanManageCustomMetricsForSomeBrandOrganizationByBrandIds([brand.id]);
    if (!brandCanAccessCustomMetrics(brand) || !brandOrganizationCanManageCustomMetrics) {
      return toolTips.customMetrics?.brandCannotAccessCustomMetrics;
    }

    const hasBrandChip = setCustomMetricBrandChip(brandUsage);
    if (!brandUsage?.usage || !hasBrandChip) return null;
    if (brandHasReachedCustomMetricsLimit(brandUsage)) {
      return `Custom Metric limit reached. A brand can only have ${brandUsage?.limit} custom metrics`;
    }
    return `This brand has already been selected for ${brandUsage?.usage} custom metrics, out of ${brandUsage?.limit} max.`;
  }

  function setCustomMetricsUsageChipColor(brandUsage) {
    if (brandHasReachedCustomMetricsLimit(brandUsage)) {
      return constants.CUSTOM_METRICS_USAGE_THRESHOLD_COLORS.LIMIT_REACHED;
    }
    if (brandHasAlmostReachedCustomMetricsLimit(brandUsage)) {
      return constants.CUSTOM_METRICS_USAGE_THRESHOLD_COLORS.WARNING;
    }
    return constants.CUSTOM_METRICS_USAGE_THRESHOLD_COLORS.OK;
  }

  function getChannelText(channel) {
    return CHANNELS[channel]?.text;
  }

  function getChannelIcon(channel) {
    return CHANNELS[channel]?.channelIcon;
  }

  function generateCustomMetricTooltip(customMetric) {
    const name = customMetric.name;
    const description = customMetric?.description;
    const formula = customMetric.formula;

    if (!description) {
      return `${name}\n${formula}`;
    }
    return `${name}\n${description}\n${formula}`;
  }

  function getChannelConnectionData(
    uniqueChannelsUsed,
    brandIds,
    parsedCalculation,
    getChannelIconFromMetric,
  ) {
    if (!uniqueChannelsUsed.length || !brandIds.length) {
      return {
        channelConnectionStatuses: {},
        hasChannelDisconnection: false,
        metricErrors: {},
      };
    }
    const statuses = getChannelConnectionStatuses(uniqueChannelsUsed, brandIds);
    const hasDisconnection = Object.values(statuses).some(Boolean);
    const errors = parsedCalculation.reduce((acc, token) => {
      if (token.type === 'metric') {
        const metricName = token.value;
        const channelKey = getChannelIconFromMetric(metricName);
        acc[metricName] = statuses[channelKey] ?? false;
      }
      return acc;
    }, {});
    return {
      channelConnectionStatuses: statuses,
      hasChannelDisconnection: hasDisconnection,
      metricErrors: errors,
    };
  }

  function findDisplayNameByChannelMetricKey(metricKey) {
    const [channel, metric] = metricKey.split(':');
    const metricsReport = metricsStore.metricDetails[channel]?.metrics_report ?? {};
    return metricsReport[metric]?.display_name ?? metric;
  }

  function extractDisplayNameFromMetric(metricName) {
    const [metricChannel, metricKey] = metricName.split(':');
    const metricDetails = useMetricDetails({
      metric: metricKey,
      channels: [metricChannel],
      metricReportTypeKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
    });

    return metricDetails?.displayName?.value ?? metricKey;
  }

  function formatCustomMetricTooltip(customMetric) {
    const customMetricsFormulaList =
      customMetric.formula?.match(
        constants.CUSTOM_METRIC_REGEX.PERMITTED_FORMULA_CHARACTERS_PATTERN,
      ) || [];

    let customMetricsFormulaOutput = '';

    customMetricsFormulaList.forEach((part) => {
      if (part.startsWith("['") && part.endsWith("']")) {
        const [channel, metric] = part.slice(2, -2).split(':');

        const channelText = getChannelText(channel);
        const metricDisplayName = findDisplayNameByChannelMetricKey(`${channel}:${metric}`);

        customMetricsFormulaOutput += `(${channelText} ${metricDisplayName}) `;
      } else {
        customMetricsFormulaOutput += `${part} `; // operators
      }
    });

    return `${customMetric.name}\n\n${customMetric.description ? `${customMetric.description}\n\n` : ''}${customMetricsFormulaOutput}`;
  }

  function formatCustomMetricReportTooltip(customMetric) {
    const customMetricsFormulaList =
      customMetric.formula?.match(
        constants.CUSTOM_METRIC_REGEX.PERMITTED_FORMULA_CHARACTERS_PATTERN,
      ) || [];

    let customMetricsFormulaOutput = '';

    customMetricsFormulaList.forEach((part) => {
      if (part.startsWith("['") && part.endsWith("']")) {
        const [channel, metric] = part.slice(2, -2).split(':');

        const channelText = getChannelText(channel);
        const metricDisplayName = findDisplayNameByChannelMetricKey(`${channel}:${metric}`);

        customMetricsFormulaOutput += `(${channelText} ${metricDisplayName}) `;
      } else {
        customMetricsFormulaOutput += `${part} `;
      }
    });
    return {
      description: customMetric.description,
      formula: customMetricsFormulaOutput,
    };
  }

  const extractChannelsFromFormula = (formula) => {
    const captureChannelsRegex = /'([A-Z_]+):/g;
    const matches = [...formula.matchAll(captureChannelsRegex)];

    return [
      ...new Set(
        matches
          .map((match) => match[1])
          .filter((channel) => CUSTOM_METRIC_CHANNELS_FOR_CHANNEL_ICONS[channel])
          .map((channel) => snakeCase(CUSTOM_METRIC_CHANNELS_FOR_CHANNEL_ICONS[channel])),
      ),
    ];
  };

  function getFilteredBrandOptions(isCustomMetricsSelected) {
    const { dashboardIdentityBrands } = useDashboardsStore();
    return dashboardIdentityBrands
      .filter((brand) => !isCustomMetricsSelected || brandCanAccessCustomMetrics(brand))
      .map(({ id, name, avatarUrl }) => ({
        value: id,
        text: name,
        imageUrl: avatarUrl,
      }));
  }

  function getCustomReportDetailsFromId(customMetricId) {
    const metricId = parseInt(customMetricId?.split('_')[1], 10);
    const metric = customMetricsStore.customMetricList.data?.find((item) => item.id === metricId);
    if (metric) {
      const metricTooltip = formatCustomMetricTooltip(metric);
      const reportMetricTooltip = formatCustomMetricReportTooltip(metric);

      return {
        name: metric.name,
        tooltip: metricTooltip,
        reportTooltip: reportMetricTooltip,
        channels: extractChannelsFromFormula(metric.formula),
        metricFormat: DATA_TYPE_FORMATS?.[metric.metric_format],
        trendInterpretation: metric.trend_interpretation,
      };
    }

    return null;
  }

  function customMetricNotFound(customMetricId) {
    if (!customMetricId) {
      return false;
    }

    const metricId = parseInt(customMetricId.split('_')[1], 10);
    const metricExists = customMetricsStore.customMetricList.data?.some(
      (item) => item.id === metricId,
    );

    return !metricExists;
  }

  function getCustomMetricOptions(reportType) {
    return customMetricsStore.customMetricList.data
      ?.map((metric) => {
        const iconNames = extractChannelsFromFormula(metric.formula);
        customMetricChannelMap.value[metric.name] = iconNames;

        const customMetric = {
          ...metric,
          text: metric.name,
          iconNames,
          channels: iconNames,
          value: metric.name,
          tooltip: { content: formatCustomMetricTooltip(metric), delay: { show: 300, hide: 0 } },
        };

        if (
          reportType === METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT &&
          customMetric.channels.length > 1
        ) {
          return null;
        }

        return customMetric;
      })
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  function getChannelsFromCustomMetric(selectedMetric) {
    return (customMetricChannelMap.value[selectedMetric] || []).map((channel) =>
      channel.toUpperCase(),
    );
  }

  async function redirectToSettingsCustomMetrics() {
    if (!canViewOrManageCustomMetrics.value) return;

    notificationStore.confirm(
      SEND_TO_SETTINGS.CONFIRM_TEXT,
      SEND_TO_SETTINGS.CONFIRM_TEXT_SECONDARY,
      {
        confirmAlias: 'Continue',
        confirmType: 'primary',
        onConfirm: () => {
          trackingStore.track(DASHBOARD_EVENT_NAMES.MANAGE_TAG_BUTTON_CLICKED, {
            page: SEND_TO_SETTINGS.CUSTOM_METRICS_NAME,
            previousPage: route.name,
          });

          router.push({
            name: SEND_TO_SETTINGS.CUSTOM_METRICS_PAGE,
            params: { brandLabel: authStore.currentBrand?.label },
          });
        },
      },
    );
  }

  function customMetricIdFromName(metricName) {
    const metric = customMetricsStore.customMetricList.data?.find(
      (item) => item.name === metricName,
    );
    return metric ? `CM_${metric.id}` : null;
  }

  function formatCustomMetricValue(value, metricFormat, formatLength = 'NORMAL') {
    const emptyValue = '-';
    if (isNil(value)) return emptyValue;

    const format = metricFormat?.[formatLength];
    if (!format) {
      logger.error(`formatCustomMetricValue: missing format for '${formatLength}'`);
      return isNumber(value) ? value.toString() : value;
    }

    const formattedValue = formatValueByFormatType(value, format, emptyValue);
    return formattedValue;
  }

  function customMetricsAllSelectedBrandsCanAccess(customMetricsList, selectedBrandIds) {
    return customMetricsList.filter((customMetric) => {
      const uniqueCustomMetricBrandIds = new Set(customMetric.brand_ids);
      const hasEverySelectedBrandId = selectedBrandIds.every((selectedBrandId) =>
        uniqueCustomMetricBrandIds.has(selectedBrandId),
      );

      if (!hasEverySelectedBrandId) return false;
      return customMetric;
    });
  }

  return {
    brandCanAccessCustomMetrics,
    canAccessCustomMetrics,
    canManageCustomMetrics,
    canViewOrManageCustomMetrics,
    customMetricIdFromName,
    customMetricsAllSelectedBrandsCanAccess,
    disableBrand,
    disabledBrandTooltip,
    extractChannelsFromFormula,
    extractDisplayNameFromMetric,
    formatCustomMetricTooltip,
    formatCustomMetricReportTooltip,
    formatCustomMetricValue,
    generateCustomMetricTooltip,
    getChannelConnectionData,
    getChannelConnectionStatuses,
    getChannelIcon,
    getChannelsFromCustomMetric,
    getChannelText,
    getCustomMetricOptions,
    getCustomReportDetailsFromId,
    getFilteredBrandOptions,
    noAccessTooltip,
    redirectToSettingsCustomMetrics,
    sanitizeFormulaForChip,
    setCustomMetricBrandChip,
    setCustomMetricsUsageChipColor,
    someUserBrandHasCanManageCustomMetrics,
    updateChannelConnectionStatuses,
    updateCustomMetricBuilder,
    userCanManageCustomMetricsForSomeBrandOrganizationByBrandIds,
    customMetricNotFound,
  };
}
