import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import { env } from '@/env';
import { createLazyInstance } from '@/utils/lazy';

function createRefreshAxiosInstance(options) {
  return createLazyInstance(axios.create, [options]);
}

const refreshTokenAxios = createRefreshAxiosInstance({
  baseURL: env.authApiUrl,
  withCredentials: true,
});

export const useTokenStore = defineStore('token', () => {
  const token = ref(null);

  const hasAttemptedTokenRefresh = ref(false);

  const pending = ref({
    refresh: false,
  });

  const refreshPromise = ref(null);

  function setToken({ token: newToken }) {
    token.value = newToken;
  }

  function clearToken() {
    token.value = null;
  }

  async function refreshToken() {
    pending.value.refresh = true;
    if (!refreshPromise.value) {
      refreshPromise.value = refreshTokenAxios
        .post('/tokens/refresh')
        .then((response) => {
          const { status, data } = response;
          if (status === 201) {
            setToken({ token: data.token });
            return true;
          }
          return false;
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          hasAttemptedTokenRefresh.value = true;
          pending.value.refresh = false;
          refreshPromise.value = null;
        });
    }
    return refreshPromise.value;
  }

  return {
    token,
    hasAttemptedTokenRefresh,
    setToken,
    clearToken,
    refreshToken,
  };
});
