import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.schedulerApiUrl, {
  camelizeKeys: true,
  decamelizeIgnoreKeys: ['pinterestBoard'],
});

export const fetchPosts = (
  { platform, brandId, start, end, sort, status, limit, hasScheduledTime },
  axiosConfig = {},
) =>
  axios.get(`/${platform}/scheduled_posts`, {
    ...axiosConfig,
    params: {
      brandId,
      start,
      end,
      sort,
      status: status?.join?.(','),
      limit,
      hasScheduledTime,
      mediaV2: true,
    },
  });

export const fetchPost = ({ platform, id }, axiosConfig = {}) =>
  axios.get(`/${platform}/scheduled_posts/${id}`, {
    ...axiosConfig,
    params: {
      mediaV2: true,
    },
  });

export const createPost = ({ platform, data }, axiosConfig = {}) =>
  axios.post(`/${platform}/scheduled_posts`, data, {
    ...axiosConfig,
    params: {
      mediaV2: true,
    },
  });

export const updatePost = ({ platform, id, data }, axiosConfig = {}) =>
  axios.patch(`/${platform}/scheduled_posts/${id}`, data, {
    ...axiosConfig,
    params: {
      mediaV2: true,
    },
  });

export const deletePost = ({ platform, id }, axiosConfig = {}) =>
  axios.delete(`/${platform}/scheduled_posts/${id}`, {
    ...axiosConfig,
    params: {
      mediaV2: true,
    },
  });

export const reorderPost = ({ platform, id, brandId, after }, axiosConfig = {}) =>
  axios.post(
    `/${platform}/scheduled_posts/${id}/reorder`,
    { after, brandId },
    {
      ...axiosConfig,
      params: {
        mediaV2: true,
      },
    },
  );

export const fetchEvents = ({ brandId }, axiosConfig = {}) =>
  axios.get('/events', {
    ...axiosConfig,
    params: { brandId },
  });

export const createEvent = (
  { brandId, title, startDate, endDate, description, color },
  axiosConfig = {},
) =>
  axios.post(
    '/events',
    {
      brandId,
      title,
      startDate,
      endDate,
      description,
      color,
    },
    axiosConfig,
  );

export const updateEvent = (
  { id, brandId, title, startDate, endDate, description, color },
  axiosConfig = {},
) =>
  axios.patch(
    `/events/${id}`,
    {
      brandId,
      title,
      startDate,
      endDate,
      description,
      color,
    },
    axiosConfig,
  );

export const deleteEvent = ({ id }, axiosConfig = {}) => axios.delete(`/events/${id}`, axiosConfig);

export const updateApprovalRequest = (
  { postId, platform, approvalRequestId, approvalStatus },
  axiosConfig = {},
) =>
  axios.patch(
    `/${platform}/scheduled_posts/${postId}/approval_requests/${approvalRequestId}`,
    { approval_status: approvalStatus },
    axiosConfig,
  );

export async function getRecommendedPublishTimes(
  { brandId, platform, timezone },
  axiosConfig = {},
) {
  return axios.get(`/recommended_publish_times`, {
    ...axiosConfig,
    params: { brandId, platform_type: platform.toUpperCase(), timezone },
  });
}
