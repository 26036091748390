<template>
  <div class="frame-container">
    <div v-if="title" :class="{ compact }" class="text">
      {{ title }}
      <InfoTooltip
        v-if="showTooltip"
        :tooltip="tooltip"
        :icon-color="iconColor"
        class="suggestion-tooltip"
      />
    </div>
    <div class="slider" data-cy="keyframe-slider">
      <div class="background">
        <VideoPreview
          :media="media"
          :width="keyframeWidth"
          :height="keyframeHeight"
          :duration="videoLength"
        />
      </div>
      <PredictionLineGraph
        v-if="canViewPredictions"
        :predictions="predictions"
        :suggested-thumbnails="suggestedThumbnails"
        :height="keyframeHeight"
        :width="keyframeWidth"
      />
      <div ref="container" class="outer-track" :style="draggingStyle">
        <div
          ref="draggable"
          :style="{
            left: ratioToOffset(sliderLocation.x),
            height: keyframeHeight + 'px',
          }"
          class="inner-track draggable"
          @mousedown.stop="mouseDownHandler"
          @mousemove.stop="mouseMoveHandler"
        >
          <div class="handle handle-top"><div class="slider-caret"></div></div>
          <div class="handle handle-bottom"><div class="slider-caret"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import VideoPreview from '@/components/MediaCropping/VideoPreview/VideoPreview.vue';
import PredictionLineGraph from '@/app/scheduler/components/EditPost/MediaViewer/PredictionLineGraph.vue';
import { colours } from '@/ux/colours';
import { thumbnailSourceType } from '@/app/scheduler/constants';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { useSchedulerStore } from '@/stores/scheduler';
import { mediaUrl } from '@/app/scheduler/utils';
import { env } from '@/env';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'KeyframeSliderPrecise',
  components: { InfoTooltip, PredictionLineGraph, VideoPreview },
  props: {
    compact: { type: Boolean, default: false },
    isProcessing: { type: Boolean, default: null },
    media: { type: Object, default: null },
    predictions: { type: Object, default: null },
    predictionInterval: { type: Number, default: null },
    suggestedThumbnails: { type: Array, default: null },
    title: { type: String, default: 'Select a cover image' },
    // make selected thumbOffset as a v-model
    value: { type: Number, default: 0 },
  },
  emits: ['offset-update'],
  data() {
    return {
      dragging: false,
      keyframeHeight: 56,
      keyframeWidth: null,
      trackerWidth: 2,
      offsetX: null,
      sliderLocation: {
        x: 0,
      },
      videoLength: null,
      iconColor: colours.ICON.ICON_SECONDARY,
      tooltip:
        'The images on the white dots are Vision recommended. Or, follow the white line to see how high Vision is predicting other images to perform as your cover photo.',
      resizeObserver: null,
    };
  },
  computed: {
    ...mapStores(useSchedulerStore, useAuthStore),
    canViewPredictions() {
      return (
        this.authStore.guard(BRAND.VISION.CAN_ACCESS_VISION) &&
        this.authStore.guard(USER.VISION.CAN_ACCESS_VISION)
      );
    },
    draggingStyle() {
      return {
        cursor: this.dragging ? 'grab' : 'auto',
      };
    },
    showTooltip() {
      return this.canViewPredictions && this.predictions?.engagement?.length && !this.isProcessing;
    },
    adaptedVideoLength() {
      const trim = 0.5;
      return this.videoLength > trim ? this.videoLength - trim : this.videoLength;
    },
  },
  watch: {
    value: {
      async handler(newVal) {
        if (newVal === null || newVal === 0) {
          this.sliderLocation.x = 0;
        } else {
          this.setInitialPosition(newVal);
        }
      },
    },
    media: {
      deep: true,
      async handler(newVal, oldVal) {
        // Re-calculate video length on duration change
        if (newVal?.duration !== oldVal?.duration) {
          this.videoLength = await this.getVideoLength();
        }
      },
    },
  },
  async mounted() {
    this.updateKeyframeWidth();
    window.addEventListener('mouseup', this.mouseUpHandler);
    window.addEventListener('mousemove', this.mouseMoveHandler);
    window.addEventListener('resize', this.updateKeyframeWidth);
    this.videoLength = await this.getVideoLength();
    this.setInitialPosition();
    this.resizeObserver = new ResizeObserver(() => this.updateKeyframeWidth());
    if (this.$refs.container) {
      this.resizeObserver.observe(this.$refs.container);
    }
  },
  beforeUnmount() {
    window.removeEventListener('mouseup', this.mouseUpHandler);
    window.removeEventListener('mousemove', this.mouseMoveHandler);
    window.removeEventListener('resize', this.updateKeyframeWidth);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    getVideoLength() {
      return new Promise((resolve) => {
        if (this.media.duration) {
          resolve(this.media.duration);
        }

        // Unfortunately backend APIs don't always give us video duration when newly uploaded, so need to calculate here
        const video = document.createElement('video');
        video.onloadedmetadata = (event) => {
          resolve(event.target.duration);
        };
        video.preload = 'metadata';
        video.crossOrigin = 'anonymous';

        // Prevent Chrome from trying to use cached responses that don't have the appropriate CORS
        // headers if the object is not in s3 or cloudfront cache. See https://serverfault.com/a/856948 for more detail.
        const videoSrc = mediaUrl([this.media]);
        video.src =
          videoSrc.search(env.uploadedMediaStorageUrl) === -1 &&
          videoSrc.search(
            /^https:\/\/dashhudson-(static|dev)\.s3(-accelerate)?\.amazonaws\.com\/media\//,
          ) === -1
            ? videoSrc
            : `${videoSrc}?x-bust-cache=true`;
      });
    },
    getContainerWidth() {
      if (this.$refs.container) {
        return this.$refs.container.clientWidth;
      }
      return 0;
    },
    updateKeyframeWidth() {
      this.keyframeWidth = this.getContainerWidth();
    },
    ratioToOffset(ratio) {
      const offset = Math.round(ratio * (this.getContainerWidth() - this.trackerWidth));
      return `${offset}px`;
    },
    offsetToRatio(offset) {
      return offset / (this.getContainerWidth() - this.trackerWidth);
    },
    move(x) {
      // Update currentTime in seek bar
      const ratio = this.offsetToRatio(x);
      this.sliderLocation.x = ratio;
      this.$emit('offset-update', ratio * this.adaptedVideoLength, thumbnailSourceType.SLIDER);
    },
    xIncrement() {
      return this.getContainerWidth() / (this.predictions.engagement.length - 1);
    },
    highestPredictionXPosition() {
      const highestPrediction = this.suggestedThumbnails.reduce((prev, curr) => {
        return prev?.predictions?.engagement > curr.predictions?.engagement ? prev : curr;
      });
      const offset = highestPrediction.index * this.xIncrement();
      return this.offsetToRatio(offset) * this.adaptedVideoLength;
    },
    setInitialPosition() {
      const isNewPost = window.location.href.includes('/posts/new');
      const defaultOffset = this.suggestedThumbnails
        ? this.highestPredictionXPosition()
        : Math.round((this.adaptedVideoLength / 2) * 10) / 10;
      const timeOffset = this.value || (isNewPost && defaultOffset);
      this.sliderLocation.x = timeOffset / this.adaptedVideoLength;
      this.$emit('offset-update', timeOffset);
    },
    mouseDownHandler(e) {
      this.dragging = true;
      this.offsetX = e.clientX - this.$refs.draggable.getBoundingClientRect().left;
    },
    mouseMoveHandler(e) {
      if (this.dragging) {
        const mouseX = e.clientX;
        const boundingBox = this.$refs.container.getBoundingClientRect();
        let x = mouseX - boundingBox.left - this.offsetX;
        const draggedElPointX = mouseX - this.offsetX;
        if (draggedElPointX < boundingBox.left) x = 0;
        if (draggedElPointX + this.trackerWidth > boundingBox.right) {
          x = boundingBox.width - this.trackerWidth;
        }
        this.move(x);
      }
    },
    mouseUpHandler() {
      this.dragging = false;
    },
  },
});
export default comp;
</script>
<style scoped lang="postcss">
.frame-container {
  width: 100%;
}

.text {
  display: flex;
  padding-bottom: var(--space-6);
  padding-top: var(--space-16);
  font-size: var(--x14);

  .loading {
    width: 20px;
    height: 20px;
    margin-left: var(--space-6);
  }
}

.suggestion-tooltip {
  position: relative;
  right: var(--space-4);
  top: var(--space-4);
}

.slider {
  position: relative;
  margin-top: var(--space-12);
  background-color: var(--background-500);
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--round-corner-small);
  height: 56px;
  display: block;
}

.background {
  border-radius: var(--round-corner-small);
  background-color: var(--background-0);
  opacity: 0.35;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
}

.outer-track {
  box-sizing: border-box;
  border-radius: var(--round-corner-small);
  inset: 0;
  position: absolute;
  display: block;
  width: 100%;
}

.inner-track {
  z-index: var(--z-index-raised);
  cursor: grab;
  box-sizing: content-box;
  display: block;
  position: absolute;
  background-color: var(--action-500);
  width: 2px;

  .handle {
    width: 12px;
    height: 8px;
    position: absolute;
    left: -5px;
    background-color: var(--background-0);
    border-radius: var(--round-corner-extra-small);
    box-shadow: 0 1px 4px 0 rgb(42 42 42 / 30%);
  }

  .handle-top {
    top: -8px;

    .slider-caret {
      width: 0;
      height: 0;
      position: absolute;
      top: 7px;
      margin-left: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 7px solid var(--white);
    }
  }

  .handle-bottom {
    bottom: -8px;

    .slider-caret {
      width: 0;
      height: 0;
      position: absolute;
      bottom: 7px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 7px solid var(--white);
    }
  }
}
</style>
