export const METRIC_TYPES = {
  PLATFORM: 'platformMetrics',
  CUSTOM: 'customMetrics',
};

export const REPORT_TYPE_LABELS = {
  METRIC: 'Metric Report',
  GRAPH: 'Graph Report',
  CONTENT: 'Content Report',
};

export const REPORT_PANEL_OPTIONS = Object.freeze({
  METRIC_TYPE: {
    title: 'Platform Metric or Custom Metric',
    subHeader: 'Select a metric type*',
    tooltip:
      'Platform metrics are standard Dash Social metrics. Custom metrics are metrics created by your team.',
  },
  REPORT_TYPE: {
    title: 'Report Type',
    subHeader: 'Select the type of report*',
    tooltip:
      'Reports showcase your top or bottom posts by a performance metric. Metric Reports track one metric for selected handles and channels. Graph Reports visualize your data with line or bar charts.',
  },
});

export const OWNED_REPORT_ID = 'owned_report';

export const PLATFORM_CUSTOM_METRIC_OPTIONS = [
  {
    text: 'Platform Metrics',
    value: 'platformMetrics',
    icon: 'system',
  },
  {
    text: 'Custom Metrics',
    value: 'customMetrics',
    icon: 'singleUser',
  },
];
