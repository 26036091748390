import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useParagonGmailAPI } from '@/apis/paragon-gmail';
import { useParagonOutlookAPI } from '@/apis/paragon-outlook';
import { AUTHENTICATION_TYPE, useIntegrationStore } from '@/stores/integration';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import {
  decodeBase64ToUTF8,
  decodeHtmlEntities,
  extractGmailMessageParts,
  extractGoogleDriveLinks,
  parseGmailHeaders,
} from '@/utils/gmailApi';
import { EMAIL_RESPONSE_TYPES } from '@/app/creators/constants';
import { useDatadogStore } from '@/stores/datadog';

export const THREAD_PAGINATION_PAGE_SIZE = 15;
export const OUTLOOK_API_BASE_URL = 'https://graph.microsoft.com/v1.0/';
export const PROVIDERS = ['outlook', 'gmail'];

const INLINE_IMAGE_PATTERN = /<img\b[^>]*\bsrc=["']cid:([^"']+)["'][^>]*>/gi;
const GMAIL_UNREAD = 'UNREAD';

/**
 * Extracts One Drive links and their corresponding filenames from the raw HTML content of a message.
 *
 * @param {string} content The raw HTML content from the Outlook API message.
 * @returns {Array} An array of objects, each containing the link and filename.
 */
function extractOneDriveLinks(content) {
  if (!content) return [];

  const linkAndNameRegex =
    /<a[^>]*href="(https:\/\/1drv\.ms\/[^\s"]+)"[^>]*>(?:<[^>]+>)*([^<]+)(?:<\/[^>]+>)*<\/a>/g;

  const links = [];
  let match = linkAndNameRegex.exec(content);

  while (match !== null) {
    const filename = match[2].trim();

    links.push({
      link: match[1],
      filename,
    });
    match = linkAndNameRegex.exec(content);
  }

  return links;
}

/**
 * Parse a list of names and email addresses in the format: Jane Doe <jane@email.com>
 * and return a list of objects.
 * @param {string} sendersOrRecipientsString A string containing a list of names and addresses
 * @returns {object[]} A list of objects containing `name` and `address`.
 */
function parseSenderOrRecipientList(sendersOrRecipientsString) {
  return sendersOrRecipientsString.split(',').map((senderOrRecipient) => {
    const match = senderOrRecipient.match(/([^<]+)\s<([^>]+)>/);
    if (match) {
      return { name: match[1].trim(), address: match[2].trim() };
    }
    return { name: null, address: senderOrRecipient.trim() };
  });
}

/**
 * Check if the creator is a participant in message.
 * @param {string} creatorEmail The email address of the creator.
 * @returns {function(Object): boolean} Returns true if the creatorEmail is found in message
 */
const isCreatorInMessage = (creatorEmail) => (message) => {
  return [...message.to, ...message.cc, ...message.bcc, ...message.from].some(
    ({ address }) => address === creatorEmail,
  );
};

/**
 * Find and return all content IDs associated with inline images in the HTML
 * body of an email message.
 * @param {string} messageBody The HTML body of a message.
 * @returns {string[]} List of all referenced content IDs.
 */
const extractContentIdsFromMessageBody = (messageBody) =>
  Array.from(messageBody.matchAll(INLINE_IMAGE_PATTERN), (match) => match[1]);

/**
 * Normalizes the response from the Gmail API into an object of relevant information required to
 * render messages in our platform.
 * @param {object} message The message object returned from the Gmail API.
 * @returns {object} Returns an object containing normalized thread information
 */
function normalizeGmailMessage(message) {
  const headers = parseGmailHeaders(message.payload.headers);
  const parts = extractGmailMessageParts(message.payload);

  const subject = headers.subject;
  const date = new Date(headers.date);
  const from = parseSenderOrRecipientList(headers.from);
  const to = headers.to ? parseSenderOrRecipientList(headers.to) : [];
  const cc = headers.cc ? parseSenderOrRecipientList(headers.cc) : [];
  const bcc = headers.bcc ? parseSenderOrRecipientList(headers.bcc) : [];
  const partsWithData = parts.filter((part) => part.body?.data);
  const textBodyPart = partsWithData.find((part) => part.mimeType === 'text/plain');
  const htmlBodyPart = partsWithData.find((part) => part.mimeType === 'text/html');
  const htmlBody = htmlBodyPart && decodeBase64ToUTF8(htmlBodyPart.body.data);
  const textBody =
    textBodyPart && decodeBase64ToUTF8(textBodyPart.body.data).replaceAll(/\n/g, '<br/>');
  const body = htmlBody ?? textBody;
  const googleDriveAttachments = extractGoogleDriveLinks(body);
  const oneDriveAttachments = extractOneDriveLinks(body);
  const contentIds = new Set(extractContentIdsFromMessageBody(body ?? ''));

  const attachments = Object.values(
    Object.fromEntries(
      parts
        .filter((part) => part.body.attachmentId)
        .map((part) => [
          part.filename,
          {
            filename: part.filename,
            id: part.body.attachmentId,
            contentId: part.headers && parseGmailHeaders(part.headers)['x-attachment-id'],
            mimeType: part.mimeType,
          },
        ]),
    ),
  ).map((attachment) => ({
    ...attachment,
    isReferencedInBody: contentIds.has(attachment.contentId) || contentIds.has(attachment.filename),
  }));

  return {
    attachments: [...attachments, ...googleDriveAttachments, ...oneDriveAttachments],
    date,
    id: message.id,
    rfcMessageId: headers['message-id'], // Used for replying to a specific message
    from,
    to,
    cc,
    bcc,
    subject,
    unread: message.labelIds.includes(GMAIL_UNREAD),
    body,
    snippet: decodeHtmlEntities(message.snippet),
  };
}

/**
 * Normalizes the response from the Outlook API into an object of relevant information required to
 * render messages in our platform.
 * @param {object} message The message object returned from the Outlook API.
 * @returns {object} Returns an object containing normalized thread information
 */
export function normalizeOutlookMessage(message) {
  const contentIds = new Set(extractContentIdsFromMessageBody(message.body.content ?? ''));

  const oneDriveAttachments = extractOneDriveLinks(message.body.content);
  const googleDriveAttachments = extractGoogleDriveLinks(message.body.content);
  const attachments = message.attachments
    .map(({ id, name, contentType, contentId }) => ({
      id,
      contentId,
      filename: name,
      mimeType: contentType,
    }))
    .map((attachment) => ({
      ...attachment,
      isReferencedInBody:
        contentIds.has(attachment.contentId) || contentIds.has(attachment.filename),
    }));

  return {
    threadId: message.conversationId,
    unread: !message.isRead,
    from: [message.sender.emailAddress],
    snippet: message.bodyPreview,
    date: new Date(message.sentDateTime),
    id: message.id,
    rfcMessageId: message.id,
    to: message.toRecipients.map(({ emailAddress }) => emailAddress),
    cc: message.ccRecipients.map(({ emailAddress }) => emailAddress),
    bcc: message.bccRecipients.map(({ emailAddress }) => emailAddress),
    subject: message.subject,
    body: message.body.content,
    attachments: [...attachments, ...oneDriveAttachments, ...googleDriveAttachments],
  };
}

export const useEmailStore = defineStore('email', () => {
  // Stores
  const integrationStore = useIntegrationStore();
  const datadogStore = useDatadogStore();

  // Composables
  const gmailAPI = useParagonGmailAPI();
  const outlookAPI = useParagonOutlookAPI();

  // Refs
  const threadIds = ref([]);
  const messagesByThreadId = ref({});
  const attachmentsById = ref({});
  const isFullThreadLoadedByThreadId = ref({});
  const unreadThreadCount = ref(0);
  const userEmail = ref('');
  const emailDraft = ref({});

  const pendingCounts = ref({
    threadsList: 0,
    sendMessage: 0,
    messagesByThreadId: {},
    attachmentsById: {},
    updateThread: 0,
    sendReply: 0,
    settingEmail: 0,
  });

  const errors = ref({
    threadsList: null,
    sendMessage: null,
    messagesByThreadId: null,
    attachmentsById: {},
    updateThread: null,
    userEmail: null,
    sendReply: null,
  });

  const paging = ref({
    threadsList: null,
  });

  function clearEmailDraft() {
    emailDraft.value = {};
  }

  // Computed
  const integrationInfo = computed(() => {
    return PROVIDERS.map((provider) => {
      const integration = integrationStore.paragonIntegrations[provider];

      return {
        provider,
        credentialId: integration?.credentialId,
        credentialStatus: integration?.credentialStatus,
        enabled: integration?.enabled,
      };
    });
  });

  const pending = computed(() =>
    Object.fromEntries(
      Object.entries(pendingCounts.value).map(([k, v]) => {
        if (typeof v === 'object') {
          return [k, Object.fromEntries(Object.entries(v).map(([id, count]) => [id, !!count]))];
        }
        return [k, !!v];
      }),
    ),
  );
  const canLoadMore = computed(() =>
    Object.fromEntries(Object.entries(paging.value).map(([k, v]) => [k, !!v])),
  );
  const threads = computed(() =>
    threadIds.value
      .map((id) => {
        const messages = messagesByThreadId.value[id];
        const firstMessage = messages[0];
        const lastMessage = messages[messages.length - 1];

        return {
          id,
          attachments: lastMessage.attachments,
          date: lastMessage.date,
          from: lastMessage.from,
          to: lastMessage.to,
          subject: firstMessage.subject,
          unread: lastMessage.unread,
          messageCount: messages.length,
          snippet: lastMessage.snippet,
        };
      })
      .toSorted((a, b) => b.date - a.date),
  );

  async function logActionError(action, callback) {
    const startTime = new Date().getTime() / 1000;
    try {
      await callback();
    } catch (error) {
      const endTime = new Date().getTime() / 1000;
      const context = {
        action,
        integrationInfo: integrationInfo.value,
        requestPath: error?.request?.path,
        response: error?.response,
        duration: endTime - startTime,
      };

      datadogStore.addError(error, context);

      throw error;
    }
  }

  // Functions
  async function performProviderSpecificAction(actionsByProvider) {
    await integrationStore.authenticate(AUTHENTICATION_TYPE.user);
    const { isConnected } = integrationStore;

    const supportedProviders = Object.keys(actionsByProvider);

    const invalidProviders = difference(supportedProviders, PROVIDERS);
    const missingProviders = difference(PROVIDERS, supportedProviders);
    if (missingProviders.length > 0) {
      throw new Error(
        `Support is missing for the following email providers: ${missingProviders.join(', ')}`,
      );
    }
    if (invalidProviders.length > 0) {
      throw new Error(
        `The following email providers supplied for this action are not valid: ${invalidProviders.join(', ')}`,
      );
    }

    const connectedProvider = PROVIDERS.find(isConnected);

    if (connectedProvider) {
      return actionsByProvider[connectedProvider]();
    }

    throw new Error('No email providers are currently connected!');
  }

  async function setUserEmail() {
    pendingCounts.value.settingEmail += 1;
    await performProviderSpecificAction({
      gmail() {
        userEmail.value = integrationStore.paragonIntegrations?.gmail?.providerId;
      },
      async outlook() {
        try {
          const response = await outlookAPI.getUser();
          userEmail.value = response?.mail;
          errors.value.userEmail = null;
        } catch (e) {
          errors.value.userEmail = e;
          throw e;
        }
      },
    });
    pendingCounts.value.settingEmail -= 1;
  }

  async function fetchMessagesByThreadId(threadId) {
    await logActionError('fetchMessageByThreadId', async () => {
      if (isFullThreadLoadedByThreadId.value[threadId]) {
        return;
      }

      pendingCounts.value.messagesByThreadId[threadId] =
        (pendingCounts.value.messagesByThreadId[threadId] ?? 0) + 1;

      try {
        await performProviderSpecificAction({
          async gmail() {
            const response = await gmailAPI.getThread({ threadId });

            messagesByThreadId.value = {
              ...messagesByThreadId.value,
              [threadId]: response.messages.map(normalizeGmailMessage),
            };
            isFullThreadLoadedByThreadId.value[threadId] = true;
          },
          async outlook() {
            const response = await outlookAPI.getMessages({
              conversationId: threadId,
            });

            messagesByThreadId.value = {
              ...messagesByThreadId.value,
              [threadId]: response.value.map(normalizeOutlookMessage),
            };
            isFullThreadLoadedByThreadId.value[threadId] = true;
          },
        });

        errors.value.messagesByThreadId = null;
      } catch (e) {
        errors.value.messagesByThreadId = e;
        throw e;
      } finally {
        pendingCounts.value.messagesByThreadId[threadId] -= 1;
      }
    });
  }

  async function updateStoreWithRawOutlookMessagesResponse(response, creatorEmail) {
    const normalizedMessages = response.value
      .filter((message) => !message.isDraft)
      .map(normalizeOutlookMessage)
      .filter(isCreatorInMessage(creatorEmail))
      .toSorted((a, b) => a.date - b.date);
    const allUniqueThreadIds = uniq(normalizedMessages.map(({ threadId }) => threadId));
    const groupedMessagesForUnloadedThreads = groupBy(
      normalizedMessages.filter(({ threadId }) => !isFullThreadLoadedByThreadId.value[threadId]),
      'threadId',
    );
    messagesByThreadId.value = {
      ...messagesByThreadId.value,
      ...groupedMessagesForUnloadedThreads,
    };
    threadIds.value = uniq([...threadIds.value, ...allUniqueThreadIds]);
    allUniqueThreadIds.forEach(fetchMessagesByThreadId);

    return response['@odata.nextLink']?.replace(OUTLOOK_API_BASE_URL, '');
  }

  async function fetchThreadsList({ creatorEmail }) {
    await logActionError('fetchThreadsList', async () => {
      pendingCounts.value.threadsList += 1;
      threadIds.value = [];
      isFullThreadLoadedByThreadId.value = {};
      paging.value.threadsList = null;

      try {
        await performProviderSpecificAction({
          async gmail() {
            const params = {
              query: `from:${creatorEmail} OR to:${creatorEmail}`,
              limit: THREAD_PAGINATION_PAGE_SIZE,
            };
            const response = await gmailAPI.getThreadsList(params);

            const responseThreadIds = (response.threads ?? []).map(({ id }) => id);
            const threadIdsWithExistingMessages = responseThreadIds.filter(
              (id) => messagesByThreadId.value[id],
            );
            const threadIdsWithoutExistingMessages = responseThreadIds.filter(
              (id) => !messagesByThreadId.value[id],
            );

            // We only need to wait for threads without existing messages loaded.
            // If we have already loaded messages previously, we can refresh the
            // data asynchronously to cut down on loading times.
            Promise.all(threadIdsWithExistingMessages.map(fetchMessagesByThreadId));
            await Promise.all(threadIdsWithoutExistingMessages.map(fetchMessagesByThreadId));

            threadIds.value = responseThreadIds;

            paging.value.threadsList = response.nextPageToken
              ? {
                  nextPage: response.nextPageToken,
                  lastRequestBody: params,
                }
              : null;
          },
          async outlook() {
            const params = {
              query: creatorEmail,
              limit: THREAD_PAGINATION_PAGE_SIZE,
            };
            const response = await outlookAPI.getMessages(params);
            const nextLink = await updateStoreWithRawOutlookMessagesResponse(
              response,
              creatorEmail,
            );
            paging.value.threadsList = nextLink
              ? {
                  lastRequestBody: params,
                  nextLink,
                }
              : null;
          },
        });

        errors.value.threadsList = null;
      } catch (e) {
        errors.value.threadsList = e;
        throw e;
      } finally {
        pendingCounts.value.threadsList -= 1;
      }
    });
  }

  async function fetchThreadsListNextPage() {
    await logActionError('fetchThreadsListNextPage', async () => {
      if (!canLoadMore.value.threadsList || pendingCounts.value.threadsList) return;

      pendingCounts.value.threadsList += 1;
      try {
        await performProviderSpecificAction({
          async gmail() {
            const response = await gmailAPI.getThreadsList({
              ...paging.value.threadsList.lastRequestBody,
              pageToken: paging.value.threadsList?.nextPage,
            });

            const responseThreadIds = (response.threads ?? []).map(({ id }) => id);
            await Promise.all(responseThreadIds.map(fetchMessagesByThreadId));

            threadIds.value = [...threadIds.value, ...responseThreadIds];
            paging.value.threadsList = response.nextPageToken
              ? {
                  ...paging.value.threadsList,
                  nextPage: response.nextPageToken,
                }
              : null;
          },
          async outlook() {
            const response = await integrationStore.request(
              'outlook',
              paging.value.threadsList.nextLink,
              {
                method: 'GET',
              },
            );

            const creatorEmail = paging.value.threadsList.lastRequestBody.query;
            const nextLink = await updateStoreWithRawOutlookMessagesResponse(
              response,
              creatorEmail,
            );
            paging.value.threadsList = nextLink
              ? {
                  ...paging.value.threadsList,
                  nextLink,
                }
              : null;
          },
        });

        errors.value.threadsList = null;
      } catch (e) {
        errors.value.threadsList = e;
        throw e;
      } finally {
        pendingCounts.value.threadsList -= 1;
      }
    });
  }

  async function markThreadAsRead(threadId) {
    await logActionError('markThreadAsRead', async () => {
      pendingCounts.value.updateThread += 1;
      try {
        await performProviderSpecificAction({
          async gmail() {
            await gmailAPI.modifyThread({
              threadId,
              removeLabelIds: ['UNREAD'],
            });
            messagesByThreadId.value[threadId]?.forEach((message) => {
              message.unread = false;
            });
          },
          async outlook() {
            const allMessages = messagesByThreadId.value[threadId];

            const markAsReadPromises = allMessages.map(async (message) => {
              await outlookAPI.markMessageAsRead({ messageId: message.id });
              message.unread = false;
            });

            await Promise.all(markAsReadPromises);
          },
        });
        errors.value.updateThread = null;
      } catch (e) {
        errors.value.updateThread = e;
        throw e;
      } finally {
        pendingCounts.value.updateThread -= 1;
      }
    });
  }

  async function sendMessage({
    forwardMessageId,
    forwardComment,
    to,
    cc,
    bcc,
    subject,
    body,
    attachments,
    messageType,
  }) {
    await logActionError('sendMessage', async () => {
      pendingCounts.value.sendMessage += 1;
      try {
        const attachmentsWithData = attachments?.map((attachment) => {
          const attachmentData = attachmentsById.value[attachment.id];
          if (!attachmentData) {
            throw new Error('One of more attachments failed to load.');
          }
          return {
            ...attachment,
            content: attachmentData.data,
          };
        });
        await performProviderSpecificAction({
          async gmail() {
            const response = await gmailAPI.sendMessage({
              from: userEmail.value,
              to,
              cc,
              bcc,
              subject,
              body,
              attachments: attachmentsWithData,
            });
            const threadId = response.threadId;

            if (messageType !== EMAIL_RESPONSE_TYPES.forward) {
              await fetchMessagesByThreadId(threadId);
              threadIds.value = [threadId, ...threadIds.value];
            }
            errors.value.sendMessage = null;
          },
          async outlook() {
            if (messageType === EMAIL_RESPONSE_TYPES.forward) {
              if (!forwardMessageId) {
                throw new Error(
                  'Cannot forward an Outlook message without the ID of the original message!',
                );
              }
              if (cc?.length || bcc?.length) {
                throw new Error('Outlook does not support including CC or BCC when forwarding!');
              }
              await outlookAPI.forwardMessage({
                messageId: forwardMessageId,
                comment: forwardComment,
                to,
              });
            } else {
              const response = await outlookAPI.createDraft({
                subject,
                body,
                to,
                cc,
                bcc,
                attachments: attachmentsWithData,
              });

              await outlookAPI.sendMessage({ messageId: response.id });
              await fetchMessagesByThreadId(response.conversationId);
              threadIds.value = [response.conversationId, ...threadIds.value];
            }
          },
        });

        clearEmailDraft();
        errors.value.sendMessage = null;
      } catch (e) {
        errors.value.sendMessage = e;
        throw e;
      } finally {
        pendingCounts.value.sendMessage -= 1;
      }
    });
  }

  async function sendReply({ to, cc, bcc, subject, body, replyMessageId, threadId }) {
    await logActionError('sendReply', async () => {
      pendingCounts.value.sendReply += 1;
      try {
        await performProviderSpecificAction({
          async gmail() {
            const response = await gmailAPI.sendMessage({
              from: userEmail.value,
              to,
              cc,
              bcc,
              subject,
              body,
              rfcMessageId: replyMessageId,
              threadId,
            });

            const messageId = response.id;
            const messageReponse = await gmailAPI.getMessage({ messageId });
            messagesByThreadId.value[threadId].push(normalizeGmailMessage(messageReponse));
          },
          async outlook() {
            await outlookAPI.sendReply({
              id: replyMessageId,
              body,
              to,
              cc,
              bcc,
            });

            // Wait for one second prior to fetching messages to avoid ErrorItemNotFound from Outlook
            await new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });

            // Fetch the message
            const updatedThread = await outlookAPI.getMessages({ conversationId: threadId });
            const newMessage = updatedThread.value[updatedThread.value.length - 1];
            messagesByThreadId.value[threadId].push(normalizeOutlookMessage(newMessage));
          },
        });

        clearEmailDraft();
        errors.value.sendReply = null;
      } catch (e) {
        errors.value.sendReply = e;
        throw e;
      } finally {
        pendingCounts.value.sendReply -= 1;
      }
    });
  }

  async function fetchAttachment({ messageId, attachmentId }) {
    await logActionError('fetchAttachment', async () => {
      if (!pendingCounts.value.attachmentsById[attachmentId])
        pendingCounts.value.attachmentsById[attachmentId] = 0;

      if (pendingCounts.value.attachmentsById[attachmentId] || attachmentsById.value[attachmentId])
        return;

      pendingCounts.value.attachmentsById[attachmentId] += 1;

      try {
        await performProviderSpecificAction({
          async gmail() {
            const attachment = await gmailAPI.getAttachment({
              messageId,
              attachmentId,
            });

            attachmentsById.value = {
              ...attachmentsById.value,
              [attachmentId]: { data: attachment.data },
            };
          },
          async outlook() {
            const attachment = await outlookAPI.getAttachment({
              messageId,
              attachmentId,
            });

            attachmentsById.value = {
              ...attachmentsById.value,
              [attachmentId]: { data: attachment.contentBytes },
            };
          },
        });

        errors.value.attachmentsById[attachmentId] = null;
      } catch (e) {
        errors.value.attachmentsById[attachmentId] = e;
        throw e;
      } finally {
        pendingCounts.value.attachmentsById[attachmentId] -= 1;
      }
    });
  }

  async function fetchUnreadThreadCount({ creatorEmail }) {
    await logActionError('fetchUnreadThreadCount', async () => {
      unreadThreadCount.value = 0;

      await performProviderSpecificAction({
        async gmail() {
          const limit = 1; // The lowest number the API will allow.
          const labelIds = ['UNREAD'];
          const response = await gmailAPI.getThreadsList({
            query: `from:${creatorEmail} OR to:${creatorEmail}`,
            labelIds,
            limit,
          });
          unreadThreadCount.value = response.resultSizeEstimate;
        },
        async outlook() {
          const response = await outlookAPI.getUnreadMessageCount({ creatorEmail });
          const conversationIds = new Set(response.value.map((message) => message.conversationId));
          unreadThreadCount.value = conversationIds.size;
        },
      });
    });
  }

  function clearAttachments() {
    attachmentsById.value = {};
  }

  function clearUserEmail() {
    userEmail.value = '';
    errors.value.userEmail = null;
  }

  function clearUnreadThreadCount() {
    unreadThreadCount.value = 0;
  }

  watch(
    () => integrationStore.paragonIntegrations,
    async (to) => {
      if (to) {
        if (
          Object.entries(to).some(
            ([providerName, provider]) => PROVIDERS.includes(providerName) && provider.enabled,
          )
        ) {
          setUserEmail();
        } else {
          clearUserEmail();
        }
      }
    },
    { immediate: true },
  );

  return {
    // States
    errors,
    pending,
    threads,
    attachmentsById,
    messagesByThreadId,
    userEmail,
    unreadThreadCount,
    canLoadMore,
    emailDraft,

    // Functions
    setUserEmail,
    clearUserEmail,
    fetchThreadsList,
    sendMessage,
    sendReply,
    fetchThreadsListNextPage,
    fetchMessagesByThreadId,
    markThreadAsRead,
    fetchUnreadThreadCount,
    fetchAttachment,
    clearAttachments,
    clearEmailDraft,
    clearUnreadThreadCount,
    logActionError,
  };
});
