<template>
  <SubScreen :title="title" @on-back="handleBack">
    <template #content>
      <section>
        <div
          v-if="
            hasCollaboratorFeatureFlag &&
            !isCarousel &&
            tagType === userTagType.MEDIA_TAG &&
            isActiveMediaVideo
          "
          class="add-user"
        >
          <PeopleTagInput :media-type="mediaType" @tag-added="saveReelUserTag" />
        </div>
        <div
          v-else-if="
            tagType === userTagType.MEDIA_TAG &&
            !instagramUserTaggerStore.activeMediaTaggedUsers.length
          "
          class="prompt"
        >
          <p>{{ prompt }}</p>
        </div>

        <div
          v-else-if="tagType === userTagType.MEDIA_TAG && instagramUserTaggerStore.editingUserTag"
          class="add-user"
        >
          <PeopleTagInput :media-type="mediaType" @tag-added="saveUserTag" />
        </div>

        <div v-else-if="tagType === userTagType.COLLABORATOR">
          <Banner class="mb-4 mt-6">{{ collaboratorBannerText }}</Banner>
          <p class="label mb-2">
            Collaborators
            <InfoTooltip :tooltip="toolTips.instagramInviteCollaborators" />
          </p>
          <PeopleTagInput
            placeholder-text="Type Instagram Handle (3 max)"
            :media-type="mediaType"
            @tag-added="saveCollaboratorTag"
          />
        </div>

        <div v-if="showTagList" class="users-list">
          <p v-if="tagType === userTagType.MEDIA_TAG">
            {{ prompt }}
          </p>
          <p v-else-if="showCollaborators" class="invited-list-title">
            {{ numberOfCollaborators }}
          </p>
          <ul>
            <li v-for="(userTag, index) in tagList" :key="index">
              <div class="user-label">
                <Icon class="user-icon" color="#666666" name="user" xsmall />
                <span>{{ userTag.username }}</span>
              </div>
              <div>
                <Icon
                  :hover-color="removeIconHoverColor"
                  class="remove-button"
                  name="close"
                  xsmall
                  @click="deleteUser(index)"
                />
              </div>
            </li>
          </ul>
        </div>
        <Banner v-if="showWarningBox" alert-type="warning" class="mt-2">
          {{ userTagsWarning }}
        </Banner>
      </section>
    </template>
  </SubScreen>
</template>

<script>
import { defineComponent } from 'vue';

import { mapStores } from 'pinia';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import {
  INSTAGRAM_COLLABORATOR_INFORMATION_BANNER_TEXT,
  tagPeoplePromptMessage,
  tooManyUserTagsMessage,
  USER_TAG_TYPE,
  tagPeopleVideoErrorMessage,
  maxUserTags,
} from '@/app/scheduler/constants';
import { useFlagStore } from '@/stores/flag';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import PeopleTagInput from '@/app/scheduler/components/EditPost/PeopleTagInput.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { toolTips } from '@/config';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'InstagramTagPeople',
  components: {
    InfoTooltip,
    PeopleTagInput,
    Icon,
    SubScreen,
    Banner,
  },
  props: {
    onBack: { type: Function, required: true },
    onTagsChanged: { type: Function, default: () => {} },
    tagType: { type: String, default: USER_TAG_TYPE.MEDIA_TAG },
    isCarousel: { type: Boolean, default: false },
  },
  data() {
    return {
      removeIconColor: colours.BACKGROUND.BACKGROUND_400,
      removeIconHoverColor: colours.ACTION.ACTION_500,
      value: null,
      user: '',
      toolTips,
    };
  },
  computed: {
    ...mapStores(useSchedulerStore, useInstagramUserTaggerStore, useFlagStore),
    numberOfCollaborators() {
      return `Invited (${this.instagramUserTaggerStore.collaborators.length}/3)`;
    },
    showCollaborators() {
      return (
        this.tagType === USER_TAG_TYPE.COLLABORATOR &&
        this.instagramUserTaggerStore.collaborators.length > 0
      );
    },
    showMediaTags() {
      const isEditingReelUserTags =
        this.isActiveMediaVideo && this.instagramUserTaggerStore.editingUserTag;
      return (
        this.tagType === USER_TAG_TYPE.MEDIA_TAG &&
        (!this.instagramUserTaggerStore.editingUserTag || isEditingReelUserTags) &&
        this.instagramUserTaggerStore.activeMediaTaggedUsers.length > 0
      );
    },
    showTagList() {
      return this.showCollaborators || this.showMediaTags;
    },
    tagList() {
      return this.tagType === USER_TAG_TYPE.COLLABORATOR
        ? this.instagramUserTaggerStore.collaborators
        : this.instagramUserTaggerStore.activeMediaTaggedUsers;
    },
    showWarningBox() {
      const { totalUserTags, editingUserTag } = this.instagramUserTaggerStore;
      const maxUserTagsReached = totalUserTags >= maxUserTags;

      if (this.mediaType === 'VIDEO' && !this.isCarousel) {
        return maxUserTagsReached;
      }
      if (this.mediaType === 'IMAGE') {
        if (editingUserTag) {
          return totalUserTags > maxUserTags;
        }
        if (!editingUserTag) {
          return maxUserTagsReached;
        }
      }
      return false;
    },
    userTagsWarning() {
      return tooManyUserTagsMessage;
    },
    title() {
      if (this.tagType === USER_TAG_TYPE.COLLABORATOR) {
        return 'Invite Collaborators';
      }
      return this.instagramUserTaggerStore.editingUserTag ? 'Add Tag' : 'Add People Tags';
    },
    prompt() {
      if (this.hasCollaboratorFeatureFlag && !this.isCarousel && this.isActiveMediaVideo) {
        return null;
      }
      if (this.isCarousel && this.isActiveMediaVideo) {
        return tagPeopleVideoErrorMessage;
      }
      return tagPeoplePromptMessage;
    },
    hasCollaboratorFeatureFlag() {
      return this.flagStore.ready && this.flagStore.flags.instagramCollabPosts;
    },
    isActiveMediaVideo() {
      return this.mediaType === 'VIDEO';
    },
    mediaType() {
      return this.schedulerStore.carouselActiveMedia?.type;
    },
    userTagType() {
      return USER_TAG_TYPE;
    },
    collaboratorBannerText() {
      return INSTAGRAM_COLLABORATOR_INFORMATION_BANNER_TEXT;
    },
  },
  watch: {
    'instagramUserTaggerStore.activeMediaTaggedUsers': {
      handler(newVal) {
        newVal.forEach((val) => {
          if (val.username === undefined) {
            const index = newVal.indexOf(val);
            this.instagramUserTaggerStore.deleteUserTag({ index });
          }
        });
      },
    },
  },
  beforeMount() {
    if (this.tagType === USER_TAG_TYPE.MEDIA_TAG) {
      this.instagramUserTaggerStore.enable();
    } else {
      this.instagramUserTaggerStore.disable();
    }

    this.instagramUserTaggerStore.enableTagCounter();
  },
  unmounted() {
    this.instagramUserTaggerStore.disable();
    this.instagramUserTaggerStore.disableTagCounter();
  },
  methods: {
    saveUserTag(userTag) {
      this.instagramUserTaggerStore.updateUserTag({
        index: this.instagramUserTaggerStore.activeMediaTaggedUsers.length - 1,
        tag: { username: userTag },
      });
      this.instagramUserTaggerStore.setEditingUserTag({ value: false });
    },
    saveReelUserTag(userTag) {
      this.instagramUserTaggerStore.addUserTag({
        tag: {
          username: userTag,
        },
      });
    },
    saveCollaboratorTag(collaborator) {
      this.instagramUserTaggerStore.addCollaborator({
        username: collaborator,
      });
    },
    deleteUser(index) {
      if (this.tagType === USER_TAG_TYPE.COLLABORATOR) {
        this.instagramUserTaggerStore.deleteCollaborator(index);
      } else {
        this.instagramUserTaggerStore.deleteUserTag({ index });
      }
    },
    handleBack() {
      if (this.instagramUserTaggerStore.editingUserTag && !this.isActiveMediaVideo) {
        this.instagramUserTaggerStore.deleteUserTag({
          index: this.instagramUserTaggerStore.activeMediaTaggedUsers.length - 1,
        });
        this.user = '';
      } else {
        this.onTagsChanged(this.instagramUserTaggerStore.taggedUsers);
        this.onBack();
      }
      this.instagramUserTaggerStore.setEditingUserTag({ value: false });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.prompt {
  margin-top: var(--space-88);
  font-size: var(--x18);
  color: var(--text-secondary);
}

.invited-list-title {
  font-size: var(--x14);
  margin-bottom: 0;
}

.add-user {
  margin-top: var(--space-32);
}

.user-label {
  display: flex;
  align-items: center;
  font-weight: var(--font-medium);
}

.user-icon {
  margin-right: var(--space-8);
}

.users-list {
  p {
    text-align: left;
    margin: var(--space-24) 0 var(--space-8);
    color: var(--text-secondary);
  }

  li {
    padding: var(--space-8) 0;
    text-align: left;
    display: flex;
    justify-content: space-between;

    .remove-button {
      border: none;
      cursor: pointer;
    }
  }
}
</style>
