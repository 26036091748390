import { ALL_CHANNELS_KEY } from '@/models/dashboards/channels.enum';

export function getReportRequestParams(
  reportDateRangeInUserTimezone,
  contextDateRangeInUserTimezone,
  graphScale,
  contentTagIds,
  brandIds,
  brandTagIds,
  competitorSourceAccountIds,
  competitorTagIds,
) {
  const params = {
    include_data: true,
    start_date: reportDateRangeInUserTimezone?.[0],
    end_date: reportDateRangeInUserTimezone?.[1],
    context_start_date: contextDateRangeInUserTimezone?.[0],
    context_end_date: contextDateRangeInUserTimezone?.[1],
    time_scale: graphScale,
  };

  const overrideContentTags = contentTagIds && contentTagIds.length > 0;
  const overrideBrandIds = brandIds && brandIds.length > 0;
  const overrideBrandTagIds = brandTagIds && brandTagIds.length > 0;
  const overrideCompetitorIds = competitorSourceAccountIds && competitorSourceAccountIds.length > 0;
  const overrideCompetitorTagIds = competitorTagIds && competitorTagIds.length > 0;

  if (overrideContentTags) params.override_content_tag_ids = contentTagIds.join(',');
  if (overrideBrandIds) params.override_brand_ids = brandIds.join(',');
  if (overrideBrandTagIds) params.override_brand_tag_ids = brandTagIds.join(',');
  if (overrideCompetitorIds)
    params.override_competitor_source_account_ids = competitorSourceAccountIds.join(',');
  if (overrideCompetitorTagIds) params.override_competitor_tag_ids = competitorTagIds.join(',');

  return params;
}

export function getBrandIdToReportAdCampaign(reportAdCampaigns) {
  return reportAdCampaigns.reduce((mapping, brandReport) => {
    mapping[brandReport.id] = brandReport;
    return mapping;
  }, {});
}

export function getFormattedMetrics(metricsObject, reportMetric, channel) {
  const isCustomMetric = reportMetric.includes('CM_');
  const channelKey = isCustomMetric ? ALL_CHANNELS_KEY : channel;
  const metricValue =
    metricsObject?.[reportMetric]?.[channelKey] ?? metricsObject?.[reportMetric] ?? {};

  const formattedMetrics =
    Object.keys(metricsObject ?? {}).map((metric) => {
      const metricValues = metricsObject?.[metric]?.[channel] || metricsObject?.[metric] || {};
      return { metric, ...metricValues };
    }) || [];

  return {
    formattedMetrics,
    metric: metricValue,
  };
}

export function parseQueryToArray(query) {
  return query?.split(',').map((q) => q.trim());
}
