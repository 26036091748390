import toPairs from 'lodash/toPairs';
import dayjs from 'dayjs';
import {
  COMMUNITY_INTERACTION_TYPES,
  COMMUNITY_ROUTE_NAMES,
  COMMUNITY_ROUTE_PRESET_FILTERS,
  DEFAULT_COMMUNITY_INTERACTION_SORT,
  INBOX_SORT_BY,
} from '@/app/community/constants';
import { applyReversibleUpdate } from '@/utils/optimism';
import { useTrackingStore } from '@/stores/tracking';
import { useIdentityStore } from '@/stores/identity';

export const parseBoolean = (s) => {
  if (typeof s === 'boolean') {
    return s;
  }
  return ['true', 'false'].includes(s) ? s === 'true' : undefined;
};

export const getCommunityInteractionFilters = (query, ignoreAssigneeId = true) => {
  const identityStore = useIdentityStore();
  const { isArchived, isFollowUp, responseRecommended } = query;
  const assignment = ignoreAssigneeId ? {} : { assigneeId: identityStore?.identity?.id };
  return {
    isArchived: parseBoolean(isArchived),
    ...(responseRecommended !== true ? {} : { responseRecommended }),
    ...(isFollowUp === undefined ? {} : { isFollowUp: parseBoolean(isFollowUp) }),
    ...assignment,
  };
};

export const getCommunityInteractionSort = (query) =>
  query.sort || DEFAULT_COMMUNITY_INTERACTION_SORT;

export const getNumericIdsFromQuery = (query, key) => {
  const idQuery = query?.[key] ?? [];
  const ids = Array.isArray(idQuery) ? idQuery : idQuery.split(',');
  return ids.map((id) => Number(id));
};

export const sortCommunityInteractions = (communityInteractions, query) => {
  const sort = getCommunityInteractionSort(query);

  if (sort === INBOX_SORT_BY.RELEVANCE) {
    return communityInteractions;
  }

  let direction;

  if (sort === INBOX_SORT_BY.SRC_UPDATED_AT_DESC) {
    direction = -1;
  }

  if (sort === INBOX_SORT_BY.SRC_UPDATED_AT_ASC) {
    direction = 1;
  }

  return communityInteractions.sort((prev, next) => {
    const p1 = prev.sourceUpdatedAt ?? prev.createdAt;
    const p2 = next.sourceUpdatedAt ?? next.createdAt;
    if (direction === 1) {
      return p1 < p2 ? -1 : 1;
    }
    return p1 < p2 ? 1 : -1;
  });
};

export const filterCommunityInteractions = (
  communityInteractions,
  currentQuery,
  ignoreIsArchived = false,
) => {
  const trackingStore = useTrackingStore();
  const query = {
    ...currentQuery,
    ...COMMUNITY_ROUTE_PRESET_FILTERS[trackingStore.currentRoute?.name],
  };
  const tagIds = getNumericIdsFromQuery(query, 'tagIds');
  // Ignore ADS_COMMENT type and use normal COMMENT type
  // since the ad comment is not its own interaction type, it's just a workaround to specify ad comment filter
  // *_ADS_COMMENT type is converted to *_COMMENT type + is_ad_comment = true in the backend
  const interactionTypes =
    query.types?.replace(/_ADS/g, '') ?? Object.values(COMMUNITY_INTERACTION_TYPES);
  const brandIds = getNumericIdsFromQuery(query, 'brandIds');
  const filters = getCommunityInteractionFilters(
    query,
    trackingStore.currentRoute?.name !== COMMUNITY_ROUTE_NAMES.ASSIGNED_TO_YOU,
  );
  // Apply filters
  // didn't figure out the cause but communityInteractions can have {0:undefined} that causes issue
  // later on, so added an undefined filter guard in the end.
  let filtered = communityInteractions
    .filter((interaction) =>
      toPairs(filters).every(([key, value]) => {
        // temp work around for isArchived out of sync in DB and ES. Expose issue to UI to
        // let users have a chance to fix them instead of infinite loading
        if ((ignoreIsArchived || value === undefined) && key === 'isArchived') return true;
        return interaction[key] === value;
      }),
    )
    .filter((interaction) => interaction !== undefined);
  // Filter by tags
  if (tagIds.length > 0) {
    filtered = filtered.filter((interaction) =>
      tagIds.every((tagId) => interaction.tagIds.includes(tagId)),
    );
  }
  // Filter by interaction type
  filtered = filtered.filter((interaction) => interactionTypes.includes(interaction.type));

  // Filter by date filters
  const { startDate, endDate } = query;
  if (startDate && endDate) {
    filtered = filtered.filter((interaction) => {
      let dateTime = interaction.sourceUpdatedAt ?? interaction.createdAt;
      // Below if is mainly a check for avoiding doing a large amount of test migrations
      if (!dayjs.isDayjs(dateTime)) {
        dateTime = dayjs(dateTime);
      }
      return dateTime.isBetween(
        dayjs(startDate).startOf('date'),
        dayjs(endDate).endOf('date'),
        'day',
        '[]',
      );
    });
  }
  // Filter by brands
  if (brandIds.length > 0) {
    filtered = filtered.filter((interaction) => brandIds.includes(interaction.brandId));
  }

  return filtered;
};

export const applyReversibleCommunityInteractionUpdate = (
  communityInteractions,
  id,
  { tagIds, ...flagUpdates },
) => {
  const communityInteraction = communityInteractions.find((ci) => ci.id === id);
  if (communityInteraction) {
    const addTagIds = tagIds?.add ?? [];
    const removeTagIds = tagIds?.remove ?? [];
    applyReversibleUpdate(communityInteractions, id, {
      ...flagUpdates,
      tagIds: communityInteraction.tagIds
        .filter((tid) => !removeTagIds.includes(tid) && !addTagIds.includes(tid))
        .concat(addTagIds),
    });
  }
};
