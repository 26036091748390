import {
  emptyStateDetails,
  filterDetails,
  PAGE_SIZE,
  SEARCH_DEBOUNCE_TIME,
  sortDetails,
} from '@/app/creators/constants';
import { getHasMentionedBrand, getSortString } from '@/app/creators/utils';
import { useCreatorsStore } from '@/stores/creators';
import { useCampaignsStore } from '@/stores/campaigns';
import { useIdentityStore } from '@/stores/identity';
import { useTrackingStore } from '@/stores/tracking';
import { useDataView, useFilterSidePanel } from '@dashhudson/dashing-ui';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useFlagStore } from '@/stores/flag';
import { useInstagramRelationshipsStore } from '@/stores/instagram-relationships';

export function useCreators(currentScope) {
  // Sort and Filter Maps
  const yourCreatorsFilterDetails = filterDetails.YOUR_CREATORS;
  const creatorsWizardFilterDetails = filterDetails.CREATORS_WIZARD;
  const yourCreatorsSortDetails = sortDetails.YOUR_CREATORS;

  // Flags Store
  const flagStore = useFlagStore();
  const { ready: flagsReady, flags } = storeToRefs(flagStore);

  // Identity Store
  const identityStore = useIdentityStore();
  const { currentBrand } = storeToRefs(identityStore);

  // Creator Store
  const creatorsStore = useCreatorsStore();
  const { creators, creatorsPaging, pending } = storeToRefs(creatorsStore);
  const { fetchBrandRelationshipTags, fetchYourCreators, fetchYourCreatorsNextPage } =
    creatorsStore;

  // Campaigns store
  const campaignsStore = useCampaignsStore();
  const { campaigns } = storeToRefs(campaignsStore);
  const { getCampaigns, updateCampaign } = campaignsStore;

  // Tracking store
  const trackingStore = useTrackingStore();

  // Instagram Relationships Store
  const instagramRelationshipsStore = useInstagramRelationshipsStore();

  // Search State
  const lookupName = ref('');
  const debounceLookupName = debounce((value) => {
    lookupName.value = value;
  }, SEARCH_DEBOUNCE_TIME);

  // Bulk Actions State
  const {
    selection: selectedCreators,
    selecting: selectModeActive,
    dataViewProps,
  } = useDataView({
    value: creators,
    allItemsLoaded: computed(() => !creatorsPaging.value.nextCursor),
  });
  const onClearSelection = () => {
    selectedCreators.value = [];
    selectModeActive.value = false;
  };

  // Filter State
  const { filters, changedFilterCount } = useFilterSidePanel();
  const creatorFilters = computed(() => filters[yourCreatorsFilterDetails.scope]);
  const creatorsWizardFilters = computed(() => filters[creatorsWizardFilterDetails.scope]);
  const filtersForCurrentScope = computed(() => filters[currentScope]);

  // Sort State
  const sort = ref(yourCreatorsSortDetails.default);
  const sortOptions = computed(() => {
    const { restrictToPlatforms, instagramPostType } = filtersForCurrentScope.value || {};
    const { options: sortDetailOptions } = yourCreatorsSortDetails;

    let options = sortDetailOptions.MULTIPLE_CHANNELS;

    if (isEqual(restrictToPlatforms, ['TIKTOK'])) {
      options = sortDetailOptions.TIKTOK;
    } else if (isEqual(restrictToPlatforms, ['INSTAGRAM'])) {
      if (isEqual(instagramPostType, ['STORY'])) {
        options = sortDetailOptions.INSTAGRAM_STORIES_ONLY;
      } else if (isEqual(instagramPostType, ['IMAGE'])) {
        options = sortDetailOptions.INSTAGRAM_FEED_ONLY;
      } else {
        options = sortDetailOptions.INSTAGRAM;
      }
    }
    return options.filter(
      (option) =>
        !option.flag ||
        (flagsReady.value && flags.value?.[option.flag] !== (option.hideOnFlag ?? false)),
    );
  });

  // Search Parameters
  const searchParams = computed(() => {
    return {
      limit: PAGE_SIZE,
      sort: getSortString(sort.value),
      lookupName: lookupName.value,
      restrictToPlatforms:
        filtersForCurrentScope.value?.restrictToPlatforms?.length > 0
          ? filtersForCurrentScope.value?.restrictToPlatforms
          : undefined,
      postsPublishedStartDate:
        filtersForCurrentScope.value?.datePublished?.range?.start ?? undefined,
      postsPublishedEndDate: filtersForCurrentScope.value?.datePublished?.range?.end ?? undefined,
      tagFilterType:
        filtersForCurrentScope.value?.tags?.length > 0
          ? filtersForCurrentScope.value?.tagFilterType
          : undefined,
      tags: filtersForCurrentScope.value?.tags,
      hasMentionedBrand: getHasMentionedBrand(filtersForCurrentScope.value?.hasMentionedBrand),
      instagramPostType: filtersForCurrentScope.value?.instagramPostType,
      instagramConnectionStatus: filtersForCurrentScope.value?.instagramConnectionStatus?.flat(),
      instagramFollowersGreaterThan:
        Number(filtersForCurrentScope.value?.instagramFollowersGreaterThan) || undefined,
      instagramFollowersLessThan:
        Number(filtersForCurrentScope.value?.instagramFollowersLessThan) || undefined,
      instagramInterests: filtersForCurrentScope.value?.instagramInterests,
      instagramRegions: filtersForCurrentScope.value?.instagramRegions,
      instagramLocations: filtersForCurrentScope.value?.instagramLocations,
      instagramIsBusiness:
        filtersForCurrentScope.value?.instagramIsBusiness?.length !== 1
          ? undefined
          : filtersForCurrentScope.value.instagramIsBusiness[0],
      tiktokConnectionStatus: filtersForCurrentScope.value?.tiktokConnectionStatus?.flat(),
      tiktokFollowersGreaterThan:
        Number(filtersForCurrentScope.value?.tiktokFollowersGreaterThan) || undefined,
      tiktokFollowersLessThan:
        Number(filtersForCurrentScope.value?.tiktokFollowersLessThan) || undefined,
      tiktokInterests: filtersForCurrentScope.value?.tiktokInterests,
      tiktokRegions: filtersForCurrentScope.value?.tiktokRegions,
      tiktokTTCMStatus: filtersForCurrentScope.value?.tiktokTTCMStatus,
      tiktokAudienceRegions: filtersForCurrentScope.value?.tiktokAudienceRegions,
      tiktokAudienceGender:
        filtersForCurrentScope.value?.tiktokAudienceGender &&
        filtersForCurrentScope.value?.tiktokAudienceGender !== 'ALL_GENDERS'
          ? filtersForCurrentScope.value.tiktokAudienceGender
          : undefined,
      tiktokAudienceAge:
        filtersForCurrentScope.value?.tiktokAudienceAge &&
        filtersForCurrentScope.value?.tiktokAudienceAge !== 'ALL_AGES'
          ? filtersForCurrentScope.value.tiktokAudienceAge
          : undefined,
    };
  });

  async function saveInfluencerInstagramDataAndFormatConnectionLink(creator) {
    await instagramRelationshipsStore.findRelationshipByHandle({
      brandId: currentBrand.value.id,
      handle: creator.handle,
    });
    // We have to invoke this call in order to get connectInstagramInfluencerUrl back
    const influencerRes = await instagramRelationshipsStore.createOrUpdateInfluencer({
      brandId: currentBrand.value.id,
      influencerInstagramId: creator.sourceCreatorId,
    });
    let connectionLink = influencerRes.data.connectInstagramInfluencerUrl;
    // TODO: https://app.shortcut.com/dashhudson/story/139058/jan-15-update-ig-creator-path
    // Remove after release and switching to new path in auth
    connectionLink = connectionLink.replace(
      'influencer-connect-facebook',
      'brand-connect-instagram-creator',
    );
    const { id, name, avatarUrl } = currentBrand.value;
    const url = new URL(connectionLink);
    url.searchParams.set('invited_by', id);
    url.searchParams.set('invited_by_name', name);
    url.searchParams.set('invited_by_brand_avatar_url', avatarUrl);
    url.searchParams.set('avatar_url', creator.avatar);
    url.searchParams.set('brand_name', creator.handle);
    return url.toString();
  }

  async function bulkAddCampaignCreators(campaignIds, creatorIds) {
    let toastMessage;

    try {
      await getCampaigns({ ids: campaignIds });
      let existingCreatorIds = [];

      await Promise.all(
        campaignIds.map(async (campaignIDToUpdate) => {
          const existingCampaign = campaigns.value.find(
            (campaign) => campaign.id === campaignIDToUpdate,
          );
          existingCreatorIds = existingCampaign.creator_ids || [];

          await updateCampaign({
            originalCampaign: existingCampaign,
            editedCampaign: {
              ...existingCampaign,
              campaignId: campaignIDToUpdate,
              creatorIds: [...creatorIds, ...existingCreatorIds],
            },
          });
        }),
      );
      creatorIds.forEach((creatorId) => {
        campaignIds.forEach((campaignId) => {
          if (!existingCreatorIds.includes(creatorId)) {
            trackingStore.track('Creator Added to Campaign', {
              creatorId,
              campaignId,
            });
          }
        });
      });
      toastMessage = {
        severity: 'success',
        message: 'Creators added to the selected campaigns',
      };
    } catch (e) {
      toastMessage = {
        severity: 'error',
        message: `An error was encountered adding creators to the selected campaigns. Please try again.`,
      };
    }
    return toastMessage;
  }

  // Empty State
  const emptyState = computed(() => {
    if (pending.value.creators || creators.value.length > 0) return null;
    if (searchParams.value.lookupName.length > 0) return emptyStateDetails.NO_SEARCH;
    if (changedFilterCount.value > 0) return emptyStateDetails.NO_FILTER;
    return emptyStateDetails.NO_CREATORS;
  });
  const emptyFilterResults = computed(
    () => emptyState.value?.value === emptyStateDetails.NO_FILTER.value,
  );
  const disableToolbar = computed(() => {
    return pending.value.creators || (!!emptyState.value && !emptyFilterResults.value);
  });

  const hasNoCreators = computed(() => {
    return (
      !pending.value.creators &&
      !creators.value.length &&
      !searchParams.value.lookupName.length &&
      !changedFilterCount.value
    );
  });

  // Watchers
  watch(
    () => searchParams.value,
    async (to, from) => {
      if (to && !isEqual(to, from)) {
        window.scrollTo({ top: 0 });
        await fetchYourCreators(searchParams.value);
      }
    },
  );

  watch(
    () => sortOptions.value,
    () => {
      if (!sortOptions.value?.map((option) => option.value).includes(sort.value.selected.value)) {
        sort.value = yourCreatorsSortDetails.default;
      }
    },
  );

  watch(
    () => currentBrand.value.id,
    () => {
      window.scrollTo({ top: 0 });
      fetchBrandRelationshipTags();
      fetchYourCreators(searchParams.value);
    },
  );

  return {
    selectedCreators,
    selectModeActive,
    fetchYourCreators,
    searchParams,
    lookupName,
    debounceLookupName,
    dataViewProps,
    onClearSelection,
    creators,
    disableToolbar,
    sort,
    sortOptions,
    yourCreatorsSortDetails,
    emptyState,
    pending,
    fetchYourCreatorsNextPage,
    creatorsPaging,
    fetchBrandRelationshipTags,
    creatorFilters,
    creatorsWizardFilters,
    saveInfluencerInstagramDataAndFormatConnectionLink,
    bulkAddCampaignCreators,
    hasNoCreators,
  };
}
