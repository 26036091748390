import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

// Bluesky account info lives in auth_db.bluesky_account table for now
// until the dedicated backend is created
export const axios = createAxiosInstance(env.authApiUrl);

export async function getBlueskyAccount({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/bluesky/handle`, axiosConfig);
}

export async function getBlueskyAccountAllBrands({ brandIds }, axiosConfig = {}) {
  return axios.put(`/brands/bluesky/handle`, { brand_ids: brandIds }, axiosConfig);
}
