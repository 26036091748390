import { computed } from 'vue';
import { useDashboardsStore } from '@/stores/dashboards';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { TEMPLATES } from '@/app/dashboards/utils/templates.enum';
import { BRAND } from '@/models/auth/permissions.enum';
import { REPORT_CATEGORY_OPTIONS } from '@/app/dashboards/utils/reports.enum';
import { useContentTagsStore } from '@/stores/content-tags';
import { useSearchStore } from '@/stores/search';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';
import { getAllAvailableCompetitorIdsByChannel } from '@/app/dashboards/utils/competitive.utils';
import { CHANNELS } from '@/models/dashboards/channels.enum';

export function useDashboards() {
  const dashboardsStore = useDashboardsStore();
  const dashboardReportsStore = useDashboardReportsStore();
  const searchStore = useSearchStore();
  const contentTagsStore = useContentTagsStore();
  const flagStore = useFlagStore();
  const identityStore = useIdentityStore();
  function canUserAccessDashboards(brand) {
    const brandLabel = brand.label;
    return identityStore.identity?.permissions?.dashboard?.[brandLabel]?.can_access_dashboards;
  }

  const availableOwnedDashboards = computed(() => {
    return dashboardsStore.ownedDashboards.map((dashboard) => {
      return { value: dashboard.id, text: dashboard.name };
    });
  });

  const availableSharedDashboards = computed(() => {
    return dashboardsStore.sharedDashboards?.map((dashboard) => {
      return { value: dashboard.id, text: dashboard.name };
    });
  });

  const canAccessGDIDashboards = computed(() => {
    return flagStore?.ready && flagStore?.flags?.gdiDashboards;
  });

  const canAccessDashboards = computed(() => {
    return identityStore.allUserBrands.some(
      (brand) =>
        identityStore.guard(BRAND.DASHBOARD.CAN_ACCESS_DASHBOARDS, brand) ||
        canUserAccessDashboards(brand) ||
        false,
    );
  });

  const allTemplates = computed(() => {
    return Object.keys(TEMPLATES).map((key) => TEMPLATES[key]);
  });

  const allReports = computed(() => {
    return Object.values(REPORT_CATEGORY_OPTIONS);
  });

  function canAccessBrandOrUserPermissions(item) {
    return (
      identityStore.checkIfBrandPermissionsExist(item.brandPermissions) ||
      identityStore.checkIfUserPermissionsExist(item.userPermissions)
    );
  }

  function canAccessTemplateFlags(template) {
    if (template?.requiredFlag) {
      return flagStore.flags[template.requiredFlag];
    }
    if (template?.hiddenRequiredFlag) {
      return !flagStore.flags[template.hiddenRequiredFlag];
    }
    return true;
  }

  function checkOptionFlags(option) {
    if (!option.requiredFlag && !option.requireSomeFlag) {
      return true;
    }
    const requireEveryFlag = Array.isArray(option.requiredFlag)
      ? option.requiredFlag
      : [option.requiredFlag];
    const requireSomeFlag = Array.isArray(option.requireSomeFlag)
      ? option.requireSomeFlag
      : [option.requireSomeFlag];
    const everyFlag =
      !option.requiredFlag || requireEveryFlag.every((flag) => this.flagStore.flags[flag]);
    const someFlag =
      !option.requireSomeFlag || requireSomeFlag.some((flag) => this.flagStore.flags[flag]);
    return everyFlag && someFlag;
  }

  function getDashboardChannelIconName(channel) {
    const channelMap = {
      INSTAGRAM: 'INSTAGRAM',
      FACEBOOK: 'FACEBOOK',
      YOUTUBE: 'YOUTUBE',
      LINKEDIN: 'LINKEDIN',
      TIKTOK: 'TIKTOK',
      TWITTER: 'TWITTER',
      PINTEREST: 'PINTEREST',
      META: 'META',
    };

    return Object.keys(channelMap).find((prefix) => channel.startsWith(prefix)) ?? channel;
  }

  function extractUniqueChannelIconNames(channels) {
    return [...new Set(channels?.map((channel) => getDashboardChannelIconName(channel)))];
  }

  function extractDashboardOwnerName(dashboard) {
    return dashboardsStore.currentDashboardUser.id === dashboard.user_id
      ? null
      : dashboard.owner_name;
  }

  const filteredTemplates = computed(() => {
    return allTemplates.value
      .filter((template) => {
        return (
          (canAccessBrandOrUserPermissions(template) || template?.showUpgrade) &&
          canAccessTemplateFlags(template)
        );
      })
      .map((template) => ({
        ...template,
        locked: !canAccessBrandOrUserPermissions(template) && template?.showUpgrade,
      }));
  });

  const filteredReportOptions = computed(() => {
    return allReports.value
      .filter((report) => checkOptionFlags(report))
      .map((report) => ({
        ...report,
        locked: !canAccessBrandOrUserPermissions(report),
      }));
  });

  const showContentTagsFilter = computed(() => {
    return canAccessGDIDashboards.value && contentTagsStore.allOrganizationsContentTags;
  });

  const showBrandFilter = computed(() => {
    return canAccessGDIDashboards.value && dashboardsStore.dashboardIdentityBrands.length > 1;
  });

  const contentTagOptions = computed(() => {
    return contentTagsStore.allOrganizationsContentTags.map((contentTag) => {
      return {
        value: contentTag.id,
        label: contentTag.name,
      };
    });
  });

  const competitorOptions = computed(() => {
    const supportedChannels = [
      CHANNELS.INSTAGRAM_COMPETITIVE.value,
      CHANNELS.FACEBOOK_COMPETITIVE.value,
    ];
    const channelsInDashboard = dashboardsStore.currentDashboard?.channels ?? [];
    const allAvailableCompetitorIdsByChannel =
      getAllAvailableCompetitorIdsByChannel(supportedChannels);

    const optionsPerChannel = supportedChannels.reduce((acc, channel) => {
      if (!channelsInDashboard.includes(channel)) return acc;
      const availableCompetitorIdsForChannel = allAvailableCompetitorIdsByChannel[channel];
      const sourceChannel = CHANNELS[channel].competitiveChannelType;
      const channelCompetitors = searchStore.competitors?.[sourceChannel] ?? [];
      const allCompetitorOptions = channelCompetitors
        .filter((competitor) =>
          availableCompetitorIdsForChannel.includes(competitor.sourceAccountId),
        )
        .map((competitor) => {
          return {
            value: `${competitor.source}_${competitor.sourceAccountId}`,
            label: competitor.handle,
            avatar: competitor.avatarUrl,
            channel: competitor.source,
          };
        });
      acc.push(allCompetitorOptions);
      return acc;
    }, []);

    return optionsPerChannel
      .reduce((acc, channelOptions) => {
        return acc.concat(channelOptions);
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  });

  const showCompetitorFilter = computed(() => {
    return canAccessGDIDashboards.value && competitorOptions.value.length > 1;
  });

  const competitorTagOptions = computed(() => {
    return dashboardReportsStore.competitorTags
      .reduce((acc, competitorTags) => acc.concat(competitorTags.tags), [])
      .map((tag) => {
        return { value: tag.id, label: tag.name };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  });

  const showCompetitorTagFilter = computed(() => {
    return competitorTagOptions.value.length > 0;
  });

  return {
    availableOwnedDashboards,
    availableSharedDashboards,
    canAccessGDIDashboards,
    filteredTemplates,
    canAccessDashboards,
    filteredReportOptions,
    showContentTagsFilter,
    showBrandFilter,
    showCompetitorFilter,
    showCompetitorTagFilter,
    contentTagOptions,
    competitorOptions,
    competitorTagOptions,
    extractDashboardOwnerName,
    extractUniqueChannelIconNames,
  };
}
