<template>
  <section class="all-insights-container">
    <HighlightedInsights v-if="showHighlightedInsights" :insights="highlightedInsights" />
    <section class="main-insights-container">
      <InsightsBase :header-title="insightsHeader" header-icon="instagram-reels">
        <template #title>
          <Icon
            v-if="postIsPromoted"
            v-tooltip="boostedTooltip"
            :color="colours.BRAND.BRAND_ACCENT"
            name="boosted"
          />
        </template>
        <CircularLoader v-if="!insights" />
        <template v-else>
          <div v-if="!isEmpty(callToActionBanner)" class="pt-4">
            <Banner v-bind="callToActionBanner" small>
              {{ callToActionBanner.message }}
            </Banner>
          </div>
          <div v-if="disabledLikeCount" class="hidden-likes">
            Like count is hidden on this post.
            <br />
            Impacted metrics will not be shown.
          </div>
          <span v-for="insightProp in insightsProps" :key="insightProp.label">
            <InsightsItem
              v-if="!insightProp.hidden"
              :label="insightProp.label"
              :label-tooltip="insightProp.labelTooltip"
              :value="formatValue(insightProp.value, insightProp.key)"
              :value-tooltip="insightProp.valueTooltip"
            />
            <template v-for="childInsightProp in insightProp.children">
              <InsightsItem
                v-if="!childInsightProp.hidden"
                :key="childInsightProp.label"
                indented
                :label="childInsightProp.label"
                :label-tooltip="childInsightProp.labelTooltip"
                :value="childInsightProp.value"
                :ad-account-scoped-insight="
                  useAdAccountScopedInsightsStyling(insightProp.key, childInsightProp.label)
                "
              />
            </template>
          </span>
        </template>
        <template #custom-metrics-insights>
          <CustomMetricsInsights v-if="showCustomMetricsInsights" :media-item="mediaItem" />
        </template>
      </InsightsBase>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import differenceInHours from 'date-fns/differenceInHours';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import { useAuthStore } from '@/stores/auth';
import { updatePost } from '@/apis/instagram';
import CustomMetricsInsights from '@/app/library/components/MediaPopup/CustomMetricsInsights.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import enumTypes from '@/app/library/constants';
import instagramConstants, {
  rateFieldFormat,
  accountingField,
  durationFields,
  metricsDiscontinuationDate,
  metaPromotedLevelReelFieldsDeprecated,
  metaPromotedLevelReelFields,
  metaPromotedReelFieldsToVerboseDeprecated,
  metaPromotedReelFieldsToVerbose,
  organicInsightsLabelsVerboseDeprecated,
  organicInsightsLabelsVerbose,
  discontinuedFields,
  newMetricFields,
  newMetricsStartDate,
  connectedUgcInsightsLabelsVerbose,
} from '@/app/instagram/constants';
import Icon from '@/components/foundation/Icon.vue';
import InsightsBase from '@/components/insights/InsightsBase.vue';
import InsightsItem from '@/components/insights/InsightsItem.vue';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import HighlightedInsights from '@/app/library/components/MediaPopup/HighlightedInsights.vue';
import { METRIC_FORMATS } from '@/models/dashboards/metrics.constants';
import { validateRealUser } from '@/utils/user';
import { formatDynamicDuration, formatValueByFormatType, localize } from '@/utils/formatters';
import {
  formatMetaPromoted,
  getAdAccountScopedNestings,
  getFormattedNestings,
  sortMetaPromotedFields,
} from '@/app/instagram/utils';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { usePlatformStore } from '@/stores/platform';
import { useFlagStore } from '@/stores/flag';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import snakeCase from 'lodash/snakeCase';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ReelsInsights',
  components: {
    HighlightedInsights,
    CircularLoader,
    Icon,
    CustomMetricsInsights,
    InsightsBase,
    InsightsItem,
    Banner,
  },
  props: {
    mediaItem: { type: Object, default: null },
  },
  setup() {
    const { canAccessCustomMetrics } = useCustomMetrics();
    return {
      canAccessCustomMetrics,
    };
  },
  data() {
    return {
      insights: null,
      boostedTooltip: toolTips.instagramInsights.boosted,
    };
  },
  computed: {
    ...mapStores(usePlatformStore, useFlagStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    isMetaAdsConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    hasConnectedMetaAdsPreviously() {
      return !this.platformStore.isPlatformNeverConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    hasInstagramMetricUpdates() {
      return this.flagStore.ready && this.flagStore.flags.instagramMetricUpdates;
    },
    hasBoostedPostUpdatesEnabled() {
      return this.flagStore.ready && this.flagStore.flags.boostedPostUpdates;
    },
    hideDiscontinuedMetrics() {
      return dayjs(this.mediaItem.datePosted).isAfter(dayjs(metricsDiscontinuationDate), 'day');
    },
    showNewMetrics() {
      return (
        this.showBusinessInsights &&
        this.hasInstagramMetricUpdates &&
        dayjs(this.mediaItem.datePosted).isAfter(dayjs(newMetricsStartDate), 'day')
      );
    },
    metaPromotedLevelReelFields() {
      const fields = this.hideDiscontinuedMetrics
        ? cloneDeep(metaPromotedLevelReelFields)
        : cloneDeep(metaPromotedLevelReelFieldsDeprecated);
      if (this.showNewMetrics) {
        const noSubMetrics = null;
        newMetricFields.forEach((field) => {
          fields[field] = noSubMetrics;
        });
      }
      return fields;
    },
    metaPromotedReelFieldsToVerbose() {
      return this.hasInstagramMetricUpdates
        ? metaPromotedReelFieldsToVerbose
        : metaPromotedReelFieldsToVerboseDeprecated;
    },
    tooltips() {
      return this.hasInstagramMetricUpdates
        ? toolTips.instagramReelsInsights
        : toolTips.instagramReelsInsightsDeprecated;
    },
    metaPromotedTooltips() {
      const tooltipsCopy = cloneDeep(
        this.hasInstagramMetricUpdates
          ? toolTips.metaInstagramReelMediaPopup
          : toolTips.metaInstagramReelMediaPopupDeprecated,
      );

      if (this.hasBoostedPostUpdatesEnabled) {
        Object.entries(toolTips.instagramAdAccountScopedInsightsSuffixes).forEach(
          ([key, suffix]) => {
            tooltipsCopy[key] = [tooltipsCopy[key], suffix].join('.\n\n');
          },
        );
      }

      return tooltipsCopy;
    },
    callToActionBanner() {
      if (!this.hasConnectedMetaAdsPreviously) {
        return {
          customIcon: 'boosted',
          message: 'Inform your social strategy with promoted post insights!',
          actionText: 'Connect Meta Ads Account',
          alertType: 'white',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      if (!this.isMetaAdsConnected) {
        return {
          customIcon: 'alertCircle',
          message: 'Meta Ads not connected, data may not be current',
          actionText: 'Reconnect in Settings',
          alertType: 'warning',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      return {};
    },
    metaAdsAccounts() {
      if (!this.hasConnectedMetaAdsPreviously) return undefined;
      return keyBy(this.platformStore.getAccount(PLATFORM_CONNECTION.FACEBOOK_ADS), 'account_id');
    },
    currency() {
      return Object.values(this.metaAdsAccounts ?? {})?.[0]?.currency;
    },
    metaAdsAccountScopedNestingsByMetric() {
      const adAccountScopedInsights = this.mediaItem?.boostedData?.adAccountScopedInsights ?? [];

      return {
        reachTotal: getAdAccountScopedNestings(
          adAccountScopedInsights,
          this.metaAdsAccounts,
          'reach',
          {
            organic: this.insights?.reach,
          },
        ),
        frequencyPaid: getAdAccountScopedNestings(
          adAccountScopedInsights,
          this.metaAdsAccounts,
          'frequency',
        ),
      };
    },
    organicNewInsightProps() {
      const {
        replays,
        total_plays: totalPlays,
        avg_time_viewed_sec: avgTimeViewedSec,
        total_time_viewed_sec: totalTimeViewedSec,
      } = this.insights;
      return [
        {
          label: 'Replays',
          labelTooltip: this.tooltips.replays,
          value: replays,
          children: [],
          hidden: !this.showBusinessInsights,
          key: 'replays',
        },
        {
          label: 'All Plays',
          labelTooltip: this.tooltips.totalPlays,
          value: totalPlays,
          children: [],
          hidden: !this.showBusinessInsights,
          key: 'totalPlays',
        },
        {
          label: 'Average Watch Time',
          labelTooltip: this.tooltips.avgTimeViewedSec,
          value: formatDynamicDuration(avgTimeViewedSec),
          children: [],
          hidden: !this.showBusinessInsights,
          key: 'avgTimeViewedSec',
        },
        {
          label: 'Total Time Viewed',
          labelTooltip: this.tooltips.totalTimeViewedSec,
          value: formatDynamicDuration(totalTimeViewedSec),
          children: [],
          hidden: !this.showBusinessInsights,
          key: 'totalTimeViewedSec',
        },
      ];
    },
    relationshipInsightProps() {
      const { relationship_post: relationshipPost } = this.insights;
      return [
        {
          label: 'Followers Gained',
          labelTooltip: this.tooltips.followersGained,
          value: relationshipPost?.followers_gained ?? '-',
          children: [],
          key: 'followersGained',
        },
        {
          label: 'EMV',
          labelTooltip: this.tooltips.emv,
          value:
            this.insights?.emv != null && !this.disabledLikeCount
              ? this.formatEmvRate(this.insights.emv)
              : '-',
          children: [],
          key: 'emv',
        },
      ];
    },
    colours() {
      return colours;
    },
    highlightedInsights() {
      const value = this.insights?.entertainment_score;
      const formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      let formattedValue;
      if (value == null) {
        formattedValue = '-';
      } else {
        formattedValue = value.toLocaleString('en', formatOptions);
      }
      return [
        {
          name: 'Entertainment Score',
          value: formattedValue,
          iconName: 'leisurePartyPopper',
          tooltip: toolTips.instagramInsights.entertainmentScore,
        },
      ];
    },
    postIsPromoted() {
      return !isEmpty(this.mediaItem.boostedData);
    },
    postIsRIQ() {
      return (this.insights.is_mentioned || this.insights.is_tagged) && !this.postIsOwned;
    },
    showCustomMetricsInsights() {
      return this.canAccessCustomMetrics && this.postIsOwned && this.insights;
    },
    postIsOwned() {
      return this.mediaItem.sourceType.startsWith(enumTypes.INSTAGRAM_OWNED);
    },
    showBusinessInsights() {
      return this.insights?.show_business_insights;
    },
    userDataUnavailable() {
      return !validateRealUser(this.mediaItem.userName);
    },
    insightsHeader() {
      return this.postIsOwned ? 'Reels Insights' : 'Organic Reels Insights';
    },
    insightsProps() {
      const props = this.postIsPromoted ? this.metaPromotedProps : this.organicInsightsProps;
      // make sure that items are always in alphabetical order by label
      props.sort((aa, bb) => aa?.label?.localeCompare(bb?.label));
      return props;
    },
    disabledLikeCount() {
      const likes = this.insights?.like_count;
      const moreThanOneHourAgo =
        differenceInHours(new Date(), new Date(this.mediaItem.datePosted)) > 1;
      return !this.showBusinessInsights && likes === 0 && moreThanOneHourAgo;
    },
    organicInsightsProps() {
      const {
        comments_count: comments,
        effectiveness,
        engagement: engagementRate,
        engagement_rate_impressions: engagementRateImpressions,
        impressions,
        like_count: likes,
        reach,
        saved,
        shares,
        video_views: videoViews,
      } = this.insights;
      const engagementsChildren = [
        {
          label: 'Likes',
          labelTooltip: this.postIsOwned ? null : this.tooltips.likes,
          value: !this.disabledLikeCount ? likes : null,
          key: 'likes',
        },
        {
          label: 'Comments',
          labelTooltip: this.postIsOwned ? null : this.tooltips.comments,
          value: comments,
          key: 'comments',
        },
        {
          label: 'Shares',
          labelTooltip: null,
          value: shares,
          hidden: !this.showBusinessInsights,
          key: 'shares',
        },
        {
          label: 'Saves',
          labelTooltip: null,
          value: saved,
          hidden: !this.showBusinessInsights,
          key: 'saved',
        },
      ];
      const relationshipInsightsProp = this.postIsRIQ ? this.relationshipInsightProps : [];
      let insightsProps = [
        {
          label: 'Plays',
          labelTooltip: this.tooltips.videoViewsOrganic,
          value: videoViews,
          children: [],
          hidden: !this.showBusinessInsights,
          key: 'videoViews',
        },
        {
          label: !this.showBusinessInsights ? 'Estimated Reach' : 'Reach',
          labelTooltip: !this.showBusinessInsights
            ? this.tooltips.estimatedReach
            : this.tooltips.reachOrganic,
          value: this.userDataUnavailable ? '-' : reach,
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          key: 'reach',
        },
        {
          label: 'Impressions',
          labelTooltip: this.tooltips.impressions,
          value: impressions,
          children: [],
          hidden: !this.showBusinessInsights,
          key: 'impressions',
        },
        {
          label: 'Effectiveness',
          labelTooltip: this.showBusinessInsights
            ? this.tooltips.effectivenessOrganic
            : this.tooltips.effectivenessUgc,
          value:
            this.userDataUnavailable || this.disabledLikeCount
              ? '-'
              : this.formatRate(effectiveness),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          key: 'effectiveness',
        },
        {
          label: 'Engagement Rate',
          labelTooltip: this.postIsOwned
            ? this.tooltips.engagementRateOrganic
            : this.tooltips.engagementRateUgc,
          value:
            this.userDataUnavailable || this.disabledLikeCount
              ? '-'
              : this.formatRate(engagementRate),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          key: 'engagementRate',
        },
        {
          label: 'Engagement Rate Impressions',
          labelTooltip: this.tooltips.engagementRateOrganicImpressions,
          value: this.formatRate(engagementRateImpressions),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          hidden: !this.postIsOwned,
          key: 'engagementRateImpressions',
        },
        {
          label: 'Engagements',
          labelTooltip: this.showBusinessInsights
            ? this.tooltips.engagementsOrganic
            : this.tooltips.engagementsUgc,
          value: !this.disabledLikeCount ? comments + likes + shares + saved : '-',
          children: engagementsChildren,
          key: 'engagements',
        },
        ...this.organicNewInsightProps,
        ...relationshipInsightsProp,
      ];
      insightsProps = insightsProps.filter(
        (prop) => !(this.hideDiscontinuedMetrics && discontinuedFields.includes(prop.key)),
      );
      if (this.showNewMetrics) {
        this.addNewMetrics(insightsProps);
      }
      if (this.postIsOwned) {
        insightsProps = this.insightsPropsReformatLabelsOrganic(insightsProps);
        insightsProps.push({
          label: 'LikeShop Clicks',
          labelTooltip: null,
          value: this.likeshopClicks,
          children: [],
        });
      } else {
        insightsProps = this.insightsPropsReformatLabelsUgc(insightsProps);
      }
      return insightsProps;
    },
    metaPromotedProps() {
      const isNeverConnected = !this.isMetaAdsConnected && !this.hasConnectedMetaAdsPreviously;
      const mapped = Object.entries(this.metaPromotedLevelReelFields)
        .sort(this.sortMetaPromoted)
        .filter(([topLevel]) => !isNeverConnected || !topLevel.endsWith('Paid'))
        .map(([topLevel, nesting]) => {
          let formatter;
          if (rateFieldFormat.includes(topLevel)) {
            formatter = this.formatRate;
          } else if (durationFields.includes(topLevel)) {
            formatter = (v) => formatDynamicDuration(v);
          } else {
            formatter = (v) => v;
          }
          const children = [];
          if (nesting !== null && !isNeverConnected) {
            if (
              this.hasBoostedPostUpdatesEnabled &&
              this.metaAdsAccountScopedNestingsByMetric[topLevel]
            ) {
              Object.entries(this.metaAdsAccountScopedNestingsByMetric[topLevel]).forEach(
                ([subLabel, value]) => {
                  children.push({
                    label: this.formatMetaPromoted(subLabel, topLevel),
                    labelTooltip: null,
                    value: formatter(value),
                  });
                },
              );
            } else if (nesting.length !== 0) {
              const nestingFormatted = getFormattedNestings(nesting, this.mediaItem.boostedData);
              children.push({
                label: this.formatMetaPromoted('organic', topLevel),
                labelTooltip: null,
                value: formatter(nestingFormatted.organic),
              });
              children.push({
                label: 'Promoted',
                labelTooltip: null,
                value: formatter(nestingFormatted.promoted),
              });
            }
          }
          const label = this.formatMetaPromoted(topLevel);
          let value;
          if (topLevel === 'likeshopClicks') {
            value = this.likeshopClicks;
          } else if (newMetricFields.includes(topLevel)) {
            value = this.insights[snakeCase(topLevel)];
          } else {
            value = this.mediaItem.boostedData[topLevel];
          }
          const labelTooltip = this.metaPromotedTooltips[topLevel];
          return {
            label,
            key: topLevel,
            labelTooltip,
            value: formatter(value),
            children,
          };
        });
      return mapped;
    },
    likeshopClicks() {
      const { likeshopClicks } = this.mediaItem;
      if (likeshopClicks && !Number.isNaN(likeshopClicks)) {
        return Number(likeshopClicks);
      }
      return 0;
    },
    showHighlightedInsights() {
      return this.postIsOwned;
    },
    organicInsightsLabelsVerbose() {
      return this.hasInstagramMetricUpdates
        ? organicInsightsLabelsVerbose
        : organicInsightsLabelsVerboseDeprecated;
    },
  },
  mounted() {
    this.fetchInsights();
  },
  methods: {
    isEmpty,
    async showMetaAdsConnectionPopup() {
      await this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW, {
        showWhenDrawerOpen: true,
      });
    },
    async fetchInsights() {
      const { data } = await updatePost({
        brandId: this.mediaItem.brandId ?? this.currentBrand.id,
        postSourceId: this.mediaItem.postSourceId,
      });
      this.insights = data;
    },
    formatRate(value) {
      if (value === 0) {
        return 0;
      }
      return `${(value * 100).toFixed(2)}%`;
    },
    formatValue(value, key) {
      if (
        this.hasBoostedPostUpdatesEnabled &&
        this.metaAdsAccountScopedNestingsByMetric[key] &&
        key.toLowerCase().endsWith('paid')
      ) {
        return '-';
      }
      if (accountingField.includes(key)) {
        const formattedValue = formatValueByFormatType(value, METRIC_FORMATS.MONETARY_LONG, '-');
        return this.currency ? `${formattedValue} ${this.currency}` : formattedValue;
      }
      return value;
    },
    insightsPropsReformatLabelsOrganic(insightsProps) {
      return insightsProps.map((prop) => {
        prop.label = this.organicInsightsLabelsVerbose[prop.key] ?? `${prop.label} - Organic`;

        if (prop.children) {
          prop.children = prop.children.map((childProp) => {
            childProp.label += ' - Organic';
            return childProp;
          });
        }
        return prop;
      });
    },
    insightsPropsReformatLabelsUgc(insightsProps) {
      if (!this.hasInstagramMetricUpdates || !this.showBusinessInsights) {
        return insightsProps;
      }
      return insightsProps.map((prop) => {
        prop.label = connectedUgcInsightsLabelsVerbose[prop.key] ?? prop.label;
        return prop;
      });
    },
    formatEmvRate(value) {
      return `$${localize(value)}`;
    },
    formatMetaPromoted(field, parentField) {
      return parentField
        ? formatMetaPromoted(field, {
            parentField,
            verboseFields: this.metaPromotedReelFieldsToVerbose,
          })
        : formatMetaPromoted(field, { verboseFields: this.metaPromotedReelFieldsToVerbose });
    },
    sortMetaPromoted([a], [b]) {
      return sortMetaPromotedFields(a, b, this.formatMetaPromoted);
    },
    addNewMetrics(insightsProps) {
      const {
        views,
        engagement_rate_views: engagementRateViews,
        effectiveness_engagements: effectivenessEngagements,
      } = this.insights;
      insightsProps.push(
        {
          label: 'Views',
          labelTooltip: this.tooltips.views,
          value: views,
          children: [],
          key: 'views',
        },
        {
          label: 'Engagement Rate Views',
          labelTooltip: this.tooltips.engagementRateViews,
          value: this.formatRate(engagementRateViews),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          key: 'engagementRateViews',
        },
        {
          label: 'Effectiveness',
          labelTooltip: this.tooltips.effectivenessEngagements,
          value: this.formatRate(effectivenessEngagements),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          key: 'effectivenessEngagements',
        },
      );
    },
    useAdAccountScopedInsightsStyling(topLevelPropKey, childLabel) {
      return (
        this.hasBoostedPostUpdatesEnabled &&
        this.metaAdsAccountScopedNestingsByMetric[topLevelPropKey] &&
        childLabel.toLowerCase() !== 'organic'
      );
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.all-insights-container {
  width: 100%;

  .main-insights-container {
    background: var(--background-300);
    border-radius: var(--round-corner);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-24);
    margin: var(--space-8) auto;
    font-size: var(--x14);
    color: var(--text-primary);
  }

  .hidden-likes {
    margin: var(--space-16) var(--space-32) 0 var(--space-32);
    background-color: var(--background-400);
    padding: var(--space-16) var(--space-16) var(--space-16) var(--space-16);
    text-align: left;
  }
}
</style>
