import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useMediaPopupStore } from '@/stores/media-popup';
import { useFlagStore } from '@/stores/flag';
import { useDrawer } from '@dashhudson/dashing-ui';

const propsListIndex = ref();
const context = ref({});

export function useMediaDrawer() {
  const router = useRouter();

  const mediaPopupStore = useMediaPopupStore();
  const flagStore = useFlagStore();
  const { launchDrawer } = useDrawer();

  const hasGdiHeadersAndFiltersFlag = computed(
    () => flagStore.ready && flagStore.flags.gdiHeadersAndFilters,
  );

  const propsList = computed(() =>
    (mediaPopupStore.contextList ?? [])?.map((contextListItem) => {
      const id = contextListItem.id || contextListItem.sourceId;
      const source = contextListItem.source;

      const patchJson = { ...(context.value.patchJson ?? {}), media_id: id };
      if (contextListItem[source]?.timestamp) {
        patchJson.timestamp = contextListItem[source].timestamp;
      }

      return {
        id,
        patchJson,

        brandLabel: context.value.brandLabel,
        brandId: context.value.brandId,
        source: context.value.source,
      };
    }),
  );

  async function openMediaPopup({ id, brandId, brandLabel, source, replaceModal, patchJson } = {}) {
    const params = {
      id,
    };
    const query = {};

    if (brandId) {
      params.brandId = brandId;
      query.brandId = brandId;
    }

    if (brandLabel) {
      params.brandLabel = brandLabel;
    }

    if (source) {
      query.source = source;
    }
    if (replaceModal) {
      query.replaceModal = !!replaceModal;
    }
    if (patchJson) {
      query.patchJson = patchJson;
    }
    const payload = {
      name: 'library.media',
      params,
    };

    if (Object.keys(query).length > 0) {
      payload.query = query;
    }

    await router.push(payload);
  }

  async function openMedia({
    id,
    brandId,
    brandLabel,
    source,
    replaceModal,
    patchJson: incomingPatchJson,
  }) {
    const patchJson = { ...(incomingPatchJson ?? {}), media_id: id };

    context.value = { brandId, brandLabel, source, patchJson };

    if (hasGdiHeadersAndFiltersFlag.value) {
      launchDrawer({
        name: 'media',
        props: {
          id,
          brandId,
          brandLabel,
          source,
          patchJson,
        },
        propsList: propsList.value,
      });
    } else {
      openMediaPopup({ id, brandId, brandLabel, source, replaceModal, patchJson });
    }
  }

  function updatePropsListIndex(media) {
    if (!media || !propsList.value) {
      propsListIndex.value = null;
      return;
    }

    propsListIndex.value = propsList.value.findIndex((item) => {
      if (item.id) {
        return item.id === media.id || item.id === media.postSourceId;
      }
      return item.sourceId === media.postSourceId;
    });
  }

  const hasPreviousMedia = computed(() => {
    if (propsList.value?.length > 0) {
      return propsListIndex.value > 0;
    }
    return false;
  });

  const hasNextMedia = computed(() => {
    if (propsList.value?.length > 0) {
      return propsListIndex.value < propsList.value.length - 1;
    }
    return false;
  });

  function loadPreviousMedia() {
    if (!hasPreviousMedia.value) {
      return null;
    }

    propsListIndex.value -= 1;
    const previousProps = propsList.value[propsListIndex.value];

    openMedia({ ...previousProps, replaceModal: true });

    return previousProps;
  }

  function loadNextMedia() {
    if (!hasNextMedia.value) {
      return null;
    }

    propsListIndex.value += 1;
    const nextProps = propsList.value[propsListIndex.value];

    openMedia({ ...nextProps, replaceModal: true });

    return nextProps;
  }

  return {
    openMedia,
    hasPreviousMedia,
    hasNextMedia,
    loadPreviousMedia,
    loadNextMedia,
    updatePropsListIndex,
  };
}
