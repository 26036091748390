import enumTypes from '@/app/library/constants';

import { getPasswordResetStatus } from '@/apis/auth';
import { useAuthStore } from '@/stores/auth';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { useTrackingStore } from '@/stores/tracking';
import ConnectTiktokAds from '@/app/auth/pages/ConnectTiktokAds.vue';
import ConnectTiktokAdsSuccess from '@/app/auth/pages/ConnectTiktokAdsSuccess.vue';
import { CHANNELS } from '@/models/dashboards/channels.enum';

const App = () => import('@/app/auth/App.vue');
const AccountSetup = () => import('@/app/auth/pages/AccountSetup.vue');
const ConnectFacebook = () => import('@/app/auth/pages/ConnectFacebook.vue');
const ConnectFacebookSuccess = () => import('@/app/auth/pages/ConnectFacebookSuccess.vue');
const ConnectFacebookAds = () => import('@/app/auth/pages/ConnectFacebookAds.vue');
const ConnectFacebookAdsSuccess = () => import('@/app/auth/pages/ConnectFacebookAdsSuccess.vue');
const ConnectPinterest = () => import('@/app/auth/pages/ConnectPinterest.vue');
const ConnectPinterestSuccess = () => import('@/app/auth/pages/ConnectPinterestSuccess.vue');
const ConnectTwitter = () => import('@/app/auth/pages/ConnectTwitter.vue');
const ConnectTwitterSuccess = () => import('@/app/auth/pages/ConnectTwitterSuccess.vue');
const ConnectTiktok = () => import('@/app/auth/pages/ConnectTiktok.vue');
const ConnectTiktokSuccess = () => import('@/app/auth/pages/ConnectTiktokSuccess.vue');
const ConnectYouTube = () => import('@/app/auth/pages/ConnectYouTube.vue');
const ConnectYouTubeSuccess = () => import('@/app/auth/pages/ConnectYouTubeSuccess.vue');
const ConnectThreads = () => import('@/app/auth/pages/ConnectThreads.vue');
const ConnectThreadsSuccess = () => import('@/app/auth/pages/ConnectThreadsSuccess.vue');
const ConnectBluesky = () => import('@/app/auth/pages/ConnectBluesky.vue');
const ConnectBlueskySuccess = () => import('@/app/auth/pages/ConnectBlueskySuccess.vue');
const ExpiredLinkPage = () => import('../../components/ExpiredLinkPage.vue');
const CreatorConnectPlatformSuccess = () =>
  import('@/app/auth/pages/CreatorConnectPlatformSuccess.vue');
const CreatorConnectPlatform = () => import('@/app/auth/pages/CreatorConnectPlatform.vue');
const CreatorConnectError = () => import('@/app/auth/pages/CreatorConnectError.vue');
const Login = () => import('@/app/auth/pages/Login.vue');
const ForgotPassword = () => import('@/app/auth/pages/ForgotPassword.vue');
const Logout = () => import('@/app/auth/pages/Logout.vue');
const SetPassword = () => import('@/app/auth/pages/SetPassword.vue');
const OAuth = () => import('@/app/auth/pages/OAuth.vue');
const PasswordReset = () => import('@/app/auth/pages/PasswordReset.vue');
const Redirecting = () => import('@/app/auth/pages/Redirecting.vue');
const SSO = () => import('@/app/auth/pages/SSO.vue');
const SSOLogin = () => import('@/app/auth/pages/SSOLogin.vue');
const SSOLoginError = () => import('@/app/auth/pages/SSOLoginError.vue');
const OnboardingFlowPage = () => import('@/app/auth/pages/OnboardingFlowPage.vue');

const PAGE_LEVEL_TOP = 'Auth';

export async function beforeEnterHashCheck(to, from, next) {
  const { hash } = to.params;
  const { user_id: userId } = to.query;
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();
  if (authStore.isLoggedIn && to.name === 'auth.set-password') {
    next({ name: 'landingPage' });
  }
  if (!hash || !userId) {
    next({ name: 'link.expired' });
  }
  try {
    const response = await getPasswordResetStatus({
      hash,
      userId,
    });
    if (response.data.status === 'lapsed') {
      next({ name: 'link.expired' });
      try {
        trackingStore.track('Password Reset Link Expired', {
          passwordResetUserId: userId,
        });
      } catch (_) {
        // catch the error to avoid breaking the redirect
      }
    } else {
      next();
    }
  } catch {
    next();
  }
}

export function beforeEnterOnboardingFlow(to, from, next) {
  if (!useCustomerJourneyStore().isProductLedGrowTrial) {
    next({
      name: 'settings.socialPlatform',
      params: { brandLabel: useAuthStore().currentBrandLabel },
    });
  } else {
    next();
  }
}

export default {
  path: '',
  component: App,
  meta: {
    hideNav: true,
    hidePrimaryNav: true,
    hideSecondaryNav: true,
    hideVisionAi: true,
  },
  children: [
    {
      name: 'account.setup',
      path: 'setup',
      component: AccountSetup,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Setup`,
        requiresAuth: true,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
    },
    {
      name: 'auth.login',
      path: 'login',
      component: Login,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Login`,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
    },
    {
      name: 'auth.forgot-password',
      path: 'forgot-password',
      component: ForgotPassword,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Forgot Password`,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
    },
    {
      name: 'auth.logout',
      path: 'logout',
      component: Logout,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Logout`,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'auth.set-password',
      path: 'set-password/:hash',
      component: SetPassword,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Set Password`,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
      beforeEnter: (to, from, next) => beforeEnterHashCheck(to, from, next),
    },
    {
      name: 'auth.callback',
      path: 'callback/:provider',
      component: OAuth,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Callback`,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'auth.ssoRedirect',
      path: 'sso/redirect',
      component: SSO,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - SSO Redirect`,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'auth.ssoLogin',
      path: 'sso/login',
      component: SSOLogin,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - SSO Login`,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
    },
    {
      name: 'auth.acsError',
      path: 'sso/acs/error',
      component: SSOLoginError,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ACS Error`,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'auth.acsRedirect',
      path: 'sso/acs',
      component: Redirecting,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ACS Redirect`,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.facebook',
      path: 'brand-connect-facebook/:brandId/:token',
      component: ConnectFacebook,
      meta: {
        analytics: 'Facebook - Connection Page',
        connection: true,
        platform: 'facebook',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.facebook.success',
      path: 'brand-connect-facebook-success',
      component: ConnectFacebookSuccess,
      meta: {
        analytics: 'Facebook - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.facebook.analytics',
      path: 'brand-connect-facebook-analytics/:brandId/:token',
      component: ConnectFacebook,
      meta: {
        analytics: 'Facebook Analytics - Connection Page',
        connection: true,
        platform: enumTypes.FACEBOOK_ANALYTICS,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.facebook.analytics.success',
      path: 'brand-connect-facebook-analytics-success',
      component: ConnectFacebookSuccess,
      meta: {
        analytics: 'Facebook Analytics - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.facebookAds',
      path: 'brand-connect-facebook-ads/:brandId/:token',
      component: ConnectFacebookAds,
      meta: {
        analytics: 'Facebook Ads - Connection Page',
        connection: true,
        platform: enumTypes.FACEBOOK_ADS,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.facebookAds.success',
      path: 'brand-connect-facebook-ads-success',
      component: ConnectFacebookAdsSuccess,
      meta: {
        analytics: 'Facebook Ads - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.instagramCreator',
      path: 'brand-connect-instagram-creator/:brandId/:token',
      component: CreatorConnectPlatform,
      meta: {
        analytics: 'Instagram Influencer - Connection Page',
        connection: true,
        platform: enumTypes.INSTAGRAM_INFLUENCER,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.instagramCreator.success',
      path: 'brand-connect-instagram-creator-success',
      component: CreatorConnectPlatformSuccess,
      meta: {
        analytics: 'Instagram Creator - Connection Success Page',
        allowWhenNoConnections: true,
        channel: CHANNELS.INSTAGRAM.text,
      },
    },
    {
      name: 'connect.instagramInfluencer',
      path: 'influencer-connect-facebook/:brandId/:token',
      redirect: { name: 'connect.instagramCreator' },
    },
    {
      name: 'connect.instagramInfluencer.success',
      path: 'influencer-connect-facebook-success',
      component: CreatorConnectPlatformSuccess,
      meta: {
        analytics: 'Instagram Creator - Connection Success Page',
        allowWhenNoConnections: true,
        channel: CHANNELS.INSTAGRAM.text,
      },
    },
    {
      name: 'connect.instagramCreator.error',
      path: 'brand-connect-instagram-creator-error',
      component: CreatorConnectError,
      meta: {
        analytics: 'Instagram Creator - Connection Error Page',
        allowWhenNoConnections: true,
        platform: enumTypes.INSTAGRAM_INFLUENCER,
      },
    },
    {
      name: 'connect.tiktokCreator',
      path: 'brand-connect-tiktok-creator/:brandId/:token',
      component: CreatorConnectPlatform,
      meta: {
        analytics: 'TikTok Creator - Connection Page',
        connection: true,
        platform: enumTypes.TIKTOK_CREATOR,
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.tiktokCreator.success',
      path: 'brand-connect-tiktok-creator-success',
      component: CreatorConnectPlatformSuccess,
      meta: {
        analytics: 'Tiktok Creator - Connection Success Page',
        allowWhenNoConnections: true,
        channel: CHANNELS.TIKTOK.text,
      },
    },
    {
      name: 'connect.tiktokCreator.error',
      path: 'brand-connect-tiktok-creator-error',
      component: CreatorConnectError,
      meta: {
        analytics: 'TikTok Creator - Connection Error Page',
        allowWhenNoConnections: true,
        platform: enumTypes.TIKTOK_CREATOR,
      },
    },
    {
      name: 'connect.pinterest',
      path: 'brand-connect-pinterest/:brandId/:token',
      component: ConnectPinterest,
      meta: {
        analytics: 'Pinterest - Connection Page',
        connection: true,
        platform: 'pinterest',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.pinterestV5',
      path: 'brand-connect-pinterest-v5/:brandId/:token',
      component: ConnectPinterest,
      meta: {
        analytics: 'Pinterest - Connection Page',
        connection: true,
        platform: 'pinterest_v5',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.pinterest.success',
      path: 'brand-connect-pinterest-success',
      component: ConnectPinterestSuccess,
      meta: {
        analytics: 'Pinterest - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.pinterestV5.success',
      path: 'brand-connect-pinterest-v5-success',
      component: ConnectPinterestSuccess,
      meta: {
        analytics: 'Pinterest - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.twitter',
      path: 'brand-connect-twitter/:brandId/:token',
      component: ConnectTwitter,
      meta: {
        analytics: 'Twitter - Connection Page',
        connection: true,
        platform: 'twitter',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.twitter.success',
      path: 'brand-connect-twitter-success',
      component: ConnectTwitterSuccess,
      meta: {
        analytics: 'Twitter - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.tiktok',
      path: 'brand-connect-tiktok/:brandId/:token',
      component: ConnectTiktok,
      meta: {
        connection: true,
        platform: 'tiktok',
        analytics: 'TikTok - Connection Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.tiktok.success',
      path: 'brand-connect-tiktok-success',
      component: ConnectTiktokSuccess,
      meta: {
        analytics: 'TikTok - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.tiktokAds',
      path: 'brand-connect-tiktok-ads/:brandId/:token',
      component: ConnectTiktokAds,
      meta: {
        connection: true,
        platform: enumTypes.TIKTOK_ADS,
        analytics: 'TikTok Ads - Connection Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.tiktokAds.success',
      path: 'brand-connect-tiktok-ads-success',
      component: ConnectTiktokAdsSuccess,
      meta: {
        analytics: 'TikTok Ads - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.youtube',
      path: 'brand-connect-youtube/:brandId/:token',
      component: ConnectYouTube,
      meta: {
        connection: true,
        platform: 'youtube',
        analytics: 'YouTube - Connection Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.youtube.success',
      path: 'brand-connect-youtube-success',
      component: ConnectYouTubeSuccess,
      meta: {
        analytics: 'YouTube - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.threads',
      path: 'brand-connect-threads/:brandId/:token',
      component: ConnectThreads,
      meta: {
        connection: true,
        platform: 'threads',
        analytics: 'Threads - Connection Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.threads.success',
      path: 'brand-connect-threads-success',
      component: ConnectThreadsSuccess,
      meta: {
        analytics: 'Threads - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.bluesky',
      path: 'brand-connect-bluesky/:brandId/:token',
      component: ConnectBluesky,
      meta: {
        connection: true,
        platform: 'bluesky',
        analytics: 'Bluesky - Connection Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'connect.bluesky.success',
      path: 'brand-connect-bluesky-success',
      component: ConnectBlueskySuccess,
      meta: {
        analytics: 'Bluesky - Connection Success Page',
        allowWhenNoConnections: true,
      },
    },
    {
      name: 'password.reset',
      path: 'password_resets/:hash',
      component: PasswordReset,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Password Reset`,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
      beforeEnter: (to, from, next) => beforeEnterHashCheck(to, from, next),
    },
    {
      name: 'link.expired',
      path: 'link_expired',
      props: true,
      component: ExpiredLinkPage,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Link Expired`,
        allowWhenNoConnections: true,
        setResponsiveViewportMetaTag: true,
      },
    },
    {
      name: 'onboardingFlowPage',
      path: 'welcome',
      component: OnboardingFlowPage,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Onboarding`,
        requiresAuth: true,
        setResponsiveViewportMetaTag: true,
        hidePrimaryNav: true,
        hideSecondaryNav: true,
      },
      beforeEnter: beforeEnterOnboardingFlow,
    },
  ],
};
