import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import * as CommunityAPI from '@/apis/community';
import { useIdentityStore } from '@/stores/identity';
import { useNotificationStore } from '@/stores/notification';
import {
  COMMUNITY_INTERACTION_RULES_DELETE_POPUP,
  COMMUNITY_INTERACTION_RULES_UNDO_ACTION_POPUP,
  COMMUNITY_INTERACTION_RULES_SAVE_AND_APPLY_TO_EXISTING_POPUP,
} from '@/app/community/constants';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import {
  createOrEditMessageRuleEvent,
  messageRuleInteractionsEvent,
  undoMessageRuleEvent,
} from '@/app/community/utils/mixpanel-events';
import { useAuthStore } from '@/stores/auth';
import { useCommunityMessageRuleBuilder } from '@/composables/community/useCommunityRuleBuilder';
import {
  COMMUNITY_INTERACTION_RULE_TOASTS,
  MESSAGE_RULE_MIXPANEL_CONSTANTS,
  messageRuleToggleToast,
} from '@/app/community/constants/inboxAutomation';
import { refreshCancelToken } from '@/apis/axios.utils';
import { logger } from '@/utils/logger';
import * as AuthAPI from '@/apis/auth';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

export const useCommunityInteractionRulesStore = defineStore('communityInteractionRules', () => {
  const identityStore = useIdentityStore();
  const notificationStore = useNotificationStore();

  // TODO: clean this up so there is only 1 state variable for single and multi brand rules
  const interactionRules = ref([]);
  const multiBrandInteractionRules = ref([]);
  const selectedBrandIdForInteractionRules = ref();
  const selectedBrandAccessibleUsers = ref([]);

  const showPopup = ref({
    messageRules: false,
    messageRuleBuilder: false,
  });
  const editedRuleId = ref();
  const pending = ref({
    interactionRules: true,
    multiBrandInteractionRules: true,
  });

  const hasInteractionRules = computed(() => {
    return interactionRules.value.length > 0;
  });

  const selectedBrandId = computed(() => {
    return selectedBrandIdForInteractionRules.value ?? identityStore.currentBrand?.id;
  });

  const interactionRulesByBrand = computed(() => {
    if (!multiBrandInteractionRules.value?.length) {
      return {};
    }
    return groupBy(multiBrandInteractionRules.value, 'brandId');
  });

  function getSelectedBrandInteractionRules(brandId = selectedBrandId.value) {
    const brandInteractionRules = interactionRulesByBrand.value?.[brandId] ?? [];
    return orderBy(brandInteractionRules, [(r) => r.lastModifiedAt ?? r.createdAt], ['desc']);
  }

  const {
    actions,
    conditionGroups,
    isFiltersComplete,
    isActionsComplete,
    isRuleComplete,
    hasRepliedFilter,
    filterMatchedMessagesCount,
    ruleBuilderErrors,
    ruleDetail,
    buildJSONRule,
    setConditionGroupOperator,
    updateGroup,
    removeFilterGroup,
    buildJSONRuleFilters,
    buildJSONRuleActions,
    checkGroupHasCompleteItem,
    clearRuleBuilder,
    updateFilterMatchCount,
    setRuleActionsFromJSON,
    setRuleFiltersFromJSON,
    isRuleDetailChanged,
    getFilterUsed,
    getFilterGroups,
  } = useCommunityMessageRuleBuilder();

  async function getInteractionRules() {
    pending.value.interactionRules = true;
    try {
      const res = await CommunityAPI.getInteractionRules(identityStore.currentBrand.id);
      interactionRules.value = res.data.data;
    } finally {
      pending.value.interactionRules = false;
    }
  }

  async function getInteractionRulesForBrands(brandIds) {
    pending.value.multiBrandInteractionRules = true;
    const interactionRuleBrandIds = Array.isArray(brandIds) ? brandIds.join(',') : brandIds;
    try {
      const res = await CommunityAPI.getInteractionRulesForBrands(interactionRuleBrandIds);
      multiBrandInteractionRules.value = res.data.data;
    } catch {
      pending.value.multiBrandInteractionRules = false;
    } finally {
      pending.value.multiBrandInteractionRules = false;
    }
  }

  async function confirmSaveAndApplyToExisting(applyToHistorical) {
    if (applyToHistorical) {
      const { title, confirmMessage, confirmAlias, cancelAlias } =
        COMMUNITY_INTERACTION_RULES_SAVE_AND_APPLY_TO_EXISTING_POPUP;
      const confirmMessageWithCount = confirmMessage.replace(
        'NUM',
        filterMatchedMessagesCount.value,
      );
      const confirmed = await notificationStore.confirm(title, confirmMessageWithCount, {
        confirmType: 'primary',
        confirmAlias,
        cancelAlias,
      });
      return confirmed;
    }
    return true;
  }

  async function createInteractionRule(
    name,
    detail,
    applyToHistorical = false,
    isMultiBrand = false,
  ) {
    const notification = { message: COMMUNITY_INTERACTION_RULE_TOASTS.SUCCESS };
    const payload = { name, detail };
    payload.authorId = identityStore.identity?.id;
    payload.enabled = true;
    payload.applyToHistorical = applyToHistorical;

    if (payload.applyToHistorical) {
      notification.message = `${notification.message}. ${COMMUNITY_INTERACTION_RULE_TOASTS.APPLY_TO_EXISTING}`;
    }

    const confirmed = await confirmSaveAndApplyToExisting(applyToHistorical);
    if (!confirmed) {
      return false;
    }
    try {
      const res = await CommunityAPI.createInteractionRule(selectedBrandId.value, payload);
      if (isMultiBrand) {
        multiBrandInteractionRules.value.unshift({
          ...res.data,
          new: true,
        });
      } else {
        interactionRules.value.unshift({
          ...res.data,
          new: true,
        });
      }
      notificationStore.setToast(notification);
    } catch (error) {
      const message =
        [409, 400].includes(error.response.status) && error.response.data?.error
          ? error.response.data.error
          : COMMUNITY_INTERACTION_RULE_TOASTS.FAILURE;
      notificationStore.setToast({
        message,
        type: 'error',
      });
      throw error;
    }
    return true;
  }

  async function updateInteractionRule(
    ruleId,
    payload,
    reactive = false,
    ruleModified = false,
    cancelToken = null,
    isMultiBrand = false,
  ) {
    const notification = { message: COMMUNITY_INTERACTION_RULE_TOASTS.SUCCESS };
    if (reactive) {
      notification.message = COMMUNITY_INTERACTION_RULE_TOASTS.SUCCESS_REACTIVATED;
    }
    if (!ruleModified && payload.enabled !== undefined) {
      notification.message = messageRuleToggleToast(payload.enabled);
    }
    if (payload.applyToHistorical) {
      notification.message = `${notification.message}. ${COMMUNITY_INTERACTION_RULE_TOASTS.APPLY_TO_EXISTING}`;
    }
    const confirmed = await confirmSaveAndApplyToExisting(payload.applyToHistorical);
    if (!confirmed) {
      return false;
    }
    try {
      let axiosCancelToken = null;
      if (cancelToken) {
        const { key, value } = cancelToken;
        axiosCancelToken = refreshCancelToken(value, key);
      }
      const res = await CommunityAPI.updateInteractionRule(selectedBrandId.value, ruleId, payload, {
        cancelToken: axiosCancelToken,
      });
      const updatedRule = res.data;
      const existingRuleIndex = isMultiBrand
        ? multiBrandInteractionRules.value.findIndex((rule) => rule.id === ruleId)
        : interactionRules.value.findIndex((rule) => rule.id === ruleId);
      if (ruleModified) {
        if (isMultiBrand) {
          multiBrandInteractionRules.value.splice(existingRuleIndex, 1);
          multiBrandInteractionRules.value.unshift(updatedRule);
        } else {
          interactionRules.value.splice(existingRuleIndex, 1);
          interactionRules.value.unshift(updatedRule);
        }
      } else if (isMultiBrand) {
        multiBrandInteractionRules.value.splice(existingRuleIndex, 1, updatedRule);
      } else {
        interactionRules.value.splice(existingRuleIndex, 1, updatedRule);
      }
      notificationStore.setToast(notification);
    } catch (error) {
      const message =
        error.response?.status === 409 && error.response.data?.error
          ? error.response.data.error
          : COMMUNITY_INTERACTION_RULE_TOASTS.FAILURE;
      notificationStore.setToast({
        message,
        type: 'error',
      });
      return false;
    }
    return true;
  }

  async function deleteInteractionRule(rule, isMultiBrand = false) {
    let notification = {
      message: COMMUNITY_INTERACTION_RULE_TOASTS.DELETED.SUCCESS,
      type: 'success',
    };
    const { title, confirmMessage, confirmAlias, cancelAlias } =
      COMMUNITY_INTERACTION_RULES_DELETE_POPUP;
    const confirmed = await notificationStore.confirm(title, confirmMessage, {
      confirmAlias,
      cancelAlias,
    });
    if (confirmed) {
      try {
        await CommunityAPI.deleteInteractionRule(selectedBrandId.value, rule.id);
        if (isMultiBrand) {
          multiBrandInteractionRules.value = multiBrandInteractionRules.value.filter(
            (r) => r.id !== rule.id,
          );
        } else {
          interactionRules.value = interactionRules.value.filter((r) => r.id !== rule.id);
        }

        messageRuleInteractionsEvent({
          interaction: MESSAGE_RULE_MIXPANEL_CONSTANTS.MESSAGE_RULE_INTERACTIONS.DELETE,
          ruleId: rule.id,
          ruleName: rule.name,
        });
      } catch (error) {
        notification = {
          message: COMMUNITY_INTERACTION_RULE_TOASTS.DELETED.FAILURE,
          type: 'error',
        };
      }
      notificationStore.setToast(notification);
    }
  }

  function setEditedRuleId(id) {
    editedRuleId.value = id;
  }

  function togglePopup(name, value) {
    showPopup.value[name] = !!value;
  }

  async function openRuleBuilderToEdit(ruleId, fromMessage = false, isMultiBrand = false) {
    await setEditedRuleId(ruleId);
    togglePopup('messageRuleBuilder', true);

    const editedRule = isMultiBrand
      ? multiBrandInteractionRules.value.find((r) => r.id === ruleId)
      : interactionRules.value.find((r) => r.id === ruleId);
    if (editedRule) {
      const option = fromMessage ? 'EDIT_FROM_MESSAGE' : 'EDIT';
      createOrEditMessageRuleEvent({
        openedFrom: MESSAGE_RULE_MIXPANEL_CONSTANTS.RULE_BUILDER_OPENED_FROM[option],
        ruleId,
        ruleName: editedRule.name,
      });
      messageRuleInteractionsEvent({
        interaction: MESSAGE_RULE_MIXPANEL_CONSTANTS.MESSAGE_RULE_INTERACTIONS[option],
        ruleId,
        ruleName: editedRule.name,
      });
    }
  }

  function formatTags(actionTagList = [], savedTagList = []) {
    const tagsMap = actionTagList.reduce((result, tag) => Object.assign(result, tag), {});
    const formatted = Object.keys(tagsMap).map((tagId) => {
      const savedTag = savedTagList.find((t) => t.id === Number(tagId));
      const item = { icon: { name: 'dot' } };
      if (savedTag) {
        item.icon.color = savedTag.color;
        item.text = savedTag.name;
        item.id = savedTag.id;
      } else {
        item.text = tagsMap[tagId];
        item.id = Number(tagId);
        item.error = true;
      }
      return item;
    });

    return formatted;
  }

  function getAuthorName(authorId) {
    if (authorId) {
      const authStore = useAuthStore();
      let brandAccessibleUsers;
      if (selectedBrandAccessibleUsers.value.length > 0) {
        brandAccessibleUsers = selectedBrandAccessibleUsers.value;
      } else {
        brandAccessibleUsers = authStore.brandAccessibleUsers;
      }
      const author = brandAccessibleUsers.find((u) => u.id === authorId);
      if (author) {
        return [author.firstName, author.lastName].join(' ');
      }
      return 'Unavailable User';
    }
    return 'System';
  }

  async function getSelectedBrandAccessibleUsers(brandId) {
    try {
      const response = await AuthAPI.getBrandUsers({
        brandId,
        sort: 'first_name,last_name',
      });
      const payload = response?.data;
      selectedBrandAccessibleUsers.value = payload?.data;
      return response;
    } catch (error) {
      if (error.response?.status === 401) {
        logger.error(
          `auth getBrandAccessibleUsers request returned authentication error: ${error.response?.data?.description}`,
          {},
          error,
        );
        return null;
      }
      throw error;
    }
  }

  async function updateInternalMessageRuleInteraction(interactionId, payload) {
    const communityInteractionStore = useCommunityInteractionStore();
    await communityInteractionStore.updateCommunityInteraction(
      interactionId,
      payload,
      false,
      null,
      null,
      true,
    );
    return true;
  }

  async function deleteInteractionRulesHistory(brandId, ruleExecutionHistoryId) {
    try {
      await CommunityAPI.deleteInteractionRulesHistory(brandId, ruleExecutionHistoryId);
      return true;
    } catch (error) {
      throw new Error('Failed to delete interaction rule history');
    }
  }

  async function undoAction(message, deleteRuleExecutedMessage) {
    const communityInteractionStore = useCommunityInteractionStore();
    const interactionId = communityInteractionStore.selectedCommunityInteractionId;
    const { title, confirmMessage, confirmAlias } = COMMUNITY_INTERACTION_RULES_UNDO_ACTION_POPUP;
    const confirmed = await notificationStore.confirm(title, confirmMessage, {
      confirmAlias,
      confirmType: 'primary',
    });
    if (confirmed) {
      const payload = {};
      const revertPayload = {};
      if (message.action.archive) {
        payload.isArchived = false;
        revertPayload.isArchived = true;
      }
      if (message.action.tags) {
        const tagIds = Object.keys(message.action.tags[0]).map(Number);
        payload.tagIds = { remove: tagIds };
        revertPayload.tagIds = { add: tagIds };
      }

      try {
        const undoResult = await updateInternalMessageRuleInteraction(interactionId, payload);
        if (undoResult) {
          try {
            const deleteHistory = await deleteInteractionRulesHistory(
              identityStore.currentBrand.id,
              message.id,
            );
            if (deleteHistory) {
              deleteRuleExecutedMessage({ ...message, interactionId });
              notificationStore.setToast({
                message: COMMUNITY_INTERACTION_RULE_TOASTS.UNDO.SUCCESS,
                type: 'success',
              });
            }
          } catch (error) {
            await updateInternalMessageRuleInteraction(interactionId, revertPayload);
            throw error;
          }
        }
        undoMessageRuleEvent({ ruleId: message.ruleId, ruleName: message.triggeredRuleName });
      } catch {
        notificationStore.setToast({
          message: COMMUNITY_INTERACTION_RULE_TOASTS.UNDO.ERROR,
          type: 'error',
        });
      }
    }
  }

  function checkRuleDeleted(ruleId) {
    const ruleFound = interactionRules.value.some((rule) => rule.id === ruleId);
    return !ruleFound;
  }

  return {
    interactionRules,
    multiBrandInteractionRules,
    showPopup,
    pending,
    hasInteractionRules,
    interactionRulesByBrand,
    getSelectedBrandInteractionRules,
    editedRuleId,
    getInteractionRules,
    getInteractionRulesForBrands,
    updateInteractionRule,
    createInteractionRule,
    togglePopup,
    setEditedRuleId,
    openRuleBuilderToEdit,
    formatTags,
    getAuthorName,
    actions,
    conditionGroups,
    isFiltersComplete,
    isActionsComplete,
    isRuleComplete,
    hasRepliedFilter,
    filterMatchedMessagesCount,
    ruleBuilderErrors,
    ruleDetail,
    updateGroup,
    removeFilterGroup,
    deleteInteractionRule,
    deleteInteractionRulesHistory,
    undoAction,
    checkRuleDeleted,
    setConditionGroupOperator,
    buildJSONRule,
    buildJSONRuleActions,
    buildJSONRuleFilters,
    clearRuleBuilder,
    updateFilterMatchCount,
    checkGroupHasCompleteItem,
    setRuleActionsFromJSON,
    setRuleFiltersFromJSON,
    isRuleDetailChanged,
    getFilterUsed,
    getFilterGroups,
    selectedBrandIdForInteractionRules,
    getSelectedBrandAccessibleUsers,
    selectedBrandAccessibleUsers,
    selectedBrandId,
  };
});
