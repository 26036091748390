import { constants } from '@/config';

const App = () => import('@/app/facebook/App.vue');
const BoardsWrapper = () => import('@/app/boards/pages/BoardsWrapper.vue');
const FacebookGalleryEdit = () => import('@/app/facebook/pages/FacebookGalleryEdit.vue');
const Gallery = () => import('@/components/Galleries/Gallery.vue');
const Overview = () => import('@/app/facebook/pages/Overview.vue');
const YourPosts = () => import('@/app/facebook/pages/YourPosts.vue');

const PAGE_LEVEL_TOP = `Facebook`;

export default {
  path: 'facebook',
  component: App,
  redirect: { name: 'facebook.overview' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      name: 'facebook',
      path: '',
      redirect: { name: 'facebook.overview' },
    },
    {
      name: 'facebook.dashboard',
      path: 'dashboard',
      component: Overview,
      redirect: { name: 'facebook.overview' },
    },
    {
      name: 'facebook.dashboard.report',
      path: 'dashboard/report',
      component: Overview,
      meta: {
        report: true,
      },
      redirect: { name: 'facebook.overview.report' },
    },
    {
      name: 'facebook.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'facebook.overview.report',
      path: 'overview/report',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview Report`,
        report: true,
      },
    },
    {
      name: 'facebook.yourPosts',
      path: 'your-posts',
      component: YourPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Posts`,
      },
    },
    {
      path: 'boards',
      children: [
        {
          name: 'facebook.galleries',
          path: '',
          component: BoardsWrapper,
          props: {
            galleryType: constants.FACEBOOK,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Boards`,
            scrollToTop: (to, from) => from.name !== 'facebook.galleries.new',
          },
          children: [
            {
              name: 'facebook.galleries.new',
              path: 'new',
              component: FacebookGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - New`,
                scrollToTop: false,
              },
            },
          ],
        },
        {
          name: 'facebook.gallery.detail',
          path: ':id',
          component: Gallery,
          props: { galleryType: constants.FACEBOOK },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Board Detail`,
          },
          children: [
            {
              name: 'facebook.galleries.edit',
              path: 'edit',
              component: FacebookGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - Edit`,
              },
            },
          ],
        },
      ],
    },
  ],
};
