import { computed, unref } from 'vue';
import {
  REPORTS,
  MULTI_CHANNEL_REPORT_TYPES,
  BENCHMARK_REPORTS,
  COMPETITIVE_REPORTS,
} from '@/app/dashboards/utils/reports.enum';
import { DATA_TYPES } from '@/app/dashboards/constants';

export function useReportType({ reportType } = {}) {
  const type = computed(() => unref(reportType));

  const isAdsTotalMetric = computed(() => {
    return type.value === REPORTS.ADS_TOTAL_METRIC.value;
  });

  const isMultiChannelReport = computed(() => MULTI_CHANNEL_REPORT_TYPES.includes(type.value));

  const isMultiMediaTypeReport = computed(
    () => type.value === REPORTS.MULTI_METRIC_MEDIA_TYPE.value,
  );

  const isBenchmarkReport = computed(() => BENCHMARK_REPORTS.includes(type.value));

  const isTotalMetricReportType = computed(() => {
    return type.value === REPORTS.TOTAL_METRIC.value;
  });

  const isTotalGroupedMetricReport = computed(
    () => type.value === REPORTS.TOTAL_GROUPED_METRIC.value,
  );

  const isAdsTotalGroupedMetricReport = computed(() => {
    return type.value === REPORTS.ADS_TOTAL_GROUPED_METRIC.value;
  });

  const isCompetitiveReport = computed(() => {
    return COMPETITIVE_REPORTS.includes(type.value);
  });

  const isMultiBrandReport = computed(() => {
    return type.value === REPORTS.MULTI_BRAND_METRIC.value;
  });

  function isBenchmark(item) {
    return item?.data_type === DATA_TYPES.BENCHMARK;
  }

  return {
    isAdsTotalMetric,
    isMultiChannelReport,
    isMultiMediaTypeReport,
    isBenchmarkReport,
    isTotalMetricReportType,
    isTotalGroupedMetricReport,
    isAdsTotalGroupedMetricReport,
    isCompetitiveReport,
    isMultiBrandReport,
    isBenchmark,
  };
}
