export const postTypes = Object.freeze({
  ORGANIC: 'organic',
  PROMOTED: 'promoted',
  ORGANIC_PROMOTED: 'organic-promoted',
  ADS: 'ads',
});

export const GALLERIES_DEFAULT_SORT = Object.freeze({
  label: 'Recently Published',
  value: 'CREATED',
});

export const DETAIL_DEFAULT_SORT = Object.freeze({
  label: 'Recently Published',
  value: 'DATE',
});

export const METRICS = Object.freeze({
  INSTAGRAM: {
    AVG_REACH: {
      label: 'Avg. Reach',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_AVG_REACH',
      detail: { attribute: 'avg_reach' },
      formatType: 'number',
      galleries: { attribute: 'instagramAvgReach' },
      info: 'TBD',
    },
    ORGANIC_COMMENTS: {
      label: 'Comments',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_ORGANIC_COMMENTS',
      detail: { attribute: 'comments_count' },
    },
    PAID_COMMENTS: {
      label: 'Comments',
      postType: postTypes.PROMOTED,
      value: 'INSTAGRAM_PAID_COMMENTS',
      detail: { attribute: 'paid_comments' },
    },
    TOTAL_COMMENTS: {
      label: 'Comments',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'INSTAGRAM_TOTAL_COMMENTS',
      detail: { attribute: 'total_comments' },
    },
    GALLERY_TOTAL_COMMENTS: {
      label: 'Comments',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_TOTAL_COMMENTS',
      formatType: 'number',
      galleries: { attribute: 'instagramTotalComments' },
      info: 'TBD',
    },
    EFFECTIVENESS: {
      label: 'Avg. Effectiveness',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_EFFECTIVENESS',
      detail: { attribute: 'effectiveness' },
      formatType: 'percent',
      galleries: { attribute: 'instagramAvgEffectiveness' },
      info: 'TBD',
    },
    ENGAGEMENT: {
      label: 'Avg. Eng. Rate (Followers)',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_ENGAGEMENT',
      detail: { attribute: 'avg_engagement' },
      formatType: 'percent',
      galleries: { attribute: 'instagramAvgEngagement' },
      info: 'TBD',
    },
    ENGAGEMENT_RATE: {
      label: 'Engagement Rate (Followers)',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_ENGAGEMENT_RATE',
      detail: { attribute: 'engagement' },
    },
    ORGANIC_IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
      detail: { attribute: 'impressions' },
    },
    PAID_IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.PROMOTED,
      value: 'INSTAGRAM_PAID_IMPRESSIONS',
      detail: { attribute: 'paid_impressions' },
    },
    TOTAL_IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
      detail: { attribute: 'total_impressions' },
    },
    ORGANIC_LIKES: {
      label: 'Likes',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_ORGANIC_LIKES',
      detail: { attribute: 'like_count' },
    },
    PAID_LIKES: {
      label: 'Likes',
      postType: postTypes.PROMOTED,
      value: 'INSTAGRAM_PAID_LIKES',
      detail: { attribute: 'paid_likes' },
    },
    TOTAL_LIKES: {
      label: 'Likes',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'INSTAGRAM_TOTAL_LIKES',
      detail: { attribute: 'total_likes' },
    },
    GALLERY_TOTAL_LIKES: {
      label: 'Likes',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_TOTAL_LIKES',
      formatType: 'number',
      galleries: { attribute: 'instagramTotalLikes' },
      info: 'TBD',
    },
    LIKESHOP_CLICKS: {
      label: 'LikeShop Clicks',
      postType: null,
      value: 'LIKESHOP_CLICKS',
      detail: { attribute: 'likeshop_clicks' },
    },
    REACH: {
      label: 'Reach',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_REACH',
      detail: { attribute: 'reach' },
    },
    REELS_SHARES: {
      label: 'Reels - Shares',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_SHARES',
      detail: { attribute: 'shares' },
    },
    SAVES: {
      label: 'Saves',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_SAVES',
      detail: { attribute: 'saved' },
    },
    TOTAL_ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'total_engagement' },
    },
    PAID_TOTAL_ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.PROMOTED,
      value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'paid_total_engagements' },
    },
    SUM_TOTAL_ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'sum_total_engagement' },
    },
    VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_VIDEO_VIEWS',
      detail: { attribute: 'video_views' },
    },
    PAID_VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.PROMOTED,
      value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
      detail: { attribute: 'paid_video_views' },
    },
    TOTAL_VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
      detail: { attribute: 'total_video_views' },
    },
  },
  INSTAGRAM_STORY: {
    REACH: {
      label: 'Reach',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_REACH',
      detail: { attribute: 'reach' },
    },
    IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
      detail: { attribute: 'views' },
    },
    COMPLETION_RATE: {
      label: 'Completion Rate',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
      detail: { attribute: 'completion_rate' },
    },
    EXIT_RATE: {
      label: 'Exit Rate',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
      detail: { attribute: 'exit_rate' },
    },
    EXITS: {
      label: 'Exits',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_EXITS',
      detail: { attribute: 'exits' },
    },
    LINK_CLICKS: {
      label: 'Link Clicks',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
      detail: { attribute: 'swipe_ups' },
    },
    REPLIES: {
      label: 'Replies',
      postType: postTypes.ORGANIC,
      value: 'INSTAGRAM_STORY_FRAME_REPLIES',
      detail: { attribute: 'replies' },
    },
    AVG_REACH: {
      label: 'Reach',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'IG_STORY_AVG_REACH',
      galleries: { attribute: 'igStoryAvgReach' },
      info: 'The average number of unique accounts who saw your story frames',
    },
    AVG_IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'IG_STORY_AVG_IMPRESSIONS',
      galleries: { attribute: 'igStoryAvgImpressions' },
      info: 'The number of times your story frames were displayed',
    },
    AVG_COMPLETION_RATE: {
      label: 'Completion Rate',
      postType: postTypes.ORGANIC,
      formatType: 'percent',
      value: 'IG_STORY_AVG_COMPLETION_RATE',
      galleries: { attribute: 'igStoryAvgCompletionRate' },
      info: 'The average completion rate of the story frames in this Board\n\nCompletion Rate = (Impression - Exits) / Impressions',
    },
    AVG_EXIT_RATE: {
      label: 'Exit Rate',
      postType: postTypes.ORGANIC,
      formatType: 'percent',
      value: 'IG_STORY_AVG_EXIT_RATE',
      galleries: { attribute: 'igStoryAvgExitRate' },
      info: 'The average exit rate of the story frames in this Board\n\nExit Rate = Exits / Impressions',
    },
    SWIPE_UPS: {
      label: 'Link Clicks',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'IG_STORY_SWIPE_UPS',
      galleries: { attribute: 'igStorySwipeUps' },
      info: 'The number of link clicks on story frames in this Board',
    },
  },
  TIKTOK: {
    TOTAL_VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      formatType: 'number',
      value: 'TIKTOK_TOTAL_VIDEO_VIEWS',
      galleries: { attribute: 'tiktokTotalVideoViews' },
      info: 'The number of times your posts in this Board were viewed',
    },
    AVG_VIDEO_VIEWS: {
      label: 'Avg. Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      formatType: 'number',
      value: 'TIKTOK_AVG_VIDEO_VIEWS',
      galleries: { attribute: 'tiktokAvgVideoViews' },
      info: 'The average number of video views for  posts in this Board\n\nAvg. Video Views = Video Views / Number of Media',
    },
    AVG_RETENTION_RATE: {
      label: 'Avg. Retention Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      formatType: 'percent',
      value: 'TIKTOK_AVG_RETENTION_RATE',
      galleries: { attribute: 'tiktokAvgRetentionRate' },
      info: 'The average percentage of your videos in this Board that your audience watched\n\nRetention Rate = Average Time Watched / Video Duration',
    },
    AVG_ENGAGEMENT_RATE: {
      label: 'Avg. Engagement Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      formatType: 'percent',
      value: 'TIKTOK_AVG_ENGAGEMENT_RATE',
      galleries: { attribute: 'tiktokAvgEngagementRate' },
      info: 'The average engagement rate for your posts in this Board\n\nEngagement Rate = Total Engagements / Reach',
    },
    VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_VIEWS',
      detail: { attribute: 'views' },
    },
    TOTAL_ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'total_engagements' },
    },
    EFFECTIVENESS: {
      label: 'Effectiveness',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_EFFECTIVENESS',
      detail: { attribute: 'effectiveness' },
    },
    ENGAGEMENT_RATE: {
      label: 'Engagement Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_ENGAGEMENT_RATE',
      detail: { attribute: 'engagement_rate' },
    },
    RETENTION_RATE: {
      label: 'Retention Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_AVERAGE_COMPLETION_RATE',
      detail: { attribute: 'average_completion_rate' },
    },
    FYP_VIEWS: {
      label: 'FYP Views %',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
      detail: { attribute: 'impressions_for_you_rate' },
    },
    IMPRESSIONS_PERSONAL_PROFILE_RATE: {
      label: 'Profile Views %',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
      detail: { attribute: 'impressions_personal_profile_rate' },
    },
    AVERAGE_TIME_WATCHED: {
      label: 'Avg. Time Watched',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_AVERAGE_TIME_WATCHED',
      detail: { attribute: 'average_time_watched' },
    },
    REACH: {
      label: 'Reach',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_REACH',
      detail: { attribute: 'reach' },
    },
    COMMENTS: {
      label: 'Comments',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_COMMENTS',
      detail: { attribute: 'comments' },
    },
    FULL_VIDEO_WATCHED_RATE: {
      label: 'Completion Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
      detail: { attribute: 'full_video_watched_rate' },
    },
    IMPRESSIONS_SEARCH_RATE: {
      label: 'Search Views %',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
      detail: { attribute: 'impressions_search_rate' },
    },
    SHARES: {
      label: 'Shares',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_SHARES',
      detail: { attribute: 'shares' },
    },
    IMPRESSIONS_SOUND_RATE: {
      label: 'Sound Views %',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
      detail: { attribute: 'impressions_sound_rate' },
    },
    IMPRESSIONS_FOLLOW_RATE: {
      label: 'Following Page Views %',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
      detail: { attribute: 'impressions_follow_rate' },
    },
    TOTAL_TIME_WATCHED: {
      label: 'Total Time Watched',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_TOTAL_TIME_WATCHED',
      detail: { attribute: 'total_time_watched' },
    },
    IMPRESSIONS_HASHTAG_RATE: {
      label: 'Hashtag Views %',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
      detail: { attribute: 'impressions_hashtag_rate' },
    },
    DURATION: {
      label: 'Video Duration',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_DURATION',
      detail: { attribute: 'duration' },
    },
    LIKES: {
      label: 'Likes',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_LIKES',
      detail: { attribute: 'likes' },
    },
    ENTERTAINMENT_SCORE: {
      label: 'Entertainment Score',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TIKTOK_ENTERTAINMENT_SCORE',
      detail: { attribute: 'entertainment_score' },
    },
  },
  FACEBOOK: {
    POST_CLICKS: {
      label: 'Post Clicks',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_POST_CLICKS',
      detail: { attribute: 'post_clicks' },
    },
    COMMENTS: {
      label: 'Comments',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_COMMENTS',
      detail: { attribute: 'comments' },
    },
    REACH: {
      label: 'Reach',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_REACH',
      detail: { attribute: 'reach' },
    },
    EFFECTIVENESS: {
      label: 'Effectiveness',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_EFFECTIVENESS',
      detail: { attribute: 'effectiveness' },
    },
    REACTIONS: {
      label: 'Reactions',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_REACTIONS',
      detail: { attribute: 'reactions' },
    },
    ENGAGEMENT_RATE: {
      label: 'Engagement Rate',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_ENGAGEMENT_RATE',
      detail: { attribute: 'engagement_rate' },
    },
    SHARES: {
      label: 'Shares',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_SHARES',
      detail: { attribute: 'shares' },
    },
    IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_IMPRESSIONS',
      detail: { attribute: 'impressions' },
    },
    TOTAL_ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'total_engagements' },
      formatType: 'number',
      galleries: { attribute: 'fbTotalEngagements' },
      info: 'The number of engagements your posts in this Board received\n\nEngagements = Reactions + Comments + Shares + Post Clicks + Post Clicks + Video View Clicks + Other Clicks',
    },
    LINK_CLICKS: {
      label: 'Link Clicks',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_LINK_CLICKS',
      detail: { attribute: 'link_clicks' },
    },
    VIDEO_COMPLETE_VIEWS: {
      label: 'Video Complete Views',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
      detail: { attribute: 'video_complete_views' },
    },
    OTHER_CLICKS: {
      label: 'Other Clicks',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_OTHER_CLICKS',
      detail: { attribute: 'other_clicks' },
    },
    VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_VIDEO_VIEWS',
      detail: { attribute: 'video_views' },
    },
    PHOTO_VIEW_CLICKS: {
      label: 'Photo View Clicks',
      postType: postTypes.ORGANIC,
      value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
      detail: { attribute: 'photo_view_clicks' },
    },
    AVG_ENGAGEMENT_RATE: {
      label: 'Avg. Engagement Rate',
      postType: postTypes.ORGANIC,
      formatType: 'percent',
      value: 'FACEBOOK_AVG_ENGAGEMENT_RATE',
      galleries: { attribute: 'fbAvgEngagementRate' },
      info: 'The average engagement rate for your posts in this Board\n\nEngagement Rate =  Engagements / Impressions',
    },
    AVG_EFFECTIVENESS: {
      label: 'Avg. Effectiveness',
      postType: postTypes.ORGANIC,
      formatType: 'percent',
      value: 'FACEBOOK_AVG_EFFECTIVENESS',
      galleries: { attribute: 'fbAvgEffectiveness' },
      info: 'TBD',
    },
    AVG_REACH: {
      label: 'Avg. Reach',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'FACEBOOK_AVG_REACH',
      galleries: { attribute: 'fbAvgReach' },
      info: 'The average reach for your posts in this Board\n\nAverage Reach = Reach / Number of Media',
    },
    TOTAL_IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'FACEBOOK_TOTAL_IMPRESSIONS',
      galleries: { attribute: 'fbTotalImpressions' },
      info: 'The number of times your posts in this Board were displayed',
    },
  },
  PINTEREST: {
    ENGAGEMENT_RATE: {
      label: 'Engagement Rate',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_ENGAGEMENT',
      detail: { attribute: 'engagement' },
    },
    IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_IMPRESSIONS',
      detail: { attribute: 'total_impressions' },
    },
    OUTBOUND_CLICKS: {
      label: 'Outbound Clicks',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_CLICKS',
      detail: { attribute: 'total_clicks' },
    },
    SAVES: {
      label: 'Saves',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_SAVES',
      detail: { attribute: 'total_saves' },
    },
    PIN_CLICKS: {
      label: 'Pin Clicks',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_CLOSEUPS',
      detail: { attribute: 'total_closeups' },
    },
    VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_VIDEO_VIEWS',
      detail: { attribute: 'total_video_views' },
    },
    AVERAGE_VIDEO_WATCH_TIME: {
      label: 'Average Watch Time',
      postType: postTypes.ORGANIC,
      value: 'PINTEREST_AVERAGE_VIDEO_WATCH_TIME',
      detail: { attribute: 'average_video_watch_time' },
    },
    TOTAL_ENGAGEMENT_RATE: {
      label: 'Avg. Engagement Rate',
      postType: postTypes.ORGANIC,
      formatType: 'percent',
      value: 'TOTAL_ENGAGEMENT',
      galleries: { attribute: 'pinterestAvgEngagementRate' },
      info: 'The average engagement rate for your Pins in this Board\n\nEngagement Rate = (Pin Clicks + Outbound Clicks + Saves) / Impressions',
    },
    TOTAL_CLICKS: {
      label: 'Outbound Clicks',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'TOTAL_CLICKS',
      galleries: { attribute: 'pinterestTotalClicks' },
      info: 'The total number of times people clicked and followed the links attached to your Pins to the destination URL',
    },
    TOTAL_SAVES: {
      label: 'Saves',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'TOTAL_SAVES',
      galleries: { attribute: 'pinterestTotalSaves' },
      info: 'The number of times your Pins in this Board were saved',
    },
    TOTAL_IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'TOTAL_IMPRESSIONS',
      galleries: { attribute: 'pinterestTotalImpressions' },
      info: 'The number of times your Pins in this Board were displayed',
    },
    TOTAL_PIN_CLICKS: {
      label: 'Pin Clicks',
      postType: postTypes.ORGANIC,
      formatType: 'number',
      value: 'TOTAL_CLOSEUPS',
      galleries: { attribute: 'pinterestTotalCloseups' },
      info: 'The number of times pins were clicked to see more details',
    },
  },
  YOUTUBE: {
    AVG_VIEW_PERCENTAGE: {
      label: 'Avg. % Viewed',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_AVG_VIEW_PERCENTAGE',
      detail: { attribute: 'avgViewPercentage' },
    },
    AVG_VIEW_DURATION: {
      label: 'Avg. View Duration',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_AVG_VIEW_DURATION',
      detail: { attribute: 'avgViewDuration' },
      formatType: 'duration',
      galleries: { attribute: 'youtubeAvgViewDuration' },
      info: 'The average amount of time viewers spent watching per video view',
    },
    CARD_CLICKS: {
      label: 'Card Clicks',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_CARD_CLICKS',
      detail: { attribute: 'cardClicks' },
    },
    CARD_CLICK_RATE: {
      label: 'Card Click Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_CARD_CLICK_RATE',
      detail: { attribute: 'cardClickRate' },
    },
    CARD_TEASER_CLICKS: {
      label: 'Card Teaser Clicks',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_CARD_TEASER_CLICKS',
      detail: { attribute: 'cardTeaserClicks' },
    },
    CARD_TEASER_CLICK_RATE: {
      label: 'Card Teaser Click Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_CARD_TEASER_CLICK_RATE',
      detail: { attribute: 'cardTeaserClickRate' },
    },
    CARD_TEASER_IMPRESSIONS: {
      label: 'Card Teaser Impressions',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_CARD_TEASER_IMPRESSIONS',
      detail: { attribute: 'cardTeaserImpressions' },
    },
    COMMENTS: {
      label: 'Comments',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_COMMENTS',
      detail: { attribute: 'comments' },
    },
    DISLIKES: {
      label: 'Dislikes',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_DISLIKES',
      detail: { attribute: 'dislikes' },
    },
    ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_ENGAGEMENTS',
      detail: { attribute: 'engagements' },
    },
    LIKES: {
      label: 'Likes',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_LIKES',
      detail: { attribute: 'likes' },
    },
    PREMIUM_VIEWS: {
      label: 'Premium Views',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_PREMIUM_VIEWS',
      detail: { attribute: 'premiumViews' },
    },
    PREMIUM_WATCH_TIME: {
      label: 'Premium Watch Time',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_EST_PREMIUM_SECONDS_WATCHED',
      detail: { attribute: 'estPremiumSecondsWatched' },
    },
    SUBSCRIBERS: {
      label: 'Subscribers',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_SUBSCRIBERS',
      detail: { attribute: 'subscribers' },
      formatType: 'number',
      galleries: { attribute: 'youtubeSubscribers' },
      info: 'TBD',
    },
    SHARES: {
      label: 'Shares',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_SHARES',
      detail: { attribute: 'shares' },
    },
    TOTAL_ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'engagements' },
      formatType: 'number',
      galleries: { attribute: 'youtubeTotalEngagements' },
      info: 'The total number of engagements your videos in this Board received',
    },
    VIDEOS_ADDED_TO_PLAYLIST: {
      label: 'Added to Playlist',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_VIDEOS_ADDED_TO_PLAYLIST',
      detail: { attribute: 'videosAddedToPlaylist' },
    },
    VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_VIEWS',
      detail: { attribute: 'views' },
    },
    TOTAL_VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_TOTAL_VIDEO_VIEWS',
      formatType: 'number',
      galleries: { attribute: 'youtubeTotalVideoViews' },
      info: 'The number of times your videos in this Board were viewed',
    },
    WATCH_TIME: {
      label: 'Watch Time',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_EST_SECONDS_WATCHED',
      detail: { attribute: 'estSecondsWatched' },
    },
    TOTAL_WATCH_TIME: {
      label: 'Watch Time',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'YOUTUBE_TOTAL_WATCH_TIME',
      formatType: 'duration',
      galleries: { attribute: 'youtubeTotalWatchTime' },
      info: 'The total amount of time that users watched your videos in this Board',
    },
  },
  TWITTER: {
    ENGAGEMENT_RATE: {
      label: 'Engagement Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_ENGAGEMENT_RATE',
      detail: { attribute: 'engagementRate' },
    },
    ENGAGEMENTS: {
      label: 'Engagements',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_TOTAL_ENGAGEMENTS',
      detail: { attribute: 'engagements' },
      formatType: 'number',
      galleries: { attribute: 'twitterTotalEngagements' },
      info: 'The number of engagements your posts in this Board received\n\nEngagements = Reposts + Replies + Likes + Link Clicks',
    },
    IMPRESSIONS: {
      label: 'Impressions',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_IMPRESSIONS',
      detail: { attribute: 'impressions' },
      formatType: 'number',
      galleries: { attribute: 'twitterImpressions' },
      info: 'The number of times your posts in this Board were displayed',
    },
    LINK_CLICKS: {
      label: 'Link Clicks',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_URL_CLICKS',
      detail: { attribute: 'urlClicks' },
    },
    PROFILE_CLICKS: {
      label: 'Profile Clicks',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_USER_PROFILE_CLICKS',
      detail: { attribute: 'userProfileClicks' },
    },
    QUOTE_POSTS: {
      label: 'Quote Posts',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_QUOTE_TWEETS',
      detail: { attribute: 'quoteTweets' },
    },
    REPLIES: {
      label: 'Replies',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_REPLIES',
      detail: { attribute: 'replies' },
    },
    REPOSTS: {
      label: 'Reposts',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_RETWEETS',
      detail: { attribute: 'retweets' },
      formatType: 'number',
      galleries: { attribute: 'twitterRetweets' },
      info: 'The number of times your posts in this Board were reposted',
    },
    TOTAL_REPOSTS: {
      label: 'Total Reposts',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_TOTAL_RETWEETS',
      detail: { attribute: 'totalRetweets' },
    },
    USER_FOLLOWS: {
      label: 'User Follows',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_FOLLOWS',
      detail: { attribute: 'follows' },
    },
    VIDEO_VIEWS: {
      label: 'Video Views',
      postType: postTypes.ORGANIC_PROMOTED,
      value: 'TWITTER_VIDEO_VIEWS',
      detail: { attribute: 'videoViews' },
    },
    AVG_ENGAGEMENT_RATE: {
      label: 'Avg. Engagement Rate',
      postType: postTypes.ORGANIC_PROMOTED,
      formatType: 'percent',
      value: 'TWITTER_AVG_ENGAGEMENT_RATE',
      galleries: { attribute: 'twitterAvgEngagementRate' },
      info: 'The average engagement rate for your posts in this Board\n\nEngagement Rate = (Total Engagements / Impressions)',
    },
    LIKES: {
      label: 'Likes',
      postType: postTypes.ORGANIC_PROMOTED,
      formatType: 'number',
      value: 'TWITTER_LIKES',
      galleries: { attribute: 'twitterLikes' },
      detail: { attribute: 'likes' },
      info: 'The number of times your posts in this Board were liked',
    },
  },
  LIBRARY: {
    CLICKS: {
      label: 'Clicks',
      value: 'CLICKS',
      detail: { attribute: 'clicks' },
    },
  },
});
