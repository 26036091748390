import { useRoute, useRouter } from 'vue-router';
import {
  convertPostFrom,
  convertPostTo,
  DUPLICATION_POST_TYPES,
  DUPLICATION_TYPES,
  duplicationOptions,
  postTypeByPlatform,
  duplicationOptionsGDS,
} from '@/utils/postDuplication';
import { useAuthStore } from '@/stores/auth';
import {
  PLATFORMS,
  mixpanelActions,
  postStatus,
  SCHEDULER_DRAWERS,
} from '@/app/scheduler/constants';
import { useTrackingStore } from '@/stores/tracking';
import enumTypes from '@/app/library/constants';
import { toolTips } from '@/config';
import { useNotificationStore } from '@/stores/notification';
import { useFlagStore } from '@/stores/flag';
import { useDrawer } from '@dashhudson/dashing-ui';
import { getPlatformPostTypeFromPost } from '@/app/scheduler/utils';
import { nextTick } from 'vue';

export function usePostDuplication() {
  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();
  const notificationStore = useNotificationStore();
  const flagStore = useFlagStore();

  const crossChannelPublishingFlag = flagStore.ready && flagStore.flags.crossChannelPublishing;

  function pdPostTypeByPlatform(platform, post) {
    return postTypeByPlatform(platform, post);
  }
  function pdDuplicationOptions(action) {
    return duplicationOptions(flagStore, action);
  }
  function pdDuplicationOptionsGDS(action, canDelete = false) {
    return duplicationOptionsGDS(action, canDelete);
  }

  async function pdDuplicatePostToBrand({ post, toBrand }) {
    const postDuplicationData = {
      duplicatedFrom: post.brand_id,
      duplicationType: DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_BRAND,
      duplicateBrandID: toBrand.id,
      duplicateBrandName: toBrand.name,
    };
    trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
    await router.push({
      name: route.name,
      params: { brandLabel: toBrand.label },
    });

    await nextTick();

    if (crossChannelPublishingFlag) {
      const { launchDrawer } = useDrawer();
      launchDrawer({
        name: SCHEDULER_DRAWERS.EDIT_POST_DRAWER,
        props: {
          duplicationDetails: {
            fromPostPlatform: post.platform,
            fromPostId: post.id,
          },
          initialPostType: getPlatformPostTypeFromPost(post),
        },
      });
    } else {
      await router.push({
        name: `scheduler.${post.platform}.posts`,
        params: {
          brandLabel: toBrand.label,
          id: 'new',
        },
        query: {
          duplicatePostId: post.id,
          duplicatePostPlatform: post.platform,
        },
      });
    }
  }

  function duplicateToBrandSubList(post) {
    return Object.values(authStore.identity?.brands || {})
      .map((toBrand) => {
        return toBrand.id !== post.brand_id
          ? {
              label: toBrand.name,
              action: pdDuplicatePostToBrand,
              actionParams: { post, toBrand },
              command: () => {
                pdDuplicatePostToBrand({ post, toBrand });
              },
            }
          : null;
      })
      .filter(Boolean);
  }

  function pdCanCrossBrandDuplicateMedia(media) {
    let canCrossBrand = true;
    media.forEach((mediaItem) => {
      if (![enumTypes.UPLOAD, enumTypes.EDITOR].includes(mediaItem.source)) {
        canCrossBrand = false;
      }
    });
    return canCrossBrand;
  }

  function pdCrossBrandDuplicationDisabled(post, media) {
    const isThreadedTweet = post?.platform === PLATFORMS.TWITTER && post?.replies?.length > 0;
    if (![postStatus.IMPORTED, postStatus.POSTED].includes(post?.status)) {
      return { isDisabled: true, tip: toolTips.crossBrandDisabledPostStatus };
    }
    if (isThreadedTweet) {
      return { isDisabled: true, tip: toolTips.crossBrandDisabledTweetThread };
    }
    if (!pdCanCrossBrandDuplicateMedia(media)) {
      return { isDisabled: true, tip: toolTips.crossBrandDisabledMedia };
    }

    return { isDisabled: false, tip: '' };
  }
  function pdDuplicatePostToChannel({ post, toType, fromType, replaceModal = true }) {
    const validTypes = Object.values(DUPLICATION_POST_TYPES);
    if (!validTypes.includes(toType) || !validTypes.includes(fromType)) {
      notificationStore.setToast({
        message: 'The specified platform does not yet have post duplication implemented.',
        type: 'error',
      });
    } else {
      const basePost = convertPostFrom(post, fromType);
      const newPost = convertPostTo(basePost, toType);
      if (crossChannelPublishingFlag) {
        const { launchDrawer } = useDrawer();
        const drawerProps =
          newPost.type === 'pinterestBulk'
            ? {
                mediaList: newPost?.data?.mediaList ?? [],
              }
            : {
                duplicationDetails: {
                  fromPostPlatform: post.platform,
                  fromPostId: post.id,
                  toPostType: toType,
                },
                initialPostType: getPlatformPostTypeFromPost(newPost.data.post),
              };

        launchDrawer({
          name:
            newPost.type === 'pinterestBulk'
              ? SCHEDULER_DRAWERS.BULK_PINTEREST_DRAWER
              : SCHEDULER_DRAWERS.EDIT_POST_DRAWER,
          props: drawerProps,
        });
      } else {
        const isPinterestBulk = newPost.type === 'pinterestBulk';
        const params = isPinterestBulk ? {} : { id: 'new' };
        const routeName = isPinterestBulk
          ? 'scheduler.pinterest.posts.new.bulk'
          : `scheduler.${newPost.type}.posts`;
        router.push({
          name: routeName,
          params,
          query: {
            duplicatePostId: post.id,
            duplicatePostPlatform: post.platform,
            toType,
            replaceModal,
          },
        });
      }
    }
  }

  function duplicateAction(post, toType) {
    const fromType = pdPostTypeByPlatform(post.platform, post);
    pdDuplicatePostToChannel({
      fromType,
      toType,
      post,
      replaceModal: false,
    });
  }

  return {
    pdDuplicatePostToBrand,
    pdDuplicatePostToChannel,
    pdCrossBrandDuplicationDisabled,
    duplicateToBrandSubList,
    pdPostTypeByPlatform,
    pdDuplicationOptions,
    pdDuplicationOptionsGDS,
    duplicateAction,
  };
}
