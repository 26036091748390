import dayjs from 'dayjs';
import {
  POST_VOLUME_GRAPH_OPTIONS,
  SENTIMENT_TREND_CHART_OPTIONS,
  SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS,
  SOCIAL_LISTENING_TOP_POST_SENTIMENT_CHIP_PROPS,
  TOPICS_QUERY_PARAM_MAP,
} from '@/app/socialListening/constants';
import { useAuthStore } from '@/stores/auth';
import { groupByPrimaryPercentBreakdown } from '@/app/socialListening/utils/graph-utils';
import { getSentimentKeys } from '@/app/socialListening/utils/metric-utils';
import { formatVideoDurationInSeconds } from '@/utils/formatters';
import { getUserTimezone } from '@/utils/timezone';
import sortBy from 'lodash/sortBy';

export function formatCustomSelectDropdownLabel(start, end, format) {
  const startLabel = dayjs(start).format(format);
  const endLabel = dayjs(end).format(format);
  return `${startLabel} - ${endLabel}`;
}

export function formatTopicQueryParamsFromSourceLink(query) {
  const authStore = useAuthStore();
  const tempFilters = {
    visualData: {
      includes: [],
      doesNotInclude: [],
    },
  };
  const sentiment = {};

  // format the values from query param to be used in the filters pinia store object
  [TOPICS_QUERY_PARAM_MAP.SOURCES, TOPICS_QUERY_PARAM_MAP.MEDIA_TYPES].forEach((key) => {
    if (query[key]) {
      tempFilters[key] = query[key];
    }
  });
  [
    TOPICS_QUERY_PARAM_MAP.POSITIVE_SENTIMENT,
    TOPICS_QUERY_PARAM_MAP.NEUTRAL_SENTIMENT,
    TOPICS_QUERY_PARAM_MAP.NEGATIVE_SENTIMENT,
  ].forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      sentiment[key] = query[key] === true;
    }
  });
  [TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_INCLUDE, TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_EXCLUDE].forEach(
    (key) => {
      if (query[key]) {
        if (key.includes('include') || key.includes('exclude')) {
          const visualData = query[key];
          const targetArray = key.includes('include')
            ? tempFilters.visualData.includes
            : tempFilters.visualData.doesNotInclude;

          visualData.forEach((e) => {
            const keyName = e.split(':')[0].includes(authStore.currentBrand?.id.toString())
              ? 'libraryMediaDetails'
              : 'sourceAndId';
            targetArray.push({ [keyName]: e });
          });
        }
      }
    },
  );

  if (
    !tempFilters.visualData?.includes?.length &&
    !tempFilters.visualData?.doesNotInclude?.length
  ) {
    delete tempFilters.visualData;
  }

  if (Object.keys(sentiment).length > 0) {
    Object.keys(sentiment).forEach((key) => {
      if (sentiment[key] === true) {
        tempFilters.sentiment = sentiment;
      }
    });
  }
  return tempFilters;
}

export function formatTopicQueryParamsVisualDataForSourceLink(queryParams) {
  const formattedVisualDataQueryParams = {
    includeVisualDataSourceAndId: [],
    excludeVisualDataSourceAndId: [],
  };

  [TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_INCLUDE, TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_EXCLUDE].forEach(
    (key) => {
      if (queryParams[key]) {
        if (key.includes('include') || key.includes('exclude')) {
          const sourceAndIds = queryParams[key];
          const targetArray = key.includes('include')
            ? formattedVisualDataQueryParams.includeVisualDataSourceAndId
            : formattedVisualDataQueryParams.excludeVisualDataSourceAndId;

          sourceAndIds.forEach((e) => {
            const keyName = Object.keys(e)[0];
            targetArray.push(e[keyName]);
          });
        }
      }
    },
  );
  return formattedVisualDataQueryParams;
}

export function getFormattedSentimentChartData(data, filters) {
  const datasets = [];
  const breakdownGroups = groupByPrimaryPercentBreakdown(
    data,
    POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.value,
    filters.sentiments.map((key) => key.toLowerCase()),
  );
  Object.entries(breakdownGroups).forEach(([label, dataList]) => {
    datasets.push({
      label,
      data: dataList.map((d) => {
        return { ...d, y: d.totalPosts.replace(',', '') };
      }),
      borderColor: SENTIMENT_TREND_CHART_OPTIONS.sentimentColors[label.toUpperCase()],
    });
  });
  return sortBy(
    datasets,
    (set) => SENTIMENT_TREND_CHART_OPTIONS.legendSort[set.label.toUpperCase()],
  );
}

/**
 * Formats the sentiment chip props for the media card.
 * @param {Object} sentiment - The sentiment object attached to the returned top post
 * @param {boolean} sentiment.isNegative - Whether the sentiment is negative
 * @param {boolean} sentiment.isNeutral - Whether the sentiment is neutral
 * @param {boolean} sentiment.isPositive - Whether the sentiment is positive
 * @param {boolean} sentiment.isQuestion - Whether the sentiment is a question
 *
 * @returns {{
 *  label: 'Positive',
 *  size: 'sm',
 *  badge: { severity: 'positive' },
 *}}
 */
export function formatSentimentChipProps(sentiment) {
  const mediaCardSentimentMetrics = {};
  if (sentiment) {
    // Only use sentiment keys and filter out isQuestion key
    const sentimentMetricKeys = getSentimentKeys(sentiment);
    sentimentMetricKeys.reduce((acc, sentimentKey) => {
      const sentimentMetric = SOCIAL_LISTENING_TOP_POST_SENTIMENT_CHIP_PROPS[sentimentKey];
      if (sentiment[sentimentKey]) {
        acc.label = sentimentMetric.label;
        acc.size = sentimentMetric.size;
        acc.badge = { severity: sentimentMetric.badge.severity };
      }
      return acc;
    }, mediaCardSentimentMetrics);
  }
  return mediaCardSentimentMetrics;
}

export function formatTopPostPostDate(postDate, formatYear = false) {
  const timezone = getUserTimezone();
  const formattedPostDate = dayjs.utc(postDate).tz(timezone);
  if (formatYear) {
    const isCurrYear = formattedPostDate.year() === dayjs().tz(timezone).year();
    return formattedPostDate.format(`MMM D,${isCurrYear ? '' : ' YYYY'} h:mm A`);
  }
  return formattedPostDate;
}

export function formatPostDataToMediaCardProps(post) {
  const duration = post.duration ?? post.videoDuration;
  return {
    src: post.urls.thumbs,
    sourceType: post.mediaType.toLowerCase(),
    platformMediaType: post.postType ?? post.mediaType,
    videoDuration:
      post.mediaType === SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.VIDEO.value
        ? formatVideoDurationInSeconds(duration, post.source)
        : undefined,
    avatar: post.sourceCreatorAvatar,
    title: post.sourceCreatorHandle,
    caption: post.caption,
    channel: post.source.toLowerCase(),
    metricItems: [
      {
        label: 'Engagements',
        type: 'Total',
        formatType: 'number',
        value: post.engagements,
      },
      {
        label: 'Sentiment',
        chip: formatSentimentChipProps(post.sentiment),
      },
    ],
    text:
      post.mediaType === SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.TEXT.value ? post.caption : undefined,
    postDate: formatTopPostPostDate(post.sourceCreatedAt),
    sourceId: post.sourceId,
    source: post.source,
    sizes: post.urls?.imageSizes ?? post.urls?.videoSizes,
  };
}

export function formatPostDataToLibraryCardProps(post) {
  const duration = post.duration ?? post.videoDuration;
  return {
    channel: post.source,
    sourceType: post.mediaType.toLowerCase(),
    platformMediaType: post.mediaType,
    src: post.urls?.thumbs,
    videoDuration:
      post.mediaType === SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.VIDEO.value
        ? formatVideoDurationInSeconds(duration, post.source)
        : undefined,
    sourceId: post.sourceId,
    source: post.source,
    sizes: post.urls?.imageSizes ?? post.urls?.videoSizes,
  };
}

export function getFormattedKeywordPostVolumeTimeSeriesChartData(breakdownGroups, chartConfig) {
  const data = [];

  breakdownGroups.totalPosts.forEach((entry) => {
    data.push({
      x: entry.x,
      y: entry.totalPosts,
    });
  });

  const label = 'totalPosts';
  const upperLabel = label.toUpperCase();
  const baseColor = chartConfig.colour[upperLabel];

  return {
    meters: [
      {
        label: 'Mentions',
        data,
        borderColor: baseColor,
      },
    ],
  };
}
