<template>
  <div ref="container" v-on-click-outside="closePopup" class="multi-select-filter">
    <div @click.prevent="togglePopup">
      <slot name="filterButton" :open="showPopup">
        <div
          class="filter-button"
          :class="{
            incomplete: warnNoSelection && !showPopup && !numberOfSelectedItems,
            disabled: disabled,
            'filter-border': border,
          }"
        >
          <Icon v-if="iconName" :name="iconName" :color="iconColor" small class="filter-icon" />
          <div class="filter-label">{{ label }}</div>
          <template
            v-if="descriptionBeforeConjunction && !showNumberedDescription && !hideDescription"
          >
            <div class="preposition">{{ preposition }}</div>
            <div class="description before-conjunction">
              {{ descriptionBeforeConjunction }}
            </div>
          </template>
          <template
            v-if="descriptionAfterConjunction && !showNumberedDescription && !hideDescription"
          >
            <div class="conjunction">{{ conjunction }}</div>
            <div class="description">
              {{ descriptionAfterConjunction }}
            </div>
          </template>
          <template
            v-if="numberOfSelectedItems && showNumberedDescription && !showFilteredPostsCount"
          >
            <div class="text-small text-link ml-0.5">({{ numberOfSelectedItems }})</div>
          </template>
          <template v-if="showFilteredPostsCount">
            <div class="text-small text-link ml-0.5">({{ filteredPostsCount }})</div>
          </template>
          <div v-if="showDropdownIcon && !newSelectedItems.length" class="ml-2 mt-2">
            <Icon name="chevronDown" xsmall :dir="showPopup ? 'down' : 'up'" />
          </div>
          <div v-if="clearable && newSelectedItems.length" class="reset-button" @click.stop="reset">
            <Icon class="close-icon" name="close" xxxsmall />
          </div>
        </div>
      </slot>
    </div>

    <transition name="slide">
      <div
        v-if="showPopup"
        class="filter-popup"
        :class="{ left: popupToTheLeft || forcePopupToLeft }"
        :style="{ width: popupWidth, 'z-index': zIndex, ...popupStyles }"
      >
        <Tabs
          v-if="items.length > 1"
          :tabs="treatedTabs"
          class="tabs"
          small
          :initial-tab-index="currentTabIndex"
          @on-change="tabChanged"
        />
        <div v-if="currentTab.items.length === 0" class="empty-tab">
          {{ currentTab.noItemsMessage }}
        </div>
        <template v-else>
          <TextInput
            v-if="!disableSearch"
            v-model="searchText"
            placeholder="Search"
            class="search-input"
            data-cy="multi-select-filter-search-input"
            round
            icon-name="closeCircleSolid"
            prepend-inner-icon="search"
            prepend-inner-icon-size="xsmall"
            clearable
          />
          <div v-if="isMultiSelect && showSelectAll" class="checkbox-container">
            <CheckBox
              class="checkbox"
              :value="numberOfSelectedItems > 0"
              label="Select All"
              :input-value="null"
              :use-indeterminate-style="
                numberOfSelectedItems > 0 && numberOfSelectedItems < selectableOptions.length
              "
              small
              bold-font
              passthrough-click-events
              supress-overflow-tooltip
              @click="toggleSelectAll"
            />
            <hr />
          </div>
          <div
            class="checkbox-container"
            :class="{
              searching: !!searchText,
              scrollable: !onlyScrollOnSearch || (onlyScrollOnSearch && !!searchText),
              'more-vertical-padding': moreVerticalPadding,
            }"
          >
            <template v-for="(option, i) in selectOptions">
              <hr v-if="option.isLine" :key="`line-${i}`" />
              <CheckBox
                v-else-if="isMultiSelect"
                :key="`checkbox-${option.value}-${i}`"
                class="checkbox"
                :class="{ indented: option.isIndented }"
                :value="option.isChecked || option.isPartiallyChecked"
                :label="itemLabel(option)"
                :input-value="option.value"
                :use-indeterminate-style="option.isPartiallyChecked && !option.isChecked"
                :disabled="option.disabled"
                :disabled-tooltip="option.disabledTooltip"
                small
                bold-font
                passthrough-click-events
                supress-overflow-tooltip
                @click="option.toggle"
              >
                <slot name="checkboxContent" :item="option" :i="i" />
              </CheckBox>
              <div
                v-else
                :key="`radio-item-${option.value}`"
                class="radio-row flex cursor-pointer flex-nowrap pb-2 pt-2"
                :class="{
                  'px-6': !noBaseIndent,
                }"
                @click="option.toggle"
              >
                <input
                  style="height: 16px; width: 16px"
                  :value="option.value"
                  :checked="option.isChecked"
                  :disabled="option.disabled"
                  :name="option.name"
                  type="radio"
                />
                <span
                  class="radio"
                  :class="{
                    indented: option.isIndented,
                    hidden: hideRadio,
                  }"
                />
                <slot name="radioContent" :item="option" :i="i" />
              </div>
            </template>
            <div v-if="selectOptions.length === 0" class="no-search-results-message">
              {{ noResultsMessage }}
            </div>
          </div>
        </template>
        <div v-if="!disableControls" class="button-container">
          <Button
            class="clear-button"
            :class="{ 'clear-button-active': newSelectedItems.length }"
            dismiss
            small
            :disabled="newSelectedItems.length === 0"
            @click="clear"
          >
            Clear
          </Button>
          <Button
            v-if="!applyOnSelection"
            dismiss
            small
            class="see-results-button"
            :disabled="!selectedItemsChanged"
            @click="closePopup"
          >
            {{ confirmButtonText }}
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { vOnClickOutside } from '@vueuse/components';
import isEqual from 'lodash/isEqual';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import TextInput from '@/components/foundation/form/TextInput.vue';
import Tabs from '@/components/Tabs.vue';
import { colours } from '@/ux/colours';

export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MultiSelectFilter',
  components: {
    Icon,
    Button,
    TextInput,
    CheckBox,
    Tabs,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    isMultiSelect: { type: Boolean, default: true },
    label: { type: String, default: '' },
    iconName: { type: String, default: null },
    items: { type: Array, required: true },
    selectedItems: { type: Array, required: true },
    conjunction: { type: String, default: '' },
    preposition: { type: String, default: '' },
    showDropdownIcon: { type: Boolean, default: false },
    showNumberedDescription: { type: Boolean, default: false },
    showFilteredPostsCount: { type: Boolean, default: false },
    filteredPostsCount: { type: Number, default: null },
    hideDescription: { type: Boolean, default: false },
    hideRadio: { type: Boolean, default: false },
    noBaseIndent: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    forcePopupToLeft: { type: Boolean, default: false },
    applyOnSelection: { type: Boolean, default: false },
    maxInitialItems: { type: Number, default: null },
    onlyScrollOnSearch: { type: Boolean, default: false },
    noResultsMessage: { type: String, default: 'No results.' },
    popupStyles: { type: Object, default: () => ({}) },
    warnNoSelection: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
    confirmButtonText: { type: String, default: 'Save' },
    popupWidth: { type: String, default: '300px' },
    showSelectAll: { type: Boolean, default: false },
    zIndex: { type: String, default: 'var(--z-index-dropdown)' },
    moreVerticalPadding: { type: Boolean, default: false },
    disableSearch: { type: Boolean, default: false },
    disableControls: { type: Boolean, default: false },
    eventTracker: { type: Object, default: null },
    clearable: { type: Boolean, default: true },
    requireSelection: { type: Boolean, default: false },
  },
  emits: ['update:selected-items', 'update:current-tab', 'update:show-popup'],
  data() {
    return {
      searchText: '',
      newSelectedItems: [],
      showPopup: false,
      removeResizeListener: () => {},
      popupToTheLeft: false,
      currentTabIndex: 0,
    };
  },
  computed: {
    iconColor() {
      return colours.TEXT.TEXT_SECONDARY;
    },
    currentTab() {
      return this.items[this.currentTabIndex];
    },
    descriptionBeforeConjunction() {
      if (this.areAllSelected) {
        return `All ${this.currentTab.name}`;
      }
      const itemsBeforeConjunction =
        this.condensedNewSelectedItemsLabels.length > 1
          ? this.condensedNewSelectedItemsLabels.slice(0, -1)
          : this.condensedNewSelectedItemsLabels;
      return `${itemsBeforeConjunction.join(', ')}${
        this.condensedNewSelectedItemsLabels.length > 2 ? ',' : ''
      }`;
    },
    descriptionAfterConjunction() {
      return this.condensedNewSelectedItemsLabels.length > 1 && !this.areAllSelected
        ? this.condensedNewSelectedItemsLabels[this.condensedNewSelectedItemsLabels.length - 1]
        : '';
    },
    categories() {
      return this.currentTab.items
        .filter((item) => item.type === 'category')
        .reduce((acc, c) => ({ ...acc, [c.value]: c.items }), {});
    },
    selectedCategoriesByItem() {
      return this.selectedCategories
        .flatMap((category) => this.categories[category].map((item) => [category, item]))
        .reduce(
          (acc, [name, item]) => ({
            ...acc,
            [item.value]: [...new Set([...(acc[item.value] ?? []), name])],
          }),
          {},
        );
    },
    selectedCategories() {
      return Object.keys(this.categories).filter((category) =>
        this.categories[category].every(this.isChecked.bind(this)),
      );
    },
    condensedNewSelectedItems() {
      let categoriesAlreadyIncluded = [];
      let condensedNewSelectedItems = [];
      [...this.newSelectedItems].reverse().forEach((item) => {
        const categories = this.selectedCategoriesByItem[item];
        if (categories) {
          const categoriesNotIncluded = categories.filter(
            (category) => !categoriesAlreadyIncluded.includes(category),
          );
          condensedNewSelectedItems = condensedNewSelectedItems.concat(categoriesNotIncluded);
          categoriesAlreadyIncluded = [...categoriesAlreadyIncluded, ...categoriesNotIncluded];
        } else {
          condensedNewSelectedItems.push(item);
        }
      });
      return condensedNewSelectedItems.reverse();
    },
    flatItems() {
      return this.currentTab.items
        ?.map((item) => (item.type === 'category' ? [item, ...item.items] : item))
        .flat();
    },
    condensedNewSelectedItemsLabels() {
      return this.condensedNewSelectedItems.map((selectedItem) =>
        this.shortItemLabel(this.flatItems.find((item) => item.value === selectedItem)),
      );
    },
    selectedItemsChanged() {
      return !isEqual(this.selectedItems, this.newSelectedItems);
    },
    selectOptions() {
      const check = ({ value }) => {
        this.newSelectedItems = this.isMultiSelect
          ? [...new Set([...this.newSelectedItems, value])]
          : [value];
        if (this.applyOnSelection) {
          this.closePopup();
        }
      };

      const uncheck = ({ value }) => {
        if (this.requireSelection && this.newSelectedItems.length === 1) {
          return;
        }
        this.newSelectedItems = this.newSelectedItems.filter(
          (selectedItem) => selectedItem !== value,
        );
      };

      const matchesSearch = (item) =>
        !this.searchText ||
        (!item.hideInSearchResults &&
          (item.label?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            item.items?.some(matchesSearch)));

      const singleItem = (isIndented) => (item) => ({
        label: this.itemLabel(item),
        value: item.value,
        isIndented,
        isChecked: this.isChecked(item),
        toggle: () => (this.isChecked(item) ? uncheck : check)(item),
        ...item,
      });

      const nestedCheckboxes = ({ label, value, items }) => [
        {
          label,
          value,
          isChecked: items.every((subItem) => this.newSelectedItems.includes(subItem.value)),
          isPartiallyChecked: items.some((subItem) =>
            this.newSelectedItems.includes(subItem.value),
          ),
          toggle: () =>
            items.forEach(items.every((subItem) => this.isChecked(subItem)) ? uncheck : check),
        },
        ...items.filter(matchesSearch).map(singleItem(true)),
      ];

      const maxVisibleItems =
        !this.searchText && this.maxInitialItems != null
          ? this.maxInitialItems
          : this.flatItems.length;

      return this.currentTab.items
        .filter(matchesSearch)
        .flatMap((item) => {
          switch (item.type) {
            case 'item':
              return [singleItem(false)(item)];
            case 'category':
              return nestedCheckboxes(item);
            case 'line':
              return { isLine: true };
            default:
              throw new Error(`Item has invalid type "${item.type}"!`);
          }
        })
        .slice(0, maxVisibleItems);
    },
    selectableOptions() {
      return this.selectOptions.filter((item) => !item.disabled);
    },
    numberOfSelectedItems() {
      return this.newSelectedItems.length;
    },
    treatedTabs() {
      return this.items.map((tab, i) => ({
        ...tab,
        disabled: this.currentTabIndex !== i && this.newSelectedItems.length > 0,
      }));
    },
    areAllSelected() {
      return (
        this.numberOfSelectedItems > 0 &&
        this.currentTab.items.reduce((acc, c) => acc + (c.items?.length ?? 1), 0) ===
          this.numberOfSelectedItems
      );
    },
  },
  watch: {
    showPopup(to) {
      this.$emit('update:show-popup', to);
      if (!to) {
        if (this.selectedItemsChanged) {
          this.searchText = '';
          this.$emit('update:selected-items', this.newSelectedItems);
          this.newSelectedItems = this.selectedItems;
          this.$emit('update:current-tab', this.currentTab.name);
        }
      }
    },
    selectedItems: {
      immediate: true,
      handler(to) {
        if (!this.showPopup) {
          this.newSelectedItems = [...to];
        }
      },
    },
  },
  mounted() {
    this.installResizeListener();
  },
  unmounted() {
    this.removeResizeListener();
  },
  updated() {
    this.handleResize();
  },
  methods: {
    installResizeListener() {
      const handleResize = () => this.handleResize();
      window.addEventListener('resize', handleResize);
      handleResize();
      this.removeResizeListener = () => {
        window.removeEventListener('resize', handleResize);
      };
    },
    handleResize() {
      const spaceToTheRight = window.innerWidth - this.$refs.container.getBoundingClientRect().left;
      this.popupToTheLeft = spaceToTheRight < 310;
    },
    isChecked({ value }) {
      return this.newSelectedItems.includes(value);
    },
    togglePopup() {
      if (this.disabled) {
        return;
      }
      this.showPopup = !this.showPopup;
      if (this.eventTracker && this.showPopup) {
        const { tracker, filterName } = this.eventTracker;
        tracker.filters('Clicked', filterName, this.selectedItems);
      }
    },
    closePopup() {
      this.showPopup = false;
    },
    clear() {
      this.newSelectedItems = [];
      if (this.applyOnSelection) {
        this.closePopup();
      }
    },
    reset() {
      if (this.disabled) {
        return;
      }
      this.newSelectedItems = [];
      this.showPopup = false;
      if (this.selectedItemsChanged) {
        this.$emit('update:selected-items', []);
      }
    },
    itemLabel(item) {
      return item?.label ?? item?.value ?? item;
    },
    shortItemLabel(item) {
      return item?.pillLabel ?? this.itemLabel(item);
    },
    tabChanged(to) {
      this.currentTabIndex = to;
    },
    toggleSelectAll() {
      if (this.newSelectedItems.length === this.selectableOptions.length) {
        this.newSelectedItems = [];
      } else {
        this.newSelectedItems = this.selectableOptions.map((item) => item.value);
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.multi-select-filter {
  position: relative;
  min-width: 0;

  .filter-button {
    color: var(--text-primary);
    font-size: var(--space-14);
    background-color: white;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 0 var(--space-12);
    height: var(--space-32);
    border-radius: var(--space-16);
    transition: var(--transition-all);
    max-width: 45rem;

    &.filter-border {
      border: 1px solid var(--border);
    }

    &.incomplete {
      background: var(--error-100);
    }

    &:not(.disabled):hover {
      box-shadow: var(--shadow-1);
      transform: var(--hover-move);
    }

    &.disabled {
      cursor: default;
    }

    .filter-icon {
      margin-right: var(--space-8);
    }

    .filter-label {
      margin-right: var(--space-2);
    }

    .preposition,
    .conjunction {
      color: var(--text-secondary);
      margin: 0 var(--space-2);
    }

    .description {
      color: var(--action-500);
      margin: 0 var(--space-2);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.before-conjunction {
        flex: 1;
        min-width: 0;
      }
    }

    .reset-button {
      margin-left: var(--space-8);
    }
  }

  .filter-popup {
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    position: absolute;
    background-color: var(--background-0);
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-4);
    top: var(--space-40);
    left: 0;

    &.left {
      left: unset;
      right: 0;
    }

    .empty-tab {
      border: 2px dashed var(--border);
      border-radius: var(--round-corner-small);
      padding: var(--space-10);
      margin: var(--space-20);
      font-size: var(--x13);
    }

    .tab-container {
      text-align: center;
      padding-top: var(--space-16);
      justify-content: center;
    }

    .search-input {
      margin: 0;
      padding: var(--space-16) var(--space-16) 0 var(--space-16);
    }

    .checkbox-container {
      padding: var(--space-6) var(--space-26) 0 var(--space-26);
      flex: 1;

      &.more-vertical-padding {
        padding-bottom: var(--space-6);
      }

      &.scrollable {
        overflow-y: auto;

        &:not(.more-vertical-padding) {
          padding-top: unset;
        }
      }

      .checkbox {
        margin: var(--space-10) 0;

        &.indented {
          margin-left: var(--space-24);
        }
      }

      .no-search-results-message {
        padding: var(--space-8) 0;
        font-size: var(--x14);
        color: var(--text-secondary);
      }
    }

    .radio-row {
      width: 100%;

      /* Custom radio buttons */

      /* Hide the default radio button */
      input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }

      /* Custom radio button */
      span.radio {
        position: relative;
        height: var(--space-16);
        width: var(--space-16);
        background: var(--background-0);
        border-radius: 50%;
        border: 2px solid #ddd;
        margin-top: 0.12rem;

        &.indented {
          margin-left: var(--space-24);
        }
      }

      input:checked {
        ~ span.radio {
          background: var(--action-500);
          border: 2px solid var(--action-500);
        }

        &:disabled ~ span.radio {
          background: var(--background-500);
          border: 2px solid var(--background-500);
        }
      }

      /* White circle inside the radio */
      span.radio::after {
        content: '';
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--background-0);
      }

      input:checked ~ span.radio::after {
        display: block;
      }

      p {
        display: inline;
        font-size: var(--x14);
        margin-left: var(--space-8);
      }

      .disabled {
        cursor: auto;
      }
    }

    .radio-row:hover {
      span.radio {
        background-color: var(--background-300);
      }
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      background-color: var(--background-300);
      padding: var(--space-4) var(--space-12);
      border-radius: 0 0 var(--round-corner) var(--round-corner);

      .clear-button {
        color: var(--text-primary);
      }

      .clear-button-active:hover {
        cursor: pointer;
      }

      .see-results-button {
        color: var(--action-500);
      }
    }
  }
}
</style>
