import { computed } from 'vue';
import { useIdentityStore } from '@/stores/identity';
import { competitiveRouteNames } from '@/app/competitive/constants';
import { BRAND } from '@/models/auth/permissions.enum';

export function usePrimaryNavigationMenu() {
  const identityStore = useIdentityStore();
  const currentBrandLabel = computed(() => identityStore.currentBrand?.label);
  const brandCanAccessCreators = computed(() => {
    return identityStore.guard(BRAND.CREATORS.CAN_ACCESS_CREATORS);
  });
  const brandCanAccessRelationships = computed(() => {
    return identityStore.guard(BRAND.INSTAGRAM.CAN_ACCESS_RELATIONSHIPS);
  });
  const brandCanAccessRelationshipsOnly = computed(() => {
    return !brandCanAccessCreators.value && brandCanAccessRelationships.value;
  });

  const primaryNavigationItems = computed(() => {
    return [
      {
        backgroundClass: 'dash-gradient-bg-nav-pink',
        icon: 'dashboards',
        label: 'Dashboards',
        link: 'dashboards',
        name: 'dashboards',
        visible: true,
        routeHasBrandLabel: false,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-green',
        icon: 'analytics',
        label: 'Analytics',
        link: 'instagram',
        name: 'analytics',
        visible: true,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-orange',
        icon: 'creators',
        label: 'Relationships',
        link: 'relationships',
        name: 'relationships',
        visible: brandCanAccessRelationshipsOnly.value,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-blue',
        icon: 'creators',
        label: 'Creators',
        link: 'creators',
        name: 'creators',
        visible: !brandCanAccessRelationshipsOnly.value,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-purple',
        icon: 'campaigns',
        label: 'Campaigns',
        link: 'campaigns',
        name: 'campaigns',
        visible: true,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-pink',
        icon: 'community',
        label: 'Community',
        link: 'community',
        name: 'community',
        visible: true,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-green',
        icon: 'competitors',
        label: 'Competitors',
        link: competitiveRouteNames.instagram.main,
        name: 'competitors',
        visible: true,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-orange',
        icon: 'publishing',
        label: 'Scheduler',
        link: 'scheduler',
        name: 'scheduler',
        visible: true,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-blue',
        icon: 'library',
        label: 'Library',
        link: 'library',
        name: 'library',
        visible: true,
        routeHasBrandLabel: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-purple',
        icon: 'listening',
        label: 'Listening',
        link: 'socialListening',
        name: 'listening',
        visible: true,
        routeHasBrandLabel: true,
      },
    ];
  });

  const visibleNavigationItems = computed(() => {
    return primaryNavigationItems.value
      .filter((platform) => platform.visible)
      .map((platform) => {
        const to = { name: platform.link };
        if (platform.routeHasBrandLabel) {
          to.params = { brandLabel: currentBrandLabel.value };
        }
        return {
          to,
          ...platform,
        };
      });
  });

  return {
    visibleNavigationItems,
  };
}
