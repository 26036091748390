import {
  creatorPerformanceMetricTooltips,
  creatorSource,
  instagramFilterInputLimit,
  instagramPostInsightsMetricTooltips,
  instagramStoryInsightsMetricTooltips,
  inviteStatusDetails,
  metricDetails,
  tikTokAccountMetricTooltips,
  tikTokAccountPerformanceMetricTooltips,
  tikTokCreatorMetricTooltips,
  tiktokPostInsightsMetricTooltips,
} from '@/app/creators/constants';
import { visualSources } from '@/app/library/constants';
import { POPULAR_FILE_EXTENSIONS } from '@/constants';
import { SENTIMENTS } from '@/models/sentiment.enum';
import { getCurrentDate } from '@/utils';
import { getCountryName } from '@/utils/countries';
import { base64ToBinaryArray } from '@/utils/gmailApi';
import { engagementRating } from '@/utils/vision';
import format from 'date-fns/format';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';

const FORWARD_MESSAGE_HEADER = '---------- Forwarded message ---------';

export function formatBirthday({ day, month, year }) {
  if (!day && !month && !year) return '-';
  let str = '';
  if (month) {
    str += new Intl.DateTimeFormat('en-US', { month: 'long', timeZone: 'UTC' }).format(
      Date.UTC(2021, month - 1),
    );
  }
  if (day) {
    str += ` ${day}`;
    if (year) {
      return `${str}, ${year}`;
    }
  }
  if (year) {
    return `${str} ${year}`;
  }
  return str;
}

export function formatAddress({ country, region, city, mailingAddress, postalCode }) {
  if (!country && !region && !city && !mailingAddress && !postalCode) return '-';
  const values = [mailingAddress, city, region, postalCode];
  if (country) values.push(getCountryName(country));
  return values.filter((value) => value).join(', ');
}

export function checkCreatorDetailsExist(creator) {
  if (!creator) return false;

  return Object.values({
    creatorName: creator.creatorName,
    email: creator.email,
    notes: creator.notes,
    ...creator.birthday,
    ...creator.address,
    ...creator.phone,
  }).some((value) => value);
}

export function replaceEmptyStringsWithNull(obj) {
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '') {
        obj[key] = null;
      } else if (typeof obj[key] === 'object') {
        replaceEmptyStringsWithNull(obj[key]);
      }
    });
  }
  return obj;
}

export function diffCreatorDetails(originalDetails, updatedDetails) {
  return Object.keys({ ...originalDetails, ...updatedDetails }).reduce((diff, key) => {
    const originalValue = originalDetails[key];
    const updatedValue = updatedDetails[key];
    if (!Object.is(originalValue, updatedValue)) {
      if (isPlainObject(originalValue) && isPlainObject(updatedValue)) {
        const nestedDiff = diffCreatorDetails(originalValue, updatedValue);
        if (Object.keys(nestedDiff).length) {
          diff[key] = { ...originalValue, ...nestedDiff };
        }
      } else {
        diff[key] = updatedValue;
      }
    }
    return diff;
  }, {});
}

export function findChangedKeys(originalDetails, updatedDetails, parentKey = '') {
  const nonCustomFieldKeys = [];
  const customFieldKeys = [];

  Object.keys({ ...originalDetails, ...updatedDetails }).forEach((key) => {
    const originalValue = originalDetails[key];
    const updatedValue = updatedDetails[key];
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (updatedValue === undefined) {
      return;
    }

    if (key === 'customFields' && Array.isArray(originalValue) && Array.isArray(updatedValue)) {
      const updatedFieldKeys = new Set();
      const originalMap = Object.fromEntries(originalValue.map((item) => [item.name, item]));
      const updatedMap = Object.fromEntries(updatedValue.map((item) => [item.name, item]));

      Object.keys({ ...originalMap, ...updatedMap }).forEach((fieldKey) => {
        const originalItem = originalMap[fieldKey];
        const updatedItem = updatedMap[fieldKey];
        if (!originalItem || !updatedItem || originalItem.value !== updatedItem.value) {
          updatedFieldKeys.add(fieldKey);
        }
      });

      if (updatedFieldKeys.size > 0) {
        nonCustomFieldKeys.push('Custom');
        customFieldKeys.push(...updatedFieldKeys);
      }
      return;
    }

    if (!Object.is(originalValue, updatedValue)) {
      if (isPlainObject(originalValue) && isPlainObject(updatedValue)) {
        const { nonCustomFieldKeys: nestedNonCustom, customFieldKeys: nestedCustom } =
          findChangedKeys(originalValue, updatedValue, fullKey);
        nonCustomFieldKeys.push(...nestedNonCustom);
        customFieldKeys.push(...nestedCustom);
      } else {
        nonCustomFieldKeys.push(fullKey);
      }
    }
  });

  return { nonCustomFieldKeys, customFieldKeys };
}

export function cleanCustomFields(creatorDetails) {
  let customFields = cloneDeep(creatorDetails.customFields);

  customFields = customFields?.filter((customField) => customField.value);

  customFields?.forEach((customField) => {
    delete customField.name;
    delete customField.showInRequestForm;
  });

  return customFields;
}

export function mapGlobalCustomFieldsToCreator(creator, globalCustomFields) {
  return globalCustomFields.value?.map((globalCustomField) => {
    const creatorCustomField = creator?.customFields?.find(
      (item) => item.id === globalCustomField.id,
    );
    return {
      ...globalCustomField,
      value: creatorCustomField?.value,
    };
  });
}

export function mapApiResponseToForm(creatorDetails, globalCustomFields) {
  if (!creatorDetails) {
    return {
      phone: {
        countryCode: '',
        nationalNumber: '',
      },
      customFields: globalCustomFields,
    };
  }

  const customFields = mapGlobalCustomFieldsToCreator(creatorDetails, globalCustomFields);

  return {
    creatorName: creatorDetails.creatorName,
    email: creatorDetails.email,
    notes: creatorDetails.notes,
    phone: {
      nationalNumber: creatorDetails.phone?.nationalNumber || '',
    },
    address: creatorDetails.address,
    birthday: creatorDetails.birthday,
    customFields,
  };
}

export function formatDate(timestamp) {
  let date;

  // If it's a string with 10 digits, assume it's a unix timestamp in seconds.
  if (typeof timestamp === 'string' && /^\d{10}$/.test(timestamp)) {
    date = new Date(parseInt(timestamp, 10) * 1000);
  } else {
    date = new Date(timestamp);
  }

  return format(date, 'MMM dd, hh:mm a');
}

export function formatTikTokVacationDate(datetime) {
  const date = new Date(datetime);

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function getMetricFormatAndPopover(metricFormat, metricType, metricValue) {
  if (!metricFormat || !metricType) return null;
  let formatOptions = {};
  let popover = {};

  if (metricFormat === 'number' || metricFormat === 'currency') {
    if (!metricValue || metricValue === '-') return null;
    if (metricValue < 1) formatOptions = { maximumFractionDigits: 1 };
    else if (metricValue >= 1 && metricValue < 1000000) {
      formatOptions = { maximumFractionDigits: 0 };
    } else if (metricValue >= 1000000) {
      formatOptions = {
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short',
      };
      popover = {
        title: metricValue,
      };
    }
    return { formatOptions, popover };
  }
  if (metricType === 'Average' || metricFormat === 'percent') {
    formatOptions = { maximumFractionDigits: 2 };
  }
  return { formatOptions, popover };
}

export function getInstagramAccountPerformance(creator) {
  return [
    {
      title: 'Followers',
      value: creator?.rollingAccountMetrics.followers,
      tooltip: creatorPerformanceMetricTooltips.FOLLOWERS,
      format: 'number',
      type: 'Total',
    },
    {
      title: 'Avg. Eng. Rate',
      value: creator?.rollingAccountMetrics.avgEngagementRate,
      tooltip: creatorPerformanceMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. EMV',
      value: creator?.rollingAccountMetrics.avgEmv,
      tooltip: creatorPerformanceMetricTooltips.TOTAL_EMV,
      format: 'currency',
      type: 'Total',
    },
    {
      title: 'Avg. Engagements',
      value: creator?.rollingAccountMetrics.avgEngagements,
      tooltip: creatorPerformanceMetricTooltips.AVG_ENGAGEMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Effectiveness',
      value: creator?.rollingAccountMetrics.avgEffectiveness,
      tooltip: creatorPerformanceMetricTooltips.AVG_EFFECTIVENESS,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Weekly Posts',
      value: creator?.rollingAccountMetrics.avgWeeklyPosts,
      tooltip: creatorPerformanceMetricTooltips.AVG_WEEKLY_POSTS,
      format: 'number',
      type: 'Average',
    },
  ];
}

export function getInstagramAccountMetrics(account) {
  return [
    {
      title: 'Followers',
      value: account?.followers,
      tooltip: creatorPerformanceMetricTooltips.FOLLOWERS,
      format: 'number',
      type: 'Total',
    },
    {
      title: 'Avg. Eng. Rate',
      value: account?.instagram?.engagementRate,
      tooltip: creatorPerformanceMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Engagements',
      value: account?.instagram?.engagements,
      tooltip: creatorPerformanceMetricTooltips.AVG_ENGAGEMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Effectiveness',
      value: account?.avgEffectivenessRate,
      tooltip: creatorPerformanceMetricTooltips.AVG_EFFECTIVENESS,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Estimated Reach',
      value: account?.avgEstimatedReach,
      tooltip: creatorPerformanceMetricTooltips.AVG_ESTIMATED_REACH,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Weekly Posts',
      value: account?.avgPostsPerWeek,
      tooltip: creatorPerformanceMetricTooltips.AVG_WEEKLY_POSTS,
      format: 'number',
      type: 'Average',
    },
  ];
}

export function getTikTokAccountMetrics(account) {
  return [
    {
      title: 'Followers',
      value: account?.followers,
      tooltip: tikTokAccountMetricTooltips.FOLLOWERS,
      format: 'number',
      type: 'Total',
    },
    {
      title: 'Avg. Eng. Rate',
      value: account?.avgEngagementRate,
      tooltip: tikTokAccountMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Engagements',
      value: account?.avgEngagements,
      tooltip: tikTokAccountMetricTooltips.AVG_ENGAGEMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Video Views',
      value: account?.avgVideoViews,
      tooltip: tikTokAccountMetricTooltips.AVG_VIDEO_VIEWS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Comments',
      value: account?.avgComments,
      tooltip: tikTokAccountMetricTooltips.AVG_COMMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Weekly Posts',
      value: account?.avgWeeklyPosts,
      tooltip: tikTokAccountMetricTooltips.AVG_WEEKLY_POSTS,
      format: 'number',
      type: 'Average',
    },
  ];
}

export function getTikTokAccountPerformanceMetrics(account, hasMultiChannels) {
  const isConnected = account?.invitationStatus === inviteStatusDetails.ACCEPTED.value;
  const isInTTCM = account?.ttcmStatus === 'IN';

  const notConnectedInTTCMMetrics = [
    'Avg. Engagements',
    'Avg. Video Views',
    'Avg. Weekly Posts',
    'EMV',
    'Likes',
    'Video Count',
  ];
  const connectedNotInTTCMMetrics = [
    'Avg. Engagements',
    'Avg. Video Views',
    'Avg. Weekly Posts',
    'EMV',
    'Avg. 6 Sec Views Rate',
  ];

  const metrics = [
    {
      title: 'Avg. 6 Sec Views Rate',
      value: account?.sixSecondsViewRate,
      benchmark: account?.sixSecondsViewRateBenchmark,
      tooltip: tikTokAccountPerformanceMetricTooltips.SIX_SECONDS_VIEW_RATE,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Eng. Rate',
      value: account?.engagementRate,
      benchmark: account?.engagementRateBenchmark,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'EMV',
      value: account?.totalEmv,
      tooltip: tikTokAccountPerformanceMetricTooltips.TOTAL_EMV,
      format: 'currency',
      type: 'Total',
    },
    {
      title: 'Avg. Completion Rate',
      value: account?.completionRate,
      benchmark: account?.completionRateBenchmark,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_COMPLETION,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Follower Growth Rate',
      value: account?.followersGrowthRate,
      benchmark: account?.followersGrowthRateBenchmark,
      tooltip: tikTokAccountPerformanceMetricTooltips.FOLLOWERS_GROWTH,
      format: 'percent',
      type: 'Total',
    },
    {
      title: isConnected ? 'Avg. Likes' : 'Likes',
      value: isConnected ? account?.avgLikes : account?.totalLikes,
      tooltip: isConnected
        ? tikTokAccountPerformanceMetricTooltips.AVG_LIKES
        : tikTokAccountPerformanceMetricTooltips.TOTAL_LIKES,
      format: 'number',
      type: isConnected ? 'Average' : 'Total',
    },
    {
      title: 'Avg. Shares',
      value: account?.avgShares,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_SHARES,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Comments',
      value: account?.avgComments,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_COMMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Video Count',
      value: account?.totalPostCount,
      tooltip: tikTokAccountPerformanceMetricTooltips.VIDEO_COUNT,
      format: 'number',
      type: 'Total',
    },
  ];

  const multiChannelMetrics = [
    {
      title: 'Avg. Engagements',
      value: account?.avgEngagements,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_ENGAGEMENTS,
      benchmark: account?.avgEngagementsBenchmark,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Video Views',
      value: account?.avgVideoViews,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_VIDEO_VIEWS,
      benchmark: account?.avgVideoViewsBenchmark,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Weekly Posts',
      value: account?.avgWeeklyPosts,
      tooltip: tikTokAccountPerformanceMetricTooltips.AVG_WEEKLY_POSTS,
      format: 'number',
      type: 'Average',
    },
  ];

  if (hasMultiChannels) {
    metrics.unshift(...multiChannelMetrics);
  }

  if (!isConnected && isInTTCM)
    return metrics.filter((metric) => notConnectedInTTCMMetrics.includes(metric.title));
  if (isConnected && !isInTTCM)
    return metrics.filter((metric) => connectedNotInTTCMMetrics.includes(metric.title));

  return metrics.filter((metric) => metric.title !== 'Video Count');
}

export function getInstagramAccountPerformanceMetrics(account, hasMultiChannels) {
  const isConnected = account?.invitationStatus === inviteStatusDetails.ACCEPTED.value;

  const metrics = [
    {
      title: isConnected ? 'Avg. Reach' : 'Avg. Est. Reach',
      value: account?.rollingAccountMetrics.avgReach,
      tooltip: isConnected
        ? creatorPerformanceMetricTooltips.AVG_REACH
        : creatorPerformanceMetricTooltips.AVG_ESTIMATED_REACH,
      format: 'number',
      type: 'Average',
    },
  ];
  const multiChannelMetrics = [
    {
      title: 'Avg. Engagements',
      value: account?.rollingAccountMetrics.avgEngagements,
      tooltip: creatorPerformanceMetricTooltips.AVG_ENGAGEMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Effectiveness',
      value: account?.rollingAccountMetrics.avgEffectiveness,
      tooltip: creatorPerformanceMetricTooltips.AVG_EFFECTIVENESS,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Weekly Posts',
      value: account?.rollingAccountMetrics.avgWeeklyPosts,
      tooltip: creatorPerformanceMetricTooltips.AVG_WEEKLY_POSTS,
      format: 'number',
      type: 'Average',
    },
  ];

  if (hasMultiChannels) {
    metrics.unshift(...multiChannelMetrics);
  }
  return metrics;
}

export function getMultiChannelCreatorMetrics(tiktokAccount, instagramAccount) {
  if (!tiktokAccount) {
    const instagramMetrics = getInstagramAccountPerformance(instagramAccount);
    return instagramMetrics.map((metric) => ({ ...metric, channel: creatorSource.INSTAGRAM }));
  }

  return [
    {
      title: 'Followers',
      value: tiktokAccount?.followers,
      tooltip: tikTokCreatorMetricTooltips.FOLLOWERS,
      format: 'number',
      type: 'Total',
      channel: creatorSource.TIKTOK,
    },
    {
      title: 'Avg. Eng. Rate',
      value: tiktokAccount?.avgEngagementRate,
      tooltip: tikTokCreatorMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
      channel: creatorSource.TIKTOK,
    },
    {
      title: 'Avg. EMV',
      value: tiktokAccount?.avgEmv,
      tooltip: tikTokCreatorMetricTooltips.AVG_EMV,
      format: 'currency',
      type: 'Average',
      channel: creatorSource.TIKTOK,
    },
    {
      title: 'Followers',
      value: instagramAccount?.rollingAccountMetrics?.followers,
      tooltip: creatorPerformanceMetricTooltips.FOLLOWERS,
      format: 'number',
      type: 'Total',
      channel: creatorSource.INSTAGRAM,
    },
    {
      title: 'Avg. Eng. Rate',
      value: instagramAccount?.rollingAccountMetrics?.avgEngagementRate,
      tooltip: creatorPerformanceMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
      channel: creatorSource.INSTAGRAM,
    },
    {
      title: 'Avg. EMV',
      value: instagramAccount?.rollingAccountMetrics?.avgEmv,
      tooltip: creatorPerformanceMetricTooltips.TOTAL_EMV,
      format: 'currency',
      type: 'Average',
      channel: creatorSource.INSTAGRAM,
    },
  ];
}

export function getTikTokCreatorMetrics(account) {
  return [
    {
      title: 'Followers',
      value: account?.followers,
      tooltip: tikTokCreatorMetricTooltips.FOLLOWERS,
      format: 'number',
      type: 'Total',
    },
    {
      title: 'Avg. Eng. Rate',
      value: account?.avgEngagementRate,
      benchmark: account?.avgEngagementRateBenchmark,
      tooltip: tikTokCreatorMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. EMV',
      value: account?.avgEmv,
      tooltip: tikTokCreatorMetricTooltips.AVG_EMV,
      format: 'currency',
      type: 'Average',
    },
    {
      title: 'Avg. Engagements',
      value: account?.avgEngagements,
      benchmark: account?.avgEngagementsBenchmark,
      tooltip: tikTokCreatorMetricTooltips.AVG_ENGAGEMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Video Views',
      value: account?.avgVideoViews,
      benchmark: account?.avgVideoViewsBenchmark,
      tooltip: tikTokCreatorMetricTooltips.AVG_VIDEO_VIEWS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Weekly Posts',
      value: account?.avgWeeklyPosts,
      tooltip: tikTokCreatorMetricTooltips.AVG_WEEKLY_POSTS,
      format: 'number',
      type: 'Average',
    },
  ];
}

export function getTikTokPostInsightsNotConnected(account) {
  return [
    {
      title: 'Avg. Likes',
      value: account?.postInsightMetrics?.avgLikes,
      tooltip: tiktokPostInsightsMetricTooltips.AVG_LIKES,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Comments',
      value: account?.postInsightMetrics?.avgComments,
      tooltip: tiktokPostInsightsMetricTooltips.AVG_COMMENTS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Shares',
      value: account?.postInsightMetrics?.avgShares,
      tooltip: tiktokPostInsightsMetricTooltips.AVG_SHARES,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Video Views',
      value: account?.postInsightMetrics?.avgVideoViews,
      tooltip: tiktokPostInsightsMetricTooltips.AVG_VIDEO_VIEWS,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'EMV',
      value: account?.postInsightMetrics?.totalEmv,
      tooltip: tiktokPostInsightsMetricTooltips.TOTAL_EMV,
      format: 'currency',
      formatOptions: { maximumFractionDigits: 0 },
      type: 'Total',
    },
  ];
}

export function getTikTokPostInsightsConnected(account) {
  return [
    ...getTikTokPostInsightsNotConnected(account),
    {
      title: 'Avg. Engagements',
      value: account?.postInsightMetrics?.avgEngagements,
      tooltip: tiktokPostInsightsMetricTooltips.AVG_ENGAGEMENT,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Avg. Eng. Rate',
      value: account?.postInsightMetrics?.avgEngagementRate,
      tooltip: tiktokPostInsightsMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
  ];
}

export function getInstagramPostInsights(account) {
  return [
    {
      title: 'Avg. Eng. Rate',
      value: account?.riqAccountMetrics?.avgEngagementRate,
      tooltip: instagramPostInsightsMetricTooltips.AVG_ENGAGEMENT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Engagements',
      value: account?.riqAccountMetrics?.avgEngagements,
      tooltip: instagramPostInsightsMetricTooltips.AVG_ENGAGEMENT_PER_POST,
      format: 'number',
      type: 'Average',
    },
    {
      title: 'Followers Gained',
      value: account?.riqAccountMetrics?.totalFollowersGained,
      tooltip: instagramPostInsightsMetricTooltips.TOTAL_FOLLOWERS_GAINED,
      format: 'number',
      type: 'Total',
    },
    {
      title: 'Avg. Effectiveness',
      value: account?.riqAccountMetrics?.avgEffectiveness,
      tooltip: instagramPostInsightsMetricTooltips.AVG_EFFECTIVENESS,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'EMV',
      value: account?.riqAccountMetrics?.totalEmv,
      tooltip: instagramPostInsightsMetricTooltips.TOTAL_EMV,
      format: 'currency',
      formatOptions: { maximumFractionDigits: 0 },
      type: 'Total',
    },
  ];
}

export function getInstagramStoryInsights(account, hasInstagramMetricUpdates = false) {
  return [
    {
      title: 'Avg. Reach',
      value: account?.riqAccountMetrics?.avgReach,
      tooltip: instagramStoryInsightsMetricTooltips.AVG_REACH,
      format: 'number',
      type: 'Average',
    },
    ...(hasInstagramMetricUpdates
      ? [
          {
            title: 'Avg. Views',
            value: account?.riqAccountMetrics?.avgVideoViews,
            tooltip: instagramStoryInsightsMetricTooltips.AVG_VIDEO_VIEWS,
            format: 'number',
            type: 'Average',
          },
        ]
      : [
          {
            title: 'Avg. Impressions',
            value: account?.riqAccountMetrics?.avgImpressions,
            tooltip: instagramStoryInsightsMetricTooltips.AVG_IMPRESSIONS,
            format: 'number',
            type: 'Average',
          },
        ]),
    {
      title: 'Avg. Exit Rate',
      value: account?.riqAccountMetrics?.avgExitRate,
      tooltip: hasInstagramMetricUpdates
        ? instagramStoryInsightsMetricTooltips.AVG_EXIT_RATE_VIEWS
        : instagramStoryInsightsMetricTooltips.AVG_EXIT_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'Avg. Completion Rate',
      value: account?.riqAccountMetrics?.avgCompletionRate,
      tooltip: hasInstagramMetricUpdates
        ? instagramStoryInsightsMetricTooltips.AVG_COMPLETION_RATE_VIEWS
        : instagramStoryInsightsMetricTooltips.AVG_COMPLETION_RATE,
      format: 'percent',
      type: 'Average',
    },
    {
      title: 'EMV',
      value: account?.riqAccountMetrics?.totalEmv,
      tooltip: instagramPostInsightsMetricTooltips.TOTAL_EMV,
      formatOptions: { maximumFractionDigits: 0 },
      format: 'currency',
      type: 'Total',
    },
  ];
}

export function getSortString(sort) {
  return `${sort.sortOrder === 'DESC' ? '-' : ''}${sort.selected.value}`;
}

export function getHasMentionedBrand(value) {
  if (!value) return undefined;
  const mentionedMe = value.includes('MENTIONED_ME');
  const hasNotMentionedMe = value.includes('HAS_NOT_MENTIONED_ME');
  if (mentionedMe && hasNotMentionedMe) return undefined;
  if (mentionedMe) return true;
  if (hasNotMentionedMe) return false;
  return undefined;
}

export function getSentimentValue(sentiment) {
  if (sentiment?.isPositive) {
    return 'POSITIVE';
  }
  if (sentiment?.isNegative) {
    return 'NEGATIVE';
  }
  if (sentiment?.isNeutral) {
    return 'NEUTRAL';
  }
  return null;
}

export function getVisionPredictionValue(prediction) {
  if (prediction === null || prediction === undefined) {
    return null;
  }
  if (prediction > engagementRating.excellent) {
    return 'top';
  }
  if (prediction > engagementRating.good) {
    return 'better';
  }
  return 'less';
}

export const sentimentOptions = [
  {
    label: SENTIMENTS.POSITIVE.text,
    value: SENTIMENTS.POSITIVE.value,
    severity: SENTIMENTS.POSITIVE.severity,
  },
  {
    label: SENTIMENTS.NEUTRAL.text,
    value: SENTIMENTS.NEUTRAL.value,
    severity: SENTIMENTS.NEUTRAL.severity,
  },
  {
    label: SENTIMENTS.NEGATIVE.text,
    value: SENTIMENTS.NEGATIVE.value,
    severity: SENTIMENTS.NEGATIVE.severity,
  },
];

export function getIconFromSource(source) {
  if (source === creatorSource.INSTAGRAM) return 'instagram-color';
  if (source === creatorSource.TIKTOK) return 'tiktok-color';
  throw new Error(`No know icon for source ${source}`);
}

export function getMetricLabel(metric, account, hasInstagramMetricUpdates = false) {
  if (
    account?.invitationStatus !== inviteStatusDetails.ACCEPTED.value &&
    metricDetails[metric.metricValue].disconnectedLabel
  ) {
    return metricDetails[metric.metricValue].disconnectedLabel;
  }
  if (hasInstagramMetricUpdates && metricDetails[metric.metricValue].newLabel) {
    return metricDetails[metric.metricValue].newLabel;
  }
  return metric.label;
}

export function getMetricPrefix(metric, accountKey) {
  if (accountKey.toUpperCase() === creatorSource.INSTAGRAM) {
    return metricDetails[metric.metricValue].isRIQMetric
      ? 'riqAccountMetrics'
      : 'rollingAccountMetrics';
  }
  if (accountKey.toUpperCase() === creatorSource.TIKTOK) {
    return metricDetails[metric.metricValue].isRIQMetric ? 'postInsightMetrics' : null;
  }
  return null;
}

export function getKeywords(keywords) {
  const result = {
    bioIncludes: [],
    bioDoesNotInclude: [],
    captionsInclude: [],
    captionsDoNotInclude: [],
    bioOrCaptionsInclude: [],
  };

  keywords?.forEach(({ shouldInclude, keyword, location }) => {
    if (!keyword || !keyword.length) return;

    if (shouldInclude) {
      if (location === 'bio') result.bioIncludes.push(keyword);
      if (location === 'caption') result.captionsInclude.push(keyword);
      if (location === 'anywhere') result.bioOrCaptionsInclude.push(keyword);
    } else {
      if (location === 'bio' || location === 'anywhere') result.bioDoesNotInclude.push(keyword);
      if (location === 'caption' || location === 'anywhere')
        result.captionsDoNotInclude.push(keyword);
    }
  });

  Object.keys(result).forEach((key) => {
    if (!result[key].length) result[key] = undefined;
  });

  return result;
}

export function getChannelIconTooltip(handle, status) {
  switch (status) {
    case inviteStatusDetails.ACCEPTED.value:
      return `@${handle} has accepted your invite and is connected`;
    case inviteStatusDetails.INVITATION_SENT.value:
      return `@${handle} has not yet accepted your invitation to connect`;
    case inviteStatusDetails.LAPSED.value:
      return `@${handle} has not accepted your invitation to connect in over 14 days`;
    case inviteStatusDetails.REVOKED.value:
    case inviteStatusDetails.DISCONNECTED.value:
      return `@${handle} has been disconnected. Select analytics will not import until they reconnect.`;
    case inviteStatusDetails.NOT_INVITED.value:
      return `Send @${handle} a connection invite to receive more analytics!`;
    case null:
    case '':
      return `@${handle} has not connected`;
    default:
      return `@${handle}`;
  }
}

export function getFollowersRange({ min, max } = {}) {
  const isValidMin = min >= instagramFilterInputLimit.minFollowers;
  const isValidMax = max <= instagramFilterInputLimit.maxFollowers;

  return {
    min: isValidMin ? Number(min) : undefined,
    max: isValidMax ? Number(max) : undefined,
  };
}

export function truncateFilename(filename) {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex !== -1) {
    const extension = filename.slice(lastDotIndex);
    if (POPULAR_FILE_EXTENSIONS.includes(extension.toLowerCase())) {
      const name = filename.slice(0, lastDotIndex);
      if (name.length > 20) return `${name.slice(0, 20)}...${extension}`;
      return filename;
    }
  }

  return filename.length > 20 ? `${filename.slice(0, 20)}...` : filename;
}

export function createBlobUrlForAttachment(data, mimeType) {
  const binaryArray = base64ToBinaryArray(data);
  return URL.createObjectURL(new Blob([binaryArray], { type: mimeType }));
}

export function getFileType(mimeType) {
  if (mimeType) {
    if (mimeType.startsWith('image/')) return 'image';
    if (
      mimeType.startsWith('text/') ||
      mimeType === 'application/json' ||
      mimeType === 'application/xml'
    ) {
      return 'text';
    }
    if (mimeType.startsWith('video/')) return 'video';
    if (mimeType.startsWith('application/') || mimeType.startsWith('audio/')) {
      return 'document';
    }
  }

  // Assume that if there are no mimeType, it is a Google Drive document
  return 'document';
}

function formatForwardedDate(date) {
  return `${date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })} at ${date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
}

export function formatForwardedMessage(message) {
  const { from, date, subject, to, cc, body } = message;
  const formattedDate = formatForwardedDate(date);

  const formatEmails = (emailList) =>
    emailList.map(({ address }) => `&lt;${address}&gt;`).join(', ');

  const forwardedHeader = `
    <br><br>
    <p>${FORWARD_MESSAGE_HEADER}<br>
    From: ${formatEmails(from)}<br>
    Date: ${formattedDate}<br>
    Subject: ${subject}<br>
    To: ${formatEmails(to)}<br>
    ${cc?.length ? `Cc: ${formatEmails(cc)}</p>` : ''}
    <br><br>`;

  return `${forwardedHeader}${body}`;
}

export function extractCommentFromForwardMessageBody(body) {
  return body.replace(new RegExp(`<[^>]+>${FORWARD_MESSAGE_HEADER}.*$`), '');
}

export function sanitizeStoriesFromDMs(postsList) {
  // TODO: rather than clearing out story media links in the frontend after 24 hours, we should
  //  handle this in the backend (likely on import) when time permits.
  return postsList.map((post) => {
    if (
      post.source === visualSources.INSTAGRAM_STORY &&
      post.instagramStoryFrame.importedViaDm &&
      dayjs(post.createdAt).diff(getCurrentDate(), 'hour') < -24
    ) {
      // hide media for all stories > 24 hours old (request from partnerships team)
      post.image = null;
      post.predictions = null;
      if (post.type === 'VIDEO') {
        post.video.sizes = null;
        post.video.thumbnails = null;
      }
      return post;
    }
    // return all other posts normally
    return post;
  });
}

export function monthDayRange(data) {
  let maxDay = 31;
  const month = data?.birthday?.month ?? 0;
  const year = data?.birthday?.year ?? 0;

  if (month > 0) {
    maxDay = new Date(year, month, 0).getDate();
  }
  if (month === 2 && !year) {
    maxDay += 1;
  }
  return [1, maxDay];
}
