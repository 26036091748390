<template>
  <div
    ref="container"
    v-drag-move-2="dragMoveParams"
    class="user-tagger"
    @click="handleTaggerClick"
  >
    <template v-if="containerRendered && !transitioning">
      <MediaTag
        v-for="(userTag, index) in instagramUserTaggerStore.activeMediaTaggedUsers"
        :key="index"
        :boundary="boundary"
        :title="userTag.username"
        :style="{ left: ratioToOffset(userTag.x, 'x'), top: ratioToOffset(userTag.y, 'y') }"
        :data-tag-index="index"
        class="draggable"
        @delete-tag="instagramUserTaggerStore.deleteUserTag({ index })"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import {
  maxUserTags,
  maxTagsPerMedia,
  maxTagsPerPost,
  tooManyUserTagsMessage,
  tooManyTagsPerMediaMessage,
  tooManyTagsPerPostMessage,
} from '@/app/scheduler/constants';
import MediaTag from '@/app/scheduler/components/EditPost/MediaTag.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'UserTagger',
  components: { MediaTag },
  props: {
    transitioning: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      containerRendered: false,
      zIndex: 1,
      dragMoveParams: {
        draggableClass: 'draggable',
        onDragStarted: this.handleDragStarted,
        onDragFinished: this.handleDragFinished,
      },
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useSchedulerStore, useInstagramUserTaggerStore),
    boundary() {
      return this.$el.closest('.media-container');
    },
  },
  watch: {
    'schedulerStore.carouselActiveMedia': {
      handler(newVal, oldVal) {
        if (this.instagramUserTaggerStore.editingUserTag) {
          this.instagramUserTaggerStore.deleteUserTag({ mediaId: oldVal.id });
          this.instagramUserTaggerStore.setEditingUserTag({ value: false });
        }
      },
    },
  },
  mounted() {
    this.containerRendered = true;
  },
  methods: {
    getSize(axis) {
      return axis === 'x' ? this.$refs.container.clientWidth : this.$refs.container.clientHeight;
    },
    ratioToOffset(ratio, axis) {
      const offset = Math.round(ratio * this.getSize(axis));
      return `${offset}px`;
    },
    offsetToRatio(offset, axis) {
      const ratio = offset / this.getSize(axis);

      // In some very rare scenarios, the ratio can be slightly above 1 (or potentially slightly
      // below 0, but I haven't seen that yet). Instead of spending more time trying to debug this
      // very rare scenario, I decided to fix the issue by round the number to within the range of
      // 0 - 1 (inclusive). This should have no negative effects.
      if (ratio > 1) {
        return 1;
      }
      if (ratio < 0) {
        return 0;
      }
      return ratio;
    },
    canAddUserTag() {
      if (this.schedulerStore.carouselActiveMedia.type === 'VIDEO') {
        return false;
      }
      if (this.instagramUserTaggerStore.totalUserTags >= maxUserTags) {
        this.notificationStore.setToast({
          message: tooManyUserTagsMessage,
          type: 'warning',
        });
        return false;
      }
      if (this.instagramUserTaggerStore.totalMediaTagsAndProductTags >= maxTagsPerPost) {
        this.notificationStore.setToast({
          message: tooManyTagsPerPostMessage,
          type: 'warning',
        });
        return false;
      }
      if (
        this.instagramUserTaggerStore.totalActiveMediaTags >= maxTagsPerMedia &&
        this.instagramUserTaggerStore.totalActiveMediaTags !==
          this.instagramUserTaggerStore.activeMediaTaggedUsers.length
      ) {
        // Only show if at least one product is tagged on the media
        this.notificationStore.setToast({
          message: tooManyTagsPerMediaMessage,
          type: 'warning',
        });
        return false;
      }
      return true;
    },
    handleTaggerClick(e) {
      if (
        e.target.classList.contains('user-tagger') &&
        !this.instagramUserTaggerStore.editingUserTag
      ) {
        if (this.canAddUserTag()) {
          this.instagramUserTaggerStore.addUserTag({
            tag: {
              username: null,
              x: this.offsetToRatio(e.offsetX, 'x'),
              y: this.offsetToRatio(e.offsetY, 'y'),
            },
          });
          this.instagramUserTaggerStore.setEditingUserTag({ value: true });
        }
      }
    },
    handleDragStarted(target) {
      target.style.zIndex = this.zIndex;
      this.zIndex += 1;
    },
    handleDragFinished(target, x, y) {
      const index = parseInt(target.dataset.tagIndex, 10);
      this.instagramUserTaggerStore.updateUserTag({
        index,
        tag: {
          x: this.offsetToRatio(x, 'x'),
          y: this.offsetToRatio(y, 'y'),
        },
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.user-tagger {
  height: 100%;
  position: relative;
  cursor: pointer;
}

.draggable {
  position: absolute;
  white-space: nowrap;
}
</style>
