import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { LibraryAPI } from '@/apis';
import { logger } from '@/utils/logger';
import { encodeUtmErrorMessage } from '@/app/settings/components/Utm/const';
import { fetchUtmSettings as fetchUtmSettingsUtil } from '@/app/settings/components/Utm/utils';
import { useNotificationStore } from '@/stores/notification';
import { trackSchedulerUtmPopout } from '@/app/scheduler/mixpanel';
import { useGalleryStore } from '@/stores/gallery';
import { useAuthStore } from '@/stores/auth';
import { useDrawer } from '@dashhudson/dashing-ui';
import { useFlagStore } from '@/stores/flag';
import { ECOMMERCE_DRAWERS } from '@/app/ecommerce/constants';

export const useMediaLinksStore = defineStore('mediaLinks', () => {
  const authStore = useAuthStore();
  const flagStore = useFlagStore();
  const galleryStore = useGalleryStore();

  const { launchDrawer } = useDrawer();

  const pending = ref({
    mediaLinks: false,
  });
  const mediaItem = ref(null);
  const mediaLinks = ref([]);
  const editedMediaLink = ref('');
  const linkLimit = ref(null);
  const showLinkPopup = ref(null);
  const linkUpdateStatus = ref(null);
  const linkUpdatingMediaId = ref(null);
  const linkUpdateSource = ref(null);
  const utmChannel = ref(null);
  const utmTrackingData = ref(null);
  const utmSettings = ref({});
  const utmEditorPanelIndex = ref(null);
  const showUtmEditorPanel = ref(false);
  const isUgcMedia = ref(null);
  const isCompetitiveMedia = ref(null);
  const brandChannelUtmSettings = ref(null);
  const selectedProduct = ref(null);

  const showUtmSettingsDrawer = ref(false);

  const hasGdiLikeShopFlag = computed(() => flagStore.ready && flagStore.flags.gdiLikeshop);

  function setLinkLimit(limit) {
    linkLimit.value = limit;
  }

  function openMediaLinkEditor(item, openDrawer = true) {
    mediaItem.value = item;
    if (hasGdiLikeShopFlag.value && openDrawer) {
      launchDrawer({
        name: ECOMMERCE_DRAWERS.MEDIA_LINKS,
        props: {
          mediaId: item.id,
        },
      });
    } else {
      showLinkPopup.value = true;
    }
  }

  function closeLinkPopup() {
    showLinkPopup.value = false;
    linkLimit.value = undefined;
    mediaItem.value = null;
  }

  function clearBrandChannelUtmSettings() {
    brandChannelUtmSettings.value = null;
  }

  function clearMediaLinks() {
    mediaLinks.value = [];
  }

  function setLinkUpdateSource(source) {
    linkUpdateSource.value = source;
  }

  function openUtmEditorPanel(index) {
    utmEditorPanelIndex.value = index;
    if (!showUtmEditorPanel.value) {
      showUtmEditorPanel.value = true;

      try {
        trackSchedulerUtmPopout({
          ...utmTrackingData.value,
          action: 'open',
        });
      } catch (error) {
        logger.error(error.message, {}, error);
      }
    }
  }

  function closeUtmEditorPanel() {
    if (showUtmEditorPanel.value) {
      utmEditorPanelIndex.value = null;
      showUtmEditorPanel.value = false;
      try {
        trackSchedulerUtmPopout({
          ...utmTrackingData.value,
          action: 'close',
        });
      } catch (error) {
        logger.error(error.message, {}, error);
      }
    }
  }

  async function encodeUtm({ mediaId, brandId, mediaLink }) {
    const response = await LibraryAPI.encodeUtmSettings({
      brandId,
      mediaId,
      channel: utmChannel.value,
      content: mediaLink.url,
      productId: mediaLink?.product?.id,
      shortenUrl: false,
      urls: [mediaLink.url],
    });
    mediaLink.url = response.data.content;
  }

  async function encodeUtms({ mediaId, brandId, data }) {
    const promises = data.map((mediaLink) => {
      return encodeUtm({ mediaId, brandId, mediaLink });
    });
    try {
      await Promise.all(promises);
    } catch (error) {
      const notificationStore = useNotificationStore();
      logger.error(`Error while encoding url: ${error}`);
      notificationStore.setToast({
        message: encodeUtmErrorMessage,
        type: 'error',
      });
    }
  }

  async function updateMediaLinks({ mediaId, brandId, data }) {
    linkUpdatingMediaId.value = mediaId;
    linkUpdateStatus.value = 'pending';
    pending.value.mediaLinks = true;

    try {
      if (!isUgcMedia.value && !isCompetitiveMedia.value && utmChannel.value) {
        if (hasGdiLikeShopFlag.value) {
          const modifiedLinks = data.filter((link) => link.editedUrl);
          await encodeUtms({
            mediaId,
            brandId,
            data: modifiedLinks.map((link) => ({
              ...link,
              url: link.editedUrl || link.url,
            })),
          });
        } else {
          const modifiedLinks = data.filter((link) => link.urlLinkCustomized === true);
          if (modifiedLinks.length > 0) {
            await encodeUtms({ mediaId, brandId, data: modifiedLinks });
          }
        }
      }
      const res = await LibraryAPI.updateMediaLinks({ mediaId, brandId, data });
      linkUpdatingMediaId.value = null;
      mediaLinks.value = res.data;
      linkUpdateStatus.value = 'success';
      pending.value.mediaLinks = false;
      galleryStore.updateGalleryMediaLink({ mediaId, links: res.data });
    } catch (e) {
      logger.error(`Failed to update media links for brand ${brandId}, media ${mediaId}`, {}, e);
      linkUpdatingMediaId.value = null;
      linkUpdateStatus.value = 'error';
      pending.value.mediaLinks = false;
    }
  }

  async function fetchMediaLinks({ brandId, mediaId }) {
    try {
      pending.value.mediaLinks = true;
      const res = await LibraryAPI.getListMediaLinks({ brandId, mediaId });
      return res.data;
    } finally {
      pending.value.mediaLinks = false;
    }
  }

  async function listMediaLinks({ brandId, mediaId }) {
    try {
      mediaLinks.value = await fetchMediaLinks({ brandId, mediaId });
    } catch (e) {
      mediaLinks.value = [];
    }
  }

  async function fetchUtmSettings() {
    utmSettings.value = await fetchUtmSettingsUtil({
      brandId: authStore?.currentBrand?.id,
      channel: utmChannel.value,
    });
  }

  return {
    pending,
    mediaItem,
    mediaLinks,
    editedMediaLink,
    linkLimit,
    showLinkPopup,
    linkUpdateStatus,
    linkUpdatingMediaId,
    linkUpdateSource,
    utmChannel,
    utmTrackingData,
    utmSettings,
    utmEditorPanelIndex,
    showUtmEditorPanel,
    isUgcMedia,
    isCompetitiveMedia,
    selectedProduct,
    brandChannelUtmSettings,

    showUtmSettingsDrawer,
    setLinkLimit,
    openMediaLinkEditor,
    closeLinkPopup,
    clearMediaLinks,
    setLinkUpdateSource,
    openUtmEditorPanel,
    closeUtmEditorPanel,
    encodeUtm,
    encodeUtms,
    updateMediaLinks,
    listMediaLinks,
    fetchMediaLinks,
    fetchUtmSettings,
    clearBrandChannelUtmSettings,
  };
});
