<template>
  <AudienceSection
    v-model="audienceSectionTab"
    :audience-statistics="audienceStatistics"
    :empty-state-copy="emptyStateCopy"
  />
</template>

<script>
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { toolTips } from '@/config';
import { audienceSectionTabs } from '@/app/library/constants';
import { getCountryName } from '@/utils/countries';
import AudienceSection from '@/app/library/components/MediaPopup/AudienceSection.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TikTokAudienceSection',
  components: { AudienceSection },
  props: {
    value: {
      type: String,
      default: audienceSectionTabs.TRAFFIC_SOURCE,
    },
    mediaItem: { type: Object, required: true },
  },
  emits: ['input', 'tabChanged'],
  data() {
    return {
      tooltips: toolTips.tiktokInsights,
      trafficSourceRates: [
        'followingPageViews',
        'forYouPageViews',
        'hashtagViews',
        'soundViews',
        'profileViews',
        'searchViews',
      ],
      headerTitle: 'Audience',
      dropdownOpen: true,
      emptyStateCopy: 'Video data is currently processing. Check back later.',
      audienceSectionTab: this.value,
    };
  },
  computed: {
    audienceStatistics() {
      return {
        [audienceSectionTabs.TRAFFIC_SOURCE]: {
          tooltip: "The breakdown of TikTok pages this video's views originated from.",
          statistics: this.sortAudienceObject(this.trafficSourceStatistics),
          format: 'percent',
        },
        [audienceSectionTabs.LOCATION]: {
          tooltip: 'The breakdown of the top countries this video was viewed in.',
          statistics: this.sortAudienceObject(this.locationStatistics),
          format: 'percent',
        },
      };
    },
    trafficSourceStatistics() {
      const insights = this.mediaItem.insights || {};
      const trafficSources = this.trafficSourceRates.filter((source) => insights[source]);

      return trafficSources.map((source) => {
        return {
          label: this.formatStatName(source),
          value: insights[source],
        };
      });
    },
    locationStatistics() {
      let audienceLocations = this.mediaItem?.audienceLocations || [];
      audienceLocations = audienceLocations.sort((a, b) => b.percentage - a.percentage);

      if (audienceLocations.length < 6) {
        return audienceLocations.map((location) => {
          return {
            // Rename Others -> Other to keep language consistent
            label: location.country === 'Others' ? 'Other' : getCountryName(location.country),
            value: location.percentage / 100,
          };
        });
      }

      const [displayedStatistics, otherSum] = audienceLocations.reduce(
        ([stats, other], location) => {
          if (stats.length < 4 && location.country !== 'Others') {
            stats.push({
              label: getCountryName(location.country),
              value: location.percentage / 100,
            });
            return [stats, other];
          }
          return [stats, other + location.percentage];
        },
        [[], 0],
      );

      return [
        ...displayedStatistics,
        {
          label: 'Other',
          value: otherSum / 100,
        },
      ];
    },
  },
  watch: {
    audienceSectionTab(to) {
      this.$emit('input', to);
      this.$emit('tabChanged', 'Audience', `Click ${startCase(to.toLowerCase())}`, {});
    },
  },
  methods: {
    formatStatName(name) {
      return `${startCase(name.replace('Views', ''))}`;
    },
    sortAudienceObject(statistics) {
      return statistics.sort((a, b) => b.value - a.value);
    },
  },
});
export default comp;
</script>
