<template>
  <div
    v-tooltip="hasCrossBrandDuplicateError && crossBrandDuplicateErrTooltip"
    :class="['list-viewer-item', { 'single-media-view': singleMediaView }]"
  >
    <Media
      ref="media"
      :aspect-ratio="singleMediaView ? 'unset' : 'square'"
      :compact="singleMediaView"
      :media="media"
      :thumbnail="!singleMediaView"
      :show-media-only="hasCrossBrandDuplicateError"
      :creating-brand-media="creatingBrandMedia"
      :thumb-offset="thumbOffset"
      @uploaded-duration="handleDurationDetected"
    />
    <a
      v-if="showRemoveButton"
      v-tooltip="'Remove Media'"
      class="close dash-z-20"
      @click="remove(media)"
    >
      <Icon :color="closeIconColor" height="8" width="8" name="close" />
    </a>
    <div class="button-wrapper">
      <a
        v-if="showCropButton"
        v-tooltip="'Crop Media'"
        class="crop dash-z-20"
        @click="handleCropClicked"
      >
        <Icon :color="closeIconColor" :hover-color="iconHoverColor" name="crop" xsmall />
      </a>
      <a
        v-if="showDownloadButton"
        v-tooltip="'Download Media'"
        class="download dash-z-20"
        :class="{ loading: downloadingMedia }"
        @click="handleDownloadMedia(media)"
      >
        <Icon :color="closeIconColor" :hover-color="iconHoverColor" name="download" xsmall />
      </a>
    </div>

    <a v-if="showWarningIcon" :class="['warning', { 'hide-red-bg': hasCrossBrandDuplicateError }]">
      <Icon
        :color="warningIconColor"
        :name="validationError.icon || 'warning'"
        :xxsmall="!hasCrossBrandDuplicateError"
        :small="hasCrossBrandDuplicateError"
      />
    </a>

    <a
      v-if="showAltTextButton"
      v-tooltip="'Alt Text'"
      :class="['alt-text', { 'editing-alt-text': editingAltText }]"
      @click="handleAltTextClicked"
    >
      <Icon :color="altTextIconColor" :hover-color="iconHoverColor" name="chat-bubble" xsmall />
    </a>
    <PopoverWrapper
      ref="popoverWrapper"
      title="Alt Text"
      :show-popover="editingAltText"
      :on-save="onSaveAltText"
      :on-clear="clearAltText"
      :clear-disabled="isClearDisabled"
      :save-disabled="isSaveDisabled()"
      :handle-outside-click="handleOutsideClick"
    >
      <span v-if="showAltTextLengthIndicator" class="alt-text-length-indicator">
        -{{ altText.length - maxAltTextLength }}
      </span>
      <div class="input-wrapper">
        <Textarea
          ref="addAltText"
          v-model="altText"
          class="alt-text-area"
          placeholder="Add a description"
          resizable
          :focus-on-mount="true"
          :enable-emoji-picker="false"
          :readonly="disableEditing"
          is-alt-text-style
        />
      </div>
    </PopoverWrapper>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import downloadMediaMixin from '@/mixins/downloadMediaMixin';
import Icon from '@/components/foundation/Icon.vue';
import { mediaSizeIsCroppable } from '@/utils/media';
import {
  CROSS_BRAND_DUPLICATE_ERROR,
  MAX_ALT_TEXT_LENGTH,
  mediaValidationMap,
} from '@/app/scheduler/constants';
import { useFlagStore } from '@/stores/flag';
import PopoverWrapper from '@/components/core/PopoverWrapper.vue';
import Textarea from '@/components/Textarea.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import { useAuthStore } from '@/stores/auth';
import Media from './Media.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ListViewerItem',
  components: {
    Icon,
    Media,
    Textarea,
    PopoverWrapper,
  },
  mixins: [downloadMediaMixin],
  props: {
    cropClicked: { type: Function, default: () => {} },
    disableEditing: { type: Boolean, default: false },
    downloadMediaClicked: { type: Function, default: () => {} },
    media: { type: Object, required: true },
    platform: { type: String, default: null },
    remove: { type: Function, required: true },
    singleMediaView: { type: Boolean, default: false },
    validationError: { type: [Boolean, Object], default: false },
    altTextMediaMap: { type: Object, default: null },
    saveAltText: { type: Function, default: () => {} },
    creatingBrandMedia: { type: Boolean, default: false },
    thumbOffset: { type: Number, default: null },
  },
  data() {
    return {
      closeIconColor: colours.BASIC.WHITE,
      iconHoverColor: colours.ACTION.ACTION_500,
      uploadedDuration: null,
      downloadingMedia: false,
      editingAltText: false,
      maxAltTextLength: MAX_ALT_TEXT_LENGTH,
      altText: this.altTextMediaMap?.[this.media.id] ?? '',
      altTextIconColor: this.altTextMediaMap?.[this.media.id]
        ? colours.ACTION.ACTION_500
        : colours.BASIC.WHITE,
      crossBrandDuplicateErrTooltip: CROSS_BRAND_DUPLICATE_ERROR,
    };
  },
  computed: {
    ...mapStores(useFlagStore, useTrackingStore, useSchedulerStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    showWarningIcon() {
      return (
        this.validationError?.level === 'error' &&
        (!this.singleMediaView || this.hasCrossBrandDuplicateError)
      );
    },
    warningIconColor() {
      return this.hasCrossBrandDuplicateError ? colours.ERROR.ERROR_500 : this.closeIconColor;
    },
    hasCrossBrandDuplicateError() {
      return this.validationError?.message === CROSS_BRAND_DUPLICATE_ERROR;
    },
    currentMediaUploading() {
      return Boolean(this.media.uploadStatus) && this.media.uploadStatus !== UPLOAD_STATUS.SUCCESS;
    },
    mediaIsCroppable() {
      return !this.currentMediaUploading && mediaSizeIsCroppable(this.media);
    },
    trimmerMaxNumeric() {
      const platformValidations = mediaValidationMap[this.platform];
      return (
        platformValidations &&
        platformValidations.duration &&
        platformValidations.duration.video &&
        platformValidations.duration.video.trimmerMaxNumeric
      );
    },
    showCropButton() {
      return (
        !this.hasCrossBrandDuplicateError &&
        !this.editingAltText &&
        !this.disableEditing &&
        !this.currentMediaUploading &&
        !this.creatingBrandMedia &&
        ((this.media.type === 'IMAGE' && this.mediaIsCroppable) ||
          (this.media.type === 'VIDEO' &&
            !this.currentMediaUploading &&
            (!this.trimmerMaxNumeric ||
              (this.media.duration || this.uploadedDuration) <= this.trimmerMaxNumeric)))
      );
    },
    showDownloadButton() {
      return (
        !this.creatingBrandMedia &&
        !this.hasCrossBrandDuplicateError &&
        !this.editingAltText &&
        !this.currentMediaUploading &&
        (this.media.uploadStatus === UPLOAD_STATUS.SUCCESS || !this.media.uploadStatus)
      );
    },
    showAltTextButton() {
      return (
        !this.creatingBrandMedia &&
        this.flagStore.ready &&
        this.flagStore.flags.altTextPublishing &&
        (this.editingAltText || (!this.currentMediaUploading && this.media.type === 'IMAGE')) &&
        !this.hasCrossBrandDuplicateError &&
        Boolean(this.altTextMediaMap)
      );
    },
    showRemoveButton() {
      return !this.disableEditing && !this.creatingBrandMedia;
    },
    isClearDisabled() {
      return this.disableEditing || this.altText.length === 0;
    },
    showAltTextLengthIndicator() {
      return this.altText.length > this.maxAltTextLength;
    },
    crossChannelPublishingFlag() {
      const flagStore = useFlagStore();
      return flagStore.ready && flagStore.flags.crossChannelPublishing;
    },
    autoPublish() {
      return this.crossChannelPublishingFlag
        ? this.schedulerStore.autoPublishChannelMap[this.currentBrand.id]?.[this.platform]
        : this.schedulerStore.autoPublish;
    },
  },
  watch: {
    'flagStore.ready': {
      immediate: true,
      handler(ready) {
        if (ready) {
          this.flags = this.flagStore.flags;
        }
      },
    },
    showAltTextLengthIndicator() {
      if (this.showAltTextLengthIndicator) this.altTextIconColor = colours.ICON.ICON_PRIMARY;
    },
    media: {
      deep: true,
      handler(to) {
        if (this.altTextMediaMap?.[to.id]) {
          this.altText = this.altTextMediaMap[to.id];
          this.altTextIconColor = colours.ACTION.ACTION_500;
        } else {
          this.clearAltText();
          this.altTextIconColor = colours.BASIC.WHITE;
        }
      },
    },
  },
  methods: {
    handleCropClicked() {
      this.cropClicked();
      if (this.media.type === 'VIDEO') {
        this.$refs.media.pause();
      }
    },
    isSaveDisabled() {
      return (
        (this.altText.length === 0 && !this.altTextMediaMap?.[this.media.id]) || this.disableEditing
      );
    },
    handleDurationDetected(uDuration) {
      this.uploadedDuration = uDuration;
    },
    async handleDownloadMedia(media) {
      if (this.downloadingMedia) return;
      this.downloadingMedia = true;
      this.downloadMediaClicked(media);
      const status = await this.downloadMedia(this.getDownloadableMediaUrl(media));
      if (status) this.downloadingMedia = false;
    },
    handleAltTextClicked() {
      this.editingAltText = !this.editingAltText;
    },
    onSaveAltText() {
      this.saveAltText(this.media.id, this.altText);
      this.editingAltText = false;
      let action;
      if (this.altText.length > 0) {
        this.altTextIconColor = colours.ACTION.ACTION_500;
        action = 'added';
      } else {
        this.altTextIconColor = colours.BASIC.WHITE;
        action = 'removed';
      }
      if (this.altTextMediaMap?.[this.media.id] !== this.altText) {
        this.trackingStore.track('Scheduler Alt Text Clicked', {
          action,
          publishType: this.autoPublish ? 'Auto Publish' : 'Send Notification',
          channel: this.platform,
        });
      }
    },
    clearAltText() {
      this.altText = '';
    },
    handleOutsideClick(clickEvent) {
      clickEvent.stopPropagation();
      this.onSaveAltText();
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.crop,
.download {
  position: absolute;
  left: var(--space-4);
  top: var(--space-4);
  background: var(--black-alpha-70);
  width: var(--space-24);
  height: var(--space-24);
  border-radius: 50%;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
}

.list-viewer-item {
  position: relative;
  width: var(--space-80);
  height: var(--space-80);
  margin-bottom: var(--space-4) 0;
  border-radius: var(--round-corner-small);

  .button-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    :deep(.video-icon) {
      display: none;
    }

    .crop,
    .download {
      display: flex;
      position: relative;
    }

    .download {
      margin-left: var(--space-4);
    }

    .alt-text {
      display: flex;
    }
  }

  &.single-media-view {
    height: auto;
    max-width: 100%;
    width: fit-content;
    margin: auto;

    &:hover {
      :deep(.video-icon) {
        display: block;
      }

      .crop,
      .download {
        margin: var(--space-8) 0 0 var(--space-8);
        width: var(--space-32);
        height: var(--space-32);
      }
    }
  }
}

.close {
  position: absolute;
  right: -8px;
  top: -8px;
  background: var(--background-900);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

a[disabled] {
  pointer-events: none;
  text-decoration: none;
}

.warning {
  position: absolute;
  left: var(--space-30);
  top: var(--space-30);
  background: var(--error-500);
  width: var(--space-20);
  height: var(--space-20);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hide-red-bg {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0;
    background: linear-gradient(0deg, rgba(255 255 255 / 50%), rgba(255 255 255 / 50%));
    left: auto;
    top: 0;
  }
}

.alt-text {
  position: absolute;
  right: var(--space-4);
  bottom: var(--space-4);
  background: var(--black-alpha-70);
  width: var(--space-24);
  height: var(--space-24);
  border-radius: 50%;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
}

.editing-alt-text {
  display: flex;
}

.loading {
  color: transparent !important;
  position: relative;

  & > svg {
    display: none;
  }
}

.loading::after {
  content: '';
  display: block;
  border: 2px solid var(--border);
  border-left-color: rgb(255 255 255 / 20%);
  border-right-color: rgb(255 255 255 / 20%);
  border-bottom-color: rgb(255 255 255 / 20%);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  margin-left: -0.75em;
  margin-top: -0.75em;
  animation: rotate 1s linear infinite;
}

.alt-text-length-indicator {
  color: red;
  right: var(--space-24);
  position: absolute;
  top: var(--space-24);
}

.input-wrapper {
  width: 100%;
  position: relative;

  input {
    width: 100%;
    padding: var(--space-8) var(--space-40) var(--space-8) var(--space-16);
    border-radius: var(--round-corner-small);
    border: 1px solid #4990e2;
  }
}
</style>
