<script setup>
import { Button, useFilterSidePanel, Skeleton } from '@dashhudson/dashing-ui';
import { useHubspotStore } from '@/stores/hubspot';
import { onMounted, onUnmounted } from 'vue';

const hubspotStore = useHubspotStore();

const { visible: filterVisible } = useFilterSidePanel();

function onToggleChat() {
  hubspotStore.toggle();
}

onMounted(() => {
  hubspotStore.chatMounted();
});

onUnmounted(() => {
  hubspotStore.chatUnmounted();
});
</script>

<template>
  <div
    v-if="hubspotStore.isGdsVersion"
    :class="[
      'dash-transition-md fixed bottom-4 flex flex-col gap-3 print:hidden',
      {
        'right-4': !filterVisible,
        'right-[23rem]': filterVisible,
      },
    ]"
    :style="{ zIndex: '9999' }"
  >
    <Transition name="expand">
      <div
        v-show="hubspotStore.showConversation"
        class="dash-relative dash-elevation-5xl dash-rounded-lg dash-bg-background-base origin-bottom-right overflow-hidden"
        :style="{ width: '376px', height: '530px' }"
      >
        <div id="dh-hubspot-chat" class="dash-absolute dash-h-full dash-w-full" />
        <Skeleton v-if="hubspotStore.loadingWidget" height="100%" width="100%" />
      </div>
    </Transition>
    <div class="flex justify-end">
      <Button
        v-if="hubspotStore.ready"
        size="lg"
        :icon="{ name: 'hubspot-chat', size: 22 }"
        severity="footer"
        aria-label="Support Chat"
        :disabled="!hubspotStore.ready"
        @click="onToggleChat"
      />
    </div>
  </div>
</template>
