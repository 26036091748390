import { defineQuery, useQuery } from '@pinia/colada';
import { computed, ref } from 'vue';

import { QUERY_OPTIONS } from '@/config';

import * as igHashtagsAPI from '@/apis/social-listening/ig-hashtags';

export const generateIgHashtagsPostVolumeKey = (filters) => ['ig-hashtags-post-volume', filters];

export async function fetchIgHashtagsPostVolume(
  { hashtags, startDate, endDate, rule = 'and' },
  axiosConfig = {},
) {
  const response = await igHashtagsAPI.getIgHashtagsPostVolume(
    { hashtags, startDate, endDate, rule },
    axiosConfig,
  );
  return response?.data?.data;
}

export const useIgHashtagsPostVolume = defineQuery(() => {
  const hashtagName = ref(null);
  const relatedHashtagName = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);

  const filters = computed(() => ({
    hashtags: [hashtagName.value, relatedHashtagName.value].filter(Boolean),
    startDate: startDate.value,
    endDate: endDate.value,
  }));

  const enabled = computed(() => hashtagName.value && startDate.value && endDate.value);

  const { data, ...rest } = useQuery({
    enabled,
    key: () => generateIgHashtagsPostVolumeKey(filters.value),
    query: ({ signal }) => {
      return fetchIgHashtagsPostVolume(filters.value, { signal });
    },
    ...QUERY_OPTIONS,
    refetchOnMount: enabled.value,
  });

  return {
    hashtagName,
    relatedHashtagName,
    startDate,
    endDate,
    postVolume: data,
    ...rest,
  };
});
