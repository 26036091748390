<script setup>
import { computed, ref, useSlots, watch } from 'vue';
import { useElementSize } from '@vueuse/core';
import SelectionDisplayInput from '@/components/foundation/SelectionDisplayInput.vue';

const props = defineProps({
  value: {
    type: [String, Array],
    default: null,
  },
  applyText: {
    type: String,
    default: 'Select',
  },
  clearText: {
    type: String,
    default: 'Clear',
  },
  autoWidth: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  clearButtonDisabled: {
    type: Boolean,
    default: false,
  },
  applyButtonDisabled: {
    type: Boolean,
    default: false,
  },
  openDropdownOnMount: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clear', 'open', 'apply', 'auto-hide']);

const slots = useSlots();

const open = ref(props.openDropdownOnMount);
const selectionDisplay = ref(null);
const { width } = useElementSize(selectionDisplay, {}, { box: 'border-box' });
const popupWidth = computed(() => {
  return props.autoWidth ? width.value : 216;
});

function onClear() {
  if (props.clearButtonDisabled) return;
  emit('clear');
  open.value = false;
}

function onApply() {
  if (props.applyButtonDisabled) return;
  emit('apply');
  open.value = false;
}

function onAutoHide() {
  emit('auto-hide');
}
watch(open, () => emit('open', open.value));
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <VDropdown
    v-model:shown="open"
    placement="bottom-start"
    theme="dh-dropdown-full"
    boundary="viewport"
    :triggers="props.disabled ? [] : undefined"
    auto-boundary-max-size
    @auto-hide="onAutoHide"
  >
    <template v-if="!!slots.selectionDisplayInput">
      <slot name="selectionDisplayInput" />
    </template>
    <SelectionDisplayInput
      v-else
      v-bind="$attrs"
      ref="selectionDisplay"
      :value="props.value"
      :open="open"
      :disabled="props.disabled"
      @clear="emit('clear', $event)"
    >
      <slot name="SelectionDisplayContent">
        {{ props.value }}
      </slot>
    </SelectionDisplayInput>
    <template #popper>
      <div
        class="filter-dropdown-wrapper text-primary"
        :style="{
          minWidth: `${popupWidth}px`,
          maxWidth: `${popupWidth}px`,
        }"
      >
        <div class="max-h-96 overflow-y-auto overflow-x-hidden pt-2">
          <slot />
        </div>
        <template v-if="Boolean(slots.floatingFooter)">
          <slot name="floatingFooter" />
        </template>
        <div class="flex justify-between bg-grey-300 px-5 py-3">
          <div>
            <span
              class="text-small-medium"
              :class="{
                'cursor-not-allowed text-gray-400': props.clearButtonDisabled,
                'cursor-pointer': !props.clearButtonDisabled,
              }"
              @click.prevent="onClear"
            >
              {{ props.clearText }}
            </span>
          </div>
          <div>
            <span
              :class="{
                'cursor-not-allowed text-gray-400': props.applyButtonDisabled,
                'cursor-pointer': !props.applyButtonDisabled,
              }"
              class="text-small-medium text-link"
              @click.prevent="onApply"
            >
              {{ props.applyText }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<style lang="postcss" scoped>
.filter-dropdown-wrapper {
  border-radius: var(--round-corner-small);
}
</style>
