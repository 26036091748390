<script setup>
import { ContentBanner, Button, Menu, useToast, useDrawer } from '@dashhudson/dashing-ui';
import { computed, ref } from 'vue';
import { useClipboard } from '@vueuse/core';
import { useCreatorsStore } from '@/stores/creators';
import { useTrackingStore } from '@/stores/tracking';
import { storeToRefs } from 'pinia';
import { inviteStatusDetails, creatorSource } from '@/app/creators/constants';
import { useCreators } from '@/app/creators/composables/creators';
import { CHANNELS } from '@/models/dashboards/channels.enum';

const props = defineProps({
  useLongerMessage: {
    type: Boolean,
    default: false,
  },
});

const { saveInfluencerInstagramDataAndFormatConnectionLink } = useCreators();

const trackingStore = useTrackingStore();

const creatorsStore = useCreatorsStore();

const { creator } = storeToRefs(creatorsStore);
const creatorIG = computed(() => creator?.value?.accounts?.instagram);

const fetchingConnectionLink = ref(false);
const instagramConnectionLink = ref();

const { addToast } = useToast({
  life: 5000,
});
async function copyInvite() {
  fetchingConnectionLink.value = true;
  const link = instagramConnectionLink.value
    ? instagramConnectionLink.value
    : await saveInfluencerInstagramDataAndFormatConnectionLink(creatorIG.value);
  const { copy } = useClipboard({ link });
  copy(link);
  trackingStore.track('Connection Invite Link Copied', {
    source: creatorSource.INSTAGRAM,
  });
  addToast({
    severity: 'success',
    message: 'Link Copied',
  });
  fetchingConnectionLink.value = false;
}

const { launchDrawer } = useDrawer();
function launchEmailInvitationDrawer() {
  launchDrawer({
    name: 'emailInvitation',
    props: {
      creatorId: creator?.value?.creatorId,
      email: creator?.value?.email,
      handle: creator?.value?.handle,
      sourceCreatorId: creator?.value?.accounts?.instagram?.sourceCreatorId,
      source: CHANNELS.INSTAGRAM.value,
    },
  });
}

const isInYourCreators = computed(() => !!creatorIG?.value?.sourceCreatorId);
const instagramConnectionRequested = computed(() => {
  return [inviteStatusDetails.ACCEPTED.value, inviteStatusDetails.INVITATION_SENT.value].includes(
    creatorIG?.value?.invitationStatus,
  );
});
const creatorEmail = computed(() => creator?.value?.email);

const inviteTooltipCopy = computed(() =>
  creatorEmail.value
    ? 'Email invitation will be sent from "noreply@dashsocial.com" by default. '
    : "Creator email not available. Please add it in the About tab of the creator's profile.",
);
const connectionTooltip = computed(() => {
  return instagramConnectionRequested.value
    ? "The creator has been invited to connect, but the data remains unavailable as they weren't connected at the time of posting"
    : null;
});

const mediaUnavailableMessage = computed(() => {
  const prefix = props.useLongerMessage ? 'Story media unavailable. ' : '';
  return isInYourCreators.value
    ? `${prefix}Invite this creator to connect to access future Stories media and analytics.`
    : `${prefix}Add this user to Your Creators and invite them to connect to access future Stories media and analytics.`;
});

const inviteToConnectMenuOptions = computed(() => {
  return [
    {
      label: 'Copy Invite Connection Link',
      icon: 'copy',
      command: () => {
        copyInvite();
      },
    },
    {
      label: 'Send Email Invitation',
      icon: 'mail-unread',
      tooltip: inviteTooltipCopy.value,
      disabled: !creatorEmail.value,
      command: () => launchEmailInvitationDrawer(),
    },
  ];
});
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <ContentBanner class="!w-full" variant="sectionEmpty" :message="mediaUnavailableMessage" />
    <Menu
      v-if="isInYourCreators"
      data-testid="inviteToConnectMenu"
      :model="inviteToConnectMenuOptions"
      :dropdown="{ placement: 'bottom-start' }"
    >
      <template #default="{ shown }">
        <Button
          v-dash-tooltip="connectionTooltip"
          severity="secondary"
          size="lg"
          :active="shown"
          data-testid="inviteToConnect"
          :loading="fetchingConnectionLink"
          :disabled="fetchingConnectionLink || instagramConnectionRequested"
        >
          Invite to Connect
        </Button>
      </template>
    </Menu>
  </div>
</template>
