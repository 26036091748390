<script setup>
import { watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Drawer, useDrawer, useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import { isScreenXl } from '@/utils/tailwind';
import { useGlobalModals } from '@/composables/useGlobalModals';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import { getGlobalModals } from '@/utils/globalModals';
import { registerDrawers } from '@/drawerRegistry';

const route = useRoute();
const router = useRouter();
const { stateKey, launchDrawer, closeDrawer, currentDrawer, parseStateKey } = useDrawer();
const { hasGlobalModal } = useGlobalModals();

registerDrawers();

watch(
  () => hasGlobalModal.value,
  () => {
    if (hasGlobalModal.value) {
      closeDrawer();
    }
  },
);

function isSameStateKey(leftStateKey, rightStateKey) {
  return isEqual(leftStateKey, rightStateKey);
}

function isSameDrawer(leftStateKey, rightStateKey) {
  const leftStateKeyObject = parseStateKey(leftStateKey);
  const rightStateKeyObject = parseStateKey(rightStateKey);
  return isEqual(leftStateKeyObject?.name, rightStateKeyObject?.name);
}

function changeDrawerQuery({ d, replace = false }) {
  const globalModalsOpen = getGlobalModals()?.length > 0;

  if (!globalModalsOpen) {
    // work around for global modals
    router[replace ? 'replace' : 'push']({
      ...route,
      query: {
        ...route.query,
        d,
      },
    });
  }
}

const pushOnPropChange = computed(() => {
  if (isFunction(currentDrawer.value?.meta?.pushHistoryOnPropChange)) {
    return currentDrawer.value.meta.pushHistoryOnPropChange();
  }
  return !!currentDrawer.value?.meta?.pushHistoryOnPropChange;
});

// Watch for stateKey changes and update the route query
watch(
  () => stateKey.value,
  (sk, oldSk) => {
    if (currentDrawer.value?.meta?.excludeFromRouter) {
      return;
    }

    if (!sk) {
      if (!hasGlobalModal.value) {
        changeDrawerQuery({ d: undefined });
      }
    } else if (!isSameDrawer(sk, oldSk) || pushOnPropChange.value) {
      changeDrawerQuery({ d: sk });
    } else if (!isSameStateKey(sk, route.query.d)) {
      changeDrawerQuery({ d: sk, replace: true });
    }
  },
);

function updateDrawerState() {
  if (isSameStateKey(stateKey.value, route.query.d)) {
    return;
  }

  if (route.query?.d && !route?.meta?.report) {
    launchDrawer({ ...route.query, stateKey: route.query.d });
  } else {
    closeDrawer();
  }
}

onMounted(() => {
  updateDrawerState();
});

watch(route, () => {
  updateDrawerState();
});

const { expanded, collapseNavigationPanel } = useSecondaryNavigationPanel();

watch(
  () => [isScreenXl.value, currentDrawer.value],
  () => {
    if (!isScreenXl.value && expanded.value && currentDrawer.value) {
      collapseNavigationPanel();
    }
  },
);

router.afterEach((to, from) => {
  if (to?.path !== from?.path) {
    closeDrawer();
  }
});

const { visible: filterVisible } = useFilterSidePanel();

const drawerMaxWidth = computed(() => {
  let usedSpace = '(var(--primary-navigation-width) + 40px)';
  if (filterVisible.value) {
    usedSpace = 'var(--filter-side-panel-width)';
  } else if (expanded.value) {
    if (isScreenXl.value) {
      usedSpace = '(var(--combined-navigation-width) + 40px)';
    }
  }
  return `calc(100% - ${usedSpace})`;
});

/**
 * Configure's the Drawer's KeepAlive component.
 *
 * You can read up on how KeepAlive works in the vue docs:
 * https://vuejs.org/guide/built-ins/keep-alive
 */
const keepAlive = computed(() => {
  return {
    include: [
      'CreatorProfile',
      'AccountProfile',
      'AssignDeliverables',
      'TopPerformingCreators',
      'AddCreatorsWizard',
      'DateRangeEditor',
      'EmailInvitation',
      'SocialListeningTopicCreator',
      'SocialListeningTrendingKeywords',
      'SocialListeningTopPerformingPosts',
      'SocialListeningVisualTrends',
      'SocialListeningVisualTrend',
      'CampaignCreationDrawer',
      'EventDrawer',
      'SocialListeningVisualFilters',
      'EditPostDrawerWrapper',
      'PinterestBulkDrawerContent',
      'DashboardTemplates',
    ],
  };
});
</script>

<template>
  <Drawer
    :keep-alive="keepAlive"
    :style="{
      maxWidth: drawerMaxWidth,
    }"
  />
</template>

<style lang="postcss">
.hs-web-interactives-top-banner-open {
  [data-dash-drawer] {
    top: var(--hubspot-banner-height) !important;
  }
}
</style>
