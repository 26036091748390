import merge from 'lodash/merge';
import snakeCase from 'lodash/snakeCase';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';
import { createExternalAxiosInstance } from '@/apis/external-apis';

const axios = createAxiosInstance(env.authApiUrl);
const noAuthAxios = createAxiosInstance(env.authApiUrl, {
  includeAuth: false,
});
const simpleAxios = createExternalAxiosInstance({
  baseURL: env.authApiUrl,
});

export async function getSelf(axiosConfig = {}) {
  return axios.get('/self', {
    ...axiosConfig,
  });
}

export async function validateApiKey({ token } = {}, axiosConfig = {}) {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  // Using an external axios instance here since we don't want impersonation or to logout if this fails
  return simpleAxios.get('/self', {
    headers,
    ...axiosConfig,
  });
}

export async function getSelfSubscriptions({ token } = {}, axiosConfig = {}) {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios.get('/self/subscriptions', {
    headers,
    camelizeKeys: true,
    ...axiosConfig,
  });
}

export async function getSelfActionMetadata(axiosConfig = {}) {
  return axios.get('/self/action_metadata', {
    camelizeKeys: true,
    ...axiosConfig,
  });
}

export async function createToken({ email, password, oauth, otpCode }, axiosConfig = {}) {
  return axios.post(
    '/tokens',
    {
      email,
      password,
      oauth,
      otpCode,
    },
    merge({ withCredentials: true }, axiosConfig),
  );
}

export async function destroyToken(axiosConfig = {}) {
  return axios.delete('/tokens/self', {
    withCredentials: true,
    ...axiosConfig,
  });
}

export async function getUserDetails({ userId }, axiosConfig = {}) {
  return axios.get(`/users/${userId}`, axiosConfig);
}

export async function updateUser({ userId, data }, axiosConfig = {}) {
  return axios.patch(`/users/${userId}`, data, axiosConfig);
}

export async function resetUserPassword({ userId, hash, password }, axiosConfig = {}) {
  return noAuthAxios.post(`/users/${userId}/reset_password`, { hash, password }, axiosConfig);
}

export async function changeUserPassword({ userId, password, oldPassword }, axiosConfig = {}) {
  return axios.post(
    `/users/${userId}/change_password`,
    { password, oldPassword },
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function getBrands({ params }, axiosConfig = {}) {
  return noAuthAxios.get('/brands', merge({ params }, axiosConfig));
}

export async function getBrandIntegrations({ brandId } = {}, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/integrations`, merge({ camelizeKeys: true }, axiosConfig));
}

export async function getUserIntegrations(axiosConfig = {}) {
  return axios.get(`/self/integrations`, merge({ camelizeKeys: true }, axiosConfig));
}

export async function postOAuth({ provider, redirectUri, brandId }, axiosConfig = {}) {
  const providerFormatted = provider === 'pinterest_v5' ? 'pinterest_v5' : snakeCase(provider);
  return axios.post(
    `/${providerFormatted}/oauth`,
    {
      redirect_uri: redirectUri,
      brand_id: brandId,
    },
    axiosConfig,
  );
}

export async function getBrandUsers(
  {
    brandId,
    limit = null,
    offset = 0,
    email = null,
    search = null,
    sort = null,
    includeRestricted,
  },
  axiosConfig = {},
) {
  const params = {
    limit,
    offset,
    includeRestricted,
  };
  if (email) {
    params.email = email;
  }
  if (search) {
    params.search = search;
  }
  if (sort) {
    params.sort = sort;
  }
  return axios.get(
    `/brands/${brandId}/users`,
    merge(
      { camelizeKeys: true },
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export async function createBrandUser({ brandId, email, permissions, role }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/users`,
    {
      email,
      permissions,
      role,
    },
    axiosConfig,
  );
}

export async function getBrandUser({ brandId, userId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/users/${userId}`, axiosConfig);
}

export async function updateBrandUser({ brandId, userId, role, permissions }, axiosConfig = {}) {
  return axios.patch(
    `/brands/${brandId}/users/${userId}`,
    {
      role,
      permissions,
    },
    axiosConfig,
  );
}

export async function removeBrandUser({ brandId, userId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/users/${userId}`, axiosConfig);
}

export async function resendBrandUserInvitation({ brandId, userId }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/users/${userId}/resend_invitation`, axiosConfig);
}

export async function getBrandUserExportUserList({ userId }, axiosConfig = {}) {
  return axios.get(`/brands/user/${userId}/exports/user_list`, axiosConfig);
}

export async function getSAMLIdP({ email }, axiosConfig = {}) {
  return noAuthAxios.get(
    `/saml_idps`,
    merge(
      {
        params: {
          email,
        },
      },
      axiosConfig,
    ),
  );
}

export async function postSamlAssertion({ samlRequest, samlRelayState }, axiosConfig = {}) {
  return axios.post(
    '/saml_assertions',
    {
      saml_request: samlRequest,
      relay_state: samlRelayState,
    },
    axiosConfig,
  );
}

export async function generateApiKey({ brandId, label }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/api_keys`, { label }, axiosConfig);
}

export async function getApiKeysForBrand({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/api_keys`, merge({ camelizeKeys: true }, axiosConfig));
}

export async function deleteApiKeyForBrand({ brandId, apiKeyId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/api_keys/${apiKeyId}`, axiosConfig);
}

export async function generatePasswordReset({ email }, axiosConfig = {}) {
  return axios.post('password_resets', { email }, axiosConfig);
}

export async function getPasswordResetStatus({ hash, userId }, axiosConfig = {}) {
  return axios.get(`password_resets/${hash}`, merge({ params: { userId } }, axiosConfig));
}

export async function getPlatformConnections(
  { brandIds, brandId, brandToken, platformType },
  axiosConfig = {},
) {
  const params = {};
  if (brandId) {
    params.brandId = brandId;
    params.brandToken = brandToken;
    params.platformType = platformType;
  } else if (brandIds) {
    params.brandIds = Array.isArray(brandIds) ? brandIds.join(',') : brandIds;
  }
  const axiosInstance = brandToken ? noAuthAxios : axios;
  return axiosInstance.get(`/platform_connections`, merge({ params }, axiosConfig));
}

export async function createPlatformConnection(
  { brandId, oauth, brandToken, platformType, invitedBy, sourceCreatorId, creatorHandleName, page },
  axiosConfig = {},
) {
  const axiosInstance = brandToken ? noAuthAxios : axios;
  return axiosInstance.post(
    `/platform_connections`,
    {
      brandId,
      oauth,
      brandToken,
      platformType,
      invitedBy,
      page,
      // Used in TikTok creator OAuth flow
      sourceCreatorId,
      creatorHandleName,
    },
    axiosConfig,
  );
}

export async function updatePlatformConnection(
  { connectionId, brandId, oauth, brandToken, platformType, invitedBy },
  axiosConfig = {},
) {
  const axiosInstance = brandToken ? noAuthAxios : axios;
  return axiosInstance.patch(
    `/platform_connections/${connectionId}`,
    { brandId, oauth, brandToken, platformType, invitedBy },
    axiosConfig,
  );
}

export async function deletePlatformConnection({ connectionId }, axiosConfig = {}) {
  return axios.delete(`/platform_connections/${connectionId}`, axiosConfig);
}

export async function getAccounts(
  { brandId, oauth, brandToken, platformType, invitedBy },
  axiosConfig = {},
) {
  const axiosInstance = brandToken ? noAuthAxios : axios;
  return axiosInstance.post(
    `/platform_connections/accounts`,
    { brandId, oauth, brandToken, platformType, invitedBy },
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function createBillingCheckoutSession(
  { brandId, priceLookupKey, redirectDomain },
  axiosConfig = {},
) {
  return axios.post(
    `/billing/checkout_sessions`,
    { brandId, priceLookupKey, redirectDomain },
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function setBrandTrialDates({ brandId, dhProductSubscriptionId }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/trial`,
    { dhProductSubscriptionId },
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function setActionMetadata({ brandId, actionContext, payload }, axiosConfig = {}) {
  return axios.patch(
    `/brands/${brandId}/action_metadata/${actionContext}`,
    payload,
    merge({ camelizeKeys: false }, axiosConfig),
  );
}

export async function getUsers({ params }, axiosConfig = {}) {
  return axios.get('/users', { ...axiosConfig, params });
}

export async function getBrandTags({ organizationId }, axiosConfig = {}) {
  return axios.get(
    `/organizations/${organizationId}/brand_tags`,
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function createBrandTag({ organizationId, name, color }, axiosConfig = {}) {
  return axios.post(
    `/organizations/${organizationId}/brand_tags`,
    { name, color },
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function createBrandTagAssociations(
  { organizationId, brandIds, brandTagIds },
  axiosConfig = {},
) {
  const requests = [];
  const chunkSize = 10;
  for (let i = 0; i < brandIds.length; i += chunkSize) {
    const brandIdsChunk = brandIds.slice(i, i + chunkSize);
    requests.push(
      axios.post(
        `/organizations/${organizationId}/brand_associations`,
        { brandIds: brandIdsChunk, brandTagIds },
        merge({ camelizeKeys: true }, axiosConfig),
      ),
    );
  }
  await Promise.all(requests);
  return true;
}

export async function removeTagFromBrand(
  { organizationId, brandId, brandTagId },
  axiosConfig = {},
) {
  return axios.delete(
    `/organizations/${organizationId}/brands/${brandId}/tags/${brandTagId}`,
    axiosConfig,
  );
}

export async function deleteBrandTag({ organizationId, brandTagId }, axiosConfig = {}) {
  return axios.delete(`/organizations/${organizationId}/brand_tags/${brandTagId}`, axiosConfig);
}

export async function updateSubscriptionItem(
  { organizationId, priceLookupKey, subscriptionId = null },
  axiosConfig = {},
) {
  const data = { priceLookupKey };
  if (subscriptionId) {
    data.subscriptionId = subscriptionId;
  }
  return axios.post(
    `/organizations/${organizationId}/subscription_items`,
    data,
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function getOrganizationSubscriptions(
  { organizationId, params = { brandId: null } },
  axiosConfig = {},
) {
  return axios.get(
    `/organizations/${organizationId}/dh_product_subscriptions`,
    merge({ camelizeKeys: true }, { params }, axiosConfig),
  );
}

export async function getSubscriptionSourceData({ organizationId, sourceIds }, axiosConfig = {}) {
  return axios.get(
    `/organizations/${organizationId}/subscription_source_data`,
    merge({ camelizeKeys: true }, { params: { sourceIds: sourceIds.join(',') } }, axiosConfig),
  );
}

export async function getCompanyCustomerInformation(companyId, axiosConfig = {}) {
  return axios.get(
    `/companies/${companyId}/customer_information`,
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function createTrialSubscription(
  { organizationId = null, brandId = null, dhProductLabel },
  axiosConfig = {},
) {
  return axios.post(
    `/dh_product_subscriptions/start_trial`,
    { brandId, organizationId, dhProductLabel },
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function getHubspotSupportToken(axiosConfig = {}) {
  return axios.post(`/self/hubspot_visitor_token`, merge({ camelizeKeys: true }, axiosConfig));
}
