import { Buffer } from 'buffer';
import { useIntegrationStore } from '@/stores/integration';
import { backoffRetry } from '@/utils/paragon';

const GMAIL = 'gmail';
const GMAIL_API = 'gmail/v1/users';

export const useParagonGmailAPI = () => {
  const integrationStore = useIntegrationStore();

  return {
    async getMessage({ messageId }) {
      return backoffRetry(() =>
        integrationStore.request(GMAIL, `${GMAIL_API}/me/messages/${messageId}`, {
          method: 'GET',
        }),
      );
    },

    async getThread({ threadId }) {
      return backoffRetry(() =>
        integrationStore.request(GMAIL, `${GMAIL_API}/me/threads/${threadId}`, {
          method: 'GET',
        }),
      );
    },

    async getThreadsList({ query, labelIds, limit, pageToken }) {
      const params = new URLSearchParams({
        q: query,
        maxResults: limit,
      });

      if (pageToken) {
        params.append('pageToken', pageToken);
      }

      if (labelIds) {
        params.append('labelIds', labelIds);
      }

      const url = `${GMAIL_API}/me/threads?${params.toString()}`;

      return backoffRetry(() =>
        integrationStore.request(GMAIL, url, {
          method: 'GET',
        }),
      );
    },

    async modifyThread({ threadId, addLabelIds, removeLabelIds }) {
      return backoffRetry(() =>
        integrationStore.request(GMAIL, `${GMAIL_API}/me/threads/${threadId}/modify`, {
          method: 'POST',
          body: {
            addLabelIds,
            removeLabelIds,
          },
        }),
      );
    },

    async sendMessage({ bcc, body, cc, from, rfcMessageId, subject, threadId, to, attachments }) {
      const encodeAttachment = (attachment) => {
        return `
--boundary_string
Content-Type: ${attachment.mimeType}; name="${attachment.filename}"
Content-Disposition: attachment; filename="${attachment.filename}"
Content-Transfer-Encoding: base64

${Buffer.from(attachment.content, 'base64').toString('base64')}
`;
      };

      let emailBody = `Content-Type: multipart/mixed; boundary="boundary_string"
MIME-Version: 1.0
To: ${to}
From: ${from}
Cc: ${cc}
Bcc: ${bcc}
Subject: ${subject}
References: ${rfcMessageId}
In-Reply-To: ${rfcMessageId}

--boundary_string
Content-Type: text/html; charset=UTF-8
Content-Transfer-Encoding: 7bit

${body}
`;

      if (attachments && attachments.length > 0) {
        attachments.forEach((attachment) => {
          emailBody += encodeAttachment(attachment);
        });
      }

      emailBody += '--boundary_string--';

      return backoffRetry(() =>
        integrationStore.request(GMAIL, `${GMAIL_API}/me/messages/send`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            threadId,
            raw: Buffer.from(emailBody).toString('base64'),
          },
        }),
      );
    },

    async getAttachment({ messageId, attachmentId }) {
      return backoffRetry(() =>
        integrationStore.request(
          GMAIL,
          `${GMAIL_API}/me/messages/${messageId}/attachments/${attachmentId}`,
          {
            method: 'GET',
          },
        ),
      );
    },
  };
};
