<script setup>
import cloneDeep from 'lodash/cloneDeep';
// Vue
import { computed, onMounted, ref, watch } from 'vue';
// Components
import { Icon, RichContentDialog, useToast } from '@dashhudson/dashing-ui';
// Stores
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { useAuthStore } from '@/stores/auth';
import { useFlagStore } from '@/stores/flag';
import { useChiliPiperStore } from '@/stores/chili-piper';
// Constants
import { PRODUCT_MAP, START_TRIAL } from '@/models/auth/customer-plan.enum';
// Utils
import { trackStartTrialResult, trackTrialOverviewModal } from '@/app/settings/mixpanel';
import { env } from '@/env';

const customerJourneyStore = useCustomerJourneyStore();
const authStore = useAuthStore();
const flagStore = useFlagStore();
const chiliPiperStore = useChiliPiperStore();

const { addToast } = useToast({
  life: 10000,
});

const preventSuccessCallback = ref(false);

const hasInAppTrialsFlag = computed(() => flagStore.ready && flagStore.flags.inAppTrials);
const product = computed(() => customerJourneyStore.popups.startTrial.product);
const productKey = computed(() =>
  product.value === PRODUCT_MAP.PREMIUM_ANALYTICS &&
  !customerJourneyStore.isEligibleForPremiumAnalytics
    ? PRODUCT_MAP.ADVANCE_PREMIUM
    : product.value,
);
const trialInfo = computed(() => START_TRIAL[PRODUCT_MAP[productKey.value]]);
const ctaButton = computed(() => {
  const btnClass =
    product.value in PRODUCT_MAP ? `${PRODUCT_MAP[product.value].toLowerCase()}_trial_btn` : '';
  return { label: 'Start Free Trial', severity: 'upgrade', class: `${btnClass} trial-btn` };
});
const mixpanelProperties = computed(() => {
  const startTrialProduct = START_TRIAL[product.value];
  return product.value === PRODUCT_MAP.PREMIUM_ANALYTICS &&
    !customerJourneyStore.isEligibleForPremiumAnalytics
    ? {
        [startTrialProduct.trackingKey]: startTrialProduct.product_name,
        plan: START_TRIAL.ADVANCE.product_name,
      }
    : { [startTrialProduct.trackingKey]: startTrialProduct.product_name };
});

const emits = defineEmits(['close']);

function close() {
  emits('close');
}
async function startTrial() {
  if (!hasInAppTrialsFlag.value) {
    addToast({
      severity: 'error',
      message:
        'You have exceeded the maximum number of in-app trials. Please speak to your Account Owner to trial this subscription.',
    });
    trackStartTrialResult({ ...mixpanelProperties.value, warning: true });
    preventSuccessCallback.value = true;
    return;
  }
  if (
    productKey.value === PRODUCT_MAP.SOCIAL_LISTENING &&
    env.bannedSLTrialOrgs.includes(authStore.currentBrand.organization.id)
  ) {
    addToast({
      severity: 'error',
      message:
        'Your organization cannot trial Social Listening. Speak with your Account Owner for details.',
    });
    trackStartTrialResult({ ...mixpanelProperties.value, warning: true });
    preventSuccessCallback.value = true;
    return;
  }
  if (trialInfo.value?.chilipiper) {
    chiliPiperStore.submit(
      trialInfo.value.chilipiper.router,
      trialInfo.value.chilipiper.form,
      ctaButton.value.label,
    );
    preventSuccessCallback.value = true;
    close();
    return;
  }

  if (productKey.value === PRODUCT_MAP.ADVANCE_PREMIUM) {
    await customerJourneyStore.createTrialSubscription(PRODUCT_MAP.ADVANCE);
  }
  await customerJourneyStore.createTrialSubscription(product.value);
}

async function onSuccess() {
  if (preventSuccessCallback.value) {
    preventSuccessCallback.value = false;
    return;
  }

  // After re-authenticating this value could change if advance+premium is involved
  const trialInfoCopy = cloneDeep(trialInfo.value);
  const mixpanelPropertiesCopy = cloneDeep(mixpanelProperties.value);
  await authStore.identify();
  addToast({
    severity: 'success',
    summary: 'Welcome to Your Trial!',
    message: `Your 14-day trial of the ${trialInfoCopy.product_name} subscription is now live`,
    life: 10000,
  });
  trackStartTrialResult({ ...mixpanelPropertiesCopy, success: true });
  close();
}

function onError() {
  addToast({
    severity: 'error',
    message: 'Please try again. If the problem persists, contact us for assistance',
    life: 10000,
  });
  trackStartTrialResult({ ...mixpanelProperties.value, error: true });
}

watch(
  () => 'customerJourneyStore.popups.startTrial.show',
  (value) => {
    if (!value) {
      close();
    }
  },
);

onMounted(() => {
  trackTrialOverviewModal(mixpanelProperties.value);
});
</script>

<template>
  <RichContentDialog
    v-model:visible="customerJourneyStore.popups.startTrial.show"
    :chip="trialInfo.product_type"
    :title="trialInfo.title"
    :cta-button="ctaButton"
    :banner-src="trialInfo.imageSrc"
    :banner-srcset="trialInfo.imageSrcSet"
    :cta-callback="startTrial"
    dismiss-label="Do It Later"
    @cta="onSuccess"
    @cta-error="onError"
  >
    <div class="dash-flex dash-flex-col dash-gap-4">
      <div
        v-for="line in trialInfo.description_items"
        :key="line"
        class="dash-flex dash-flex-row dash-items-center dash-gap-4"
      >
        <Icon name="check" size="24" />
        <div class="dash-typo-lg">{{ line }}</div>
      </div>
    </div>
  </RichContentDialog>
</template>
