import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useSchedulerStore } from '@/stores/scheduler';
// eslint-disable-next-line import/no-cycle
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { USER_TAG_TYPE } from '@/app/scheduler/constants';

export const useInstagramUserTaggerStore = defineStore(
  'instagramUserTagger',
  () => {
    const schedulerStore = useSchedulerStore();
    const instagramShoppingTaggerStore = useInstagramShoppingTaggerStore();

    const enabled = ref(false);
    const editingUserTag = ref(false);
    const internalTaggedUsers = ref({});
    const collaborators = ref([]);
    const enabledTagCounter = ref(false);

    const activeMediaId = computed(() => {
      return schedulerStore.carouselActiveMedia?.id;
    });

    const taggedUsers = computed(() =>
      Object.keys(internalTaggedUsers.value).reduce((tags, mediaId) => {
        internalTaggedUsers.value[mediaId].forEach((tag) => tags.push({ mediaId, ...tag }));
        return tags;
      }, []),
    );

    const totalUserTags = computed(() => {
      return collaborators.value.length + taggedUsers.value.length;
    });

    const activeMediaTaggedUsers = computed(() => {
      return internalTaggedUsers.value?.[activeMediaId.value] ?? [];
    });
    const totalMediaTagsAndProductTags = computed(() => {
      const totalTaggedProducts = instagramShoppingTaggerStore.taggedProducts?.length ?? 0;
      const totalTaggedUsers = taggedUsers.value?.length ?? 0;
      return totalTaggedUsers + totalTaggedProducts;
    });
    const totalActiveMediaTags = computed(() => {
      return (
        activeMediaTaggedUsers.value.length +
        (instagramShoppingTaggerStore.activeMediaTaggedProducts?.length ?? 0)
      );
    });

    function getTagsLabel(tagType) {
      const tags = tagType === USER_TAG_TYPE.MEDIA_TAG ? taggedUsers.value : collaborators.value;
      if (tags.length === 1) {
        return tags[0].username;
      }
      if (tags.length > 1) {
        const numOthers = tags.length - 1;
        const plural = numOthers > 1 ? 's' : '';
        return `${tags[0].username} and ${numOthers} other${plural}`;
      }
      return null;
    }

    function enable() {
      enabled.value = true;
    }

    function disable() {
      enabled.value = false;
    }

    function enableTagCounter() {
      enabledTagCounter.value = true;
    }

    function disableTagCounter() {
      enabledTagCounter.value = false;
    }

    function setEditingUserTag({ value }) {
      editingUserTag.value = value;
    }
    function addUserTag({ mediaId = activeMediaId.value, tag }) {
      if (!(mediaId in internalTaggedUsers.value)) {
        internalTaggedUsers.value = {
          ...internalTaggedUsers.value,
          [mediaId]: [],
        };
      }
      internalTaggedUsers.value[mediaId].push(tag);
    }
    function updateUserTag({
      mediaId = activeMediaId.value,
      index = internalTaggedUsers.value[mediaId].length,
      tag,
    }) {
      const existingTag = internalTaggedUsers.value[mediaId][index];
      internalTaggedUsers.value[mediaId].splice(index, 1, { ...existingTag, ...tag });
    }

    function addCollaborator(collaborator) {
      collaborators.value.push(collaborator);
    }

    function deleteCollaborator(index) {
      collaborators.value.splice(index, 1);
    }

    function clearCollaborators() {
      collaborators.value = [];
    }

    function deleteUserTag({
      mediaId = activeMediaId.value,
      index = internalTaggedUsers.value[mediaId].length - 1,
    }) {
      internalTaggedUsers.value[mediaId].splice(index, 1);
    }
    function setUserTags({ tags }) {
      internalTaggedUsers.value = {};
      tags.forEach(({ mediaId, ...tag }) => {
        if (!(mediaId in internalTaggedUsers.value)) {
          internalTaggedUsers.value = {
            ...internalTaggedUsers.value,
            [mediaId]: [],
          };
        }
        internalTaggedUsers.value[mediaId].push(tag);
      });
    }
    function clearUserTags() {
      internalTaggedUsers.value = {};
    }
    function deleteMediaUserTags({ mediaId }) {
      const newValue = {
        ...internalTaggedUsers.value,
      };
      delete newValue[mediaId];
      internalTaggedUsers.value = newValue;
    }

    return {
      enabled,
      enabledTagCounter,
      editingUserTag,
      taggedUsers,
      collaborators,
      totalUserTags,
      totalMediaTagsAndProductTags,
      totalActiveMediaTags,
      activeMediaId,
      activeMediaTaggedUsers,
      enable,
      disable,
      enableTagCounter,
      disableTagCounter,
      setEditingUserTag,
      addUserTag,
      updateUserTag,
      deleteUserTag,
      setUserTags,
      clearUserTags,
      deleteMediaUserTags,
      addCollaborator,
      deleteCollaborator,
      clearCollaborators,
      getTagsLabel,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
