import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { customTooltip } from '@/components/foundation/charts/chartjs.utils';
import { formatNumber, formatPercentage } from '@/utils/formatters';
import { reportParseDate } from '@/app/dashboards/utils/report-graph.utils';
import {
  GRAPH_SCALE,
  POST_VOLUME_API_CAMEL_CASE_FORMAT,
  POST_VOLUME_API_DATE_FORMAT,
  POST_VOLUME_GRAPH_OPTIONS,
  SOCIAL_LISTENING_CHANNEL_OPTIONS,
  SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS,
  SOCIAL_LISTENING_SENTIMENTS_OPTIONS,
} from '@/app/socialListening/constants';
import { GRAPH_SCALES } from '@/models/dashboards/graph-scales.enum';
import { TOKENS } from '@dashhudson/dashing-ui';

function shouldAddYearSuffix(date) {
  const metricDate = dayjs(date, POST_VOLUME_API_DATE_FORMAT);
  const metricYear = metricDate.year();
  const currentYear = dayjs().year();
  return metricYear !== currentYear;
}

function getDailyMentionIncreasePercentage({
  mentionsRollingSum = 0,
  predictedMentionsRollingSum = 0,
}) {
  if (!predictedMentionsRollingSum) {
    return formatNumber(0, true);
  }
  const increase = (mentionsRollingSum - predictedMentionsRollingSum) / predictedMentionsRollingSum;
  return `${Math.round(increase * 100)}%`;
}

function getIncreaseOverPredictedVolume({
  mentionsRollingSum = 0,
  predictedMentionsRollingSum = 0,
}) {
  if (!predictedMentionsRollingSum) {
    return 0;
  }
  return (mentionsRollingSum - predictedMentionsRollingSum) / predictedMentionsRollingSum;
}

export function getStartDateOfPoint(dataPointDate, rangeStart, outputFormat) {
  /* for the first data point in the graph, check if the grouped date is less than the real
         start date of the range.
         hourly scale will be set as the beginning of the hour - eg. 2023-01-01 05:00:00
         daily scale will be set as the beginning of the day - eg. 2023-01-01 00:00:00
         weekly scale is grouped Monday - Sunday with the date set as the Monday
         monthly scale is grouped by the month with the date set as the 1st of the month
      */
  const rangeStartDate = dayjs(rangeStart, POST_VOLUME_API_DATE_FORMAT);
  const groupedDate = dayjs(dataPointDate, [POST_VOLUME_API_DATE_FORMAT, 'x']);
  const pointStartDate = groupedDate.isBefore(rangeStartDate) ? rangeStartDate : groupedDate;
  return pointStartDate.format(outputFormat);
}

export function getEndDateOfPoint(dataPointDate, rangeEnd, scale, outputFormat) {
  let groupedDate = dayjs(dataPointDate, [POST_VOLUME_API_DATE_FORMAT, 'x']);
  // set date to end of hour/day/week/month to capture period
  let timeUnit = scale?.toLowerCase();
  // enforce Monday - Sunday weeks
  if (scale === GRAPH_SCALE.WEEK) {
    timeUnit = `iso${timeUnit}`;
  }
  groupedDate = groupedDate.endOf(timeUnit);
  const rangeEndDate = dayjs(rangeEnd, POST_VOLUME_API_DATE_FORMAT);
  const pointEndDate = groupedDate.isAfter(rangeEndDate) ? rangeEndDate : groupedDate;
  return pointEndDate.format(outputFormat);
}

function getTooltipDateFormat(date, scale, isAnomaly) {
  let dateFormat = 'MMM D';
  if (scale === GRAPH_SCALE.HOUR) {
    dateFormat += ', hA';
  }
  if (isAnomaly) {
    dateFormat += ', h:mmA';
  }
  if (shouldAddYearSuffix(date)) {
    dateFormat += ', YYYY';
  }
  return dateFormat;
}

export function formatDates(datapointDate, rangeStart, rangeEnd, graphScale) {
  const dateFormat = getTooltipDateFormat(datapointDate, graphScale);
  const formattedStartDate = getStartDateOfPoint(datapointDate, rangeStart, dateFormat);
  const formattedEndDate = getEndDateOfPoint(datapointDate, rangeEnd, graphScale, dateFormat);
  if (formattedStartDate !== formattedEndDate) {
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
  return formattedStartDate;
}

export function formatAnomalyDate(datapointDate, graphScale) {
  const dateFormat = getTooltipDateFormat(datapointDate, graphScale, true);
  const groupedDate = dayjs(datapointDate, [POST_VOLUME_API_DATE_FORMAT, 'x']);
  const roundedTime = dayjs(groupedDate.$d).startOf('minute');
  // find nearest quarter hour
  const roundedMinutes = Math.round(roundedTime.minute() / 15) * 15;
  return roundedTime.minute(roundedMinutes).format(dateFormat);
}

function formatTooltipHeader(
  context,
  graphType,
  graphScale,
  rangeStartDate,
  rangeEndDate,
  isOverlappingPoints,
) {
  const chartConfig = POST_VOLUME_GRAPH_OPTIONS[graphType].chartConfig;
  const tooltipModel = context.tooltip;
  let tooltipDate = tooltipModel.title[0];
  const dataPoint = context.tooltip.dataPoints[0];
  let label = '';
  const disableTooltipLabelPrefix = chartConfig.disableTooltipLabelPrefix;
  if (!disableTooltipLabelPrefix) {
    label = dataPoint.dataset?.label;
    const labelOverrides = chartConfig.labelOverrides ?? {};
    label = labelOverrides[label.toLowerCase()] ?? startCase(label);
    label += ': ';
  }
  const dataset = dataPoint.dataset?.data;
  if (dataset) {
    const datapointDate = dataset[dataPoint.dataIndex]?.x;
    tooltipDate = formatDates(datapointDate, rangeStartDate, rangeEndDate);
  }
  // overlapping points will have separate subheadings per label
  if (isOverlappingPoints) {
    return tooltipDate;
  }
  return `${label}${tooltipDate}`;
}

export function generatePostVolumeTooltip(
  graphType,
  graphScale,
  rangeStartDate,
  rangeEndDate,
  isTeaser = false,
) {
  // TODO: while adding the isTeaser condition to remove the copy, we noticed this is not being tested. This should be tested, techdebt ticket: https://app.shortcut.com/dashhudson/story/119773/add-testing-to-generatepostvolumetooltip
  return customTooltip((context) => {
    const chartConfig = POST_VOLUME_GRAPH_OPTIONS[graphType].chartConfig;
    let contentHtml = '';
    const tooltipModel = context.tooltip;
    if (tooltipModel?.dataPoints?.length > 0) {
      const isOverlappingPoints = tooltipModel.body && tooltipModel.body.length > 1;
      const heading = formatTooltipHeader(
        context,
        graphType,
        graphScale,
        rangeStartDate,
        rangeEndDate,
        isOverlappingPoints,
      );
      contentHtml += `<dl class="pr-3.5"><dt>${heading}</dt></dl>`;

      if (tooltipModel.body) {
        tooltipModel.body.forEach((bodyItem, index) => {
          contentHtml += '<dl class="pr-3.5">';
          const labelOverrides = chartConfig.labelOverrides ?? {};
          const dataPoint = context.tooltip.dataPoints[index];
          const point = dataPoint.dataset?.data[dataPoint.dataIndex];
          const ignoreFields = chartConfig.tooltipIgnoreFields ?? ['x'];
          if (point?.alertsTriggered) {
            // move alerts triggered to last key in the object
            const val = point.alertsTriggered;
            delete point.alertsTriggered;
            point.alertsTriggered = val;
          }
          if (point?.increaseOverPredictedVolume) {
            // move mentions to last key in the object
            const val = point.increaseOverPredictedVolume;
            delete point.increaseOverPredictedVolume;
            point.increaseOverPredictedVolume = val;
          }

          if (isOverlappingPoints) {
            // each legend item will have its own section in the tooltip, unless its an anomaly point
            let sectionLabel = dataPoint.dataset?.label;
            sectionLabel = labelOverrides[sectionLabel.toLowerCase()] ?? startCase(sectionLabel);
            contentHtml += `<dt style="padding-top: 0.5rem; padding-bottom: 0;border-bottom: 0;margin-bottom: 0; font-weight:500">${sectionLabel}</dt>`;
          }

          Object.keys(point).forEach((dataLabel, idx, labels) => {
            let alertInfoDividerClass = '';
            if (labels?.[idx + 1] === 'alertsTriggered' && point?.increaseOverPredictedVolume) {
              alertInfoDividerClass = ` class='border-bottom'`;
            }
            if (!ignoreFields.includes(dataLabel)) {
              let label = dataLabel;
              let labelData = point[label];
              // value can't be preformatted as string since it's used as the y-axis value
              if (label === chartConfig.yAxisField.name) {
                const isPercent = chartConfig.yAxisField.format === 'percent';
                if (isPercent) {
                  labelData = formatPercentage(labelData, 0);
                } else {
                  labelData = formatNumber(labelData);
                }
              }
              label =
                labelOverrides[label.toLowerCase()] ?? labelOverrides[label] ?? startCase(label);
              contentHtml += `
                      <dd style="margin-top:0; white-space: nowrap";${alertInfoDividerClass}>
                        <span class='tooltip-data-name' style="text-transform: none">${label}</span>
                        <span class='tooltip-data'>${labelData}</span>
                      </dd>
                    `;
            }
          });
          const isLastGroup = index === tooltipModel.body.length - 1;
          if (isLastGroup && !isTeaser) {
            contentHtml +=
              '<dt style="color: #adadad; border-bottom:0; margin-bottom:0;  padding-top: 1rem;">Click a node to view posts</dt>';
          }
          contentHtml += '</dl>';
        });
      }
    }
    return contentHtml;
  });
}

/**
 * Get a list of groups names such as channels/media types/sentiments that a graph is expecting to
 * have a datapoint for.
 */
function getGroupList(graphType) {
  switch (graphType) {
    case POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL.value:
      return Object.keys(SOCIAL_LISTENING_CHANNEL_OPTIONS).map((key) => key.toLowerCase());
    case POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.value:
      return Object.keys(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).map((key) => key.toLowerCase());
    case POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_MEDIA_TYPE.value:
      return Object.keys(SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS).map((key) => key.toLowerCase());
    case POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS.value:
      return Object.keys(SOCIAL_LISTENING_CHANNEL_OPTIONS).map((key) => key.toLowerCase());
    default:
      return [];
  }
}

export function zeroMissingPostCountGroups(data, graphType, filters) {
  const existingGroups = new Set(data.map((dataGroup) => dataGroup.group.toLowerCase()));
  const allGroups = getGroupList(graphType);
  allGroups.forEach((groupName) => {
    if (!existingGroups.has(groupName) && filters.includes(groupName)) {
      data.push({
        count: 0,
        group: groupName,
      });
    }
  });
}

function groupTotalPostsWithSecondaryPercentBreakdown(chartData, graphType, filters) {
  const breakdownGroups = {};
  const datedData = chartData ?? {};
  const labelOverrides = POST_VOLUME_GRAPH_OPTIONS[graphType]?.chartConfig.labelOverrides ?? {};
  // group data by primary label and date
  Object.entries(datedData).forEach(([date, data]) => {
    const dataGroups = data.breakdown.data;
    zeroMissingPostCountGroups(dataGroups, graphType, filters);
    dataGroups.forEach((primaryBreakdown) => {
      let label = primaryBreakdown.group.toLowerCase();
      label = labelOverrides[label] ?? startCase(label);
      breakdownGroups[label] ??= [];
      const metrics = {
        x: reportParseDate(date, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
        totalPosts: primaryBreakdown.count,
      };

      const secondaryDataList = primaryBreakdown.secondaryBreakdown?.data ?? [];
      secondaryDataList.forEach((secondaryData) => {
        if (primaryBreakdown.count) {
          const secondaryLabel = secondaryData.group;
          const val = secondaryData.count / primaryBreakdown.count;
          metrics[secondaryLabel] = formatPercentage(val, 0);
        }
      });

      breakdownGroups[label].push(metrics);
    });
  });
  return breakdownGroups;
}

export function groupByPrimaryPercentBreakdown(chartData, graphType, filters) {
  const breakdownGroups = {};
  const datedData = chartData ?? {};
  // group data by primary label and date
  Object.entries(datedData).forEach(([date, data]) => {
    const totalPostsForPeriod = data.count;
    const dataGroups = data.breakdown.data;
    zeroMissingPostCountGroups(dataGroups, graphType, filters);

    dataGroups.forEach((primaryBreakdown) => {
      const label = startCase(primaryBreakdown.group.toLowerCase());
      const primaryPostCount = primaryBreakdown.count;
      const divisor = totalPostsForPeriod > 0 ? totalPostsForPeriod : 1;
      const percentOfPosts = primaryPostCount / divisor;

      const metrics = {
        x: reportParseDate(date, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
        totalPosts: formatNumber(primaryPostCount),
        percentOfPosts,
      };

      breakdownGroups[label] ??= [];
      breakdownGroups[label].push(metrics);
    });
  });
  return breakdownGroups;
}

function getAnomalyEventsBetweenDates(data, start, end) {
  const startDate = dayjs(start, ['x']).valueOf();
  const endDate = dayjs(end, ['x']).valueOf();
  const keyFormat = POST_VOLUME_API_CAMEL_CASE_FORMAT;
  return Object.entries(data).reduce((acc, [timestamp, val]) => {
    const unixTimestamp = dayjs(timestamp, [keyFormat]).valueOf();
    const isBetweenDates = unixTimestamp >= startDate && unixTimestamp < endDate;
    return isBetweenDates ? [...acc, [timestamp, { ...val, timestamp }]] : acc;
  }, []);
}

export function groupByTotalPostsPerPeriodWithPrimaryBreakdown(
  chartData,
  anomalyData,
  graphType,
  filters,
  graphScale,
) {
  const breakdownGroups = {};
  const label = 'totalPosts';
  const results = [];
  const datedData = chartData ?? {};

  // group data by total posts per period and provide a primary grouping breakdown
  Object.entries(datedData).forEach(([date, data], idx, arr) => {
    const totalPostsForPeriod = data.count;
    const metrics = {
      x: reportParseDate(date, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
      totalPosts: totalPostsForPeriod,
    };
    /*
     * Get the anomaly events between the current data point and the next
     * If the current data point is the last one, get the anomaly events between
     * the current data point and the end of according to the scale. ('hour','day') etc.
     * */
    const anomaliesInRange =
      idx < arr.length - 1
        ? getAnomalyEventsBetweenDates(
            anomalyData,
            reportParseDate(date, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
            reportParseDate(arr[idx + 1][0], POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
          )
        : getAnomalyEventsBetweenDates(
            anomalyData,
            reportParseDate(date, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
            dayjs(date).endOf(graphScale.toLowerCase()).valueOf(),
          );

    /*
     * if the scale is hourly and it has anomalies use the last anomaly in the range
     * to get the daily mention increase percentage from. Since anomalies are detected
     * hourly this should only ever be 1. But when manually testing it's possible to
     * have more than one.
     */
    if (graphScale === GRAPH_SCALE.HOUR && anomaliesInRange.length) {
      const mentionVolumeIncrease = getDailyMentionIncreasePercentage(
        anomaliesInRange[anomaliesInRange.length - 1][1],
      );
      metrics.increaseOverPredictedVolume = mentionVolumeIncrease;
    }
    if (anomaliesInRange.length) {
      metrics.alertsTriggered = anomaliesInRange.length;
    }

    const dataGroups = data?.breakdown?.data;
    if (dataGroups) {
      zeroMissingPostCountGroups(dataGroups, graphType, filters);
      dataGroups.forEach((primaryBreakdown) => {
        const primaryLabel = startCase(primaryBreakdown.group.toLowerCase());
        const postCount = primaryBreakdown.count;
        metrics[primaryLabel] = formatNumber(postCount);
      });
    }
    results.push(metrics);
  });
  breakdownGroups[label] = sortBy(results, (result) => result.x);
  return breakdownGroups;
}

export function groupChartDataByLabel(chartData, anomalyData, graphType, filters, graphScale) {
  switch (graphType) {
    case POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL.value:
      return groupTotalPostsWithSecondaryPercentBreakdown(chartData, graphType, filters);
    case POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.value:
      return groupByPrimaryPercentBreakdown(chartData, graphType, filters);
    case POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_MEDIA_TYPE.value:
      return groupTotalPostsWithSecondaryPercentBreakdown(chartData, graphType, filters);
    case POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS.value:
      return groupByTotalPostsPerPeriodWithPrimaryBreakdown(
        chartData,
        anomalyData,
        graphType,
        filters,
        graphScale,
      );
    default:
      return {};
  }
}

export function formatMentionsBreakdownChartData(
  data,
  graphOption = POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL,
  filters = [],
) {
  const datasets = [];
  const breakdownGroups = groupTotalPostsWithSecondaryPercentBreakdown(
    data,
    graphOption.value,
    filters.length ? filters : graphOption.chartDataKeys,
  );
  Object.entries(breakdownGroups).forEach(([label, dataList]) => {
    datasets.push({
      label,
      data: dataList.map((d) => {
        return { ...d, y: d.totalPosts };
      }),
    });
  });
  return sortBy(datasets, (set) => graphOption.chartConfig.legendSort[set.label.toUpperCase()]);
}

function getAnomaliesInRange({ anomalyData, start, end }) {
  return getAnomalyEventsBetweenDates(
    anomalyData,
    reportParseDate(start, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
    reportParseDate(end, POST_VOLUME_API_CAMEL_CASE_FORMAT).valueOf(),
  );
}

export function getLineChartGraphScale(startDate, endDate) {
  if (Math.abs(dayjs(startDate).diff(dayjs(endDate), 'days')) <= 1) {
    return { withinYear: true, scale: GRAPH_SCALE.HOUR, unit: 'hours' };
  }
  if (dayjs(startDate).year() !== dayjs().year()) {
    return { withinYear: false, scale: GRAPH_SCALE.DAY, unit: GRAPH_SCALES.DAILY.value };
  }
  return { withinYear: true, scale: GRAPH_SCALE.DAY, unit: GRAPH_SCALES.DAILY.value };
}

export function formatMentionsTrendChartData(chartData, anomalyData) {
  if (chartData === null || chartData === undefined) {
    return [];
  }

  const pointRadius = [];
  const pointBackgroundColor = [];
  const pointHoverBackgroundColor = [];
  const datasets = {
    label: 'Mentions',
    data: [],
    pointHoverRadius: 4,
    pointRadius,
    pointBackgroundColor,
    pointHoverBackgroundColor,
  };
  const dates = Object.keys(chartData);
  const { scale } = getLineChartGraphScale(dates?.[0], dates?.[dates.length - 1]);

  Object.entries(chartData).forEach(([date, value], idx, arr) => {
    const dataPoint = { x: date, y: value.count };
    const nextDate = arr?.[idx + 1]?.[0];
    const anomaliesInRange = getAnomaliesInRange({
      anomalyData,
      start: date,
      end:
        nextDate ??
        dayjs(date).endOf(scale.toLowerCase()).format(POST_VOLUME_API_CAMEL_CASE_FORMAT),
    });
    /*
     * if the scale is hourly and it has anomalies use the last anomaly in the range
     * to get the daily mention increase percentage from. Since anomalies are detected
     * hourly this should only ever be 1. But when manually testing it's possible to
     * have more than one.
     */
    if (scale === GRAPH_SCALE.HOUR && anomaliesInRange.length) {
      const targetAnomaly = anomaliesInRange[anomaliesInRange.length - 1][1];
      const mentionVolumeIncrease = getIncreaseOverPredictedVolume(targetAnomaly);
      dataPoint.increaseOverPredictedVolume = mentionVolumeIncrease;
    }
    if (anomaliesInRange.length) {
      dataPoint.alertsTriggered = anomaliesInRange.length;
      dataPoint.color = TOKENS.COLOR_WARNING_800;
      pointRadius.push(4);
      pointBackgroundColor.push(TOKENS.COLOR_WARNING_800);
      pointHoverBackgroundColor.push(TOKENS.COLOR_WARNING_800);
    } else {
      pointRadius.push(0);
      pointBackgroundColor.push(TOKENS.COLOR_GRADIENT_800);
      pointHoverBackgroundColor.push(TOKENS.COLOR_GRADIENT_800);
    }
    datasets.data.push(dataPoint);
  });

  return [datasets];
}

export function formatChartDateTooltip(tooltip) {
  if (tooltip?.dataPoints?.length > 0) {
    const xValue = tooltip.dataPoints[0].raw.x;
    return dayjs(xValue).format('MMM D, YYYY');
  }
  return {};
}

export function generateMetricTooltipItems(tooltip) {
  if (!tooltip?.dataPoints?.length) return [];

  const target = tooltip.dataPoints[0].raw;
  return tooltip.dataPoints
    .map((dataPoint) => ({
      label: dataPoint.dataset.label,
      value: dataPoint.formattedValue,
      dot: {
        style: {
          backgroundColor: target?.color ? target.color : dataPoint.dataset.pointBackgroundColor,
        },
      },
    }))
    .concat(
      target?.alertsTriggered
        ? [{ label: 'Alerts Triggered', value: target.alertsTriggered, formatType: 'number' }]
        : [],
      target?.increaseOverPredictedVolume
        ? [
            {
              label: 'Increase Over Predicted Volume',
              value: target.increaseOverPredictedVolume,
              formatType: 'percent',
              formatOptions: {
                maximumFractionDigits: 0,
              },
            },
          ]
        : [],
    );
}
