import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as LibraryAPI from '@/apis/library';
import { useAuthStore } from '@/stores/auth';
import { refreshAbortController, handleCancelError, abortApiCall } from '@/apis/axios.utils';
import { usePopupGalleryStore } from './popup-gallery';

export const useGalleryStore = defineStore(
  'gallery',
  () => {
    const authStore = useAuthStore();
    const popupGalleryStore = usePopupGalleryStore();
    // State
    const pending = ref({
      deleteGallery: false,
      gallery: false,
      galleries: false,
      updateGallery: false,
      shoppableGalleries: false,
      galleryMediaV2List: false,
      likeShopFeedMediaV2List: false,
      reorderMedia: false,
      createGalleryStatus: false,
      addMediaStatus: false,
      removeMediaStatus: false,
      summaryGalleries: 0,
    });

    const error = ref({
      gallery: null,
      createGalleryStatus: null,
      addMediaStatus: null,
      removeMediaStatus: null,
    });

    const abortControllers = {
      getGalleries: ref(null),
      getShoppableGalleries: ref(null),
      getGalleryMediaV2: ref(null),
      getLikeShopFeedMediaV2: ref(null),
    };

    const gallery = ref(null);
    const galleries = ref([]);
    const shoppableGalleries = ref([]);
    const galleriesNextUrl = ref(null);
    const shoppableGalleriesNextUrl = ref(null);
    const uniqueImageGroupIds = ref([]);
    const galleryMediaV2List = ref([]);
    const galleryMediaV2ListNext = ref(null);
    const galleryMediaV2ListFullyLoaded = ref(false);
    const likeShopFeedMediaV2List = ref([]);
    const likeShopFeedMediaV2ListNext = ref(null);
    const uniqueLikeShopFeedImageGroupIds = ref([]);
    const likeShopFeedMediaV2ListFullyLoaded = ref(false);
    const newGallery = ref(null);
    const summaryGalleries = ref([]);
    const showShoppableSettingsPopup = ref(false);

    // Actions
    async function getGallery({ galleryId }) {
      try {
        pending.value.gallery = true;
        const response = await LibraryAPI.getGallery({
          brandId: authStore.currentBrand.id,
          galleryId,
        });
        const payload = response?.data;
        if (payload) {
          gallery.value = payload;
        }
      } catch (err) {
        gallery.value = null;
        error.value.gallery = err;
        throw err;
      } finally {
        pending.value.gallery = false;
      }
    }

    async function getSummaryGalleries({ type, types, minSize }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.summaryGalleries += 1;
      try {
        const response = await LibraryAPI.getGalleries({
          brandId,
          summary: true,
          type,
          types,
          minSize,
        });
        const payload = response?.data;
        summaryGalleries.value = payload;
        return payload;
      } finally {
        pending.value.summaryGalleries -= 1;
      }
    }

    function clearSummaryGalleries() {
      summaryGalleries.value = [];
    }

    async function getGalleries({
      type,
      startDate,
      endDate,
      tags,
      tagsFilterType,
      search,
      sort,
      spiritGalleryEnabled,
      limit,
      url,
    } = {}) {
      const brandId = authStore.currentBrand?.id;

      pending.value.galleries = true;
      let isAxiosCancelError = false;

      try {
        const signal = refreshAbortController(abortControllers.getGalleries);

        const response = await LibraryAPI.getGalleries(
          {
            brandId,
            type,
            startDate,
            endDate,
            tags,
            tagsFilterType,
            search,
            sort,
            spiritGalleryEnabled,
            limit,
            url,
          },
          { signal },
        );
        const payload = response?.data;

        galleries.value = [...galleries.value, ...(payload?.data ?? [])];
        galleriesNextUrl.value = payload?.paging?.next;
      } catch (e) {
        handleCancelError(e, () => {
          isAxiosCancelError = true;
        });
      } finally {
        if (!isAxiosCancelError) {
          pending.value.galleries = false;
        }
      }
    }
    function clearGalleries() {
      galleries.value = [];
      galleriesNextUrl.value = null;
    }

    async function getShoppableGalleries({
      type,
      startDate,
      endDate,
      tags,
      tagsFilterType,
      search,
      sort,
      limit,
      url,
    } = {}) {
      const brandId = authStore.currentBrand?.id;

      pending.value.shoppableGalleries = true;
      let isAxiosCancelError = false;

      try {
        const signal = refreshAbortController(abortControllers.getShoppableGalleries);

        const response = await LibraryAPI.getGalleries(
          {
            brandId,
            type,
            startDate,
            endDate,
            tags,
            tagsFilterType,
            search,
            sort,
            limit,
            url,
            live: 1,
          },
          { signal },
        );
        const payload = response?.data;

        shoppableGalleries.value = [...shoppableGalleries.value, ...(payload?.data ?? [])];
        shoppableGalleriesNextUrl.value = payload?.paging?.next;
      } catch (e) {
        handleCancelError(e, () => {
          isAxiosCancelError = true;
        });
      } finally {
        if (!isAxiosCancelError) {
          pending.value.shoppableGalleries = false;
        }
      }
    }
    function clearShoppableGalleries() {
      shoppableGalleries.value = [];
      shoppableGalleriesNextUrl.value = null;
    }

    async function getGalleryMediaV2({
      galleryId,
      url,
      limit,
      sort,
      include,
      withProductsOnly,
      startDate,
      endDate,
      excludeInstagramOwnedWithoutProducts,
    }) {
      const brandId = authStore.currentBrand?.id;

      galleryMediaV2ListFullyLoaded.value = false;
      pending.value.galleryMediaV2List = true;
      try {
        const signal = refreshAbortController(abortControllers.getGalleryMediaV2);

        const response = await LibraryAPI.default.getGalleryMediaV2(
          {
            brandId,
            galleryId,
            limit,
            sort,
            withProductsOnly,
            startDate,
            endDate,
            excludeInstagramOwnedWithoutProducts,
            include,
            url,
          },
          { signal },
        );
        const payload = response?.data;

        const uniqueMediaList =
          payload?.data
            ?.map((item) => {
              if (uniqueImageGroupIds.value.indexOf(item.id) < 0) {
                uniqueImageGroupIds.value.push(item.id);
                return item;
              }
              return null;
            })
            .filter((item) => item !== null) ?? [];
        galleryMediaV2List.value = [...galleryMediaV2List.value, ...uniqueMediaList];
        galleryMediaV2ListNext.value = payload?.paging?.next;
        if (payload?.paging?.next === null) {
          galleryMediaV2ListFullyLoaded.value = true;
        }

        return payload;
      } catch (e) {
        handleCancelError(e);
      } finally {
        pending.value.galleryMediaV2List = false;
      }
      return undefined;
    }

    function clearGalleryMediaV2() {
      galleryMediaV2List.value = [];
      galleryMediaV2ListNext.value = null;
      galleryMediaV2ListFullyLoaded.value = false;
      uniqueImageGroupIds.value = [];
    }

    async function getLikeShopFeedMediaV2({ galleryId, url, limit }) {
      const brandId = authStore.currentBrand?.id;

      likeShopFeedMediaV2ListFullyLoaded.value = false;
      pending.value.likeShopFeedMediaV2List = true;
      try {
        const signal = refreshAbortController(abortControllers.getLikeShopFeedMediaV2);
        const response = await LibraryAPI.default.getGalleryMediaV2(
          {
            brandId,
            galleryId,
            limit,
            sort: 'ORDER',
            withProductsOnly: true,
            excludeInstagramOwnedWithoutProducts: true,
            url,
          },
          { signal },
        );
        const payload = response?.data;

        const uniqueMediaList =
          payload?.data
            ?.map((item) => {
              if (uniqueLikeShopFeedImageGroupIds.value.indexOf(item.id) < 0) {
                uniqueLikeShopFeedImageGroupIds.value.push(item.id);
                return item;
              }
              return null;
            })
            .filter((item) => item !== null) ?? [];
        likeShopFeedMediaV2List.value = [...likeShopFeedMediaV2List.value, ...uniqueMediaList];
        likeShopFeedMediaV2ListNext.value = payload?.paging?.next;
        if (payload?.paging?.next === null) {
          likeShopFeedMediaV2ListFullyLoaded.value = true;
        }

        return payload;
      } catch (e) {
        handleCancelError(e);
      } finally {
        pending.value.likeShopFeedMediaV2List = false;
      }
      return undefined;
    }

    function clearLikeShopFeedMediaV2() {
      likeShopFeedMediaV2List.value = [];
      likeShopFeedMediaV2ListNext.value = null;
      likeShopFeedMediaV2ListFullyLoaded.value = false;
      uniqueLikeShopFeedImageGroupIds.value = [];
    }

    function clearGalleryV2() {
      galleryMediaV2List.value = [];
    }

    function addToFrontOfGalleryMediaList(item) {
      galleryMediaV2List.value.unshift(item);
    }

    function updateGalleryMediaLink({ mediaId, links }) {
      // update target media in the list if there is any
      // galleryMediaList element always has such structure (each element has nested media.id)
      const targetIndex = galleryMediaV2List.value.findIndex((item) => item.id === mediaId);
      if (targetIndex > -1) {
        const linksMap = links.reduce((acc, obj) => {
          acc[obj.url] = obj;
          return acc;
        }, {});
        const targetMedia = galleryMediaV2List.value[targetIndex];
        // Filter list based on the new list
        targetMedia.products = targetMedia.products.filter((el) =>
          Object.keys(linksMap).includes(el.url),
        );

        // Adding new links
        const existingUrls = targetMedia.products.map((el) => el.url);
        Object.keys(linksMap).forEach((url) => {
          if (!existingUrls.includes(url)) {
            targetMedia.products.push({ ...linksMap[url], clicks: 0 });
          }
        });
        galleryMediaV2List.value.splice(targetIndex, 1, targetMedia);
      }
    }

    function cancelGetGalleryMediaV2() {
      abortApiCall(abortControllers.getGalleryMediaV2);
    }

    async function reorderMedias({ galleryId, changeList, sort, limit, autoReload = true }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.reorderMedia = true;
      try {
        await LibraryAPI.updateGalleryOrder({ brandId, galleryId, changeList });
      } finally {
        pending.value.reorderMedia = false;
      }

      if (autoReload) {
        clearGalleryMediaV2();
        getGalleryMediaV2({
          galleryId,
          sort,
          limit,
          include: ['predictions'],
        });
      }
    }

    async function createGallery({
      name,
      galleryType,
      duplicate,
      duplicateFilter,
      description,
      tags,
    }) {
      const brandId = authStore.currentBrand?.id;

      error.value.createGalleryStatus = null;
      pending.value.createGalleryStatus = true;

      try {
        const response = await LibraryAPI.createGallery({
          brandId,
          name,
          description,
          tags,
          galleryType,
          duplicate,
          duplicateFilter,
        });
        const payload = response?.data;

        newGallery.value = payload;

        return payload;
      } catch (e) {
        error.value.createGalleryStatus = e;
        throw e;
      } finally {
        pending.value.createGalleryStatus = false;
      }
    }

    async function updateGallery({
      galleryId,
      name,
      description,
      tags,
      spiritGalleryEnabled,
      settings,
    }) {
      const brandId = authStore.currentBrand?.id;

      error.value.gallery = null;
      pending.value.gallery = true;
      pending.value.updateGallery = true;

      try {
        const response = await LibraryAPI.updateGallery(
          {
            brandId,
            galleryId,
            name,
            description,
            tags,
            spiritGalleryEnabled,
            settings,
          },
          { decamelizeRequests: false },
        );
        const payload = response?.data;

        gallery.value = payload;

        return payload;
      } catch (e) {
        error.value.gallery = e;
        throw e;
      } finally {
        pending.value.gallery = false;
        pending.value.updateGallery = false;
      }
    }

    async function deleteGallery({ galleryId }) {
      pending.value.deleteGallery = true;
      try {
        const brandId = authStore.currentBrand?.id;

        const response = await LibraryAPI.deleteGallery({ brandId, galleryId });
        return response?.data;
      } finally {
        pending.value.deleteGallery = false;
      }
    }

    function clearNewGallery() {
      newGallery.value = null;
    }

    async function addMediaToGallery({ galleryId, mediaIds }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.addMediaStatus = true;
      error.value.addMediaStatus = null;

      try {
        const response = await LibraryAPI.addGalleryMedia({ brandId, galleryId, mediaIds });
        const payload = response?.data;

        return payload;
      } catch (e) {
        error.value.addMediaStatus = e;
        throw e;
      } finally {
        pending.value.addMediaStatus = false;
      }
    }

    async function removeMediaFromGallery({ galleryId, mediaId }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.removeMediaStatus = true;
      error.value.removeMediaStatus = null;

      try {
        const response = await LibraryAPI.removeGalleryMedia({ brandId, galleryId, mediaId });
        const payload = response?.data;

        // Check if gallery exists or if it's changed. This can happen if a DELETE request was slow
        // and the user navigated away from the board page while it was still pending
        if (gallery.value && gallery.value.id === galleryId) {
          popupGalleryStore.galleryMediaList =
            popupGalleryStore.galleryMediaList?.filter((item) => item.media.id !== mediaId) ?? [];
          gallery.value.gallerySize = popupGalleryStore.galleryMediaList.length;
        }

        return payload;
      } catch (e) {
        error.value.removeMediaStatus = e;
        throw e;
      } finally {
        pending.value.removeMediaStatus = false;
      }
    }

    async function removeBulkMediaFromGallery({ galleryId, mediaIds }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.removeMediaStatus = true;
      error.value.removeMediaStatus = null;

      try {
        const response = await LibraryAPI.removeGalleryMediaBulk({ brandId, galleryId, mediaIds });
        const payload = response?.data;

        // Check if gallery exists or if it's changed. This can happen if a DELETE request was slow
        // and the user navigated away from the board page while it was still pending
        if (gallery.value && gallery.value.id === galleryId) {
          popupGalleryStore.galleryMediaList =
            popupGalleryStore.galleryMediaList?.filter(
              (item) => !mediaIds.includes(item.media.id),
            ) ?? [];
          gallery.value.gallerySize = popupGalleryStore.galleryMediaList.length;
        }

        return payload;
      } catch (e) {
        error.value.removeMediaStatus = e;
        throw e;
      } finally {
        pending.value.removeMediaStatus = false;
      }
    }

    function openShoppableSettingsPopup() {
      showShoppableSettingsPopup.value = true;
    }

    function closeShoppableSettingsPopup() {
      showShoppableSettingsPopup.value = false;
    }

    return {
      // State
      pending,
      error,
      gallery,
      galleries,
      galleriesNextUrl,
      shoppableGalleries,
      shoppableGalleriesNextUrl,
      galleryMediaV2List,
      galleryMediaV2ListNext,
      galleryMediaV2ListFullyLoaded,
      likeShopFeedMediaV2List,
      likeShopFeedMediaV2ListNext,
      likeShopFeedMediaV2ListFullyLoaded,
      newGallery,
      showShoppableSettingsPopup,
      // Actions
      getGallery,
      getShoppableGalleries,
      summaryGalleries,
      getGalleries,
      clearGalleries,
      clearShoppableGalleries,
      getGalleryMediaV2,
      clearGalleryMediaV2,
      getLikeShopFeedMediaV2,
      clearGalleryV2,
      clearLikeShopFeedMediaV2,
      addToFrontOfGalleryMediaList,
      updateGalleryMediaLink,
      cancelGetGalleryMediaV2,
      reorderMedias,
      createGallery,
      updateGallery,
      deleteGallery,
      clearNewGallery,
      addMediaToGallery,
      removeMediaFromGallery,
      removeBulkMediaFromGallery,
      getSummaryGalleries,
      clearSummaryGalleries,
      openShoppableSettingsPopup,
      closeShoppableSettingsPopup,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
