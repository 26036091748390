import { ref } from 'vue';
import { defineStore } from 'pinia';
import { LibraryAPI } from '@/apis';
import MediaModel from '@/models/media';
import { handleCancelError, refreshAbortController } from '@/apis/axios.utils';

export const useMediaStore = defineStore(
  'media',
  () => {
    const pending = ref({
      mediaList: false,
      topPostMediaList: false,
      createMediaV2: false,
    });

    const error = ref({});

    const mediaProducts = ref({});
    const mediaV2 = ref(null);
    const newMedia = ref(null);
    const newMediaV2 = ref(null);
    const mediaList = ref([]);
    const topPostMediaList = ref([]);
    const mediaListNextUrl = ref(null);

    const abortControllers = {
      mediaList: ref(null),
      topPostMediaList: ref(null),
    };

    // These functions ensure that media being passed into the scheduler are the correct Object shape.
    // This should reduce, and eventually eliminate the need for the above helper functions.
    // TODO: Remove these helper functions once the Unified Media API is complete.

    async function validateMediaList({ brandId, mediaArrList }, include = 'predictions') {
      if (mediaArrList.some((media) => !(media instanceof MediaModel))) {
        const response = await LibraryAPI.getMediaList({
          brandId,
          include,
          ids: mediaArrList.map((media) => media.id ?? media.media_id),
        });

        const payload = response?.data?.data;

        if (payload) {
          return payload.map((media) => new MediaModel(media));
        }
        return [];
      }
      return mediaArrList;
    }

    async function validateMediaObject({ brandId, media }) {
      if (!(media instanceof MediaModel)) {
        const response = await LibraryAPI.getMediaV2({
          brandId,
          mediaId: media.id ?? media.mediaId,
        });
        const payload = response?.data;
        if (payload) {
          this.mediaV2 = new MediaModel(payload);
        } else {
          this.mediaV2 = undefined;
        }
      } else {
        this.mediaV2 = media;
      }
    }

    async function fetchMediaProducts({ brandId, mediaId }) {
      const response = await LibraryAPI.fetchMediaProducts({ brandId, mediaId });
      this.mediaProducts = response.data;
    }

    async function createMedia({ brandId }, data) {
      const response = await LibraryAPI.createMedia({ brandId }, data);
      this.newMedia = response?.data;
    }

    async function createMediaV2({ brandId }, data) {
      const response = await LibraryAPI.createMediaV2({ brandId }, data);
      this.newMediaV2 = response?.data;
    }

    function clearMediaList() {
      mediaListNextUrl.value = null;
      mediaList.value = [];
    }

    function updateMediaListItem({ idx, item }) {
      this.mediaList[idx] = item;
    }

    async function getMediaList({
      brandId,
      url,
      sortFields,
      sort,
      filters,
      include,
      ids,
      limit,
      clearMedia = false,
      includeInstagramUsers = false,
    }) {
      pending.value.mediaList = true;
      let isAxiosCancelError = false;

      try {
        const signal = refreshAbortController(abortControllers.mediaList);

        const response = await LibraryAPI.getMediaList(
          { brandId, url, sortFields, sort, filters, include, ids, limit, includeInstagramUsers },
          { signal },
        );
        const payload = response?.data;

        if (clearMedia) {
          clearMediaList();
        }

        if (payload?.data) {
          this.mediaList = [...this.mediaList, ...payload.data];
          mediaListNextUrl.value = payload?.paging?.next;
        }
      } catch (err) {
        handleCancelError(err, () => {
          isAxiosCancelError = true;
        });
      } finally {
        if (!isAxiosCancelError) {
          pending.value.mediaList = false;
        }
      }
    }

    async function getTopPostMediaList({ brandId, limit, filters, sortFields }) {
      pending.value.topPostMediaList = true;
      let isAxiosCancelError = false;

      try {
        const signal = refreshAbortController(abortControllers.topPostMediaList);

        const response = await LibraryAPI.getTopPostMediaList(
          { brandId, limit, filters, sortFields },
          { signal },
        );
        this.topPostMediaList = response.data.data;
      } catch (err) {
        handleCancelError(err, () => {
          isAxiosCancelError = true;
        });
      } finally {
        if (!isAxiosCancelError) {
          pending.value.topPostMediaList = false;
        }
      }
    }

    async function getMediaListCSV({ brandId, source, filters, sortFields, sort, socketId }) {
      return LibraryAPI.getMediaListCSV({
        brandId,
        source,
        filters,
        sortFields,
        sort,
        socketId,
      });
    }

    return {
      pending,
      error,
      mediaProducts,
      mediaV2,
      newMedia,
      newMediaV2,
      mediaList,
      topPostMediaList,
      mediaListNextUrl,
      fetchMediaProducts,
      validateMediaList,
      validateMediaObject,
      createMedia,
      createMediaV2,
      clearMediaList,
      updateMediaListItem,
      getMediaList,
      getTopPostMediaList,
      getMediaListCSV,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
